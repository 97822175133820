angular.module("eShareApp").controller("AdapterImportCtrl", [
	"$state", "$scope", "messageBox", "adaptersRepository",

	function (
		$state, $scope, messageBox, adaptersRepository
	) {

		$scope.importExportTitle = "Import Adapter";
		$scope.isImport = true;
		$scope.importMessages = [];
		$scope.errorMessages = [];
		$scope.isMultiImport = false;
		$scope.isBusy = false;

		$scope.cfg = {
			json: "",
		};

		const stateName = $state.current.name;

		$scope.importConfiguration = function () {
			const configuration = angular.fromJson($scope.cfg.json);
			const project = $scope.$parent.project;
			$scope.isBusy = true;
			if(configuration.length === undefined) {
				adaptersRepository.importConfiguration($scope.project, configuration).then(
					upgradedConfiguration => {
						$scope.adapter = upgradedConfiguration;
						if($scope.current.name === stateName) {
							$state.go("project.admin.adapters.adapter.edit", {
								projectId: project.id,
								adapterId: $scope.adapter.id,
							});
						}
					},
					errorMessage => {
						$scope.isBusy = false;
						messageBox.openError("Error", "Import failed. Reason: " + errorMessage);
					}
				);
			} else {
				$scope.isMultiImport = true;
				let finishedCount = 0;
				for(let i = 0; i < configuration.length; i++) {
					const adapterConfig = configuration[i];
					adaptersRepository.importConfiguration($scope.project, adapterConfig).then(
						upgradedConfiguration => {
							++finishedCount;
							if(finishedCount === configuration.length) {
								$scope.isBusy = false;
							}
							$scope.importMessages.push(
								"Succesfully imported " + upgradedConfiguration.name
							);
						},
						errorMessage => {
							++finishedCount;
							if(finishedCount === configuration.length) {
								$scope.isBusy = false;
							}
							$scope.errorMessages.push(
								"Import failed. Reason: " + errorMessage
							);
						}
					);
				}
			}
		};
	}
]);
