angular.module("eShare.core").factory("notification", notification);

notification.$inject = [];

function notification() {

	const maxBufferSize = 200;

	let messages = [];
	const toastsOnScreen = {};
	let areToastsEnabled = true;

	initialize();

	return {
		clearAll: clearAll,
		exception: exception,
		error: error,
		warning: warning,
		info: info,
		success: success,
		logError: logError,
		messages: messages,
		disableToasts: disableToasts,
	};

	function initialize() {
		let messagesFromStore = window.localStorage.getItem("messageStore");
		let needsToStore = false;
		if(messagesFromStore) {
			messagesFromStore = angular.fromJson(messagesFromStore);
			if(messagesFromStore instanceof Array) {
				messages = messagesFromStore;
				for(let i = 0; i < messages.length; ++i) {
					if(!(messages[i].username instanceof String)) {
						messages[i].username = "";
						needsToStore = true;
					}
				}
				if(messages.length > maxBufferSize) {
					messages.length = maxBufferSize;
					needsToStore = true;
				}
			} else {
				needsToStore = true;
			}
		} else {
			needsToStore = true;
		}
		if(needsToStore) {
			window.localStorage.setItem("messageStore", angular.toJson(messages));
		}
		filterByUser();
		toastr.subscribe(toastrCallback);
	}

	function filterByUser() {
		const currentUsername = getCurrentUsername();
		messages = _.filter(messages, message => {
			return message.username === currentUsername || message.username === "";
		});
	}

	function getCurrentUsername() {
		return window.eShare.currentUser.username;
	}

	function toastrCallback(response) {
		if(Utilities.isNullOrUndefined(response.toastId)) {
			return;
		}
		if(response.state === "hidden") {
			if(!Utilities.isNullOrUndefined(toastsOnScreen[response.toastId])) {
				delete toastsOnScreen[response.toastId];
			}
		} else if(response.state === "visible") {
			toastsOnScreen[response.toastId] = response.map.message;
		}
	}

	function store(message) {
		message.timeStamp = new Date();
		message.username = getCurrentUsername();
		/*
		FIXME:
		data-property of the incoming message is an object with non-enumerable properties
		in other browsers than IE11. This causes the data property to become an empty object
		in those browsers when calling angular.toJson().

		The if-block below partly fixes this by getting the object's non-enumerable properties and
		setting them to a new object as enumerable ones, but inherited properties
		(e.g. the error number) are lost like this.
		*/
		if(!window.eShare.isIE && message.data) {
			const newData = {};
			const properties = Object.getOwnPropertyNames(message.data);
			properties.sort();
			properties.forEach(prop => {
				newData[prop] = message.data[prop];
			});
			message.data = newData;
		}
		messages.unshift(message);
		if(messages.length > maxBufferSize) {
			messages.length = maxBufferSize;
		}
		window.localStorage.setItem("messageStore", angular.toJson(messages));
	}

	function notify(type, message, showToast, overrides) {
		console.log(type + ": " + message);
		if(messages.length === 0 || messages[0].message !== message) {
			store({
				type: type,
				message: message,
			});
		}
		if(showToast) {
			message = normalizeNewlines(message);
			if(!_.includes(toastsOnScreen, message) && areToastsEnabled) {
				toastr[type](message, null, overrides);
			}
		}
	}

	function clearAll() {
		messages.length = 0;
		window.localStorage.setItem("messageStore", angular.toJson(messages));
	}

	function exception(e) {
		console.log("------- Exception ------- BEGIN -------");
		let hasProperties = false;
		_.forOwn(e, (value, key) => {
			console.log("exception." + key + " = '" + value + "'");
			hasProperties = true;
		});
		if(!hasProperties) {
			console.log(e);
		}
		console.log("------- Exception -------  END  -------");
		let message = Utilities.getErrorMessage(e);
		if(message.contains("The server threw an exception.")) {
			message = "CADMATIC eShare failed to load / modify 3D view possibly due to"
			+ " insufficient memory. Closing and reopening the web browser might help.";
		}
		store({
			type: "exception",
			message: message,
			data: e,
		});
		message = normalizeNewlines(message);
		if(!_.includes(toastsOnScreen, message) && areToastsEnabled) {
			toastr.error(message, null, { timeOut: 0, extendedTimeOut: 0 });
		}
	}

	function error(message) {
		if(Utilities.isObject(message) && (message.status === 498 || message.status === 499)) {
			// Session terminated or No license: ignore
			return;
		}
		message = Utilities.getErrorMessage(message);
		if(message.contains("\"status\":498")
			&& message.contains("\"statusText\":\"Session terminated\"")) {
			// Session terminated: ignore
			return;
		}
		if(message.contains("\"status\":499")
			&& message.contains("\"statusText\":\"No license\"")) {
			// No license: ignore
			return;
		}
		notify("error", message, true, { timeOut: 0 });
	}

	function warning(message) {
		notify("warning", message, true);
	}

	function info(message) {
		notify("info", message, true);
	}

	function success(message) {
		notify("success", message, true);
	}

	function logError(message) {
		notify("error", Utilities.getErrorMessage(message), false);
	}

	function normalizeNewlines(message) {
		message = message.replace("\r\n", "</br>");
		message = message.replace("\n\r", "</br>");
		return message.replace("\n", "</br>");
	}

	function disableToasts() {
		areToastsEnabled = false;
	}
}
