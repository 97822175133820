angular.module("eShareApp").factory("adaptersRepository", adaptersRepository);

adaptersRepository.$inject = ["repositoryBase"];

function adaptersRepository(repositoryBase) {
	const baseApi = "/api";
	const availableAdaptersApi = baseApi + "/AvailableAdapters";
	const projectsApi = baseApi + "/project";
	const projectIdApi = projectsApi + "/{projectId}";
	const adaptersApi = projectIdApi + "/DataSourceAdapterConfigurations/";
	const adapterIdApi = adaptersApi + "{adapterId}";
	const configEditorApi = projectIdApi + "/ConfigurationEditor";
	const viewApi =
		configEditorApi + "/GetAdapterConfigurationTemplate/{adapterId}";
	const controllerApi =
		configEditorApi + "/GetAdapterConfigurationScript/{adapterId}";
	const createDataSourceApi =
		configEditorApi + "/CreateDataSource/{adapterId}?dataSourceTypeGuid={dataSourceTypeGuid}";
	const createAdapterApi =
		configEditorApi + "/CreateAdapter/{adapterTypeGuid}";
	const performAdapterActionApi =
		configEditorApi + "/PerformAdapterAction/{adapterId}?action={action}";
	const performDataSourceActionApi =
		configEditorApi + "/PerformDataSourceAction/{dataSourceId}?action={action}";
	const refreshCategoriesApi =
		configEditorApi + "/RefreshCategories/{dataSourceId}";
	const importAdapterConfigurationApi =
		configEditorApi + "/ImportAdapterConfiguration/" + Utilities.emptyGuid();

	return {
		getAvailableAdapters: getAvailableAdapters,
		getAll: getAll,
		get: get,
		create: create,
		createDataSource: createDataSource,
		remove: remove,
		removeDataSource: removeDataSource,
		update: update,
		getConfigurationView: getConfigurationView,
		getConfigurationController: getConfigurationController,
		performAdapterAction: performAdapterAction,
		performDataSourceAction: performDataSourceAction,
		refreshCategories: refreshCategories,
		importConfiguration: importConfiguration,
		exportAll: exportAll,
		importAll: importAll,
	};

	function getAvailableAdapters() {
		return repositoryBase.get(availableAdaptersApi, {})
			.then(data => {
				let adapters = Utilities.isArray(data) ? data : [];
				adapters = _.sortBy(adapters, a => {
					return a.name.toLowerCase();
				});
				return adapters;
			});
	}

	function getAll(projectId) {
		return repositoryBase.get(adaptersApi, { projectId: projectId })
			.then(data => {
				let adapters = Utilities.isArray(data) ? data : [];
				adapters = _.sortBy(adapters, a => {
					return a.name.toLowerCase();
				});
				for(let i = 0; i < adapters.length; ++i) {
					sortDataSources(adapters[i]);
				}
				return adapters;
			});
	}

	function get(projectId, adapterId) {
		return repositoryBase.get(adapterIdApi, { projectId: projectId, adapterId: adapterId })
			.then(adapter => {
				sortDataSources(adapter);
				return adapter;
			});
	}

	function remove(project, adapter) {
		return repositoryBase.remove(adapterIdApi, { projectId: project.id, adapterId: adapter.id });
	}

	function getConfigurationView(projectId, adapterId) {
		return repositoryBase.get(viewApi, { projectId: projectId, adapterId: adapterId })
			.then(data => {
				return data.template;
			});
	}

	function getConfigurationController(projectId, adapterId) {
		return repositoryBase.get(controllerApi, { projectId: projectId, adapterId: adapterId })
			.then(data => {
				return data.script;
			});
	}

	function create(project, adapterTypeGuid) {
		return repositoryBase
			.post(createAdapterApi, {
				projectId: project.id,
				adapterTypeGuid: adapterTypeGuid,
			})
			.then(data => {
				return data.adapterConfigurationId;
			});
	}

	function createDataSource(projectId, adapterId, dataSourceTypeGuid, name) {
		name = name === undefined ? null : "\"" + name + "\"";
		return repositoryBase
			.post(createDataSourceApi, {
				projectId: projectId,
				adapterId: adapterId,
				dataSourceTypeGuid: dataSourceTypeGuid,
			}, name)
			.then(data => {
				return data.dataSourceConfigurationId;
			});
	}

	function update(projectId, adapter) {
		return repositoryBase.put(adapterIdApi, {
			projectId: projectId,
			adapterId: adapter.id,
		}, adapter);
	}

	function removeDataSource(projectId, adapter, dataSourceId) {
		const dataSourceIndex = _.findIndex(adapter.dataSourceConfigurations, dsc => {
			return dsc.id === dataSourceId;
		});
		if(dataSourceIndex === -1) {
			throw new EShareException("Data source " + dataSourceId + " is not found");
		}
		adapter.dataSourceConfigurations.splice(dataSourceIndex, 1);
		return update(projectId, adapter);
	}

	function performAdapterAction(projectId, adapter, action) {
		return repositoryBase.post(performAdapterActionApi, {
			projectId: projectId,
			adapterId: adapter.id,
			action: action,
		}, adapter);
	}

	function performDataSourceAction(projectId, adapter, dataSource, action) {
		const adapterCopy = _.cloneDeep(adapter);
		const i = _.findIndex(adapterCopy.dataSourceConfigurations, { id: dataSource.id });
		if(i >= 0) {
			adapterCopy.dataSourceConfigurations[i] = _.cloneDeep(dataSource);
		}
		return repositoryBase.post(performDataSourceActionApi, {
			projectId: projectId,
			dataSourceId: dataSource.id,
			action: action,
		}, adapterCopy);
	}

	function refreshCategories(projectId, adapter, dataSource) {
		const adapterCopy = _.cloneDeep(adapter);
		const i = _.findIndex(adapterCopy.dataSourceConfigurations, { id: dataSource.id });
		if(i >= 0) {
			adapterCopy.dataSourceConfigurations[i] = _.cloneDeep(dataSource);
		}
		return repositoryBase.post(refreshCategoriesApi, {
			projectId: projectId,
			dataSourceId: dataSource.id,
		}, adapterCopy);
	}

	function importConfiguration(project, adapterConfiguration) {
		return repositoryBase.post(importAdapterConfigurationApi, {
			projectId: project.id,
		}, adapterConfiguration);
	}

	function exportAll(projectId) {
		return repositoryBase
			.post(Utilities.combineUri(adaptersApi, "export"), { projectId: projectId })
			.then(data => JSON.stringify(data, undefined, 3));
	}

	function importAll(projectId, serializedExportedData) {
		const exportedData = JSON.parse(serializedExportedData);
		return repositoryBase.post(
			Utilities.combineUri(adaptersApi, "import"), { projectId: projectId }, exportedData
		);
	}

	function sortDataSources(adapter) {
		let dataSourceConfigurations = adapter.dataSourceConfigurations;
		dataSourceConfigurations = _.sortBy(dataSourceConfigurations, ds => {
			return ds.name.toLowerCase();
		});
		adapter.dataSourceConfigurations = dataSourceConfigurations;
	}
}
