angular.module("eShareApp").controller("MapsConfigCtrl", mapsConfigCtrl);

mapsConfigCtrl.$inject = [
	"$scope", "$state", "project", "maps", "mapsRepository", "$http", "notification", "Upload",
	"messageBox", "$interval", "backgroundProgressesRepository"
];

function mapsConfigCtrl(
	$scope, $state, project, maps, mapsRepository, $http, notification, upload,
	messageBox, $interval, backgroundProgressesRepository
) {
	$scope.project = project;
	$scope.maps = initializeMaps(maps);
	$scope.isUploadInProgress = true;
	mapsRepository.isMapsBusy($scope.project.id).then(response => {
		$scope.isUploadInProgress = response;
		if($scope.isUploadInProgress) {
			backgroundProgressesRepository
				.getTargetBackgroundMessages($scope.project.id, "Map")
				.then(messages => {
					if(messages) {
						$scope.message = messages[0];
					}
				});
		}
	});

	$scope.onMousedown = function () {
		$scope.isDragging = true;
		$(document).one("mouseup", () => {
			$scope.isDragging = false;
		});
	};

	function initializeMaps(maps) {
		const tempMaps = _.cloneDeep(maps);
		_.forEach(tempMaps, tempMap => {
			if(tempMap.zfrom.indexOf(":::") > -1) {
				tempMap.targetCoordinateIdFrom = tempMap.zfrom.substring(
					tempMap.zfrom.indexOf(":::") + 3
				);
				tempMap.zfrom = tempMap.zfrom.substring(0, tempMap.zfrom.indexOf(":::"));
			}
			if(tempMap.zto.indexOf(":::") > -1) {
				tempMap.targetCoordinateIdTo = tempMap.zto.substring(tempMap.zto.indexOf(":::") + 3);
				tempMap.zto = tempMap.zto.substring(0, tempMap.zto.indexOf(":::"));
			}
		});
		return tempMaps;
	}

	const isMapsBusyInterval = $interval(() => {
		if($state.current.name === "project.admin.maps") {
			mapsRepository.isMapsBusy($scope.project.id).then(response => {
				$scope.isUploadInProgress = response;
			});
			if(!$scope.isDragging) {
				mapsRepository.getAll($scope.project.id).then(maps => {
					if(!$scope.isDragging) {
						$scope.maps = initializeMaps(maps);
					}
				});
			}
			if($scope.isUploadInProgress) {
				backgroundProgressesRepository
					.getTargetBackgroundMessages($scope.project.id, "Map")
					.then(messages => {
						if(messages) {
							$scope.message = messages[0];
						}
					});
			}
		} else {
			$interval.cancel(isMapsBusyInterval);
		}
	}, 5 * 1000);

	$scope.mapUpload = {
		isActive: false,
		progress: "0%",
	};

	$scope.mapFileSelected = function (files/*, event*/) {
		if(!files) {
			return;
		}
		files.forEach(file => {
			if(!file) {
				return;
			}
			$scope.fileUploaded = file.name;
			let fileType = "";
			const extension = (file.name || "").toLowerCase();
			if(extension.endsWith(".dwg")) {
				fileType = ".dwg";
			}
			if(extension.endsWith(".dxf")) {
				fileType = ".dxf";
			}
			if(extension.endsWith(".pdf")) {
				fileType = ".pdf";
			}
			if(extension.endsWith(".jpg")) {
				fileType = ".jpg";
			}
			if(extension.endsWith(".jpeg")) {
				fileType = ".jpeg";
			}
			if(extension.endsWith(".tif")) {
				fileType = ".tif";
			}
			if(extension.endsWith(".tiff")) {
				fileType = ".tiff";
			}
			if(extension.endsWith(".png")) {
				fileType = ".png";
			}
			if(extension.endsWith(".gif")) {
				fileType = ".gif";
			}
			if(extension.endsWith(".bmp")) {
				fileType = ".bmp";
			}
			if(fileType === "") {
				notification.error(
					"File " + file.name + ": The file type must be .dwg, .dxf or .pdf,"
					+ " or an image format (.jpg, .jpeg, .png, .gif, .bmp, .tif, .tiff)"
				);
				return;
			}
			if(!window.eShare.integratedSecurity) {
				$http.post("api/OneTimeToken/Create")
					.then(
						tokenData => {
							uploadModel(file, fileType, tokenData.data.token);
						},
						() => {
							notification.error("Error getting one-time authorization token");
						}
					);
			} else {
				uploadModel(file, fileType);
			}
		});
	};

	function uploadModel(file, fileType, oneTimeToken) {
		$scope.isUploadInProgress = true;
		let lastModifiedDate;
		try {
			lastModifiedDate = file.lastModifiedDate.getTime();
		} catch(e) {
			lastModifiedDate = file.lastModified;
		}
		let url = oneTimeToken
			? "api/project/{projectId}/Maps/{projectId}/Upload?fileType={fileType}"
			+ "&lastModifiedDate={lastModifiedDate}&token={oneTimeToken}"

			: "api/project/{projectId}/Maps/{projectId}/Upload?fileType={fileType}"
			+ "&lastModifiedDate={lastModifiedDate}";
		url = url.supplant({
			projectId: project.id,
			oneTimeToken: oneTimeToken,
			lastModifiedDate: lastModifiedDate,
			fileType: fileType,
		});
		$scope.mapUpload.isActive = true;
		$scope.mapUpload.progress = "0%";
		const uploadSession = upload.upload({
			url: url,
			data: {
				file: file,
			},
		});
		uploadSession.then(onSuccess, onError, onProgress);

		function onSuccess(response) {
			$scope.mapUpload.isActive = false;
			$scope.mapUpload.progress = "100%";
			notification.success("Map file uploaded");
			$scope.maps = response.data;
			//$scope.isUploadInProgress = false;
		}

		function onError(reason) {
			$scope.mapUpload.isActive = false;
			$scope.mapUpload.progress = "100%";
			notification.error("Map file upload failed: " + Utilities.getErrorMessage(reason));
			//$scope.isUploadInProgress = false;
		}

		function onProgress(event) {
			$scope.isUploadInProgress = true;
			let progress = event.total ? event.loaded / event.total : 0;
			progress = Math.floor(progress * 100);
			$scope.mapUpload.progress = progress + "%";
		}
	}

	$scope.localizeDate = function localizeDate(utcDateTimeString) {
		const date = moment.utc(utcDateTimeString);
		return date.isValid() ? date.local().format("LLL") : utcDateTimeString;
	};

	$scope.removeMap = function (map) {
		const mbox = messageBox.openQuestion(
			"Remove map",
			"Do you really want to remove map " + map.displayName + "?",
			"Confirm", "Cancel"
		);
		mbox.result.then(
			() => {
				mapsRepository.remove($scope.project.id, map.id).then(() => {
					for(let i = 0; i < $scope.maps.length; i++) {
						if($scope.maps[i].id === map.id) {
							$scope.maps.splice(i, 1);
						}
					}
				});
			}
		);
	};

	$scope.moveMaps = function () {
		if($scope.isUploadInProgress) {
			return;
		}
		$scope.isUploadInProgress = true;
		mapsRepository.saveAllMaps($scope.project.id, $scope.maps).then(() => {
			$scope.isUploadInProgress = false;
		}, error => {
			notification.error(error);
			$scope.isUploadInProgress = false;
		});
	};
}
