angular.module("eShareApp").controller("AttributeVisibilityCtrl", [
	"$state", "$scope", "$timeout", "$window", "notification", "attributeDefinitionRepository",
	"attributeDefinitions", "messageBox", "dirtyFormTrackingService", "hideEmptyAttributes",

	function (
		$state, $scope, $timeout, $window, notification, attributeDefinitionRepository,
		attributeDefinitions, messageBox, dirtyFormTrackingService, hideEmptyAttributes
	) {

		$scope.attributeDefinitions = _.sortBy(
			attributeDefinitions, ["displayPriority", "displayName"]
		);
		$scope.attributeDefinitions.forEach(row => {
			row.isSelected = false;
		});
		const originalDefinitions = _.cloneDeep($scope.attributeDefinitions);
		$scope.selectedRows = [];
		$scope.headerBox = {
			isSet: false,
			isIndeterminate: false,
		};
		$scope.settings = {
			hideEmptyAttributes: hideEmptyAttributes,
		};

		function save() {
			const defaultAttributes = _.sortBy($scope.attributeDefinitions, ["displayName"]);
			const match = _.find(defaultAttributes, (item, index) => {
				return item !== $scope.attributeDefinitions[index];
			});
			if(match) {
				$scope.attributeDefinitions.forEach((item, index) => {
					item.displayPriority = -($scope.attributeDefinitions.length - index);
				});
			} else {
				$scope.attributeDefinitions.forEach(item => {
					item.displayPriority = 0;
				});
			}
			attributeDefinitionRepository
				.save($state.params.projectId, $scope.attributeDefinitions)
				.then(() => {
					notification.success("Attribute visibility saved successfully");
					$scope.formScope.form && $scope.formScope.form.$setPristine();
					$timeout(() => {
						$state.go("^", {}, { reload: true });
					}, 0);
				}, errorMessage => {
					notification.error("Error saving attribute visibility: " + errorMessage);
				});
			attributeDefinitionRepository.saveHideEmptyAttributes(
				$state.params.projectId,
				$scope.settings.hideEmptyAttributes
			);
		}

		function cancel() {
			clearSelection();
			if(angular.toJson(originalDefinitions) === angular.toJson($scope.attributeDefinitions)
				&& hideEmptyAttributes === $scope.settings.hideEmptyAttributes) {
				$scope.formScope.form.$setPristine();
			} else {
				$scope.formScope.form.$setDirty();
			}
			$timeout(() => {
				$state.go("^", {}, { reload: true });
			}, 0);
		}

		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		function resetOrder() {
			const mbox = messageBox.openQuestion(
				"Reset Order?",
				"Are you sure you want to reset the attribute order?"
				+ " This will revert the order back to default (alphabetical).",
				"Reset", "Cancel"
			);
			mbox.result.then(() => {
				$scope.attributeDefinitions = _.sortBy($scope.attributeDefinitions, ["displayName"]);
				$scope.attributeDefinitions.forEach(row => {
					row.isSelected = false;
				});
				$scope.selectedRows.splice(0, $scope.selectedRows.length);
			});
		}

		function onCheckBoxClicked(event) {
			event.stopPropagation();
			$scope.formScope.form.$setDirty();
		}

		function onOrderChanged() {
			$scope.formScope.form.$setDirty();
		}

		function toggleSelectAll() {
			$timeout(() => {
				$scope.attributeDefinitions.forEach(column => {
					if($scope.headerBox.isSet) {
						column.isVisible = true;
					} else {
						column.isVisible = false;
					}
				}, 0);
			});
			$scope.formScope.form.$setDirty();
		}

		function onCheckBoxChanged(index) {
			if(_.find($scope.selectedRows, item => {
				return item === $scope.attributeDefinitions[index];
			}) != null) {
				const isVisible = $scope.attributeDefinitions[index].isVisible;
				$scope.selectedRows.forEach(row => {
					row.isVisible = isVisible;
				});
			} else {
				clearSelection();
			}
			refreshHeaderBox();
			$scope.formScope.form.$setDirty();
		}

		function clearSelection() {
			$scope.attributeDefinitions.forEach(row => {
				row.isSelected = false;
			});
			$scope.selectedRows.splice(0, $scope.selectedRows.length);
		}

		function refreshHeaderBox() {
			let areNoneVisible = true;
			let areAllVisible = true;

			$scope.attributeDefinitions.forEach(column => {
				if(column.isVisible && areNoneVisible) {
					areNoneVisible = false;
				} else if(!column.isVisible && areAllVisible) {
					areAllVisible = false;
				}
			});

			if(areNoneVisible === areAllVisible) {
				$scope.headerBox.isIndeterminate = true;
			} else if(areNoneVisible) {
				$scope.headerBox.isIndeterminate = false;
				$scope.headerBox.isSet = false;
			} else if(areAllVisible) {
				$scope.headerBox.isIndeterminate = false;
				$scope.headerBox.isSet = true;
			}

			const box = document.querySelector("#headerBox");
			if(box) {
				box.indeterminate = $scope.headerBox.isIndeterminate;
			}
		}

		$scope.sortAlphabetically = function () {
			const items = $scope.attributeDefinitions;
			const temp = _.sortBy(items, "displayName");
			for(let i = 0; i < items.length; i++) {
				items[i] = temp[i];
			}
			onOrderChanged();
		};

		$scope.sortByVisibility = function () {
			const items = $scope.attributeDefinitions;
			const temp = _.orderBy(items, "isVisible", "desc");
			for(let i = 0; i < items.length; i++) {
				items[i] = temp[i];
			}
			onOrderChanged();
		};

		$scope.onCheckBoxClicked = onCheckBoxClicked;
		$scope.onCheckBoxChanged = onCheckBoxChanged;
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.resetOrder = resetOrder;
		$scope.toggleSelectAll = toggleSelectAll;
		$scope.refreshHeaderBox = refreshHeaderBox;
		$scope.onOrderChanged = onOrderChanged;
	}
]);
