angular.module("eShareApp").controller("eGoSettingsCtrl", eGoSettingsCtrl);

eGoSettingsCtrl.$inject = [
	"$scope", "$state", "project", "eGoSettingsRepository", "notification", "isSysAdmin"
];

function eGoSettingsCtrl(
	$scope, $state, project, eGoSettingsRepository, notification, isSysAdmin
) {

	$scope.project = project;
	$scope.qrParsing = {
		script: "",
		testData: "",
	};
	$scope.isSysAdmin = isSysAdmin;

	eGoSettingsRepository.getSettings($scope.project.id).then(
		settings => {
			if(settings) {
				$scope.qrParsing.script = settings.qrScript;
			}
		}
	);

	$scope.executeQuery = function () {
		eGoSettingsRepository
			.executeQuery($scope.project.id, $scope.qrParsing.script, $scope.qrParsing.testData)
			.then(data => {
				if(data) {
					$scope.isScriptCompiled = true;
					$scope.scriptCompiledSuccessfully = data.isCompiled;
					$scope.scriptCompilationErrors = data.errors;
					$scope.queryResult = data;
					checkForOutputErrors($scope.queryResult);
				}
			}, error => {
				notification.error("Error testing script: " + error);
			});
	};

	function checkForOutputErrors(data) {
		if(data.key) {
			if(data.abbreviation || data.value) {
				$scope.testOutputError = "The output should not receive values for both"
				+ " Key and Attribute Abbreviation/Value";
				return;
			}
		} else if(data.abbreviation) {
			if(!data.value) {
				$scope.testOutputError = "Attribute Value missing";
				return;
			}
		} else if(data.value) {
			if(!data.abbreviation) {
				$scope.testOutputError = "Attribute Abbreviation missing";
				return;
			}
		} else if(
			!data.cameraX
			&& !data.cameraY
			&& !data.cameraZ
			&& !data.cameraSlope
			&& !data.cameraRotation
		) {
			$scope.testOutputError = "The output received no values";
			return;
		}
		if((data.cameraX || data.cameraY || data.cameraZ || data.cameraSlope || data.cameraRotation)
				&& !(data.cameraX && data.cameraY && data.cameraZ)) {
			$scope.testOutputError = "Coordinates should always hold value for each axis (x, y, z)";
			return;
		}
		$scope.testOutputError = "";
	}

	$scope.aceLoaded = function (editor) {
		//Make editor disabled
		editor.setReadOnly(true);
		editor.container.style.opacity = 0.5;
		$scope.isFocusDropped = true;
		editor.setShowPrintMargin(false);
		editor.setOptions({
			autoScrollEditorIntoView: true,
			minLines: 4,
			maxLines: 30,
		});
		setPlaceholderCode(editor);
		editor.on("focus", () => {
			$scope.isFocusDropped = false;
		});
		editor.renderer.setScrollMargin(4, 4);
		//Enable editor if the user is system admin
		if($scope.isSysAdmin) {
			editor.setReadOnly(false);
			editor.container.style.opacity = 1;
		}
	};

	$scope.aceChanged = function (e) {
		const editor = e[1];
		setPlaceholderCode(editor);
	};

	$scope.aceBlurred = function () {
		$scope.isFocusDropped = true;
	};

	function setPlaceholderCode(editor) {
		const shouldShow = !editor.session.getValue().length;
		let node = editor.renderer.emptyMessageNode;
		if(!shouldShow && node) {
			editor.renderer.scroller.removeChild(editor.renderer.emptyMessageNode);
			editor.renderer.emptyMessageNode = null;
		} else if(shouldShow && !node) {
			node = editor.renderer.emptyMessageNode = document.createElement("div");
			node.style.cssText = "padding: 4px 4px;  white-space: pre;";
			node.textContent = "Example: \n"
			+ "result.Abbreviation = qrCode.Substring(0,3);\n"
			+ "result.Value = qrCode.Substring(4);";
			node.className = "ace_invisible ace_emptyMessage";
			editor.renderer.scroller.appendChild(node);
		}
	}

	$scope.saveTransformations = function () {
		eGoSettingsRepository.saveSettings($scope.project.id, $scope.qrParsing.script).then(() => {
			$state.go("^", {}, { reload: true });
		}, error => {
			notification.error(error);
		});
	};
}
