angular.module("eShareApp")
	.controller("ProjectUsersCtrl", [
		"$rootScope", "$state", "$scope", "$filter", "project", "users", "permissions", "isSysAdmin",
		"permissionsRepository", "messageBox", "notification", "groups", "groupPermissions",

		function (
			$rootScope, $state, $scope, $filter, project, users, permissions, isSysAdmin,
			permissionsRepository, messageBox, notification, groups, groupPermissions
		) {

			$scope.project = project;
			$scope.users = users;
			$scope.selectedProjectUser = {};
			$scope.selectedAllUser = {};
			$scope.permissions = permissions;
			$scope.projectUsers = [];
			$scope.allUsers = [];

			$scope.groups = groups;
			$scope.groupPermissions = groupPermissions;
			$scope.projectGroups = [];
			$scope.allGroups = [];
			$scope.isSysAdmin = isSysAdmin;
			$scope.availableUsersOpen = false;
			$scope.state = $state;

			$scope.addUserToProject = function (user) {
				const permission = {
					userAccountId: user.id,
					projectId: $scope.project.id,
					isAdmin: false,
				};
				permissionsRepository
					.add(permission)
					.then(() => {
						$scope.permissions = permissionsRepository.getByProject($scope.project.id)
							.then(newPermissions => {
								$scope.permissions = newPermissions;
								addPermissions(newPermissions);
							}, error => {
								notification.error(error);
							});
					}, error => {
						notification.error(error);
					});
			};

			$scope.addGroupToProject = function (group) {
				const permission = {
					id: null,
					userGroupId: group.id,
					projectId: $scope.project.id,
					isAdmin: false,
				};
				permissionsRepository
					.addGroup(permission)
					.then(() => {
						$scope.groupPermissions = permissionsRepository
							.getByProjectForGroups($scope.project.id)
							.then(newPermissions => {
								$scope.groupPermissions = newPermissions;
								addGroupPermissions(newPermissions);
							}, error => {
								notification.error(error);
							});
					}, error => {
						notification.error(error);
					});
			};

			$scope.removeUserFromProject = function (user) {
				permissionsRepository.remove(user.permission)
					.then(() => {
						$scope.permissions = permissionsRepository
							.getByProject($scope.project.id)
							.then(newPermissions => {
								$scope.permissions = newPermissions;
								addPermissions(newPermissions);
							}, error => {
								notification.error(error);
							});
					}, error => {
						notification.error(error);
					});
			};

			$scope.removeGroupFromProject = function (group) {
				permissionsRepository.removeForGroup(group.permission)
					.then(() => {
						$scope.groupPermissions = permissionsRepository
							.getByProjectForGroups($scope.project.id)
							.then(newPermissions => {
								$scope.groupPermissions = newPermissions;
								addGroupPermissions(newPermissions);
							}, error => {
								notification.error(error);
							});
					}, error => {
						notification.error(error);
					});
			};

			$scope.adminChanged = function (user) {
				permissionsRepository.update(user.permission)
					.then(() => {
						$scope.permissions = permissionsRepository
							.getByProject($scope.project.id)
							.then(newPermissions => {
								$scope.permissions = newPermissions;
								addPermissions(newPermissions);
							}, error => {
								notification.error(error);
							});
					}, error => {
						notification.error(error);
					});
			};

			$scope.groupAdminChanged = function (group) {
				permissionsRepository.updateForGroup(group.permission)
					.then(() => {
						$scope.permissions = permissionsRepository
							.getByProjectForGroups($scope.project.id)
							.then(newPermissions => {
								$scope.groupPermissions = newPermissions;
								addGroupPermissions(newPermissions);
							}, error => {
								notification.error(error);
							});
					}, error => {
						notification.error(error);
					});
			};

			function addPermissions(newPermissions) {
				const permissionDictionary = {};
				for(const p in newPermissions) {
					if(newPermissions.hasOwnProperty(p)) {
						const permission = newPermissions[p];
						permissionDictionary[permission.userAccountId] = permission;
					}
				}
				$scope.projectUsers = [];
				$scope.allUsers = [];
				if($scope.users) {
					for(const u in $scope.users) {
						if($scope.users.hasOwnProperty(u)) {
							const user = $scope.users[u];
							if(permissionDictionary[user.id] !== undefined) {
								user.permission = permissionDictionary[user.id];
								$scope.projectUsers.push(user);
							} else {
								user.permission = {};
								$scope.allUsers.push(user);
							}
						}
					}
				}
			}

			function addGroupPermissions(newGroupPermissions) {
				const groupPermissionDictionary = {};
				for(const p in newGroupPermissions) {
					if(newGroupPermissions.hasOwnProperty(p)) {
						const permission = newGroupPermissions[p];
						groupPermissionDictionary[permission.userGroupId] = permission;
					}
				}
				$scope.projectGroups = [];
				$scope.allGroups = [];
				if($scope.groups) {
					for(const g in $scope.groups) {
						if($scope.groups.hasOwnProperty(g)) {
							const group = $scope.groups[g];
							if(groupPermissionDictionary[group.id] !== undefined) {
								group.permission = groupPermissionDictionary[group.id];
								$scope.projectGroups.push(group);
							} else {
								group.permission = {};
								$scope.allGroups.push(group);
							}
						}
					}
				}
			}

			addPermissions($scope.permissions);
			addGroupPermissions($scope.groupPermissions);

		}
	]);
