angular.module("eShareApp").controller("AdministrationCtrl", [
	"$scope", "$state", "$stateParams", "permissionsRepository", "project",

	function (
		$scope, $state, $stateParams, permissionsRepository, project
	) {

		$scope.state = $state;
		$scope.isSysAdmin = window.eShare.currentUser.role === "sysadmin";
		$scope.project = project;
	}
]);
