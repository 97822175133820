angular.module("eShare.ui").factory("helpService", helpServiceFactory);

helpServiceFactory.$inject = [];

function helpServiceFactory() {
	const isEnabledStorageItemName = "help.isEnabled";
	const levelStorageItemName = "help.level";
	let isEnabled = true;
	let level = 1;

	initialize();

	return {
		toggleIsEnabled: toggleIsEnabled,
		getIsEnabled: getIsEnabled,
		setIsEnabled: setIsEnabled,
		getLevel: getLevel,
		setLevel: setLevel,
		isVisible: isVisible,
	};

	function initialize() {
		setIsEnabled(window.localStorage.getItem(isEnabledStorageItemName));
		setLevel(window.localStorage.getItem(levelStorageItemName));
	}

	function toggleIsEnabled() {
		setIsEnabled(!isEnabled);
	}

	function getIsEnabled() {
		return isEnabled;
	}

	function setIsEnabled(value) {
		isEnabled = Utilities.isNullOrUndefined(value) || Utilities.toBool(value);
		window.localStorage.setItem(isEnabledStorageItemName, isEnabled.toString());
	}

	function getLevel() {
		return level;
	}

	function setLevel(value) {
		value = Utilities.parseIntOrDefault(value, 1);
		level = Math.max(value, 1);
		window.localStorage.setItem(levelStorageItemName, level.toString());
	}

	function isVisible(levelToCheck) {
		levelToCheck = Utilities.parseIntOrDefault(levelToCheck, level);
		return isEnabled && (levelToCheck === 0 || levelToCheck >= level);
	}
}
