angular.module("eShareApp").directive("cadNavBar", [
	"$state", "$timeout", "searchService", "notification", "helpService",
	function ($state, $timeout, searchService, notification, helpService) {
		return {
			restrict: "E",
			scope: { project: "=", hideSearch: "=", showProfile: "=" },
			replace: true,
			transclude: true,
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadNavBar.html'),
			link: function (scope /*, element, attrs*/) {
				scope.user = window.eShare.currentUser;
				scope.searchRequest = { text: "" };
				scope.searchResults = searchService.result;
				scope.help = helpService;
				scope.getSerializedTerms = function () {
					return searchService.serializeTerms(scope.searchResults.request.terms);
				};
				scope.isSearchStateActive = function () {
					return $state.current.name === "project.search";
				};
				scope.search = function () {
					let searchRequest = null;
					let text = scope.searchRequest.text || "";
					if(typeof text !== "string" || text.trim() === "") {
						text = "";
						if(searchService.result.hasResults()) {
							searchRequest = searchService.result.request;
						}
					}
					searchRequest = searchRequest || {
						projectId: scope.project.id,
						isAdvanced: false,
						text: text,
						scope: "IdOnly",
						terms: [],
						searchTarget: "Objects",
						shouldGetGeometryIds: false,
					};
					scope.searchRequest.text = "";
					searchService.search(searchRequest, null).then(
						result => {
							scope.searchResults = result;
							if((result.objects.length + result.pointsOfInterest.length) === 1
								&& text !== "") {
								if(result.objects.length > 0) {
									const object = result.objects[0];
									if(object.groupDefinition) {
										$state.go("project.model", {
											projectId: scope.project.id,
											groupParentTag: object.groupDefinition.parentTag,
											groupChildTag: object.groupDefinition.childTag,
											groupId: object.keyAttribute.value,
										}, {
											reload: true,
											inherit: false,
										});
									} else {
										if(object.keyAttribute) {
											$state.go("project.model", {
												projectId: scope.project.id,
												positionId: object.keyAttribute.value,
												tag: object.keyAttribute.tag,
												geometryId: object.geometryId,
												modelTimestamp: result.modelTimestamp,
											}, {
												reload: true,
												inherit: false,
											});
										} else {
											$state.go("project.model", {
												projectId: scope.project.id,
												geometryId: object.geometryId,
												modelTimestamp: result.modelTimestamp,
											}, {
												reload: true,
												inherit: false,
											});
										}
									}
								} else {
									const pointOfInterest = result.pointsOfInterest[0];
									$state.go("project.model", {
										projectId: scope.project.id,
										pointId: pointOfInterest.id,
									}, {
										reload: true,
										inherit: false,
									});
								}
							} else {
								$state.go("project.search", {
									projectId: searchRequest.projectId,
									isAdvanced: searchRequest.isAdvanced,
									text: searchRequest.text,
									scope: searchRequest.scope,
									terms: searchService.serializeTerms(searchRequest.terms),
								}, {
									reloadOnSearch: true,
								}, {
									inherit: false,
								});
							}
						},
						reason => {
							notification.error("Search failed: " + Utilities.getErrorMessage(reason));
						}
					);
				};
				scope.goToSearch = function () {
					scope.searchRequest.text = "";
					let searchRequest = null;
					if(searchService.result.hasResults()) {
						searchRequest = searchService.result.request;
					}
					searchRequest = searchRequest || {
						projectId: scope.project.id,
						isAdvanced: false,
						text: "",
						scope: "IdOnly",
						terms: [],
					};
					$state.go("project.search", {
						projectId: searchRequest.projectId,
						isAdvanced: searchRequest.isAdvanced,
						text: searchRequest.text,
						scope: searchRequest.scope,
						terms: searchService.serializeTerms(searchRequest.terms),
						searchTarget: searchRequest.searchTarget,
					}, {
						reloadOnSearch: true,
					}, {
						inherit: false,
					});
				};
				scope.toggleHelp = function () {
					helpService.toggleIsEnabled();
				};
				scope.openDocumentation = function () {
					const state = $state.current.name;
					let id = "DEFAULT";
					if(state.startsWith("project.model")) {
						id = "MODEL";
					}
					if(state.startsWith("project.maps")) {
						id = "MAP";
					}
					if(state.startsWith("project.document")) {
						id = "DOCUMENTS";
					}
					if(state.startsWith("project.search")) {
						id = "SEARCH";
					}
					if(state.startsWith("project.admin") || state.startsWith("admin")) {
						id = "ADMIN";
					}
					const helpUrl = "/docs/HTML5/Index.htm#cshid=ES_VIEW_" + id;
					$timeout(() => {
						window.open(helpUrl, "_cadmaticeShareDocumentation");
					}, 1);
				};
			},
		};
	}
]);