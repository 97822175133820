angular.module("eShareApp").directive("cadUnique",
	() => {
		return {
			require: "ngModel",
			link: function (scope, elem, attrs, ctrl) {
				scope.$watch(attrs.cadUnique, newUnique => {
					const filter = {};
					filter[attrs.cadUniqueAttribute] = ctrl.$viewValue;
					const filtered = _.filter(newUnique, filter);
					const count = filtered.length;
					ctrl.$setValidity("unique", count <= 1);
				}, true);
			},
		};
	});