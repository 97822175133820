angular.module("eShare.ui").directive("cadSplitter", cadSplitterDirective);

cadSplitterDirective.$inject = ["$rootScope"];

function cadSplitterDirective($rootScope) {
	//options = {
	// context?: any,
	// orientation?: "horizontal" | "vertical",
	// initialSizeBefore?: number | string, // 42, "42%"
	// initialSizeAfter?: number | string, // 42, "42%"
	// storageKey?: string,
	// beforeMin: number | string, // 42, "42%"
	// beforeMax: number | string, // 42, "42%"
	// afterMin: number | string, // 42, "42%"
	// afterMax: number | string, // 42, "42%"
	// resizeOnDrag: boolean,
	// onDragStart: function (data),
	// onDrag: function (data),
	// onDragEnd: function (data),
	// onResize: function (data),
	//}

	return {
		restrict: "E",
		scope: {
			options: "=",
		},
		link: link,
		replace: true,
		template: "<div class=\"cad-splitter\"></div>",
	};

	function link(scope, element /*, attrs*/) {
		const containerElement = element[0].parentElement;
		let elementBefore = null;
		let elementAfter = null;
		const splitterElement = element;
		let dragElement = null;

		const splitterSize = 7; // constant

		let containerSize = 0;
		let contentSize = 0;
		let previousContainerSize = NaN;

		let dragPosition = 0;
		let sizeBefore = 200;

		let isHorizontal = true;
		let cssSizePropertyName = "";
		let cssOriginPropertyName = "";

		$rootScope.$on("splitter::refresh", (/*event, next, current*/) => {
			onContainerResized();
		});

		initialize();

		function initialize() {
			isHorizontal = Utilities.trimOrNullIfEmpty(getOption("orientation", null)) !== "vertical";
			cssSizePropertyName = isHorizontal ? "width" : "height";
			cssOriginPropertyName = isHorizontal ? "left" : "top";
			if(!setupElements()) {
				console.error("Splitter element expects to have two sibiling containers");
				return;
			}
			syncContainerSizes();
			setupStyles();
			const size = loadSizeBefore();
			setSizeBefore(size, true);
			if(Utilities.isObject(scope.options)) {
				scope.options.resizeBefore = onResizeBeforeRequested;
				scope.options.resizeAfter = onResizeAfterRequested;
				scope.options.ensureBeforeSize = onEnsureBeforeSize;
				scope.options.ensureAfterSize = onEnsureAfterSize;
				scope.options.minimizeBefore = minimizeBefore;
				scope.options.minimizeAfter = minimizeAfter;
				scope.options.restore = restore;
				if(scope.options.isAfterMinimized === true) {
					minimizeAfter();
				}
			}
			ResizeListener.add(containerElement, onContainerResized);
			splitterElement.on("mousedown", mouseDown);

			function setupElements() {
				const numberOfChildren = containerElement.children.length;
				if(numberOfChildren < 3) {
					return false;
				}
				for(let i = 0; i < 3; ++i) {
					const e = containerElement.children[i];
					if(e.classList.contains("cad-splitter")) {
						continue;
					}
					if(!elementBefore) {
						elementBefore = $(e);
						continue;
					}
					if(!elementAfter) {
						elementAfter = $(e);
						break;
					}
				}
				if(!elementAfter) {
					elementBefore = null;
					return false;
				}
				const dragElementDiv = document.createElement("div");
				dragElement = $(dragElementDiv);
				dragElement.addClass("cad-splitter");
				dragElement.addClass("drag");
				dragElement.hide();
				containerElement.insertAdjacentElement("beforeend", dragElementDiv);
				return true;
			}

			function setupStyles() {
				setParentStyle();
				setSplitterStyle();
				setElementBeforeStyle();
				setElementAfterStyle();

				function setParentStyle() {
					const parentStyle = {
						overflow: "hidden",
					};
					$(containerElement).css(parentStyle);
				}

				function setSplitterStyle() {
					let splitterStyle;
					if(isHorizontal) {
						splitterStyle = {
							cursor: "e-resize",
							position: "absolute",
							left: "0",
							width: splitterSize + "px",
							top: "0",
							bottom: "0",
						};
					} else {
						splitterStyle = {
							cursor: "n-resize",
							position: "absolute",
							left: "0",
							right: "0",
							top: "0",
							height: splitterSize + "px",
						};
					}
					splitterElement.css(splitterStyle);
					dragElement.css(splitterStyle);
				}

				function setElementBeforeStyle() {
					const elementBeforeStyle = {
						position: "absolute",
						left: "0",
						width: "0",
						top: "0",
						bottom: "0",
						overflow: "hidden",
					};
					elementBefore.css(elementBeforeStyle);
				}

				function setElementAfterStyle() {
					const elementAfterStyle = {
						position: "absolute",
						left: "0",
						right: "0",
						top: "0",
						bottom: "0",
						overflow: "hidden",
					};
					elementAfter.css(elementAfterStyle);
				}
			}
		}

		function syncContainerSizes() {
			containerSize = isHorizontal
				? containerElement.clientWidth
				: containerElement.clientHeight;
			contentSize = containerSize - splitterSize;
		}

		function mouseDown(event) {
			event.preventDefault();
			event.stopPropagation();
			document.body.style["pointer-events"] = "none";
			if(event.target.setCapture) {
				event.target.setCapture();
			}
			window.addEventListener("mousemove", mouseMove, true);
			window.addEventListener("mouseup", mouseUp, true);
			onDragStart();
		}

		function mouseMove(event) {
			event.preventDefault();
			event.stopPropagation();
			const pointerPosition = isHorizontal
				? event.pageX - containerElement.offsetLeft
				: window.innerHeight - event.pageY;
			onDrag(pointerPosition);
		}

		function mouseUp(event) {
			event.preventDefault();
			event.stopPropagation();
			document.body.style["pointer-events"] = "auto";
			window.removeEventListener("mousemove", mouseMove, true);
			window.removeEventListener("mouseup", mouseUp, true);
			if(document.releaseCapture) {
				document.releaseCapture();
			}
			onDragEnd();
		}

		function onDragStart() {
			dragPosition = sizeBefore;
			splitterElement.addClass("is-dragged");
			dragElement.show();
			repositionDragElement();
			const onDragStartCallback = getOption("onDragStart", null);
			if(Utilities.isFunction(onDragStartCallback)) {
				const data = getCallbackData(dragPosition);
				onDragStartCallback(data);
			}
		}

		function onDrag(pointerPosition) {
			let desiredSizeBefore = Math.round(pointerPosition - splitterSize / 2);
			desiredSizeBefore = normalizeSizeBefore(desiredSizeBefore);
			dragPosition = desiredSizeBefore;
			repositionDragElement();
			if(getOption("resizeOnDrag", false)) {
				setSizeBefore(desiredSizeBefore, true);
			}
			const onDragCallback = getOption("onDrag", null);
			if(Utilities.isFunction(onDragCallback)) {
				const data = getCallbackData(dragPosition);
				onDragCallback(data);
			}
		}

		function onDragEnd() {
			splitterElement.removeClass("is-dragged");
			dragElement.hide();
			setSizeBefore(dragPosition, true);
			const onDragEndCallback = getOption("onDragEnd", null);
			if(Utilities.isFunction(onDragEndCallback)) {
				const data = getCallbackData(dragPosition);
				onDragEndCallback(data);
			}

		}

		function onResizeBeforeRequested(requestedSizeBefore) {
			requestedSizeBefore = parseSize(requestedSizeBefore);
			if(!isNaN(requestedSizeBefore)) {
				setSizeBefore(requestedSizeBefore, true);
			}
		}

		function onResizeAfterRequested(requestedSizeAfter) {
			requestedSizeAfter = parseSize(requestedSizeAfter);
			if(!isNaN(requestedSizeAfter)) {
				const requestedSizeBefore = getOtherSize(requestedSizeAfter);
				setSizeBefore(requestedSizeBefore, true);
			}
		}

		function onEnsureBeforeSize(requestedSizeBefore) {
			requestedSizeBefore = parseSize(requestedSizeBefore);
			if(!isNaN(requestedSizeBefore)) {
				if(sizeBefore < requestedSizeBefore) {
					setSizeBefore(requestedSizeBefore, true);
				}
			}
		}

		function onEnsureAfterSize(requestedSizeAfter) {
			requestedSizeAfter = parseSize(requestedSizeAfter);
			if(!isNaN(requestedSizeAfter)) {
				const sizeAfter = getOtherSize(sizeBefore);
				if(sizeAfter < requestedSizeAfter) {
					const requestedSizeBefore = getOtherSize(requestedSizeAfter);
					setSizeBefore(requestedSizeBefore, true);
				}
			}
		}

		function setSizeBefore(size, shouldSave) {
			size = normalizeSizeBefore(size);
			if(size === sizeBefore && previousContainerSize === containerSize) {
				return;
			}
			sizeBefore = size;
			previousContainerSize = containerSize;
			if(shouldSave) {
				saveSizeBefore();
			}
			resizeElementBefore();
			repositionSplitter();
			resizeElementAfter();
			const onResizeCallback = getOption("onResize", null);
			if(Utilities.isFunction(onResizeCallback)) {
				const data = getCallbackData(sizeBefore);
				onResizeCallback(data);
			}

			function resizeElementBefore() {
				const style = makeObjectWithProperty(cssSizePropertyName, sizeBefore + "px");
				elementBefore.css(style);
			}

			function repositionSplitter() {
				const style = makeObjectWithProperty(cssOriginPropertyName, sizeBefore + "px");
				splitterElement.css(style);
			}

			function resizeElementAfter() {
				const style = makeObjectWithProperty(
					cssOriginPropertyName, (sizeBefore + splitterSize) + "px"
				);
				elementAfter.css(style);
			}
		}

		function getCallbackData(desiredSizeBefore) {
			const desiredSizeAfter = getOtherSize(desiredSizeBefore);
			const data = {
				context: getOption("context"),
				sizeBefore: desiredSizeBefore,
				sizeAfter: desiredSizeAfter,
			};
			return data;
		}

		function makeObjectWithProperty(propertyName, value) {
			const o = {};
			o[propertyName] = value;
			return o;
		}

		function repositionDragElement() {
			const style = makeObjectWithProperty(cssOriginPropertyName, dragPosition + "px");
			dragElement.css(style);
		}

		function onContainerResized(/*data*/) {
			const proportion = sizeBefore / contentSize;
			syncContainerSizes();
			const size = Math.round(proportion * contentSize);
			setSizeBefore(size, true);
		}

		function getOption(name, defaultValue) {
			const value = (scope.options && scope.options[name]) || defaultValue;
			return value;
		}

		function normalizeSize(size) {
			size = Math.round(size);
			return Utilities.clampToRange(size, 0, contentSize);
		}

		function normalizeSizeBefore(size) {
			const minMax = getBeforeMinMax();
			size = Utilities.clampToRange(size, minMax.minSize, minMax.maxSize);
			return size;
		}

		function getOtherSize(size) {
			return isNaN(size)
				? NaN
				: normalizeSize(contentSize - size);
		}

		function getStorageKey() {
			return Utilities.trimOrNullIfEmpty(getOption("storageKey", ""));
		}

		function saveSizeBefore() {
			const storageKey = getStorageKey();
			if(storageKey) {
				const sizeBeforeInPercents = Math.round((sizeBefore / contentSize * 100) * 100) / 100;
				const sizeBeforeInPercentsStr = sizeBeforeInPercents.toFixed(2) + "%";
				Utilities.setLocalStorageItem(storageKey, sizeBeforeInPercentsStr);
			}
		}

		function loadSizeBefore() {
			let size;
			const storageKey = getStorageKey();
			let initialSizeBefore = NaN;
			if(storageKey) {
				const savedSizeBeforeInPercents = Utilities.getLocalStorageItem(storageKey, null, true);
				initialSizeBefore = parseSize(savedSizeBeforeInPercents);
			}
			if(isNaN(initialSizeBefore)) {
				initialSizeBefore = getSizeOption("initialSizeBefore");
			}
			if(isNaN(initialSizeBefore)) {
				const initialSizeAfter = getSizeOption("initialSizeAfter");
				if(isNaN(initialSizeAfter)) {
					size = contentSize / 2;
				} else {
					size = getOtherSize(initialSizeAfter);
				}
			} else {
				size = initialSizeBefore;
			}
			return normalizeSize(size);
		}

		function parseSize(value) {
			let size = NaN;
			if(Utilities.isString(value)) {
				let strSize = Utilities.trimOrNullIfEmpty(value);
				if(strSize) {
					const isPercent = strSize.endsWith("%");
					if(isPercent) {
						strSize = strSize.substring(0, strSize.length - 1);
						let sizeInPercents = parseFloat(strSize);
						sizeInPercents = Utilities.clampToRange(sizeInPercents, 0, 100);
						size = contentSize * sizeInPercents / 100;
					} else {
						size = parseFloat(strSize);
					}
				}
			} else if(Utilities.isNumber(value)) {
				size = value;
			}
			return isNaN(size)
				? NaN
				: normalizeSize(size);
		}

		function getSizeOption(optionName) {
			return parseSize(getOption(optionName, null));
		}

		function getBeforeMinMax() {
			const beforeMin = getSizeOption("beforeMin");
			const beforeMax = getSizeOption("beforeMax");
			const afterMin = getSizeOption("afterMin");
			const afterMax = getSizeOption("afterMax");
			const hasBeforeMin = !isNaN(beforeMin);
			const hasBeforeMax = !isNaN(beforeMax);
			const hasAfterMin = !isNaN(afterMin);
			const hasAfterMax = !isNaN(afterMax);
			const beforeMinDerived = getOtherSize(afterMax);
			const beforeMaxDerived = getOtherSize(afterMin);
			let beforeMinSize = NaN;
			let beforeMaxSize = NaN;
			if(hasBeforeMin) {
				if(hasAfterMax) {
					beforeMinSize = Math.max(beforeMin, beforeMinDerived);
				} else {
					beforeMinSize = beforeMin;
				}
			} else {
				if(hasAfterMax) {
					beforeMinSize = beforeMinDerived;
				}
			}
			if(hasBeforeMax) {
				if(hasAfterMin) {
					beforeMaxSize = Math.min(beforeMax, beforeMaxDerived);
				} else {
					beforeMaxSize = beforeMax;
				}
			} else {
				if(hasAfterMin) {
					beforeMaxSize = beforeMaxDerived;
				}
			}
			if(isNaN(beforeMinSize)) {
				beforeMinSize = 0;
			}
			if(isNaN(beforeMaxSize)) {
				beforeMaxSize = contentSize;
			}
			if(beforeMinSize > beforeMaxSize) {
				const size = Math.round((beforeMinSize + beforeMaxSize) / 2);
				beforeMinSize = size;
				beforeMaxSize = size;
			}
			return {
				minSize: beforeMinSize,
				maxSize: beforeMaxSize,
			};
		}

		function minimizeBefore() {
			setSizeBefore(0, false);
		}

		function minimizeAfter() {
			const requestedSizeBefore = getOtherSize(0);
			setSizeBefore(requestedSizeBefore, false);
			scope.options.isAfterMinimized = true;
		}

		function restore() {
			const beforeSize = loadSizeBefore();
			setSizeBefore(beforeSize, true);
			scope.options.isAfterMinimized = false;
		}
	}
}
