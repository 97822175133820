angular.module("eShareApp").factory("modelImportersRepository", modelImportersRepository);

modelImportersRepository.$inject = ["repositoryBase"];

function modelImportersRepository(repositoryBase) {
	const baseApi = "/api/project/{projectId}/ModelImporters/";

	return {
		getAllTypes: getAllTypes,
		get: get,
		create: create,
		remove: remove,
		update: update,
		getConfigurationScript: getConfigurationScript,
		getConfigurationTemplate: getConfigurationTemplate,
		exportAll: exportAll,
		importAll: importAll,
		importConfiguration: importConfiguration,
		getStatus: getStatus,
		getDates: getDates,
		setAutopublish: setAutopublish,
		setKeepModelsSeparate: setKeepModelsSeparate,
		setAddBoundingBoxCenterCoordinates: setAddBoundingBoxCenterCoordinates,
		setAddBoundingBoxMinCoordinates: setAddBoundingBoxMinCoordinates,
		setAddBoundingBoxMaxCoordinates: setAddBoundingBoxMaxCoordinates,
	};

	function getAllTypes(projectId) {
		const url = baseApi + "TypeInfos";
		const routeData = {
			projectId: projectId,
		};
		return repositoryBase.get(url, routeData)
			.then(data => {
				let modelImporterTypeInfos = Utilities.isArray(data) ? data : [];
				modelImporterTypeInfos = _.sortBy(modelImporterTypeInfos, modelImporterTypeInfo => {
					return modelImporterTypeInfo.name.toLowerCase();
				});
				return modelImporterTypeInfos;
			});
	}

	function get(projectId, modelImporterId) {
		const url = baseApi + "{modelImporterId}";
		const routeData = {
			projectId: projectId,
			modelImporterId: modelImporterId,
		};
		return repositoryBase.get(url, routeData);
	}

	function remove(projectId, modelImporterId) {
		const url = baseApi + "{modelImporterId}";
		const routeData = {
			projectId: projectId,
			modelImporterId: modelImporterId,
		};
		return repositoryBase.remove(url, routeData);
	}

	function getConfigurationScript(projectId, modelImporterId) {
		const url = baseApi + "{modelImporterId}/Script";
		const routeData = {
			projectId: projectId,
			modelImporterId: modelImporterId,
		};
		return repositoryBase.get(url, routeData)
			.then(data => {
				return data.script;
			});
	}

	function getConfigurationTemplate(projectId, modelImporterId) {
		const url = baseApi + "{modelImporterId}/Template";
		const routeData = {
			projectId: projectId,
			modelImporterId: modelImporterId,
		};
		return repositoryBase.get(url, routeData)
			.then(data => {
				return data.template;
			});
	}

	function create(projectId, modelImporterTypeGuid) {
		const url = baseApi + "{modelImporterTypeGuid}";
		const routeData = {
			projectId: projectId,
			modelImporterTypeGuid: modelImporterTypeGuid,
		};
		return repositoryBase.post(url, routeData);
	}

	function update(projectId, modelImporter) {
		const url = baseApi;
		const routeData = {
			projectId: projectId,
		};
		return repositoryBase.put(url, routeData, modelImporter);
	}

	function exportAll(projectId) {
		return repositoryBase.post(baseApi + "Export", { projectId: projectId }).then(
			data => {
				return JSON.stringify(data, undefined, 3);
			}
		);
	}

	function importAll(projectId, serializedExportedData, overwriteDefault) {
		const exportedData = JSON.parse(serializedExportedData);
		return repositoryBase.post(
			baseApi + "Import?overwriteDefault={overwriteDefault}",
			{ projectId: projectId, overwriteDefault: overwriteDefault },
			exportedData
		);
	}

	function importConfiguration(projectId, configuration, overwriteDefault) {
		return repositoryBase.post(
			baseApi + "ImportConfiguration?overwriteDefault={overwriteDefault}",
			{ projectId: projectId, overwriteDefault: overwriteDefault },
			configuration
		);
	}

	function getStatus(projectId) {
		return repositoryBase.get(baseApi + "Status", { projectId: projectId });
	}

	function getDates(projectId) {
		return repositoryBase.get(baseApi + "DateStatus", { projectId: projectId });
	}

	function setAutopublish(projectId, autopublish) {
		return repositoryBase.post(baseApi + "Autopublish", { projectId: projectId }, autopublish);
	}

	function setKeepModelsSeparate(projectId, keepModelsSeparate) {
		return repositoryBase.post(
			baseApi + "KeepModelsSeparate",
			{ projectId: projectId },
			keepModelsSeparate
		);
	}

	function setAddBoundingBoxCenterCoordinates(projectId, addBoundingBoxCenterCoordinates) {
		return repositoryBase.post(
			baseApi + "AddBoundingBoxCenterCoordinates",
			{ projectId: projectId },
			addBoundingBoxCenterCoordinates
		);
	}

	function setAddBoundingBoxMinCoordinates(projectId, addBoundingBoxMinCoordinates) {
		return repositoryBase.post(
			baseApi + "AddBoundingBoxMinCoordinates",
			{ projectId: projectId },
			addBoundingBoxMinCoordinates
		);
	}

	function setAddBoundingBoxMaxCoordinates(projectId, addBoundingBoxMaxCoordinates) {
		return repositoryBase.post(
			baseApi + "AddBoundingBoxMaxCoordinates",
			{ projectId: projectId },
			addBoundingBoxMaxCoordinates
		);
	}
}