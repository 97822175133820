angular.module("eShareApp").directive("cadUserProfile", cadUserProfile);
cadUserProfile.$inject = [];

function cadUserProfile() {
	return {
		restrict: "E",
		replace: true,
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadUserProfile.directive.html'),
		scope: {},
		bindToController: {
			projectId: "<",
			user: "<",
			canSeeMarkups: "<",
		},
		controllerAs: "vm",
		controller: cadUserProfileController,
	};
}

cadUserProfileController.$inject = [
	"$state", "$interval", "messageBox", "usersRepository", "markupsRepository", "groupsRepository",
	"searchService", "eBrowser", "notification", "projectsRepository"
];

function cadUserProfileController(
	$state, $interval, messageBox, usersRepository, markupsRepository, groupsRepository,
	searchService, eBrowser, notification, projectsRepository
) {
	const vm = this;

	const stateName = $state.current.name;

	vm.isNew = $state.current.data.isNew;
	vm.isEditing = vm.isNew;
	vm.isBusy = false;
	vm.isSysAdmin = window.eShare.currentUser.role === "sysadmin";
	vm.projectName = "";

	if(vm.projectId) {
		projectsRepository.getProjectNameById(vm.projectId).then(name => {
			vm.projectName = name;
		});
	}

	if($state.current.data.isNew) {
		vm.user = {
			name: "",
			login: "",
			isAdmin: false,
			isDeactivated: false,
			deactivationReason: null,
		};
	}

	const userCopy = _.cloneDeep(vm.user);

	vm.isModifyingSelf = vm.user.id === window.eShare.currentUser.id;

	let hasUserGroupsBeenFetched = false;

	if(!vm.isNew) {
		if(vm.projectId) {
			refreshMarkups();
		}
		groupsRepository.getForUser(vm.user.id)
			.then(userGroups => {
				vm.userGroups = userGroups;
				hasUserGroupsBeenFetched = true;
			});
	}

	if(vm.isSysAdmin) {
		groupsRepository.getAll()
			.then(allGroups => {
				vm.allGroups = allGroups;
				const interval = $interval(() => {
					if(hasUserGroupsBeenFetched || vm.isNew) {
						if(!vm.userGroups) {
							vm.userGroups = [];
						}
						vm.notUserGroups = [];
						_.forEach(vm.allGroups, group => {
							if(!(_.some(vm.userGroups, userGroup => {
								return group.id === userGroup.id;
							}))) {
								vm.notUserGroups.push(group);
							}
						});
						$interval.cancel(interval);
					}
				}, 100);
			});
	}

	usersRepository.getAllowUserNameChange().then(isAllowed => {
		vm.allowUserNameChange = isAllowed;
	});

	vm.isValid = function () {
		return vm.user.name && vm.user.login && vm.isUserNameValid() && vm.isLoginValid();
	};

	vm.isUserNameValid = function () {
		return angular.isString(vm.user.name)
			&& Utilities.betweenIncl(vm.user.name.trim().length, 1, 640);
	};

	vm.isLoginValid = function () {
		return angular.isString(vm.user.login)
			&& Utilities.betweenIncl(vm.user.login.trim().length, 1, 640);
	};

	vm.toggleEditMode = function () {
		vm.isEditing = !vm.isEditing;
	};

	vm.addToGroup = function () {
		if(vm.targetGroup) {
			groupsRepository.addUserToGroup(vm.user.id, vm.targetGroup.id)
				.then(() => {
					if(!vm.userGroups) {
						vm.userGroups = [];
					}
					vm.userGroups.push(_.cloneDeep(vm.targetGroup));
					for(let i = 0; i < vm.notUserGroups.length; i++) {
						const group = vm.notUserGroups[i];
						if(vm.targetGroup.id === group.id) {
							vm.notUserGroups.splice(i, 1);
						}
					}
					vm.targetGroup = null;
				});
		}
	};

	vm.removeFromGroup = function (group) {
		groupsRepository.removeUserFromGroup(vm.user.id, group.id)
			.then(() => {
				for(let i = 0; i < vm.userGroups.length; i++) {
					if(vm.userGroups[i].id === group.id) {
						vm.userGroups.splice(i, 1);
						vm.notUserGroups.push(group);
						return;
					}
				}
			});
	};

	function refreshMarkups() {
		markupsRepository.getLatestForUser(vm.projectId, vm.user.id)
			.then(userMarkups => {
				vm.userMarkups = userMarkups;
			});
	}

	vm.showCreatedMarkups = function () {
		searchService.clear();
		$state.go("project.search", {
			projectId: vm.projectId,
			isAdvanced: true,
			text: "",
			scope: "",
			terms: "((pointKind)):eq:-2" + "|((createdBy)):eq:" + vm.user.name,
			searchTarget: "Points",
		}, {
			reload: true,
			inherit: false,
		});
	};

	vm.showModifiedMarkups = function () {
		searchService.clear();
		$state.go("project.search", {
			projectId: vm.projectId,
			isAdvanced: true,
			text: "",
			scope: "",
			terms: "((pointKind)):eq:-2" + "|((modifiedBy)):wasEq:" + vm.user.name,
			searchTarget: "Points",
		}, {
			reload: true,
			inherit: false,
		});
	};

	vm.showAssignedMarkups = function () {
		searchService.clear();
		$state.go("project.search", {
			projectId: vm.projectId,
			isAdvanced: true,
			text: "",
			scope: "",
			terms: "((pointKind)):eq:-2" + "|((assigneeName)):eq:" + vm.user.name,
			searchTarget: "Points",
		}, {
			reload: true,
			inherit: false,
		});
	};

	vm.openMarkup = function (markup) {
		const markupId = markup.id;
		$state.go("project.model", { projectId: vm.projectId, markupId: markupId });
	};

	vm.removeMarkup = function (markup) {
		markupsRepository.remove(vm.projectId, markup.id)
			.then((/*data*/) => {
				eBrowser.deletePointFrom3D(markup.id);
				refreshMarkups();
			},
			(/*error*/) => {
				notification.error("Error deleting a markup");
			});
	};

	vm.editCommit = function () {
		vm.isBusy = true;
		let updateFunction;
		if($state.current.data.isNew) {
			updateFunction = usersRepository.add;
		} else {
			if(!vm.isSysAdmin && vm.isModifyingSelf && vm.allowUserNameChange) {
				updateFunction = usersRepository.updateName;
			} else if(vm.isSysAdmin) {
				updateFunction = usersRepository.update;
			} else {
				return;
			}
		}
		updateFunction(vm.user).then(
			() => {
				notification.success("Profile settings saved successfully");
				vm.isBusy = false;
				goBack();
			},
			message => {
				messageBox.openError(
					"Error creating / modifying user",
					"Error creating / modifying user: " + message
				);
				vm.isBusy = false;
			}
		);
	};

	vm.cancel = function () {
		vm.user = _.cloneDeep(userCopy);
		vm.isEditing = false;
		vm.isBusy = false;
	};

	function goBack() {
		if($state.current.name !== stateName) {
			return;
		}
		if(vm.isNew) {
			$state.go("admin.users", {}, { reload: true });
		}
		vm.isEditing = false;
	}
}