angular.module("eShareApp").directive("cadJsonSelector", cadJsonSelector);

cadJsonSelector.$inject = [];

function cadJsonSelector() {
	return {
		restrict: "E",
		scope: {},
		bindToController: {
			selector: "=",
			isAdvancedMode: "<",
			extraKeys: "<",
			usesForEach: "@",
			collapsePreview: "@",
			targetType: "<",
			targetJson: "<",
			isEditing: "<",
			onAddChild: "&?",
			previewAction: "&?",
		},
		controller: CadJsonSelectorController,
		controllerAs: "vm",
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadJsonSelector.html'),
		replace: false,
	};
}

CadJsonSelectorController.$inject = ["$scope", "$timeout"];

function CadJsonSelectorController($scope, $timeout) {
	const vm = this;

	// Data validation
	if(typeof vm.selector === "undefined") {
		throw new Error("cad-json-selector: selector missing");
	}
	if(typeof vm.selector.selectorString === "undefined") {
		throw new Error("cad-json-selector: selectorString missing");
	}
	if(typeof vm.isAdvancedMode === "undefined") {
		throw new Error("cad-json-selector: isAdvancedMode missing");
	}
	if(typeof vm.targetJson === "undefined") {
		vm.targetJson = "";
		// throw new Error("cad-json-selector: targetJson missing");
	}
	if(typeof vm.isEditing === "undefined") {
		throw new Error("cad-json-selector: isEditing missing");
	}

	// Initialize
	vm.rootSelectorDataTypes = ["All data", "Object/Array"];
	vm.childDataTypes = ["Property", "Array Element", "Condition"];
	vm.conditionTypes = ["NOT EXISTS", "EXISTS", "==", "!=", "<=", ">=", "<", ">"];
	vm.selectorType = vm.rootSelectorDataTypes[0];
	vm.suggestions = {};
	vm.previousSelectorString = "...";
	vm.selectorChildren = [];
	vm.isUsingKey = typeof vm.extraKeys === "object";
	vm.isUsingForEach = typeof vm.usesForEach === "string";
	vm.targetType = vm.targetType || "";
	vm.isCollapsingPreview = typeof vm.collapsePreview === "string";

	// Watchers
	$scope.$watch(
		() => {
			return vm.isAdvancedMode;
		},
		() => {
			return vm.onModeChange();
		}
	);

	$scope.$watch("vm.selectorChildren", () => {
		if(!vm.isAdvancedMode) {
			vm.selector.selectorString = vm.getQueryStringUntilIndex(2147483647);
		}
	}, true);

	// Selector children

	vm.removeChild = function removeChild(child) {
		vm.selectorChildren = _.without(vm.selectorChildren, child);
		if(vm.selectorChildren.length === 0) {
			vm.selectorType = vm.rootSelectorDataTypes[0];
		}
	};

	vm.moveChild = function moveChild(child, offset) {
		const initialIndex = vm.selectorChildren.indexOf(child);
		vm.selectorChildren.splice(initialIndex, 1);
		vm.selectorChildren.splice(initialIndex + offset, 0, child);
	};

	vm.addChild = function addChild() {
		vm.selectorType = vm.rootSelectorDataTypes[1];
		const newChild = {
			selectorType: vm.childDataTypes[0],
			name: "",
			id: "",
			conditionType: vm.conditionTypes[1],
			conditionLeft: "",
			conditionRight: "",
			propertyOptions: [],
			indexOptions: [],
		};
		vm.selectorChildren.push(newChild);
		vm.getBasicSuggestions(newChild, true);
		if(typeof vm.targetJson === "undefined" || !vm.validateJson(vm.targetJson)) {
			return;
		}
		if(typeof vm.onAddChild === "function") {
			vm.onAddChild();
		}
	};

	// Suggestions

	vm.getBasicSuggestions = function (child, isInitial) {
		const qs = vm.getQueryStringUntilIndex(vm.selectorChildren.indexOf(child) - 1);
		if(qs === vm.previousSelectorString
			&& (child.propertyOptions.length > 0 || child.indexOptions.length > 0)) {
			return;
		}
		vm.previousSelectorString = qs;

		child.indexOptions = [];
		child.propertyOptions = [];
		const indString = vm.getQueryStringUntilIndex(vm.selectorChildren.indexOf(child) - 1);
		const optString = indString.length > 0 ? indString + "." : indString;
		const inds = vm.getSuggestionsFromJson(indString);
		const opts = vm.getSuggestionsFromJson(optString);
		child.indexOptions = vm.unique(inds.filter(item => {
			return item.charAt(0) === "[";
		})
			.map(item => {
				return item.substring(item.indexOf("[") + 1, item.indexOf("]"));
			}));
		child.propertyOptions = opts.map(item => {
			const bracketLocation = item.indexOf("[");
			if(bracketLocation !== -1) {
				return item.substring(0, bracketLocation);
			}
			return item;
		}).filter(item => {
			return item.trim() !== "";
		});
		child.propertyOptions = vm.unique(child.propertyOptions);

		if(isInitial && child.indexOptions.length > 0) {
			child.selectorType = vm.childDataTypes[1];
		}

	};

	vm.getAdvancedSuggestions = function (elementId) {
		// Only update suggestions when a new word is beginning
		const selector = vm.removeConditionals(vm.selector.selectorString);
		if(vm.selector.selectorString === vm.previousSelectorString
			|| (selector.charAt(selector.length - 1) !== "."
				&& selector.charAt(selector.length - 1) !== "")) {
			return;
		}
		vm.advancedSuggestions = vm.getSuggestionsFromJson(vm.selector.selectorString).map(item => {
			return vm.targetType === "array" ? item : vm.selector.selectorString + item;
		})
			.filter(item => {
				return item !== vm.selector.selectorString && item.trim() !== "";
			});
		if(elementId) {
			vm.refreshElement(elementId);
		}
	};

	vm.getSuggestionsFromJson = function (selectorString) {
		if(vm.targetJson == null) {
			return [];
		}
		if(vm.isUsingForEach) {
			selectorString = "[]." + selectorString;
		}

		vm.previousJson = vm.targetJson;
		try {
			const obj = JSON.parse(vm.targetJson);
			let tempOptions = vm.getAllKeys(obj, "");
			const pureSelector = vm.removeConditionals(selectorString);
			tempOptions = tempOptions.filter(word => {
				return _.startsWith(word, pureSelector);
			});
			tempOptions = vm.unique(tempOptions);

			if(vm.targetType === "array" && vm.isAdvancedMode) {
				tempOptions = tempOptions.filter(item => {
					return _.endsWith(item, "[]");
				})
					.map(item => {
						return item.substring(0, item.length - 2);
					});
			} else {
				tempOptions = tempOptions.map(item => {
					while((item.substring(pureSelector.length).match(/\./g) || []).length > 0) {
						item = item.substring(0, item.lastIndexOf("."));
					}
					const sub = item.substring(pureSelector.length);
					return sub.charAt(0) === "." ? sub.substring(1) : sub;
				});
			}
			tempOptions = vm.unique(tempOptions);
			if(vm.isUsingKey
				&& (selectorString.charAt(selectorString.length - 1) === "."
					|| selectorString.length === 0)) {
				_.forEach(vm.extraKeys, key => {
					tempOptions.push("<<" + key + ">>");
				});
			}
			return tempOptions;

		} catch(e) {
			console.log(e);
			return [];
		}
	};

	// Query manipulation

	vm.getQueryStringUntilIndex = function (lastIndex) {
		let queryString = "";
		_.forEach(vm.selectorChildren, (child, index) => {
			if(lastIndex < index) {
				return;
			}
			const scType = index > 0 ? vm.selectorChildren[index - 1].selectorType : null;
			if(child.selectorType === vm.childDataTypes[0]) { // this is an object child
				if(!child.name || child.name.trim() === "") {
					return;
				}
				if(index !== 0 && scType !== vm.childDataTypes[2]) {
					queryString += ".";
				}
				queryString += child.name;
			} else if(child.selectorType === vm.childDataTypes[1]) { // this is an array element
				queryString += "[" + child.id + "]";
			} else if(child.selectorType === vm.childDataTypes[2]) { // this is a conditional
				if(
					index > 0
						&& (scType === vm.childDataTypes[0]
							|| scType === vm.childDataTypes[1])
				) {
					queryString += ".";
				}
				switch(child.conditionType) {
				case vm.conditionTypes[0]:
				case vm.conditionTypes[1]:
					queryString += "{" + child.conditionLeft + " " + child.conditionType + "}";
					break;
				default:
					queryString +=
						"{"
						+ child.conditionLeft
						+ child.conditionType
						+ child.conditionRight
						+ "}";
					break;
				}

			}
		});
		return queryString;
	};

	/**
		* Removes [] and {} blocks
		* @param {string} selectorString
		*/
	vm.removeConditionalsAndIndexes = function (selectorString) {
		if(selectorString.contains("[")) {
			selectorString = selectorString.substring(0, selectorString.indexOf("["))
				+ selectorString.substring(selectorString.lastIndexOf("]") + 1, selectorString.length);
			selectorString = vm.removeConditionalsAndIndexes(selectorString);
		} else if(selectorString.contains("{")) {
			selectorString = selectorString.substring(0, selectorString.indexOf("{"))
				+ selectorString.substring(selectorString.lastIndexOf("}") + 1, selectorString.length);
			selectorString = vm.removeConditionalsAndIndexes(selectorString);
		}
		return selectorString;
	};

	vm.removeConditionals = function (str) {
		if(str.contains("{") && str.contains("}")) {
			str = str.substring(0, str.indexOf("{"))
				+ str.substring(str.lastIndexOf("}") + 1, str.length);
			str = vm.removeConditionals(str);
		}
		return str;
	};

	// UI

	vm.makeBasicUi = function () {
		let selectorString = vm.selector.selectorString.trim();
		selectorString = selectorString.replace(/\.\[/g, "[").replace(/\[/g, ".[");
		// Add dots between brackets and properties, example: asd.[][].d[] => asd.[].[].d.[]

		if(selectorString.trim() === "") {
			vm.selectorType = vm.rootSelectorDataTypes[0]; // "All data"
			vm.selectorChildren = [];
			return;
		} else {
			vm.selectorType = vm.rootSelectorDataTypes[1]; // "Object / Array"
		}

		const query = selectorString.split(".");
		vm.selectorChildren = [];

		_.forEach(query, queryItem => {
			if(queryItem.trim() === "") {
				return;
			}
			vm.addChild();
			const childName = vm.removeConditionalsAndIndexes(queryItem);
			let currSelector = vm.selectorChildren[vm.selectorChildren.length - 1];

			while(queryItem.indexOf("{") !== -1) { // Check if this query part contains a condition
				currSelector.selectorType = vm.childDataTypes[2];
				const conditionString =
					queryItem.substring(queryItem.indexOf("{") + 1, queryItem.indexOf("}"));
				let conditionType = "==";
				// get condition type
				for(let i = 0; i < vm.conditionTypes.length; i++) {
					const type = vm.conditionTypes[i];
					if(conditionString.toUpperCase().indexOf(type) !== -1) {
						conditionType = vm.conditionTypes[i];
						break;
					}
				}
				const typeIndex = conditionString.indexOf(conditionType);
				currSelector.conditionLeft = conditionString.substring(0, typeIndex).trim();
				currSelector.conditionRight = conditionString.substring(
					typeIndex + conditionType.length
				);
				currSelector.conditionType = conditionType;

				const queryItemStart = queryItem.substring(0, queryItem.indexOf("{"));
				const queryItemEnd = queryItem.substring(queryItem.indexOf("}") + 1, queryItem.length);
				const potentialSelector = queryItemStart + queryItemEnd;
				if(potentialSelector.trim().length > 0) {
					vm.addChild();
					currSelector = vm.selectorChildren[vm.selectorChildren.length - 1];
				}
				queryItem = potentialSelector;
			}
			if(queryItem.trim().length === 0) {
				return;
			}

			// Check if this query part is an array index
			const arraySymbolIndex = queryItem.indexOf("[");
			if(arraySymbolIndex !== -1) {
				currSelector.selectorType = vm.childDataTypes[1];
				currSelector.id = queryItem.substring(arraySymbolIndex + 1, queryItem.indexOf("]"));
			} else {
				// Assume it's an object
				currSelector.name = childName;
				currSelector.selectorType = vm.childDataTypes[0];
			}
		});
	};

	// Generic

	vm.validateJson = function (body) {
		try {
			JSON.parse(body);
			return true;
		} catch(err) {
			return false;
		}
	};

	vm.onModeChange = function () {
		if(!vm.isAdvancedMode) {
			vm.makeBasicUi();
		}
	};

	vm.hasPreviewAction = function () {
		return typeof vm.previewAction === "function";
	};

	vm.getAllKeys = function (obj, baseString) {
		let resultList = [];
		const separator = baseString === "" ? "" : ".";
		if(obj && typeof obj === "object") {
			if(!Array.isArray(obj)) {
				Object.keys(obj).forEach(key => {
					resultList.push(baseString + separator + key);
					resultList = resultList.concat(
						vm.getAllKeys(
							obj[key],
							baseString + separator + key
						)
					);
				});
			} else {
				obj.forEach((child, index) => {
					resultList.push(baseString + "[" + index + "]");
					resultList.push(baseString + "[]");
					resultList = resultList.concat(vm.getAllKeys(child, baseString + "[" + index + "]"));
					resultList = resultList.concat(vm.getAllKeys(child, baseString + "[]"));
				});
			}
		}
		return resultList;
	};

	vm.refreshElement = function (elementId) {
		const element = angular.element("#" + elementId);
		// stupid hack to force datalist update in IE
		element.blur();
		setTimeout(() => {
			element.focus();
		}, 0);
	};

	vm.performPreviewAction = function () {
		vm.previewAction({ query: vm.selector.selectorString }).then(data => {
			vm.selector.preview = data;
		});
	};

	vm.unique = function (arr) {
		const dict = {};
		const final = [];
		arr.forEach(item => {
			if(typeof dict[item] === "undefined") {
				dict[item] = item;
				final.push(item);
			}
		});
		return final;
	};

	vm.aceLoaded = function (editor) {
		editor.setReadOnly(true);
		editor.setOptions({
			autoScrollEditorIntoView: true,
			minLines: 4,
			maxLines: 30,
		});
		editor.container.style.background = "WhiteSmoke";
		$timeout(() => {
			vm.aceChanged([{}, editor], true);
		}, 0);
	};

	vm.aceChanged = function (e, ignoreCondition) {
		if(vm.isCollapsingPreview) {
			const editor = e[1];
			if(editor.$isFocused === false || ignoreCondition) {
				editor.gotoLine(2, 0);
				editor.commands.commands.foldall.exec(editor);
				editor.commands.commands.unfold.exec(editor);
				editor.gotoLine(1, 0);
			}
		}
	};

	vm.isJsonArray = function (jsonString) {
		try {
			const obj = angular.fromJson(jsonString);
			if(Array.isArray(obj)) {
				return true;
			}
		} catch(e) {
			// do nothing
		}
		return false;
	};
}
