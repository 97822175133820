angular.module("eShare.documentViewer").directive("cadDocumentSearchNav", cadDocumentSearchNav);

cadDocumentSearchNav.$inject = [];

function cadDocumentSearchNav() {
	return {
		restrict: "E",
		replace: true,
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadDocumentSearchNav.directive.html'),
		scope: {},
		bindToController: {
			text: "<",
			currentIndex: "<",
			results: "=",
			onMoveToSearchResultRequested: "&",
			setCurrentSearchIndex: "&",
		},
		controllerAs: "vm",
		controller: cadDocumentSearchNavController,
	};
}

cadDocumentSearchNavController.$inject = ["$scope"];

function cadDocumentSearchNavController($scope) {
	const vm = this;

	$scope.$watch("vm.results", updateResults);

	function updateResults() {
		if(vm.results) {
			vm.hasResults = vm.results.length > 0;
			vm.lastIndex = vm.results.length - 1;
		}
	}

	vm.prevSearchResult = function prevSearchResult() {
		fireMoveToSearchResultRequested(-1);
	};

	vm.nextSearchResult = function nextSearchResult() {
		fireMoveToSearchResultRequested(1);
	};

	vm.firstSearchResult = function firstSearchResult() {
		fireSetCurrentSearchIndex(0);
		fireMoveToSearchResultRequested(0);
	};

	vm.lastSearchResult = function lastSearchResult() {
		fireSetCurrentSearchIndex(vm.lastIndex);
		fireMoveToSearchResultRequested(0);
	};

	function fireMoveToSearchResultRequested(delta) {
		const onMoveToSearchResultRequested = vm.onMoveToSearchResultRequested();
		if(onMoveToSearchResultRequested) {
			onMoveToSearchResultRequested(delta);
		}
	}

	function fireSetCurrentSearchIndex(index) {
		const setCurrentSearchIndex = vm.setCurrentSearchIndex();
		if(setCurrentSearchIndex) {
			setCurrentSearchIndex(index);
		}
	}
}