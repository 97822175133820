angular.module("eShare.documentViewer").directive("cadDocumentTreeView", cadDocumentTreeView);

cadDocumentTreeView.$inject = [];

function cadDocumentTreeView() {
	return {
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadDocumentTreeView.directive.html'),
		replace: true,
		restrict: "EA",
		controller: CadDocumentTreeViewController,
	};
}

CadDocumentTreeViewController.$inject = [
	"$rootScope", "$scope", "$state", "$timeout", "$http", "documentTreeRepository",
	"attributesService", "notification", "$uibModal", "documentListConfigurationRepository",
	"documentConfigurationsRepository", "authorization", "searchService", "$stateParams",
	"printedDocumentInformation", "$interval", "$filter"
];

// ReSharper disable once InconsistentNaming
function CadDocumentTreeViewController(
	$rootScope, $scope, $state, $timeout, $http, documentTreeRepository,
	attributesService, notification, $uibModal, documentListConfigurationRepository,
	documentConfigurationsRepository, authorization, searchService, $stateParams,
	printedDocumentInformation, $interval, $filter
) {

	$scope.searchControl = {};
	$scope.viewerControl = {};
	$scope.loadControl = {};

	$scope.docInfo = null;
	$scope.searchesByDocument = [];

	let delayedSearchObject = null;

	$scope.onPageNumberRequested = function onPageNumberRequested(pageNumber) {
		if($scope.docInfo.currentPageNumber === pageNumber) {
			return;
		}
		const documentKey = getDocumentKey($scope.currentDocument);
		$scope.viewerControl[documentKey].changePage(pageNumber);
	};

	function getDocumentKey(doc) {
		return doc.dataSourceId + "|" + doc.id;
	}

	function setupSearchForDocument(doc) {
		const documentKey = getDocumentKey(doc);
		$scope.docInfo = $scope.searchesByDocument[documentKey];
		if($scope.docInfo && $scope.docInfo.isSearchPending) {
			const interval = $interval(() => {
				const searchTextChanged = $scope.searchControl.searchTextChanged;
				if(searchTextChanged) {
					searchTextChanged();
					$scope.docInfo.isSearchPending = false;
					$interval.cancel(interval);
				}
			}, 50);
		}
	}

	function moveToSearchResult(delta) {
		const docInfo = $scope.docInfo;
		if(!docInfo || !docInfo.hasResults) {
			return;
		}
		const index = docInfo.currentIndex + delta;
		if(index < 0 || docInfo.lastIndex < index) {
			return;
		}
		const result = docInfo.results[index];
		if(!result) {
			return;
		}
		docInfo.currentIndex = index;
		const documentKey = getDocumentKey($scope.currentDocument);
		if(!$scope.viewerControl[documentKey]) {
			return;
		}
		const focusSearchResult = $scope.viewerControl[documentKey].focusSearchResult;
		const interval = $interval(() => {
			if(typeof focusSearchResult === "function") {
				focusSearchResult(result);
				$interval.cancel(interval);
			}
		}, 50);
	}

	$scope.onDocInfoLoaded = function (documentKey, docInfo) {
		if(delayedSearchObject) {
			if(docInfo.links && docInfo.links.length > 0) {
				if(delayedSearchObject.val) {
					docInfo.text = delayedSearchObject.val;
					docInfo.selectedAbbreviation = delayedSearchObject.tag || "";
					let pageNumber = parseInt(delayedSearchObject.page || "");
					if(isNaN(pageNumber) || pageNumber < 0) {
						pageNumber = 0;
					}
					docInfo.currentPageNumber = pageNumber;
					delayedSearchObject = null;
				} else if(delayedSearchObject.areaId) {
					$timeout(() => {
						moveToAreaLocation(documentKey, delayedSearchObject.areaId);
						delayedSearchObject = null;
					});
				}
			}
		}
		let currentDocumentKey;
		if($scope.currentDocument != null) {
			currentDocumentKey = getDocumentKey($scope.currentDocument);
		}
		if(currentDocumentKey != null && documentKey === currentDocumentKey) {
			const interval = $interval(() => {
				const searchTextChanged = $scope.searchControl.searchTextChanged;
				if(searchTextChanged) {
					searchTextChanged();
					$interval.cancel(interval);
				}
			}, 50);
		} else {
			docInfo.isSearchPending = true;
		}
		$scope.searchesByDocument[documentKey] = docInfo;
		if(currentDocumentKey != null) {
			$scope.docInfo = $scope.searchesByDocument[currentDocumentKey];
		}
	};

	$scope.onOpenNewDocument = function (link) {
		const documentRequest = {
			dataSourceId: link.dataSourceId,
			documentId: link.documentId,
			areaId: link.areaId,
		};
		documentRequested(documentRequest);
	};

	$scope.onMoveToSearchResultRequested = function (delta) {
		moveToSearchResult(delta);
	};

	$scope.setCurrentSearchIndex = function (index) {
		$scope.docInfo.currentIndex = index;
	};

	$scope.projectId = null;
	$scope.hierarchyData = { selectedHierarchy: "" };
	$scope.viewState = {
		isInViewMode: false,
		activeDocumentIndex: 0,
	};
	let isDocumentsPageVisible = false;

	$scope.splitterOptionsLeft = {
		context: "documents",
		storageKey: "documents.splitterLeft",
		orientation: "horizontal",
		beforeMin: "20%",
		afterMin: "20%",
		initialSizeBefore: "40%",
		onResize: onSplitterResize,
	};

	$scope.splitterOptionsRight = {
		context: "documents",
		storageKey: "documents.splitterRight",
		orientation: "horizontal",
		beforeMin: "60%",
		initialSizeAfter: "20%",
		onResize: onSplitterResize,
	};

	function onSplitterResize(/*data*/) {
		if($scope.documentGridOptions && $scope.documentGridOptions.api) {
			$scope.documentGridOptions.api.sizeColumnsToFit();
		}
	}

	$rootScope.$on("$stateChangeSuccess", (event, toState /*, toParams, fromState, fromParams*/) => {
		if(toState.name !== "project.document" && isDocumentsPageVisible) {
			isDocumentsPageVisible = false;
			$timeout(() => {
				$("#documentTreeView").css("left", "-20000px");
				$("#documentTreeView").css("width", "1000px");
			}, 10);
		}
		if(toState.name === "project.document") {
			isDocumentsPageVisible = true;
			$("#documentTreeView").css("left", "0");
			$("#documentTreeView").css("right", "0");
			$("#documentTreeView").css("width", "100%");
			$timeout(() => {
				prepareDocumentView();
			}, 0);
		}
	});

	$scope.$on("documentTreeView::getFolders",
		(/*event, toState, toParams, fromState, fromParams*/) => {
			refreshDocumentTree();
		});

	// Counts the number of geometryIds found in the document's links
	$scope.numberOfObjectsFound = 0;

	// Folder tree
	$scope.roots = null;
	$scope.selectedFolder = null;
	$scope.isDocumentHierarchyDone = false;

	$scope.onFolderSelected = onFolderSelected;
	$scope.onFolderExpanded = onFolderExpanded;

	// List of documents in the document table
	$scope.documents = [];
	const documentStub = {
		id: null,
		dataSourceId: null,
		attributes: [],
		attributeRequestId: null,
	};

	// Opened document viewer tabs
	$scope.documentTabs = [];

	// Metadata pane contents

	// Document selected in Browse mode
	$scope.selectedDocument = {};

	// Document whose metadata is being displayed
	// (selectedDocument in Browse mode, tab.document in View mode)
	$scope.currentDocument = {};
	$scope.multipleItemsSelected = false;

	// The only purpose of the update token is to force reload of document when it is re-opened.
	// See DocumentController.cs and IM-2891 for further information.
	$scope.currentUpdateToken = Utilities.generateUuid();

	// Fixed columns in the document grid;
	// per-DS columns will be added to the grid options separately
	const fixedColumns = [
		{
			field: "name",
			displayName: "Name",
			width: 300,
			cellRenderer: function (/*params*/) {
				return "<div style=\"cursor: pointer; overflow: hidden; text-overflow: ellipsis\">"
				+ "<a ng-href=\"\" ng-click=\"$parent.$parent.openDocument(data)\">{{data.name}}</a>"
				+ "</div>";
			},
		}
	];
	const defaultColumnWidth = 150;

	let nextDocumentTabIndex = 0;
	let nextViewerIndex = 0;
	let nextDivIndex = 0;

	$scope.metadataFields = [];

	// When the user clicks a document in the document table, open its metadata on the metadata pane:
	$scope.selectDocuments = function () {
		const documents = $scope.documentGridOptions.selectedRows;
		$scope.isManagedDocumentsDataSource = documents[0]
			&& documents[0].dataSourceId === "7c800013-66bb-40ac-979e-e131f0f1667b";
		if(documents.length === 1) {
			const document = documents[0];
			$scope.confirmDelete = false;
			$scope.confirmExamine = false;
			$scope.selectedDocument = $scope.currentDocument = document;
			getExternalAttributesForDocument(document);
			$scope.multipleItemsSelected = false;
			const documentTab = _.find($scope.documentTabs,
				{
					id: document.id,
					document: { dataSourceId: document.dataSourceId },
				});
			if(!documentTab) {
				getDocumentDefaultColorConfiguration(document);
			}
		} else {
			$scope.selectedDocument = $scope.currentDocument = null;
			$scope.multipleItemsSelected = true;
			const isSupported = _.find(documents, doc => {
				return (typeof doc.isKnownFormat === "boolean" && doc.isKnownFormat);
			});
			$scope.isSupportedSelected = !!isSupported;
		}
	};

	function getDocumentDefaultColorConfiguration(document) {
		if(document.linkColoringConfigurations && document.linkColoringConfigurations.length > 1) {
			const configurationKey = "defaultLinkColoringConfigurationId:" + $scope.projectId;
			const defaultColoringConfigurationId = localStorage.getItem(configurationKey);
			if(Utilities.isString(defaultColoringConfigurationId)
				&& defaultColoringConfigurationId !== "") {
				for(let i = 0; i < document.linkColoringConfigurations.length; i++) {
					const configuration = document.linkColoringConfigurations[i];
					if(configuration.id === defaultColoringConfigurationId) {
						document.selectedLinkColoringConfiguration = configuration;
					}
				}
			}
		}
	}

	$scope.$watch("documentGridOptions.selectedRows.length", () => {
		$scope.multipleItemsSelected = $scope.documentGridOptions.selectedRows.length > 1;
	});

	// Document grid options:
	$scope.documentGridOptions = {
		rowData: $scope.documents,
		rowSelection: "multiple",
		rowHeight: 31.42,
		enableColResize: true,
		enableSorting: true,
		enableFilter: true,
		angularCompileRows: true,
		columnDefs: _.clone(fixedColumns),
		//rowSelected: function (row) {
		//$scope.selectDocument(row);
		//},
		selectionChanged: $scope.selectDocuments,
		quickFilterText: "",
		rowDeselection: true,
		ready: function () {
			$scope.documentGridOptions.api.sizeColumnsToFit();
		},
	};

	function getDocumentDiv(documentTab) {
		return $("#DC" + documentTab.index);
	}

	let isCtrlPressed = false;
	let shouldSuppressZoom = false;
	if(window.eShare.isIE || window.eShare.isChrome) {
		const bodyElement = $("body");
		bodyElement.on("keyup", e => {
			if(e && e.which === 17) {
				isCtrlPressed = true;
			}
		});
		bodyElement.on("keydown", e => {
			if(e && e.which === 17) {
				isCtrlPressed = true;
			}
		});
		bodyElement.on("mousewheel", e => {
			if(isCtrlPressed && shouldSuppressZoom && $state.current.name === "project.document") {
				e.preventDefault();
			}
		});
	}

	// Browse/View mode toggled:
	$scope.$watch("viewState.isInViewMode", isInViewMode => {
		if(isInViewMode) {
			shouldSuppressZoom = true;
			if($scope.documentTabs.length > 0) {
				$timeout(() => {
					let documentTab = _.find($scope.documentTabs,
						{
							index: $scope.viewState.activeDocumentIndex,
						});
					if(!documentTab) {
						documentTab = $scope.documentTabs[$scope.documentTabs.length - 1];
						$scope.viewState.activeDocumentIndex = documentTab.index;
					}
					$scope.currentDocument = documentTab.document;
					$scope.currentUpdateToken = documentTab.updateToken;
				}, 10);
			} else {
				$scope.currentDocument = _.clone(documentStub);
			}
		} else {
			shouldSuppressZoom = false;
			$scope.currentDocument = $scope.selectedDocument;
		}
		$timeout(() => {
			// Refresh the size of each splitter pane:
			$rootScope.$broadcast("splitter::refresh");
		});
	});

	$scope.$watch("isDocumentHierarchyDone", isDone => {
		if(isDone) {
			checkForDocumentRequest();
		}
	});

	$scope.currentRevision = {};

	$scope.$watch("currentDocument", () => {
		if($scope.currentDocument) {
			$scope.currentRevision.id = $scope.currentDocument.id;
		}
	});

	/*
	When the project is changed:
	1. reload the folder tree
	2. reset the document table
	3. close all documents
	*/
	function changeProject(project, canAdministerProject) {
		$scope.projectId = project;
		$scope.roots = null;
		$scope.confirmDelete = false;
		$scope.confirmExamine = false;
		$scope.canAdministerProject = canAdministerProject;
		$scope.metadataFields = [];
		if($scope.documentGridOptions) {
			$scope.documentGridOptions.rowData = [];
			$scope.documentGridOptions.api.onNewCols();
			$scope.documentGridOptions.api.onNewRows();
			$scope.documentGridOptions.quickFilterText = "";
		}
		if($scope.projectId !== null) {
			documentConfigurationsRepository.getHierarchySettings($scope.projectId).then(settings => {
				let hierarchyNames = _.map(settings, s => {
					return {
						id: s.name,
						name: s.name,
					};
				});
				hierarchyNames = _.sortBy(hierarchyNames, n => {
					return n.name.toLowerCase();
				});
				hierarchyNames = _.concat([{ id: "", name: "Default Hierarchy" }], hierarchyNames);
				$scope.hierarchyNames = hierarchyNames;
				$scope.hierarchyData.selectedHierarchyName = getSelectedHierarchyName();
				documentTreeRepository
					.getFolderTree($scope.projectId, $scope.hierarchyData.selectedHierarchyName)
					.then(setDocumentHierarchy, error => {
						notification.error("Error loading document folder tree: " + error);
					});
			});
		}
	}

	function getSelectedHierarchyName() {
		const configurationKey = "defaultDynamicDocumentHierarchy:" + $scope.projectId;
		const storedHierarchy = localStorage.getItem(configurationKey);
		if(!Utilities.isString(storedHierarchy) || storedHierarchy === "") {
			return "";
		}
		if(!_.some($scope.hierarchyNames, { "id": storedHierarchy })) {
			return "";
		}
		return storedHierarchy;
	}

	function setDocumentHierarchy(roots) {
		for(let i = 0; i < roots.length; ++i) {
			setTrackingKeys(roots[i]);
		}
		$scope.roots = roots;
		_.forEach($scope.documentTabs, documentTab => {
			getDocumentDiv(documentTab).remove();
		});
		$scope.documentGridOptions.columnDefs = _.clone(fixedColumns);
		$scope.documentGridOptions.rowData = $scope.documents = [];
		$scope.documentGridOptions.api.onNewCols();
		$scope.documentGridOptions.api.onNewRows();
		$scope.documentGridOptions.api.sizeColumnsToFit();
		$scope.documentTabs = [];
		$scope.selectedDocument = $scope.currentDocument = _.clone(documentStub);
		$scope.isDocumentHierarchyDone = true;
	}

	function checkForDocumentRequest() {
		if($stateParams.dataSourceId && $stateParams.documentId) {
			//FIXME: Should we allow dataSourceId to not be set and in this case try to find it?
			const documentRequest = {
				dataSourceId: $stateParams.dataSourceId,
				documentId: $stateParams.documentId,
				page: $stateParams.page,
				tag: $stateParams.tag,
				val: $stateParams.val,
				x: $stateParams.x,
				y: $stateParams.y,
				width: $stateParams.width,
				height: $stateParams.height,
				areaId: $stateParams.areaId,
				colorConfigurationId: null,
				//TODO: It is not yet possible to set the color configuration with URL
			};
			documentRequested(documentRequest);
		}
	}

	function setTrackingKeys(folder) {
		folder.trackingKey =
		folder.dataSourceId + "#"
		+ folder.key + "#"
		+ folder.name + "#"
		+ folder.id;
		if(folder.subFolders && folder.subFolders.length > 0) {
			for(let i = 0; i < folder.subFolders.length; ++i) {
				setTrackingKeys(folder.subFolders[i]);
			}
		}
	}

	$scope.hierarchySelected = function () {
		$scope.confirmDelete = false;
		$scope.confirmExamine = false;
		const selectedHierarchyName = $scope.hierarchyData.selectedHierarchyName || "";
		const configurationKey = "defaultDynamicDocumentHierarchy:" + $scope.projectId;
		localStorage.setItem(configurationKey, selectedHierarchyName);
		documentTreeRepository
			.getFolderTree($scope.projectId, selectedHierarchyName)
			.then(setDocumentHierarchy, error => {
				notification.error("Error loading document folder tree: " + error);
			});
	};

	function downloadDocuments(documentsToDownload, isOriginal, forcePdf) {
		if(!Array.isArray(documentsToDownload) || documentsToDownload.length < 1) {
			return;
		}
		const docIds = _.map(documentsToDownload, doc => {
			return doc.id;
		});
		const uri =
		"api/project/{projectId}/Documents/{dataSourceId}?isOriginal={isOriginal}&forcePdf={forcePdf}"
			.supplant({
				projectId: $scope.projectId,
				dataSourceId: documentsToDownload[0].dataSourceId,
				isOriginal: new Boolean(isOriginal).toString(),
				forcePdf: new Boolean(forcePdf).toString(),
			});
		$http.post(uri, docIds,
			{
				responseType: "blob",
			}).then(processFileResponse, processErrorResponse);

		function processFileResponse(response) {
			const blob = response.data;
			const filename =
				decodeURIComponent(getFileNameFromResponseHeader(response)) || "document.pdf";
			window.saveAs(blob, filename);
		}

		function processErrorResponse(reason) {
			notification.error("Downloading document failed: " + Utilities.getErrorMessage(reason));
		}
	}

	function downloadDocument(documentToDownload, isOriginal, forcePdf) {
		if(!documentToDownload) {
			return;
		}
		const uri = ("api/project/{projectId}/Document/{dataSourceId}"
		+ "?documentId={documentId}&isOriginal={isOriginal}&forcePdf={forcePdf}")
			.supplant({
				projectId: $scope.projectId,
				dataSourceId: documentToDownload.dataSourceId,
				documentId: encodeURIComponent(documentToDownload.id),
				isOriginal: new Boolean(isOriginal).toString(),
				forcePdf: new Boolean(forcePdf).toString(),
			});
		$http.get(uri, {
			responseType: "blob",
		}).then(processFileResponse, processErrorResponse);

		function processFileResponse(response) {
			const blob = response.data;
			const filename =
				decodeURIComponent(getFileNameFromResponseHeader(response)) || "document.pdf";
			window.saveAs(blob, filename);
		}

		function processErrorResponse(reason) {
			notification.error("Downloading document failed: " + Utilities.getErrorMessage(reason));
		}
	}

	function getFileNameFromResponseHeader(response) {
		const contentDispositionParts = (response.headers("Content-Disposition") || "").split(";");
		for(let i = 0; i < contentDispositionParts.length; ++i) {
			const contentDispositionPart = contentDispositionParts[i].trim();
			if(contentDispositionPart.toLowerCase().startsWith("filename=")) {
				let filename = contentDispositionPart.substr(9).trim();
				if(filename.length >= 2 && filename[0] === "\"") {
					filename = filename.substr(1, filename.length - 2);
				}
				return filename;
			}
		}
		return null;
	}

	// In viewer mode

	$scope.downloadCurrentDocument = function downloadCurrentDocument() {
		const documentToDownload = $scope.currentDocument;
		downloadDocument(documentToDownload);
	};

	$scope.downloadCurrentOriginalDocument = function downloadCurrentOriginalDocument() {
		const documentToDownload = $scope.currentDocument;
		downloadDocument(documentToDownload, true);
	};

	$scope.downloadCurrentDocumentWithoutLinks = function downloadCurrentOriginalDocument() {
		const documentToDownload = $scope.currentDocument;
		downloadDocument(documentToDownload, true, true);
	};

	// In Browse mode

	$scope.downloadSelectedDocument = function downloadSelectedDocument() {
		const documentToDownload = $scope.selectedDocument;
		downloadDocument(documentToDownload);
	};

	$scope.downloadSelectedDocuments = function downloadSelectedDocuments() {
		const documentsToDownload = $scope.documentGridOptions.selectedRows.slice();
		downloadDocuments(documentsToDownload);
	};

	$scope.downloadSelectedOriginalDocument = function downloadSelectedOriginalDocument() {
		const documentToDownload = $scope.selectedDocument;
		downloadDocument(documentToDownload, true);
	};

	$scope.downloadSelectedDocumentWithoutLinks = function downloadSelectedOriginalDocument() {
		const documentToDownload = $scope.selectedDocument;
		downloadDocument(documentToDownload, false, true);
	};

	$scope.downloadSelectedOriginalDocuments = function downloadSelectedOriginalDocuments() {
		const documentsToDownload = $scope.documentGridOptions.selectedRows.slice();
		downloadDocuments(documentsToDownload, true);
	};

	$scope.downloadSelectedDocumentsWithoutLinks = function downloadSelectedDocumentsWithoutLinks() {
		const documentsToDownload = $scope.documentGridOptions.selectedRows.slice();
		downloadDocuments(documentsToDownload, false, true);
	};

	$rootScope.$on("eShare::projectChanged", (event, projectInfo) => {
		if(projectInfo.project.id !== $scope.projectId) {
			changeProject(projectInfo.project.id, projectInfo.canAdministerProject);
			$scope.viewState.isInViewMode = false;
		}
	});

	function prepareDocumentView() {
		let totalColumnWidths = 0;
		_.forEach($scope.documentGridOptions.columnDefs, columnDef => {
			totalColumnWidths += columnDef.width ? columnDef.width : defaultColumnWidth;
		});
		if($("#documentGridContainer").width() > totalColumnWidths) {
			$scope.documentGridOptions.api.sizeColumnsToFit();
		}
		if($scope.isDocumentHierarchyDone) {
			checkForDocumentRequest();
		}
	}

	function onFolderSelected(folder) {
		$scope.selectedFolder = folder;
		$scope.confirmDelete = false;
		$scope.confirmExamine = false;
		updateFolderContents(folder);
	}

	function onFolderExpanded(folder, callback) {
		$scope.confirmDelete = false;
		$scope.confirmExamine = false;
		expandFolder(folder, callback);
	}

	const previousLinkColoringConfigurations = [];

	// When the user clicks the "Open" button on the metadata panel,
	// open the document in a new tab, or if it is already open, activate its tab:
	function openDocument(document, colorConfigurationId, page, tag, val,
		x, y, width, height, areaId) {
		let documentTab = _.find($scope.documentTabs,
			{
				id: document.id,
				document: { dataSourceId: document.dataSourceId },
			});
		if(!documentTab && document.revisions) {
			_.forEach(document.revisions, revision => {
				const tab = _.find($scope.documentTabs,
					{
						id: revision.id,
						document: { dataSourceId: document.dataSourceId },
					});
				if(tab != null) {
					$scope.closeTab(tab.index, true);
				}
			});
		}
		if(colorConfigurationId) {
			if(document.linkColoringConfigurations && document.linkColoringConfigurations.length > 1) {
				if(Utilities.isString(colorConfigurationId) && colorConfigurationId !== "") {
					for(let i = 0; i < document.linkColoringConfigurations.length; i++) {
						const configuration = document.linkColoringConfigurations[i];
						if(configuration.id === colorConfigurationId) {
							document.selectedLinkColoringConfiguration = configuration;
						}
					}
				}
			}
		} else {
			getDocumentDefaultColorConfiguration(document);
		}
		const linkColoringConfigurationId = document.selectedLinkColoringConfiguration
			? document.selectedLinkColoringConfiguration.id
			: "";
		if(documentTab) {
			if(previousLinkColoringConfigurations[document.id]
				&& previousLinkColoringConfigurations[document.id] !== linkColoringConfigurationId) {
				$scope.closeTab(documentTab.index, true);
				documentTab = null;
			}
		}
		previousLinkColoringConfigurations[document.id] = linkColoringConfigurationId;
		const configurationKey = "defaultLinkColoringConfigurationId:" + $scope.projectId;
		localStorage.setItem(configurationKey, linkColoringConfigurationId);
		if(documentTab) {
			$scope.currentDocument = documentTab.document;
			$scope.currentUpdateToken = documentTab.updateToken;
			$scope.viewState.activeDocumentIndex = documentTab.index;
			if(val) {
				const documentKey = getDocumentKey(document);
				const documentSearch = $scope.searchesByDocument[documentKey];
				if(!documentSearch) {
					delayedSearchObject = {
						val: val,
						tag: tag,
						page: page,
					};
				} else {
					if(documentSearch.links && documentSearch.links.length > 0) {
						documentSearch.text = val;
						documentSearch.selectedAbbreviation = tag || "";
						$scope.searchesByDocument[documentKey] = documentSearch;
						$scope.docInfo = documentSearch;
						let pageNumber = parseInt(page || "");
						if(isNaN(pageNumber) || pageNumber < 0) {
							pageNumber = 0;
						}
						$scope.docInfo.currentPageNumber = pageNumber;
						const searchTextChanged = $scope.searchControl.searchTextChanged;
						if(searchTextChanged) {
							searchTextChanged();
						}
					}
				}
			} else if(x && y && width && height) {
				moveToDziLocation(x, y, width, height, page);
			} else if(areaId) {
				const documentKey = getDocumentKey(document);
				const documentSearch = $scope.searchesByDocument[documentKey];
				if(!documentSearch) {
					delayedSearchObject = {
						areaId: areaId,
					};
				} else {
					if(documentSearch.links && documentSearch.links.length > 0) {
						$scope.searchesByDocument[documentKey] = documentSearch;
						$scope.docInfo = documentSearch;
						$timeout(() => {
							moveToAreaLocation(documentKey, areaId);
						});
					}
				}
			}
		} else {
			$scope.currentDocument = document;
			getDocument(document, linkColoringConfigurationId, page, tag, val,
				x, y, width, height, areaId);
		}
		// Switch to the View mode:
		onContainerResized();
		$scope.viewState.isInViewMode = true;
		$scope.isChangingRevision = false;
	}

	function deleteDocument(document) {
		$scope.confirmDelete = false;
		$scope.confirmExamine = false;
		if($scope.multipleItemsSelected) {
			const documentsToDelete = $scope.documentGridOptions.selectedRows.slice();
			let documentsCount = documentsToDelete.length;
			_.forEach(documentsToDelete, doc => {
				documentTreeRepository.removeDocument($scope.projectId, doc.id).then(() => {
					documentsCount--;
					if(documentsCount === 0) {
						updateFolderContents($scope.selectedFolder);
					}
				}, err => {
					notification.error(err);
					documentsCount--;
					if(documentsCount === 0) {
						updateFolderContents($scope.selectedFolder);
					}
				});
			});
		} else {
			documentTreeRepository.removeDocument($scope.projectId, document.id).then(() => {
				updateFolderContents($scope.selectedFolder);
			}, err => {
				notification.error(err);
				updateFolderContents($scope.selectedFolder);
			});
		}

	}

	$scope.confirmDelete = false;

	$scope.confirmDocumentDelete = function () {
		$scope.confirmDelete = true;
	};

	$scope.cancelDocumentDelete = function () {
		$scope.confirmDelete = false;
	};

	$scope.openDocument = openDocument;

	$scope.openCurrentDocument = function (colorConfigurationId) {
		if($scope.viewState.isInViewMode) {
			openDocument($scope.currentDocument, colorConfigurationId);
		} else {
			openDocument($scope.selectedDocument, colorConfigurationId);
		}
	};

	$scope.deleteCurrentDocument = function () {
		deleteDocument($scope.selectedDocument);
	};

	$scope.isShowingHidden = function (category) {
		return !category.hasHidden;
	};

	$scope.flipHidden = function (category) {
		category.hasHidden = !category.hasHidden;
	};

	$scope.decodeUri = function (uri) {
		return decodeURIComponent(uri);
	};

	function documentRequested(documentRequest) {
		documentTreeRepository
			.getDocumentInfo($scope.projectId, documentRequest.dataSourceId,
				documentRequest.documentId).then(document => {
				openDocument(document, documentRequest.colorConfigurationId, documentRequest.page,
					documentRequest.tag, documentRequest.val, documentRequest.x, documentRequest.y,
					documentRequest.width, documentRequest.height, documentRequest.areaId);
			}, err => {
				notification.error(err);
			});
	}

	function addDocumentTab(document, uri) {
		const index = nextDocumentTabIndex++;
		const documentTab = {
			title: document.name,
			id: document.id,
			document: document,
			uri: uri,
			updateToken: Utilities.generateUuid(),
			index: index,
			viewerId: "__osd_" + nextViewerIndex++,
			divId: "DC" + nextDivIndex++,
		};
		$scope.documentTabs.push(documentTab);
		$scope.currentDocument = document;
		$scope.updateToken = documentTab.updateToken;
		getExternalAttributesForDocument(document);
		if(!document.isComparison) {
			getRevisionsForDocument(document);
		} else {
			document.revisions = [];
		}
		$timeout(() => {
			$scope.viewState.activeDocumentIndex = index;
		});
		return documentTab;
	}

	let requestId = 0;

	function getExternalAttributesForDocument(document) {
		document.requestTimestamp = document.requestTimestamp || 0;
		const now = Date.now();
		if(now - document.requestTimestamp < 300) {
			return;
		}
		document.attributes = [];
		document.categories = [];
		document.requestId = ++requestId;
		document.requestTimestamp = now;

		function addAttributesToDocument(response, requestDepth) {
			return addAttributes(document, response, requestDepth);
		}

		const keys = _.map(_.filter(document.metadata, metadatum => {
			return !!metadatum.abbreviation;
		}), metadatum => {
			return {
				keyType: "Tag",
				tags: [
					{
						attribute: metadatum.abbreviation,
						value: metadatum.value,
						isGroup: metadatum.isGroup,
					}
				],
			};
		});
		attributesService.getAttributes(document.requestId, $scope.projectId,
			addAttributesToDocument, processError, keys);
	}

	function getRevisionsForDocument(document) {
		documentTreeRepository
			.getRevisionsForDocument($scope.projectId, document.dataSourceId, document.id)
			.then(result => {
				_.forEach(result, rev => {
					rev.dateString = "(" + $filter("amDateFormat")(rev.lastModified, "YYYY-MM-DD HH:mm");
					rev.dateString += rev.isCurrentRevision ? ", latest)" : ")";
					rev.name = rev.name || "zero";
				});
				document.revisions = result;
				$scope.currentRevision.id = document.id;
			});
	}

	function addAttributes(document, response, requestDepth) {
		document.isLocateActive = false;
		if(document.requestId !== response.requestId) {
			return false;
		}
		const dataSourceId = response.dataSourceId;
		const key = response.key;
		const isMatchingAttribute = function (attribute) {
			return attribute.dataSourceId === dataSourceId
					&& Utilities.isObject(attribute.key)
					&& attribute.key.keyType === key.keyType
					&& Utilities.areEqual(attribute.key, key);
		};
		let indexToRemove = _.findIndex(document.attributes, isMatchingAttribute);
		while(indexToRemove !== -1) {
			document.attributes.splice(indexToRemove, 1);
			indexToRemove = _.findIndex(document.attributes, isMatchingAttribute);
		}
		_.forEach(_.filter(response.attributes, attribute => {
			return !(attribute.eShareKey
					&& attribute.eShareKey.keyType === "DocumentId"
					&& dataSourceId === document.dataSourceId
					&& attribute.eShareKey.documentId === document.id);
		}), attribute => {
			attribute.dataSourceId = dataSourceId;
			attribute.key = key;
			if(attribute.dataType !== "PoiName") {
				document.attributes.push(attribute);
				const categoryIndex = _.findIndex(document.categories, category => {
					return category.displayCategory === attribute.displayCategory;
				});
				if(categoryIndex === -1) {
					document.categories.push({
						hasHidden: false,
						displayCategory: attribute.displayCategory,
						attributes: [attribute],
					});
				} else {
					const category = document.categories[categoryIndex];
					category.attributes.push(attribute);
				}
			}
		});
		return requestDepth === 1;
	}

	function processError(reason) {
		notification.error(reason.data);
	}

	function getDocument(document, colorConfigurationId, page, tag, val,
		x, y, width, height, areaId) {
		let uri;
		if(!window.eShare.integratedSecurity) {
			authorization.createOneTimeToken().then(
				data => {
					uri = ("api/project/{projectId}/Document/{dataSourceId}?documentId={documentId}"
					+ "&token={token}&colorConfigurationId={colorConfigurationId}")
						.supplant({
							projectId: $scope.projectId,
							dataSourceId: document.dataSourceId,
							documentId: encodeURIComponent(document.id),
							token: data.token,
							colorConfigurationId: colorConfigurationId,
						});
					addDocument(document, colorConfigurationId, uri, page, tag, val,
						x, y, width, height, areaId);
				}
			);
		} else {
			uri = ("api/project/{projectId}/Document/{dataSourceId}?documentId={documentId}"
			+ "&colorConfigurationId={colorConfigurationId}")
				.supplant({
					projectId: $scope.projectId,
					dataSourceId: document.dataSourceId,
					documentId: encodeURIComponent(document.id),
					colorConfigurationId: colorConfigurationId,
				});
			addDocument(document, colorConfigurationId, uri, page, tag, val,
				x, y, width, height, areaId);
		}
	}

	let lastClickedFolder = null;

	function updateFolderContents(folder) {
		if(!folder) {
			return;
		}
		lastClickedFolder = folder;
		documentTreeRepository.getDocuments($scope.projectId, folder.dataSourceId, folder.id).then(
			data => {
				if(lastClickedFolder !== folder) {
					return;
				}
				data.documents.sort(Utilities.caseInsensitiveComparator("name"));
				// Extract document metadata to properties on the document itself
				// (so the grid can use them)
				_.forEach(data.documents, document => {
					_.forEach(document.metadata, metadata => {
						document["metadata_" + metadata.displayName] = metadata.value;
					});
				});
				$scope.metadataFields = _.clone(data.metadataFields);
				// Get the configured column list from localStorage,
				// or initialize it with only the Revision column (if one exists)
				documentListConfigurationRepository
					.get($scope.projectId, folder.dataSourceId).then(metadataColumns => {
						if(metadataColumns.length === 0) {
							metadataColumns = [];
							if(_.some($scope.metadataFields, field => {
								return field === "Revision";
							})) {
								metadataColumns.push({
									displayName: "Revision",
								});
							}
						}
						// Configure the grid columns:
						$scope.documentGridOptions.columnDefs = _.clone(fixedColumns);
						_.forEach(metadataColumns, column => {
						// A column is only shown if some document contains data for that column:
							if(_.some(data.documents, document => {
								return _.some(document.metadata, { "displayName": column.displayName });
							})) {
								$scope.documentGridOptions.columnDefs.push({
									displayName: column.displayName,
									field: "metadata_" + column.displayName,
									width: defaultColumnWidth,
								});
							}
						});
						let totalColumnWidths = 0;
						_.forEach($scope.documentGridOptions.columnDefs, columnDef => {
							totalColumnWidths += columnDef.width ? columnDef.width : defaultColumnWidth;
						});
						$scope.documentGridOptions.rowData = $scope.documents = data.documents;
						$scope.documentGridOptions.api.onNewCols();
						$scope.documentGridOptions.api.onNewRows();
						if($("#documentGridContainer").width() > totalColumnWidths) {
							$scope.documentGridOptions.api.sizeColumnsToFit();
						}
					});
				$scope.currentDocument = $scope.selectedDocument = _.clone(documentStub);
			}, error => {
				notification.error(error);
			}
		);

	}

	function expandFolder(folder, callback) {
		if(!folder || (folder.subFolders && folder.subFolders.length > 0 && !folder.isRecursive)) {
			return;
		}
		lastClickedFolder = folder;
		documentTreeRepository.getSubFolders($scope.projectId, folder.dataSourceId, folder.id,
			$scope.hierarchyData.selectedHierarchyName)
			.then(
				data => {
					callback(folder, data);
				}
			);
	}

	function addDocument(documentToAdd, colorConfigurationId, uri, page, tag, val,
		x, y, width, height, areaId) {

		/*
		Note: It would be nice if it were possible to include the object element directly through the
		template (cadDocumentTreeView.html), but this breaks the initial page load completely,
		probably because the HTML is inserted into the DOM before the bindings are evaluated and the
		HTML then would include the object tag without a valid "data" attribute. This breaks down both
		Adobe Reader and Google Chrome built-in PDF reader.

		As a workaround, we are forced to generate the object tag on the fly (here) and appending it
		to the corresponding element in the DOM.
		*/

		let pageNumber = parseInt(page || "");
		if(isNaN(pageNumber) || pageNumber < 0) {
			pageNumber = 0;
		}
		$scope.pageNumber = pageNumber;
		addDocumentTab(documentToAdd, uri);
		const documentKey = getDocumentKey(documentToAdd);

		// make sure loadDocument exists
		const interval = $interval(() => {
			if(typeof $scope.loadControl.loadDocument === "function") {
				$scope.viewerControl[documentKey] = {};
				$scope.loadControl.loadDocument(tag, val, x, y, width, height,
					areaId, colorConfigurationId, !!documentToAdd.isComparison, false);
				$interval.cancel(interval);
			}
		}, 0);
	}

	$scope.closeTab = function (index, isForRevision) {
		return $timeout(() => {
			let indexInArray = _.findIndex($scope.documentTabs, { index: index });
			if(indexInArray < 0) {
				return $timeout();
			}
			const documentTab = $scope.documentTabs[indexInArray];
			if(documentTab.document) {
				const documentKey = getDocumentKey(documentTab.document);
				delete $scope.searchesByDocument[documentKey];
				delete $scope.viewerControl[documentKey];
			}
			getDocumentDiv(documentTab).remove();
			$scope.documentTabs.splice(indexInArray, 1);
			if(indexInArray >= $scope.documentTabs.length) {
				indexInArray = $scope.documentTabs.length - 1;
			}
			if($scope.documentTabs.length === 0 && !isForRevision) {
				return $timeout(() => {
					$scope.docInfo = null;
					$scope.viewState.isInViewMode = false;
				}, 0);
			} else {
				return $timeout(() => {
					//if(isForRevision) {
					//indexInArray = $scope.documentTabs.length - 1;
					//}
					if(!isForRevision) {
						const activeDocumentTab = $scope.documentTabs[indexInArray];
						$scope.viewState.activeDocumentIndex = activeDocumentTab.index;
						$scope.currentDocument = activeDocumentTab.document;
						$scope.currentUpdateToken = activeDocumentTab.updateToken;
						setupSearchForDocument($scope.currentDocument);
					}
				});
			}
		});
	};

	$scope.closeAllDocuments = function () {
		$timeout(() => {
			$scope.searchesByDocument = [];
			$scope.viewerControl = {};
			$scope.loadControl = {};
			$scope.docInfo = null;
			_.forEach($scope.documentTabs, documentTab => {
				getDocumentDiv(documentTab).remove();
			});
			$scope.documentTabs = [];
			$timeout(() => {
				$scope.viewState.isInViewMode = false;
				$scope.viewState.activeDocumentIndex = -1;
				$scope.currentDocument = _.clone(documentStub);
			}, 0);
		}, 0);
	};

	$scope.tabSelected = function (index) {
		const documentTab = _.find($scope.documentTabs, { index: index });
		if(documentTab) {
			$scope.currentDocument = documentTab.document;
		}
		setupSearchForDocument(documentTab.document);
	};

	$scope.configureDocumentPane = function () {
		const modalInstance = $uibModal.open({
			backdrop: true,
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\dialogs\\DocumentListConfigurationDialog.html'),
			resolve: {
				metadataFields: function () {
					return $scope.metadataFields;
				},
				dataSourceId: function () {
					return $scope.selectedFolder.dataSourceId;
				},
				projectId: function () {
					return $scope.projectId;
				},
			},
			controller: "DocumentListConfigurationDialogCtrl",
		});
		modalInstance.result.then(columns => {
			$scope.documentGridOptions.columnDefs = _.clone(fixedColumns);
			_.forEach(columns, column => {
				if(_.some($scope.documents, document => {
					return _.some(document.metadata, { "displayName": column.displayName });
				})) {
					$scope.documentGridOptions.columnDefs.push({
						displayName: column.displayName,
						field: "metadata_" + column.displayName,
						width: defaultColumnWidth,
					});
				}
			});
			let totalColumnWidths = 0;
			_.forEach($scope.documentGridOptions.columnDefs, columnDef => {
				totalColumnWidths += columnDef.width ? columnDef.width : defaultColumnWidth;
			});
			$scope.documentGridOptions.api.onNewCols();
			$scope.documentGridOptions.api.onFilterChanged();
			if($("#documentGridContainer").width() > totalColumnWidths) {
				$scope.documentGridOptions.api.sizeColumnsToFit();
			}
		});
	};

	$scope.getObjectGeometryIds = function () {
		$scope.isFetchingGeometry = true;
		let uri;
		if(!window.eShare.integratedSecurity) {
			authorization.createOneTimeToken().then(
				data => {
					uri = ("api/project/{projectId}/DocumentGeometryIds/{dataSourceId}"
					+ "?documentId={documentId}&token={token}")
						.supplant({
							projectId: $scope.projectId,
							dataSourceId: $scope.currentDocument.dataSourceId,
							documentId: encodeURIComponent($scope.currentDocument.id),
							token: data.token,
						});
					getGeometry(uri);
				}
			);
		} else {
			uri = ("api/project/{projectId}/DocumentGeometryIds/{dataSourceId}"
			+ "?documentId={documentId}")
				.supplant({
					projectId: $scope.projectId,
					dataSourceId: $scope.currentDocument.dataSourceId,
					documentId: encodeURIComponent($scope.currentDocument.id),
				});
			getGeometry(uri);
		}
	};

	function getGeometry(uri) {
		$http.get(uri).then(response => {
			const geometryIds = cleanArray(response.data);
			searchService.result.allObjectGeometryIds = geometryIds;
			$scope.numberOfObjectsFound = geometryIds.length;
			if($scope.numberOfObjectsFound > 200000) {
				$scope.isFetchingGeometry = false;
				$scope.confirmExamine = true;
			} else if($scope.numberOfObjectsFound === 0) {
				notification.error("No objects found in the document");
				$scope.isFetchingGeometry = false;
			} else {
				$scope.examineAllObjects();
			}
		}, error => {
			notification.error("Error finding linked objects: " + error.data.message);
		});
	}

	$scope.examineAllObjects = function () {
		$scope.isFetchingGeometry = false;
		searchService.result.shouldExamineAllObjects = true;
		searchService.result.includeGroups = true;
		searchService.result.isDocumentExamine = true;
		searchService.result.text = $scope.currentDocument.name;
		$scope.confirmExamine = false;
		$state.go("project.model");
	};

	function cleanArray(array) {
		let cleanOne = _.map(array, id => {
			if(id !== -1) {
				return id;
			}
			return null;
		});
		cleanOne = _.without(cleanOne, null);
		array = _.uniq(cleanOne);
		return array;
	}

	function refreshDocumentTree() {
		documentTreeRepository
			.getFolderTree($scope.projectId, $scope.hierarchyData.selectedHierarchyName)
			.then(setDocumentHierarchy, error => {
				notification.error("Error loading document folder tree: " + error);
			});
	}

	const documentTabContainerElement = document.getElementById("documentTabContainer");
	const documentAttributePaneElement = document.getElementById("documentAttributePane");
	const browseViewPanelElement = document.getElementById("browseViewPanel");
	ResizeListener.add(documentTabContainerElement, onContainerResized);
	ResizeListener.add(documentAttributePaneElement, onContainerResized);
	onContainerResized();

	function onContainerResized() {
		if($state.current.name !== "project.document" || !browseViewPanelElement) {
			return;
		}
		const rightPaneSize = documentAttributePaneElement.offsetWidth;
		documentTabContainerElement.style.width = window.innerWidth - rightPaneSize - 7 + "px";
		if($scope.documentTabs.length > 0) {
			const uibTabElement = document.getElementById("uibTabElement");
			if(!uibTabElement) {
				return;
			}
			const documentTabRowElement = uibTabElement.firstElementChild;
			if(!documentTabRowElement) {
				return;
			}
			documentTabContainerElement.style.height = "100%";
			documentTabContainerElement.style.height =
					documentTabContainerElement.offsetHeight - 50
					- browseViewPanelElement.offsetHeight
					- (documentTabRowElement.offsetHeight - 43) + "px";
		}
	}

	$scope.printDocument = function () {
		printedDocumentInformation.document = $scope.currentDocument;
		const documentKey = getDocumentKey($scope.currentDocument);
		const viewer = $scope.viewerControl[documentKey].getViewer();
		const image = viewer.canvas.firstChild;
		printedDocumentInformation.image = image.toDataURL();
		printedDocumentInformation.currentPageNumber = $scope.docInfo.currentPageNumber;
		const bounds = viewer.viewport.getBoundsNoRotate(true);
		printedDocumentInformation.bounds = {
			x: bounds.x.toFixed(2),
			y: bounds.y.toFixed(2),
			width: bounds.width.toFixed(2),
			height: bounds.height.toFixed(2),
		};
		$state.go("project.document.print", { projectId: $scope.projectId });
	};

	function moveToDziLocation(x, y, width, height, pageNumber) {
		pageNumber = parseInt(pageNumber || "");
		if(isNaN(pageNumber) || pageNumber < 0) {
			pageNumber = 0;
		}
		const documentKey = getDocumentKey($scope.currentDocument);
		$scope.viewerControl[documentKey].focusByCoordinates(pageNumber, x, y, width, height, true);
	}

	function moveToAreaLocation(documentKey, areaId) {
		$scope.viewerControl[documentKey].focusByAreaId(areaId);
	}

	$scope.isChangingRevision = false;

	$scope.changeRevision = function () {
		$scope.isChangingRevision = true;
		const revisionToChangeTo = $scope.currentRevision.id;
		if(revisionToChangeTo == null) {
			$scope.isChangingRevision = false;
			return;
		}
		documentTreeRepository
			.getDocumentInfo($scope.projectId, $scope.currentDocument.dataSourceId, revisionToChangeTo)
			.then(dto => {
				openDocument(dto);
				$scope.isChangingRevision = false;
			})
			.catch((/*err*/) => {
				$scope.isChangingRevision = false;
			});
	};

	$scope.compareToRevision = function (otherRevision) {
		const dto = _.cloneDeep($scope.currentDocument);
		dto.isComparison = true;
		const pipeIndex = dto.id.indexOf("|");
		const currentRev = _.find(dto.revisions, { id: dto.id })
			|| _.find(dto.revisions, { id: dto.id.substring(pipeIndex + 1) });
		const revisions = _.orderBy([currentRev, otherRevision], ["lastModified"], ["asc"]);
		let revisionName = revisions[0].name || "zero";
		let revisionName2 = revisions[1].name || "zero";
		if(revisionName === revisionName2) {
			revisionName += " " + revisions[0].dateString;
			revisionName2 += " " + revisions[1].dateString;
		}

		dto.name = "Comparing " + dto.name + " rev. " + revisionName + " with " + revisionName2;
		dto.revisionName = revisionName;
		dto.revisionId = revisions[0].id;
		dto.otherRevisionName = revisionName2;
		dto.otherRevisionId = revisions[1].id;
		dto.revisions = [];
		dto.id = angular.toJson([revisions[0].id, revisions[1].id]);
		openDocument(dto);
	};

	$scope.openOtherDocument = function (documentId, dataSourceId) {
		const obj = {
			documentId: documentId,
			dataSourceId: dataSourceId,
		};
		documentRequested(obj);
	};
}
