angular.module("eShareApp").directive("cadFileTypes", cadFileTypes);

cadFileTypes.$inject = [];

function cadFileTypes() {
	return {
		restrict: "E",
		scope: {},
		bindToController: {
			dsc: "=",
			isEditing: "=",
		},
		controller: CadFileTypesController,
		controllerAs: "vm",
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadFileTypes.html'),
		replace: false,
	};
}

CadFileTypesController.$inject = [];

function CadFileTypesController() {
	const vm = this;
	vm.fileTypeSettings = angular.fromJson(vm.dsc.ddsbFileTypeSettings);

	vm.onChange = function () {
		vm.dsc.ddsbFileTypeSettings = angular.toJson(vm.fileTypeSettings);
	};
}