angular.module("eShare.ui").directive("cadUiSelectFix", cadUiSelectFixDirective);

cadUiSelectFixDirective.$inject = [];

function cadUiSelectFixDirective() {
	return {
		restrict: "A",
		link: link,
	};

	function link(scope, elem) {
		const span = angular.element(elem).find("span.ui-select-toggle");
		span.removeClass("btn").removeClass("btn-default");
	}
}
