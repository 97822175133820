angular
	.module("eShareApp")
	.controller("ModelImporterExportImportCtrl", ModelImporterExportImportCtrl);

ModelImporterExportImportCtrl.$inject = [
	"$state", "notification", "isExport", "isImport", "isSingle", "project",
	"modelImportersRepository"
];

function ModelImporterExportImportCtrl(
	$state, notification, isExport, isImport, isSingle, project,
	modelImportersRepository
) {
	const vm = this;
	vm.isExport = isExport;
	vm.isImport = isImport;
	vm.serializedExportedData = "";
	vm.importResult = null;
	vm.importAll = importAll;
	vm.isInputValid = isInputValid;
	vm.isBusy = false;
	vm.isSingle = isSingle;
	vm.shouldOverwriteDefault = false;

	const stateName = $state.current.name;

	initialize();

	function initialize() {
		if(isExport) {
			vm.serializedExportedData = "Please wait...";
			if(!vm.isSingle) {
				modelImportersRepository.exportAll(project.id).then(
					serializedExportedData => {
						vm.serializedExportedData = serializedExportedData;
					}
				);
			} else {
				modelImportersRepository.get(project.id, $state.params.importerId).then(
					importer => {
						vm.serializedExportedData = JSON.stringify(importer, undefined, 3);
					}
				);
			}
		}
	}

	function importAll() {
		vm.importResult = null;
		let isSingleImporter = true;
		let exportedData = null;
		try {
			exportedData = JSON.parse(vm.serializedExportedData);
			isSingleImporter = !Utilities.isArray(exportedData.modelImporterConfigurations);
		} catch(e) {
			// do nothing
		}
		vm.isBusy = true;
		if(isSingleImporter) {
			modelImportersRepository
				.importConfiguration(project.id, exportedData, vm.shouldOverwriteDefault)
				.then(upgradedConfiguration => {
					vm.importResult = {
						hasSucceeded: true,
						isValid: true,
						errors: [],
						warnings: [],
					};
					notification.success("Imported Model Importer");
					if($state.current.name === stateName) {
						$state.go("project.admin.model.importer.edit", {
							projectId: project.id,
							importerId: upgradedConfiguration.id,
						}, {
							reload: true,
						});
					}
				},
				errorMessage => {
					vm.isBusy = false;
					vm.importResult = {
						hasSucceeded: false,
						isValid: true,
						errors: ["Model Importer import failed. Reason: " + errorMessage],
						warnings: [],
					};
				});
		} else {
			modelImportersRepository
				.importAll(project.id, vm.serializedExportedData, vm.shouldOverwriteDefault)
				.then(importResult => {
					if(importResult.hasSucceeded
							&& (!importResult.warnings || importResult.warnings.length === 0)) {
						notification.success("Imported Model Importers");
						if($state.current.name === stateName) {
							$state.go("^", {}, { reload: true });
						}
					} else {
						vm.isBusy = false;
						if(importResult.hasSucceeded) {
							notification.success("Imported Model Importers, with warnings");
						}
						vm.importResult = importResult;
					}
				});
		}
	}

	function isInputValid() {
		return Utilities.isJsonObject(vm.serializedExportedData);
	}
}
