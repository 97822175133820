angular.module("eShare.core").factory("debounce", debounce);

debounce.$inject = ["$timeout"];

function debounce($timeout) {
	return function (callback, interval) {
		let timeout = null;
		return function () {
			const args = arguments;
			if(timeout) {
				$timeout.cancel(timeout);
			}
			if(!interval) {
				interval = 0;
			}
			timeout = $timeout(function () {
				callback.apply(this, args);
			}, interval);
		};
	};
}
