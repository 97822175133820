angular.module("eShareApp").controller("AdapterViewCtrl", [
	"$state", "$scope", "$timeout", "adapter", "messageBox", "$compile", "availableDataSourceTypes",
	"adaptersRepository", "adapterConfigurationView", "adapterConfigurationScript", "notification",
	"$injector",

	function (
		$state, $scope, $timeout, adapter, messageBox, $compile, availableDataSourceTypes,
		adaptersRepository, adapterConfigurationView, adapterConfigurationScript, notification,
		$injector
	) {

		$scope.adapter = adapter;
		$scope.adapterConfig = adapter.configurationParameters;

		$scope.dataSource = null;
		$scope.$on("DataSourceInfoExtracted", (evt, ds) => {
			$scope.dataSource = ds;
		});

		$scope.isAdapterInEditMode = false;

		$scope.performAdapterAction = function (action) {
			return adaptersRepository.performAdapterAction($scope.project.id, $scope.adapter, action);
		};

		$timeout(() => {
			$scope.adapterScript = eval(adapterConfigurationScript)($scope, $injector);
			const adapterConfigurationArea = $("#adapterConfigurationArea");
			adapterConfigurationArea.html(adapterConfigurationView);
			$compile(adapterConfigurationArea.contents())($scope);
		}, 0);

		$scope.selectDataSource = function (dataSource) {
			$scope.dataSource = dataSource;
		};

		$scope.toggleDataSourceIsActive = function (dataSource) {
			$scope.isSavingDataSource = true;
			dataSource.isActive = !dataSource.isActive;
			for(const i in $scope.adapter.dataSourceConfigurations) {
				if($scope.adapter.dataSourceConfigurations.hasOwnProperty(i)) {
					if($scope.adapter.dataSourceConfigurations[i].id === dataSource.id) {
						$scope.adapter.dataSourceConfigurations[i] = _.cloneDeep(dataSource);
						break;
					}
				}
			}
			adaptersRepository.update($scope.project.id, $scope.adapter)
				.then(
					() => {
						$scope.isSavingDataSource = false;
					},
					message => {
						messageBox.openError(
							"Error",
							"Error saving data source configuration: " + message
						);
						$scope.isSavingDataSource = false;
					}
				);
		};

		$scope.removeDataSource = function (dataSource) {
			const mbox = messageBox.openQuestion(
				"Remove selected data source?",
				"Do you really want to remove data source " + dataSource.name + "?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					adapter = _.cloneDeep($scope.adapter);
					adaptersRepository.removeDataSource($scope.project.id, adapter, dataSource.id)
						.then(
							() => {
								$scope.adapter = adapter;
							}, error => {
								notification.error(error);
							}
						);
				}
			);
		};

		$scope.isKnownDataSourceType = function (dataSourceTypeGuid) {
			return !Utilities.isNullOrUndefined(getDataSourceType(dataSourceTypeGuid));
		};

		$scope.getDataSourceTypeName = function (dataSourceTypeGuid) {
			const dataSourceType = getDataSourceType(dataSourceTypeGuid);
			return Utilities.isNullOrUndefined(dataSourceType)
				? "Unknown (GUID:" + dataSourceTypeGuid + ")"
				: dataSourceType.name;
		};

		function getDataSourceType(dataSourceTypeGuid) {
			return _.find(availableDataSourceTypes, dst => {
				return dst.typeGuid === dataSourceTypeGuid;
			});
		}
	}
]);
