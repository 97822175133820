angular.module("eShareApp").filter("formatAttributeValue", formatAttributeValue);

function formatAttributeValue() {
	return formatAttributeValueFilterFunction;
}

function formatAttributeValueFilterFunction(attribute, emptyValue) {
	emptyValue = emptyValue || "";
	if(Utilities.isNullOrUndefined(attribute) || Utilities.isNullOrUndefined(attribute.value)) {
		return emptyValue;
	}
	switch(attribute.dataType) {
	case "unixDate":
		return localizeDate(attribute.value) || emptyValue;
	default:
		return attribute.value;
	}
}

function localizeDate(utcDateTimeString) {
	const date = moment.utc(utcDateTimeString);
	return date.isValid() ? date.local().format("LLL") : utcDateTimeString;
}
