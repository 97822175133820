angular.module("eShareApp").directive("cadRegexAttributeTransformation", [
	"notification", "attributeTransformationsRepository",
	function (notification, attributeTransformationsRepository) {
		return {
			restrict: "A",
			replace: true,
			scope:
			{
				definitions: "=",
				transformation: "=",
				projectId: "=",
				modelId: "=",
				allTransformations: "=",
			},
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadRegexAttributeTransformation.html'),
			controller: [
				"$scope", "$rootScope",
				function ($scope, $rootScope) {
					$scope.sourceAttribute = _.find(
						$scope.definitions,
						{ abbreviation: $scope.transformation.abbreviation }
					);
					if(Utilities.isNullOrUndefined($scope.sourceAttribute)) {
						$scope.sourceAttribute = _.head(_.sortBy($scope.definitions, "displayName"));
					}
					$scope.testResults = {
						sourceDefinitions: [],
						targetDefinitions: [],
						matchingResults: [],
						nonMatching: [],
					};
					$scope.expanded = false;
					$scope.testButtonDisabled = false;
					$scope.isValid = function () {
						if(!$scope.sourceAttribute || !$scope.sourceAttribute.abbreviation) {
							return false;
						}
						if(!$scope.transformation
							|| !$scope.transformation.pattern
							|| $scope.transformation.pattern.trim() === "") {
							return false;
						}
						return _.every($scope.transformation.attributes, attribute => {
							if(attribute.abbreviationDuplicated) {
								return false;
							}
							return true;
						});
					};

					$scope.$watch("sourceAttribute", (newValue /*, oldValue*/) => {
						if($scope.transformation && newValue && newValue.abbreviation) {
							$scope.transformation.abbreviation = newValue.abbreviation;
							$scope.transformation.displayName = newValue.displayName;
						}
					});
					$scope.$watch("transformation.pattern", newValue => {
						const re = /\(\?<([^>]*)>/g;
						let match;
						const abbreviationsInPattern = [];
						$scope.transformation.attributes = $scope.transformation.attributes || [];
						while((match = re.exec(newValue))) {
							abbreviationsInPattern.push(match[1]);
							if(!_.some($scope.transformation.attributes, { abbreviation: match[1] })) {
								// If this is a new abbreviation, add it to the list of attributes:
								$scope.transformation.attributes.push({
									abbreviation: match[1],
									displayName: match[1],
								});
							}
						}
						// Check for removed abbreviations:
						const allAbbreviations = _.map($scope.transformation.attributes, "abbreviation");
						for(const idx in allAbbreviations) {
							if(!_.includes(abbreviationsInPattern, allAbbreviations[idx])) {
								_.remove(
									$scope.transformation.attributes,
									{ abbreviation: allAbbreviations[idx] }
								);
							}
						}
					});

					$scope.toggleExpand = function () {
						$scope.expanded = !$scope.expanded;
					};

					function validate() {
						if(!$scope.sourceAttribute || !$scope.sourceAttribute.abbreviation) {
							notification.error("A source attribute must be selected");
							return false;
						}
						if(!$scope.transformation
							|| !$scope.transformation.pattern
							|| $scope.transformation.pattern.trim() === "") {
							notification.error("A pattern to match must be entered");
							return false;
						}
						return _.every($scope.transformation.attributes, attribute => {
							if(attribute.abbreviationDuplicated) {
								notification.error("An abbreviation in the pattern has already been used");
								return false;
							}
							return true;
						});
					}

					function formatTestResults() {
						for(let j = 0; j < $scope.testResults.matchingResults.length; j++) {
							const match = $scope.testResults.matchingResults[j];
							match.inputMap = {};
							match.outputMap = {};
							for(let i = 0; i < match.inputValues.length; i++) {
								match.inputMap[match.inputValues[i].abbreviation] =
									match.inputValues[i].value;
							}
							for(let i = 0; i < match.outputValues.length; i++) {
								match.outputMap[match.outputValues[i].abbreviation] =
									match.outputValues[i].value;
							}
						}
						for(let j = 0; j < $scope.testResults.nonMatchingResults.length; j++) {
							const match = $scope.testResults.nonMatchingResults[j];
							match.inputMap = {};
							for(let i = 0; i < match.inputValues.length; i++) {
								match.inputMap[match.inputValues[i].abbreviation] =
									match.inputValues[i].value;
							}
						}
					}

					$scope.tryPattern = function () {
						if(!validate()) {
							return;
						}
						$scope.testButtonDisabled = true;
						const index = _.indexOf($scope.allTransformations, $scope.transformation);
						attributeTransformationsRepository.test(
							$scope.projectId,
							$scope.modelId,
							$scope.allTransformations,
							index
						).then(data => {
							$scope.testResults = data;
							formatTestResults();
							$scope.expanded = true;
							$scope.testButtonDisabled = false;
						}, error => {
							notification.error("Error testing pattern: " + error);
							$scope.testButtonDisabled = false;
						});
					};

					$scope.deleteTransformation = function () {
						$rootScope.$broadcast("attributeTransformation:delete", $scope.transformation);
					};
				}
			],
		};
	}
]);