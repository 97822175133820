angular.module("eShareApp").factory("mapsRepository", mapsRepository);

mapsRepository.$inject = ["repositoryBase"];

function mapsRepository(repositoryBase) {
	const baseApi = "api/project/{projectId}/Maps";
	const getMapsAndPointInformationApi = "api/project/{projectId}/GetMapsAndPointInformation";
	const getCoordinateZPlanesApi = "api/project/{projectId}/GetCoordinateZPlanes";
	const isMapsBusyApi = "api/project/{projectId}/IsMapsBusy";
	const singleMapApi = baseApi + "/{mapId}";
	const pointLocationsApi = singleMapApi + "/PointLocations";
	const deleteApi = singleMapApi + "/Delete";
	const saveApi = singleMapApi + "/Save";

	return {
		getAll: getAll,
		getMap: getMap,
		save: save,
		saveAllMaps: saveAllMaps,
		remove: remove,
		getPointLocations: getPointLocations,
		getCoordinateZPlanes: getCoordinateZPlanes,
		isMapsBusy: isMapsBusy,
		getMapsAndPointInformation: getMapsAndPointInformation,
	};

	function getAll(projectId) {
		return repositoryBase.get(baseApi, { projectId: projectId });
	}

	function getMapsAndPointInformation(projectId) {
		return repositoryBase.get(getMapsAndPointInformationApi, { projectId: projectId });
	}

	function getCoordinateZPlanes(projectId) {
		return repositoryBase.get(getCoordinateZPlanesApi, { projectId: projectId });
	}

	function saveAllMaps(projectId, maps) {
		return repositoryBase.post(baseApi, { projectId: projectId }, maps);
	}

	function getMap(projectId, mapId) {
		return repositoryBase.get(singleMapApi, { projectId: projectId, mapId: mapId });
	}

	function save(projectId, map) {
		return repositoryBase.post(saveApi, { projectId: projectId, mapId: map.id }, map);
	}

	function remove(projectId, mapId) {
		return repositoryBase.remove(deleteApi, { projectId: projectId, mapId: mapId });
	}

	function getPointLocations(projectId, mapId) {
		return repositoryBase.get(pointLocationsApi, { projectId: projectId, mapId: mapId });
	}

	function isMapsBusy(projectId) {
		return repositoryBase.get(isMapsBusyApi, { projectId: projectId });
	}
}