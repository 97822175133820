angular.module("eShareApp").factory("documentTreeRepository", [
	"repositoryBase",

	function (repositoryBase) {

		const baseApi = "/api/project";
		const folderTreeApi = baseApi
			+ "/{projectId}/DocumentTree/RecursiveFolderTree?hierarchyName={hierarchyName}";
		const folderDocumentApi = baseApi
			+ "/{projectId}/DocumentTree/Documents/{dataSourceId}";
		const subFoldersApi = baseApi
			+ "/{projectId}/DocumentTree/{dataSourceId}/SubFolders?hierarchyName={hierarchyName}";
		const documentDataSourcesExistApi = baseApi
			+ "/{projectId}/DocumentTree/DocumentDataSourcesExist";
		const documentInfoApi = baseApi
			+ "/{projectId}/DocumentTree/DocumentInfo/{dataSourceId}?documentId={documentId}";
		const removeDocumentApi = baseApi
			+ "/{projectId}/ManagedDocument/{documentId}";
		const revisionsApi = baseApi
			+ "/{projectId}/DocumentTree/Revisions/{dataSourceId}?documentId={documentId}";
		const metadataApi = baseApi
			+ "/{projectId}/DocumentTree/GetMetadataEntries/{dataSourceId}";

		function getFolderTree(projectId, hierarchyName) {
			return repositoryBase.get(
				folderTreeApi, { projectId: projectId, hierarchyName: hierarchyName }
			);
		}

		function getDocuments(projectId, dataSourceId, folderId) {
			return repositoryBase.post(
				folderDocumentApi,
				{ projectId: projectId, dataSourceId: dataSourceId },
				{ folderId: folderId }
			);
		}

		function getSubFolders(projectId, dataSourceId, folderId, hierarchyName) {
			return repositoryBase.post(
				subFoldersApi,
				{ projectId: projectId, dataSourceId: dataSourceId, hierarchyName: hierarchyName },
				{ folderId: folderId }
			);
		}

		function getDocumentInfo(projectId, dataSourceId, documentId) {
			return repositoryBase.get(
				documentInfoApi,
				{ projectId: projectId, dataSourceId: dataSourceId, documentId: documentId }
			);
		}

		function documentDataSourcesExist(projectId) {
			return repositoryBase.get(documentDataSourcesExistApi, { projectId: projectId });
		}

		function removeDocument(projectId, documentId) {
			return repositoryBase.remove(
				removeDocumentApi, { projectId: projectId, documentId: documentId }
			);
		}

		function getRevisionsForDocument(projectId, dataSourceId, documentId) {
			return repositoryBase.get(
				revisionsApi,
				{ projectId: projectId, dataSourceId: dataSourceId, documentId: documentId }
			);
		}

		function getMetadataEntries(projectId, dataSourceId) {
			return repositoryBase.get(
				metadataApi, { projectId: projectId, dataSourceId: dataSourceId }
			);
		}

		return {
			getFolderTree: getFolderTree,
			getDocuments: getDocuments,
			getDocumentInfo: getDocumentInfo,
			documentDataSourcesExist: documentDataSourcesExist,
			removeDocument: removeDocument,
			getRevisionsForDocument: getRevisionsForDocument,
			getSubFolders: getSubFolders,
			getMetadataEntries: getMetadataEntries,
		};
	}
]);
