angular.module("eShare.documentViewer").directive("cadFolderTree", cadFolderTree);

function cadFolderTree() {
	return {
		restrict: "E",
		controller: CadFolderTreeController,
		controllerAs: "vm",
		bindToController: {
			folders: "=",
			folderSelected: "&",
			folderExpanded: "&",
		},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadFolderTree.directive.html'),
		scope: {},
		/*
		We cannot use "replace: true" here, as it creates isolated scope,
		and things blow up with the $compile:multidir error.
		More info at e.g.
		http://stackoverflow.com/questions/20609770/nested-e-directives-multiple-directives-asking-for-new-isolated-scope
		*/
	};
}

CadFolderTreeController.$inject = ["$scope", "$rootScope", "$stateParams", "permissionsRepository"];

// ReSharper disable once InconsistentNaming
function CadFolderTreeController($scope, $rootScope, $stateParams, permissionsRepository) {
	const vm = this;
	vm.visibleFolders = null;
	vm.selectFolder = selectFolder;
	vm.toggleIsCollapsed = toggleIsCollapsed;

	$scope.$watch(
		() => {
			return vm.folders;
		},
		onFoldersUpdated
	);

	$scope.projectId = $stateParams.projectId;

	permissionsRepository.canUserAdministerProject($scope.projectId).then(result => {
		$scope.canAdministerProject = result;
	});

	function selectFolder(folder) {
		performFolderSelection(vm.folders, folder);
		if(vm.folderSelected) {
			const handler = vm.folderSelected();
			handler(folder);
		}
	}

	function toggleIsCollapsed(folder) {
		clearSelection();
		if(!folder || !folder.isCollapsible) {
			return;
		}
		if(folder.isCollapsed
			&& vm.folderExpanded
			&& folder.isRecursive
			&& (!folder.subFolders || folder.subFolders.length == 0)) {
			const handler = vm.folderExpanded();
			handler(folder, onFolderRetrieved);
		}
		folder.isCollapsed = !folder.isCollapsed;
		if(folder.isCollapsed) {
			collapseAllSubFolders(folder);
		}
		vm.visibleFolders = getVisibleFolders(vm.folders);

		function collapseAllSubFolders(folder) {
			if(folder && Utilities.isArray(folder.subFolders)) {
				for(let i = 0; i < folder.subFolders.length; ++i) {
					const subFolder = folder.subFolders[i];
					subFolder.isCollapsed = subFolder.isCollapsible;
					collapseAllSubFolders(subFolder);
				}
			}
		}
	}

	function onFolderRetrieved(folder, subFolders) {
		folder.subFolders = subFolders;
		if(!subFolders || subFolders.length === 0) {
			folder.isCollapsible = false;
		} else {
			_.forEach(folder.subFolders, subFolder => {
				subFolder.level = folder.level + 1;
				subFolder.isCollapsible = true;
				subFolder.isCollapsed = true;
			});
		}
		sortFolders(vm.folders);
		vm.visibleFolders = getVisibleFolders(vm.folders);
	}

	function onFoldersUpdated() {
		sortFolders(vm.folders);
		collapseAll(vm.folders);
		vm.visibleFolders = getVisibleFolders(vm.folders);
	}

	function sortFolders(folders) {
		if(!Utilities.isArray(folders)) {
			return;
		}
		folders.sort(Utilities.caseInsensitiveComparator("name"));
		for(let i = 0; i < folders.length; ++ i) {
			sortFolders(folders[i].subFolders);
		}
	}

	function collapseAll(folders, level) {
		if(!Utilities.isArray(folders)) {
			return;
		}
		if(!Utilities.isInteger(level)) {
			level = 0;
		}
		for(let i = 0; i < folders.length; ++i) {
			const folder = folders[i];
			folder.level = level;
			folder.isCollapsible = folder.isRecursive
				|| (Utilities.isArray(folder.subFolders)
				&& folder.subFolders.length > 0);
			folder.isCollapsed = folder.isCollapsible;
			collapseAll(folder.subFolders, level + 1);
		}
	}

	function getVisibleFolders(folders) {
		const visibleFolders = [];
		traverseFolders(folders);
		return visibleFolders;

		function traverseFolders(folders) {
			if(!Utilities.isArray(folders)) {
				return;
			}
			for(let i = 0; i < folders.length; ++i) {
				const folder = folders[i];
				visibleFolders.push(folder);
				if(folder.isCollapsible && !folder.isCollapsed || !folder.isCollapsible) {
					traverseFolders(folder.subFolders);
				}
			}
		}

	}

	function performFolderSelection(folders, folderToSelect) {
		if(!folders || !Utilities.isArray(folders)) {
			return;
		}
		for(let i = 0; i < folders.length; ++i) {
			const folder = folders[i];
			folder.isSelected = folder === folderToSelect;
			performFolderSelection(folder.subFolders, folderToSelect);
		}
	}

	function clearSelection() {
		if(document.selection && document.selection.empty) {
			document.selection.empty();
		} else if(window.getSelection) {
			const selection = window.getSelection();
			selection.removeAllRanges();
		}
	}

	$scope.refreshDocumentTree = function () {
		$rootScope.$broadcast("documentTreeView::getFolders");
	};
}