angular.module("eShareApp").factory("documentConfigurationsRepository", [
	"repositoryBase",

	function (repositoryBase) {

		const baseApi = "/api/project";
		const pdfDocumentConfigurationsApi = baseApi + "/{projectId}/PdfDocumentConfigurations";
		const documentAttributesApi = baseApi + "/{projectId}/ManagedDocumentMetadataAttributeName";
		const documentAttributesWithValuesApi =
			baseApi + "/{projectId}/ManagedDocumentMetadataAttributeNameWithValue";
		const documentHierarchiesApi = baseApi + "/{projectId}/DocumentTree/DocumentHierarchy/";
		const hasAnyDocumentsApi = baseApi + "/{projectId}/HasAnyDocuments";
		const highlightModeApi = baseApi + "/{projectId}/ManagedDocumentHighlightMode";
		const permissionsApi = baseApi + "/{projectId}/ManagedDocumentPermissions";
		const indexingSettingsApi = baseApi + "/{projectId}/ManagedDocumentIndexingSettings";
		const managedSettingsApi = baseApi + "/{projectId}/ManagedDocumentRevisionSettings";
		const documentForConfigurationApi = baseApi + "/{projectId}/DocumentForConfiguration";
		const documentSizeApi =
			baseApi + "/{projectId}/Document/{dataSourceId}/Size?documentId={documentId}";
		const documentMetadataExtractionApi =
			baseApi + "/{projectId}/Document/{dataSourceId}/ExtractedMetadata?documentId={documentId}";

		function getPdfSettings(projectId) {
			return repositoryBase.get(pdfDocumentConfigurationsApi, { projectId: projectId });
		}

		function savePdfSettings(projectId, pdfDocumentConfigurations) {
			return repositoryBase.post(
				pdfDocumentConfigurationsApi, { projectId: projectId }, pdfDocumentConfigurations
			);
		}

		function getDocumentAttributes(projectId) {
			return repositoryBase.get(documentAttributesApi, { projectId: projectId });
		}

		function getDocumentAttributesWithValues(projectId) {
			return repositoryBase.get(documentAttributesWithValuesApi, { projectId: projectId });
		}

		function getHierarchySettings(projectId) {
			return repositoryBase
				.get(documentHierarchiesApi, { projectId: projectId })
				.then(hierarchies => {
					hierarchies = _.sortBy(hierarchies, h => {
						return (h.name || "").toLowerCase();
					});
					return hierarchies;
				});
		}

		function saveHierarchySettings(projectId, hierarchies) {
			return repositoryBase.post(documentHierarchiesApi, { projectId: projectId }, hierarchies);
		}

		function hasAnyManagedDocuments(projectId) {
			return repositoryBase.get(hasAnyDocumentsApi, { projectId: projectId });
		}

		function getManagedDocumentIndexingSettings(projectId) {
			return repositoryBase.get(indexingSettingsApi, { projectId: projectId });
		}

		function saveManagedDocumentIndexingSettings(projectId, indexingSettings) {
			const settingsData = JSON.parse(indexingSettings.indexingMode);
			indexingSettings.indexingMode = settingsData;
			return repositoryBase.post(
				indexingSettingsApi, { projectId: projectId }, indexingSettings
			);
		}

		function getManagedDocumentHighlightMode(projectId) {
			return repositoryBase.get(highlightModeApi, { projectId: projectId });
		}

		function saveManagedDocumentHighlightMode(projectId, highlightMode) {
			return repositoryBase.post(highlightModeApi, { projectId: projectId }, highlightMode);
		}

		function exportDocumentHierarchies(projectId) {
			const uri = Utilities.combineUri(documentHierarchiesApi, "export");
			return repositoryBase.post(uri, { projectId: projectId }).then(
				exportedData => {
					return JSON.stringify(exportedData, undefined, 3);
				}
			);
		}

		function importDocumentHierarchies(projectId, serializedExportedData) {
			const exportedData = JSON.parse(serializedExportedData);
			const uri = Utilities.combineUri(documentHierarchiesApi, "import");
			return repositoryBase.post(uri, { projectId: projectId }, exportedData);
		}

		function exportDocumentTypes(projectId) {
			const uri = Utilities.combineUri(pdfDocumentConfigurationsApi, "export");
			return repositoryBase.post(uri, { projectId: projectId }).then(
				exportedData => {
					return JSON.stringify(exportedData, removeObsoleteVariables, 3);
				}
			);
		}

		function importDocumentTypes(projectId, serializedExportedData) {
			const exportedData = JSON.parse(serializedExportedData);
			const uri = Utilities.combineUri(pdfDocumentConfigurationsApi, "import");
			return repositoryBase.post(uri, { projectId: projectId }, exportedData);
		}

		function exportDocumentIndexing(projectId) {
			const uri = Utilities.combineUri(indexingSettingsApi, "export");
			return repositoryBase.post(uri, { projectId: projectId }).then(
				exportedData => {
					return JSON.stringify(exportedData, removeObsoleteVariables, 3);
				}
			);
		}

		function importDocumentIndexing(projectId, serializedExportedData) {
			const exportedData = JSON.parse(serializedExportedData);
			const uri = Utilities.combineUri(indexingSettingsApi, "import");
			return repositoryBase.post(uri, { projectId: projectId }, exportedData);
		}

		function getManagedDocumentPermissions(projectId) {
			return repositoryBase.get(permissionsApi, { projectId: projectId });
		}

		function saveManagedDocumentPermissions(projectId, permissions) {
			return repositoryBase.post(permissionsApi, { projectId: projectId }, permissions);
		}

		function getManagedDocumentSavingSettings(projectId) {
			return repositoryBase.get(managedSettingsApi, { projectId: projectId });
		}

		function updateManagedDocumentSavingSettings(projectId, optionsDto) {
			return repositoryBase.put(managedSettingsApi, { projectId: projectId }, optionsDto);
		}

		function getDocumentForConfiguration(projectId, documentTypeConfiguration) {
			return repositoryBase.post(
				documentForConfigurationApi, { projectId: projectId }, documentTypeConfiguration
			);
		}

		function getDocumentSize(projectId, dataSourceId, documentId) {
			return repositoryBase.get(
				documentSizeApi,
				{ projectId: projectId, dataSourceId: dataSourceId, documentId: documentId }
			);
		}

		function getDocumentExtractedMetadata(
			projectId, dataSourceId, documentId, documentTypeConfiguration
		) {
			return repositoryBase.post(
				documentMetadataExtractionApi,
				{ projectId: projectId, dataSourceId: dataSourceId, documentId: documentId },
				documentTypeConfiguration
			);
		}

		function removeObsoleteVariables(key, value) {
			if(key === "colorLinkColors"
				|| key === "showStatusTrackingLegend"
				|| key === "statusTrackingColors"
				|| key === "usedStatusTrackers"
				|| key === "useStatusTracking") {
				return undefined;
			}
			return value;
		}

		return {
			getPdfSettings: getPdfSettings,
			savePdfSettings: savePdfSettings,
			getDocumentAttributes: getDocumentAttributes,
			getHierarchySettings: getHierarchySettings,
			saveHierarchySettings: saveHierarchySettings,
			hasAnyManagedDocuments: hasAnyManagedDocuments,
			exportDocumentHierarchies: exportDocumentHierarchies,
			importDocumentHierarchies: importDocumentHierarchies,
			exportDocumentTypes: exportDocumentTypes,
			importDocumentTypes: importDocumentTypes,
			exportDocumentIndexing: exportDocumentIndexing,
			importDocumentIndexing: importDocumentIndexing,
			getManagedDocumentHighlightMode: getManagedDocumentHighlightMode,
			saveManagedDocumentHighlightMode: saveManagedDocumentHighlightMode,
			getManagedDocumentPermissions: getManagedDocumentPermissions,
			saveManagedDocumentPermissions: saveManagedDocumentPermissions,
			getManagedDocumentSavingSettings: getManagedDocumentSavingSettings,
			updateManagedDocumentSavingSettings: updateManagedDocumentSavingSettings,
			getDocumentForConfiguration: getDocumentForConfiguration,
			getDocumentSize: getDocumentSize,
			getDocumentAttributesWithValues: getDocumentAttributesWithValues,
			getDocumentExtractedMetadata: getDocumentExtractedMetadata,
			getManagedDocumentIndexingSettings: getManagedDocumentIndexingSettings,
			saveManagedDocumentIndexingSettings: saveManagedDocumentIndexingSettings,
		};
	}
]);
