angular.module("eShareApp").directive("httpActivityWidget", [
	"httpActivityNotificationChannel",
	function (httpActivityNotificationChannel) {

		return {
			restrict: "A",
			link: function (scope, element) {
				const originalDisplayValue = element.css("display");

				function showElement() {
					element.css("display", originalDisplayValue);
				}

				function hideElement() {
					element.css("display", "none");
				}

				hideElement();

				httpActivityNotificationChannel.onActivityStarted(scope, showElement);
				httpActivityNotificationChannel.onActivityEnded(scope, hideElement);
			},
		};
	}
]);