angular.module("eShareApp").controller("AttributeCategorizationEditCtrl", [
	"$rootScope", "$scope", "$state", "project", "attributeCategorization",
	"attributeCategorizationRepository", "messageBox", "notification", "$timeout", "colorService",
	"dirtyFormTrackingService", "groups", "permissions", "categorizationService",

	function (
		$rootScope, $scope, $state, project, attributeCategorization,
		attributeCategorizationRepository, messageBox, notification, $timeout, colorService,
		dirtyFormTrackingService, groups, permissions, categorizationService
	) {

		$scope.state = $state;
		$scope.project = project;
		$scope.attributeCategorization = attributeCategorization;
		const categories = attributeCategorization.categories;

		function onChanged() {
			$scope.formScope.form.$setDirty();
		}

		function refreshCategories() {
			$scope.formScope.form.$setDirty();
			$scope.categorization.uncategorized.type = "Uncategorized";
			$scope.categorization.multipleCategories.type = "MultipleCategories";
			const categories = _.cloneDeep($scope.categorization.categories);
			categories.push($scope.categorization.uncategorized);
			categories.push($scope.categorization.multipleCategories);
			const multiCategoryBehavior = $scope.categorization.multiCategoryBehavior;
			const multiVisualStyleBehavior = $scope.categorization.multiVisualStyleBehavior;
			const isCaseSensitive = $scope.categorization.isCaseSensitive;
			const categorizationConfiguration = [
				{
					name: "categories",
					value: JSON.stringify(categories),
				},
				{
					name: "multiCategoryBehavior",
					value: multiCategoryBehavior,
				},
				{
					name: "multiVisualStyleBehavior",
					value: multiVisualStyleBehavior,
				},
				{
					name: "isCaseSensitive",
					value: isCaseSensitive,
				}
			];
			return attributeCategorizationRepository.getAttributeValues2(
				$scope.project.id,
				$scope.categorization.id,
				categorizationConfiguration
			);
		}

		categorizationService.setup(JSON.stringify(categories), onChanged, refreshCategories);
		const catService = categorizationService;
		//Parse special categories from categories before assigning the service to scope.
		for(let i = 0; i < catService.categories.length; i++) {
			const category = catService.categories[i];
			if(category.categoryType === "Uncategorized") {
				catService.uncategorized = catService.categories.splice(i, 1)[0];
				i--;
			}
			if(category.categoryType === "MultipleCategories") {
				catService.multipleCategories = catService.categories.splice(i, 1)[0];
				i--;
			}
		}
		$scope.categorization = catService;
		$scope.categorization.displayName = $scope.attributeCategorization.displayName;
		$scope.categorization.name = $scope.attributeCategorization.name;
		$scope.categorization.abbreviation = $scope.attributeCategorization.abbreviation;
		$scope.categorization.id = $scope.attributeCategorization.id;
		$scope.categorization.definesVisualStyle = $scope.attributeCategorization.definesVisualStyle;
		$scope.categorization.definesHierarchy = $scope.attributeCategorization.definesHierarchy;
		$scope.categorization.isInUse = $scope.attributeCategorization.isInUse;
		$scope.categorization.multiVisualStyleBehavior =
			$scope.attributeCategorization.multiVisualStyleBehavior;
		$scope.categorization.multiCategoryBehavior =
			$scope.attributeCategorization.multiCategoryBehavior;
		$scope.categorization.groups = groups;
		$scope.categorization.isCaseSensitive = $scope.attributeCategorization.isCaseSensitive;
		_.forEach($scope.categorization.groups, group => {
			group.hasPermission = _.some(permissions, permission => {
				return permission.userGroupId === group.id;
			});
		});

		$scope.hasAllowedGroups = function () {
			return _.some($scope.categorization.groups, group => {
				return group.hasPermission;
			});
		};

		initializeCategory();

		function initializeCategory() {
			$scope.categorization.freshRefresh = false;
			$scope.categorization.refreshButtonEnabled = true;
			attributeCategorizationRepository
				.getAttributeDefinitionUsage(project.id, $scope.categorization.abbreviation)
				.then(
					count => {
						$scope.categorization.usageCount = count;
					}, error => {
						notification.error(
							"Error determining the number of values for attribute: " + error
						);
					}
				);
		}

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form", true);
		};

		$scope.saveCategorization = function () {
			$scope.categorization.allowedGroups = _.map(_.filter($scope.categorization.groups,
				group => {
					return group.hasPermission;
				}), group => {
				return group.id;
			});
			const categorization = _.cloneDeep($scope.categorization);
			categorization.uncategorized.categoryType = "Uncategorized";
			categorization.multipleCategories.categoryType = "MultipleCategories";
			let uncategorizedCategoryAdded = false;
			let multipleCategoryAdded = false;
			_.forEach(categorization.categories, category => {
				if(category.categoryType === "Uncategorized") {
					category = categorization.uncategorized;
					uncategorizedCategoryAdded = true;
				}
				if(category.categoryType === "MultipleCategories") {
					category = categorization.multipleCategories;
					multipleCategoryAdded = true;
				}
			});
			if(!uncategorizedCategoryAdded) {
				categorization.categories.push(categorization.uncategorized);
			}
			if(!multipleCategoryAdded) {
				categorization.categories.push(categorization.multipleCategories);
			}

			attributeCategorizationRepository.save(project.id, categorization).then(() => {
				notification.success("Categorization saved successfully");
				$timeout(() => {
					$scope.formScope.form && $scope.formScope.form.$setPristine();
					$state.go("^", {}, { reload: true });
				}, 0);
			}, errorMessage => {
				notification.error("Error saving attribute categorization: " + errorMessage);
			});
		};

		$scope.removeAttribute = function (attribute) {
			const mbox = messageBox.openQuestion(
				"Remove categorization?",
				"Are you sure you want to remove the categorization by "
				+ attribute.displayName + "? This cannot be undone.",
				"Remove", "Cancel"
			);
			mbox.result.then(() => {
				_.remove($scope.attributeCategorizations, attribute);
				$scope.formScope.form.$setDirty();
			});
		};

		function sortCategories(attribute, questionTitle, criteria, isAscending) {
			const mbox = messageBox.openQuestion(
				questionTitle,
				"This operation cannot be undone.",
				"Sort", "Cancel"
			);
			mbox.result.then(() => {
				$scope.formScope.form.$setDirty();
				attribute.categories = _.sortBy(attribute.categories, criteria);
				if(!isAscending) {
					attribute.categories.reverse();
				}
				attribute.freshRefresh = false;
			});
		}

		$scope.sortValuesAz = function (attribute) {
			sortCategories(attribute, "Sort by Value Ascending?", c => {
				return c.value.toLowerCase();
			}, true);
		};

		$scope.sortValuesZa = function (attribute) {
			sortCategories(attribute, "Sort by Value Descending?", c => {
				return c.value.toLowerCase();
			}, false);
		};

		$scope.sortDisplayNamesAz = function (attribute) {
			sortCategories(attribute, "Sort by Display Values Ascending?", c => {
				return (c.displayName || c.value).toLowerCase();
			}, true);
		};

		$scope.sortDisplayNamesZa = function (attribute) {
			sortCategories(attribute, "Sort by Display Values Descending?", c => {
				return (c.displayName || c.value).toLowerCase();
			}, false);
		};

		$scope.removeUnusedCategories = function (attribute) {
			_.remove(attribute.categories, category => {
				if(!category.count) {
					$scope.formScope.form.$setDirty();
				}
				return !category.count;
			});
		};

		$scope.categoryUp = function (attribute, category) {
			const itemPos = attribute.categories.indexOf(category);
			if(itemPos <= 0) {
				return;
			}
			const prevItem = attribute.categories[itemPos - 1];
			attribute.categories[itemPos - 1] = category;
			attribute.categories[itemPos] = prevItem;
			$scope.formScope.form.$setDirty();
		};

		$scope.categoryDown = function (attribute, category) {
			const itemPos = attribute.categories.indexOf(category);
			if(itemPos < 0 || itemPos >= attribute.categories.length - 1) {
				return;
			}
			const nextItem = attribute.categories[itemPos + 1];
			attribute.categories[itemPos + 1] = category;
			attribute.categories[itemPos] = nextItem;
			$scope.formScope.form.$setDirty();
		};

		$scope.addCategory = function (attribute) {
			const color = colorService.getNextColor(_.map(attribute.categories, "color"));
			attribute.categories.push({
				value: "",
				displayName: "",
				count: 0,
				color: color,
			});
			attribute.freshRefresh = true;
			$scope.formScope.form.$setDirty();
		};

		$scope.removeCategory = function (attribute, category) {
			const mbox = messageBox.openQuestion(
				"Remove category?",
				"Are you sure you want to remove the category "
				+ category.displayName + "? This cannot be undone.",
				"Remove", "Cancel"
			);
			mbox.result.then(() => {
				_.remove(attribute.categories, category);
				$scope.formScope.form.$setDirty();
			});
		};

		$scope.expandAttribute = function (attribute) {
			attribute.collapsed = false;
		};
		$scope.collapseAttribute = function (attribute) {
			attribute.collapsed = true;
		};
	}
]);
