angular
	.module("eShareApp")
	.controller("PointCloudSettingsExportImportCtrl", PointCloudSettingsExportImportCtrl);

PointCloudSettingsExportImportCtrl.$inject = [
	"$state", "pointCloudsRepository", "notification", "project", "isExport", "isImport"
];

function PointCloudSettingsExportImportCtrl(
	$state, pointCloudsRepository, notification, project, isExport, isImport
) {
	const vm = this;
	vm.isExport = isExport;
	vm.isImport = isImport;
	vm.serializedExportedData = "";
	vm.importResult = null;
	vm.importAll = importAll;
	vm.isInputValid = isInputValid;

	initialize();

	function initialize() {
		if(isExport) {
			vm.serializedExportedData = "Please wait...";
			pointCloudsRepository.exportSettings(project.id).then(
				exportedData => {
					vm.serializedExportedData = angular.toJson(exportedData);
				}
			);
		}
	}

	function importAll() {
		vm.importResult = null;
		pointCloudsRepository.importSettings(project.id, vm.serializedExportedData).then(
			importResult => {
				if(importResult.hasSucceeded
						&& (!importResult.warnings || importResult.warnings.length === 0)) {
					notification.success("Imported point cloud settings");
					$state.go("^", {}, { reload: true });
				} else {
					if(importResult.hasSucceeded) {
						notification.success("Imported point cloud settings, with warnings");
					}
					vm.importResult = importResult;
				}
			}
		);
	}

	function isInputValid() {
		return Utilities.isJsonObject(vm.serializedExportedData);
	}
}
