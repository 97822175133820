angular.module("eShareApp").factory("usersRepository", [
	"repositoryBase", "notification",
	function (repositoryBase, notification) {

		const baseApi = "/api";
		const usersApi = baseApi + "/UserAccounts";
		const userIdApi = usersApi + "/{userId}";
		const userIdAllowNameChangeApi = usersApi + "/AllowUserNameChange";
		const updateUserNameApi = usersApi + "/UpdateName";

		function getAll() {
			return repositoryBase.get(usersApi, {}).then(data => {
				const users = _.sortBy(data, user => {
					return (user.name || "").toLowerCase();
				});
				return users;
			}, error => {
				notification.error(error);
			});
		}

		function getLoggedInUsers() {
			return repositoryBase.get(usersApi + "/LoggedIn", {}).then(data => {
				const users = _.sortBy(data, user => {
					return (user.name || "").toLowerCase();
				});
				return users;
			}, error => {
				notification.error(error);
			});
		}

		function getAllowUserNameChange() {
			return repositoryBase.get(userIdAllowNameChangeApi);
		}

		function setAllowUserNameChange(isAllowed) {
			return repositoryBase.put(userIdAllowNameChangeApi, {}, { isAllowed: isAllowed });
		}

		function get(id) {
			return repositoryBase.get(userIdApi, { userId: id });
		}

		function getWithProjectId(projectId, userId) {
			return repositoryBase.get(
				"api/project/{projectId}/user/{userId}",
				{ projectId: projectId, userId: userId }
			);
		}

		function add(user) {
			return repositoryBase.post(usersApi, {}, userToDto(user));
		}

		function update(user) {
			return repositoryBase.put(userIdApi, { userId: user.id }, userToDto(user));
		}

		function updateName(user) {
			return repositoryBase.put(updateUserNameApi, {}, userToDto(user));
		}

		function remove(user) {
			return repositoryBase.remove(userIdApi, { userId: user.id });
		}

		function exportAll(projectId) {
			return repositoryBase.post(
				"/api/project/{projectId}/users/export",
				{ projectId: projectId }
			).then(
				data => {
					return JSON.stringify(data, undefined, 3);
				}
			);
		}

		function importAll(projectId, serializedExportedData, shouldImportProjectAdminPermissions) {
			const exportedData = JSON.parse(serializedExportedData);
			exportedData.shouldImportProjectAdminPermissions =
				Utilities.toBool(shouldImportProjectAdminPermissions);
			return repositoryBase.post(
				"/api/project/{projectId}/users/import",
				{ projectId: projectId },
				exportedData
			);
		}

		function userToDto(user) {
			return {
				id: user.id,
				name: user.name,
				login: user.login,
				isAdmin: user.isAdmin,
				isDeactivated: user.isDeactivated,
				deactivationReason: user.deactivationReason,
			};
		}

		function whoAmI() {
			return repositoryBase.rawPost("/api/WhoAmI", undefined, undefined, true);
		}

		return {
			getAll: getAll,
			getLoggedInUsers: getLoggedInUsers,
			get: get,
			getWithProjectId: getWithProjectId,
			exportAll: exportAll,
			importAll: importAll,
			add: add,
			update: update,
			updateName: updateName,
			remove: remove,
			whoAmI: whoAmI,
			getAllowUserNameChange: getAllowUserNameChange,
			setAllowUserNameChange: setAllowUserNameChange,
		};
	}
]);