angular
	.module("eShareApp")
	.controller("SubModelDivisionRulesConfigurationCtrl", SubModelDivisionRulesConfigurationCtrl);

SubModelDivisionRulesConfigurationCtrl.$inject = [
	"$scope", "subModelDivisionRepository", "$state", "$stateParams", "$timeout", "$location",
	"project", "searchService", "attributeDefinitions", "poiAttributeKinds", "notification", "l10n",
	"poiAttributeKindRepository", "statusTrackingDefinitions", "poiKinds"
];

function SubModelDivisionRulesConfigurationCtrl(
	$scope, subModelDivisionRepository, $state, $stateParams, $timeout, $location,
	project, searchService, attributeDefinitions, poiAttributeKinds, notification, l10n,
	poiAttributeKindRepository, statusTrackingDefinitions, poiKinds
) {
	const vm = this;

	$scope.ruleId = $stateParams.ruleId || "";

	subModelDivisionRepository.getModelDivisionRules($stateParams.projectId).then(
		configuration => {
			if(configuration) {
				$scope.sdrc = configuration;
			} else {
				$scope.sdrc = {
					rules: [],
					simpleAttributeInformation: [],
				};
			}
			if(!$scope.sdrc.rules) {
				$scope.sdrc.rules = [];
			}
			if($stateParams.ruleId === "newRule") {
				const modelSearchRequest = {
					isAdvanced: true,
					text: "",
					usedAttribute: "",
					scope: $stateParams.scope || "IdOnly",
					terms: [],
					searchTarget: "Objects",
					id: "newRule",
				};
				$scope.sdrc.rules.push(modelSearchRequest);
			}
			for(let i = 0; i < $scope.sdrc.rules.length; i++) {
				for(let j = 0; j < $scope.sdrc.rules[i].terms.length; j++) {
					const term = $scope.sdrc.rules[i].terms[j];
					term.isMultiline = isMultiline(term.tag);
					if(tagToDataType(term.attributeTag) === "unixDate") {
						term.date = l10n.textToLocalDate(term.text);
					}
				}
			}
		}
	);

	$scope.newModelSearchRequest = function () {
		const modelSearchRequest = {
			isAdvanced: true,
			usedAttribute: "",
			text: "",
			scope: $stateParams.scope || "IdOnly",
			terms: [],
			searchTarget: "Objects",
			id: Utilities.getFakeGuid(),
		};
		$scope.sdrc.rules.push(modelSearchRequest);
	};

	const anyOperations = ["eq"];
	const multilineOperations = ["contains", "exists"];
	const objectOperations = ["eq", "exists", "gt", "ge", "lt", "le"];
	const statusTrackingOperations = ["statusIs", "statusIsSet", "statusIsNotSet"];
	const statusTrackingTagRegex = new RegExp(
		"^\\(\\(ST:" + Utilities.guidRegexStr + "\\)\\)$", "i"
	);

	const enumTags = _.uniq(_.map(_.filter(_.flatMap(poiAttributeKinds, kinds => {
		return kinds.attributeKinds;
	}), kind => {
		return kind.dataType === "Enumeration";
	}), kind => {
		return kind.id.toString();
	}));
	const enumOptions = {};

	mergeStatusTrackingDefinitions();
	loadAllEnumOptions();

	$scope.save = function () {
		const cfg = _.cloneDeep($scope.sdrc);
		subModelDivisionRepository.setModelDivisionRules($stateParams.projectId, cfg).then(() => {
			$state.go("^", {}, { reload: true });
		}, error => {
			notification.error(error);
		});
	};

	function mergeStatusTrackingDefinitions() {
		if(!Utilities.isArray(statusTrackingDefinitions)) {
			return;
		}
		for(let i = 0; i < statusTrackingDefinitions.length; ++i) {
			const statusTrackingDefinition = statusTrackingDefinitions[i];
			const attributeDefinition = {
				displayName: statusTrackingDefinition.name,
				abbreviation: "((ST:" + statusTrackingDefinition.id + "))",
				dataType: "status.enum",
				id: statusTrackingDefinition.id,
				enumValues: statusTrackingDefinition.statusValues,
			};
			attributeDefinitions.push(attributeDefinition);
		}
		attributeDefinitions = _.sortBy(attributeDefinitions, ad => {
			return ad.displayName.toLowerCase();
		});
	}

	function loadAllEnumOptions() {
		for(let i = 0; i < enumTags.length; i++) {
			const enumTag = enumTags[i];
			loadEnumOptions(enumTag);
		}
		enumOptions["((pointKind))"] = _.map(poiKinds, poiKind => {
			return { name: poiKind.name, id: poiKind.id.toString() };
		});
		enumOptions["((pointKind))"].push({ name: "Any", id: "-1" });

		function loadEnumOptions(enumTagToRetrieve) {
			poiAttributeKindRepository.get($stateParams.projectId, enumTagToRetrieve)
				.then(data => {
					enumOptions[enumTagToRetrieve] = _.map(data.enumValues, value => {
						return { name: value.value, id: value.id.toString() };
					});
				}, error => {
					notification.error(error);
				});
		}
	}

	const multilinePois = _.map(_.filter(poiAttributeKinds, kind => {
		return kind.dataType === "Multiline";
	}), kind => {
		return kind.id.toString();
	});

	vm.operations = objectOperations;
	vm.attributeDefinitions = attributeDefinitions;

	vm.simpleSearch = !$stateParams.isAdvanced || $stateParams.isAdvanced === "false";
	vm.targetObjects = !vm.simpleSearch && $stateParams.searchTarget === "Objects";
	vm.targetPoints = !vm.simpleSearch && $stateParams.searchTarget === "Points";

	vm.activeTab = 0;
	if(vm.simpleSearch) {
		vm.activeTab = 0;
	} else if(vm.targetObjects) {
		vm.activeTab = 1;
	} else if(vm.targetPoints) {
		vm.activeTab = 2;
	}

	vm.tabChanged = tabChanged;

	function tabChanged(tabIndex) {
		vm.simpleSearch = false;
		vm.targetObjects = false;
		vm.targetPoints = false;
		switch(tabIndex) {
		case 0:
			vm.simpleSearch = true;
			break;
		case 1:
			vm.targetObjects = true;
			break;
		case 2:
			vm.targetPoints = true;
			break;
		default:
			vm.simpleSearch = true;
			break;
		}
	}

	vm.modelSearchRequest = {
		projectId: (project && project.id) || Utilities.emptyGuid(),
		isAdvanced: true,
		text: "",
		scope: $stateParams.scope || "IdOnly",
		terms: vm.targetObjects ? searchService.deserializeTerms($stateParams.terms) : [],
		searchTarget: "Objects",
		shouldExcludeFoundTargets: false,
	};

	vm.modelTermPlaceholder = {
		attributeTag: "",
	};

	let j;
	for(j = 0; j < vm.modelSearchRequest.terms.length; j++) {
		vm.modelSearchRequest.terms[j].isMultiline = isMultiline(vm.modelSearchRequest.terms[j].tag);
		if(tagToDataType(vm.modelSearchRequest.terms[j].attributeTag) === "unixDate") {
			vm.modelSearchRequest.terms[j].date = l10n.textToLocalDate(
				vm.modelSearchRequest.terms[j].text
			);
		}
	}

	vm.searchLabel = $stateParams.label;

	vm.modelTermTagSelected = modelTermTagSelected;
	vm.addModelSearchTerm = addModelSearchTerm;
	vm.attributeTagChanged = attributeTagChanged;
	vm.isModelSearchValid = isModelSearchValid;
	vm.operationToDisplayName = operationToDisplayName;
	vm.removeModelSearchTerm = removeModelSearchTerm;
	vm.scopeToAttributeTag = scopeToAttributeTag;
	vm.tagToDataType = tagToDataType;
	vm.tagToDisplayName = tagToDisplayName;
	vm.getEnumOptions = getEnumOptions;
	vm.getStatusEnumOptions = getStatusEnumOptions;
	vm.getEnumText = getEnumText;
	vm.getModelAttributeDefinitions = getModelAttributeDefinitions;
	vm.getModelAttributeDefinitionsForGroups = getModelAttributeDefinitionsForGroups;
	vm.getOperations = getOperations;
	vm.isStatusChecked = isStatusChecked;
	vm.toggleStatusChecked = toggleStatusChecked;

	function isStatusChecked(term, option) {
		const text = (term.text || "").toUpperCase();
		const id = (option.id || Utilities.emptyGuid()).toUpperCase();
		return text.indexOf(id) >= 0;
	}

	function toggleStatusChecked(term, option) {
		let text = (term.text || "").trim().toUpperCase();
		const id = (option.id || Utilities.emptyGuid()).trim().toUpperCase();
		const ids = text ? text.split(",") : [];
		const pos = ids.indexOf(id);
		if(pos >= 0) {
			ids.splice(pos, 1);
		} else {
			ids.push(id);
		}
		text = ids.join(",");
		term.text = text;
	}

	function modelTermTagSelected(rule, term) {
		if(!term || !term.attributeTag) {
			return;
		}
		addModelSearchTerm(rule, term);
		vm.modelTermPlaceholder.attributeTag = "";
	}

	function addModelSearchTerm(rule, term) {
		const tag = term ? term.attributeTag : (rule.terms.length === 0 ? "((id))" : "((any))");
		let date = undefined;
		if(tagToDataType(tag) === "unixDate") {
			date = new Date();
		}
		const newTerm = {
			attributeTag: tag,
			operation: getOperations(tag)[0],
			text: "",
			date: date,
			previousTag: tag,
			isMultiline: isMultiline(tag),
		};
		rule.terms.push(newTerm);
	}

	function attributeTagChanged(term) {
		if(term.previousTag !== term.attributeTag) {
			if(isEnum(term.attributeTag)) {
				term.date = undefined;
				term.text = getInitialTermText(term.attributeTag);
			} else if(tagToDataType(term.attributeTag) === "unixDate") {
				term.date = new Date();
				term.text = l10n.dateToLocalText(term.date);
			} else if(tagToDataType(term.previousTag) === "unixDate") {
				term.date = undefined;
				term.text = "";
			} else if(term.attributeTag === "((pointKind))"
					|| isStatusTracking(term.attributeTag)
					|| isEnum(term.previousTag)
					|| term.previousTag === "((pointKind))"
					|| isStatusTracking(term.previousTag)) {
				term.date = undefined;
				term.text = "";
			}
		}
		if(!getOperations(term.attributeTag).contains(term.operation)) {
			term.operation = getOperations(term.attributeTag)[0];
		}
		term.previousTag = term.attributeTag;
		term.isMultiline = isMultiline(term.attributeTag);
	}

	function getInitialTermText(tag) {
		if(isEnum(tag)) {
			const options = getEnumOptions(tag);
			if(Utilities.isArray(options) && options.length > 0) {
				return options[0].id;
			}
		}
		return "";
	}

	function isValidNumber(value) {
		const numberRegex = /^[+-]?\d+[.,]?\d*([eE][+-]?\d+)?$/;
		return angular.isString(value) && numberRegex.test(value.trim());
	}

	function isModelSearchValid() {
		if(!$scope.sdrc) {
			return false;
		}
		if($scope.sdrc.rules.length > 0) {
			for(let ruleIndex = 0; ruleIndex < $scope.sdrc.rules.length; ruleIndex++) {
				const rule = $scope.sdrc.rules[ruleIndex];
				if(!rule.name) {
					return false;
				} else if(rule.name.length === 0) {
					return false;
				}
				if(rule.terms.length > 0) {
					for(let i = 0; i < rule.terms.length; ++i) {
						const term = rule.terms[i];
						switch(tagToDataType(term.attributeTag)) {
						case "unixDate":{
							const text = term.date ? l10n.dateToLocalText(term.date) : "invalid";
							if(term.operation !== "exists" && !l10n.isValidDateString(text)) {
								return false;
							}
							term.text = text;
							break;
						}
						case "numeric":{
							if(term.operation !== "exists" && !isValidNumber(term.text)) {
								return false;
							}
							break;
						}
						case "status.enum":{
							if(term.operation === "statusIs") {
								if(!Utilities.isString(term.text) || term.text === "") {
									return false;
								}
							}
							break;
						}
						}
					}
				} else {
					if(!rule.usedAttribute || rule.usedAttribute.length === 0) {
						return false;
					}
				}
			}
			return true;
		}
		return true;
	}

	function operationToDisplayName(operation) {
		switch(operation) {
		case "eq":
			return "equals";
		case "exists":
			return "exists";
		case "gt":
			return "&gt;";
		case "ge":
			return "&ge;";
		case "lt":
			return "&lt;";
		case "le":
			return "&le;";
		case "contains":
			return "contains";
		case "statusIs":
			return "is";
		case "statusIsSet":
			return "is set";
		case "statusIsNotSet":
			return "is not set";
		case "wasEq":
			return "was equal";
		case "existed":
			return "existed";
		case "contained":
			return "contained";
		case "wasGt":
			return "was &gt;";
		case "wasGe":
			return "was &ge;";
		case "wasLt":
			return "was &lt;";
		case "wasLe":
			return "was &le;";
		case "notEq":
			return "not equal";
		default:
			return "?";
		}
	}

	function removeModelSearchTerm(rule, termIndex) {
		rule.terms.splice(termIndex, 1);
	}

	function scopeToAttributeTag(searchScope) {
		switch(searchScope) {
		case "IdOnly":
			return "((id))";
		case "AllAttributes":
			return "((any))";
		default:
			return "((any))";
		}
	}

	function tagToDataType(tag) {
		if(tag === "((modificationDate))") {
			return "unixDate";
		}
		const attributeDefinition = _.find(attributeDefinitions, { abbreviation: tag });
		return attributeDefinition ? attributeDefinition.dataType : "string";
	}

	function tagToDisplayName(tag) {
		const attributeDefinition = _.find(attributeDefinitions, { abbreviation: tag });
		return attributeDefinition ? attributeDefinition.displayName : "?";
	}

	function getEnumOptions(tag) {
		return enumOptions[tag];
	}

	function getStatusEnumOptions(tag) {
		const attributeDefinition = _.find(attributeDefinitions, { abbreviation: tag });
		if(attributeDefinition) {
			return attributeDefinition.enumValues;
		}
		return [];
	}

	function getEnumText(tag, text) {
		const value = _.find(enumOptions[tag], { id: text });
		return value ? value.name : "";
	}

	function getModelAttributeDefinitions() {
		return attributeDefinitions;
	}

	const groupAttributeDefinitions = _.filter(attributeDefinitions, { "isGroup": true }) || [];
	const noAttributeSelected = {
		abbreviation: "",
		dataType: "string",
		displayName: "None",
		displayPriority: 0,
		enumValues: null,
		isGroup: true,
		isVisible: true,
		isSpecial: true,
	};
	groupAttributeDefinitions.push(noAttributeSelected);

	function getModelAttributeDefinitionsForGroups() {
		return groupAttributeDefinitions;
	}

	function getOperations(tag) {
		if(tag === "((any))" || tag === "((pointKind))" || tag === "((createdBy))") {
			return anyOperations;
		}
		if(tag === "((modifiedBy))") {
			return anyOperations;
		}
		if(tag === "((externalId))") {
			return objectOperations;
		}
		if(isMultiline(tag)) {
			return multilineOperations;
		}
		if(isStatusTracking(tag)) {
			return statusTrackingOperations;
		}
		return objectOperations;
	}

	function isStatusTracking(tag) {
		return Utilities.isString(tag) && statusTrackingTagRegex.test(tag);
	}

	function isMultiline(tag) {
		return multilinePois.contains(tag);
	}

	function isEnum(tag) {
		return enumTags.contains(tag);
	}
}
