angular.module("eShareApp").directive("cadCombiningExcelColumnTransformation", [
	"notification",
	function (notification) {
		return {
			restrict: "A",
			replace: true,
			scope:
			{
				allColumnsMetadata: "=",
				transformation: "=",
				allTransformations: "=",
				testTransformationFunction: "&",
			},
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadCombiningExcelColumnTransformation.html'),
			controller: [
				"$scope",
				function ($scope) {
					// Combine column metadata and transformation attributes
					$scope.allAttributes = _.map($scope.allColumnsMetadata, columnMetadata => {
						return {
							abbreviation: columnMetadata.columnIndex,
							displayName: columnMetadata.columnNumber + " / " + columnMetadata.name,
						};
					}).concat(_.flatMap($scope.allTransformations, transformation => {
						return _.map(transformation.attributes, attribute => {
							return {
								abbreviation: attribute.name,
								displayName: attribute.displayName,
							};
						});
					}));

					$scope.testResults = {
						sourceDefinitions: [],
						targetDefinitions: [],
						matchingResults: [],
						nonMatching: [],
					};
					$scope.testResultExpanded = false;
					$scope.testButtonDisabled = false;
					$scope.isValid = function () {
						let isValid = true;
						if(!$scope.transformation
							|| !$scope.transformation.abbreviation
							|| $scope.transformation.abbreviation.length === 0) {
							isValid = false;
						}
						if(!$scope.transformation.format || $scope.transformation.format.length === 0) {
							isValid = false;
						}
						$scope.transformation.isValid = isValid;
						return isValid;
					};

					$scope.$watch("allTransformations", (/*newValue, oldValue*/) => {
						const nonDerivedColumnMetadata = _.filter(
							$scope.allColumnsMetadata, columnMetadata => {
								return !columnMetadata.isDerived;
							}
						);
						$scope.allAttributes = _.map(nonDerivedColumnMetadata, columnMetadata => {
							return {
								abbreviation: columnMetadata.columnIndex,
								displayName: columnMetadata.columnNumber + " / " + columnMetadata.name,
							};
						}).concat(_.flatMap($scope.allTransformations, transformation => {
							return _.map(transformation.attributes, attribute => {
								return {
									abbreviation: attribute.name,
									displayName: attribute.displayName,
								};
							});
						}));
					}, true);

					function validate() {
						if(!$scope.transformation
							|| !$scope.transformation.abbreviation
							|| $scope.transformation.abbreviation.length === 0) {
							notification.error("A name for the new column must be entered");
							return false;
						}
						if(!$scope.transformation.format || $scope.transformation.format.length === 0) {
							notification.error("A pattern to generate the new column must be entered");
							return false;
						}
						return true;
					}

					$scope.toggleTestResultExpand = function () {
						$scope.testResultExpanded = !$scope.testResultExpanded;
					};

					function formatTestResults() {
						for(let j = 0; j < $scope.testResults.matchingResults.length; j++) {
							const match = $scope.testResults.matchingResults[j];
							match.inputMap = {};
							match.outputMap = {};
							for(let i = 0; i < match.inputValues.length; i++) {
								match.inputMap[match.inputValues[i].abbreviation] =
									match.inputValues[i].value;
							}
							for(let i = 0; i < match.outputValues.length; i++) {
								match.outputMap[match.outputValues[i].abbreviation] =
									match.outputValues[i].value;
							}
						}
					}

					$scope.tryPattern = function () {
						if(!validate()) {
							return;
						}
						$scope.testButtonDisabled = true;
						const index = _.indexOf($scope.allTransformations, $scope.transformation);
						$scope.testTransformationFunction()(index)
							.then(data => {
								$scope.testResults = data;
								formatTestResults();
								$scope.testResultExpanded = true;
								$scope.testButtonDisabled = false;
							}, error => {
								notification.error("Error testing pattern: " + error);
								$scope.testButtonDisabled = false;
							});
					};

					$scope.deleteTransformation = function () {
						_.remove($scope.allTransformations, $scope.transformation);
					};
				}
			],
		};
	}
]);