angular.module("eShareApp").directive("cadServerMonitor", cadServerMonitor);

cadServerMonitor.$inject = [];

function cadServerMonitor() {
	return {
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadServerMonitor.html'),
		restrict: "E",
		replace: true,
		controller: cadServerMonitorCtrl,
		controllerAs: "vm",
	};
}

cadServerMonitorCtrl.$inject = ["$rootScope", "serverMonitor", "notification"];

function cadServerMonitorCtrl($rootScope, serverMonitor, notification) {

	let isAlive = true;
	let isDocumentTreeVisible = false;
	let isMapViewerVisible = false;

	const dynamicStyle = {
		display: "none",
	};

	const vm = {
		dynamicStyle: dynamicStyle,
		tryReconnect: tryReconnect,
	};

	initialize();

	return vm;

	function initialize() {
		$rootScope.$on("event:serverIsAliveChanged", onServerIsAliveChanged);
		$rootScope.$on("documentTreeView::show", onDocumentTreeShow);
		$rootScope.$on("documentTreeView::hide", onDocumentTreeHide);
		$rootScope.$on("mapViewer::show", onMapViewerShow);
		$rootScope.$on("mapViewer::hide", onMapViewerHide);
	}

	function onServerIsAliveChanged(event, newIsAlive) {
		newIsAlive = !!newIsAlive;
		if(isAlive === newIsAlive) {
			return;
		}
		isAlive = newIsAlive;
		if(isAlive) {
			dynamicStyle.display = "none";
			notification.success("eShare connection restored");
			if(isDocumentTreeVisible) {
				$("#documentTreeView").show();
			}
			if(isMapViewerVisible) {
				$("#mapViewer").show();
			}
		} else {
			dynamicStyle.display = "block";
			notification.logError(window.eShare.connectionLostMessage);
			if(isDocumentTreeVisible) {
				$("#documentTreeView").hide();
			}
			if(isMapViewerVisible) {
				$("#mapViewer").hide();
			}
		}
	}

	function tryReconnect() {
		serverMonitor.tryReconnect();
	}

	function onDocumentTreeShow() {
		isDocumentTreeVisible = true;
	}

	function onDocumentTreeHide() {
		isDocumentTreeVisible = false;
	}

	function onMapViewerShow() {
		isMapViewerVisible = true;
	}

	function onMapViewerHide() {
		isMapViewerVisible = false;
	}
}