angular.module("eShareApp").factory("uploadService", uploadService);
uploadService.$inject = [
	"notification", "Upload", "$http", "$timeout", "$window", "onBeforeUnloadService"
];

const servicesByProject = {};
let unload = false;
const maxChunkSize = 2147482624;

function uploadService(
	notification, upload, $http, $timeout, $window, onBeforeUnloadService
) {

	if(unload === false) {
		createUnloadListener();
		unload = true;
	}

	function getOrCreate(projectId, name, fileTypes, uploadUrl, multiUploadUrl) {
		const projectServices = servicesByProject[projectId] || {};
		const existingService = projectServices[name];
		if(existingService != null) {
			return existingService;
		}
		const service = {
			projectId: projectId,
			name: name,
			nameCapitalized: name.charAt(0).toUpperCase() + name.slice(1),
			uploadUrl: uploadUrl,
			multiUploadUrl: multiUploadUrl,
			uploadStatuses: [],
			isCanceled: false,
			isFailed: false,
			isUploadInProgress: false,
			fileTypes: fileTypes,
			cancel: cancel,
			sequentialUpload: sequentialUpload,
			prepareUploadFunctions: prepareUploadFunctions,
		};
		if(validateService(service)) {
			throw "Invalid parameters passed to getOrCreate";
		}

		servicesByProject[projectId] = servicesByProject[projectId] || {};
		servicesByProject[projectId][name] = service;
		return service;
	}

	function cancel() {
		this.isCanceled = true;
	}

	function getExtension(fileName) {
		let extension = (fileName || "").toLowerCase();
		const dot = extension.lastIndexOf(".");
		extension = extension.substring(dot);
		return extension;
	}

	function createFileTypeError(fileTypes, file) {
		let error = "File " + file.name + ": The file type must be";
		fileTypes.forEach((type, index) => {
			if(index === 0) {
				error += " " + type;
			} else if(index === fileTypes.length - 1) {
				error += " or " + type;
			} else {
				error += ", " + type;
			}
		});
		error += "!";
		notification.error(error);
	}

	function prepareUploadFunctions(service, files, queryParameters) {
		return files.map(file => {
			if(!file) {
				return null;
			}
			const extension = getExtension(file.name);
			const fileType = _.find(service.fileTypes, type => {
				return extension.toLowerCase() === type.toLowerCase();
			});
			if(fileType == null) {
				createFileTypeError(service.fileTypes, file);
				return null;
			}
			const totalChunks = Math.ceil(file.size / 2147482624);

			const existingStatusIndex = _.findIndex(service.uploadStatuses, status => {
				return status.sourceFileName === file.name;
			});
			if(existingStatusIndex !== -1) {
				service.uploadStatuses.splice(existingStatusIndex, 1);
			}
			const uploadObject = {
				sourceFileName: file.name,
				status: "queued",
				statusText: "Upload in queue",
			};
			service.uploadStatuses.push(uploadObject);

			queryParameters = angular.copy(queryParameters);
			queryParameters.fileType = fileType;

			if(totalChunks === 1) {
				return function () {
					const service2 = service;
					const file2 = file;
					const uploadObject2 = uploadObject;
					const queryParameters2 = queryParameters;
					return function () {
						return uploadAsSingle(service2, file2, uploadObject2, queryParameters2);
					};
				}();
			} else {
				return function () {
					const service2 = service;
					const file2 = file;
					const uploadObject2 = uploadObject;
					const queryParameters2 = queryParameters;
					const totalChunks2 = totalChunks;
					return function () {
						return uploadInParts(
							service2,
							file2,
							uploadObject2,
							queryParameters2,
							totalChunks2
						);
					};
				}();
			}
		})
			.filter(item => {
				return item != null;
			});
	}

	function sequentialUpload(service, uploads, index) {
		validateService(service);
		index = typeof index === "number" ? index : 0;
		if(index < uploads.length && !service.isCanceled) {
			return uploads[index]().then(() => {
				return sequentialUpload(service, uploads, index + 1);
			}, () => {
				return sequentialUpload(service, uploads, index + 1);
			});
		} else {
			const newUploads = service.uploadStatuses.filter(upload => {
				return upload.status === "success" || upload.status === "fail";
			});
			service.uploadStatuses.length = 0;
			Array.prototype.push.apply(service.uploadStatuses, newUploads);
			service.isUploadInProgress = false;
			return $timeout(() => {
				return service;
			});
		}
	}

	function uploadAsSingle(service, file, uploadObject, queryParameters) {
		if(!window.eShare.integratedSecurity) {
			return $http.post("api/OneTimeToken/Create")
				.then(tokenData => {
					return uploadOne(service, file, uploadObject, queryParameters, tokenData.data.token);
				},
				() => {
					notification.error("Error getting one-time authorization token");
					service.isUploadInProgress = false;
				});
		} else {
			return uploadOne(service, file, uploadObject, queryParameters);
		}
	}

	function getLastModified(file) {
		try {
			return file.lastModifiedDate.getTime();
		} catch(e) {
			return file.lastModified;
		}
	}

	function uploadInParts(service, file, uploadObject, queryParameters, totalChunks) {
		if(service.multiUploadUrl == null) {
			throw "Multi upload url not defined";
		}
		const lastModified = getLastModified(file);
		const parts = [];
		for(let i = 0; i < totalChunks; i++) {
			parts[i] = file.slice(i * maxChunkSize, Math.min(file.size, (i + 1) * maxChunkSize));
			parts[i].name = file.name;
			parts[i].lastModified = lastModified;
		}

		uploadObject.isActive = true;
		uploadObject.progress = "0%";
		queryParameters.totalChunks = totalChunks;
		return uploadParts(service, parts, 0, uploadObject, queryParameters, file, "");
	}

	function uploadParts(service, parts, index, uploadObject, queryParameters, file, fileId) {
		if(service.isCanceled) {
			return $timeout();
		}
		if(service.isFailed) {
			return $timeout();
		}
		if(index === parts.length) {
			uploadObject.isActive = false;
			uploadObject.status = "success";
			uploadObject.statusText = "Upload successful, awaiting processing";
			notification.success(
				service.nameCapitalized + " " + uploadObject.sourceFileName + " uploaded"
			);
			return $timeout();
		}
		queryParameters = angular.copy(queryParameters);
		queryParameters.fileId = fileId;
		if(!window.eShare.integratedSecurity) {
			return $http.post("api/OneTimeToken/Create")
				.then(tokenData => {
					queryParameters.token = tokenData.data.token;
					return uploadPart(
						service,
						uploadObject,
						queryParameters,
						index,
						parts[index],
						file
					).then(returnId => {
						return uploadParts(
							service,
							parts,
							++index,
							uploadObject,
							queryParameters,
							file,
							returnId
						);
					});
				},
				() => {
					notification.error("Error getting one-time authorization token");
					service.isUploadInProgress = false;
				});
		} else {
			return uploadPart(
				service,
				uploadObject,
				queryParameters,
				index,
				parts[index],
				file
			).then(returnId => {
				return uploadParts(
					service,
					parts,
					++index,
					uploadObject,
					queryParameters,
					file,
					returnId
				);
			});
		}
	}

	function uploadPart(service, uploadObject, queryParameters, index, part, file) {
		queryParameters = queryParameters || {};
		queryParameters.lastModifiedDate = part.lastModified;
		queryParameters.chunkNumber = index;

		let url = generateUrl(service.multiUploadUrl, queryParameters);
		url = url.supplant({
			projectId: service.projectId,
		});

		const uploadSession = upload.upload({
			url: url,
			data: {
				file: part,
			},
		});
		let lastUpdated = Date.now();
		return uploadSession.then(onSuccess, onError, onProgress);

		function onSuccess(asd) {
			uploadObject.progress = Math.floor((index * maxChunkSize + part.size) / file.size) + "%";
			return asd.data;
		}

		function onError(reason) {
			if(service.isCanceled) {
				return;
			}
			service.isFailed = true;
			uploadObject.status = "fail";
			uploadObject.isActive = false;
			uploadObject.progress = "100%";
			uploadObject.statusText = "Upload failed: " + Utilities.getErrorMessage(reason);
			notification.error(
				service.nameCapitalized + " file upload failed: " + Utilities.getErrorMessage(reason)
			);
		}

		function onProgress(event) {
			if(service.isCanceled) {
				uploadSession.abort();
			}
			let progress = event.total ? event.loaded / event.total : 0;
			//progressbar looks better in IE if you throttle it a bit
			if(Date.now() > lastUpdated + 100 || progress === 100) {
				progress = (index * maxChunkSize + part.size * progress) / file.size;
				progress = Math.floor(progress * 100);
				uploadObject.progress = progress + "%";
				lastUpdated = Date.now();
			}
		}
	}

	function uploadOne(service, file, uploadObject, queryParameters, oneTimeToken) {
		queryParameters = queryParameters || {};
		const lastModified = getLastModified(file);
		queryParameters.lastModifiedDate = lastModified;
		if(oneTimeToken) {
			queryParameters.token = oneTimeToken;
		}
		let url = generateUrl(service.uploadUrl, queryParameters);
		url = url.supplant({
			projectId: service.projectId,
		});
		uploadObject.isActive = true;
		uploadObject.progress = "0%";
		const uploadSession = upload.upload({
			url: url,
			data: {
				file: file,
			},
		});
		let lastUpdated = Date.now();
		return uploadSession.then(onSuccess, onError, onProgress);

		function onSuccess() {
			uploadObject.isActive = false;
			uploadObject.progress = "100%";
			uploadObject.status = "success";
			uploadObject.statusText = "Upload successful, awaiting processing";
			notification.success(
				service.nameCapitalized + " file " + uploadObject.sourceFileName + " uploaded"
			);
		}

		function onError(reason) {
			if(service.isCanceled) {
				return;
			}
			uploadObject.status = "fail";
			uploadObject.isActive = false;
			uploadObject.progress = "100%";
			uploadObject.statusText = "Upload failed: " + Utilities.getErrorMessage(reason);
			notification.error(
				service.nameCapitalized + " file upload failed: " + Utilities.getErrorMessage(reason)
			);
		}

		function onProgress(event) {
			if(service.isCanceled) {
				uploadSession.abort();
			}
			let progress = event.total ? event.loaded / event.total : 0;
			progress = Math.floor(progress * 100);
			// progressbar looks better in IE if you throttle it a bit
			if(Date.now() > lastUpdated + 100 || progress === 100) {
				uploadObject.progress = progress + "%";
				lastUpdated = Date.now();
			}
		}
	}

	function generateUrl(uploadUrl, queryParameters) {
		let url = uploadUrl;
		let index = 0;
		_.forOwn(queryParameters, (value, key) => {
			if(value == null) {
				return;
			}
			if(index === 0) {
				url += "?" + key + "=" + value;
			} else {
				url += "&" + key + "=" + value;
			}
			index++;
		});
		return url;
	}

	function validateService(service) {
		if(service.projectId == null
			|| service.name == null
			|| service.name.trim() === ""
			|| service.uploadUrl == null
			|| service.uploadUrl.trim() === "") {
			throw "service created with invalid parameters";
		}
	}

	function createUnloadListener() {
		onBeforeUnloadService.addUnloadListener(event => {
			let uploads = 0;
			_.forOwn(servicesByProject, project => {
				_.forOwn(project, projectService => {
					uploads += projectService.uploadStatuses.filter(status => {
						return status.status === "queued";
					}).length;
				});
			});
			if(uploads > 0) {
				const toReturn = uploads
					+ (uploads === 1 ? " upload is" : " uploads are")
					+ " in progress!";
				event.returnValue = toReturn;
				return toReturn;
			}
			return "";

		});
	}

	return {
		getOrCreate: getOrCreate,
	};
}