angular.module("eShareApp").controller("DataSourceTypeSelectCtrl", [
	"$state", "$scope", "$stateParams", "messageBox", "adapter", "project", "adaptersRepository",
	"adapterTypes",

	function (
		$state, $scope, $stateParams, messageBox, adapter, project, adaptersRepository,
		adapterTypes
	) {

		$scope.project = project;
		$scope.adapter = adapter;
		$scope.adapterTypes = adapterTypes;
		$scope.dataSourceTypes = _.find($scope.adapterTypes, {
			"typeGuid": adapter.typeGuid,
		}).dataSourceTypeInfoDtos;
		$scope.selectedDataSourceType = { typeGuid: "", name: "" };

		$scope.selectdataSourceType = function (dataSourceType) {
			$scope.selectedDataSourceType = dataSourceType;
		};

		$scope.createDataSource = function () {
			adaptersRepository.createDataSource(
				$scope.project.id,
				$scope.adapter.id,
				$scope.selectedDataSourceType.typeGuid
			).then(dataSourceId => {
				// success
				adaptersRepository.get($scope.project.id, $scope.adapter.id).then(
					(/*ad*/) => {
						$state.go("project.admin.adapters.adapter.view.dataSource.edit", {
							dataSourceId: dataSourceId,
						}, { reload: true });
					},
					e => {
						fail(e);
					}
				);
			},
			e => {
				fail(e);
			});
		};

		function fail(e) {
			const mbox = messageBox.openError(
				"Creating data source failed!",
				"Creating  data source failed. Reason: " + e
			);
			mbox.result.then(
				() => {
					$state.go("^");
				}
			);
		}

		function selectFirstRow() {
			if($scope.dataSourceTypes.length > 0) {
				$scope.selectedDataSourceType = $scope.dataSourceTypes[0];
			}
		}

		selectFirstRow();
	}
]);
