angular.module("eShareApp").factory("eBrowser", eBrowser);

eBrowser.$inject = [];

function eBrowser() {
	let isInitialized = false;
	let eBrowserCtrl;

	return {
		registerController: registerController,
		isSupported: isSupported,
		isModelLoaded: isModelLoaded,
		reloadModel: reloadModel,
		showMessage: showMessage,
		showImportantMessage: showImportantMessage,
		hideMessage: hideMessage,
		locateByGeometryId: locateByGeometryId,
		locateGroup: locateGroup,
		locatePoint: locatePoint,
		locatePointCloud: locatePointCloud,
		goToLocation: goToLocation,
		examineSearchResults: examineSearchResults,
		exitExamineMode: exitExamineMode,
		addPointTo3D: addPointTo3D,
		deletePointFrom3D: deletePointFrom3D,
		getModelTimestamp: getModelTimestamp,
		isModelTimestampMatching: isModelTimestampMatching,
		print3D: print3D,
		getCurrentLocationUrl: getCurrentLocationUrl,
		openMarkup: openMarkup,
		isMarkupEditorDirty: isMarkupEditorDirty,
		cancelEditMarkup: cancelEditMarkup,
		updatePoints: updatePoints,
		updateObjectStatus: updateObjectStatus,
		updateObjectStatusWithIds: updateObjectStatusWithIds,
		onDragStart: onDragStart,
		onDrag: onDrag,
		onDragEnd: onDragEnd,
		setModelTreeVisibile: setModelTreeVisibile,
		changeHierarchy: changeHierarchy,
		examineBranch: examineBranch,
		exitMarkupMode: exitMarkupMode,
		changeVisualStyle: changeVisualStyle,
	};

	function registerController(eBrowserController) {
		if(Utilities.isNullOrUndefined(eBrowserController)) {
			throw new EShareException("eBrowserController is null");
		}
		eBrowserCtrl = eBrowserController;
		isInitialized = true;
	}

	function isSupported() {
		ensureIsInitialized();
		return eBrowserCtrl.isSupported();
	}

	function isModelLoaded() {
		ensureIsInitialized();
		return eBrowserCtrl.isModelLoaded();
	}

	function reloadModel() {
		ensureIsInitialized();
		eBrowserCtrl.reloadModel();
	}

	function showMessage(title, text, button1Text, button2Text, button1Click, button2Click) {
		ensureIsInitialized();
		eBrowserCtrl.showMessage(title, text, button1Text, button2Text, button1Click, button2Click);
	}

	function showImportantMessage(
		title, text, button1Text, button2Text, button1Click, button2Click
	) {
		ensureIsInitialized();
		eBrowserCtrl.showImportantMessage(
			title, text, button1Text, button2Text, button1Click, button2Click
		);
	}

	function hideMessage() {
		ensureIsInitialized();
		eBrowserCtrl.hideMessage();
	}

	function locateByGeometryId(geometryId, modelTimestamp, moveCamera, examineFlags) {
		ensureIsInitialized();
		eBrowserCtrl.locateByGeometryId(geometryId, modelTimestamp, moveCamera, examineFlags);
	}

	function locateGroup(parentTag, childTag, id, animate, examineFlags) {
		ensureIsInitialized();
		eBrowserCtrl.locateGroup(parentTag, childTag, id, animate, examineFlags);
	}

	function locatePoint(pointId) {
		ensureIsInitialized();
		eBrowserCtrl.locatePoint(pointId);
	}

	function locatePointCloud(fileName) {
		ensureIsInitialized();
		eBrowserCtrl.locatePointCloud(fileName);
	}

	function changeVisualStyle(visualStyleName) {
		ensureIsInitialized();
		eBrowserCtrl.changeVisualStyle(visualStyleName);
	}

	function goToLocation(location) {
		ensureIsInitialized();
		eBrowserCtrl.goToLocation(location);
	}

	function examineSearchResults(geometryIds, examineFlags) {
		ensureIsInitialized();
		eBrowserCtrl.examineSearchResults(geometryIds, examineFlags);
	}

	function exitExamineMode() {
		ensureIsInitialized();
		eBrowserCtrl.exitExamineMode();
	}

	function setModelTreeVisibile(visible) {
		ensureIsInitialized();
		eBrowserCtrl.setModelTreeVisibile(visible);
	}

	function changeHierarchy(hierarchyName) {
		ensureIsInitialized();
		eBrowserCtrl.changeHierarchy(hierarchyName);
	}

	function examineBranch(branchPath, examineFlags) {
		ensureIsInitialized();
		eBrowserCtrl.examineBranch(branchPath, examineFlags);
	}

	function addPointTo3D(id, name, externalId, status, kindName, color, iconUrl, x, y, z) {
		ensureIsInitialized();
		eBrowserCtrl.addPointTo3D(id, name, externalId, status, kindName, color, iconUrl, x, y, z);
	}

	function deletePointFrom3D(id) {
		ensureIsInitialized();
		eBrowserCtrl.deletePointFrom3D(id);
	}

	function getModelTimestamp() {
		ensureIsInitialized();
		return eBrowserCtrl.getModelTimestamp();
	}

	function isModelTimestampMatching(timestamp) {
		ensureIsInitialized();
		return eBrowserCtrl.isModelTimestampMatching(timestamp);
	}

	function print3D() {
		ensureIsInitialized();
		return eBrowserCtrl.print3D();
	}

	function getCurrentLocationUrl() {
		ensureIsInitialized();
		return eBrowserCtrl.getCurrentLocationUrl();
	}

	function openMarkup(ebxFileContents, allowEditing) {
		ensureIsInitialized();
		return eBrowserCtrl.openMarkup(ebxFileContents, allowEditing);
	}

	function isMarkupEditorDirty() {
		ensureIsInitialized();
		return eBrowserCtrl.isMarkupEditorDirty();
	}

	function cancelEditMarkup() {
		ensureIsInitialized();
		return eBrowserCtrl.cancelEditMarkup();
	}

	function updatePoints(pointsOfInterestWithKinds) {
		ensureIsInitialized();
		if(!pointsOfInterestWithKinds
				|| !Utilities.isArray(pointsOfInterestWithKinds.points)
				|| pointsOfInterestWithKinds.points.length < 1
				|| !Utilities.isArray(pointsOfInterestWithKinds.kinds)
				|| pointsOfInterestWithKinds.kinds.length < 1) {
			return false;
		}
		let numberOfPointsUpdated = 0;
		const points = pointsOfInterestWithKinds.points;
		const kinds = pointsOfInterestWithKinds.kinds;
		for(let i = 0; i < points.length; ++i) {
			const point = points[i];
			const kind = _.find(kinds, { id: point.kindId });
			if(!kind) {
				continue;
			}
			eBrowserCtrl.deletePointFrom3D(point.id).then(() => {
				eBrowserCtrl.addPointTo3D(
					point.id,
					point.name,
					point.externalId || "",
					point.status,
					kind.name,
					kind.color,
					kind.iconUrl,
					point.x,
					point.y,
					point.z
				);
			});
			++numberOfPointsUpdated;
		}
		return numberOfPointsUpdated > 0;
	}

	function updateObjectStatus(attributeAbbreviation, attributeValue, trackingName, state, color) {
		ensureIsInitialized();
		eBrowserCtrl.updateObjectStatus(
			attributeAbbreviation, attributeValue, trackingName, state, color
		);
	}

	function updateObjectStatusWithIds(objectIds, kindName, stateName, stateColor){
		ensureIsInitialized();
		eBrowserCtrl.updateObjectStatusWithIds(objectIds, kindName, stateName, stateColor);
	}

	function onDragStart(data) {
		ensureIsInitialized();
		eBrowserCtrl.onDragStart(data);
	}

	function onDrag(data) {
		ensureIsInitialized();
		eBrowserCtrl.onDrag(data);
	}

	function onDragEnd(data) {
		ensureIsInitialized();
		eBrowserCtrl.onDragEnd(data);
	}

	function exitMarkupMode(save) {
		ensureIsInitialized();
		return eBrowserCtrl.exitMarkupMode(save);
	}

	function ensureIsInitialized() {
		if(!isInitialized) {
			throw new EShareException("eBrowser service is not initilized");
		}
	}
}
