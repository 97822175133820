angular.module("eShareApp").directive("cadReplacingAttributeTransformation", [
	"notification", "attributeTransformationsRepository",
	function (notification, attributeTransformationsRepository) {
		return {
			restrict: "A",
			replace: true,
			scope:
			{
				definitions: "=",
				transformation: "=",
				projectId: "=",
				modelId: "=",
				allTransformations: "=",
			},
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadReplacingAttributeTransformation.html'),
			controller: [
				"$scope", "$rootScope",
				function ($scope, $rootScope) {
					$scope.sourceAttribute = _.find(
						$scope.definitions,
						{ abbreviation: $scope.transformation.abbreviation }
					);
					if(Utilities.isNullOrUndefined($scope.sourceAttribute)) {
						$scope.sourceAttribute = _.head(_.sortBy($scope.definitions, "displayName"));
					}
					$scope.testResults = {
						sourceDefinitions: [],
						targetDefinitions: [],
						matchingResults: [],
					};
					$scope.expanded = false;
					$scope.testButtonDisabled = false;
					$scope.isValid = function () {
						if(!$scope.sourceAttribute || !$scope.sourceAttribute.abbreviation) {
							return false;
						}
						if(!$scope.transformation
							|| !$scope.transformation.attributes
							|| $scope.transformation.attributes.length !== 1
							|| !$scope.transformation.attributes[0].displayName
							|| !$scope.transformation.attributes[0].abbreviation) {
							return false;
						}
						return true;
					};

					$scope.$watch("sourceAttribute", (newValue /*, oldValue*/) => {
						if($scope.transformation && newValue && newValue.abbreviation) {
							$scope.transformation.abbreviation = newValue.abbreviation;
							$scope.transformation.displayName = newValue.displayName;
						}
					}, true);

					$scope.sourceAttributeChanged = function () {
						if($scope.transformation
							&& $scope.transformation.attributes[0]
							&& $scope.sourceAttribute
							&& $scope.sourceAttribute.abbreviation) {
							$scope.transformation.attributes[0].abbreviation =
								$scope.sourceAttribute.abbreviation + "Replaced";
							$scope.abbreviationChanged();
						}
					};

					$scope.abbreviationChanged = function () {
						$scope.transformation.attributes[0].displayName =
							$scope.transformation.attributes[0].abbreviation + "";
					};

					$scope.toggleExpand = function () {
						$scope.expanded = !$scope.expanded;
					};

					function validate() {
						if(!$scope.sourceAttribute || !$scope.sourceAttribute.abbreviation) {
							notification.error("A source attribute must be selected");
							return false;
						}
						if(!$scope.transformation
							|| !$scope.transformation.attributes
							|| $scope.transformation.attributes.length !== 1
							|| !$scope.transformation.attributes[0].displayName
							|| !$scope.transformation.attributes[0].abbreviation) {
							notification.error("A pattern to match must be entered");
							return false;
						}
						return true;
					}

					function formatTestResults() {
						for(let j = 0; j < $scope.testResults.matchingResults.length; j++) {
							const match = $scope.testResults.matchingResults[j];
							match.inputMap = {};
							match.outputMap = {};
							for(let i = 0; i < match.inputValues.length; i++) {
								match.inputMap[match.inputValues[i].abbreviation] =
									match.inputValues[i].value;
							}
							for(let i = 0; i < match.outputValues.length; i++) {
								match.outputMap[match.outputValues[i].abbreviation] =
									match.outputValues[i].value;
							}
						}
					}

					$scope.tryRule = function () {
						if(!validate()) {
							return;
						}
						$scope.testButtonDisabled = true;
						const index = _.indexOf($scope.allTransformations, $scope.transformation);
						attributeTransformationsRepository.test(
							$scope.projectId,
							$scope.modelId,
							$scope.allTransformations,
							index
						).then(data => {
							$scope.testResults = data;
							formatTestResults();
							$scope.expanded = true;
							$scope.testButtonDisabled = false;
						}, error => {
							notification.error("Error testing pattern: " + error);
							$scope.testButtonDisabled = false;
						});
					};

					$scope.addReplacement = function () {
						$scope.transformation.replacementRules.push({
							search: "",
							replace: "",
						});
					};

					$scope.deleteReplacement = function (replacementRule) {
						_.remove($scope.transformation.replacementRules, replacementRule);
					};
					$scope.filterAttributes = function (actual /*, expected*/) {
						if(!actual || !actual.abbreviation) {
							return false;
						}
						if(actual.abbreviation === $scope.transformation.attributes[0].abbreviation) {
							return false;
						}
						return true;
					};

					$scope.deleteTransformation = function () {
						$rootScope.$broadcast("attributeTransformation:delete", $scope.transformation);
					};
				}
			],
		};
	}
]);