angular.module("eShareApp").factory("historicalDataRepository", [
	"repositoryBase",

	function (repositoryBase) {

		const historyApi = "/api/project/{projectId}/GetHistoryData/{dataSourceId}";

		function getHistory(projectId, datasourceId, historyIdentifier) {
			return repositoryBase.post(
				historyApi, { projectId: projectId, dataSourceId: datasourceId }, historyIdentifier
			);
		}

		return {
			getHistory: getHistory,
		};
	}
]);
