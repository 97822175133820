angular.module("eShareApp").controller("MapsEditCtrl", mapsEditCtrl);

mapsEditCtrl.$inject = [
	"$scope", "$state", "project", "map", "mapsRepository", "notification", "groups", "$interval",
	"$http", "Upload", "messageBox", "backgroundProgressesRepository", "coordinateSystemRepository"
];

function mapsEditCtrl(
	$scope, $state, project, map, mapsRepository, notification, groups, $interval,
	$http, upload, messageBox, backgroundProgressesRepository, coordinateSystemRepository
) {

	$scope.project = project;
	$scope.projectId = project.id;

	const tempMap = _.cloneDeep(map);
	if(tempMap.zfrom.indexOf(":::") > -1) {
		tempMap.targetCoordinateIdFrom = tempMap.zfrom.substring(tempMap.zfrom.indexOf(":::") + 3);
		tempMap.zfrom = tempMap.zfrom.substring(0, tempMap.zfrom.indexOf(":::"));
	}
	if(tempMap.zto.indexOf(":::") > -1) {
		tempMap.targetCoordinateIdTo = tempMap.zto.substring(tempMap.zto.indexOf(":::") + 3);
		tempMap.zto = tempMap.zto.substring(0, tempMap.zto.indexOf(":::"));
	}

	$scope.map = tempMap;
	$scope.map.viewerId = "MapConfigurationV";
	$scope.map.documentDivId = "MapConfigurationD";
	$scope.map.showPoints = true;
	$scope.map.configurationMode = true;
	$scope.map.isVisible = true;
	$scope.map.loadControl = {};
	$scope.map.viewerControl = {};

	$scope.attributeTag = "((zMap))";
	$scope.searchTarget = "Points";
	$scope.inputErrorMessage = "";
	$scope.groups = groups;
	const coordinateUpperLimit = 2147481997;
	const coordinateLowerLimit = -2147485298;

	$scope.isUploadInProgress = true;

	initialize();

	$scope.$watch("map.shouldAlignManually", toggleMapViewer);

	function initialize() {
		mapsRepository.isMapsBusy($scope.project.id).then(response => {
			$scope.isUploadInProgress = response;
			if($scope.isUploadInProgress) {
				backgroundProgressesRepository
					.getTargetBackgroundMessages($scope.project.id, "Map")
					.then(messages => {
						if(messages) {
							$scope.message = messages[0];
						}
					});
			}

		});
		_.forEach($scope.groups, group => {
			group.hasPermissionRead = $scope.map.groupsAllowedToSee.indexOf(group.id) > -1;
		});

		coordinateSystemRepository
			.getCoordinateSystems(project.id)
			.then(response => {
				if(response.coordinateSystems
						&& response.coordinateSystems.length > 0
						&& response.coordinateSystems[0].name) {
					$scope.coordinateSystems = response.coordinateSystems;
				}
			});

		const isMapsBusyInterval = $interval(() => {
			if($state.current.name === "project.admin.maps.map.edit") {
				mapsRepository.isMapsBusy($scope.project.id).then(response => {
					$scope.isUploadInProgress = response;
				});
				if($scope.isUploadInProgress) {
					backgroundProgressesRepository
						.getTargetBackgroundMessages($scope.project.id, "Map")
						.then(messages => {
							if(messages) {
								$scope.message = messages[0];
							}
						});
				}
			} else {
				$interval.cancel(isMapsBusyInterval);
			}
		}, 5 * 1000);

		if($scope.map.mapCoordinateAndPointInformation.coordinateInformation
				&& $scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointOne
				&& $scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointTwo) {
			$scope.map.shouldAlignManually = true;
		} else {
			$scope.map.shouldAlignManually = false;
		}
		if($scope.map.isImage) {
			$scope.map.shouldAlignManually = true;
		}
	}

	function toggleMapViewer() {
		if($scope.map.shouldAlignManually) {
			if($scope.map.mapCoordinateAndPointInformation.coordinateInformation
					&& !$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointOne) {
				const pointOne = {
					mapX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMin,
					mapY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMin,
					modelX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMin,
					modelY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMin,
				};
				const pointTwo = {
					mapX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMax,
					mapY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMax,
					modelX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMax,
					modelY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMax,
				};

				$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointOne = pointOne;
				$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointTwo = pointTwo;
			}
			const interval = $interval(() => {
				if($state.current.name === "project.admin.maps.map.edit" && $scope.map.loadControl) {
					const loadDocument = $scope.map.loadControl.loadDocument;
					if(loadDocument && $scope.project.id) {
						loadDocument();
						$interval.cancel(interval);
					}
				}
			}, 100);
		} else {
			$("#" + $scope.map.documentDivId).empty();
		}
	}

	mapsRepository.getCoordinateZPlanes($scope.project.id).then(planes => {
		$scope.coordinatePlanes = planes;
	});

	$scope.coordinateSystemIdToName = function (id) {
		if(!$scope.coordinateSystems || $scope.coordinateSystems.length === 0) {
			return "Default Coordinates";
		}
		for(let i = 0; i < $scope.coordinateSystems.length; i++) {
			if($scope.coordinateSystems[i].id === id) {
				return $scope.coordinateSystems[i].name;
			}
		}
		return "Default Coordinates";
	};

	$scope.coordinateSystemIdToDescription = function (id) {
		if(!$scope.coordinateSystems || $scope.coordinateSystems.length === 0) {
			return "No description available";
		}
		for(let i = 0; i < $scope.coordinateSystems.length; i++) {
			if($scope.coordinateSystems[i].id === id) {
				return $scope.coordinateSystems[i].description || "No description available";
			}
		}
		return "No description available";
	};

	$scope.checkIfNotValid = function () {
		if($scope.map.length === 0) {
			return true;
		}
		if($scope.map.displayName.length === 0) {
			$scope.inputErrorMessage = "Missing name for the map";
		}
		if($scope.map.zfrom.length > 50) {
			$scope.inputErrorMessage = "Value for " + ($scope.map.showAllPoints
				? "Camera Level"
				: "Z-Value From") + " contains too many characters";
			return true;
		}
		let valueIsCoordinatePlane;
		if(isNaN($scope.map.zfrom)) {
			valueIsCoordinatePlane = false;
			_.forEach($scope.coordinatePlanes, planeArray => {
				_.forEach(planeArray, plane => {
					if(plane.name === $scope.map.zfrom) {
						valueIsCoordinatePlane = true;
					}
				});
			});
			if(!valueIsCoordinatePlane) {
				$scope.inputErrorMessage = "Value for " + ($scope.map.showAllPoints
					? "Camera Level"
					: "Z-Value From") + " does not correspond to a number or a coordinate level";
				return true;
			}
		} else if($scope.map.zfrom > coordinateUpperLimit
					|| $scope.map.zfrom < coordinateLowerLimit) {
			valueIsCoordinatePlane = false;
			_.forEach($scope.coordinatePlanes, planeArray => {
				_.forEach(planeArray, plane => {
					if(plane.name === $scope.map.zfrom) {
						valueIsCoordinatePlane = true;
					}
				});
			});
			if(!valueIsCoordinatePlane) {
				$scope.inputErrorMessage = "Value for " + ($scope.map.showAllPoints
					? "Camera Level"
					: "Z-Value From") + " is out of bounds";
				return true;
			}
		}
		if($scope.map.zto.length > 50) {
			if(!$scope.map.showAllPoints) {
				$scope.inputErrorMessage = "Value for Z-Value To contains too many characters";
				return true;
			} else {
				$scope.map.zto = "0";
			}
		}
		if(isNaN($scope.map.zto)) {
			valueIsCoordinatePlane = false;
			_.forEach($scope.coordinatePlanes, planeArray => {
				_.forEach(planeArray, plane => {
					if(plane.name === $scope.map.zto) {
						valueIsCoordinatePlane = true;
					}
				});
			});
			if(!valueIsCoordinatePlane) {
				if(!$scope.map.showAllPoints) {
					$scope.inputErrorMessage = "Value for Z-Value To does not correspond"
					+ " to a number or a coordinate level";
					return true;
				} else {
					$scope.map.zto = "0";
				}
			}
		} else if($scope.map.zto > coordinateUpperLimit || $scope.map.zto < coordinateLowerLimit) {
			valueIsCoordinatePlane = false;
			_.forEach($scope.coordinatePlanes, planeArray => {
				_.forEach(planeArray, plane => {
					if(plane.name === $scope.map.zfrom) {
						valueIsCoordinatePlane = true;
					}
				});
			});
			if(!valueIsCoordinatePlane) {
				if(!$scope.map.showAllPoints) {
					$scope.inputErrorMessage = "Value for Z-Value To is out of bounds";
					return true;
				} else {
					$scope.map.zto = "0";
				}
			}
		}
		if($scope.map.shouldAlignManually) {
			const coordinateInfo = $scope.map.mapCoordinateAndPointInformation.coordinateInformation;
			const pointOne = coordinateInfo.pointOne;
			const pointTwo = coordinateInfo.pointTwo;

			if(isNaN(pointOne.mapX)
					|| isNaN(pointOne.mapY)
					|| isNaN(pointOne.modelX)
					|| isNaN(pointOne.modelY)
					|| isNaN(pointTwo.mapX)
					|| isNaN(pointTwo.mapY)
					|| isNaN(pointTwo.modelX)
					|| isNaN(pointTwo.modelY)) {
				$scope.inputErrorMessage = "Point One or Two contain one or more non-numerical values.";
				return true;
			}

			if(parseFloat(pointOne.mapX) === parseFloat(pointTwo.mapX)) {
				$scope.inputErrorMessage = "The Map X values between Point One and Point Two"
				+ " are identical. This makes determining the scale of the map impossible.";
				return true;
			} else if(parseFloat(pointOne.mapY) === parseFloat(pointTwo.mapY)) {
				$scope.inputErrorMessage = "The Map Y values between Point One and Point Two"
				+ " are identical. This makes determining the scale of the map impossible.";
				return true;
			} else if(parseFloat(pointOne.modelX) === parseFloat(pointTwo.modelX)) {
				$scope.inputErrorMessage = "The Model X values between Point One and Point Two"
				+ " are identical. This makes determining the scale of the map impossible.";
				return true;
			} else if(parseFloat(pointOne.modelY) === parseFloat(pointTwo.modelY)) {
				$scope.inputErrorMessage = "The Model Y values between Point One and Point Two"
				+ " are identical. This makes determining the scale of the map impossible.";
				return true;
			}
		}
		$scope.inputErrorMessage = "";
		return false;
	};

	$scope.resetPoints = function () {
		const mbox = messageBox.openQuestion(
			"Reset alignment points to default positions?",
			"Do you really want to reset alignment points to default positions?"
			+ " This cannot be undone.",
			"Reset", "Cancel"
		);
		mbox.result.then(
			() => {
				const pointOne = {
					mapX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMin,
					mapY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMin,
					modelX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMin,
					modelY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMin,
				};
				const pointTwo = {
					mapX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMax,
					mapY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMax,
					modelX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMax,
					modelY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMax,
				};
				$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointOne = pointOne;
				$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointTwo = pointTwo;
				$scope.pointOneChanged();
				$scope.pointTwoChanged();
			}
		);
	};

	$scope.save = function save() {
		const mapToSave = _.cloneDeep($scope.map);
		mapToSave.groupsAllowedToSee = getGroupIdsForPermission(group => {
			return group.hasPermissionRead;
		});
		if(!mapToSave.shouldAlignManually
				&& mapToSave.mapCoordinateAndPointInformation.coordinateInformation) {
			mapToSave.mapCoordinateAndPointInformation.coordinateInformation.pointOne = null;
			mapToSave.mapCoordinateAndPointInformation.coordinateInformation.pointTwo = null;
		}
		if(mapToSave.targetCoordinateIdFrom) {
			mapToSave.zfrom += ":::" + mapToSave.targetCoordinateIdFrom;
		}
		if(mapToSave.targetCoordinateIdTo) {
			mapToSave.zto += ":::" + mapToSave.targetCoordinateIdTo;
		}
		mapsRepository.save($scope.project.id, mapToSave).then(() => {
			notification.success("Map settings saved");
			$state.go("project.admin.maps", {}, { reload: true });
		},
		error => {
			notification.error(error);
		});
	};

	function getGroupIdsForPermission(permissionSelector) {
		return _.map(_.filter($scope.groups, group => {
			return permissionSelector(group);
		}), group => {
			return group.id;
		});
	}

	$scope.pointOneChanged = function () {
		const changePointLocation = $scope.map.viewerControl.changePointLocation;
		if(changePointLocation) {
			changePointLocation(
				"configuration",
				"Point One",
				$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointOne.mapX,
				$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointOne.mapY
			);
		}
	};

	$scope.pointTwoChanged = function () {
		const changePointLocation = $scope.map.viewerControl.changePointLocation;
		if(changePointLocation) {
			changePointLocation(
				"configuration",
				"Point Two",
				$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointTwo.mapX,
				$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointTwo.mapY
			);
		}
	};

	$scope.onPointLocationChanged = function (pointName, x, y) {
		if(pointName === "Point One") {
			$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointOne.mapX = x;
			$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointOne.mapY = y;
		} else if(pointName === "Point Two") {
			$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointTwo.mapX = x;
			$scope.map.mapCoordinateAndPointInformation.coordinateInformation.pointTwo.mapY = y;
		}
	};

	$scope.mapUpload = {
		isActive: false,
		progress: "0%",
	};

	$scope.mapFileSelected = function (files/*, event*/) {
		const file = files ? files[0] : null;
		if(!file) {
			//notification.error("No file selected");
			return;
		}
		const mbox = messageBox.openQuestion(
			"Change map file?",
			"Do you really want to change the map source file? This cannot be undone.",
			"Change", "Cancel"
		);
		mbox.result.then(
			() => {
				let fileType = "";
				const extension = (file.name || "").toLowerCase();
				if(extension.endsWith(".dwg")) {
					fileType = ".dwg";
				}
				if(extension.endsWith(".dxf")) {
					fileType = ".dxf";
				}
				if(extension.endsWith(".pdf")) {
					fileType = ".pdf";
				}
				if(extension.endsWith(".jpg")) {
					fileType = ".jpg";
				}
				if(extension.endsWith(".jpeg")) {
					fileType = ".jpeg";
				}
				if(extension.endsWith(".tif")) {
					fileType = ".tif";
				}
				if(extension.endsWith(".tiff")) {
					fileType = ".tiff";
				}
				if(extension.endsWith(".png")) {
					fileType = ".png";
				}
				if(extension.endsWith(".gif")) {
					fileType = ".gif";
				}
				if(extension.endsWith(".bmp")) {
					fileType = ".bmp";
				}
				if(fileType === "") {
					notification.error("The file type must be .dwg, .dxf or .pdf,"
					+ " or an image format (.jpg, .jpeg, .png, .gif, .bmp, .tif, .tiff)");
					return;
				}
				if(!window.eShare.integratedSecurity) {
					$http.post("api/OneTimeToken/Create")
						.then(
							tokenData => {
								uploadModel(file, fileType, tokenData.data.token);
							},
							() => {
								notification.error("Error getting one-time authorization token");
							}
						);
				} else {
					uploadModel(file, fileType);
				}
			}
		);
	};

	function uploadModel(file, fileType, oneTimeToken) {
		$scope.isUploadInProgress = true;
		let lastModifiedDate;
		try {
			lastModifiedDate = file.lastModifiedDate.getTime();
		} catch(e) {
			lastModifiedDate = file.lastModified;
		}
		let url = oneTimeToken
			? "api/project/{projectId}/Maps/{mapId}/Upload?fileType={fileType}"
			+ "&lastModifiedDate={lastModifiedDate}&token={oneTimeToken}"

			: "api/project/{projectId}/Maps/{mapId}/Upload?fileType={fileType}"
			+ "&lastModifiedDate={lastModifiedDate}";
		url = url.supplant({
			projectId: project.id,
			mapId: $scope.map.id,
			oneTimeToken: oneTimeToken,
			lastModifiedDate: lastModifiedDate,
			fileType: fileType,
		});
		$scope.mapUpload.isActive = true;
		$scope.mapUpload.progress = "0%";
		const uploadSession = upload.upload({
			url: url,
			data: {
				file: file,
			},
		});
		uploadSession.then(onSuccess, onError, onProgress);

		function onSuccess(response) {
			$scope.mapUpload.isActive = false;
			$scope.mapUpload.progress = "100%";
			notification.success("Map file changed");
			$scope.maps = response.data;
			$scope.isUploadInProgress = false;

			_.forEach(response.data, mapFromResponse => {
				if(mapFromResponse.id === $scope.map.id) {
					$scope.map.mapCoordinateAndPointInformation.coordinateInformation =
						mapFromResponse.mapCoordinateAndPointInformation.coordinateInformation;
					const pointOne = {
						mapX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMin,
						mapY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMin,
						modelX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMin,
						modelY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMin,
					};
					const pointTwo = {
						mapX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMax,
						mapY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMax,
						modelX: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.xMax,
						modelY: $scope.map.mapCoordinateAndPointInformation.coordinateInformation.yMax,
					};
					$scope.map.mapCoordinateAndPointInformation
						.coordinateInformation.pointOne = pointOne;
					$scope.map.mapCoordinateAndPointInformation
						.coordinateInformation.pointTwo = pointTwo;
				}
			});
			if($scope.map.shouldAlignManually) {
				$("#" + $scope.map.documentDivId).empty();
				const interval = $interval(() => {
					const loadDocument = $scope.map.loadControl.loadDocument;
					if(loadDocument && $scope.project.id) {
						loadDocument();
						$interval.cancel(interval);
					}
				}, 100);
			}
		}

		function onError(reason) {
			$scope.mapUpload.isActive = false;
			$scope.mapUpload.progress = "100%";
			notification.error("Map file upload failed: " + Utilities.getErrorMessage(reason));
			$scope.isUploadInProgress = false;
		}

		function onProgress(event) {
			$scope.isUploadInProgress = true;
			let progress = event.total ? event.loaded / event.total : 0;
			progress = Math.floor(progress * 100);
			$scope.mapUpload.progress = progress + "%";
		}
	}
}
