angular.module("eShareApp").factory("groupsRepository", [
	"repositoryBase", "notification",

	function (
		repositoryBase, notification
	) {

		const baseApi = "/api";
		const groupsApi = baseApi + "/UserGroups";
		const groupIdApi = groupsApi + "/{groupId}";
		const groupsForUserApi = groupsApi + "/ForUser/{userId}";
		const addUserToGroupApi = groupIdApi + "/AddUser/{userId}";
		const removeUserFromGroupApi = groupIdApi + "/RemoveUser/{userId}";

		function getAll() {
			return repositoryBase.get(groupsApi, {}).then(data => {
				const groups = _.sortBy(data, group => {
					return (group.name || "").toLowerCase();
				});
				return groups;
			}, error => {
				notification.error(error);
			});
		}

		function getAllForProject() {
			return repositoryBase.get(groupsApi, {}).then(data => {
				const groups = _.sortBy(data, group => {
					return (group.name || "").toLowerCase();
				});
				groups.push(
					{ id: "E80FBCA7-6D1B-46A1-8799-222C24320E3B".toLowerCase(), name: "All Users" }
				);
				return groups;
			}, error => {
				notification.error(error);
			});
		}

		function get(id) {
			return repositoryBase.get(groupIdApi, { groupId: id });
		}

		function add(group) {
			return repositoryBase.post(groupsApi, {}, groupToDto(group));
		}

		function update(group) {
			return repositoryBase.put(groupIdApi, { groupId: group.id }, groupToDto(group));
		}

		function remove(group) {
			return repositoryBase.remove(groupIdApi, { groupId: group.id });
		}

		function getForUser(userId) {
			return repositoryBase.get(groupsForUserApi, { userId: userId });
		}

		function addUserToGroup(userId, groupId) {
			return repositoryBase.post(addUserToGroupApi, { groupId: groupId, userId: userId });
		}

		function removeUserFromGroup(userId, groupId) {
			return repositoryBase.remove(removeUserFromGroupApi, { groupId: groupId, userId: userId });
		}

		function groupToDto(group) {
			return {
				id: group.id,
				name: group.name,
				description: group.description,
				memberIds: group.memberIds,
			};
		}

		return {
			getAll: getAll,
			getAllForProject: getAllForProject,
			get: get,
			add: add,
			update: update,
			remove: remove,
			getForUser: getForUser,
			addUserToGroup: addUserToGroup,
			removeUserFromGroup: removeUserFromGroup,
		};
	}
]);
