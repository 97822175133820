angular.module("eShare.documentViewer").directive("cadDocumentSearch", cadDocumentSearch);

cadDocumentSearch.$inject = [];

function cadDocumentSearch() {
	return {
		restrict: "E",
		replace: true,
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadDocumentSearch.directive.html'),
		scope: {},
		bindToController: {
			docInfo: "=",
			onMoveToSearchResultRequested: "&",
			control: "=",
		},
		controllerAs: "vm",
		controller: cadDocumentSearchController,
	};
}

cadDocumentSearchController.$inject = ["$scope", "notification"];

function cadDocumentSearchController($scope, notification) {
	const vm = this;

	vm.searchTextChanged = searchTextChanged;
	vm.clearSearch = clearSearch;

	setupControl();

	$scope.$watch("vm.control", setupControl);

	function setupControl() {
		if(vm.control) {
			vm.control.searchTextChanged = searchTextChanged;
		}
	}

	function searchTextChanged() {
		const docInfo = vm.docInfo;
		if(!docInfo) {
			return;
		}
		const results = [];
		let searchText = docInfo.text || "";
		let searchAbbreviation = docInfo.selectedAbbreviation || "";
		if(!_.some(docInfo.attributeDefinitions, ad => {
			return ad.abbreviation === searchAbbreviation;
		})) {
			if(searchAbbreviation !== "::freeTextSearch::") {
				if(searchAbbreviation !== "") {
					notification.warning("The attribute received from the URL (" + searchAbbreviation
					+ ") could not be found in the document links. Using All links as search target.");
				}
				docInfo.selectedAbbreviation = "";
				searchAbbreviation = "";
			}
		}
		if(isNaN(docInfo.currentPageNumber)) {
			docInfo.currentPageNumber = docInfo.viewer.currentPage();
		}
		if(docInfo.links && searchText || searchAbbreviation) {
			searchText = searchText.toLowerCase();
			const areas = docInfo.links;
			let i;
			let j;
			let area;
			let link;
			let result;
			if(searchAbbreviation === "" || !docInfo.attributeDefinitions) {
				// Searching for searchText matching either link text or link value
				for(i = 0; i < areas.length; ++i) {
					area = areas[i];
					for(j = 0; j < area.links.length; j++) {
						link = area.links[j];
						if((link.text || "").toLowerCase().contains(searchText)
						|| (link.value || "").toLowerCase().contains(searchText)) {
							result = {
								page: area.page,
								left: area.left,
								top: area.top,
								right: area.right,
								bottom: area.bottom,
								i: area.i,
								value: link.value,
								text: link.text,
								tags: link.tags,
								url: link.url,
							};
							results.push(result);
						}
					}
				}
			} else if(areas) {
				// Searching for searchText matching link value and abbreviation
				for(i = 0; i < areas.length; ++i) {
					area = areas[i];
					for(j = 0; j < area.links.length; j++) {
						link = area.links[j];
						const value = (link.value || "").toLowerCase();
						if(searchText === "" || value.contains(searchText)) {
							if(_.some(link.tags, tag => {
								return tag.a === searchAbbreviation || tag.g === searchAbbreviation;
							})) {
								result = {
									page: area.page,
									left: area.left,
									top: area.top,
									right: area.right,
									bottom: area.bottom,
									i: area.i,
									value: link.value,
									text: link.text,
									tags: link.tags,
									url: link.url,
								};
								results.push(result);
							}
						}
					}
				}
			}
		}
		if(docInfo.texts && searchText && searchAbbreviation === "::freeTextSearch::") {
			const texts = docInfo.texts;
			let text;
			for(let k = 0; k < texts.length; ++k) {
				text = texts[k];
				if(text.text.toLowerCase().contains(searchText)) {
					results.push(text);
				}
			}
		}
		docInfo.results = results;
		docInfo.hasResults = results.length > 0;
		docInfo.lastIndex = results.length - 1;
		docInfo.currentIndex = 0;
		fireMoveToSearchResultRequested(0);
	}

	function clearSearch() {
		const docInfo = vm.docInfo;
		if(!docInfo) {
			return;
		}
		docInfo.text = "";
		searchTextChanged();
	}

	function fireMoveToSearchResultRequested(delta) {
		const onMoveToSearchResultRequested = vm.onMoveToSearchResultRequested();
		if(onMoveToSearchResultRequested) {
			onMoveToSearchResultRequested(delta);
		}
	}
}