angular.module("eShareApp").controller("ErrorCtrl", [
	"$scope", "$state", "project",

	function (
		$scope, $state, project
	) {
		$scope.state = $state;
		$scope.project = project;
	}
]);
