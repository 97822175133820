angular.module("eShareApp").controller("StatusTrackingAdminCtrl", [
	"$rootScope", "$state", "$scope", "messageBox", "project", "statusTrackingKinds",
	"statusTrackingKindRepository",

	function (
		$rootScope, $state, $scope, messageBox, project, statusTrackingKinds,
		statusTrackingKindRepository
	) {

		$scope.project = project;
		$scope.statusTrackings = statusTrackingKinds;

		$scope.addStatusTrackingKind = function () {
			$state.go(".editStatusTracking", {
				statusTrackingId: -1,
				projectId: project.id,
			});
		};

		$scope.removeStatusTrackingKind = function (statusTrackingKind) {
			const mbox = messageBox.openQuestion(
				"Remove selected status trackign type?",
				"Do you really want to remove the " + statusTrackingKind.name
				+ " status tracking type?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					statusTrackingKindRepository.remove(project.id, statusTrackingKind.id);
					$state.go($state.current, {}, {
						reload: true,
					});
				}
			);
		};
	}
]);
