angular.module("eShareApp").controller("Model3dPrintCtrl", Model3dPrintCtrl);

Model3dPrintCtrl.$inject = [
	"$scope", "$timeout", "$filter", "$state", "$stateParams", "eBrowser", "project",
	"model3dState", "notification", "coordinateAttributes"
];

function Model3dPrintCtrl(
	$scope, $timeout, $filter, $state, $stateParams, eBrowser, project,
	model3dState, notification, coordinateAttributes
) {
	const vm = this;
	vm.projectName = project.name;
	vm.positionId = model3dState.positionId || "Unnamed Object";
	vm.imageDataUrl = null;
	vm.rows = [];
	vm.close = close;

	let tag = undefined;

	initialize();

	function initialize() {
		if(!project || !project.id) {
			notification.error("Project not found");
			$state.go("selectProject", {}, { location: "replace" });
			return;
		}
		$scope.$on("$viewContentLoaded", viewContentLoaded);
		getImageDataUrl();
		const formatAttributeValue = $filter("formatAttributeValue");
		const newlineToBr = $filter("newlineToBr");
		const attributesView = model3dState.attributesView;
		for(let categoryIndex = 0; categoryIndex < attributesView.length; ++categoryIndex) {
			let isCoordinate = false;
			const category = attributesView[categoryIndex];
			const attributes = category.attributes;
			if(!Utilities.isArray(attributes) || attributes.length === 0) {
				continue;
			}
			let categoryRow = {
				isHeader: true,
				isData: false,
				dataType: "category",
				value: category.displayCategory || "Category",
			};
			for(let i = 0; i < attributes.length; ++i) {
				const attribute = attributes[i];
				const dataType = attribute.dataType;
				const name = attribute.displayName;
				let value = formatAttributeValue(attribute);
				let isHtml = false;
				switch(attribute.dataType) {
				case "DocumentId":
				case "linkedModelItem":
					value = attribute.displayValue;
					break;
				case "Multiline":
					value = newlineToBr(value);
					isHtml = true;
					break;
				case "statusTracking":
					break;
				case "valueSet":
					value = attribute.value;
					break;
				case "hyperlink":
					value = decodeURIComponent(attribute.value);
					break;
				case "linkedPointOfInterest":
					value = attribute.displayValue;
					break;
				default:{
					const eShareKey = attribute.eShareKey;
					if(eShareKey && eShareKey.keyType === "Tag" && eShareKey.tags.length === 1) {
						const abbreviation = eShareKey.tags[0].attribute;
						isCoordinate = _.includes(coordinateAttributes, abbreviation);
						if(attribute.isKey) {
							tag = abbreviation;
							vm.positionId = eShareKey.tags[0].value;
						}
					}
					break;
				}
				}
				if(attribute.isHidden && !category.showHidden) {
					continue;
				}
				if(categoryRow) {
					vm.rows.push(categoryRow);
					categoryRow = null;
				}
				vm.rows.push({
					isHeader: false,
					isData: true,
					isHtml: isHtml,
					dataType: dataType,
					name: name,
					value: value,
					isCoordinate: isCoordinate,
				});
			}
		}
	}

	function close() {
		$state.go("^", $stateParams, { location: "replace" });
	}

	function viewContentLoaded() {
		let stateParams = null;
		if($stateParams.groupParentTag && $stateParams.groupChildTag && $stateParams.groupId) {
			stateParams = {
				positionId: undefined,
				tag: undefined,
				geometryId: undefined,
				modelTimestamp: undefined,
				groupParentTag: $stateParams.groupParentTag,
				groupChildTag: $stateParams.groupChildTag,
				groupId: $stateParams.groupId,
				externalId: undefined,
				externalTag: undefined,
				pointId: undefined,
			};
		} else if($stateParams.externalId && $stateParams.externalTag) {
			stateParams = {
				positionId: undefined,
				tag: undefined,
				geometryId: undefined,
				modelTimestamp: undefined,
				groupParentTag: undefined,
				groupChildTag: undefined,
				groupId: undefined,
				externalId: $stateParams.externalId,
				externalTag: $stateParams.externalTag,
				pointId: undefined,
			};
		} else if($stateParams.pointId) {
			stateParams = {
				positionId: undefined,
				tag: undefined,
				geometryId: undefined,
				modelTimestamp: undefined,
				groupParentTag: undefined,
				groupChildTag: undefined,
				groupId: undefined,
				externalId: undefined,
				externalTag: undefined,
				pointId: $stateParams.pointId,
			};
		} else if(vm.positionId && tag) {
			stateParams = {
				positionId: vm.positionId,
				tag: tag,
				geometryId: undefined,
				modelTimestamp: undefined,
				groupParentTag: undefined,
				groupChildTag: undefined,
				groupId: undefined,
				externalId: undefined,
				externalTag: undefined,
				pointId: undefined,
			};
		}
		const qrcodeElement = angular.element(document.getElementById("qrcode"));
		if(stateParams) {
			let href = $state.href("project.model", stateParams, { absolute: true });
			const qrcode = new QRCode("qrcode", {
				width: 512,
				height: 512,
				correctLevel: QRCode.CorrectLevel.L,
			});
			eBrowser.getCurrentLocationUrl().then(location => {
				if(location) {
					href += location;
				}
				qrcode.makeCode(href);
				const imageElement = qrcodeElement.find("img")[0];
				imageElement.style.width = "100%";
				imageElement.style.height = "100%";
				$timeout(() => {
					window.print();
				}, 500);
			});
		} else {
			qrcodeElement.innerHTML = "";
			$timeout(() => {
				window.print();
			}, 500);
		}
	}

	function getImageDataUrl() {
		try {
			eBrowser.print3D().then(imageData => {
				vm.imageDataUrl = Utilities.isString(imageData) && imageData
					? "data:image/png;base64," + imageData
					: "";
			});
		} catch(e) {
			vm.imageDataUrl = "";
		}
	}
}
