angular.module("eShareApp").factory("eGoSettingsRepository", eGoSettingsRepository);

eGoSettingsRepository.$inject = ["repositoryBase"];

function eGoSettingsRepository(repositoryBase) {

	const baseApi = "api/project/{projectId}/eGoSettings";
	const testApi = baseApi + "/test";
	const exportApi = baseApi + "/export";
	const importApi = baseApi + "/import";
	const deviceInfoApi = "api/eGoDeviceInformation";

	return {
		executeQuery: executeQuery,
		saveSettings: saveSettings,
		getSettings: getSettings,
		exportSettings: exportSettings,
		importSettings: importSettings,
		getDeviceInformation: getDeviceInformation,
		saveLicense: saveLicense,
	};

	function executeQuery(projectId, pattern, data) {
		const postData = {
			pattern: pattern,
			testData: data,
		};
		return repositoryBase.post(testApi, { projectId: projectId }, postData);
	}

	function saveSettings(projectId, data) {
		const postData = {
			qrScript: data,
		};
		return repositoryBase.post(baseApi, { projectId: projectId }, postData);
	}

	function getSettings(projectId) {
		return repositoryBase.get(baseApi, { projectId: projectId });
	}

	function exportSettings(projectId) {
		return repositoryBase.post(exportApi, { projectId: projectId }).then(
			data => {
				return JSON.stringify(data, undefined, 3);
			}
		);
	}

	function importSettings(projectId, serializedExportedData) {
		const exportedData = JSON.parse(serializedExportedData);
		return repositoryBase.post(importApi, { projectId: projectId }, exportedData);
	}

	function getDeviceInformation() {
		return repositoryBase.get(deviceInfoApi);
	}

	function saveLicense(deviceInformation) {
		return repositoryBase.post(deviceInfoApi, {}, deviceInformation);
	}
}
