angular.module("eShareApp").directive("breadcrumb", [
	"$parse",
	function ($parse) {
		return {
			restrict: "E",
			replace: true,
			scope: {
			},
			controller: [
				"$scope", "$element", "$rootScope", "$state",
				function ($scope, $element, $rootScope, $state) {
					function updateBreadcrumb(event, toState, toParams /*, fromState, fromParams*/) {
						const list = angular.element("<ul class=\"breadcrumb\"></ul>");

						const state = $state.$current;

						for(let i = 0; i < state.path.length; i++) {
							let title = state.path[i].data.title;
							if(title != "") {
								const item = angular.element("<li></li>");
								if(i === state.path.length - 1) {
									item.attr("class", "active");
								}
								let titleElem = item;
								if(i !== state.path.length - 1) {
									if(i === 0
										&& toState === ""
										&& !toParams.hasOwnProperty("projectId")
										&& state.path[i].url.sourcePath.contains("{projectId}")) {
										toParams.projectId = state.path[i].locals.globals.project.id;
									}
									titleElem = angular.element("<a href=\"#"
									+ state.path[i].url.format(toParams)
									+ "\"></a>");
									item.append(titleElem);
								}
								const stateGlobals = state.path[i].locals.globals;
								if(stateGlobals && title.indexOf("{{") !== -1) {
									while(title.indexOf("{{") !== -1) {
										const expr = title.replace(/.*?{{(.+?)}}.*/, "$1");
										const parsed = $parse(expr)({}, stateGlobals);
										title = title.replace(/{{(.+?)}}/, parsed);
									}
								}
								titleElem.text(title);
								list.append(item);
							}
						}

						angular.element($element).html(list);
					}

					$scope.$on("$stateChangeSuccess", updateBreadcrumb);

					updateBreadcrumb("init", "", {});
				}
			],
		};
	}
]);