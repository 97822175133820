angular.module("eShareApp").controller("SubModelViewCtrl", SubModelViewCtrl);

SubModelViewCtrl.$inject = [
	"$scope", "$state", "$stateParams", "subModelDivisionRepository", "attributeDefinitions",
	"messageBox", "notification"
];

function SubModelViewCtrl(
	$scope, $state, $stateParams, subModelDivisionRepository, attributeDefinitions,
	messageBox, notification
) {

	$scope.configuration = [];
	$scope.attributeDefinitions = attributeDefinitions || [];
	$scope.subModelLimit = 50;
	$scope.modelDivisionInformationByRuleId = [];

	subModelDivisionRepository.getModelDivisionRules($stateParams.projectId).then(
		configuration => {
			$scope.configuration = configuration || {};
			if(!$scope.configuration.rules) {
				$scope.configuration.rules = [];
			}
			subModelDivisionRepository.getModelDivisionInformation($stateParams.projectId).then(
				information => {
					$scope.modelDivisionInformation = information || [];
					_.forEach($scope.configuration.rules, rule => {
						rule.subModelCount = getRuleSubModelCount(rule.id);
					});
				}
			);
		}
	);

	$scope.getObjectCountByRuleId = function (ruleId) {
		if(!$scope.modelDivisionInformationByRuleId) {
			return;
		}
		let counter = 0;
		for(let i = 0; i < $scope.modelDivisionInformationByRuleId[ruleId].length; i++) {
			const information = $scope.modelDivisionInformationByRuleId[ruleId][i];
			if(information.objectCount != undefined || counter >= $scope.subModelLimit) {
				return;
			}
			counter++;
			getSubModelGeometryIdsLength(information);
		}
	};

	$scope.getAttributeDisplayName = function (tag) {
		if(!tag) {
			return "None";
		}
		const attribute = _.find($scope.attributeDefinitions, attribute => {
			if(attribute.abbreviation === tag) {
				return true;
			}
			return false;
		});
		return attribute ? attribute.displayName + " (" + tag + ")" : "";
	};

	function getRuleSubModelCount(ruleId) {
		const subModels = _.filter($scope.modelDivisionInformation, information => {
			return information.ruleId === ruleId;
		});
		$scope.modelDivisionInformationByRuleId[ruleId] = subModels;
		return subModels.length;
	}

	$scope.deleteRule = function (rule, ruleIndex) {
		const mbox = messageBox.openQuestion(
			"Remove selected Division Rule?",
			"Do you really want to remove the rule " + rule.name + "?",
			"Remove", "Cancel"
		);
		mbox.result.then(
			() => {
				$scope.configuration.rules.splice(ruleIndex, 1);
				const cfg = _.cloneDeep($scope.configuration);
				subModelDivisionRepository
					.setModelDivisionRules($stateParams.projectId, cfg)
					.then(() => {
					//Do nothing
					}, error => {
						notification.error(error);
					});
			}
		);
	};

	function getSubModelGeometryIdsLength(subModelInformation) {
		subModelDivisionRepository
			.getSubModelGeometryIds($stateParams.projectId, subModelInformation.id)
			.then(result => {
				if(result) {
					subModelInformation.objectCount = result.length;
				}
			}, error => {
				notification.error(
					"Searching for sub-model " + subModelInformation.name + " failed. Reason: " + error
				);
			});
	}
}
