angular.module("eShareApp").directive("cadRegexExcelColumnTransformation", [
	"notification",
	function (notification) {
		return {
			restrict: "A",
			replace: true,
			scope:
			{
				definitions: "=",
				transformation: "=",
				allTransformations: "=",
				testTransformationFunction: "&",
			},
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadRegexExcelColumnTransformation.html'),
			controller: [
				"$scope",
				function ($scope) {
					$scope.sourceColumn = _.find(
						$scope.definitions,
						{ columnIndex: $scope.transformation.sourceColumn.columnIndex || null }
					);
					if(Utilities.isNullOrUndefined($scope.sourceColumn)) {
						$scope.sourceColumn = _.head(_.sortBy($scope.definitions, "columnIndex"));
					}
					$scope.transformation.abbreviation = $scope.sourceColumn.columnIndex;

					$scope.testResults = {
						sourceDefinitions: [],
						targetDefinitions: [],
						matchingResults: [],
						nonMatching: [],
					};

					$scope.testButtonDisabled = false;
					$scope.testResultExpanded = false;

					$scope.toggleTestResultExpand = function () {
						$scope.testResultExpanded = !$scope.testResultExpanded;
					};

					$scope.isValid = function () {
						let isValid = true;
						if(!$scope.sourceColumn || !$scope.sourceColumn.name) {
							isValid = false;
						}
						if(!$scope.transformation
							|| !$scope.transformation.pattern
							|| $scope.transformation.pattern.trim() === "") {
							isValid = false;
						}
						$scope.transformation.isValid = isValid;
						return isValid;
					};

					$scope.tryPattern = function () {
						if(!validate()) {
							return;
						}
						$scope.testButtonDisabled = true;
						const index = _.indexOf($scope.allTransformations, $scope.transformation);
						$scope.testTransformationFunction()(index)
							.then(data => {
								$scope.testResults = data;
								formatTestResults();
								$scope.testResultExpanded = true;
								$scope.testButtonDisabled = false;
							}, error => {
								$scope.testButtonDisabled = false;
								throw new Error("Error testing pattern: " + error);
							});
					};

					$scope.deleteTransformation = function () {
						_.remove($scope.allTransformations, $scope.transformation);
					};

					$scope.$watch("sourceColumn", (newValue /*, oldValue*/) => {
						if($scope.transformation && newValue && newValue.name) {
							$scope.transformation.sourceColumn = newValue;
						}
					});
					$scope.$watch("transformation.pattern", newValue => {
						const re = /\(\?<([^>]*)>/g;
						let match;
						const namesInPattern = [];
						$scope.transformation.attributes = $scope.transformation.attributes || [];
						while((match = re.exec(newValue))) {
							namesInPattern.push(match[1]);
							if(!_.some($scope.transformation.attributes, { name: match[1] })) {
								// If this is a new name, add it to the list of attributes:
								$scope.transformation.attributes.push({
									name: match[1],
									displayName: match[1],
								});
							}
						}
						// Check for removed name:
						const allNames = _.map($scope.transformation.attributes, "name");
						for(const idx in allNames) {
							if(!_.includes(namesInPattern, allNames[idx])) {
								_.remove($scope.transformation.attributes, { name: allNames[idx] });
							}
						}
					});
					$scope.$watch("sourceColumn", (/*newValue*/) => {
						$scope.transformation.abbreviation = $scope.sourceColumn.columnIndex;
					});

					function validate() {
						if(!$scope.sourceColumn) {
							notification.error("A source column must be selected");
							return false;
						}
						if(!$scope.transformation
							|| !$scope.transformation.pattern
							|| $scope.transformation.pattern.trim() === "") {
							notification.error("A pattern to match must be entered");
							return false;
						}
						return true;
					}

					function formatTestResults() {
						for(let j = 0; j < $scope.testResults.matchingResults.length; j++) {
							const match = $scope.testResults.matchingResults[j];
							match.inputMap = {};
							match.outputMap = {};
							for(let i = 0; i < match.inputValues.length; i++) {
								match.inputMap[match.inputValues[i].abbreviation] =
									match.inputValues[i].value;
							}
							for(let i = 0; i < match.outputValues.length; i++) {
								match.outputMap[match.outputValues[i].abbreviation] =
									match.outputValues[i].value;
							}
						}
						for(let j = 0; j < $scope.testResults.nonMatchingResults.length; j++) {
							const match = $scope.testResults.nonMatchingResults[j];
							match.inputMap = {};
							for(let i = 0; i < match.inputValues.length; i++) {
								match.inputMap[match.inputValues[i].abbreviation] =
									match.inputValues[i].value;
							}
						}
					}
				}
			],
		};
	}
]);