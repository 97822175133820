angular.module("eShareApp").controller("SearchCtrl", SearchCtrl);

SearchCtrl.$inject = [
	"$state", "$stateParams", "$timeout", "$location", "project", "searchService",
	"attributeDefinitions", "poiAttributeKinds", "notification", "l10n",
	"poiAttributeKindRepository", "statusTrackingDefinitions", "poiKinds", "savedSearches",
	"savedSearchRepository", "$uibModal", "messageBox", "markupsRepository", "wordExportTemplates",
	"coordinateSystemRepository", "excelExportDataSources",
	"savedSearchExportConfigurationsRepository", "attributeDefinitionsUsedByObjects",
	"authorization", "groupsRepository"
];

function SearchCtrl(
	$state, $stateParams, $timeout, $location, project, searchService,
	attributeDefinitions, poiAttributeKinds, notification, l10n,
	poiAttributeKindRepository, statusTrackingDefinitions, poiKinds, savedSearches,
	savedSearchRepository, $uibModal, messageBox, markupsRepository, wordExportTemplates,
	coordinateSystemRepository, excelExportDataSources,
	savedSearchExportConfigurationsRepository, attributeDefinitionsUsedByObjects,
	authorization, groupsRepository
) {
	const vm = this;
	vm.wordExportTemplates = wordExportTemplates;
	vm.chunkSize = 100;

	authorization.getUserPermissions(project.id).then(permission => {
		vm.isAdmin = permission.data.isProjectAdmin || permission.data.isSystemAdmin;
		if(vm.isAdmin) {
			groupsRepository.getAllForProject().then(groups => {
				vm.groups = groups;
			});
		}
	});

	coordinateSystemRepository.getCoordinateSystems(project.id).then(response => {
		if(response.coordinateSystems
				&& response.coordinateSystems.length > 0
				&& response.coordinateSystems[0].name) {
			vm.coordinateSystems = response.coordinateSystems;
		}
	});

	const anyOperations = ["eq", "notEq"];
	const anyHistoryOperations = ["wasEq"];
	const enumOperations = ["eq", "notEq", "exists"];
	const enumHistoryOperations = ["wasEq", "existed"];
	const multilineOperations = ["contains", "exists"];
	const multilineHistoryOperations = ["contained", "existed"];
	const objectOperations = ["eq", "notEq", "exists", "gt", "ge", "lt", "le"];
	const objectHistoryOperations = ["wasEq", "existed", "wasGt", "wasGe", "wasLt", "wasLe"];
	const pointOperations = ["eq", "notEq", "gt", "ge", "lt", "le"];
	const pointHistoryOperations = ["wasEq", "wasGt", "wasGe", "wasLt", "wasLe"];
	const pointStringOperations = ["eq", "notEq", "exists", "gt", "ge", "lt", "le"];
	const statusTrackingOperations = ["statusIs", "statusIsSet", "statusIsNotSet"];
	const statusTrackingTagRegex = new RegExp(
		"^\\(\\(ST:" + Utilities.guidRegexStr + "\\)\\)$", "i"
	);

	const enumTags = _.uniq(_.map(_.filter(_.flatMap(poiAttributeKinds, kinds => {
		return kinds.attributeKinds;
	}), kind => {
		return kind.dataType === "Enumeration";
	}), kind => {
		return kind.id.toString();
	}));
	const enumOptions = {};

	mergeStatusTrackingDefinitions();
	loadAllEnumOptions();

	function mergeStatusTrackingDefinitions() {
		if(!Utilities.isArray(statusTrackingDefinitions)) {
			return;
		}
		for(let i = 0; i < statusTrackingDefinitions.length; ++i) {
			const statusTrackingDefinition = statusTrackingDefinitions[i];
			const attributeDefinition = {
				displayName: statusTrackingDefinition.name,
				abbreviation: "((ST:" + statusTrackingDefinition.id + "))",
				dataType: "status.enum",
				id: statusTrackingDefinition.id,
				enumValues: statusTrackingDefinition.statusValues,
			};
			attributeDefinitions.push(attributeDefinition);
		}
		attributeDefinitions = _.sortBy(attributeDefinitions, ad => {
			return ad.displayName.toLowerCase();
		});
	}

	function loadAllEnumOptions() {
		for(let i = 0; i < enumTags.length; i++) {
			const enumTag = enumTags[i];
			loadEnumOptions(enumTag);
		}
		enumOptions["((pointKind))"] = _.map(poiKinds, poiKind => {
			return { name: poiKind.name, id: poiKind.id.toString() };
		});
		enumOptions["((pointKind))"].push({ name: "Any", id: "-1" });
		enumOptions["((pointKind))"].push({ name: "Any markup type", id: "-2" });

		function loadEnumOptions(enumTagToRetrieve) {
			poiAttributeKindRepository.get($stateParams.projectId, enumTagToRetrieve)
				.then(data => {
					enumOptions[enumTagToRetrieve] = _.map(data.enumValues, value => {
						return { name: value.value, id: value.id.toString() };
					});
				}, error => {
					notification.error(error);
				});
		}
	}

	vm.examineAllObjects = examineAllObjects;
	vm.isExamineObjectsActive = isExamineObjectsActive;
	vm.isExamineAllObjectsActive = isExamineAllObjectsActive;

	function examineAllObjects(includeGroups) {
		if(searchService.result.isFetchingGeometry) {
			return;
		}
		const request = _.cloneDeep(searchService.result.request);
		searchService.getObjectGeometryIds(request, attributeDefinitions).then(
			data => {
				if($state.current.name !== "project.search") {
					searchService.result.allObjectGeometryIds = data.allObjectGeometryIds;
					searchService.result.objectGeometryIds = data.objectGeometryIds;
					return;
				}
				if(data.objectGeometryIds.length > 200000
					|| (includeGroups && data.allObjectGeometryIds.length > 200000)) {
					messageBox.openQuestion(
						"Large Examine",
						"You are examining a very large group of objects. ("
							+ (includeGroups
								? data.allObjectGeometryIds.length
								: data.objectGeometryIds.length)
							+ ")<br><br>Do you really want to continue examining?"
							+ " Doing so may cause eShare to become unresponsive for a while.",
						"<i class='fa fa-check fa-fw'></i> Yes", "<i class='fa fa-times fa-fw'></i> No"
					).result.then(
						() => {
							searchService.result.allObjectGeometryIds = data.allObjectGeometryIds;
							searchService.result.objectGeometryIds = data.objectGeometryIds;
							searchService.result.shouldExamineAllObjects = true;
							searchService.result.includeGroups = includeGroups;
							searchService.result.isDocumentExamine = false;
							$state.go("project.model");
							return;
						}, () => {
							searchService.result.allObjectGeometryIds = data.allObjectGeometryIds;
							searchService.result.objectGeometryIds = data.objectGeometryIds;
							return;
						}
					);
				} else {
					searchService.result.allObjectGeometryIds = data.allObjectGeometryIds;
					searchService.result.objectGeometryIds = data.objectGeometryIds;
					searchService.result.shouldExamineAllObjects = true;
					searchService.result.includeGroups = includeGroups;
					searchService.result.isDocumentExamine = false;
					$state.go("project.model");
				}
			}
		);
	}

	function isExamineObjectsActive() {
		if(vm.searchResult.numberOfObjectsFound > 1) {
			if(!vm.searchResult.areObjectsTruncated) {
				let objectsFound = false;
				_.forEach(vm.searchResult.objects, object => {
					if(!object.groupDefinition
						&& object.geometryId
						&& object.geometryId > -1) {
						objectsFound = true;
						return;
					}
				});
				return objectsFound;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	function isExamineAllObjectsActive() {
		if(vm.searchResult.numberOfObjectsFound > 1) {
			if(!vm.searchResult.areObjectsTruncated) {
				let groupsFound = false;
				_.forEach(vm.searchResult.objects, object => {
					if(object.groupDefinition) {
						groupsFound = true;
						return;
					}
				});
				return groupsFound;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	const multilinePois = _.map(_.filter(poiAttributeKinds, kind => {
		return kind.dataType === "Multiline";
	}), kind => {
		return kind.id.toString();
	});

	vm.operations = objectOperations;
	vm.attributeDefinitions = attributeDefinitions;
	vm.savedSearches = savedSearches;

	vm.simpleSearch = !$stateParams.isAdvanced || $stateParams.isAdvanced === "false";
	vm.targetObjects = !vm.simpleSearch && $stateParams.searchTarget === "Objects";
	vm.targetPoints = !vm.simpleSearch && $stateParams.searchTarget === "Points";

	vm.activeTab = 0;
	if(vm.simpleSearch) {
		vm.activeTab = 0;
	} else if(vm.targetObjects) {
		vm.activeTab = 1;
	} else if(vm.targetPoints) {
		vm.activeTab = 2;
	}

	vm.tabChanged = tabChanged;

	function tabChanged(tabIndex) {
		vm.simpleSearch = false;
		vm.targetObjects = false;
		vm.targetPoints = false;
		switch(tabIndex) {
		case 0:
			vm.simpleSearch = true;
			break;
		case 1:
			vm.targetObjects = true;
			break;
		case 2:
			vm.targetPoints = true;
			break;
		default:
			vm.simpleSearch = true;
			break;
		}
	}

	vm.simpleSearchRequest = {
		projectId: (project && project.id) || Utilities.emptyGuid(),
		isAdvanced: false,
		text: $stateParams.text || "",
		scope: $stateParams.scope || "IdOnly",
		terms: [],
		searchTarget: "Objects",
	};
	vm.modelSearchRequest = {
		projectId: (project && project.id) || Utilities.emptyGuid(),
		isAdvanced: true,
		text: "",
		scope: $stateParams.scope || "IdOnly",
		terms: vm.targetObjects ? searchService.deserializeTerms($stateParams.terms) : [],
		searchTarget: "Objects",
	};
	vm.pointSearchRequest = {
		projectId: (project && project.id) || Utilities.emptyGuid(),
		isAdvanced: true,
		text: "",
		scope: $stateParams.scope || "IdOnly",
		terms: vm.targetPoints
			? searchService.deserializeTerms($stateParams.terms)
			: [
				{
					attributeTag: "((pointKind))",
					operation: "eq",
					text: "-1",
					date: undefined,
					previousTag: "((pointKind))",
					isMultiline: false,
				}
			],
		searchTarget: "Points",
	};
	vm.modelTermPlaceholder = {
		attributeTag: "",
	};
	vm.pointTermPlaceholder = {
		attributeTag: "",
	};
	vm.searchResult = searchService.result;
	let j;
	for(j = 0; j < vm.modelSearchRequest.terms.length; j++) {
		vm.modelSearchRequest.terms[j].isMultiline = isMultiline(vm.modelSearchRequest.terms[j].tag);
		if(tagToDataType(vm.modelSearchRequest.terms[j].attributeTag) === "unixDate") {
			vm.modelSearchRequest.terms[j].date = l10n.textToLocalDate(
				vm.modelSearchRequest.terms[j].text
			);
		}
	}
	for(j = 0; j < vm.pointSearchRequest.terms.length; j++) {
		const term = vm.pointSearchRequest.terms[j];
		vm.pointSearchRequest.terms[j].isMultiline = isMultiline(vm.pointSearchRequest.terms[j].tag);
		if(tagToDataType(vm.pointSearchRequest.terms[j].attributeTag) === "unixDate") {
			vm.pointSearchRequest.terms[j].date = l10n.textToLocalDate(
				vm.pointSearchRequest.terms[j].text
			);
		}
		if(term.attributeTag.length > 40
			&& (term.attributeTag.indexOf("((x") > -1
				|| term.attributeTag.indexOf("((y") > -1
				|| term.attributeTag.indexOf("((z") > -1
				|| term.attributeTag.indexOf("((cameraX") > -1
				|| term.attributeTag.indexOf("((cameraY") > -1
				|| term.attributeTag.indexOf("((cameraZ") > -1)) {
			const colonPosition = term.attributeTag.indexOf(":");
			if(colonPosition > -1) {
				term.targetCoordinateId = term.attributeTag.substring(
					colonPosition + 1, term.attributeTag.length - 2
				);
				term.attributeTag = term.attributeTag.substring(0, colonPosition) + "))";
			}
		}
	}
	vm.searchLabel = $stateParams.label;

	vm.savedSearchActions = [
		{
			"class": "fa-trash-o",
			"label": "Delete",
		}
	];

	vm.modelTermTagSelected = modelTermTagSelected;
	vm.addModelSearchTerm = addModelSearchTerm;
	vm.pointTermTagSelected = pointTermTagSelected;
	vm.addPointSearchTerm = addPointSearchTerm;
	vm.attributeTagChanged = attributeTagChanged;
	vm.clearTerms = clearTerms;
	vm.isModelSearchValid = isModelSearchValid;
	vm.isPointSearchValid = isPointSearchValid;
	vm.operationToDisplayName = operationToDisplayName;
	vm.removeModelSearchTerm = removeModelSearchTerm;
	vm.removePointSearchTerm = removePointSearchTerm;
	vm.scopeToAttributeTag = scopeToAttributeTag;
	vm.scopeToDisplay = scopeToDisplay;
	vm.search = search;
	vm.openExcelExportDialog = openExcelExportDialog;
	vm.exportWord = exportWord;
	vm.export3dd = export3dd;
	vm.exportMarkup = exportMarkup;
	vm.exportBcf = exportBcf;
	vm.tagToDataType = tagToDataType;
	vm.tagToDisplayName = tagToDisplayName;
	vm.getEnumOptions = getEnumOptions;
	vm.getStatusEnumOptions = getStatusEnumOptions;
	vm.getEnumText = getEnumText;
	vm.getModelAttributeDefinitions = getModelAttributeDefinitions;
	vm.getPointAttributeDefinitions = getPointAttributeDefinitions;
	vm.getOperations = getOperations;
	vm.saveSearch = saveSearch;
	vm.deleteSavedSearch = deleteSavedSearch;
	vm.isStatusChecked = isStatusChecked;
	vm.toggleStatusChecked = toggleStatusChecked;
	vm.openMarkup = openMarkup;
	vm.removeMarkup = removeMarkup;
	vm.has3ddExport = window.eShare.has3ddExport;
	vm.isAttributeCoordinate = isAttributeCoordinate;
	vm.coordinateSystemIdToName = coordinateSystemIdToName;
	vm.coordinateSystemIdToDescription = coordinateSystemIdToDescription;
	vm.setMarkupChunk = setMarkupChunk;
	vm.currentMarkupRangeUpperLimit = currentMarkupRangeUpperLimit;

	function isAttributeCoordinate(tag) {
		return tag === "((x))"
			|| tag === "((y))"
			|| tag === "((z))"
			|| tag === "((cameraX))"
			|| tag === "((cameraY))"
			|| tag === "((cameraZ))";
	}

	function coordinateSystemIdToName(id) {
		if(!vm.coordinateSystems || vm.coordinateSystems.length === 0) {
			return "Default Coordinates";
		}
		for(let i = 0; i < vm.coordinateSystems.length; i++) {
			if(vm.coordinateSystems[i].id === id) {
				return vm.coordinateSystems[i].name;
			}
		}
		return "Default Coordinates";
	}

	function coordinateSystemIdToDescription(id) {
		if(!vm.coordinateSystems || vm.coordinateSystems.length === 0) {
			return "No description available";
		}
		for(let i = 0; i < vm.coordinateSystems.length; i++) {
			if(vm.coordinateSystems[i].id === id) {
				return vm.coordinateSystems[i].description || "No description available";
			}
		}
		return "No description available";
	}

	function openMarkup(markup) {
		const markupId = markup.id;
		$state.go("project.model", { markupId: markupId });
	}

	function removeMarkup(markup) {
		markupsRepository.remove(project.id, markup.id)
			.then((/*data*/) => {
				const index = _.findIndex(vm.searchResult.markups, { id: markup.id });
				if(index >= 0) {
					vm.searchResult.markups.splice(index, 1);
					vm.searchResult.numberOfMarkupsFound--;
				}
				vm.searchResult.markupChunks = _.chunk(vm.searchResult.markups, vm.chunkSize);
				if(vm.searchResult.currentMarkupChunk >= vm.searchResult.markupChunks.length){
					vm.searchResult.currentMarkupChunk = vm.searchResult.markupChunks.length - 1;
				}
			}, (/*error*/) => {
				notification.error("Error deleting a markup");
			});
	}

	function currentMarkupRangeUpperLimit(){
		return Math.min(
			vm.searchResult.currentMarkupChunk * vm.chunkSize + vm.chunkSize,
			vm.searchResult.numberOfMarkupsFound
		);
	}

	function setMarkupChunk(markupChunkIndex){
		vm.searchResult.currentMarkupChunk = markupChunkIndex;
	}

	function isStatusChecked(term, option) {
		const text = (term.text || "").toUpperCase();
		const id = (option.id || Utilities.emptyGuid()).toUpperCase();
		return text.indexOf(id) >= 0;
	}

	function toggleStatusChecked(term, option) {
		let text = (term.text || "").trim().toUpperCase();
		const id = (option.id || Utilities.emptyGuid()).trim().toUpperCase();
		const ids = text ? text.split(",") : [];
		const pos = ids.indexOf(id);
		if(pos >= 0) {
			ids.splice(pos, 1);
		} else {
			ids.push(id);
		}
		text = ids.join(",");
		term.text = text;
	}

	let activeSearchRequest = updateActiveSearchRequest();

	function updateActiveSearchRequest() {
		let request = vm.simpleSearchRequest;
		if(vm.targetObjects) {
			request = vm.modelSearchRequest;
		}
		if(vm.targetPoints) {
			request = _.cloneDeep(vm.pointSearchRequest);
			if(request.terms) {
				for(let i = 0; i < request.terms.length; ++i) {
					if(isAttributeCoordinate(request.terms[i].attributeTag)
						&& request.terms[i].targetCoordinateId) {
						request.terms[i].attributeTag = request.terms[i].attributeTag.substring(
							0, request.terms[i].attributeTag.length - 2
						) + ":" + request.terms[i].targetCoordinateId + "))";
					}
				}
			}
		}
		request.shouldGetGeometryIds = false;
		return request;
	}

	searchService.search(activeSearchRequest, attributeDefinitions)
		.then(
			undefined,
			reason => {
				notification.error("Search failed: " + Utilities.getErrorMessage(reason));
			}
		);

	function modelTermTagSelected(term) {
		if(!term || !term.attributeTag) {
			return;
		}
		addModelSearchTerm(term);
		vm.modelTermPlaceholder.attributeTag = "";
	}

	function addModelSearchTerm(term) {
		const tag = term
			? term.attributeTag
			: (vm.modelSearchRequest.terms.length === 0 ? "((id))" : "((any))");
		let date = undefined;
		if(tagToDataType(tag) === "unixDate") {
			date = new Date();
			date.setHours(0, 0, 0, 0);
		}
		const newTerm = {
			attributeTag: tag,
			operation: getOperations(tag)[0],
			text: "",
			date: date,
			previousTag: tag,
			isMultiline: isMultiline(tag),
		};
		vm.modelSearchRequest.terms.push(newTerm);
	}

	function pointTermTagSelected(term) {
		if(!term || !term.attributeTag) {
			return;
		}
		addPointSearchTerm(term);
		vm.pointTermPlaceholder.attributeTag = "";
	}

	function addPointSearchTerm(term) {
		const tag = term ? term.attributeTag : "((name))";
		let date = undefined;
		let targetCoordinateId = undefined;
		if(tagToDataType(tag) === "unixDate") {
			date = new Date();
			date.setHours(0, 0, 0, 0);
		}
		if(isAttributeCoordinate(tag)) {
			targetCoordinateId = vm.coordinateSystems
				? vm.coordinateSystems[0] ? vm.coordinateSystems[0].id : undefined
				: undefined;
		}
		vm.pointSearchRequest.terms.push({
			attributeTag: tag,
			operation: getOperations(tag)[0],
			text: getInitialTermText(tag),
			date: date,
			previousTag: tag,
			isMultiline: isMultiline(tag),
			targetCoordinateId: targetCoordinateId,
		});
	}

	function attributeTagChanged(term) {
		if(term.previousTag !== term.attributeTag) {
			if(isEnum(term.attributeTag)) {
				term.date = undefined;
				term.text = getInitialTermText(term.attributeTag);
			} else if(tagToDataType(term.attributeTag) === "unixDate") {
				term.date = new Date();
				term.date.setHours(0, 0, 0, 0);
				term.text = l10n.dateToLocalText(term.date);
			} else if(tagToDataType(term.previousTag) === "unixDate") {
				term.date = undefined;
				term.text = "";
			} else if(term.attributeTag === "((pointKind))"
					|| isStatusTracking(term.attributeTag)
					|| isEnum(term.previousTag)
					|| term.previousTag === "((pointKind))"
					|| isStatusTracking(term.previousTag)) {
				term.date = undefined;
				term.text = "";
			}
		}
		if(!getOperations(term.attributeTag).contains(term.operation)) {
			term.operation = getOperations(term.attributeTag)[0];
		}
		term.previousTag = term.attributeTag;
		term.isMultiline = isMultiline(term.attributeTag);
	}

	function getInitialTermText(tag) {
		if(isEnum(tag)) {
			const options = getEnumOptions(tag);
			if(Utilities.isArray(options) && options.length > 0) {
				return options[0].id;
			}
		}
		return "";
	}

	function clearTerms() {
		$state.go(".", {
			projectId: project.id,
			isAdvanced: false,
			scope: "IdOnly",
		}, { inherit: false, reload: true });
	}

	function isValidNumber(value) {
		const numberRegex = /^[+-]?\d+[.,]?\d*([eE][+-]?\d+)?$/;
		return angular.isString(value) && numberRegex.test(value.trim());
	}

	function isModelSearchValid() {
		if(vm.modelSearchRequest.terms.length > 0) {
			for(let i = 0; i < vm.modelSearchRequest.terms.length; ++i) {
				const term = vm.modelSearchRequest.terms[i];
				switch(tagToDataType(term.attributeTag)) {
				case "unixDate":{
					const text = term.date
						? l10n.dateToLocalText(term.date)
						: "invalid";
					if(term.operation !== "exists" && !l10n.isValidDateString(text)) {
						return false;
					}
					term.text = text;
					break;
				}
				case "numeric":{
					if(term.operation !== "exists" && !isValidNumber(term.text)) {
						return false;
					}
					break;
				}
				case "status.enum":{
					if(term.operation === "statusIs") {
						if(!Utilities.isString(term.text) || term.text === "") {
							return false;
						}
					}
					break;
				}
				}
			}
			return true;
		}
		return false;
	}

	function isPointSearchValid() {
		if(vm.pointSearchRequest.terms.length > 0) {
			for(let i = 0; i < vm.pointSearchRequest.terms.length; ++i) {
				const term = vm.pointSearchRequest.terms[i];
				switch(tagToDataType(term.attributeTag)) {
				case "unixDate":{
					const text = term.date ? l10n.dateToLocalText(term.date) : "invalid";
					if((term.operation !== "exists" && term.operation !== "existed")
						&& !l10n.isValidDateString(text)) {
						return false;
					}
					term.text = text;
					break;
				}
				case "numeric":{
					if((term.operation !== "exists" && term.operation !== "existed")
						&& !isValidNumber(term.text)) {
						return false;
					}
					break;
				}
				}
			}
			return true;
		}
		return false;
	}

	function operationToDisplayName(operation) {
		switch(operation) {
		case "eq":
			return "equals";
		case "exists":
			return "exists";
		case "gt":
			return "&gt;";
		case "ge":
			return "&ge;";
		case "lt":
			return "&lt;";
		case "le":
			return "&le;";
		case "contains":
			return "contains";
		case "statusIs":
			return "is";
		case "statusIsSet":
			return "is set";
		case "statusIsNotSet":
			return "is not set";
		case "wasEq":
			return "was equal";
		case "existed":
			return "existed";
		case "contained":
			return "contained";
		case "wasGt":
			return "was &gt;";
		case "wasGe":
			return "was &ge;";
		case "wasLt":
			return "was &lt;";
		case "wasLe":
			return "was &le;";
		case "notEq":
			return "not equal";
		default:
			return "?";
		}
	}

	function removeModelSearchTerm(index) {
		vm.modelSearchRequest.terms.splice(index, 1);
	}

	function removePointSearchTerm(index) {
		vm.pointSearchRequest.terms.splice(index, 1);
	}

	function scopeToAttributeTag(searchScope) {
		switch(searchScope) {
		case "IdOnly":
			return "((id))";
		case "AllAttributes":
			return "((any))";
		default:
			return "((any))";
		}
	}

	function scopeToDisplay(searchScope) {
		switch(searchScope) {
		case "IdOnly":
			return "IDs only";
		case "AllAttributes":
			return "All attributes";
		default:
			return "?";
		}
	}

	function search(searchRequest) {
		const request = _.cloneDeep(searchRequest);
		if(request.shouldGetGeometryIds === undefined) {
			request.shouldGetGeometryIds = false;
		}
		const terms = request.isAdvanced ? request.terms : undefined;
		if(terms) {
			for(let i = 0; i < terms.length; ++i) {
				if(request.searchTarget === "Points"
					&& isAttributeCoordinate(terms[i].attributeTag)
					&& request.terms[i].targetCoordinateId) {
					terms[i].attributeTag = terms[i].attributeTag.substring(
						0, terms[i].attributeTag.length - 2
					) + ":" + terms[i].targetCoordinateId + "))";
				}
				if(terms[i].operation === "exists" || terms[i].operation === "existed") {
					terms[i].text = "";
				}
			}
		}
		request.terms = terms;
		let url = $state.href(".", {
			projectId: project.id,
			isAdvanced: request.isAdvanced,
			text: request.isAdvanced ? undefined : request.text,
			scope: request.isAdvanced ? undefined : request.scope,
			searchTarget: request.searchTarget,
			terms: searchService.serializeTerms(request.isAdvanced ? terms : undefined),
		}, {
			reload: true,
			inherit: false,
		});
		url = url.substring(1);
		$location.url(url);
		searchService.search(request, attributeDefinitions, true)
			.then(
				undefined,
				reason => {
					notification.error("Search failed: " + Utilities.getErrorMessage(reason));
				}
			);
	}

	function exportBcf(searchRequest) {
		exportToFile(searchRequest, searchService.exportBcf);
	}

	function exportExcel(searchRequest) {
		exportToFile(searchRequest, searchService.exportExcel);
	}

	function openExcelExportDialog(searchRequest) {
		const mainModalInstance = $uibModal.open({
			backdrop: true,
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\dialogs\\ExportExcelModal.html'),
			controller: "ExportExcelModalCtrl",
			controllerAs: "vm",
			resolve: {
				projectId: function () {
					return project.id;
				},
				notification: notification,
				attributesForExport: getExcelExportAttributes,
				activeSearchTab: vm.activeTab,
				savedSearchExportConfigurationsRepository: savedSearchExportConfigurationsRepository,
				getExportConfiguration: function () {
					return searchService.getExcelExportConfiguration;
				},
				setExportConfiguration: function () {
					return searchService.setExcelExportConfiguration;
				},
			},
		});
		mainModalInstance.result.then(result => {
			switch(result.action) {
			case "export":{
				searchRequest.excelExportConfiguration = result.exportConfiguration;
				exportExcel(searchRequest);
				break;
			}
			case "save":{
				const configToSave = _.cloneDeep(result.currentConfiguration);
				saveExportConfiguration(configToSave);
				break;
			}
			case "delete":{
				const configToDelete = _.cloneDeep(result.configurationToDelete);
				deleteExportConfiguration(configToDelete);
				break;
			}
			}
		});

		function saveExportConfiguration(configuration) {
			const saveModalInstance = $uibModal.open({
				backdrop: true,
				template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\dialogs\\SavedSearchExportNameModalContent.html'),
			});
			saveModalInstance.result.then(name => {
				const configurationDto = {
					name: name,
					selections: angular.toJson(configuration),
				};
				savedSearchExportConfigurationsRepository
					.add(project.id, configurationDto)
					.then(continueExporting, onError);
			}, reason => {
				switch(reason) {
				case "cancel":
					continueExporting();
					break;
				case "backdrop click":
				case "escape key press":
					break;
				default:
					onError(reason);
				}
			});

			function continueExporting() {
				openExcelExportDialog(searchRequest);
			}

			function onError(reason) {
				notification.error("Error saving selections: " + reason);
			}
		}

		function deleteExportConfiguration(configurationToDelete) {
			const msgBox = messageBox.openQuestion(
				"Delete saved export?",
				"Do you really want to delete the saved export titled \""
				+ configurationToDelete.name + "\"? This cannot be undone.",
				"Delete", "Cancel"
			);
			msgBox.result.then(() => {
				savedSearchExportConfigurationsRepository
					.remove(project.id, configurationToDelete.id)
					.then(continueExporting, onError);
			}, reason => {
				switch(reason) {
				case "cancel":
					continueExporting();
					break;
				case "backdrop click":
				case "escape key press":
					break;
				default:
					onError(reason);
				}
			});

			function continueExporting() {
				openExcelExportDialog(searchRequest);
			}

			function onError(reason) {
				notification("Error deleting saved selections: " + reason);
			}
		}

		function getExcelExportAttributes() {
			return {
				markups: getMarkupAttributes(),
				points: getSmartPointAttributes(),
				objects: getObjectAttributes(),
				dataSources: _.cloneDeep(excelExportDataSources),
			};

			function getMarkupAttributes() {
				if(vm.activeTab !== 0 && vm.activeTab !== 2) {
					return [];
				}
				const attributes = [
					{ name: "((status))", displayName: "Status" },
					{ name: "((importance))", displayName: "Importance" },
					{ name: "((type))", displayName: "Type" },
					{ name: "((title))", displayName: "Title" },
					{ name: "((latestComment))", displayName: "Latest Comment" },
					{ name: "((createdBy))", displayName: "Created by" },
					{ name: "((createdAt))", displayName: "Created at" },
					{ name: "((modifiedBy))", displayName: "Modified by" },
					{ name: "((modifiedAt))", displayName: "Modified at" },
					{ name: "((assignedTo))", displayName: "Assigned to" },
					{ name: "((objectAttribute))", displayName: "Object Attribute" },
					{ name: "((objectId))", displayName: "Object ID" },
					{ name: "((objectGuid))", displayName: "Object GUID" }
				];
				_.forEach(attributes, attribute => {
					attribute.id = -1;
					attribute.selected = true;
				});
				const additionalAttributes = getAdditionalAttributes("-2");
				for(let i = 0; i < additionalAttributes.length; i++) {
					attributes.push({
						id: additionalAttributes[i].id,
						name: additionalAttributes[i].name,
						displayName: additionalAttributes[i].displayName,
						selected: true,
					});
				}
				return attributes;
			}

			function getSmartPointAttributes() {
				if(vm.activeTab !== 0 && vm.activeTab !== 2) {
					return [];
				}
				const attributes = [
					{ name: "((pointId))", displayName: "Point ID" },
					{ name: "((pointType))", displayName: "Point Type" },
					{ name: "((objectAttribute))", displayName: "Object Attribute" },
					{ name: "((objectId))", displayName: "Object ID" },
					{ name: "((objectGuid))", displayName: "Object GUID" },
					{ name: "((externalId))", displayName: "External ID" },
					{ name: "((createdBy))", displayName: "Created by" },
					{ name: "((createdAt))", displayName: "Created at" },
					{ name: "((modifiedBy))", displayName: "Modified by" },
					{ name: "((modifiedAt))", displayName: "Modified at" },
					{ name: "((x))", displayName: "X" },
					{ name: "((y))", displayName: "Y" },
					{ name: "((z))", displayName: "Z" }
				];
				_.forEach(attributes, attribute => {
					attribute.id = -1;
					attribute.selected = true;
				});
				const additionalAttributes = getAdditionalAttributes("-1");
				for(let i = 0; i < additionalAttributes.length; i++) {
					attributes.push({
						id: additionalAttributes[i].id,
						name: additionalAttributes[i].name,
						displayName: additionalAttributes[i].displayName,
						selected: true,
					});
				}
				return attributes;
			}

			function getObjectAttributes() {
				if(vm.activeTab !== 0 && vm.activeTab !== 1) {
					return [];
				}
				const attributes = [{
					id: -1,
					name: "((id))",
					displayName: "ID",
					selected: true,
					comparisonIndexes: [],
				}];
				_.forEach(attributeDefinitionsUsedByObjects, attributeDefinition => {
					attributes.push({
						id: attributeDefinition.id,
						name: attributeDefinition.abbreviation,
						displayName: attributeDefinition.displayName,
						selected: true,
						comparisonIndexes: [],
					});
				});
				_.forEach(statusTrackingDefinitions, statusTracking => {
					attributes.push({
						id: -1,
						name: statusTracking.id,
						displayName: statusTracking.name,
						selected: true,
						comparisonIndexes: [],
					});
				});
				return attributes;
			}

			function getAdditionalAttributes(poiKindId) {
				return poiAttributeKinds.filter(attributeKind => {
					return attributeKind.pointOfInterestKindId === poiKindId;
				}).map(attributeKind => {
					return attributeKind.attributeKinds;
				})[0].filter(attribute => {
					return attribute.id > 5;
				});
			}
		}
	}

	function exportWord(searchRequest, templatePath) {
		searchRequest.wordExportTemplatePath = templatePath;
		exportToFile(searchRequest, searchService.exportWord);
	}

	function export3dd(searchRequest) {
		exportToFile(searchRequest, searchService.export3dd);
	}

	function exportToFile(searchRequest, method) {
		const terms = searchRequest.isAdvanced ? searchRequest.terms : undefined;
		if(terms) {
			for(let i = 0; i < terms.length; ++i) {
				if(terms[i].operation === "exists" || terms[i].operation === "existed") {
					terms[i].text = "";
				}
			}
		}
		method(searchRequest, attributeDefinitions);
	}

	function exportMarkup(searchRequest) {
		const terms = searchRequest.isAdvanced ? searchRequest.terms : undefined;
		if(terms) {
			for(let i = 0; i < terms.length; ++i) {
				if(terms[i].operation === "exists" || terms[i].operation === "existed") {
					terms[i].text = "";
				}
			}
		}
		searchService.exportMarkup(searchRequest, attributeDefinitions);
	}

	function tagToDataType(tag) {
		activeSearchRequest = updateActiveSearchRequest();
		if(activeSearchRequest.searchTarget === "Points"
			&& (tag === "((creationDate))" || tag === "((modificationDate))")) {
			return "unixDate";
		}
		if(activeSearchRequest.searchTarget === "Objects") {
			const attributeDefinition = _.find(attributeDefinitions, { abbreviation: tag });
			return attributeDefinition ? attributeDefinition.dataType : "string";
		}
		return (isEnum(tag) || tag === "((pointKind))") ? "enumeration" : "string";
	}

	function tagToDisplayName(tag) {
		const attributeDefinition = _.find(attributeDefinitions, { abbreviation: tag });
		return attributeDefinition ? attributeDefinition.displayName : "?";
	}

	function getEnumOptions(tag) {
		return enumOptions[tag];
	}

	function getStatusEnumOptions(tag) {
		const attributeDefinition = _.find(attributeDefinitions, { abbreviation: tag });
		if(attributeDefinition) {
			return attributeDefinition.enumValues;
		}
		return [];
	}

	function getEnumText(tag, text) {
		const value = _.find(enumOptions[tag], { id: text });
		return value ? value.name : "";
	}

	function getModelAttributeDefinitions() {
		return attributeDefinitions;
	}

	function getPointAttributeDefinitions() {
		if(hasPointTypeAsFirstTerm()) {
			return _.find(poiAttributeKinds, kinds => {
				return kinds.pointOfInterestKindId === vm.pointSearchRequest.terms[0].text;
			}).attributeKinds;
		}
		return _.find(poiAttributeKinds, kinds => {
			return kinds.pointOfInterestKindId === "-1";
		}).attributeKinds;
	}

	function getOperations(tag) {
		const isWithHistory = hasHistory(tag);
		if(tag === "((any))" || tag === "((pointKind))" || tag === "((createdBy))") {
			return anyOperations;
		}
		if(tag === "((modifiedBy))" || tag === "((assigneeName))") {
			if(isWithHistory) {
				return anyOperations.concat(anyHistoryOperations);
			}
			return anyOperations;
		}
		if(tag === "((externalId))") {
			return objectOperations;
		}
		if(isEnum(tag)) {
			if(isWithHistory) {
				return removeExistTermFromOperations(tag, enumOperations.concat(enumHistoryOperations));
			}
			return removeExistTermFromOperations(tag, enumOperations);
		}
		if(isMultiline(tag)) {
			if(isWithHistory) {
				return multilineOperations.concat(multilineHistoryOperations);
			}
			return multilineOperations;
		}
		if(isStatusTracking(tag)) {
			return statusTrackingOperations;
		}
		activeSearchRequest = updateActiveSearchRequest();
		if(activeSearchRequest.searchTarget === "Points") {
			if(isNaN(tag)) {
				return isWithHistory
					&& tag !== "((cameraX))"
					&& tag !== "((cameraY))"
					&& tag !== "((cameraZ))"
					? pointOperations.concat(pointHistoryOperations)
					: pointOperations;
			}
			const operations = isWithHistory
				? pointStringOperations.concat(objectHistoryOperations)
				: pointStringOperations;
			return removeExistTermFromOperations(tag, operations);
		}
		return objectOperations;
	}

	function removeExistTermFromOperations(tag, operations) {
		if(isNaN(tag)) {
			return operations;
		}
		const definitions = getPointAttributeDefinitions();
		const tagInt = parseInt(tag);
		if(_.some(definitions, d => {
			return d.id === tagInt
				&& (d.displayName === "Markup Status"
					|| d.displayName === "Markup Comment"
					|| d.displayName === "Markup Importance");
		})) {
			let index = operations.indexOf("exists");
			if(index > -1) {
				operations.splice(index, 1);
			}
			index = operations.indexOf("existed");
			if(index > -1) {
				operations.splice(index, 1);
			}
		}
		return operations;
	}

	function hasHistory(tag) {
		if(tag === "((x))"
			|| tag === "((y))"
			|| tag === "((z))"
			|| tag === "((creationDate))"
			|| !hasPointTypeAsFirstTerm()) {
			return false;
		}
		const kindId = vm.pointSearchRequest.terms[0].text;
		return _.some(poiAttributeKinds, kind => {
			return kind.hasHistory && kind.pointOfInterestKindId === kindId;
		});
	}

	function hasPointTypeAsFirstTerm() {
		return activeSearchRequest.searchTarget === "Points"
				&& vm.pointSearchRequest.terms
				&& vm.pointSearchRequest.terms.length > 0
				&& vm.pointSearchRequest.terms[0].attributeTag === "((pointKind))"
				&& vm.pointSearchRequest.terms[0].operation === "eq";
	}

	function isStatusTracking(tag) {
		return Utilities.isString(tag) && statusTrackingTagRegex.test(tag);
	}

	function isMultiline(tag) {
		return multilinePois.contains(tag);
	}

	function isEnum(tag) {
		return enumTags.contains(tag);
	}

	function saveSearch(savedSearch) {
		const savedSearchBackup = _.cloneDeep(savedSearch);
		activeSearchRequest = updateActiveSearchRequest();
		const clonedGroups = _.cloneDeep(vm.groups);
		if(savedSearch) {
			_.forEach(clonedGroups, group => {
				group.hasPermission = _.some(savedSearch.visibleTo, v => {
					return v === group.id;
				});
			});
		}
		const modalInstance = $uibModal.open({
			backdrop: true,
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\dialogs\\SearchNameModalContent.html'),
			controllerAs: "scope",
			controller: function () {
				this.isAdmin = vm.isAdmin;
				this.groups = clonedGroups;
				this.value = savedSearch ? savedSearch.name : "";
			},
		});
		modalInstance.result.then(result => {
			const includedGroups = [];
			if(result.groups) {
				for(let i = 0; i < result.groups.length; i++) {
					const group = result.groups[i];
					if(group.hasPermission) {
						includedGroups.push(group.id);
					}
				}
			}
			let searchToSave;
			if(savedSearch) {
				searchToSave = savedSearch;
				searchToSave.terms = searchService.serializeTerms(searchToSave.terms);
				searchToSave.name = result.name;
				searchToSave.visibleTo = includedGroups;
			} else {
				searchToSave = {
					name: result.name,
					terms: searchService.serializeTerms(activeSearchRequest.terms),
					searchTarget: activeSearchRequest.searchTarget,
					visibleTo: includedGroups,
				};
			}
			savedSearchRepository.add(project.id, searchToSave)
				.then(data => {
					const savedSearch = data;
					$timeout(() => {
						$state.go(".", {
							projectId: project.id,
							isAdvanced: !vm.simpleSearch,
							searchTarget: vm.targetObjects ? "Objects" : "Points",
							terms: searchService.serializeTerms(activeSearchRequest.terms),
						}, { inherit: false, reload: false });
						if(_.every(vm.savedSearches, s => {
							return s.id !== savedSearch.id;
						})) {
							vm.savedSearches.push({
								id: savedSearch.id,
								name: savedSearch.name,
								terms: activeSearchRequest.terms,
								searchTarget: activeSearchRequest.searchTarget,
								visibleTo: savedSearch.visibleTo,
								isMadeByUser: savedSearch.isMadeByUser,
								userName: savedSearch.userName,
								userId: savedSearch.userId,
							});
						}
					}, 0);
				}, error => {
					notification.error("Error saving search: " + error);
					// Rollback
					if(savedSearchBackup) {
						for(let k = 0; k < vm.savedSearches.length; k++) {
							if(vm.savedSearches[k].id === savedSearchBackup.id) {
								vm.savedSearches[k] = savedSearchBackup;
								break;
							}
						}
					}
				});
		});
	}

	function deleteSavedSearch(searchRequest) {
		let sharedSearchWarning = "";
		if(searchRequest.visibleTo && searchRequest.visibleTo.length > 0) {
			sharedSearchWarning =
				" All users this saved search has been shared with will lose access to it.";
		}
		let sharedOtherUserSearchWarning = "";
		if(!searchRequest.isMadeByUser) {
			sharedOtherUserSearchWarning = " This search was made by " + searchRequest.userName + ".";
		}
		const mbox = messageBox.openQuestion(
			"Delete saved search?",
			"Do you really want to delete the saved search titled \""
			+ searchRequest.name + "\"?" + sharedSearchWarning + sharedOtherUserSearchWarning
			+ " This cannot be undone.",
			"Delete", "Cancel"
		);
		mbox.result.then(
			() => {
				savedSearchRepository.remove(project.id, searchRequest.id)
					.then(() => {
						_.remove(vm.savedSearches, searchRequest);
					}, error => {
						notification.error("Error deleting search: " + error);
					});
			}
		);
	}
}
