angular.module("eShareApp").factory("licenseRepository", licenseRepository);

licenseRepository.$inject = ["repositoryBase"];

function licenseRepository(repositoryBase) {
	const thirdParty = "api/misc/getThirdPartyLicenseList";
	const cadmatic = "api/misc/getCadmaticLicense";
	return {
		getThirdPartyLicenseList: getThirdPartyLicenseList,
		getCadmaticLicense: getCadmaticLicense,
	};

	function getThirdPartyLicenseList() {
		return repositoryBase.get(thirdParty);
	}

	function getCadmaticLicense() {
		return repositoryBase.get(cadmatic);
	}
}