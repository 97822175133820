angular.module("eShare.core").factory("$exceptionHandler", exceptionHandler);

exceptionHandler.$inject = ["notification"];

function exceptionHandler(notification) {
	return notifyingExceptionHandler;

	function notifyingExceptionHandler(exception /*, cause*/) {
		notification.exception(exception);
	}
}
