angular.module("eShareApp").filter("truncate", () => {

	return function (input, length, terminator) {
		if(isNaN(length)) {
			return input;
		}

		if(Utilities.isNullOrUndefined(input)) {
			return "";
		}

		if(Utilities.isNullOrUndefined(terminator)) {
			terminator = "...";
		}

		if(input.length <= length || input.length - terminator.length <= length) {
			return input;
		} else {
			return String(input).substring(0, length - terminator.length) + terminator;
		}
	};
});
