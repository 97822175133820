angular.module("eShareApp").directive("cadDragAndDrop", cadDragAndDrop);

cadDragAndDrop.$inject = [];

function cadDragAndDrop() {
	return {
		restrict: "E",
		scope: {},
		bindToController: {
			items: "=",
			selectedItems: "=?",
			limit: "<",
			noSelect: "@",
			onChange: "&?",
		},
		controller: CadDragAndDropController,
		controllerAs: "vm",
		replace: false,
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\CadDragAndDrop.html'),
		transclude: true,
	};
}

CadDragAndDropController.$inject = ["$scope", "$document"];

function CadDragAndDropController($scope, $document) {
	const vm = this;
	vm.selectedItems = typeof vm.selectedItems === "undefined" ? [] : vm.selectedItems;
	vm.maxLimit = typeof vm.limit === "number" ? vm.limit : 999;
	vm.isSelectDisabled = typeof vm.noSelect === "string";
	deselectAll();

	const clickOutside = function (e) {
		if(e.target.nodeName && e.target.nodeName.toLowerCase() === "button") {
			return;
		}
		const el = document.getElementById("displayedColumnsList" + vm.scopeId);
		if(el && el !== e.target && !el.contains(e.target)) {
			$scope.$apply(deselectAll);
		}
	};

	$document.on("click", clickOutside);

	$scope.$on("$destroy", () => {
		$document.off("click", clickOutside);
	});

	function move(offset) {
		const tempArr = [];
		if(!vm.selectedItems[0]) {
			return;
		}
		vm.selectedItems.sort(selectSort);
		const firstIndex = vm.items.indexOf(vm.selectedItems[0]);
		let firstPosition = firstIndex + offset;
		vm.selectedItems.forEach(row => {
			tempArr.push(row);
			const position = vm.items.indexOf(row);
			vm.items.splice(position, 1);
		});
		firstPosition = Math.min(Math.max(firstPosition, 0), vm.items.length);
		tempArr.forEach((item, index) => {
			vm.items.splice(firstPosition + index, 0, item);
		});
		if(typeof vm.onChange === "function") {
			vm.onChange();
		}
	}

	function toggleSelectItem($event, index) {
		if(!$event.ctrlKey && !$event.shiftKey) {
			vm.selectedItems.splice(0, vm.selectedItems.length);
			vm.selectedItems.push(vm.items[index]);
			if(!vm.isSelectDisabled) {
				vm.items.forEach(item => {
					item.isSelected = item === vm.items[index];
				});
			}
			return;
		}
		if(!vm.isSelectDisabled && $event.shiftKey) {
			vm.selectedItems.sort(selectSort);
			let startIndex = vm.items.indexOf(vm.selectedItems[0]);
			const endIndex = index;
			if(startIndex === -1) {
				startIndex = endIndex;
			}
			vm.selectedItems.splice(0, vm.selectedItems.length);
			vm.items.forEach(def => {
				def.isSelected = false;
			});
			if(startIndex < endIndex) {
				for(let i = startIndex; i <= endIndex; i++) {
					vm.selectedItems.push(vm.items[i]);
					vm.items[i].isSelected = true;
				}
			} else {
				for(let j = startIndex; j >= endIndex; j--) {
					vm.selectedItems.push(vm.items[j]);
					vm.items[j].isSelected = true;
				}
			}
			return;
		}
		if(!vm.isSelectDisabled && $event.ctrlKey) {
			const position = vm.selectedItems.indexOf(vm.items[index]);
			if(position === -1) {
				vm.selectedItems.push(vm.items[index]);
				vm.items[index].isSelected = true;
			} else {
				vm.selectedItems.splice(position, 1);
				vm.items[index].isSelected = false;
			}
			return;
		}
	}

	function selectSort(a, b) {
		const aPos = vm.items.indexOf(a);
		const bPos = vm.items.indexOf(b);
		if(aPos > bPos) {
			return 1;
		}
		if(bPos > aPos) {
			return -1;
		}
		return 0;
	}

	function onOrderChanged(event) {
		if(vm.selectedItems.length > 1) {
			let movedRow = null;
			vm.selectedItems.forEach(row => {
				if(!movedRow && vm.items[event.dest.index] === row) {
					vm.items.splice(event.dest.index, 1);
					vm.items.splice(event.source.index, 0, row);
					movedRow = row;
				}
			});
			if(!movedRow) {
				vm.selectedItems.forEach(row => {
					row.isSelected = false;
				});
				vm.selectedItems.splice(0, vm.selectedItems.length);
				return;
			}
			const destItem = vm.items[event.dest.index];
			vm.selectedItems.sort(selectSort);

			const arrayWithoutOthers = vm.items.filter(def => {
				const isMovingItem = (def === vm.selectedItems[0] || def === destItem);
				return (!def.isSelected || isMovingItem);
			});
			const destIndex = arrayWithoutOthers.indexOf(destItem);
			const srcIndex = arrayWithoutOthers.indexOf(vm.selectedItems[0]);
			let distance = destIndex - srcIndex;

			// Special cases...
			if(event.dest.index - event.source.index < 0 && distance > 0) {
				distance--;
			}

			if(event.dest.index - event.source.index > 0
				&& distance > 0
				&& _.find(vm.selectedItems, item => {
					return item === destItem;
				})) {
				distance--;
			}
			vm.move(distance);
		} else if(!vm.items[event.dest.index].isSelected) {
			vm.selectedItems.forEach(row => {
				row.isSelected = false;
			});
			vm.selectedItems.splice(0, vm.selectedItems.length);
		}
		if(typeof vm.onChange === "function") {
			vm.onChange();
		}
	}

	function setScope(id) {
		vm.scopeId = id;
		vm.sortableOptions = {
			accept: function (sourceItemHandleScope, destSortableScope) {
				return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
			},
			containment: "#displayedColumnsList" + id,
			containerPositioning: "relative",
			orderChanged: onOrderChanged,
		};
	}

	function deselectAll() {
		if(!vm.items) {
			return;
		}
		vm.items.forEach(row => {
			row.isSelected = false;
		});
		vm.selectedItems.splice(0, vm.selectedItems.length);
	}

	$scope.attachItem = function (scope) {
		scope.getItem = function () {
			return scope.$parent.col;
		};
		scope.getIndex = function () {
			return scope.$parent.$index;
		};
	};

	vm.toggleSelectItem = toggleSelectItem;
	vm.move = move;
	vm.setScope = setScope;
}