angular.module("eShareApp").directive("cadExistenceAttributeTransformation", [
	"notification", "attributeTransformationsRepository",
	function (notification, attributeTransformationsRepository) {
		return {
			restrict: "A",
			replace: true,
			scope:
			{
				definitions: "=",
				transformation: "=",
				projectId: "=",
				modelId: "=",
				allTransformations: "=",
				onChange: "&?",
			},
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadExistenceAttributeTransformation.html'),
			controller: [
				"$scope", "$rootScope",
				function ($scope, $rootScope) {
					$scope.testResults = {
						sourceDefinitions: [],
						targetDefinitions: [],
						matchingResults: [],
					};
					$scope.expanded = false;
					$scope.testButtonDisabled = false;
					$scope.isValid = function () {
						if(!$scope.transformation?.displayName
							|| !$scope.transformation.abbreviation
							|| !$scope.transformation.attributeType
							|| !$scope.transformation.attributes?.length > 0) {
							return false;
						}
						return true;
					};

					$scope.abbreviationChanged = () => {
						$scope.transformation.displayName = $scope.transformation.abbreviation || "";
						$scope.onChange();
					};

					$scope.displayNameChanged = () => {
						$scope.onChange();
					};

					$scope.typeChanged = () => {
						$scope.onChange();
					};

					$scope.attributesChanged = () => {
						$scope.onChange();
					};

					$scope.toggleExpand = () => {
						$scope.expanded = !$scope.expanded;
					};

					$scope.except = abbreviation => {
						return function (item) {
							return abbreviation !== item.abbreviation;
						};
					};

					$scope.notInUse = () => {
						return function (item) {
							return !_.some($scope.transformation.attributes, {
								abbreviation: item.abbreviation,
							});
						};
					};

					function validate() {
						if(!$scope.isValid) {
							notification.error("All fields must be filled");
							return false;
						}
						return true;
					}

					function formatTestResults() {
						for(let j = 0; j < $scope.testResults.matchingResults.length; j++) {
							const match = $scope.testResults.matchingResults[j];
							match.inputMap = {};
							match.outputMap = {};
							for(let i = 0; i < match.inputValues.length; i++) {
								match.inputMap[match.inputValues[i].abbreviation] =
									match.inputValues[i].value;
							}
							for(let i = 0; i < match.outputValues.length; i++) {
								match.outputMap[match.outputValues[i].abbreviation] =
									match.outputValues[i].value;
							}
						}
					}

					$scope.tryRule = function () {
						if(!validate()) {
							return;
						}
						_.forEach($scope.transformation.attributes, attribute => {
							attribute.displayName = _.find($scope.definitions, {
								abbreviation: attribute.abbreviation,
							}).displayName;
						});
						$scope.testButtonDisabled = true;
						const index = _.indexOf($scope.allTransformations, $scope.transformation);
						attributeTransformationsRepository.test(
							$scope.projectId,
							$scope.modelId,
							$scope.allTransformations,
							index
						).then(data => {
							$scope.testResults = data;
							formatTestResults();
							$scope.expanded = true;
							$scope.testButtonDisabled = false;
						}, error => {
							notification.error("Error testing rule: " + error);
							$scope.testButtonDisabled = false;
						});
					};

					$scope.addAttribute = () => {
						$scope.transformation.attributes.push({
							displayName: "",
							abbreviation: "",
						});
						$scope.onChange();
					};

					$scope.deleteAttribute = attribute => {
						_.remove($scope.transformation.attributes, attribute);
						if($scope.transformation.attributes.length < 1) {
							$scope.addAttribute();
						}
						$scope.onChange();
					};

					$scope.deleteTransformation = () => {
						$rootScope.$broadcast("attributeTransformation:delete", $scope.transformation);
						$scope.onChange();
					};
				}
			],
		};
	}
]);
