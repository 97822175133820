angular.module("eShareApp").directive("cadColorPicker", cadColorPicker);

cadColorPicker.$inject = [];

function cadColorPicker() {
	return {
		restrict: "E",
		controller: cadCategorizationEditorController,
		controllerAs: "vm",
		bindToController: {
			color: "=",
			ngDisabled: "<",
		},
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadColorPicker.directive.html'),
		replace: true,
	};
}

cadCategorizationEditorController.$inject = ["colorService", "$state", "$scope", "$timeout"];

function cadCategorizationEditorController(colorService, $state, $scope, $timeout) {
	const vm = this;
	vm.isColorTiedToPicker = true;

	function initialize() {
		vm.isDisabled = !!vm.ngDisabled;
		colorService.getProjectColors($state.params.projectId).then(colors => {
			vm.colors = colors;
			vm.updateColorName();
		});
	}

	vm.openPane = function () {
		vm.isPaneOpen = true;
	};

	vm.closePane = function () {
		vm.isPaneOpen = false;
	};

	vm.selectColor = function (color) {
		$timeout(() => {
			vm.color = color.color.toUpperCase();
		});
	};

	vm.updateColorName = function () {
		const found = _.find(vm.colors, { "color": vm.color });
		vm.colorName = "";
		if(found != null) {
			vm.colorName = found.name;
		}
	};

	$scope.$watch("vm.color", () => {
		vm.updateColorName();
	});

	initialize();
}