window.Authenticator = (function (window) {
	"use strict";

	if(!Utilities.isObject(window.eShare)) {
		throw new Error(
			"The eShare application is not properly initialized - window.eShare object is not defined"
		);
	}

	window.eShare.currentUser = {
		id: "",
		username: "",
		fullName: "",
		role: "",
		token: "",
		isSystemAdmin: false,
	};

	let loginSucceededCallback = null;
	let cadAuthorizationChangedCallback = null;

	Utilities.safelyAddEventListener("storage", "onstorage", handleStorageEvent);

	return {
		initialize: initialize,
		setCadAuthorizationChangedCallback: setCadAuthorizationChangedCallback,
		getCadAuthorization: getCadAuthorization,
		setCadAuthorization: setCadAuthorization,
		navigateToNoLicense: navigateToNoLicense,
	};

	function initialize(loginSucceeded) {
		if(!Utilities.isFunction(loginSucceeded)) {
			throw new Error(
				"Invalid authenticator initialization invocation "
				+ "- no callback or invaldid callback defined"
			);
		}
		loginSucceededCallback = loginSucceeded;
		const accessToken = window.eShare.accessToken || window.localStorage.getItem("accessToken");
		if(Utilities.isString(accessToken) && accessToken !== "") {
			processToken(accessToken);
		} else {
			navigateToLogin();
		}
	}

	function setCadAuthorizationChangedCallback(cadAuthorizationChanged) {
		if(!Utilities.isFunction(cadAuthorizationChanged)) {
			throw new Error(
				"Invalid authenticator setCadAuthorizationChangedCallback invocation "
				+ "- no callback or invaldid callback defined"
			);
		}
		cadAuthorizationChangedCallback = cadAuthorizationChanged;
	}

	function getCadAuthorization() {
		let cadAuthorization = readCadAuthorization();
		cadAuthorization = normalizeCadAuthorization(cadAuthorization);
		return cadAuthorization;
	}

	function setCadAuthorization(cadAuthorization) {
		cadAuthorization = normalizeCadAuthorization(cadAuthorization);
		const currentCadAuthorization = getCadAuthorization();
		if(currentCadAuthorization === cadAuthorization) {
			return false;
		}
		storeCadAuthorization(cadAuthorization);
		if(cadAuthorizationChangedCallback) {
			cadAuthorizationChangedCallback();
		}
		return true;
	}

	function normalizeCadAuthorization(cadAuthorization) {
		cadAuthorization = Utilities.isString(cadAuthorization) ? cadAuthorization.trim() : null;
		if(cadAuthorization === "") {
			cadAuthorization = null;
		}
		return cadAuthorization;
	}

	function readCadAuthorization() {
		return window.localStorage.getItem("cadAuthorization");
	}

	function storeCadAuthorization(cadAuthorization) {
		if(cadAuthorization) {
			window.localStorage.setItem("cadAuthorization", cadAuthorization);
		} else {
			window.localStorage.removeItem("cadAuthorization");
		}
	}

	function navigateToLogin() {
		window.localStorage.removeItem("accessToken");
		window.location.href = window.eShare.loginUrl;
	}

	function processToken(accessToken) {
		if(!Utilities.isString(accessToken) || accessToken === "") {
			navigateToLogin();
			return;
		}
		const tokenParts = accessToken.split(".");
		if(tokenParts.length !== 3) {
			navigateToLogin();
			return;
		}
		const jwtHeader = window.Base64.urlDecode(tokenParts[0]);
		if(!Utilities.isString(jwtHeader) || jwtHeader === "") {
			navigateToLogin();
			return;
		}
		const tokenHeader = JSON.parse(jwtHeader);
		if(!Utilities.isObject(tokenHeader)
			|| tokenHeader.alg !== "HS256"
			|| tokenHeader.typ !== "JWT") {
			navigateToLogin();
			return;
		}
		const jwtPayload = window.Base64.urlDecode(tokenParts[1]);
		if(!Utilities.isString(jwtPayload) || jwtPayload === "") {
			navigateToLogin();
			return;
		}
		const token = JSON.parse(jwtPayload);
		if(!Utilities.isObject(token)) {
			navigateToLogin();
			return;
		}
		const username = token.sub;
		if(!Utilities.isString(username) || username === "") {
			navigateToLogin();
			return;
		}
		window.localStorage.setItem("accessToken", accessToken);
		window.eShare.currentUser.username = username;
		window.eShare.currentUser.token = accessToken;
		$.ajax({
			url: window.eShare.rootUrl + "api/WhoAmI",
			type: "POST",
			dataType: "json",
			beforeSend: function (xhr) {
				if(!window.eShare.integratedSecurity) {
					xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
				}
				const cadAuthorization = getCadAuthorization();
				if(cadAuthorization) {
					xhr.setRequestHeader("X-CAD-Authorization", cadAuthorization);
				}
			},
			success: whoAmICompleted,
			error: whoAmIFailed,
		});
	}

	function whoAmICompleted(data, textStatus, request) {
		let cadAuthorization = request.getResponseHeader("X-CAD-Authorization");
		setCadAuthorization(cadAuthorization);
		cadAuthorization = getCadAuthorization();
		if(!cadAuthorization) {
			navigateToNoLicense();
			return;
		}
		const whoAmI = data;
		_.assign(window.eShare.currentUser, {
			id: whoAmI.userId,
			fullName: whoAmI.fullName,
			role: whoAmI.role,
			isSystemAdmin: whoAmI.role === "sysadmin",
		});
		loginSucceededCallback.call(null);
	}

	function whoAmIFailed(error) {
		const statusCode = (error && error.status) || 500;
		switch(statusCode) {
		case 401:
		case 403:
			window.localStorage.removeItem("accessToken");
			setCadAuthorization(null);
			window.location.href = "/AuthenticationError";
			break;
		case 498:
			window.localStorage.removeItem("accessToken");
			setCadAuthorization(null);
			window.location.href = window.eShare.sessionTerminatedUrl;
			break;
		case 499:
			window.localStorage.removeItem("accessToken");
			setCadAuthorization(null);
			navigateToNoLicense();
			break;
		default:
			navigateToLogin();
			break;
		}
	}

	function navigateToNoLicense() {
		const token = window.eShare.currentUser.token;
		const url = !window.eShare.integratedSecurity && token
			? window.eShare.noLicenseUrl + "?token=" + token
			: window.eShare.noLicenseUrl;
		window.setTimeout(() => {
			window.location.href = url;
		}, 100);
	}

	function handleStorageEvent(e) {
		if(e && e.key === "cadAuthorization" && cadAuthorizationChangedCallback) {
			cadAuthorizationChangedCallback();
		}
	}
}(window));