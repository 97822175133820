angular
	.module("eShareApp")
	.factory("backgroundProgressesRepository", backgroundProgressesRepository);

backgroundProgressesRepository.$inject = ["repositoryBase"];

function backgroundProgressesRepository(repositoryBase) {
	const pubPercentage = "api/misc/getPublishingPercentage/";
	const pubMessage = "api/misc/getPublishingMessage/";
	const publishingInformationApi = "api/misc/getPublishingInformation/{projectId}";
	const indPercentage = "api/misc/getIndexingPercentage/";
	const indMessage = "api/misc/getIndexingMessage/";
	const dataSources = "api/misc/getDataSources";
	const dataSourcesForProject = "api/misc/getDataSources/{projectId}";
	const getEverything = "api/misc/getBackgroundAll";
	const getForProjectApi = "api/misc/getBackground/{projectId}";
	const targetBackgroundMessagesApi = "api/misc/getBackgroundMessage/{projectId}/{target}";
	const remainingPublishingTimeApi = "api/projects/{projectId}/getRemainingPublishTime";
	const totalPublishingTimeApi = "api/projects/{projectId}/getTotalPublishTime";
	const clearIndexingApi = "api/project/{projectId}/clearIndexing/{datasourceId}";
	const restartIndexingApi = "api/project/{projectId}/restartIndexing/{datasourceId}";
	const restartAsapIndexingApi = "api/project/{projectId}/restartAsapIndexing/{datasourceId}";

	return {
		getPublishingPercentage: getPublishingPercentage,
		getPublishingMessage: getPublishingMessage,
		getIndexingPercentage: getIndexingPercentage,
		getIndexingMessage: getIndexingMessage,
		getDataSources: getDataSources,
		getDataSourcesForProject: getDataSourcesForProject,
		getAll: getAll,
		getForProject: getForProject,
		getRemainingPublishingTime: getRemainingPublishingTime,
		getTotalPublishingTime: getTotalPublishingTime,
		getPublishingInformation: getPublishingInformation,
		getTargetBackgroundMessages: getTargetBackgroundMessages,
		clearIndexing: clearIndexing,
		restartIndexing: restartIndexing,
		restartAsapIndexing: restartAsapIndexing,
	};

	function getPublishingPercentage(projectId) {
		return repositoryBase.get(pubPercentage + projectId);
	}

	function getPublishingMessage(projectId) {
		return repositoryBase.get(pubMessage + projectId);
	}

	function getIndexingPercentage(dataSourceId) {
		return repositoryBase.get(indPercentage + dataSourceId);
	}

	function getIndexingMessage(dataSourceId) {
		return repositoryBase.get(indMessage + dataSourceId);
	}

	function getDataSources() {
		return repositoryBase.get(dataSources);
	}

	function getDataSourcesForProject(projectId) {
		return repositoryBase.get(dataSourcesForProject, { projectId: projectId });
	}

	function getAll() {
		return repositoryBase.get(getEverything);
	}

	function getForProject(projectId) {
		return repositoryBase.get(getForProjectApi, { projectId: projectId });
	}

	function getRemainingPublishingTime(projectId) {
		return repositoryBase.get(remainingPublishingTimeApi, { projectId: projectId });
	}

	function getTotalPublishingTime(projectId) {
		return repositoryBase.get(totalPublishingTimeApi, { projectId: projectId });
	}

	function getPublishingInformation(projectId) {
		return repositoryBase.get(publishingInformationApi, { projectId: projectId });
	}

	function getTargetBackgroundMessages(projectId, target) {
		return repositoryBase.get(
			targetBackgroundMessagesApi, { projectId: projectId, target: target }
		);
	}

	function clearIndexing(projectId, datasourceId) {
		repositoryBase.post(clearIndexingApi, { projectId: projectId, datasourceId: datasourceId });
	}

	function restartIndexing(projectId, datasourceId) {
		repositoryBase.post(restartIndexingApi, { projectId: projectId, datasourceId: datasourceId });
	}

	function restartAsapIndexing(projectId, datasourceId) {
		repositoryBase.post(
			restartAsapIndexingApi, { projectId: projectId, datasourceId: datasourceId }
		);
	}
}
