angular.module("eShareApp").controller("ProjectEditCtrl", [
	"$scope", "$state", "project", "projects", "messageBox", "projectsRepository", "notification",
	"dirtyFormTrackingService", "projectActivityRepository", "Upload", "$http",

	function (
		$scope, $state, project, projects, messageBox, projectsRepository, notification,
		dirtyFormTrackingService, projectActivityRepository, upload, $http
	) {

		$scope.isNew = $state.current.data.isNew;
		$scope.projects = projects;
		projectActivityRepository.getProjectActivity().then(data => {
			const isProjectDisabled = data;
			$scope.sourceProjects = _.filter(projects, p => {
				return p.canBeSourceProject && !isProjectDisabled[p.id];
			});
		});

		$scope.getProjectNameById = getProjectNameById;
		$scope.isBeingSaved = false;
		$scope.isBusy = false;
		$scope.isSystemAdmin = window.eShare.currentUser.isSystemAdmin;

		if($scope.isNew) {
			$scope.project = {
				id: null,
				name: "",
				description: "",
				databaseName: "",
				is64BitOnly: false,
				canBeSourceProject: $scope.isSystemAdmin,
			};
		} else {
			$scope.project = _.cloneDeep(project);
			projectsRepository.getProjectImage($scope.project.id).then(response => {
				$scope.projectImageSource = response;
			});
		}

		const stateName = $state.current.name;

		$scope.isValid = function () {
			return $scope.project.name
				&& $scope.isProjectNameValid()
				&& $scope.isDatabaseNameValid()
				&& !$scope.isBeingSaved;
		};

		$scope.isProjectNameValid = function () {
			return angular.isString($scope.project.name)
				&& Utilities.betweenIncl($scope.project.name.trim().length, 1, 640);
		};

		$scope.isDatabaseNameValid = function () {
			if($scope.project.databaseName == "") {
				return true;
			}
			if(!angular.isString($scope.project.databaseName)) {
				return false;
			}
			const trimmedName = $scope.project.databaseName;
			const badNameRegex = new RegExp("[^a-zA-Z0-9_]");
			return Utilities.betweenIncl(trimmedName.length, 1, 64) && !badNameRegex.test(trimmedName);
		};

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		$scope.editCommit = function () {
			$scope.isBusy = true;
			let updateFunction;
			if($scope.isNew) {
				updateFunction = projectsRepository.add;
			} else {
				updateFunction = projectsRepository.update;
			}
			$scope.isBeingSaved = true;
			updateFunction($scope.project).then(
				() => {
					if($scope.selectedImage) {
						//Upload image
						if(!window.eShare.integratedSecurity) {
							$http.post("api/OneTimeToken/Create")
								.then(
									tokenData => {
										prepareForUpload($scope.selectedImage, tokenData.data.token);
									},
									() => {
										notification.error("Error getting one-time authorization token");
									}
								);
						} else {
							prepareForUpload($scope.selectedImage);
						}
					} else {
						if(!$scope.projectImageSource && !$scope.isNew) {
							projectsRepository.removeProjectImage($scope.project.id).then(() => {
								$scope.formScope.form && $scope.formScope.form.$setPristine();
								goBack();
							});
						} else {
							$scope.formScope.form && $scope.formScope.form.$setPristine();
							goBack();
						}
					}
				},
				message => {
					$scope.isBusy = false;
					if(message.startsWith("WARNING:")) {
						messageBox.openError(
							"Warning",
							message
						).result.then(() => {
							$scope.formScope.form && $scope.formScope.form.$setPristine();
							goBack();
						});
					} else {
						$scope.isBeingSaved = false;
						messageBox.openError(
							"Error creating / modifying project",
							"Error creating / modifying project: " + message
						);
					}
				}
			);
		};

		let fileType = "";

		$scope.checkImageFormat = function (file) {
			if(!file) {
				return;
			}
			if(file.size > 10240000) {
				notification.error("File size must be less than 10 megabytes");
				delete $scope.selectedImage;
				return;
			}
			fileType = "";
			const extension = (file.name || "").toLowerCase();
			if(extension.endsWith(".jpg")) {
				fileType = ".jpg";
			}
			if(extension.endsWith(".jpeg")) {
				fileType = ".jpeg";
			}
			if(extension.endsWith(".png")) {
				fileType = ".png";
			}
			if(extension.endsWith(".gif")) {
				fileType = ".gif";
			}
			if(extension.endsWith(".bmp")) {
				fileType = ".bmp";
			}
			if(fileType === "") {
				notification.error("File " + file.name + ": The file type must be jpg, png or gif");
				delete $scope.selectedImage;
			}
		};

		function prepareForUpload(file, oneTimeToken) {
			let projectId = $scope.project.id;
			if(!projectId) {
				projectsRepository
					.getByNameWithSpecialCharacters($scope.project.name)
					.then(response => {
						if(response && response.projectId) {
							projectId = response.projectId;
							uploadImage(file, oneTimeToken, projectId);
						} else {
							notification.error(
								"Unable to find project " + $scope.project.name + " from the database"
							);
							$scope.formScope.form && $scope.formScope.form.$setPristine();
							goBack();
						}
					});
			} else {
				uploadImage(file, oneTimeToken, projectId);
			}
		}

		function uploadImage(file, oneTimeToken, projectId) {
			$scope.isUploadInProgress = true;
			let url = oneTimeToken
				? "api/Projects/{projectId}/uploadProjectImage?fileType={fileType}&token={oneTimeToken}"
				: "api/Projects/{projectId}/uploadProjectImage?fileType={fileType}";
			url = url.supplant({
				projectId: projectId,
				fileType: fileType,
				oneTimeToken: oneTimeToken,
			});
			$scope.uploadProgress = "0%";
			const uploadSession = upload.upload({
				url: url,
				data: {
					file: file,
				},
			});
			uploadSession.then(onSuccess, onError, onProgress);

			function onSuccess() {
				$scope.uploadProgress = "100%";
				$scope.isUploadInProgress = false;
				$scope.formScope.form && $scope.formScope.form.$setPristine();
				goBack();
			}

			function onError(reason) {
				$scope.uploadProgress = "100%";
				notification.error("Project image upload failed: " + Utilities.getErrorMessage(reason));
				$scope.isUploadInProgress = false;
				$scope.formScope.form && $scope.formScope.form.$setPristine();
				goBack();
			}

			function onProgress(event) {
				$scope.isUploadInProgress = true;
				let progress = event.total ? event.loaded / event.total : 0;
				progress = Math.floor(progress * 100);
				$scope.uploadProgress = progress + "%";
			}
		}

		$scope.editRollback = function () {
			goBack();
		};

		$scope.removeSelectedImage = function () {
			delete $scope.selectedImage;
		};

		$scope.removeProjectImage = function () {
			$scope.projectImageSource = "";
		};

		function getProjectNameById(projectId) {
			const sourceProject = Utilities.isNonEmptyGuid(projectId)
				? _.find($scope.projects, { id: projectId })
				: null;
			return sourceProject ? (sourceProject.name || "") : "";
		}

		function goBack() {
			if($state.current.name !== stateName) {
				return;
			}
			if($scope.isNew) {
				$state.go("admin.projects", {}, { reload: true });
			} else {
				$state.go("project.admin", { projectId: $scope.project.id }, { reload: true });
			}
		}
	}
]);
