angular.module("eShare.ui").filter("formatTime", formatTime);

function formatTime() {
	return formatTimeFilterFunction;
}

function formatTimeFilterFunction(dateObject, format) {
	format = format || "LLL";
	if(Utilities.isNullOrUndefined(dateObject)) {
		return "";
	}
	if(!moment.isDate(dateObject)) {
		if(typeof (dateObject) === "string") {
			dateObject = moment.utc(dateObject);
		} else {
			return dateObject;
		}
	}
	const date = moment(dateObject);
	return date.isValid() ? date.local().format(format) : dateObject;
}
