angular.module("eShare.documentViewer").directive("cadMapViewer", cadMapViewer);

cadMapViewer.$inject = [];

function cadMapViewer() {
	return {
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadMapViewer.directive.html'),
		replace: true,
		restrict: "E",
		controller: mapViewerController,
	};
}

mapViewerController.$inject = [
	"$state", "$scope", "$rootScope", "$interval", "$timeout", "mapsRepository", "eBrowser"
];

function mapViewerController(
	$state, $scope, $rootScope, $interval, $timeout, mapsRepository, eBrowser
) {
	$scope.projectId = null;
	$scope.showSmartPoints = true;
	$scope.showMarkups = true;
	$scope.showPointClouds = true;
	$scope.disablePointClouds = false;
	$scope.smartPoints = [];
	$scope.markupStatuses = [];

	let isMapsPageVisible = false;
	let cameraLocationInterval;
	let areMapPointsLoaded = [];
	let hasMapLoadedDictionary = {};

	$scope.toggleSmartPoints = toggleSmartPoints;
	$scope.toggleMarkups = toggleMarkups;

	$scope.splitterOptionsHorizontal = {
		context: "maps",
		storageKey: "documents.mapSplitterHorizontal",
		orientation: "horizontal",
		afterMin: "50%",
		initialSizeBefore: "15%",
	};

	function initialize() {
		$scope.maps = [];
		$scope.smartPoints = [];
		$scope.markupStatuses = [];
		$scope.currentMap = null;
		areMapPointsLoaded = [];
		mapsRepository.getMapsAndPointInformation($scope.projectId).then(maps => {
			if(!maps || maps.length === 0) {
				$scope.isLoading = false;
				return;
			}
			$scope.maps = maps;
			for(let i = 0; i < $scope.maps.length; i++) {
				const map = $scope.maps[i];
				map.viewerId = "MapV" + i;
				map.documentDivId = "MapD" + i;
				map.showPoints = true;
				map.isVisible = i === 0;
				map.showPointClouds = $scope.showPointClouds;
				map.loadControl = {};
				map.viewerControl = {};
				hasMapLoadedDictionary[map.id] = false;
				fireLoadDocument(map);
				_.forEach(map.mapCoordinateAndPointInformation.pointLocationInformation, pli => {
					if(pli.type === "smartPoint" && isSubTypeUnique($scope.smartPoints, pli.subType)) {
						$scope.smartPoints.push({
							name: pli.subType,
							show: true,
						});
					} else if(pli.type === "markup"
						&& isSubTypeUnique($scope.markupStatuses, pli.subType)) {
						$scope.markupStatuses.push({
							name: pli.subType,
							show: true,
						});
					}
				});
			}
			$scope.currentMap = $scope.maps[0];
			if($state.current.name === "project.maps") {
				$timeout(() => {
					cameraLocationInterval = $interval(() => {
						if($scope.currentMap) {
							_.forEach($scope.maps, (map => {
								isCameraInZRangeCheck(map);
							}));
							$interval.cancel(cameraLocationInterval);
						}
					}, 100);
					setupPointVisibility();
					areMapPointsLoaded[$scope.currentMap.id] = true;
				});
			}
		});
	}

	function isSubTypeUnique(typeArray, subType) {
		let isUnique = true;
		_.forEach(typeArray, type => {
			if(type.name === subType) {
				isUnique = false;
				return;
			}
		});
		return isUnique;
	}

	$scope.refreshMaps = function () {
		if($scope.isLoading) {
			return;
		}
		$scope.isLoading = true;
		_.forEach($scope.maps, map => {
			let wrapperDiv = document.getElementById("WD:" + map.id);
			if(wrapperDiv) {
				wrapperDiv.parentNode.removeChild(wrapperDiv);
				wrapperDiv = null;
			}
			map.loadControl = null;
			map.viewerControl = null;
			$("#" + map.documentDivId).remove();
		});
		hasMapLoadedDictionary = {};
		initialize();
		$scope.showSmartPoints = true;
		const smartPointBox = document.querySelector("#allSmartPointsCheckBox");
		if(smartPointBox) {
			smartPointBox.indeterminate = false;
		}
		$scope.showMarkups = true;
		const markupBox = document.querySelector("#allMarkupsCheckBox");
		if(markupBox) {
			markupBox.indeterminate = false;
		}
		$scope.showPointClouds = !$scope.disablePointClouds;
	};

	function isCameraInZRangeCheck(map) {
		eBrowser.getCurrentLocationUrl().then(currentLocationUrl => {
			if(currentLocationUrl) {
				const xIndex = currentLocationUrl.indexOf("&x=");
				const yIndex = currentLocationUrl.indexOf("&y=");
				const zIndex = currentLocationUrl.indexOf("&z=");
				const rIndex = currentLocationUrl.indexOf("&r=");
				const sIndex = currentLocationUrl.indexOf("&s=");
				const currentX = parseInt(currentLocationUrl.substring(xIndex + 3, yIndex), 10);
				const currentY = parseInt(currentLocationUrl.substring(yIndex + 3, zIndex), 10);
				const currentZ = parseInt(currentLocationUrl.substring(zIndex + 3, rIndex), 10);
				let currentRotation = parseInt(currentLocationUrl.substring(rIndex + 3, sIndex), 10);
				const currentSlope = parseInt(currentLocationUrl.substring(sIndex + 3), 10);

				const coordinateInformation =
					map.mapCoordinateAndPointInformation.coordinateInformation;
				if(coordinateInformation.pointOne && coordinateInformation.pointTwo) {
					//Fix the rotation due to scaling if needed
					const documentDeltaX = coordinateInformation.pointTwo.mapX
						- coordinateInformation.pointOne.mapX;
					const documentDeltaY = coordinateInformation.pointTwo.mapY
						- coordinateInformation.pointOne.mapY;
					const modelDeltaX = coordinateInformation.pointTwo.modelX
						- coordinateInformation.pointOne.modelX;
					const modelDeltaY = coordinateInformation.pointTwo.modelY
						- coordinateInformation.pointOne.modelY;
					const documentToModelRatioX = modelDeltaX / documentDeltaX;
					const documentToModelRatioY = modelDeltaY / documentDeltaY;
					if(documentToModelRatioX < 0 && documentToModelRatioY < 0) {
						currentRotation += 180;
						if(currentRotation > 360) {
							currentRotation -= 360;
						}
					} else if(documentToModelRatioY < 0) {
						const verticalAxisSubtraction = currentRotation - 90;
						currentRotation = 270 - verticalAxisSubtraction;
						if(currentRotation > 360) {
							currentRotation -= 360;
						}
						if(currentRotation < 0) {
							currentRotation += 360;
						}
					} else if(documentToModelRatioX < 0) {
						currentRotation = 180 - currentRotation;
						if(currentRotation < 0) {
							currentRotation += 360;
						}
					}
				}

				const updateCameraLocation = $scope.currentMap?.viewerControl?.updateCameraLocation;
				if(updateCameraLocation) {
					updateCameraLocation(currentX, currentY, currentZ, currentRotation, currentSlope);
				}

				const isCameraInsideZRange = map.zfromInt <= currentZ && currentZ <= map.ztoInt;
				const shouldShowCameraIcon = map.showAllPoints ? true : isCameraInsideZRange;
				map.showCameraIcon = shouldShowCameraIcon;
				if(shouldShowCameraIcon) {
					map.cameraIconRotation = "rotate(" + (450 - currentRotation) + "deg)";
					map.cameraSlope = currentSlope;
				}
				toggleCameraLocationOn();
			}
		});
	}

	function fireLoadDocument(map) {
		//Making sure that the document is loaded only after the viewer directive is ready
		$scope.showSpinner = true;
		const interval = $interval(() => {
			const loadDocument = map.loadControl.loadDocument;
			if(loadDocument) {
				loadDocument();
				$interval.cancel(interval);
			}
		}, 100);
	}

	$rootScope.$on("$stateChangeSuccess", (event, toState /*, toParams, fromState, fromParams*/) => {
		if(toState.name !== "project.maps" && isMapsPageVisible) {
			isMapsPageVisible = false;
			$interval.cancel(cameraLocationInterval);
			$timeout(() => {
				$("#mapViewer").css("left", "-20000px");
				$("#mapViewer").css("width", "1000px");
			}, 10);
		}
		if(toState.name === "project.maps" && !isMapsPageVisible) {
			isMapsPageVisible = true;
			$("#mapViewer").css("left", "0");
			$("#mapViewer").css("right", "0");
			$("#mapViewer").css("width", "100%");
			$timeout(() => {
				if($scope.currentMap) {
					if(!areMapPointsLoaded[$scope.currentMap.id]) {
						$scope.showSpinner = true;
						const interval = $interval(() => {
							const setupMapPoints = $scope.currentMap.viewerControl.setupMapPoints;
							if(setupMapPoints) {
								setupMapPoints();
								areMapPointsLoaded[$scope.currentMap.id] = true;
								$scope.showSpinner = false;
								$interval.cancel(interval);
							}
						}, 100);
					}
					const updateMapUiElements = $scope.currentMap.viewerControl.updateMapUiElements;
					if(updateMapUiElements) {
						updateMapUiElements();
					}
					setupPointVisibility();
				}
				_.forEach($scope.maps, map => {
					isCameraInZRangeCheck(map);
				});
			}, 0);
			cameraLocationInterval = $interval(() => {
				if(eBrowser.isModelLoaded()) {
					_.forEach($scope.maps, map => {
						isCameraInZRangeCheck(map);
					});
					$interval.cancel(cameraLocationInterval);
				}
			}, 1000);
		}
	});

	$rootScope.$on("$stateChangeStart", (event, toState /*, toParams, fromState, fromParams*/) => {
		if(toState.name !== "project.maps" && isMapsPageVisible) {
			if(!$scope.currentMap) {
				return;
			}
			const onViewerHide = $scope.currentMap.viewerControl.onViewerHide;
			if(onViewerHide) {
				onViewerHide();
			}
		}
	});

	$rootScope.$on("eShare::projectChanged", (event, projectInfo) => {
		if(projectInfo.project.id !== $scope.projectId) {
			changeProject(projectInfo.project.id);
		}
	});

	function changeProject(projectId) {
		$scope.projectId = projectId;
		if($scope.projectId !== null) {
			const isUsingEShareApp = !!(window.chrome
				&& window.chrome.webview
				&& window.chrome.webview.hostObjects
				&& window.chrome.webview.hostObjects.eBrowserControl);
			if(!isUsingEShareApp) {
				$scope.showPointClouds = false;
				$scope.disablePointClouds = true;
			}
			$scope.refreshMaps();
		}
	}

	$scope.switchCurrentMap = function switchCurrentMap(map) {
		if(!$scope.currentMap || map.id === $scope.currentMap.id) {
			return;
		}
		const onViewerHide = $scope.currentMap.viewerControl.onViewerHide;
		if(onViewerHide) {
			onViewerHide();
		}
		$scope.currentMap.isVisible = false;
		$scope.currentMap = map;
		$scope.currentMap.isVisible = true;
		$timeout(() => {
			if(!areMapPointsLoaded[$scope.currentMap.id]) {
				const interval = $interval(() => {
					if($scope.currentMap) {
						const setupMapPoints = $scope.currentMap.viewerControl.setupMapPoints;
						if(setupMapPoints) {
							setupMapPoints();
							areMapPointsLoaded[$scope.currentMap.id] = true;
							$interval.cancel(interval);
						}
					}
				}, 100);
			}
			const updateMapUiElements = $scope.currentMap.viewerControl.updateMapUiElements;
			if(updateMapUiElements) {
				updateMapUiElements();
			}
			cameraLocationInterval = $interval(() => {
				if($scope.currentMap) {
					isCameraInZRangeCheck($scope.currentMap);
					$interval.cancel(cameraLocationInterval);
				}
			}, 100);
			setupPointVisibility();
		});
	};

	function toggleCameraLocationOn() {
		_.forEach($scope.maps, map => {
			const togglePoints = map.viewerControl.togglePoints;
			if(togglePoints) {
				togglePoints("cameraLocationDiv", true);
			}
		});
	}

	$scope.togglePointClouds = function () {
		_.forEach($scope.maps, map => {
			const togglePointClouds = map.viewerControl.togglePoints;
			if(togglePointClouds) {
				togglePointClouds("pointCloudDiv", $scope.showPointClouds);
			}
		});
	};

	function toggleSmartPoints(smartPointType) {
		$timeout(() => {
			_.forEach($scope.maps, map => {
				const togglePoints = map.viewerControl.togglePoints;
				if(togglePoints) {
					togglePoints(smartPointType.name, smartPointType.show);
				}
			});
		});
	}

	function toggleMarkups(markupType) {
		$timeout(() => {
			_.forEach($scope.maps, map => {
				const togglePoints = map.viewerControl.togglePoints;
				if(togglePoints) {
					togglePoints(markupType.name, markupType.show);
				}
			});
		});
	}

	function setupPointVisibility() {
		const interval = $interval(() => {
			if($scope.currentMap) {
				const togglePoints = $scope.currentMap.viewerControl.togglePoints;
				if(togglePoints) {
					toggleCameraLocationOn();
					$scope.togglePointClouds();
					$scope.smartPoints.forEach(sp => {
						toggleSmartPoints(sp);
					});
					$scope.markupStatuses.forEach(m => {
						toggleMarkups(m);
					});
					$interval.cancel(interval);
				}
			}
		}, 100);
	}

	$(document).on("click", ".allow-focus", e => {
		e.stopPropagation();
	});

	$scope.onSmartPointCheckBoxChanged = function () {
		let areNoneVisible = true;
		let areAllVisible = true;
		$scope.smartPoints.forEach(sp => {
			if(sp.show && areNoneVisible) {
				areNoneVisible = false;
			} else if(!sp.show && areAllVisible) {
				areAllVisible = false;
			}
		});
		let isIndeterminate = true;
		if(areNoneVisible === areAllVisible) {
			isIndeterminate = true;
		} else if(areNoneVisible) {
			isIndeterminate = false;
			$scope.showSmartPoints = false;
		} else if(areAllVisible) {
			isIndeterminate = false;
			$scope.showSmartPoints = true;
		}
		const box = document.querySelector("#allSmartPointsCheckBox");
		if(box) {
			box.indeterminate = isIndeterminate;
		}
	};

	$scope.onMarkupCheckBoxChanged = function () {
		let areNoneVisible = true;
		let areAllVisible = true;
		$scope.markupStatuses.forEach(m => {
			if(m.show && areNoneVisible) {
				areNoneVisible = false;
			} else if(!m.show && areAllVisible) {
				areAllVisible = false;
			}
		});
		let isIndeterminate = true;
		if(areNoneVisible === areAllVisible) {
			isIndeterminate = true;
		} else if(areNoneVisible) {
			isIndeterminate = false;
			$scope.showMarkups = false;
		} else if(areAllVisible) {
			isIndeterminate = false;
			$scope.showMarkups = true;
		}
		const box = document.querySelector("#allMarkupsCheckBox");
		if(box) {
			box.indeterminate = isIndeterminate;
		}
	};

	$scope.toggleSelectAllSmartPointsFromLabel = function () {
		let areNoneVisible = true;
		let areAllVisible = true;
		$scope.smartPoints.forEach(sp => {
			if(sp.show && areNoneVisible) {
				areNoneVisible = false;
			} else if(!sp.show && areAllVisible) {
				areAllVisible = false;
			}
		});
		if(areNoneVisible === areAllVisible) {
			$scope.toggleSelectAllSmartPoints();
			const box1 = document.querySelector("#allSmartPointsCheckBox");
			if(box1) {
				box1.indeterminate = false;
			}
		} else {
			$scope.showSmartPoints = !$scope.showSmartPoints;
			$scope.toggleSelectAllSmartPoints();
			const box2 = document.querySelector("#allSmartPointsCheckBox");
			if(box2) {
				box2.indeterminate = false;
			}
		}
	};

	$scope.toggleSelectAllMarkupsFromLabel = function () {
		let areNoneVisible = true;
		let areAllVisible = true;
		$scope.markupStatuses.forEach(m => {
			if(m.show && areNoneVisible) {
				areNoneVisible = false;
			} else if(!m.show && areAllVisible) {
				areAllVisible = false;
			}
		});
		if(areNoneVisible === areAllVisible) {
			$scope.toggleSelectAllMarkups();
			const box1 = document.querySelector("#allMarkupsCheckBox");
			if(box1) {
				box1.indeterminate = false;
			}
		} else {
			$scope.showMarkups = !$scope.showMarkups;
			$scope.toggleSelectAllMarkups();
			const box2 = document.querySelector("#allMarkupsCheckBox");
			if(box2) {
				box2.indeterminate = false;
			}
		}
	};

	$scope.toggleSelectAllSmartPoints = function () {
		$timeout(() => {
			$scope.smartPoints.forEach(sp => {
				if($scope.showSmartPoints) {
					sp.show = true;
					toggleSmartPoints(sp);
				} else {
					sp.show = false;
					toggleSmartPoints(sp);
				}
			});
		});
	};

	$scope.toggleSelectAllMarkups = function () {
		$timeout(() => {
			$scope.markupStatuses.forEach(m => {
				if($scope.showMarkups) {
					m.show = true;
					toggleMarkups(m);
				} else {
					m.show = false;
					toggleMarkups(m);
				}
			});
		});
	};

	$scope.onToggleSpinner = function (isLoading) {
		$scope.showSpinner = isLoading;
	};

	$scope.onLoad = function (id) {
		hasMapLoadedDictionary[id] = true;
		//Check if all maps have loaded
		for(const key in hasMapLoadedDictionary) {
			if(hasMapLoadedDictionary.hasOwnProperty(key)) {
				if(hasMapLoadedDictionary[key] === false) {
					return;
				}
			}
		}
		$timeout(() => {
			$scope.isLoading = false;
		}, 500);
	};
}
