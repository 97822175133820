angular.module("eShareApp").controller("AttributeCategorizationCtrl", [
	"$rootScope", "$scope", "$state", "project", "attributeDefinitions", "attributeCategorizations",
	"attributeCategorizationRepository", "messageBox", "notification", "$timeout", "colorService",
	"dirtyFormTrackingService",

	function (
		$rootScope, $scope, $state, project, attributeDefinitions, attributeCategorizations,
		attributeCategorizationRepository, messageBox, notification, $timeout, colorService,
		dirtyFormTrackingService
	) {

		$scope.state = $state;
		$scope.project = project;
		$scope.attributeDefinitions = attributeDefinitions;
		//Parse special categories from categories before assigning them to scope.
		const attrCategorizations = attributeCategorizations;
		extractSpecialCategories(attrCategorizations);
		$scope.attributeCategorizations = attrCategorizations;

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		$scope.addAttribute = function (attribute) {
			const newCategorization = {
				name: attribute.displayName,
				abbreviation: attribute.abbreviation,
				displayName: attribute.displayName,
				definesHierarchy: true,
				definesVisualStyle: true,
				usesDynamicCategories: false,
				usageCount: 0,
				isInUse: true,
				categories: [],
			};
			attributeCategorizationRepository.save(project.id, newCategorization).then(data => {
				attributeCategorizationRepository.get(project.id).then(categorizations => {
					$scope.attributeCategorizations = categorizations;
					$state.go(".edit", { categorizationId: data.id });
				});
			});
		};

		$scope.removeAttribute = function (attribute) {
			const mbox = messageBox.openQuestion(
				"Remove categorization?",
				"Are you sure you want to remove the categorization by "
				+ attribute.displayName + "? This cannot be undone.",
				"Remove", "Cancel"
			);
			mbox.result.then(() => {
				attributeCategorizationRepository.remove(project.id, attribute.id).then(data => {
					const attrCategorizations = data;
					extractSpecialCategories(attrCategorizations);
					$scope.attributeCategorizations = attrCategorizations;
				});
			});
		};

		$scope.expandAttribute = function (attribute) {
			attribute.collapsed = false;
		};
		$scope.collapseAttribute = function (attribute) {
			attribute.collapsed = true;
		};
	}
]);
function extractSpecialCategories(attrCategorizations) {
	_.forEach(attrCategorizations, categorization => {
		for (let i = 0; i < categorization.categories.length; i++) {
			const category = categorization.categories[i];
			if (category.categoryType === "Uncategorized") {
				categorization.uncategorized = categorization.categories.splice(i, 1)[0];
				i--;
			}
			if (category.categoryType === "MultipleCategories") {
				categorization.multipleCategories = categorization.categories.splice(i, 1)[0];
				i--;
			}
		}
	});
}
