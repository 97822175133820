angular.module("eShareApp").controller("ProjectHomeCtrl", ProjectHomeCtrl);

ProjectHomeCtrl.$inject = [
	"$rootScope", "$state", "$scope", "$http", "$timeout", "Upload", "project", "model3dState",
	"integrations", "canAdministerProject", "documentDataSourcesExist", "searchService",
	"notification", "applicationState", "markupsRepository", "eBrowser",
	"printedDocumentInformation", "maps", "canHandleMarkups", "modelImportersRepository",
	"projectsRepository", "whoCanDownload"
];

function ProjectHomeCtrl(
	$rootScope, $state, $scope, $http, $timeout, upload, project, model3dState,
	integrations, canAdministerProject, documentDataSourcesExist, searchService,
	notification, applicationState, markupsRepository, eBrowser,
	printedDocumentInformation, maps, canHandleMarkups, modelImportersRepository,
	projectsRepository, whoCanDownload
) {

	if(!project || !project.id) {
		notification.error("Project not found");
		$state.go("selectProject", {}, { location: "replace" });
		return;
	}

	const unregisterStateChangeStartListener = $rootScope.$on("$stateChangeStart",
		(event, toState /*, toParams, fromState, fromParams*/) => {
			if(toState.controller === "ProjectHomeCtrl") {
				refreshMarkups();
			}
		});

	$scope.$on("$destroy", () => {
		unregisterStateChangeStartListener();
	});

	searchService.clearIfProjectChanged(project.id);
	model3dState.setProject(project.id);
	printedDocumentInformation.setProject(project.id);
	integrations.setProject(project.name);

	$scope.state = $state;
	$scope.isSysAdmin = window.eShare.currentUser.role === "sysadmin";
	$scope.version = window.eShare.version;
	$scope.productVersion = window.eShare.productVersion;
	$scope.offer64BitApp = window.eShare.is64BitWindows && !Utilities.toBool(
		window.external?.isEBrowserControl || window.chrome?.webview?.hostObjects?.eBrowserControl
	);
	$scope.databaseEngineInfo = window.eShare.databaseEngineInfo;

	$scope.project = project;
	$rootScope.$broadcast("eShare::projectChanged", {
		project: project,
		canAdministerProject: canAdministerProject,
	});

	$scope.currentProjectId = null;
	$scope.currentProjectName = "No project";
	$scope.canAdministerProject = canAdministerProject;
	$scope.canUploadMarkups = canHandleMarkups.canUserUploadMarkups;
	$scope.canSeeMarkups = canHandleMarkups.canUserSeeMarkups;
	$scope.documentDataSourcesExist = documentDataSourcesExist.documentDataSourcesExist;
	$scope.importResult = null;

	switch(whoCanDownload) {
	case "all":
		$scope.isDownloadAllowed = true;
		break;
	case "admin":
		$scope.isDownloadAllowed = $scope.canAdministerProject;
		break;
	default:
		$scope.isDownloadAllowed = false;
		break;
	}

	$scope.mapsExist = maps.length > 0;

	$scope.markups = null;
	$scope.myMarkups = null;
	if($state.current.name === "project") {
		refreshMarkups();
	}

	projectsRepository.getProjectImage($scope.project.id).then(response => {
		$scope.projectImageSource = response;
	});

	$scope.modelImporterDates = [];
	modelImportersRepository.getDates($scope.project.id).then(modelImporterDates => {
		$scope.modelImporterDates = modelImporterDates;
	});

	$scope.localizeDate = function localizeDate(utcDateTimeString) {
		const date = moment.utc(utcDateTimeString);
		return date.isValid() ? date.local().format("LLL") : utcDateTimeString;
	};

	$scope.isProjectDescriptionTooBig = function () {
		const element = $("#projectDescriptionText");
		if(element) {
			if(element.height() > 200) {
				return true;
			}
		}
		return false;
	};

	$scope.isProjectDescriptionOpen = false;

	$scope.toggleExpandDescription = function () {
		const element = $("#projectDescriptionDiv");
		if(element) {
			if($scope.isProjectDescriptionOpen) {
				element.css("max-height", "4.5em");
			} else {
				element.css("max-height", "");
			}
			$scope.isProjectDescriptionOpen = !$scope.isProjectDescriptionOpen;
		}
	};

	$scope.togglePopup = function () {
		const popup = document.getElementById("projectImagePopup");
		popup.classList.toggle("show");
	};

	$scope.showCreatedByMeMarkups = function () {
		searchService.clear();
		$state.go("project.search", {
			projectId: project.id,
			isAdvanced: true,
			text: "",
			scope: "",
			terms: "((pointKind)):eq:-2" + "|((createdBy)):eq:" + window.eShare.currentUser.fullName,
			searchTarget: "Points",
		}, {
			reload: true,
			inherit: false,
		});
	};

	$scope.showModifiedByMeMarkups = function () {
		searchService.clear();
		$state.go("project.search", {
			projectId: project.id,
			isAdvanced: true,
			text: "",
			scope: "",
			terms: "((pointKind)):eq:-2"
				+ "|((modifiedBy)):wasEq:"
				+ window.eShare.currentUser.fullName,
			searchTarget: "Points",
		}, {
			reload: true,
			inherit: false,
		});
	};

	$scope.showAssignedToMeMarkups = function () {
		searchService.clear();
		$state.go("project.search", {
			projectId: project.id,
			isAdvanced: true,
			text: "",
			scope: "",
			terms: "((pointKind)):eq:-2"
				+ "|((assigneeName)):eq:"
				+ window.eShare.currentUser.fullName,
			searchTarget: "Points",
		}, {
			reload: true,
			inherit: false,
		});
	};

	$scope.showAllMarkups = function () {
		searchService.clear();
		$state.go("project.search", {
			projectId: project.id,
			isAdvanced: true,
			text: "",
			scope: "",
			terms: "((pointKind)):eq:-2",
			searchTarget: "Points",
		}, {
			reload: true,
			inherit: false,
		});
	};

	$scope.openMarkup = function (markup) {
		const markupId = markup.id;
		$state.go("project.model", { markupId: markupId });
	};

	$scope.removeMarkup = function (markup) {
		markupsRepository.remove(project.id, markup.id)
			.then((/*data*/) => {
				eBrowser.deletePointFrom3D(markup.id);
				refreshMarkups();
			},
			(/*error*/) => {
				notification.error("Error deleting a markup");
			});
	};

	const markupUpload = {
		isActive: false,
		progress: "0%",
	};
	$scope.markupUpload = markupUpload;
	$scope.markupFileSelected = markupFileSelected;
	$scope.clearUploadStatus = clearUploadStatus;

	applicationState.setLastUsedProject(project.id, project.name);

	function markupFileSelected(files /*, event*/) {
		const file = files ? files[0] : null;
		if(!file) {
			return;
		}
		const extension = (file.name || "").toLowerCase();
		if(!(extension.endsWith(".ebx")
			|| extension.endsWith(".bcfzip")
			|| extension.endsWith(".bcf"))) {
			notification.error("An .ebx, .bcf or .bcfzip file must be selected");
			return;
		}
		if(!window.eShare.integratedSecurity) {
			$http.post("api/OneTimeToken/Create")
				.then(
					tokenData => {
						uploadMarkups(file, tokenData.data.token);
					},
					() => {
						notification.error("Error getting one-time authorization token");
					}
				);
		} else {
			uploadMarkups(file);
		}
	}

	function uploadMarkups(file, oneTimeToken) {
		clearUploadStatus();
		let url = oneTimeToken
			? "api/project/{projectId}/Markups/Upload?token={oneTimeToken}"
			: "api/project/{projectId}/Markups/Upload";
		url = url.supplant({
			projectId: project.id,
			oneTimeToken: oneTimeToken,
		});
		markupUpload.isActive = true;
		markupUpload.progress = "0%";
		const uploadSession = upload.upload({
			url: url,
			data: {
				file: file,
			},
		});
		uploadSession.then(onSuccess, onError, onProgress);

		function onSuccess(response) {
			markupUpload.isActive = false;
			markupUpload.progress = "100%";
			const importResult = response.data;
			if(importResult && importResult.hasSucceeded) {
				notification.success("Markup file uploaded");
			} else {
				notification.error("Markup file upload failed");
			}
			$scope.importResult = importResult;
			eBrowser.updatePoints(importResult.pointsOfInterestWithKinds);
			refreshMarkups();
		}

		function onError(reason) {
			markupUpload.isActive = false;
			markupUpload.progress = "100%";
			notification.error("Markup file upload failed: " + Utilities.getErrorMessage(reason));
			refreshMarkups();
		}

		function onProgress(event) {
			let progress = event.total ? event.loaded / event.total : 0;
			progress = Math.floor(progress * 100);
			markupUpload.progress = progress + "%";
		}
	}

	function clearUploadStatus() {
		$scope.importResult = null;
	}

	function refreshMarkups() {
		markupsRepository.getLatest(project.id)
			.then(markups => {
				$scope.markups = markups;
			});
		markupsRepository.getLatestForUser(project.id, window.eShare.currentUser.id)
			.then(myMarkups => {
				$scope.myMarkups = myMarkups;
			});
	}

	$scope.goToSearch = function () {
		let searchRequest = null;
		if(searchService.result.hasResults()) {
			searchRequest = searchService.result.request;
		}
		searchRequest = searchRequest || {
			projectId: $scope.project.id,
			isAdvanced: false,
			text: "",
			scope: "IdOnly",
			terms: [],
		};
		$state.go("project.search", {
			projectId: searchRequest.projectId,
			isAdvanced: searchRequest.isAdvanced,
			text: searchRequest.text,
			scope: searchRequest.scope,
			terms: searchService.serializeTerms(searchRequest.terms),
			searchTarget: searchRequest.searchTarget,
		}, {
			reloadOnSearch: true,
		}, {
			inherit: false,
		});
	};

	$scope.openDocumentation = function () {
		const helpUrl = "/docs/HTML5/Index.htm#cshid=ES_VIEW_DEFAULT";
		$timeout(() => {
			window.open(helpUrl, "_cadmaticeShareDocumentation");
		}, 1);
	};

	$scope.downloadModel = function () {
		const modelUrl = "{rootUrl}api/project/{projectId}/Models2/{projectId}/Download".supplant({
			rootUrl: window.eShare.rootUrl,
			projectId: $scope.project.id,
		});
		$scope.isDownloadPreparing = true;
		$http.get(modelUrl, {
			responseType: "blob",
		}).then(
			response => {
				const blob = response.data;
				const filename = $scope.project.name + " model.ebmx";
				window.saveAs(blob, filename);
				$scope.isDownloadPreparing = false;
			},
			errorResponse => {
				notification.error(
					"Downloading the model file failed: " + Utilities.getErrorMessage(errorResponse)
				);
			}
		);
	};
}
