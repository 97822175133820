angular.module("eShareApp").factory("applicationState", applicationState);

applicationState.$inject = ["$http", "notification", "repositoryBase"];

function applicationState($http, notification, repositoryBase) {
	let lastUsedProjectId;
	let lastUsedProjectName;

	return {
		setLastUsedProject: setLastUsedProject,
		getLastUsedProjectId: getLastUsedProjectId,
		getLastUsedProjectName: getLastUsedProjectName,
	};

	function getLastUsedProjectId() {
		return repositoryBase.get("/api/LastUsedProject/", {}).then(data => {
			lastUsedProjectId = Utilities.guidOrEmpty(data.lastUsedProjectId);
			return lastUsedProjectId;
		});
	}

	function getLastUsedProjectName() {
		return lastUsedProjectName;
	}

	function setLastUsedProject(projectId, projectName) {
		projectId = Utilities.guidOrEmpty(projectId);
		lastUsedProjectId = projectId;
		lastUsedProjectName = projectName;
		return $http.post("/api/LastUsedProject/", {
			lastUsedProjectId: projectId,
		});
	}
}
