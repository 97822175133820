angular.module("eShareApp").run(run);

run.$inject = [
	"$rootScope", "$state", "$timeout", "$interpolate", "authorization", "notification",
	"serverMonitor", "projectsRepository", "usersRepository", "integrations", "amMoment"
];

function run(
	$rootScope, $state, $timeout, $interpolate, authorization, notification,
	serverMonitor, projectsRepository, usersRepository, integrations, amMoment
) {

	let isSessionTerminated = false;
	let isNoLicenseEventProcessed = false;
	let isPermissionDeniedEventProcessed = false;
	let isLoginRequiredEventProcessed = false;
	let isDatabaseSchemaErrorProcessed = false;

	amMoment.changeLocale("cadmatic");
	ace.config.set("basePath", "static/js/ace");

	serverMonitor.initialize();

	$rootScope.$on("$stateChangeStart", (event, toState /*, toParams, fromState, fromParams*/) => {
		const accessLevel = toState.data.accessLevel || "public";
		// Block state transition if user is trying to go to a page they don't have access to
		if(!authorization.authorize(accessLevel, window.eShare.currentUser.role)) {
			event.preventDefault();
			notification.error("Access denied");
			navigateToErrorPage();
		}
	});

	$rootScope.$on("$stateChangeSuccess", (event, toState /*, toParams, fromState, fromParams*/) => {
		const scope = $state.$current.locals.globals;
		let projectName = scope && scope.project && scope.project.name;
		if(!Utilities.isString(projectName)) {
			projectName = "";
		}
		projectName = projectName.trim();
		let stateTitle = toState && toState.data && toState.data.title;
		if(!Utilities.isString(stateTitle)) {
			stateTitle = "";
		}
		stateTitle = stateTitle.trim();
		if(stateTitle.indexOf("{{") >= 0) {
			stateTitle = $interpolate(stateTitle)(scope);
		}
		let title = "";
		if(projectName !== "") {
			title = title + projectName + " - ";
		}
		if(stateTitle !== "") {
			title = title + stateTitle + " - ";
		}
		title += "CADMATIC eShare";
		const titleElements = document.getElementsByTagName("title");
		if(titleElements && titleElements.length > 0) {
			titleElements[0].text = title;
		}
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	});

	$rootScope.$on("$stateNotFound", (event, unfoundState /*, fromState, fromParams*/) => {
		event.preventDefault();
		notification.error("Page " + unfoundState.to + " not found");
		navigateToErrorPage();
	});

	$rootScope.$on("$stateChangeError", (event, toState, toParams, fromState, fromParams, error) => {
		event.preventDefault();
		if(error === "Insufficient access level") {
			return;
		}
		if(error === window.eShare.noLicenseMessage) {
			return;
		}
		error = Utilities.getErrorMessage(error);
		if(error === window.eShare.connectionLostMessage) {
			serverMonitor.connectionLost();
			return;
		}
		notification.error(
			"Cannot navigate from " + fromState.name + " to " + toState.name + " - " + error
		);
		navigateToErrorPage();
	});

	$rootScope.$on("event:permissionDenied", () => {
		if(isPermissionDeniedEventProcessed) {
			return;
		}
		isPermissionDeniedEventProcessed = true;
		notification.error("Permission denied");
		$timeout(() => {
			window.location.href = "/AuthenticationError";
		}, 0);
	});

	$rootScope.$on("event:loginRequired", () => {
		if(isLoginRequiredEventProcessed) {
			return;
		}
		isLoginRequiredEventProcessed = true;
		notification.warning("Login required - old session likely has expired");
		$timeout(() => {
			window.location.href = window.eShare.loginUrl;
		}, 0);
	});

	$rootScope.$on("event:sessionTerminated", () => {
		if(isSessionTerminated) {
			return;
		}
		isSessionTerminated = true;
		notification.error("Your eShare session has been terminated");
		$timeout(() => {
			window.location.href = window.eShare.sessionTerminatedUrl;
		}, 0);
	});

	$rootScope.$on("event:noLicense", () => {
		if(isNoLicenseEventProcessed) {
			return;
		}
		isNoLicenseEventProcessed = true;
		notification.error(window.eShare.noLicenseMessage);
		$timeout(() => {
			Authenticator.navigateToNoLicense();
		}, 0);
	});

	$rootScope.$on("event:databaseSchemaError", (event, error) => {
		if(isDatabaseSchemaErrorProcessed) {
			return;
		}
		isDatabaseSchemaErrorProcessed = true;
		notification.disableToasts();
		notification.error(error.message);
		$timeout(() => {
			window.location.href = window.eShare.databaseSchemaErrorUrl
				+ "?type=" + encodeURIComponent(error.type)
				+ "&message=" + encodeURIComponent(error.message);
		}, 0);
	});

	window.eShare.integrations.receiveCommand = receiveCommand;

	window.Authenticator.setCadAuthorizationChangedCallback(() => {
		$rootScope.$broadcast("event:cadAuthorizationChanged");
	});

	$("#loginScreen").hide();

	function receiveCommand(command) {
		if(!integrations.isActive) {
			notification.error("Remote command received when eShare Integrations are off");
			return;
		}
		if(!window.eShare.integrations.lib.isCommand(command)) {
			notification.error("Invalid remote command " + JSON.stringify(command));
			return;
		}
		const parameters = command.parameters || null;
		switch(command.command) {
		case "goToProject":
			if(!parameters || !parameters.projectName) {
				notification.error("Invalid remote command parameters");
				return;
			}
			navigateToProject(parameters.projectName);
			break;
		case "goToObject":
			if(!parameters || !parameters.projectName || !parameters.objectId) {
				notification.error("Invalid remote command parameters");
				return;
			}
			navigateToObject(parameters.projectName, parameters.objectId, parameters.tag);
			break;
		default:
			notification.error("Invalid remote command '" + command + "'");
			break;
		}
	}

	function navigateToErrorPage() {
		$timeout(() => {
			$state.go("error");
		}, 0);
	}

	function navigateToProject(projectName) {
		projectsRepository.getByName(projectName)
			.then(
				response => {
					if(response && Utilities.isNonEmptyGuid(response.projectId)) {
						const projectId = response.projectId;
						$state.go("project", { projectId: projectId }, { reload: true });
					} else {
						notification.error("Invalid response");
					}
				},
				error => {
					notification.error(error);
				}
			);
	}

	function navigateToObject(projectName, objectId, tag) {
		projectsRepository.getByName(projectName)
			.then(
				response => {
					if(response && Utilities.isNonEmptyGuid(response.projectId)) {
						const projectId = response.projectId;
						const stateParameters = {
							projectId: projectId,
							positionId: objectId,
							tag: tag || "",
							geometryId: "",
							modelTimestamp: "",
							externalTag: "",
							externalId: "",
							groupParentTag: "",
							groupChildTag: "",
							groupId: "",
						};
						$state.go("project.model", stateParameters, { reload: true });
					} else {
						notification.error("Invalid response");
					}
				},
				error => {
					notification.error(error);
				}
			);
	}
}
