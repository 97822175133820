angular.module("eShareApp").controller("ColorsViewCtrl", colorsViewCtrl);
colorsViewCtrl.$inject = [
	"$scope", "$stateParams", "colorService"
];

function colorsViewCtrl(
	$scope, $stateParams, colorService
) {
	colorService.resetProjectColors($stateParams.projectId);
	colorService.getProjectColors($stateParams.projectId).then(colors => {
		$scope.colors = colors;
	});
}
