angular.module("eShareApp").controller("AdaptersCtrl", [
	"$rootScope", "$state", "$scope", "$filter", "project", "adapters", "availableAdapterTypes",
	"adaptersRepository", "messageBox", "notification",

	function (
		$rootScope, $state, $scope, $filter, project, adapters, availableAdapterTypes,
		adaptersRepository, messageBox, notification
	) {

		$scope.project = project;
		$scope.adapters = adapters;

		$scope.removeAdapter = function (adapter) {
			const mbox = messageBox.openQuestion(
				"Remove selected adapter?",
				"Do you really want to remove adapter " + adapter.name + "?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					adaptersRepository.remove($scope.project, adapter).then(
						() => {
							adaptersRepository.getAll($scope.project.id)
								.then(allAdapters => {
									$scope.adapters = allAdapters;
								}, error => {
									notification.error(error);
								});
						}, error => {
							notification.error(error);
						}
					);
				}
			);
		};

		$scope.isKnownAdapterType = function (adapterTypeGuid) {
			return !Utilities.isNullOrUndefined(getAdapterType(adapterTypeGuid));
		};

		$scope.getAdapterTypeName = function (adapterTypeGuid) {
			const adapterType = getAdapterType(adapterTypeGuid);
			return Utilities.isNullOrUndefined(adapterType)
				? "Unknown (GUID: " + adapterTypeGuid + ")"
				: adapterType.name;
		};

		function getAdapterType(adapterTypeGuid) {
			return _.find(availableAdapterTypes, at => {
				return at.typeGuid === adapterTypeGuid;
			});
		}

		$scope.toggleAdapterIsActive = function (adapter) {
			$scope.isSavingAdapter = true;
			adapter.isActive = !adapter.isActive;
			adaptersRepository.update($scope.project.id, adapter)
				.then(
					() => {
						$scope.isSavingAdapter = false;
					},
					message => {
						messageBox.openError(
							"Error",
							"Error saving adapter configuration: " + message
						);
						$scope.isSavingAdapter = false;
					}
				);
		};
	}
]);
