import Dygraph from "dygraphs";

angular.module("eShareApp").directive("cadChart", [
	"notification",
	function (notification) {
		return {
			restrict: "E",
			scope: {
				id: "@",
				data: "=",
				legend: "@",
				rangeSelection: "@",
				detailLoader: "&",
				yAxisLabel: "@",
				width: "@",
				height: "@",
			},
			template: "<div class=\"cad-chart\" data-ng-style=\"{width:width,height:height}\"></div>",
			controller: [
				"$scope", "$element", function ($scope, $element) {
					$scope.parseServerData = function (data) {
						const parsedData = [];
						_.forEach(data, item => {
							const low = parseFloat(item.low);
							const high = parseFloat(item.high);
							const avg = parseFloat(item.average);
							parsedData.push([
								new Date(item.timestamp),
								[low, avg, high]
							]);
						});
						return parsedData;
					};
					$scope.loadDetailData = function () {
						if(!$scope.rangeSelection || !$scope.chart) {
							return;
						}
						const chartTimeRange = $scope.chart.xAxisRange();
						if($scope.startTime == null) {
							$scope.startTime = new Date(chartTimeRange[0]);
						}
						if($scope.endTime == null) {
							$scope.endTime = new Date(chartTimeRange[1]);
						}
						const loader = $scope.detailLoader(
							{
								startTime: $scope.startTime,
								endTime: $scope.endTime,
								pointCount: $element.width() / 2,
							}
						);
						loader.then(detailDataFromServer => {
							const detailData = $scope.parseServerData(detailDataFromServer.pointValues);
							if($scope.overviewData.length == 0) {
								$scope.chartData = detailData;
								return;
							}
							if(detailData.length == 0) {
								$scope.chartData = $scope.overviewData;
								return;
							}
							const firstDetailData = detailData[0];
							const firstDetailTime = firstDetailData[0];
							const headOverViewData = _.take($scope.overviewData, data => {
								return data[0] < firstDetailTime;
							});
							const lastDetailData = detailData[detailData.length - 1];
							const lastDetailTime = lastDetailData[0];
							$scope.chartData = headOverViewData.concat(detailData);
							_.forEach($scope.overviewData, data => {
								if(lastDetailTime < data[0]) {
									$scope.chartData.push(data);
								}
							});
							$scope.createOrRefreshChart();
						}, error => {
							notification.error(error);
						});
					};

				}
			],
			link: function (scope, elem) {
				scope.chart = null;

				scope.mouseUpHandlerElement = $(window);

				scope.mouseDownInRangeSelector = false;
				scope.startTime = null;
				scope.endTime = null;
				scope.chartData = scope.overviewData = [];
				scope.isEnum = false;

				scope.zoom = function (minDate, maxDate /*, yRanges*/) {
					scope.startTime = new Date(minDate);
					scope.endTime = new Date(maxDate);
					if(scope.chart.isZoomed("x") === false) {
						scope.mouseUpHandlerElement.off("mouseup touchend");
						return;
					}
					if(scope.mouseDownInRangeSelector) {
						return;
					}
					scope.loadDetailData();
				};

				scope.createOrRefreshChart = function (replaceAllData) {
					const chartOptions = {
						labels: ["Time", scope.yAxisLabel ? scope.yAxisLabel : "Value"],
						ylabel: scope.yAxisLabel,
						showRangeSelector: scope.rangeSelection === "true",
						rangeSelectorHeight: 30,
						//animatedZooms: true,
						stepPlot: false,
						customBars: true,
						showLabelsOnHighlight: scope.legend === "true",
						highlightCircleSize: scope.legend === "true" ? 3 : 0,
						interactionModel: scope.legend === "true" ? Dygraph.Interaction.defaultModel : {},
						zoomCallback: scope.zoom,
						axes: {
							x: {
								valueFormatter: function (msecs) {
									return moment(msecs).format("YYYY-MM-DD HH:mm:ss");
								},
							},
						},
					};
					const enumLabelFormatter = function (y) {
						if(scope.enumValues.hasOwnProperty(y)) {
							return scope.enumValues[y];
						}
						return "";
					};
					if(scope.isEnum) {
						chartOptions.labelsShowZeroValues = false;
						chartOptions.stepPlot = true;
						chartOptions.axes.y = {
							valueFormatter: enumLabelFormatter,
							axisLabelFormatter: enumLabelFormatter,
							pixelsPerLabel: 15,
						};
					}
					if(scope.startTime && scope.endTime && !replaceAllData) {
						chartOptions.dateWindow = [scope.startTime.valueOf(), scope.endTime.valueOf()];
					}
					if(scope.chart != null) {
						scope.mouseUpHandlerElement.off("mouseup touchend");
						scope.rangeElement.off("mousedown touchstart");
						scope.chart.destroy();
						scope.chart = null;
					}
					if(scope.chartData.length === 0) {
						return;
					}
					scope.chart = new Dygraph(
						elem.children(".cad-chart")[0],
						scope.chartData,
						chartOptions
					);
					scope.rangeElement = elem.children(".cad-chart")
						.find(".dygraph-rangesel-fgcanvas, .dygraph-rangesel-zoomhandle");
					scope.rangeElement.on("mousedown touchstart", () => {
						scope.mouseDownInRangeSelector = true;
						scope.mouseUpHandlerElement.off("mouseup touchend");
						scope.mouseUpHandlerElement.on("mouseup touchend", () => {
							scope.mouseDownInRangeSelector = false;
							const chartTimeRange = scope.chart.xAxisRange();
							scope.startTime = new Date(chartTimeRange[0]);
							scope.endTime = new Date(chartTimeRange[1]);
							scope.loadDetailData();
						});
					});

				};

				scope.$watch("data", newVal => {
					const data = angular.fromJson(newVal);
					scope.isEnum = data.isEnum;
					scope.enumValues = data.enumValues;
					scope.chartData = scope.overviewData = scope.parseServerData(data.pointValues);
					scope.createOrRefreshChart(true);
				}, true);
				scope.$on("CadChart::ResizeWidth", (evt, width) => {
					const height = elem.children(".cad-chart").height();
					scope.chart.resize(width, height);
				});
			},
		};
	}
]);
