angular.module("eShareApp").controller("PoiAttributeKindsEditCtrl", [
	"$scope", "$state", "$stateParams", "poiAttributeKindRepository", "poiAttributeKinds",
	"messageBox", "dirtyFormTrackingService",

	function (
		$scope, $state, $stateParams, poiAttributeKindRepository, poiAttributeKinds,
		messageBox, dirtyFormTrackingService
	) {

		$scope.poiAttributeKinds = _.cloneDeep(poiAttributeKinds);
		$scope.editedKind = null;

		$scope.editPoiAttributeKind = function (poiAttributeKind) {
			poiAttributeKindRepository.get($stateParams.projectId, poiAttributeKind.id).then(data => {
				$scope.editedKind = data;
			},
			message => {
				messageBox.openError(
					"Error retrieving smart point attribute kind",
					"Error retrieving smart point attribute kind: " + message
				);
			});
		};

		$scope.closePoiAttributeKind = function () {
			$scope.editedKind = null;
		};

		$scope.addPoiAttributeKind = function () {
			$scope.editedKind = { name: "", dataType: "String" };
		};

		$scope.addEnumeration = function (editedKind) {
			if(!editedKind.enumValues) {
				editedKind.enumValues = [];
			}
			editedKind.enumValues.push({ value: "" });
			$scope.formScope.form.$setDirty();
		};

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		const updateKindsList = function () {
			$scope.formScope.form.$setPristine();
			poiAttributeKindRepository.getAll($stateParams.projectId).then(data => {
				$scope.poiAttributeKinds.length = 0;
				_.forEach(data, item => {
					$scope.poiAttributeKinds.push(item);
				});
			},
			message => {
				messageBox.openError(
					"Error updating smart point attribute kinds list",
					"Error updating smart point attribute kinds list: " + message
				);
			});
		};

		$scope.savePoiAttributeKind = function () {
			if(!$scope.editedKind) {
				return;
			}
			const kind = $scope.editedKind;
			$scope.editedKind = null;

			if(kind.id) {
				poiAttributeKindRepository
					.update($stateParams.projectId, kind)
					.then(updateKindsList, message => {
						messageBox.openError(
							"Error updating smart point attribute kind",
							"Error updating smart point attribute kind: " + message
						);
					});
			} else {
				poiAttributeKindRepository
					.save($stateParams.projectId, kind)
					.then(updateKindsList, message => {
						messageBox.openError(
							"Error saving smart point attribute kind",
							"Error saving smart point attribute kind: " + message
						);
					});
			}
		};

		$scope.removeKind = function (editedKind) {
			$scope.editedKind = null;
			const index = $scope.poiAttributeKinds.indexOf(editedKind);
			if(index >= 0) {
				$scope.poiAttributeKinds.splice(index, 1);
			}
			if(editedKind.id) {
				poiAttributeKindRepository
					.remove($stateParams.projectId, editedKind.id)
					.then(updateKindsList, message => {
						messageBox.openError(
							"Error removing smart point attribute kind",
							"Error removing smart point attribute kind: " + message
						);
					});
			}
		};

		$scope.removeEnumeration = function (editedKind, value) {
			editedKind.enumValues.splice(editedKind.enumValues.indexOf(value), 1);
			$scope.formScope.form.$setDirty();
		};

		$scope.isValid = function () {
			return $scope.editedKind
				&& $scope.editedKind.name
				&& ($scope.editedKind.dataType === "Enumeration"
					? ($scope.editedKind.enumValues
							&& $scope.editedKind.enumValues.length > 0
							&& _.every($scope.editedKind.enumValues, value => {
								return value.value !== ""
									&& _.every($scope.editedKind.enumValues, value2 => {
										return value === value2
											|| value.value.toLowerCase() !== value2.value.toLowerCase();
									});
							}))
					: true)
				&& _.every($scope.poiAttributeKinds, poiak => {
					return poiak.id === $scope.editedKind.id
						|| poiak.name.toLowerCase() !== $scope.editedKind.name.toLowerCase();
				});
		};
	}
]);
