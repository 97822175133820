angular.module("eShareApp").factory("dirtyFormTrackingService", dirtyFormTrackingService);

dirtyFormTrackingService.$inject = [
	"$rootScope", "$state", "messageBox", "notification"
];

function dirtyFormTrackingService(
	$rootScope, $state, messageBox, notification
) {
	return {
		trackForm: trackForm,
	};

	function trackForm($scope, formName, shouldReload) {
		const removeListener = $rootScope.$on("$stateChangeStart", onStateChangeStart);
		$scope.$on("$destroy", removeListener);

		function onStateChangeStart(event, toState, toParams /*, fromState, fromParams*/) {
			if(!$scope[formName]) {
				notification.error("DEBUG: dirtyFormTrackingService - form not defined");
				return;
			}
			if($scope[formName].$pristine) {
				return;
			}
			event.preventDefault();
			messageBox.openQuestion(
				"Unsaved Changes",
				"You have unsaved changes on this page.<br><br>"
				+ "Do you really want to continue without saving these changes?",
				"<i class='fa fa-check fa-fw'></i> Yes", "<i class='fa fa-times fa-fw'></i> No"
			).result.then(() => {
				removeListener();
				$state.go(toState, toParams, { reload: shouldReload });
			}, () => {
				// do nothing on cancel
			});
		}
	}
}
