angular
	.module("eShareApp")
	.controller("DocumentIndexingExportImportCtrl", DocumentIndexingExportImportCtrl);

DocumentIndexingExportImportCtrl.$inject = [
	"$state", "project", "notification", "documentConfigurationsRepository", "isExport",
	"isImport"
];

function DocumentIndexingExportImportCtrl(
	$state, project, notification, documentConfigurationsRepository, isExport,
	isImport
) {
	const vm = this;
	vm.isExport = isExport;
	vm.isImport = isImport;
	vm.serializedExportedData = "";
	vm.importResult = null;
	vm.importAll = importAll;
	vm.isInputValid = isInputValid;

	initialize();

	function initialize() {
		if(isExport) {
			vm.serializedExportedData = "Please wait...";
			documentConfigurationsRepository.exportDocumentIndexing(project.id).then(
				serializedExportedData => {
					vm.serializedExportedData = serializedExportedData;
				}
			);
		}
	}

	function importAll() {
		vm.importResult = null;
		documentConfigurationsRepository
			.importDocumentIndexing(project.id, vm.serializedExportedData)
			.then(importResult => {
				if(importResult.hasSucceeded
					&& (!importResult.warnings || importResult.warnings.length === 0)) {
					notification.success("Imported managed document indexing settings");
					$state.go("^", {}, { reload: true });
				} else {
					if(importResult.hasSucceeded) {
						notification.success(
							"Imported managed document indexing settings, with warnings"
						);
					}
					vm.importResult = importResult;
				}
			});
	}

	function isInputValid() {
		return Utilities.isJsonObject(vm.serializedExportedData);
	}
}
