function EShareException(message) {
	"use strict";
	this.name = "EShareException";
	this.message = message || "";
}

EShareException.prototype = new Error();
EShareException.prototype.constructor = EShareException;

window.Utilities = (function (window) {

	const guidRegexStr =
		"[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";
	const guidRegex = new RegExp("^" + guidRegexStr + "$");
	const escapeHtmlMap = {
		"&": "&amp;",
		"<": "&lt;",
		">": "&gt;",
		"\"": "&quot;",
		"'": "&#39;",
		"/": "&#x2F;",
	};
	let lastFakeGuidValue = 0;

	initialize();

	return {
		typeOf: typeOf,
		isInteger: isInteger,
		isNumber: isNumber,
		isString: isString,
		isObject: isObject,
		isArray: isArray,
		isFunction: isFunction,
		isNullOrUndefined: isNullOrUndefined,
		isEmptyObject: isEmptyObject,
		isJsonObject: isJsonObject,
		fromJson: fromJson,
		toJson: toJson,
		toBool: toBool,
		parseIntOrDefault: parseIntOrDefault,
		defineReadOnlyProperty: defineReadOnlyProperty,
		safelyAddEventListener: safelyAddEventListener,
		safelyRemoveEventListener: safelyRemoveEventListener,
		clearObject: clearObject,
		isAssignableTo: isAssignableTo,
		areEqual: areEqual,
		hasOwnProperties: hasOwnProperties,
		betweenIncl: betweenIncl,
		clampToRange: clampToRange,
		invokeCallback: invokeCallback,
		invokeCallbackInContext: invokeCallbackInContext,
		generateUuid: generateUuid,
		emptyGuid: emptyGuid,
		getFakeGuid: getFakeGuid,
		isGuid: isGuid,
		isEmptyGuid: isEmptyGuid,
		isNonEmptyGuid: isNonEmptyGuid,
		guidOrEmpty: guidOrEmpty,
		cloneDeepInto: cloneDeepInto,
		findAndRemoveItem: findAndRemoveItem,
		getErrorMessage: getErrorMessage,
		escapeHtml: escapeHtml,
		inflateDataTable: inflateDataTable,
		guidRegexStr: guidRegexStr,
		combineUri: combineUri,
		caseInsensitiveComparator: caseInsensitiveComparator,
		getLocalStorageItem: getLocalStorageItem,
		setLocalStorageItem: setLocalStorageItem,
		trimOrNullIfEmpty: trimOrNullIfEmpty,
		maskOutConnectionStringPassword: maskOutConnectionStringPassword,
	};

	function initialize() {
		function supplant(dataObject) {
			/* jshint validthis: true */
			const subjectString = this.toString();
			/* jshint validthis: false */
			if(!dataObject) {
				return subjectString;
			}
			return subjectString.replace(
				/\{([^{}]*)\}/g,
				(match, p1) => {
					const value = dataObject[p1];
					return typeof value === "undefined" ? match : String(value);
				}
			);
		}

		function startsWith(searchString, position) {
			/* jshint validthis: true */
			const subjectString = this.toString();
			/* jshint validthis: false */
			position = position || 0;
			return subjectString.indexOf(searchString, position) === position;
		}

		function endsWith(searchString, position) {
			/* jshint validthis: true */
			const subjectString = this.toString();
			/* jshint validthis: false */
			if(position === undefined || position > subjectString.length) {
				position = subjectString.length;
			}
			position -= searchString.length;
			const lastIndex = subjectString.indexOf(searchString, position);
			return lastIndex !== -1 && lastIndex === position;
		}

		function contains(searchString) {
			/* jshint validthis: true */
			const subjectString = this.toString();
			/* jshint validthis: false */
			return subjectString.indexOf(searchString) !== -1;
		}

		function arrayContains(value) {
			const length = this.length;
			for(let i = 0; i < length; ++i) {
				if(this[i] === value) {
					return true;
				}
			}
			return false;
		}

		function isSafeInteger(value) {
			return Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER;
		}

		if(!String.prototype.supplant) {
			defineReadOnlyProperty(String.prototype, "supplant", supplant);
		}
		if(!String.prototype.startsWith) {
			defineReadOnlyProperty(String.prototype, "startsWith", startsWith);
		}
		if(!String.prototype.endsWith) {
			defineReadOnlyProperty(String.prototype, "endsWith", endsWith);
		}
		if(!String.prototype.contains) {
			defineReadOnlyProperty(String.prototype, "contains", contains);
		}
		if(!Array.prototype.contains) {
			defineReadOnlyProperty(Array.prototype, "contains", arrayContains);
		}
		if(!Number.MAX_SAFE_INTEGER) {
			defineReadOnlyProperty(Number, "MAX_SAFE_INTEGER", 9007199254740991);
		}
		if(!Number.isInteger) {
			defineReadOnlyProperty(Number, "isInteger", isInteger);
		}
		if(!Number.isSafeInteger) {
			defineReadOnlyProperty(Number, "isSafeInteger", isSafeInteger);
		}
	}

	function typeOf(object) {
		// Code from http://javascript.crockford.com/remedial.html
		let type = typeof object;
		if(type === "object") {
			if(object) {
				if(Object.prototype.toString.call(object) === "[object Array]") {
					type = "array";
				}
			} else {
				type = "null";
			}
		}
		return type;
	}

	function isInteger(object) {
		return isNumber(object) && Math.floor(object) === object;
	}

	function isNumber(object) {
		return typeof object === "number" && isFinite(object);
	}

	function isString(object) {
		return typeof object === "string";
	}

	function isObject(object) {
		return typeOf(object) === "object";
	}

	function isArray(object) {
		return typeOf(object) === "array";
	}

	function isFunction(object) {
		return typeof object === "function";
	}

	function isNullOrUndefined(object) {
		return _.isNull(object) || _.isUndefined(object);
	}

	function isEmptyObject(object) {
		return isObject(object) && _.isEmpty(object);
	}

	function isJsonObject(value) {
		try {
			JSON.parse(value);
			return true;
		} catch(e) {
			return false;
		}
	}

	function fromJson(stringValue, defaultValue) {
		if(!Utilities.isString(stringValue)) {
			return defaultValue;
		}
		try {
			return angular.fromJson(stringValue);
		} catch(e) {
			return defaultValue;
		}
	}

	function toJson(value) {
		return angular.toJson(value);
	}

	function toBool(value) {
		return isString(value) ? value.toLowerCase() === "true" : !!value;
	}

	function parseIntOrDefault(value, defaultValue) {
		if(isInteger(value)) {
			return value;
		} else {
			value = parseInt(value, 10);
			return isNaN(value) ? defaultValue : value;
		}
	}

	function defineReadOnlyProperty(object, propertyName, value) {
		try {
			if(Object.defineProperty) {
				Object.defineProperty(object, propertyName, {
					enumerable: false,
					configurable: false,
					writable: false,
					value: value,
				});
				return;
			}
		} catch(e) {
			// do nothing
		}
		object[propertyName] = value;
	}

	function safelyAddEventListener(typeForAddEventListener, typeForAttachEvent, listener) {
		if(window.addEventListener) {
			window.addEventListener(typeForAddEventListener, listener, false);
		} else if(window.attachEvent) {
			window.attachEvent(typeForAttachEvent, listener);
		} else {
			throw new Error("Please run this system using a web browser");
		}
	}

	function safelyRemoveEventListener(typeForRemoveEventListener, typeForDetachEvent, listener) {
		if(window.addEventListener) {
			window.removeEventListener(typeForRemoveEventListener, listener, false);
		} else if(window.attachEvent) {
			window.detachEvent(typeForDetachEvent, listener);
		} else {
			throw new Error("Please run this system using a web browser");
		}
	}

	function clearObject(object) {
		if(!isNullOrUndefined(object)) {
			const properties = _.keys(object);
			for(let i = 0; i < properties.length; ++i) {
				delete object[properties[i]];
			}
		}
	}

	function isAssignableTo(target, source) {
		if(!isObject(target)) {
			throw new EShareException("The target object must be defined");
		}
		if(!isObject(source)) {
			throw new EShareException("The source object must be defined");
		}
		const propertiesTarget = _.keys(target);
		const propertiesSource = _.keys(source);
		if(propertiesTarget.length !== propertiesSource.length) {
			return false;
		}
		return _.every(propertiesSource, value => {
			return _.includes(propertiesTarget, value);
		});
	}

	function areEqual(a, b) {
		return a === b
			|| (isArray(a) && isArray(b) && areArraysEqual(a, b))
			|| (isObject(a) && isObject(b) && areObjectsEqual(a, b));
	}

	function areArraysEqual(a, b) {
		if(!isArray(a) || !isArray(b)) {
			throw new EShareException("Array equality check expects two arrays as parameters");
		}
		if(a.length !== b.length) {
			return false;
		}
		for(let i = 0; i < a.length; ++i) {
			if(!areEqual(a[i], b[i])) {
				return false;
			}
		}
		return true;
	}

	function areObjectsEqual(a, b) {
		if(!isObject(a) || !isObject(b)) {
			throw new EShareException("Object equality check expects two objects as parameters");
		}
		const propertiesA = _.keys(a);
		const propertiesB = _.keys(b);
		if(propertiesA.length !== propertiesB.length) {
			return false;
		}
		return _.every(propertiesA, propertyName => {
			if(!_.includes(propertiesB, propertyName)) {
				throw new EShareException(
					"Cannot check for equality: objectB does not have property '" + propertyName + "'"
				);
			}
			return areEqual(a[propertyName], b[propertyName]);
		});
	}

	function hasOwnProperties(object, propertyNames) {
		if(!isObject(object)) {
			throw new EShareException("The object must be defined");
		}
		return _.every(propertyNames, propertyName => {
			return object.hasOwnProperty(propertyName);
		});
	}

	function betweenIncl(x, a, b) {
		return angular.isNumber(x)
			&& angular.isNumber(a)
			&& angular.isNumber(b)
			&& Number(a) <= Number(x)
			&& Number(x) <= Number(b);
	}

	function clampToRange(x, a, b) {
		if(b < a) {
			const t = a;
			a = b;
			b = t;
		}
		return Math.min(Math.max(x, a), b);
	}

	function invokeCallback(callback) {
		if(angular.isFunction(callback)) {
			return arguments.length === 1
				? callback.call(null)
				: callback.apply(null, Array.prototype.slice.call(arguments, 1));
		}
		return undefined;
	}

	function invokeCallbackInContext(contextThis, callback) {
		if((contextThis === null || angular.isObject(contextThis)) && angular.isFunction(callback)) {
			return arguments.length === 2
				? callback.call(contextThis)
				: callback.apply(contextThis, Array.prototype.slice.call(arguments, 2));
		}
		return undefined;
	}

	// Borrowed from http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
	// Not really universally unique, but provides enough randomness in most scenarios.
	function generateUuid() {
		/* eslint-disable no-bitwise */
		let d = new Date().getTime();
		const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
			const r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
		});
		return uuid;
		/* eslint-enable no-bitwise */
	}

	function emptyGuid() {
		return "00000000-0000-0000-0000-000000000000";
	}

	function getFakeGuid() {
		++lastFakeGuidValue;
		return "00000000-0000-0000-4641-4B45" + _.padStart(lastFakeGuidValue.toString(), 8, "0");
	}

	function isGuid(value) {
		return isString(value) && guidRegex.test(value);
	}

	function isEmptyGuid(value) {
		return value === emptyGuid();
	}

	function isNonEmptyGuid(value) {
		return isGuid(value) && !isEmptyGuid(value);
	}

	function guidOrEmpty(value) {
		return isGuid(value) ? value : emptyGuid();
	}

	function cloneDeepInto(target, source) {
		if(isNullOrUndefined(target)) {
			throw new EShareException("The target object must be defined");
		}
		if(isNullOrUndefined(source)) {
			clearObject(target);
		} else {
			_.assign(target, _.cloneDeep(source));
		}
	}

	function findAndRemoveItem(array, callback) {
		const index = _.findIndex(array, callback);
		let item;
		if(index !== -1) {
			item = array[index];
			array.splice(index, 1);
		}
		return item;
	}

	function getErrorMessage(reason, doNotRecurse) {
		if(isString(reason)) {
			return reason;
		}
		if(reason) {
			const errorMessage = reason.exceptionMessage
				|| reason.ExceptionMessage
				|| reason.message
				|| reason.Message;
			if(isString(errorMessage)) {
				return errorMessage;
			}
			if(reason.status <= 0) {
				return window.eShare.connectionLostMessage;
			}
			if(reason.status === 499) {
				return window.eShare.noLicenseMessage;
			}
			if(reason.data && !doNotRecurse) {
				return getErrorMessage(reason.data, true);
			}
		}
		return "Unknown error (" + JSON.stringify(reason) + ")";
	}

	function escapeHtml(string) {
		return String(string)
			// eslint-disable-next-line no-useless-escape
			.replace(/[&<>"'\/]/g, s => {
				return escapeHtmlMap[s];
			});
	}

	function inflateDataTable(dataTableDto) {
		const data = dataTableDto.data;
		const columns = dataTableDto.columnDefinitionIds;
		const columnsCount = columns.length;
		const rowsCount = data.length;
		const rows = [];
		rows.length = rowsCount;
		for(let rowIndex = 0; rowIndex < rowsCount; ++rowIndex) {
			const dataRow = data[rowIndex];
			const row = {};
			for(let columnIndex = 0; columnIndex < columnsCount; ++columnIndex) {
				row[columns[columnIndex]] = dataRow[columnIndex];
			}
			rows[rowIndex] = row;
		}
		const dataTable = {
			name: dataTableDto.name,
			rows: rows,
		};
		return dataTable;
	}

	function combineUri(prefix, suffix) {
		prefix = (isString(prefix) ? prefix : "").trim();
		suffix = (isString(suffix) ? suffix : "").trim();
		let uri = prefix;
		if(!prefix.endsWith("/") && !suffix.startsWith("/")) {
			uri += "/";
		}
		uri += suffix;
		return uri;
	}

	function caseInsensitiveComparator(propertyName) {
		function comparator(a, b) {
			const va = (a[propertyName] || "").toUpperCase();
			const vb = (b[propertyName] || "").toUpperCase();
			return va < vb ? -1 : (va > vb ? 1 : 0);
		}

		return comparator;
	}

	function getLocalStorageItem(key, defaultValue, isPrimitive) {
		if(isNullOrUndefined(defaultValue)) {
			defaultValue = null;
		}
		if(isPrimitive) {
			if(defaultValue !== null) {
				if(!_.isString(defaultValue)
					&& !_.isNumber(defaultValue)
					&& !_.isBoolean(defaultValue)) {
					throw new Error("defaultValue must be string, number, or boolean");
				}
				defaultValue = "" + defaultValue;
			}
		} else {
			if(defaultValue !== null && !isObject(defaultValue)) {
				throw new Error("defaultValue must be an object");
			}
		}
		const item = localStorage.getItem(key);
		if(isString(item)) {
			if(isPrimitive) {
				return item;
			}
			try {
				const object = JSON.parse(item);
				return isObject(object) ? object : defaultValue;
			} catch(e) {
				// ignore
			}
		}
		return defaultValue;
	}

	function setLocalStorageItem(key, value) {
		if(isNullOrUndefined(value)) {
			localStorage.removeItem(key);
			return;
		}
		const isPrimitive = _.isString(value) || _.isNumber(value) || _.isBoolean(value);
		const item = isPrimitive
			? "" + value
			: JSON.stringify(value);
		localStorage.setItem(key, item);
	}

	function trimOrNullIfEmpty(value) {
		value = isString(value) ? value.trim() : null;
		return value === "" ? null : value;
	}

	function maskOutConnectionStringPassword(str) {
		const regex = /((^|;)\s*)(password|pwd|pass)\s*=\s*(("[^"]*"|'[^']*'|[^;'"]*)*(?=$|;))/gi;
		const matches = str.match(regex);
		if(matches) {
			// because lookbehind assertions and .replaceAll are not supported in IE11:
			matches.forEach(match => {
				const replacement = match.substring(0, match.indexOf("=") + 1) + "*****";
				str = str.split(match).join(replacement);
			});
		}
		return str;
	}
})(window);