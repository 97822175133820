angular.module("eShareApp").controller("UserProfileCtrl", [
	"$rootScope", "$scope", "$state", "user", "project", "canHandleMarkups", "canAdministerProject",
	"documentDataSourcesExist", "maps",

	function (
		$rootScope, $scope, $state, user, project, canHandleMarkups, canAdministerProject,
		documentDataSourcesExist, maps
	) {

		$scope.project = project;
		$scope.user = _.cloneDeep(user);
		$scope.canSeeMarkups = canHandleMarkups ? canHandleMarkups.canUserSeeMarkups : false;
		$scope.canAdministerProject = canAdministerProject;
		$scope.documentDataSourcesExist = documentDataSourcesExist.documentDataSourcesExist;
		$scope.mapsExist = maps.length > 0;
		$scope.shouldHideSearch = $scope.project === null;

	}
]);
