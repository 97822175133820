angular.module("eShare.modelImporters").component("modelImporterStatus", {
	template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\modelImporters\\modelImporterStatus.component.html'),
	controller: ModelImporterStatusController,
	bindings: {
		status: "<",
	},
});

function ModelImporterStatusController(/*$scope, $element, $attrs*/) {
	const ctrl = this;

	let isUserControlled = false;

	ctrl.$onInit = onInit;
	ctrl.$onChanges = onChanges;

	ctrl.isActive = false;
	ctrl.statusType = "I";
	ctrl.statusIcon = messageTypeToIcon("I");
	ctrl.statusClass = lastMessageTypeToCssTextClass("I");
	ctrl.statusMessage = "";
	ctrl.isOpen = false;

	ctrl.toggleOpen = toggleOpen;
	ctrl.messageTypeToDisplayName = messageTypeToDisplayName;
	ctrl.messageTypeToCssTextClass = messageTypeToCssTextClass;
	ctrl.messageTypeToIcon = messageTypeToIcon;

	function onInit() {
		if(ctrl.status) {
			onStatusChanged(ctrl.status);
		}
	}

	function onChanges(changes) {
		if(changes.status && changes.status.currentValue) {
			onStatusChanged(changes.status.currentValue);
		}
	}

	function toggleOpen() {
		isUserControlled = true;
		ctrl.isOpen = !ctrl.isOpen;
	}

	function onStatusChanged(status) {
		console.dir(status);
		const messages = status.messages;
		ctrl.isActive = status.isImporting || !!status.lastMessage;
		if(messages && messages.length > 0) {
			let maxLevel = 0;
			for(let i = 0; i < messages.length; ++i) {
				const level = messageTypeToLevel(messages[i].type);
				maxLevel = Math.max(maxLevel, level);
			}
			const statusType = levelToMessageType(maxLevel);
			if(statusType !== ctrl.statusType) {
				if(statusType === "E" && ctrl.statusType === "I" && !ctrl.isOpen && !isUserControlled) {
					ctrl.isOpen = true;
				}
				ctrl.statusType = statusType;
				ctrl.statusIcon = messageTypeToIcon(statusType);
				ctrl.statusClass = lastMessageTypeToCssTextClass(statusType);
			}
			ctrl.statusMessage = status.lastMessage.text;
		}
	}

	function levelToMessageType(level) {
		switch(level) {
		case 1:
			return "W";
		case 2:
			return "E";
		default:
			return "I";
		}
	}

	function messageTypeToLevel(messageType) {
		switch(messageType) {
		case "W":
			return 1;
		case "E":
			return 2;
		default:
			return 0;
		}
	}

	function messageTypeToDisplayName(messageType) {
		switch(messageType) {
		case "W":
			return "WARNING";
		case "E":
			return "ERROR";
		default:
			return "INFO";
		}
	}

	function messageTypeToCssTextClass(messageType) {
		switch(messageType) {
		case "W":
			return "text-warning";
		case "E":
			return "text-danger";
		default:
			return "text-dim";
		}
	}

	function messageTypeToIcon(messageType) {
		switch(messageType) {
		case "W":
			return "fa-warning";
		case "E":
			return "fa-times-circle";
		default:
			return "fa-check-circle";
		}

	}

	function lastMessageTypeToCssTextClass(messageType) {
		switch(messageType) {
		case "W":
			return "text-warning";
		case "E":
			return "text-danger";
		default:
			return "text-success";
		}
	}
}
