angular.module("eShareApp").factory("integrations", integrations);

window.eShare = window.eShare || {};
window.eShare.integrations = window.eShare.integrations || {};

integrations.$inject = [];

function integrations() {

	initialize();

	return {
		isActive: !!(window.eShare.integrations.isActive),
		getOtherSystemName: getOtherSystemName,
		getOtherSystemCommands: getOtherSystemCommands,
		setProject: setProject,
		sendCommand: sendCommand,
	};

	function initialize() {
		setProject("");
	}

	function getOtherSystemName() {
		const result = (
			Utilities.isFunction(window.eShare.integrations.getOtherSystemName)
			&& window.eShare.integrations.getOtherSystemName()
		) || "NOT SET";
		return result;
	}

	function getOtherSystemCommands() {
		const commands = window.eShare.integrations.commands;
		const result = Utilities.isArray(commands) ? commands : [];
		return result;
	}

	function setProject(projectName) {
		// Does nothing?

		// eslint-disable-next-line no-unused-vars
		const currentProjectName = projectName;
	}

	function sendCommand(command, parameters, tagName, projectName) {
		if(!window.eShare.integrations.isActive) {
			throw new EShareException("eShare Integrations are not active");
		}
		const externalCommands = getOtherSystemCommands();
		for(let i = 0; i < externalCommands.length; ++i) {
			if(externalCommands[i].command === command) {
				const params = {
					project: projectName,
					tag: tagName,
					key: parameters,
					context: externalCommands[i].context,
				};
				window.eShare.integrations.sendCommand(command, params);
			}
		}
	}
}
