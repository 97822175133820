angular.module("eShareApp").controller("UserViewCtrl", [
	"$scope", "user",

	function ($scope, user) {

		$scope.user = user;
		if($scope.user.deactivatedAt) {
			$scope.user.deactivatedAt = moment.utc($scope.user.deactivatedAt).local();
		}

	}
]);
