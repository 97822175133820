angular.module("eShareApp").component("cadMarkup", {
	template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadMarkup.html'),
	controller: CadMarkupController,
	bindings: {
		markup: "<",
		onEdit: "&",
		onDelete: "&",
	},
});

CadMarkupController.$inject = ["messageBox"];

function CadMarkupController(messageBox) {
	const ctrl = this;
	ctrl.$onInit = $onInit;
	ctrl.edit = edit;
	ctrl.remove = remove;

	function $onInit() {
	}

	function edit() {
		const onEdit = ctrl.onEdit();
		if(onEdit) {
			onEdit(ctrl.markup);
		}
	}

	function remove() {
		const mbox = messageBox.openQuestion(
			"Remove markup?",
			"Are you sure you want to remove the markup "
			+ ctrl.markup.title
			+ "? This cannot be undone.",
			"Remove", "Cancel"
		);
		mbox.result.then(() => {
			const onDelete = ctrl.onDelete();
			if(onDelete) {
				onDelete(ctrl.markup);
			}
		});
	}
}