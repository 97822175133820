angular.module("eShareApp").controller("ModelHierarchiesCtrl", [
	"$state", "$scope", "$stateParams", "project", "notification", "dirtyFormTrackingService",
	"modelHierarchies", "originalHierarchies", "modelHierarchiesRepository",

	function (
		$state, $scope, $stateParams, project, notification, dirtyFormTrackingService,
		modelHierarchies, originalModelHierarchies, modelHierarchiesRepository
	) {

		$scope.hierarchies = modelHierarchies.hierarchyDefinitions;
		const originalHierarchies = originalModelHierarchies.hierarchyDefinitions;
		for(let i = 0; i < $scope.hierarchies.length; i++) {
			const hierarchy = $scope.hierarchies[i];
			const matchingOriginal = _.find(originalHierarchies, { "name": hierarchy.originalName });
			hierarchy.isNew = matchingOriginal === undefined;
			hierarchy.isModifiedFlag = !hierarchy.isNew && hierarchy.isModified;
		}
		combineWithOriginalHierarchies(originalHierarchies);

		$scope.addModelHierarchy = function () {
			$state.go(".editHierarchy", {
				projectId: project.id,
				hierarchyIndex: -1,
			});
		};

		$scope.selectOptions = {
			filter: "contains",
			placeholder: "Select one or more attributes",
		};

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		$scope.isValid = function () {
			return !_.some($scope.hierarchies, h1 => {
				return _.some($scope.hierarchies, h2 => {
					return h1 !== h2
						&& h1.name
						&& h2.name
						&& h1.name.toLowerCase() === h2.name.toLowerCase();
				});
			});
		};

		$scope.getOriginalHierarchies = function () {
			modelHierarchiesRepository.getOriginal($stateParams.projectId).then(data => {
				$scope.hierarchies = data.hierarchyDefinitions;
				$scope.formScope.form.$setPristine();
			}, error => {
				notification.error(error);
			});
		};

		function combineWithOriginalHierarchies(modelHierarchies) {
			for(let j = 0; j < modelHierarchies.length; j++) {
				const modelHierarchy = modelHierarchies[j];
				if(!_.some($scope.hierarchies, { "originalName": modelHierarchy.originalName })) {
					ensureUniqueName(modelHierarchy);
					$scope.hierarchies.splice(j, 0, modelHierarchy);
				}
			}
		}

		function ensureUniqueName(modelHierarchy) {
			if(!_.some($scope.hierarchies, { "name": modelHierarchy.name })) {
				return;
			}
			let index = 1;
			const originalName = modelHierarchy.name;
			// eslint-disable-next-line no-constant-condition
			while(true) {
				const newName = originalName + " (" + index + ")";
				if(!_.some($scope.hierarchies, { "name": newName })) {
					modelHierarchy.name = newName;
					return;
				}
				index++;
			}
		}

		$scope.removeHierarchy = function (index) {
			$scope.hierarchies.splice(index, 1);
			$scope.formScope.form.$setDirty();
		};

		$scope.toggleHierarchy = function (index) {
			const hierarchy = $scope.hierarchies[index];
			hierarchy.isActive = !hierarchy.isActive;
			$scope.formScope.form.$setDirty();
		};

		$scope.resetHierarchy = function (index) {
			modelHierarchiesRepository.getOriginal($stateParams.projectId).then(data => {
				const hierarchy = $scope.hierarchies[index];
				const originalHierarchy = _.find(data.hierarchyDefinitions, {
					"name": hierarchy.originalName,
				});
				$scope.hierarchies.splice(index, 1, originalHierarchy);
				$scope.formScope.form.$setDirty();
			}, error => {
				notification.error(error);
			});
		};

		$scope.saveChanges = function () {
			modelHierarchiesRepository.save($stateParams.projectId, $scope.hierarchies).then(() => {
				$scope.formScope.form && $scope.formScope.form.$setPristine();
				$state.go("^", {}, { reload: true });
			}, error => {
				notification.error(error);
			});
		};
	}
]);
