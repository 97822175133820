angular.module("eShareApp").factory("coordinateSystemRepository", coordinateSystemRepository);

coordinateSystemRepository.$inject = ["repositoryBase"];

function coordinateSystemRepository(repositoryBase) {
	const baseApi = "api/project/{projectId}/CoordinateSystems";

	return {
		getCoordinateSystems: getCoordinateSystems,
	};

	function getCoordinateSystems(projectId) {
		return repositoryBase.get(baseApi, { projectId: projectId });
	}
}
