angular.module("eShareApp").factory("savedSearchRepository", [
	"repositoryBase", "notification",
	function (repositoryBase, notification) {

		const baseApi = "/api/project/{projectId}";
		const savedSearchApi = baseApi + "/SavedSearches";
		const savedSearchIdApi = baseApi + "/SavedSearches/{searchId}";

		function getAll(projectId) {
			return repositoryBase.get(savedSearchApi, { projectId: projectId }).then(data => {
				return data;
			}, error => {
				notification.error(error);
			});
		}

		function add(projectId, search) {
			return repositoryBase.post(savedSearchApi, { projectId: projectId }, search);
		}

		function remove(projectId, searchId) {
			return repositoryBase.remove(
				savedSearchIdApi,
				{ projectId: projectId, searchId: searchId }
			);
		}

		return {
			getAll: getAll,
			add: add,
			remove: remove,
		};
	}
]);