angular.module("eShareApp").factory("markupsRepository", markupsRepository);

markupsRepository.$inject = ["$http", "repositoryBase"];

function markupsRepository($http, repositoryBase) {
	return {
		getForEdit: getForEdit,
		saveEdited: saveEdited,
		getLatest: getLatest,
		getLatestForUser: getLatestForUser,
		remove: remove,
		getKind: getKind,
	};

	function getLatest(projectId) {
		return repositoryBase.get("/api/project/{projectId}/markups/latest", {
			projectId: projectId,
		});
	}

	function getLatestForUser(projectId, userId) {
		return repositoryBase.get("/api/project/{projectId}/markups/latestForUser?userId={userId}", {
			projectId: projectId,
			userId: userId,
		});
	}

	function getForEdit(projectId, markupId) {
		return repositoryBase.post("/api/project/{projectId}/markups/exportForEdit/{markupId}", {
			projectId: projectId,
			markupId: markupId,
		});
	}

	function saveEdited(projectId, ebxFileContents) {
		return repositoryBase.post("/api/project/{projectId}/markups/saveEdited", {
			projectId: projectId,
		}, ebxFileContents);
	}

	function remove(projectId, pointId) {
		return repositoryBase
			.remove("api/project/{projectId}/PointsOfInterest/DeletePoint/{pointId}", {
				projectId: projectId,
				pointId: pointId,
			});
	}

	function getKind(projectId, kindId) {
		return repositoryBase.get("/api/project/{projectId}/markups/kind/{kindId}", {
			projectId: projectId,
			kindId: kindId,
		});
	}
}