angular.module("eShareApp").controller("PoiFileImportCtrl", PoiFileImportCtrl);

PoiFileImportCtrl.$inject = [
	"$state", "$scope", "messageBox", "project", "$http", "smartPointTypes", "Upload",
	"notification", "pointOfInterestKindRepository", "$timeout"
];

function PoiFileImportCtrl(
	$state, $scope, messageBox, project, $http, smartPointTypes, upload,
	notification, pointOfInterestKindRepository, $timeout
) {
	$scope.vm = {};
	const vm = $scope.vm;
	vm.project = project;
	vm.excelConfig = {
		excelId: null,
		smartPointTypeId: parseInt($state.params.smartPointTypeId, 10),
		worksheetName: "Points",
		firstDataRow: 1,
		xCoordinateColumn: -1,
		yCoordinateColumn: -1,
		zCoordinateColumn: -1,
		coordinateScalingFactor: 1,
		nameColumn: -1,
		externalIdColumn: -1,
		overwriteExistingIdenticalPoints: true,
		attributeColumns: {},
		endRowsToSkip: 0,
		hasHeaderRow: true,
	};
	vm.selectedSmartPointType = null;
	vm.smartPointTypes = smartPointTypes.filter(type => {
		return !type.isMarkup;
	});
	vm.uploadedFileName = null;
	const supportedFileTypes = {
		".xls": true,
		".xlsx": true,
		".xlsm": true,
	};
	vm.worksheetOptions = [];
	vm.columnOptions = [];
	vm.selectedWorksheet = null;

	function initialize() {
		if(vm.excelConfig.smartPointTypeId === -1) {
			vm.excelConfig.smartPointTypeId = vm.smartPointTypes.length > 0
				? vm.smartPointTypes[0].id
				: -1;
		}
		vm.loadSmartPointTypeInfo(vm.excelConfig.smartPointTypeId);
	}

	vm.getSmartPointTypeById = function (id) {
		return _.find(vm.smartPointTypes, { id: id });
	};

	vm.excelFileSelected = function (files/*, $event*/) {
		const file = files ? files[0] : null;
		if(!file) {
			return;
		}
		const fileName = (file.name || "").toLowerCase();
		const dotIndex = fileName.lastIndexOf(".");
		const fileType = dotIndex !== -1 ? fileName.substring(dotIndex) : "";
		if(!supportedFileTypes.hasOwnProperty(fileType)) {
			notification.error("The file must be of a valid type");
			return;
		}
		if(!window.eShare.integratedSecurity) {
			$http.post("api/OneTimeToken/Create")
				.then(
					tokenData => {
						uploadExcel(file, fileType, tokenData.data.token);
					},
					() => {
						notification.error("Error getting one-time authorization token");
					}
				);
		} else {
			uploadExcel(file, fileType);
		}

	};

	function uploadExcel(file, fileType, oneTimeToken) {
		let url = oneTimeToken
			? "api/project/{projectId}/SmartPoints/Excel/Upload?fileType={fileType}"
			+ "&token={oneTimeToken}"

			: "api/project/{projectId}/SmartPoints/Excel/Upload?fileType={fileType}";
		url = url.supplant({
			projectId: project.id,
			oneTimeToken: oneTimeToken,
			fileType: fileType,
		});
		const uploadSession = upload.upload({
			url: url,
			data: {
				file: file,
			},
		});
		uploadSession.then(onSuccess, onError, onProgress);
		vm.isUploading = true;

		function onSuccess(response) {
			vm.uploadedFileName = file.name;
			vm.worksheetOptions = response.data.worksheets;
			vm.excelConfig.excelId = response.data.excelId;
			if(vm.excelConfig.worksheetName != null && response.data.worksheets.length > 0) {
				const hasName = _.find(response.data.worksheets, {
					name: vm.excelConfig.worksheetName,
				}) != null;
				if(!hasName) {
					vm.excelConfig.worksheetName = response.data.worksheets[0].name;
				}
			}
			vm.isUploading = false;
			vm.previewAction();
		}

		function onError(/*reason*/) {
			vm.isUploading = false;
		}

		function onProgress(/*event*/) {
			// do nothing
		}
	}

	function updateExcelData(data) {
		vm.excelData = data;
		if(vm.excelData && vm.excelData.headerRow && vm.excelData.headerRow.data.length > 0) {
			const headers = vm.excelData.headerRow.data;
			const lowerCaseHeaders = headers.map(item => {
				return (item || "") && item.toLowerCase();
			});
			vm.columnOptions = vm.excelData.headerRow.data.map((item, index) => {
				return {
					displayName: item || "",
					index: index,
				};
			});
			vm.excelConfig.nameColumn = vm.excelConfig.nameColumn === -1
				? lowerCaseHeaders.indexOf("point id")
				: vm.excelConfig.nameColumn;
			vm.excelConfig.nameColumn = vm.excelConfig.nameColumn === -1
				? lowerCaseHeaders.indexOf("name")
				: vm.excelConfig.nameColumn;
			vm.excelConfig.nameColumn = vm.excelConfig.nameColumn === -1 && lowerCaseHeaders.length > 0
				? 0
				: vm.excelConfig.nameColumn;

			vm.excelConfig.externalIdColumn = vm.excelConfig.externalIdColumn === -1
				? lowerCaseHeaders.indexOf("external id")
				: vm.excelConfig.externalIdColumn;
			vm.excelConfig.externalIdColumn =
				vm.excelConfig.externalIdColumn === -1 && lowerCaseHeaders.length > 0
					? 0
					: vm.excelConfig.externalIdColumn;

			vm.excelConfig.xCoordinateColumn = vm.excelConfig.xCoordinateColumn === -1
				? lowerCaseHeaders.indexOf("x")
				: vm.excelConfig.xCoordinateColumn;
			vm.excelConfig.xCoordinateColumn =
				vm.excelConfig.xCoordinateColumn === -1 && lowerCaseHeaders.length > 0
					? 0
					: vm.excelConfig.xCoordinateColumn;

			vm.excelConfig.yCoordinateColumn = vm.excelConfig.yCoordinateColumn === -1
				? lowerCaseHeaders.indexOf("y")
				: vm.excelConfig.yCoordinateColumn;
			vm.excelConfig.yCoordinateColumn =
				vm.excelConfig.yCoordinateColumn === -1 && lowerCaseHeaders.length > 0
					? 0
					: vm.excelConfig.yCoordinateColumn;

			vm.excelConfig.zCoordinateColumn = vm.excelConfig.zCoordinateColumn === -1
				? lowerCaseHeaders.indexOf("z")
				: vm.excelConfig.zCoordinateColumn;
			vm.excelConfig.zCoordinateColumn =
				vm.excelConfig.zCoordinateColumn === -1 && lowerCaseHeaders.length > 0
					? 0
					: vm.excelConfig.zCoordinateColumn;

			if(vm.selectedSmartPointType
					&& vm.selectedSmartPointType.attributeKinds
					&& vm.selectedSmartPointType.attributeKinds.length > 0) {
				setSmartPointAttributes();
			}
		} else {
			const length = (
				vm.excelData
				&& vm.excelData.dataRows
				&& vm.excelData.dataRows[0]
				&& vm.excelData.dataRows[0].data
			)
				? vm.excelData.dataRows[0].data.length
				: 10;
			vm.columnOptions = Array.apply(null, Array(length)).map((item, index) => {
				return {
					displayName: intToColumn(index),
					index: index,
				};
			});
		}
		vm.optionalColumnOptions = vm.columnOptions.slice();
		vm.optionalColumnOptions.push({
			displayName: "None",
			index: -1,
		});
	}

	function intToColumn(int) {
		let output = "";
		const base26 = int.toString(26);
		for(let i = 0; i < base26.length; i++) {
			let offset = 64;
			if(i === base26.length - 1 || i !== 0) {
				offset = 65;
			}
			output += String.fromCharCode(parseInt(base26.charAt(i), 27) + offset);
		}
		return output;
	}

	vm.cancel = function () {
		// check for changes?
		$timeout(() => {
			$state.go("^", {}, { reload: true });
		}, 0);
	};

	vm.save = function () {
		pointOfInterestKindRepository.importExcelData(project.id, vm.excelConfig).then(() => {
			notification.success("Smart Point import successful");
			$state.go("^", {}, { reload: true });
		}, err => {
			notification.error(err);
		});
	};

	vm.previewAction = function () {
		vm.isReloading = true;
		pointOfInterestKindRepository.previewExcelData(project.id, vm.excelConfig).then(response => {
			updateExcelData(response);
			vm.isReloading = false;
		}, (/*err*/) => {
			vm.isReloading = false;
		});
	};

	vm.loadSmartPointTypeInfo = function (typeId) {
		vm.selectedSmartPointType = vm.getSmartPointTypeById(typeId);
		if(typeId === -1
				|| typeId == null
				|| vm.selectedSmartPointType
				&& vm.selectedSmartPointType.attributeKinds.length > 0) {
			return;
		}
		pointOfInterestKindRepository.get(project.id, typeId).then(response => {
			const index = _.findIndex(vm.smartPointTypes, { id: typeId });
			vm.smartPointTypes[index] = response;
			vm.selectedSmartPointType = vm.getSmartPointTypeById(typeId);
			if(vm.columnOptions) {
				setSmartPointAttributes();
			}
		});
	};

	function setSmartPointAttributes() {
		if(!vm.columnOptions || vm.columnOptions.length === 0) {
			return;
		}
		const lowerOptions = vm.columnOptions.map(item => {
			return { displayName: item.displayName.toLowerCase(), index: item.index };
		});
		vm.selectedSmartPointType.attributeKinds.forEach(kind => {
			if(vm.excelConfig.attributeColumns[kind.name] == null
					|| vm.excelConfig.attributeColumns[kind.name] === -1) {
				vm.excelConfig.attributeColumns[kind.name] = _.findIndex(lowerOptions, {
					displayName: kind.name.toLowerCase(),
				});
			}
		});
	}

	vm.previewExcelSmartPoints = function () {
		vm.isReloading = true;
		pointOfInterestKindRepository.previewExcelSmartPoints(project.id, vm.excelConfig)
			.then(result => {
				vm.resultPoints = result.smartPoints || [];
				vm.resultErrors = result.errors || [];
				vm.resultAttributes = vm.resultPoints.map(point => {
					const attrs = [];
					_.forOwn(point.attributes, (value, key) => {
						attrs.push({ key: key, value: value });
					});
					return attrs;
				});
				vm.testedConfiguration = angular.toJson(vm.excelConfig);
				vm.isReloading = false;
			}, (/*err*/) => {
				vm.isReloading = false;
			});
	};

	vm.isConfigurationTested = function () {
		return vm.testedConfiguration != null
			&& vm.testedConfiguration === angular.toJson(vm.excelConfig);
	};

	vm.validateConfiguration = function () {
		return typeof vm.excelConfig.hasHeaderRow === "boolean"
				&& vm.excelConfig.nameColumn >= 0
				&& vm.excelConfig.attributeColumns != null
				&& vm.excelConfig.externalIdColumn >= 0
				&& vm.excelConfig.excelId != null
				&& vm.excelConfig.coordinateScalingFactor > 0
				&& vm.excelConfig.xCoordinateColumn >= 0
				&& vm.excelConfig.yCoordinateColumn >= 0
				&& vm.excelConfig.zCoordinateColumn >= 0
				&& vm.excelConfig.smartPointTypeId >= 0
				&& vm.excelConfig.worksheetName != null
				&& vm.excelConfig.firstDataRow > 0
				&& typeof vm.excelConfig.overwriteExistingIdenticalPoints === "boolean"
				&& vm.excelConfig.endRowsToSkip >= 0;
	};

	initialize();
}
