angular.module("eShareApp").factory("logRepository", logRepository);

logRepository.$inject = ["repositoryBase"];

function logRepository(repositoryBase) {
	const dates = "api/misc/getLogFileDates";
	const text = "api/misc/getLogText/";
	const machineName = "api/misc/getServerMachineName";
	const loggingLevelApi = "api/misc/loggingLevel";
	const setLoggingLevelApi = loggingLevelApi + "?logLevel={logLevel}";
	return {
		getLogFileDates: getLogFileDates,
		getLogText: getLogText,
		getServerMachineName: getServerMachineName,
		getLoggingLevel: getLoggingLevel,
		setLoggingLevel: setLoggingLevel,
	};

	function getLogFileDates() {
		return repositoryBase.get(dates);
	}

	function getLogText(file) {
		return repositoryBase.get(text + file);
	}

	function getServerMachineName() {
		return repositoryBase.get(machineName);
	}

	function getLoggingLevel() {
		return repositoryBase.get(loggingLevelApi);
	}

	function setLoggingLevel(logLevel) {
		return repositoryBase.post(setLoggingLevelApi, { logLevel: logLevel });
	}
}