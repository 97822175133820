angular.module("eShare.documentViewer").directive("cadDocumentViewer", cadDocumentViewer);

cadDocumentViewer.$inject = [];

function cadDocumentViewer() {
	return {
		restrict: "E",
		replace: true,
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadDocumentViewer.directive.html'),
		scope: {},
		bindToController: {
			projectId: "<",
			viewerId: "<",
			pageNumber: "<",
			documentDivId: "<",
			document: "<",
			docInfo: "<",
			onDocInfoLoaded: "&",
			onToggleSpinner: "&",
			control: "<",
			loadControl: "<",
			downloadOriginal: "&",
			onLoad: "&",
			onOpenNewDocument: "&",
			onPointLocationChanged: "&",
			metadataExtractionRules: "<",
		},
		controllerAs: "vm",
		controller: cadDocumentViewerController,
	};
}

cadDocumentViewerController.$inject = [
	"$scope", "$element", "$state", "$timeout", "documentViewerRepository", "messageBox", "$compile"
];

function cadDocumentViewerController(
	$scope, $element, $state, $timeout, documentViewerRepository, messageBox, $compile
) {
	let vm = this;

	let focusRequest = null;
	let doNotResetFocusRequest = false;

	let zoomMemory;

	const linkMenuTable = document.createElement("table");
	let isLinkMenuOpen = false;
	let shouldPointMenuBeClosed = false;

	const removeControlWatch = $scope.$watch("vm.control", setupControl);
	const removeLoadControlWatch = $scope.$watch("vm.loadControl", setupControl);
	const removeRepositionWatch = $scope.$watch("vm.repositionElement", setupControl);
	const removePointWatch = $scope.$watch("vm.setupMapPointIcons", setupControl);

	let shouldUpdateMetadataAreas = false;
	let ruleIdCounter = 0;

	let isContextMenuOpen = false;

	if(setupControl) {
		setupControl();
	}

	$scope.$on("$destroy", cleanUp);

	const contextMenuListener = function (e) {
		if(!vm.coordinateInformation || vm.document.configurationMode || isLinkMenuOpen) {
			return;
		}
		if(isContextMenuOpen) {
			closeContextMenu();
		}
		openContextMenu(e);
		e.preventDefault();
	};

	function openContextMenu(event) {
		if(vm.viewerElement) {
			const canvasOffset = vm.canvas.offset();
			const mouseRelativeX = event.pageX - canvasOffset.left;
			const mouseRelativeY = event.pageY - canvasOffset.top;
			const contextMenuDiv = document.createElement("div");
			contextMenuDiv.id = "contextMenuDiv";
			contextMenuDiv.style.minWidth = "200px";
			contextMenuDiv.style.position = "absolute";

			const imagingHelper = vm.getImagingHelper();
			const logicalX = imagingHelper.physicalToLogicalX(mouseRelativeX);
			const logicalY = imagingHelper.physicalToLogicalY(mouseRelativeY);
			const offsetX = vm.coordinateInformation.xMin;
			const maxX = vm.coordinateInformation.xMax;
			const offsetY = vm.coordinateInformation.yMin;
			const maxY = vm.coordinateInformation.yMax;
			const multiplierX = maxX - offsetX;
			const multiplierY = maxY - offsetY;
			const modelX = Math.round(logicalX * multiplierX + offsetX);
			const modelY = Math.round((1 - logicalY) * multiplierY + offsetY);
			const modelZ = vm.document.zfromInt;

			const contextMenu = angular.element("<cad-document-viewer-context-menu "
					+ "model-x=\"" + modelX + "\" model-y=\"" + modelY + "\" model-z=\"" + modelZ + "\">"
					+ "</cad-document-viewer-context-menu>");
			const contextMenuDivElement = angular.element(contextMenuDiv);
			contextMenuDivElement.append(contextMenu);
			$compile(contextMenu)($scope);

			if(vm.documentDiv && vm.documentDiv[0]) {
				vm.documentDiv[0].appendChild(contextMenuDiv);

				// Small delay so that the element is loaded in DOM to get its actual size
				$timeout(() => {
					const isTooCloseToCanvasRightSide =
					mouseRelativeX + contextMenuDiv.offsetWidth > vm.documentDiv[0].offsetWidth;
					const isTooCloseToCanvasBottom =
					mouseRelativeY + contextMenuDiv.offsetHeight > vm.documentDiv[0].offsetHeight;
					contextMenuDiv.style.left = (isTooCloseToCanvasRightSide
						? mouseRelativeX - contextMenuDiv.offsetWidth
						: mouseRelativeX) + "px";
					contextMenuDiv.style.top = (isTooCloseToCanvasBottom
						? mouseRelativeY - contextMenuDiv.offsetHeight
						: mouseRelativeY) + "px";
				}, 25);

				window.addEventListener("mousedown", closeContextMenu);
				const viewer = getViewer();
				if(viewer != null) {
					viewer.addHandler("canvas-press", closeContextMenu);
					viewer.setMouseNavEnabled(false);
				}
				isContextMenuOpen = true;
			}
		}
	}

	function closeContextMenu(event) {
		const contextMenuDiv = document.getElementById("contextMenuDiv");
		if(contextMenuDiv) {
			if(event) {
				const pageX = event.originalEvent ? event.originalEvent.pageX : event.pageX;
				const pageY = event.originalEvent ? event.originalEvent.pageY : event.pageY;
				const co = vm.canvas.offset();
				const mouseX = pageX - co.left;
				const mouseY = pageY - co.top;
				const left = parseInt(contextMenuDiv.style.left, 10);
				const top = parseInt(contextMenuDiv.style.top, 10);
				if(mouseX < left || mouseX > left + contextMenuDiv.offsetWidth || mouseY < top
						|| mouseY > top + contextMenuDiv.offsetHeight) {
					contextMenuDiv.parentNode.removeChild(contextMenuDiv);
					window.removeEventListener("mousedown", closeContextMenu);
					const viewer = getViewer();
					if(viewer != null) {
						viewer.removeHandler("canvas-press", closeContextMenu);
						viewer.setMouseNavEnabled(true);
					}
					isContextMenuOpen = false;
				}
			} else {
				contextMenuDiv.parentNode.removeChild(contextMenuDiv);
				window.removeEventListener("mousedown", closeContextMenu);
				const viewer = getViewer();
				if(viewer != null) {
					viewer.removeHandler("canvas-press", closeContextMenu);
					viewer.setMouseNavEnabled(true);
				}
				isContextMenuOpen = false;
			}
		}
	}

	function cleanUp() {
		destroyViewerAndRemoveHandlers();

		removeControlWatch();
		removeLoadControlWatch();
		removeRepositionWatch();
		removePointWatch();

		vm.docInfo = null;
		vm.loadControl = null;
		vm.control = null;
		vm.document = null;
		vm.coordinateInformation = null;

		if(vm.canvas) {
			vm.canvas.remove();
			vm.canvas = null;
		}
		if(vm.viewerElement) {
			vm.viewerElement.remove();
			vm.viewerElement = null;
		}
		if(vm.documentElement) {
			vm.documentElement.remove();
			vm.documentElement = null;
		}
		if(vm.documentDiv) {
			vm.documentDiv.remove();
			vm.documentDiv = null;
		}

		vm = null;
		$scope = null;
	}

	const defaultIconSize = 30;

	// Divs that are used to mark points in the viewer
	const crossDivHorizontal = document.createElement("div");
	crossDivHorizontal.style.width = "100%";
	crossDivHorizontal.style.height = "2px";
	crossDivHorizontal.style.top = (defaultIconSize / 2 - 1) + "px";
	crossDivHorizontal.style.left = "0";
	crossDivHorizontal.style.objectFit = "contain";
	crossDivHorizontal.style.position = "relative";
	crossDivHorizontal.style.backgroundColor = "black";

	const crossDivVertical = document.createElement("div");
	crossDivVertical.style.width = "2px";
	crossDivVertical.style.height = "100%";
	crossDivVertical.style.left = (defaultIconSize / 2 - 1) + "px";
	crossDivVertical.style.top = "-2px";
	crossDivVertical.style.objectFit = "contain";
	crossDivVertical.style.position = "relative";
	crossDivVertical.style.backgroundColor = "black";

	const pointDiv = document.createElement("div");
	pointDiv.style.width = defaultIconSize + "px";
	pointDiv.style.height = defaultIconSize + "px";
	pointDiv.style.objectFit = "contain";
	pointDiv.style.position = "absolute";
	pointDiv.appendChild(crossDivHorizontal);
	pointDiv.appendChild(crossDivVertical);

	initializeOpenSeadragon();

	function initializeOpenSeadragon() {
		function getDynamicAjaxHeaders() {
			const authorizationHeaderKey = "Authorization";
			const cadAuthorizationHeaderKey = "X-CAD-Authorization";
			const token = window.eShare.currentUser.token;
			const headers = {};
			if(Utilities.isString(token) && token !== "" && !window.eShare.integratedSecurity) {
				headers[authorizationHeaderKey] = "Bearer " + token;
			}
			const cadAuthorization = window.Authenticator.getCadAuthorization();
			if(cadAuthorization) {
				headers[cadAuthorizationHeaderKey] = cadAuthorization;
			}
			return headers;
		}

		OpenSeadragon.DEFAULT_SETTINGS.loadTilesWithAjax = true;
		OpenSeadragon.DEFAULT_SETTINGS.ajaxWithCredentials = true;
		// IMPORTANT!
		// This is a non-standard function added to the OpenSeadragon object, that is
		// called from the patched OpenSeadragon's makeAjaxRequest() function in the
		// openseadragon.js. Remember to patch openseadragon.js when a new version of
		// it is getting used! See dynamicAjaxHeaders-snippet.js for more comments!
		OpenSeadragon.getDynamicAjaxHeaders = getDynamicAjaxHeaders;
	}

	function setupControl() {
		if(vm.control) {
			vm.control.changePage = changePage;
			vm.control.focusByCoordinates = requestFocusByCoordinates;
			vm.control.focusSearchResult = requestFocusBySearchResult;
			vm.control.focusByAreaId = requestFocusByAreaId;
			vm.control.getViewer = getViewer;
			vm.control.togglePoints = vm.togglePoints;
			vm.control.updateMapUiElements = updateMapUiElements;
			vm.control.onViewerHide = onViewerHide;
			vm.control.updateCameraLocation = vm.addCameraLocation;
			vm.control.setupMapPoints = vm.setupMapPointIcons;
			vm.control.changePointLocation = vm.changePointLocation;
			vm.control.addNewMetadataExtractionArea = vm.addNewMetadataExtractionArea;
			vm.control.removeMetadataExtractionArea = vm.removeMetadataExtractionArea;
			vm.control.repositionElement = repositionElement;
			vm.control.updateMetadataFields = vm.updateMetadataFields;
		}
		if(vm.loadControl) {
			vm.loadControl.loadDocument = addDocumentDzi;
		}
	}

	function repositionElement(updateMetadataAreas) {
		shouldUpdateMetadataAreas = updateMetadataAreas;
		vm.repositionElement();
	}

	function onViewerHide() {
		const viewer = getViewer();
		if(!viewer) {
			return;
		}
		zoomMemory = viewer.viewport.getZoom(true);
	}

	function updateMapUiElements() {
		if(!zoomMemory) {
			return;
		}
		const viewer = getViewer();
		if(!viewer) {
			return;
		}
		viewer.viewport.zoomTo(zoomMemory /*, _, true*/);
	}

	function getViewer() {
		if(!vm.docInfo) {
			return null;
		}
		return vm.docInfo.viewer;
	}

	function changePage(pageNumber) {
		const viewer = vm.docInfo.viewer;
		if(!viewer) {
			return;
		}
		viewer.goToPage(pageNumber);
	}

	function requestFocusByCoordinates(pageNumber, x, y, width, height, isAbsolute) {
		const xN = parseFloat(x);
		const yN = parseFloat(y);
		const wN = parseFloat(width);
		const hN = parseFloat(height);
		focusRequest = {
			viewer: vm.docInfo.viewer,
			data: {
				left: xN,
				top: yN,
				right: xN + wN,
				bottom: yN + hN,
				page: pageNumber,
				isAbsolute: isAbsolute,
			},
		};
		if(vm.docInfo.currentPageNumber !== pageNumber) {
			vm.docInfo.viewer.goToPage(pageNumber);
		} else {
			focus();
		}
	}

	function requestFocusBySearchResult(result) {
		if(!vm.docInfo || !vm.docInfo.viewer) {
			return;
		}
		doNotResetFocusRequest = true;
		const currentPageNumber = vm.docInfo.viewer.currentPage();
		const requestedPage = result.page;
		focusRequest = {
			viewer: vm.docInfo.viewer,
			data: result,
		};
		if(requestedPage === currentPageNumber) {
			focus();
		} else {
			vm.docInfo.viewer.goToPage(requestedPage);
		}
		doNotResetFocusRequest = false;
	}

	function requestFocusByAreaId(id) {
		if(!vm.docInfo.links || !id) {
			return;
		}
		for(let i = 0; i < vm.docInfo.links.length; i++) {
			const area = vm.docInfo.links[i];
			if(area.id && area.id.toLowerCase() === id.toLowerCase()) {
				focusRequest = {
					viewer: vm.docInfo.viewer,
					data: {
						left: area.left,
						top: area.top,
						right: area.right,
						bottom: area.bottom,
						page: area.page,
						isAbsolute: false,
					},
				};
				if(vm.docInfo.currentPageNumber !== area.page) {
					vm.docInfo.viewer.goToPage(area.page);
				} else {
					focus();
				}
			}
		}
	}

	function closeLinkMenuTable(viewer) {
		$(linkMenuTable).empty();
		linkMenuTable.style.display = "none";
		isLinkMenuOpen = false;
		viewer.setMouseNavEnabled(true);
	}

	function focus() {
		if(!focusRequest) {
			return;
		}
		const viewer = focusRequest.viewer;
		closeLinkMenuTable(viewer);
		const result = focusRequest.data;
		if(!doNotResetFocusRequest) {
			focusRequest = null;
		}
		const requestedPage = result.page;
		if(requestedPage !== viewer.currentPage()) {
			return;
		}
		const isAbsolute = result.isAbsolute;
		const viewport = viewer.viewport;
		let left = result.left;
		let top = result.top;
		let width = result.right - left;
		let height = result.bottom - top;
		let bounds;
		if(isAbsolute) {
			bounds = new OpenSeadragon.Rect(left, top, width, height, 0);
		} else {
			const containerWidth = viewport.containerSize.x;
			const containerHeight = viewport.containerSize.y;
			const containerAspectRatio = containerWidth / containerHeight;
			/*
			TODO
			The following line using _contentAspectRatio is a dirty hack, as it is clearly
			internal part of the OSD.
			There seems to be no official way to get this aspect ratio otherwise though.
			*/
			const contentAspectRatio = viewport._contentAspectRatio;
			const linkAspectRatio = width / height;
			const factorAspectRatio = linkAspectRatio * contentAspectRatio / containerAspectRatio;
			const factorX = width * contentAspectRatio / containerWidth;
			const factorY = height / containerHeight;
			let horizontalScaleFactor;
			let verticalScaleFactor;
			if(factorX > factorY) {
				// wider than taller
				verticalScaleFactor = 11;
				horizontalScaleFactor = verticalScaleFactor / factorAspectRatio;
				if(horizontalScaleFactor < 1.5) {
					horizontalScaleFactor = 1.5;
					verticalScaleFactor = horizontalScaleFactor * factorAspectRatio;
				}
			} else {
				// taller than wider
				horizontalScaleFactor = 11 * containerAspectRatio;
				verticalScaleFactor = horizontalScaleFactor * factorAspectRatio;
				if(verticalScaleFactor < 1.5) {
					verticalScaleFactor = 1.5;
					horizontalScaleFactor = verticalScaleFactor / factorAspectRatio;
				}
			}
			left -= width * (horizontalScaleFactor - 1) / 2;
			top -= height * (verticalScaleFactor - 1) / 2;
			width *= horizontalScaleFactor;
			height *= verticalScaleFactor;
			bounds = new OpenSeadragon.Rect(
				left,
				top / contentAspectRatio,
				width,
				height / contentAspectRatio,
				0
			);
		}
		viewport.fitBoundsWithConstraints(bounds);
	}

	function addDocumentDzi(tag, val, x, y, width, height,
		requestedAreaId, colorConfigurationId, isComparison, isConfiguration) {
		if(!vm) {
			return;
		}
		const viewerId = vm.viewerId;
		$timeout(() => {
			vm.documentDiv = $("#" + vm.documentDivId);
			vm.documentElement = angular.element(vm.documentDiv);
			// isKnownFormat does not exist for maps
			if(typeof vm.document.isKnownFormat === "boolean" && !vm.document.isKnownFormat) {
				vm.documentElement.append($compile(
					"<div id=\"{viewerId}\" style=\"background-color:#CCCCCC;position:relative;"
					+ "left:0;top:0;right:0;bottom:0;width:100%;height:100%;\">"
						+ "<div style=\"bottom: 0; left: 0; overflow: hidden; "
						+ "position: absolute; right: 0; top: 0;\">"
						+ "  <div style=\"height: 100%; white-space: nowrap; width: 100%;\">"
						+ "    <div style=\"display: inline-block; padding-bottom: 10%; "
						+ "    vertical-align: middle; white-space: normal; width: 100%;\">"
						+ "      <div class=\"container\">"
						+ "        <h3 style=\"text-align: center\">"
						+ "          <div style=\"display: inline-block;"
						+ "          margin-top: 5px; vertical-align: middle;\">"
						+ "          eShare does not support viewing this document.<br>"
						+ "          <a ng-href=\"\" class=\"pointer\"ng-click=\"vm.downloadOriginal()\">"
						+ "            <i class=\"fa fa-cloud-download\" aria-hidden=\"true\"></i>&nbsp;"
						+ "            Click here to download it for viewing in an external viewer."
						+ "          </a>"
						+ "          </div>"
						+ "        </h3>"
						+ "      </div>"
						+ "    </div>"
						+ "    <div style=\"display: inline-block; height: 100%;"
						+ "    vertical-align: middle\"></div>"
						+ "  </div>"
						+ "</div>"
						+ "</div>"
				)($scope));
				return;
			}
			const documentViewerObject = (
				"<div id=\"{viewerId}\" style=\"background-color:#CCCCCC;position:relative;"
				+ "left:0;top:0;right:0;bottom:0;width:100%;height:100%;\">"
				+ "<div style=\"bottom: 0; left: 0; overflow: hidden;"
				+ "position: absolute; right: 0; top: 0;\">"
				+ "  <div style=\"height: 100%; white-space: nowrap; width: 100%;\">"
				+ "    <div style=\"display: inline-block; padding-bottom: 10%;"
				+ "    vertical-align: middle; white-space: normal; width: 100%;\">"
				+ "      <div class=\"container\">"
				+ "        <h2 style=\"text-align: center\">"
				+ "          <div style=\"display: inline-block; margin-left: 5px;"
				+ "          margin-right: 5px; margin-top: 5px; vertical-align: middle;\">"
				+ "            <i class=\"fa fa-spinner fa-spin fa-fw\" style=\"color: #555\"></i>"
				+ "          </div>"
				+ "          <div style=\"display: inline-block; margin-top: 5px;"
				+ "          vertical-align: middle;\">Preparing document...</div> "
				+ "        </h2>"
				+ "      </div>"
				+ "    </div>"
				+ "    <div style=\"display: inline-block; height: 100%;"
				+ "    vertical-align: middle\"></div>"
				+ "  </div>"
				+ "</div>"
				+ "</div>")
				.supplant({
					viewerId: viewerId,
				});
			vm.documentDiv.append(documentViewerObject);
			vm.documentDiv.tooltipFollowsMouse = true;
			vm.documentDiv.tooltip();
			documentViewerRepository.prepareDziDocument(vm.projectId, vm.document.dataSourceId,
				vm.document.id, colorConfigurationId, isComparison, isConfiguration).then(
				result => {
					$timeout(() => {
						if(!vm) {
							return;
						}
						vm.viewerElement = document.getElementById(viewerId);
						if(vm.viewerElement == null) {
							// cadDocumentViewer: element viewerId doesn't exist anymore
							return;
						}
						vm.viewerElement.innerHTML = "";
						const pageUris = result.uris;
						if(!pageUris || pageUris.length === 0) {
							vm.documentDiv.empty();
							vm.documentDiv.append(
								"<div class=\"container\">"
								+ "<h2>Document retrieval failed</h2>"
								+ "<br>"
								+ "<p>"
								+ "There was a problem retrieving the requested document "
								+ "(document has no pages)"
								+ "</p>"
								+ "</div>"
							);
							return;
						}
						let currentPageNumber = vm.pageNumber;
						const numberOfPages = pageUris.length;
						let docInfo = {};
						if(result.isTruncated) {
							messageBox.openInfo(
								"The Document is Too Large",
								"The document has too many pages to be viewed in the internal viewer."
								+ "<br><br>"
									+ "Only the first " + numberOfPages + " pages will be shown.<br><br>"
									+ "Please use an external PDF viewer to see the whole document."
							);
						}
						let isOpen = false;
						let isOpenFailed = false;
						const viewer = OpenSeadragon({
							id: viewerId,
							prefixUrl: "static/media/osd/images/",
							tileSources: pageUris,
							maxZoomPixelRatio: 4,
							zoomPerScroll: 1.4,
							sequenceMode: true,
							initialPage: vm.pageNumber,
							showHomeControl: false,
							showFullPageControl: false,
							showSequenceControl: false,
						});
						const imagingHelper = viewer.activateImagingHelper({
							worldIndex: 0,
						});
						vm.mouseTracker = new OpenSeadragon.MouseTracker({
							element: viewer.container,
							moveHandler: onMove,
						});
						vm.mouseTracker.setTracking(true);
						viewer.addOnceHandler("open", onOpen);
						viewer.addOnceHandler("open-failed", onOpenFailed);
						viewer.addHandler("canvas-click", onClick);
						viewer.addHandler("canvas-key", onKey);
						viewer.addHandler("page", onPage);
						if(vm.viewerElement.addEventListener) {
							vm.viewerElement.addEventListener("contextmenu", contextMenuListener, false);
						}
						showPageLoading();

						function onOpen() {
							if(isOpen) {
								return;
							}
							isOpen = true;
							vm.setupMapPointIcons();
							hidePageLoading();
							fireOnLoad();
							focus();
						}

						function onOpenFailed() {
							if(isOpenFailed) {
								return;
							}
							isOpenFailed = true;
							hidePageLoading();
							showPageFailed();
							fireOnLoad();
						}

						//vm.viewer = viewer;

						const texts = setupTexts();
						let areas = setupAreas();
						result = null;
						vm.canvas = $(viewer.canvas);

						vm.getImagingHelper = function () {
							return imagingHelper || null;
						};

						function setupAreas() {
							let areasToParse = result.areas;
							if(!areasToParse) {
								areasToParse = [];
							}
							const parsedAreas = [];
							let searchLinks = [];
							for(let i = 0; i < areasToParse.length; ++i) {
								const area = areasToParse[i];
								const id = area.e;
								const left = area.l;
								const top = area.t;
								const right = left + area.w;
								const bottom = top + area.h;
								const objectLinks = area.objectLinks;
								const documentLinks = area.documentLinks;
								const poiLinks = area.poiLinks;
								let isAreaSearchable = false;
								for(let j = 0; j < objectLinks.length; j++) {
									const objectLink = objectLinks[j];
									let url = window.eShare.rootUrl + "#/p/" + vm.projectId + "/model?";
									let abbreviation = null;
									let group = null;
									if(objectLink.a && objectLink.a.length > 0) {
										abbreviation = objectLink.a[0].a || "";
										group = objectLink.a[0].g || "";
									}
									if(Utilities.isString(group) && group !== "") {
										url = url
												+ "groupParentTag=" + encodeURIComponent(group)
												+ "&groupChildTag=" + encodeURIComponent(abbreviation)
												+ "&groupId=" + encodeURIComponent(objectLink.v);
									} else {
										url = url + "positionId=" + encodeURIComponent(objectLink.v);
										if(abbreviation) {
											url = url + "&tag=" + encodeURIComponent(abbreviation);
										}
									}
									const areaLink = {
										url: url,
										text: objectLink.c,
										tags: objectLink.a,
										value: objectLink.v,
									};
									if(parsedAreas[i]) {
										parsedAreas[i].links.push(areaLink);
									} else {
										const newArea = {
											id: id,
											left: left,
											top: top,
											right: right,
											bottom: bottom,
											page: area.p,
											links: [],
											i: i,
										};
										newArea.links.push(areaLink);
										parsedAreas[i] = newArea;
									}
									if(Utilities.isString(objectLink.c)) {
										isAreaSearchable = true;
									}
								}
								for(let k = 0; k < documentLinks.length; k++) {
									const documentLink = documentLinks[k];
									const documentId = documentLink.d;
									const dataSourceId = documentLink.s;
									const areaId = documentLink.e;
									const text = documentLink.c;
									const tags = [{ a: "::documentTag::" }];
									const url = text ? "Document link " + text : "Document link";
									const areaLink = {
										url: url,
										text: text,
										tags: tags,
										value: text,
										documentId: documentId,
										dataSourceId: dataSourceId,
										areaId: areaId,
									};
									if(parsedAreas[i]) {
										parsedAreas[i].links.push(areaLink);
									} else {
										const newArea = {
											id: id,
											left: left,
											top: top,
											right: right,
											bottom: bottom,
											page: area.p,
											links: [],
											i: i,
										};
										newArea.links.push(areaLink);
										parsedAreas[i] = newArea;
									}
									// We want to add document links to searchLinks nevertheless
									// due to the special search category
									isAreaSearchable = true;
								}
								for(let l = 0; l < poiLinks.length; l++) {
									const poiLink = poiLinks[l];
									const poiKindObject = [
										{
											a: poiLink.k,
										}
									];
									const poiReference = poiLink.r;
									let url = window.eShare.rootUrl + "#/p/" + vm.projectId + "/model?";
									url = url + "poiKind=" + encodeURIComponent(poiLink.k)
										+ "&pointReference=" + encodeURIComponent(poiReference);
									const areaLink = {
										url: url,
										text: poiLink.k + ": " + poiReference,
										tags: poiKindObject,
										value: poiReference,
										type: "poi",
									};
									if(parsedAreas[i]) {
										parsedAreas[i].links.push(areaLink);
									} else {
										const newArea = {
											id: id,
											left: left,
											top: top,
											right: right,
											bottom: bottom,
											page: area.p,
											links: [],
											i: i,
										};
										newArea.links.push(areaLink);
										parsedAreas[i] = newArea;
									}
									// We want to add document links to searchLinks nevertheless
									// due to the special search category
									isAreaSearchable = true;
								}
								if(isAreaSearchable) {
									searchLinks.push(parsedAreas[i]);
								}
							}
							let attributes = result.attributes;
							if(!Utilities.isObject(attributes) || Utilities.isEmptyObject(attributes)) {
								attributes = null;
							}
							let attributeDefinitions = null;
							if(attributes || texts) {
								attributeDefinitions = [];
							}
							if(texts) {
								attributeDefinitions.push({
									abbreviation: "::freeTextSearch::",
									displayName: "Free Text",
									isSpecial: true,
								});
							}
							if(searchLinks.length === 0) {
								searchLinks = null;
							}
							if(searchLinks) {
								attributeDefinitions.push({
									abbreviation: "",
									displayName: "All links",
									isSpecial: true,
								});
								const containsDocumentLinks = searchLinks.some(area => {
									for(let l = 0; l < area.links.length; l++) {
										const link = area.links[l];
										if(link.tags[0].a === "::documentTag::") {
											return true;
										}
									}
									return false;
								});
								if(containsDocumentLinks) {
									attributeDefinitions.push({
										abbreviation: "::documentTag::",
										displayName: "Document links",
										isSpecial: true,
									});
								}
								if(attributes) {
									for(const attrAbbreviation in attributes) {
										if(attributes.hasOwnProperty(attrAbbreviation)) {
											let displayName = attributes[attrAbbreviation];
											if(displayName.indexOf(":::smartPointAbbreviation:::") > -1) {
												displayName = displayName.substring(28);
											}
											attributeDefinitions.push({
												abbreviation: attrAbbreviation,
												displayName: displayName,
											});
										}
									}
								}
							}
							const documentKey = getDocumentKey(vm.document);
							const emptySearch = {
								text: null,
								selectedAbbreviation: "",
								currentIndex: 0,
								hasResults: false,
								lastIndex: 0,
								results: null,
							};
							docInfo = _.assign({
								texts: texts,
								links: searchLinks,
								viewer: viewer,
								attributeDefinitions: attributeDefinitions,
								currentPageNumber: vm.pageNumber,
								numberOfPages: numberOfPages,
								pageNavControl: {},
								viewerControl: {},
							}, emptySearch);
							if(!searchLinks) {
								docInfo.selectedAbbreviation = "::freeTextSearch::";
							}
							if(val) {
								docInfo.text = val;
								docInfo.selectedAbbreviation = tag || "";
							}
							vm.docInfo = docInfo;
							if(x && y && width && height) {
								doNotResetFocusRequest = true;
								requestFocusByCoordinates(vm.pageNumber, x, y, width, height, true);
								doNotResetFocusRequest = false;
							}
							if(requestedAreaId) {
								doNotResetFocusRequest = true;
								requestFocusByAreaId(requestedAreaId);
								doNotResetFocusRequest = false;
							}
							const groupedAreas = _.groupBy(parsedAreas, "page");
							const areasPerPage = [];
							for(let m = 0; m < numberOfPages; m++) {
								if(groupedAreas[m]) {
									areasPerPage.push(groupedAreas[m]);
								} else {
									areasPerPage.push([]);
								}
							}
							fireOnDocInfoLoaded(documentKey, docInfo);
							return areasPerPage;
						}

						function setupTexts() {
							const texts = result.texts || [];
							const parsedTexts = [];
							let searchTexts = [];
							for(let i = 0; i < texts.length; ++i) {
								const text = texts[i];
								const left = text.l;
								const top = text.t;
								const right = left + text.w;
								const bottom = top + text.h;
								const parsedText = {
									left: left,
									top: top,
									right: right,
									bottom: bottom,
									page: text.p,
									text: text.c,
									i: i,
								};
								parsedTexts[i] = parsedText;
								if(Utilities.isString(text.c)) {
									searchTexts.push(parsedText);
								}
							}
							if(searchTexts.length === 0) {
								searchTexts = null;
							}
							return parsedTexts;
						}

						viewer.canvas.addEventListener("mousedown", checkCursorPosition);
						viewer.canvas.addEventListener("mouseup", closeLinkMenuIfNeeded);

						let hasClickedRecently = false;
						let isSettingCameraRotation = false;

						function onClick(event) {
							if(event.quick) {
								event.preventDefaultAction = true;
								if(vm.document.configurationMode || isLinkMenuOpen) {
									return;
								}
								const area = findArea(event);
								if(area && area.type !== "cameraLocation"
									&& (!area.pointsUnder || area.pointsUnder.length === 0)) {
									let url = area.url;
									if(area.links && area.links.length === 1) {
										const link = area.links[0];
										if(link.tags && link.tags[0]
											&& link.tags[0].a === "::documentTag::") {
											if(!link.dataSourceId || !link.documentId) {
												return;
											}
											fireOnOpenNewDocument(link);
											return;
										}
										url = area.links[0].url;
									} else if(area.links && area.links.length > 1) {
										openLinkMenu(event, area.links, "Links");
										return;
									}
									$timeout(() => {
										window.location.href = url;
									}, 0);
								} else if(area && area.type !== "cameraLocation"
									&& area.pointsUnder.length > 0) {
									let allPoints = [area];
									allPoints = allPoints.concat(area.pointsUnder);
									openLinkMenu(event, allPoints, "Points");
								} else if(vm.document.showPoints && !vm.document.configurationMode) {
									viewer.setMouseNavEnabled(false);
									vm.documentDiv[0].onmousedown = onPress;
									hasClickedRecently = true;
									$timeout(() => {
										viewer.setMouseNavEnabled(true);
										hasClickedRecently = false;
									}, 500);
								}
							}
						}

						let isOnConfigurationPoint = false;
						let isDraggingPoint = false;
						let pointDragged;

						let originalCursorLocationDiv;
						let lineDiv;
						let angle;

						function onPress(event) {
							if((!vm.document.configurationMode && !hasClickedRecently)
								|| !vm.document.showPoints) {
								return;
							}
							if(vm.document.configurationMode && isOnConfigurationPoint) {
								isDraggingPoint = true;
								vm.documentDiv[0].onmouseup = onRelease;
							} else {
								isSettingCameraRotation = true;
								//Make a div that marks the click location
								const canvasOffset = vm.canvas.offset();
								const mouseRelativeX = event.pageX - canvasOffset.left - 10;
								const mouseRelativeY = event.pageY - canvasOffset.top - 10;
								originalCursorLocationDiv = $("<div>")
									.css({
										"left": mouseRelativeX + "px",
										"top": mouseRelativeY + "px",
										"background-color": "rgb(0, 194, 255)",
										"width": "20px",
										"height": "20px",
										"position": "absolute",
										"border-radius": "10px",
										"border": "1px solid black",
									})
									.appendTo(vm.documentDiv[0]);
								vm.documentDiv[0].onmouseup = onRelease;
							}

							function onRelease(/*event*/) {
								viewer.setMouseNavEnabled(true);
								if(vm.document.configurationMode && isDraggingPoint) {
									isDraggingPoint = false;
									pointDragged = null;
								}
								if(!isSettingCameraRotation) {
									return;
								}
								isSettingCameraRotation = false;
								if(vm.coordinateInformation) {
									//Prepare for model view
									const canvasOffset = vm.canvas.offset();
									const originalCursorX = originalCursorLocationDiv.offset().left
										- canvasOffset.left + 10;
									const originalCursorY = originalCursorLocationDiv.offset().top
										- canvasOffset.top + 10;
									const logicalX = imagingHelper.physicalToLogicalX(originalCursorX);
									const logicalY = imagingHelper.physicalToLogicalY(originalCursorY);
									const offsetX = vm.coordinateInformation.xMin;
									const maxX = vm.coordinateInformation.xMax;
									const offsetY = vm.coordinateInformation.yMin;
									const maxY = vm.coordinateInformation.yMax;
									const multiplierX = maxX - offsetX;
									const multiplierY = maxY - offsetY;
									const dwgX = Math.round(logicalX * multiplierX + offsetX);
									const dwgY = Math.round((1 - logicalY) * multiplierY + offsetY);

									const z = vm.document.zfromInt + 1650;
									let r = convertLineDivAngleToModelRotation(angle) || 0;
									if(vm.documentToModelRatioX < 0 && vm.documentToModelRatioY < 0) {
										r += 180;
										if(r > 360) {
											r -= 360;
										}
									} else if(vm.documentToModelRatioY < 0) {
										const verticalAxisSubtraction = r - 90;
										r = 270 - verticalAxisSubtraction;
										if(r > 360) {
											r -= 360;
										}
										if(r < 0) {
											r += 360;
										}
									} else if(vm.documentToModelRatioX < 0) {
										r = 180 - r;
										if(r < 0) {
											r += 360;
										}
									}
									//Clean up
									originalCursorLocationDiv.remove();
									if(lineDiv) {
										lineDiv.remove();
									}
									//Go to model view
									if(!lineDiv) {
										$state.go("project.model", { x: dwgX, y: dwgY, z: z });
									} else {
										$state.go("project.model", { x: dwgX, y: dwgY, z: z, r: r, s: 0 });
									}
								}
							}
						}

						function onMove(event) {
							if(isSettingCameraRotation) {
								if(originalCursorLocationDiv) {
									connectCursorToOriginalLocation(event);
								}
								return;
							}
							if(isLinkMenuOpen) {
								vm.viewerElement.style.cursor = "default";
								vm.documentDiv.attr("title", "");
								return;
							}
							const area = findArea(event);
							const cursorStyle = area
								? area.type !== "cameraLocation" && !area.highlightPoints
									? "pointer"
									: "default"
								: "default";
							if(vm.document.configurationMode) {
								if(isDraggingPoint) {
									const dwgCoordinates = cursorPositionInDwgCoordinates(event);
									vm.changePointLocation(pointDragged.type, pointDragged.title,
										dwgCoordinates.x, dwgCoordinates.y);
									fireOnPointLocationChanged(pointDragged.title,
										dwgCoordinates.x, dwgCoordinates.y);
								} else if(area) {
									pointDragged = area;
									isOnConfigurationPoint = true;
									vm.documentDiv[0].onmousedown = onPress;
									viewer.setMouseNavEnabled(false);
								} else {
									isOnConfigurationPoint = false;
									viewer.setMouseNavEnabled(true);
								}
							}
							vm.viewerElement.style.cursor = cursorStyle;
							vm.documentDiv.attr("title",
								area && area.type !== "configuration"
									? decodeURIComponent(
										area.pointsUnder && area.pointsUnder.length > 0
											? "Points under: " + (area.pointsUnder.length + 1)
											: area.title
												? area.title
												: area.links
													? area.links.length > 1
														? area.links.length + " Links in Area"
														: area.links[0].url
													: ""
									)
									: "");
							if(area && area.highlightPoints) {
								vm.documentDiv.attr("title", area.title);
							}
						}

						function onKey(event) {
							if(event && event.originalEvent) {
								const keyCode = event.originalEvent.keyCode;
								if(keyCode === 114 /*r*/ || keyCode === 82 /*R*/ || keyCode === 102 /*f*/) {
									event.preventDefaultAction = true;
								}
								if(keyCode === 27 /*esc*/ && isSettingCameraRotation) {
									isSettingCameraRotation = false;
									vm.viewerElement.style.cursor = "default";
									originalCursorLocationDiv.remove();
									if(lineDiv) {
										lineDiv.remove();
									}
								}
								const processKey = vm.docInfo.pageNavControl
									&& vm.docInfo.pageNavControl.processKey;
								if(processKey) {
									if(processKey(keyCode)) {
										event.preventDefaultAction = true;
									}
								}
							}
						}

						function convertLineDivAngleToModelRotation(angle) {
							const roundedValue = Math.round(angle);
							if(roundedValue >= 0) {
								return 180 - roundedValue;
							}
							if(roundedValue < 0) {
								return roundedValue * -1 + 180;
							}
							return roundedValue;
						}

						function connectCursorToOriginalLocation(event) {
							const thickness = 2;
							const color = "black";
							const canvasOffset = vm.canvas.offset();

							// Original mouse location
							const x1 = originalCursorLocationDiv.offset().left - canvasOffset.left + 10;
							const y1 = originalCursorLocationDiv.offset().top - canvasOffset.top + 10;
							// Current mouse location
							const x2 = event.originalEvent.pageX - canvasOffset.left;
							const y2 = event.originalEvent.pageY - canvasOffset.top;
							// Distance
							const length = Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)));
							// Center
							const cx = ((x1 + x2) / 2) - (length / 2);
							const cy = ((y1 + y2) / 2) - (thickness / 2);
							// Angle
							angle = Math.atan2((y1 - y2), (x1 - x2)) * (180 / Math.PI);
							// Remove old & make the new line (with arrow wings)
							if(lineDiv) {
								lineDiv.remove();
							}
							lineDiv = $("<div>")
								.css({
									"padding": "0px",
									"margin": "0px",
									"height": thickness + "px",
									"background-color": color,
									"line-height": "1px",
									"position": "absolute",
									"left": cx + "px",
									"top": cy + "px",
									"width": length + "px",
									"-moz-transform": "rotate(" + angle + "deg)",
									"-webkit-transform": "rotate(" + angle + "deg)",
									"-o-transform": "rotate(" + angle + "deg)",
									"-ms-transform": "rotate(" + angle + "deg)",
									"transform": "rotate(" + angle + "deg)",
								});
							const arrowDivRight = $("<div>")
								.css({
									"padding": "0px",
									"margin": "0px",
									"height": thickness + "px",
									"background-color": color,
									"line-height": "1px",
									"position": "relative",
									"left": "-5px",
									"top": "-5px",
									"width": "15px",
									"-moz-transform": "rotate(-50deg)",
									"-webkit-transform": "rotate(-50deg)",
									"-o-transform": "rotate(-50deg)",
									"-ms-transform": "rotate(-50deg)",
									"transform": "rotate(-50deg)",
								});
							const arrowDivLeft = $("<div>")
								.css({
									"padding": "0px",
									"margin": "0px",
									"height": thickness + "px",
									"background-color": color,
									"line-height": "1px",
									"position": "relative",
									"left": "-5px",
									"top": "3px",
									"width": "15px",
									"-moz-transform": "rotate(50deg)",
									"-webkit-transform": "rotate(50deg)",
									"-o-transform": "rotate(50deg)",
									"-ms-transform": "rotate(50deg)",
									"transform": "rotate(50deg)",
								});
							lineDiv.append(arrowDivRight);
							lineDiv.append(arrowDivLeft);
							lineDiv.appendTo(vm.documentDiv[0]);
						}

						function openLinkMenu(event, linkCollection, title) {
							const canvasOffset = vm.canvas.offset();
							const mouseRelativeX = event.originalEvent.pageX - canvasOffset.left;
							const mouseRelativeY = event.originalEvent.pageY - canvasOffset.top;
							const containerSizeY = imagingHelper.getViewerContainerSize().y;
							$timeout(() => {
								linkMenuTable.className = "table table-condensed";
								linkMenuTable.style.position = "absolute";
								linkMenuTable.style.top = mouseRelativeY + "px";
								linkMenuTable.style.left = mouseRelativeX + "px";
								linkMenuTable.style.width = "auto";
								linkMenuTable.style.maxHeight = containerSizeY - mouseRelativeY + "px";
								linkMenuTable.style.overflowY = "auto";
								linkMenuTable.style.display = "block";
								linkMenuTable.style.backgroundColor = "white";
								linkMenuTable.style.border = "1px solid #dddddd";
								linkMenuTable.style.zIndex = "1";
								const linkMenuThead = document.createElement("thead");
								const linkMenuTheadTr = document.createElement("tr");
								const linkMenuTheadTh1 = document.createElement("th");
								const linkMenuTheadTh2 = document.createElement("th");
								linkMenuTheadTh1.innerHTML = title;
								linkMenuTheadTh2.innerHTML = "<i class=\"fa fa-fw fa-times\"></i>";
								linkMenuTheadTh2.style.cursor = "pointer";
								linkMenuTheadTh2.style.width = "20px";
								linkMenuTheadTh2.onclick = function () {
									closeLinkMenuTable(viewer);
								};
								linkMenuTheadTr.appendChild(linkMenuTheadTh1);
								linkMenuTheadTr.appendChild(linkMenuTheadTh2);
								linkMenuThead.appendChild(linkMenuTheadTr);
								linkMenuTable.appendChild(linkMenuThead);
								const linkMenuTbody = document.createElement("tbody");
								linkMenuTable.appendChild(linkMenuTbody);
								for(let i = 0; i < linkCollection.length; i++) {
									const link = linkCollection[i];
									const tr = document.createElement("tr");
									const td = document.createElement("td");
									td.colSpan = "2";
									if(!vm.document.showPoints) {
										if(link.tags && link.tags[0].a === "::documentTag::") {
											td.innerHTML = "<i class=\"fa fa-fw fa-book\"></i>&nbsp;&nbsp;";
										} else if(link.type === "poi") {
											td.innerHTML =
												"<i class=\"fa fa-fw fa-circle-o\"></i>&nbsp;&nbsp;";
										} else {
											td.innerHTML =
												"<i class=\"fa fa-fw icomoon-cube\"></i>&nbsp;&nbsp;";
										}
									}
									const a = document.createElement("a");
									a.text = link.name || link.text || link.title;
									a.title = link.url;
									a.style.cursor = "pointer";
									a.onclick = (link.tags && link.tags[0].a === "::documentTag::")
										? documentClickEvent(link)
										: urlClickEvent(link.url);
									td.appendChild(a);
									tr.appendChild(td);
									linkMenuTbody.appendChild(tr);
								}
								viewer.canvas.appendChild(linkMenuTable);
							}, 0);
							isLinkMenuOpen = true;
							viewer.setMouseNavEnabled(false);

							function urlClickEvent(url) {
								return function () {
									closeLinkMenuTable(viewer);
									window.location.href = url;
								};
							}

							function documentClickEvent(link) {
								return function () {
									closeLinkMenuTable(viewer);
									fireOnOpenNewDocument(link);
								};
							}
						}

						function showPageFailed() {
							if(document.getElementById("pageFailedElement")) {
								return;
							}
							const pageFailedElement =
									"<div id=\"pageFailedElement\" style=\"background-color:#CCCCCC;"
										+ "position:relative;left:0;top:0;right:0;bottom:0;width:100%;"
										+ "height:100%; z-index:100\">"
										+ "<div style=\"bottom: 0; left: 0; overflow: hidden;"
										+ "  position: absolute; right: 0; top: 0;\">"
										+ "  <div style=\"height: 100%; white-space: nowrap; width: 100%;\">"
										+ "    <div style=\"display: inline-block; padding-bottom: 10%;"
										+ "      vertical-align: middle; white-space: normal; width: 100%;\">"
										+ "      <div class=\"container\">"
										+ "        <h2 style=\"text-align: center\">"
										+ "          <div style=\"display: inline-block; margin-top: 5px;"
										+ "            vertical-align: middle;\">Loading page failed</div> "
										+ "        </h2>"
										+ "      </div>"
										+ "    </div>"
										+ "    <div style=\"display: inline-block; height: 100%;"
										+ "      vertical-align: middle\"></div>"
										+ "  </div>"
										+ "</div>"
										+ "</div>";
							$(pageFailedElement).insertBefore($("#" + viewerId));
						}

						function hidePageFailed() {
							const pageFailedElement = document.getElementById("pageFailedElement");
							if(!pageFailedElement) {
								return;
							}
							pageFailedElement.parentNode.removeChild(pageFailedElement);
						}

						function showPageLoading() {
							if(document.getElementById("pageLoadingElement")) {
								return;
							}
							const pageLoadingElement =
									"<div id=\"pageLoadingElement\" style=\"background-color:#CCCCCC;"
										+ "position:relative;left:0;top:0;right:0;bottom:0;width:100%;"
										+ "height:100%; z-index:100\">"
										+ "<div style=\"bottom: 0; left: 0; overflow: hidden;"
										+ "  position: absolute; right: 0; top: 0;\">"
										+ "  <div style=\"height: 100%; white-space: nowrap; width: 100%;\">"
										+ "    <div style=\"display: inline-block; padding-bottom: 10%;"
										+ "      vertical-align: middle; white-space: normal; width: 100%;\">"
										+ "      <div class=\"container\">"
										+ "        <h2 style=\"text-align: center\">"
										+ "          <div style=\"display: inline-block; margin-left: 5px;"
										+ "margin-right: 5px; margin-top: 5px; vertical-align: middle;\">"
										+ "            <i class=\"fa fa-spinner fa-spin fa-fw\""
										+ "              style=\"color: #555\"></i>"
										+ "          </div>"
										+ "          <div style=\"display: inline-block; margin-top: 5px;"
										+ "            vertical-align: middle;\">Loading page...</div> "
										+ "        </h2>"
										+ "      </div>"
										+ "    </div>"
										+ "    <div style=\"display: inline-block; height: 100%;"
										+ "      vertical-align: middle\"></div>"
										+ "  </div>"
										+ "</div>"
										+ "</div>";
							$(pageLoadingElement).insertBefore($("#" + viewerId));
						}

						function hidePageLoading() {
							const pageLoadingElement = document.getElementById("pageLoadingElement");
							if(!pageLoadingElement) {
								return;
							}
							pageLoadingElement.parentNode.removeChild(pageLoadingElement);
						}

						function onPage(event) {
							hidePageFailed();
							showPageLoading();
							currentPageNumber = event.page;
							vm.docInfo.currentPageNumber = currentPageNumber;
							viewer.addOnceHandler("open", () => {
								hidePageLoading();
								focus();
								const repositionElement = vm.repositionElement;
								if(repositionElement) {
									repositionElement();
								}
							});
							viewer.addOnceHandler("open-failed", () => {
								hidePageLoading();
								showPageFailed();
							});
						}

						vm.setupMapPointIcons = function () {
							if(($state.current.name !== "project.maps" && !vm.document.configurationMode)
								|| !vm.document.isVisible) {
								return;
							}
							if(vm.document && vm.document.showPoints) {
								// Assumes that there is only one image in the viewer
								const image = viewer.world.getItemAt(0);
								if(!image) {
									return;
								}
								const imageSize = image.getContentSize();
								const defaultCoordinateInformation = {
									xMin: 0,
									yMin: 0,
									xMax: imageSize.x,
									yMax: imageSize.y,
								};
								vm.coordinateInformation = vm.document.mapCoordinateAndPointInformation
									? vm.document.mapCoordinateAndPointInformation.coordinateInformation
									: defaultCoordinateInformation;
								if(!vm.document.configurationMode && vm.coordinateInformation.pointOne
										&& vm.coordinateInformation.pointTwo) {
									const pointTwoMapX = vm.coordinateInformation.pointTwo.mapX;
									const pointOneMapX = vm.coordinateInformation.pointOne.mapX;
									const pointTwoMapY = vm.coordinateInformation.pointTwo.mapY;
									const pointOneMapY = vm.coordinateInformation.pointOne.mapY;
									//Set proper scale to the map
									const mapDeltaX = pointTwoMapX - pointOneMapX;
									const mapDeltaY = pointTwoMapY - pointOneMapY;
									const pointTwoModelX = vm.coordinateInformation.pointTwo.modelX;
									const pointOneModelX = vm.coordinateInformation.pointOne.modelX;
									const pointTwoModelY = vm.coordinateInformation.pointTwo.modelY;
									const pointOneModelY = vm.coordinateInformation.pointOne.modelY;
									const modelDeltaX = pointTwoModelX - pointOneModelX;
									const modelDeltaY = pointTwoModelY - pointOneModelY;
									vm.documentToModelRatioX = modelDeltaX / mapDeltaX;
									vm.documentToModelRatioY = modelDeltaY / mapDeltaY;
									const xMin = vm.coordinateInformation.xMin;
									const xMax = vm.coordinateInformation.xMax;
									const yMin = vm.coordinateInformation.yMin;
									const yMax = vm.coordinateInformation.yMax;
									const minModelX = pointOneModelX
										- (pointOneMapX - xMin) * vm.documentToModelRatioX;
									const minModelY = pointOneModelY
										- (pointOneMapY - yMin) * vm.documentToModelRatioY;
									const maxModelX = pointTwoModelX
										+ (xMax - pointTwoMapX) * vm.documentToModelRatioX;
									const maxModelY = pointTwoModelY
										+ (yMax - pointTwoMapY) * vm.documentToModelRatioY;

									vm.coordinateInformation.xMin = minModelX;
									vm.coordinateInformation.yMin = minModelY;
									vm.coordinateInformation.xMax = maxModelX;
									vm.coordinateInformation.yMax = maxModelY;
								}
								let wrapperNames = [];
								const mapPointInformation = [];

								fireOnToggleSpinner(true);
								const pointLocationInformation =
									vm.document.mapCoordinateAndPointInformation
										? vm.document.mapCoordinateAndPointInformation
											.pointLocationInformation
										: [];
								const isPointTypeVisible = [];
								const quadTreesByPointType = [];
								// Wrapper div that is moved to keep the icons in the correct locations
								const wrapperDiv = document.createElement("div");
								wrapperDiv.style.position = "absolute";
								wrapperDiv.style.pointerEvents = "none";
								wrapperDiv.style.left = "0";
								wrapperDiv.style.top = "0";
								wrapperDiv.style.width = "100%";
								wrapperDiv.style.height = "100%";
								wrapperDiv.id = "WD:" + vm.document.id;
								viewer.canvas.appendChild(wrapperDiv);
								// Add layers to the wrapper div that can be filtered separately
								vm.setUpWrapperDivs = function (names) {
									_.forEach(names, name => {
										const div = document.getElementById(name + vm.document.id);
										if(!div) {
											const wrapperChildDiv = document.createElement("div");
											wrapperChildDiv.style.position = "absolute";
											wrapperChildDiv.style.pointerEvents = "none";
											wrapperChildDiv.style.left = "0";
											wrapperChildDiv.style.top = "0";
											wrapperChildDiv.style.width = "100%";
											wrapperChildDiv.style.height = "100%";
											wrapperChildDiv.id = name + vm.document.id;
											wrapperDiv.appendChild(wrapperChildDiv);
										}
									});
								};
								_.forEach(pointLocationInformation, pli => {
									if(pli.subType) {
										wrapperNames.push(pli.subType);
									}
								});
								wrapperNames.push("pointCloudDiv", "cameraLocationDiv");
								if(vm.document.configurationMode) {
									wrapperNames.push("configuration");
								}
								wrapperNames = _.uniq(wrapperNames);
								vm.setUpWrapperDivs(wrapperNames);

								if(vm.document.configurationMode) {
									const xMax = vm.coordinateInformation.xMax;
									const xMin = vm.coordinateInformation.xMin;
									const yMax = vm.coordinateInformation.yMax;
									const yMin = vm.coordinateInformation.yMin;
									const dividerX = xMax - xMin;
									const dividerY = yMax - yMin;

									const configurationDiv =
										document.getElementById("configuration" + vm.document.id);

									if(vm.document.isForDocuments) {
										if(vm.metadataExtractionRules
											&& vm.metadataExtractionRules.length > 0) {
											for(let i = 0; i < vm.metadataExtractionRules.length; i++) {
												const rule = vm.metadataExtractionRules[i];
												rule.id = i;
												const page = rule.page > 0
													? rule.page - 1
													: rule.page < 0
														? vm.docInfo.numberOfPages + rule.page
														: 0;
												//Points that define the metadata area:
												let rectOne;
												let rectTwo;
												const multiplierXInner =
													(rule.width + rule.offsetX - xMin) / dividerX;
												const multiplierYInner =
													(rule.height + rule.offsetY - yMin) / dividerY;
												const multiplierXOuter = (rule.offsetX - xMin) / dividerX;
												const multiplierYOuter = (rule.offsetY - yMin) / dividerY;
												switch(rule.originCorner) {
												case "BottomRight":
													rectOne = new OpenSeadragon.Rect(
														imageSize.x - multiplierXInner * imageSize.x,
														imageSize.y - multiplierYInner * imageSize.y,
														defaultIconSize,
														defaultIconSize
													);
													rectTwo = new OpenSeadragon.Rect(
														imageSize.x - multiplierXOuter * imageSize.x,
														imageSize.y - multiplierYOuter * imageSize.y,
														defaultIconSize,
														defaultIconSize
													);
													break;
												case "TopLeft":
													rectOne = new OpenSeadragon.Rect(
														multiplierXInner * imageSize.x,
														multiplierYInner * imageSize.y,
														defaultIconSize,
														defaultIconSize
													);
													rectTwo = new OpenSeadragon.Rect(
														multiplierXOuter * imageSize.x,
														multiplierYOuter * imageSize.y,
														defaultIconSize,
														defaultIconSize
													);
													break;
												case "TopRight":
													rectOne = new OpenSeadragon.Rect(
														imageSize.x - multiplierXInner * imageSize.x,
														multiplierYInner * imageSize.y,
														defaultIconSize,
														defaultIconSize
													);
													rectTwo = new OpenSeadragon.Rect(
														imageSize.x - multiplierXOuter * imageSize.x,
														multiplierYOuter * imageSize.y,
														defaultIconSize,
														defaultIconSize
													);
													break;
												default:
													rectOne = new OpenSeadragon.Rect(
														multiplierXInner * imageSize.x,
														imageSize.y - multiplierYInner * imageSize.y,
														defaultIconSize,
														defaultIconSize
													);
													rectTwo = new OpenSeadragon.Rect(
														multiplierXOuter * imageSize.x,
														imageSize.y - multiplierYOuter * imageSize.y,
														defaultIconSize,
														defaultIconSize
													);
													break;
												}
												const pointOneDiv = pointDiv.cloneNode(true);
												const pointTwoDiv = pointDiv.cloneNode(true);
												const pointOne = {
													rect: rectOne,
													img: pointOneDiv,
													name: rule.id + "-1",
													url: "",
													type: "configuration",
													pointsUnder: [],
													page: page,
												};
												const pointTwo = {
													rect: rectTwo,
													img: pointTwoDiv,
													name: rule.id + "-2",
													url: "",
													type: "configuration",
													pointsUnder: [],
													page: page,
												};
												mapPointInformation.push(pointOne);
												mapPointInformation.push(pointTwo);

												configurationDiv.appendChild(pointOneDiv);
												configurationDiv.appendChild(pointTwoDiv);

												//Highlighting rectangle for the area:
												const upperLeftX = rectOne.x > rectTwo.x
													? rectTwo.x
													: rectOne.x;
												const upperLeftY = rectOne.y > rectTwo.y
													? rectTwo.y
													: rectOne.y;
												const lowerRightX = rectOne.x < rectTwo.x
													? rectTwo.x
													: rectOne.x;
												const lowerRightY = rectOne.y < rectTwo.y
													? rectTwo.y
													: rectOne.y;
												const width = lowerRightX - upperLeftX;
												const height = lowerRightY - upperLeftY;
												const highlightRect = new OpenSeadragon.Rect(
													upperLeftX,
													upperLeftY,
													width,
													height
												);

												const highlightDiv = document.createElement("div");
												highlightDiv.style.width = width + "px";
												highlightDiv.style.height = height + "px";
												highlightDiv.style.objectFit = "contain";
												highlightDiv.style.position = "absolute";
												highlightDiv.style.backgroundColor = "rgba(0, 0, 255, 0.1)";
												highlightDiv.style.border = "1px solid gray";

												mapPointInformation.push({
													rect: highlightRect,
													img: highlightDiv,
													name: rule.name,
													url: "",
													type: "configuration",
													pointsUnder: [],
													highlightPoints: [pointOne, pointTwo],
													metadataRule: rule,
													page: page,
												});
												configurationDiv.appendChild(highlightDiv);
											}
											ruleIdCounter = vm.metadataExtractionRules.length;
										}
									} else {
										const pointOneMapX = vm.coordinateInformation.pointOne.mapX;
										const pointOneMapY = vm.coordinateInformation.pointOne.mapY;
										const pointTwoMapX = vm.coordinateInformation.pointTwo.mapX;
										const pointTwoMapY = vm.coordinateInformation.pointTwo.mapY;
										const pointOneMultiplierX = (pointOneMapX - xMin) / dividerX;
										const pointOneMultiplierY = 1 - (pointOneMapY - yMin) / dividerY;
										const pointTwoMultiplierX = (pointTwoMapX - xMin) / dividerX;
										const pointTwoMultiplierY = 1 - (pointTwoMapY - yMin) / dividerY;

										const rectOne = new OpenSeadragon.Rect(
											pointOneMultiplierX * imageSize.x,
											pointOneMultiplierY * imageSize.y,
											defaultIconSize,
											defaultIconSize
										);
										const rectTwo = new OpenSeadragon.Rect(
											pointTwoMultiplierX * imageSize.x,
											pointTwoMultiplierY * imageSize.y,
											defaultIconSize,
											defaultIconSize
										);

										const pointOneDiv = pointDiv.cloneNode(true);
										const pointTwoDiv = pointDiv.cloneNode(true);
										_.forEach(pointTwoDiv.childNodes, node => {
											node.style.backgroundColor = "dodgerblue";
										});
										const pointOne = {
											rect: rectOne,
											img: pointOneDiv,
											name: "Point One",
											url: "",
											type: "configuration",
											pointsUnder: [],
										};
										const pointTwo = {
											rect: rectTwo,
											img: pointTwoDiv,
											name: "Point Two",
											url: "",
											type: "configuration",
											pointsUnder: [],
										};
										mapPointInformation.push(pointOne);
										mapPointInformation.push(pointTwo);

										configurationDiv.appendChild(pointOneDiv);
										configurationDiv.appendChild(pointTwoDiv);
									}
								}

								vm.togglePoints = function (name, show) {
									const pointDivElement = document.getElementById(name + vm.document.id);
									if(!pointDivElement) {
										return;
									}
									if(!show) {
										pointDivElement.style.display = "none";
									} else {
										pointDivElement.style.display = "inline-block";
									}
									if(name === "pointCloudDiv" || name === "cameraLocationDiv") {
										name = name.substring(0, name.length - 3);
									}
									isPointTypeVisible[name] = show;
									const repositionElement = vm.repositionElement;
									if(repositionElement) {
										repositionElement(true);
									}
								};

								const pointCloudDiv =
									document.getElementById("pointCloudDiv" + vm.document.id);
								_.forEach(pointLocationInformation, pli => {
									const divContainer = document.createElement("div");
									divContainer.style.width = defaultIconSize + "px";
									divContainer.style.height = defaultIconSize + "px";
									divContainer.style.objectFit = "contain";
									divContainer.style.position = "absolute";
									divContainer.style.userSelect = "none";

									const img = document.createElement("img");
									img.src = pli.iconUrl;
									img.style.width = "100%";
									img.style.height = "100%";
									img.style.objectFit = "contain";
									img.style.position = "absolute";
									img.style.backgroundColor = pli.iconColor;
									img.style.borderRadius = defaultIconSize / 2 + "px";
									img.style.border = pli.iconColor ? "solid 1px black" : "";
									divContainer.appendChild(img);

									const textElement = document.createElement("span");
									textElement.className = "badge";
									textElement.style.color = "#ddd";
									textElement.style.height = "19.5px";
									textElement.style.fontSize = "11px";
									textElement.style.padding = "3px";
									textElement.style.background = "#444";
									textElement.style.fontWeight = "normal";
									textElement.style.objectFit = "contain";
									textElement.style.position = "absolute";
									textElement.style.left = defaultIconSize * 0.70 + "px";
									const textNode = document.createTextNode("");
									textElement.appendChild(textNode);
									divContainer.appendChild(textElement);

									let url = "";
									if(pli.type === "pointCloud") {
										pointCloudDiv.appendChild(divContainer);
										url = window.eShare.rootUrl
											+ "#/p/" + vm.projectId
											+ "/model?pointCloud=" + encodeURIComponent(pli.fileName);
									}
									if(pli.type === "smartPoint") {
										const smartPointDiv =
											document.getElementById(pli.subType + vm.document.id);
										smartPointDiv.appendChild(divContainer);
										url = window.eShare.rootUrl
											+ "#/p/" + vm.projectId
											+ "/model?pointId=" + encodeURIComponent(pli.pointId);
									}
									if(pli.type === "markup") {
										const markupDiv =
											document.getElementById(pli.subType + vm.document.id);
										markupDiv.appendChild(divContainer);
										url = window.eShare.rootUrl
											+ "#/p/" + vm.projectId
											+ "/model?markupId=" + encodeURIComponent(pli.markupId);

									}
									const rectLocationX = pli.x * imageSize.x;
									const rectLocationY = pli.y * imageSize.y;
									const rect = new OpenSeadragon.Rect(
										rectLocationX,
										rectLocationY,
										defaultIconSize,
										defaultIconSize
									);
									mapPointInformation.push({
										rect: rect,
										img: divContainer,
										textNode: textNode,
										name: pli.name,
										url: url,
										type: pli.type === "smartPoint" || pli.type === "markup"
											? pli.subType
											: pli.type,
										pointsUnder: [],
									});
									if(pli.subType) {
										vm.togglePoints(pli.subType, false);
									}
								});

								if(!vm.document.showPointClouds) {
									vm.togglePoints("pointCloudDiv", false);
								} else {
									vm.togglePoints("pointCloudDiv", false);
								}
								vm.togglePoints("cameraLocationDiv", true);
								vm.togglePoints("configuration", true);

								let isCameraLocationUpdated = false;
								vm.addCameraLocation = function (currentX, currentY, currentZ,
									currentRotation, currentSlope) {
									const isCameraInsideZRange = vm.document.zfromInt <= currentZ
										&& currentZ <= vm.document.ztoInt;
									const showWithFullOpacity = vm.document.showAllPoints
										? true
										: isCameraInsideZRange;
									const opacity = showWithFullOpacity ? "1" : "0.5";
									let div = document.getElementById("cameraIconDiv" + vm.document.id);
									/*
									If ratio X is negative,
										the camera location rotation needs to be turned 90 degrees to right.
									If ratio Y is negative,
										the camera location rotation needs to be turned 90 degrees to left.
									If both ratios are negative,
										the camera location rotation needs to be mirrored.
									*/
									if(vm.documentToModelRatioX < 0 && vm.documentToModelRatioY < 0) {
										currentRotation += 180;
										if(currentRotation > 360) {
											currentRotation -= 360;
										}
									} else if(vm.documentToModelRatioY < 0) {
										const verticalAxisSubtraction = currentRotation - 90;
										currentRotation = 270 - verticalAxisSubtraction;
										if(currentRotation > 360) {
											currentRotation -= 360;
										}
										if(currentRotation < 0) {
											currentRotation += 360;
										}
									} else if(vm.documentToModelRatioX < 0) {
										currentRotation = 180 - currentRotation;
										if(currentRotation < 0) {
											currentRotation += 360;
										}
									}
									if(!div) {
										div = document.createElement("div");
										div.style.width = defaultIconSize + "px";
										div.style.height = defaultIconSize + "px";
										div.style.objectFit = "contain";
										div.style.position = "absolute";
										div.style.transform = "rotate(" + (450 - currentRotation) + "deg)";
										div.id = "cameraIconDiv" + vm.document.id;
										div.style.backgroundColor = "rgb(0, 194, 255)";
										div.style.borderRadius = defaultIconSize / 2 + "px";
										div.style.border = "solid 1px black";
										div.style.opacity = opacity;
										div.style.textAlign = "center";
										div.style.verticalAlign = "middle";
										const cameraLocationDiv =
											document.getElementById("cameraLocationDiv" + vm.document.id);
										if(cameraLocationDiv) {
											cameraLocationDiv.appendChild(div);
										}
									}
									while(div.firstChild) {
										div.removeChild(div.firstChild);
									}
									const arrow = document.createElement("i");
									if(currentSlope > 85) {
										arrow.className = "fa fa-lg fa-plus";
									} else if(currentSlope < -85) {
										arrow.className = "fa fa-lg fa-circle";
									} else {
										arrow.className = "fa fa-lg fa-arrow-up";
									}
									arrow.style.textAlign = "center";
									arrow.style.verticalAlign = "middle";
									arrow.style.position = "relative";
									// Dirty hack, will probably go off centre if iconPxSize is changed
									arrow.style.top = "1.5px";
									arrow.style.color = "white";
									div.appendChild(arrow);

									const offsetX = vm.coordinateInformation.xMin;
									const maxX = vm.coordinateInformation.xMax;
									const offsetY = vm.coordinateInformation.yMin;
									const maxY = vm.coordinateInformation.yMax;
									const dividerX = maxX - offsetX;
									const dividerY = maxY - offsetY;
									const relativeX = (currentX - offsetX) / dividerX;
									const relativeY = 1 - (currentY - offsetY) / dividerY;
									const rectLocationX = relativeX * imageSize.x;
									const rectLocationY = relativeY * imageSize.y;
									const rect = new OpenSeadragon.Rect(
										rectLocationX,
										rectLocationY,
										defaultIconSize,
										defaultIconSize
									);

									let cameraName = "Camera location";
									if(!vm.document.showAllPoints && !isCameraInsideZRange) {
										if(vm.document.zfromInt > currentZ) {
											cameraName += "\n(below map)";
										}
										if(currentZ > vm.document.ztoInt) {
											cameraName += "\n(above map)";
										}
									}

									for(let i = 0; i < mapPointInformation.length; i++) {
										const mpi = mapPointInformation[i];
										if(mpi.type === "cameraLocation") {
											mapPointInformation.splice(i, 1);
										}
									}
									mapPointInformation.push({
										rect: rect,
										img: div,
										name: cameraName,
										url: "",
										type: "cameraLocation",
										rotation: currentRotation,
										opacity: opacity,
										pointsUnder: [],
									});
									if((currentX !== 0 || currentY !== 0 || currentZ !== 0
										|| currentRotation !== 0 || currentSlope !== 0)
										&& !isCameraLocationUpdated) {
										vm.repositionElement();
										isCameraLocationUpdated = true;
									}
								};

								let visiblePointCounter = 0;
								const maxPointCount = 1000;

								// Calculates point positions relative to the viewport
								// and applies the new values to the wrapper div
								vm.repositionElement = function () {
									if(($state.current.name !== "project.maps"
										&& !vm.document.configurationMode)
										|| !vm.document.isVisible) {
										return;
									}
									areas = [];
									if(visiblePointCounter > maxPointCount) {
										return;
									}
									fireOnToggleSpinner(true);
									const currentBounds = viewer.viewport.viewportToViewerElementRectangle(
										viewer.viewport.getBounds(true)
									);
									for(let index = 0; index < wrapperNames.length; index++) {
										let name = wrapperNames[index];
										if(name === "pointCloudDiv") {
											name = name.substring(0, name.length - 3);
										}
										quadTreesByPointType[name] = new Quadtree({
											x: 0,
											y: 0,
											width: viewer.element.offsetWidth,
											height: viewer.element.offsetHeight,
										});
									}
									const mpiLength = mapPointInformation.length;
									for(let i = 0; i < mpiLength; i++) {
										const mpi = mapPointInformation[i];
										if(!isPointTypeVisible[mpi.type] && mpi.type !== "cameraLocation") {
											continue;
										}
										const newRect = viewer.viewport.viewportToViewerElementRectangle(
											viewer.viewport.imageToViewportRectangle(mpi.rect)
										);
										const boundsXMin = currentBounds.x - defaultIconSize;
										const boundsXMax =
											currentBounds.x + currentBounds.width + defaultIconSize;
										const boundsYMin = currentBounds.y - defaultIconSize;
										const boundsYMax =
											currentBounds.y + currentBounds.height + defaultIconSize;
										if((newRect.x < boundsXMin
											|| newRect.x > boundsXMax
											|| newRect.y < boundsYMin
											|| newRect.y > boundsYMax)
											&& !vm.document.configurationMode) {
											mpi.img.style.display = "none";
											continue;
										}
										const page = mpi.page || 0;
										if(vm.docInfo.currentPageNumber !== page) {
											mpi.img.style.display = "none";
											continue;
										}
										if(mpi.type !== "cameraLocation") {
											mpi.pointsUnder = [];
											const pointLocationTree = quadTreesByPointType[mpi.type];
											if(!pointLocationTree) {
												console.log(
													"Error: Point location tree not found for type " + mpi.type
												);
												continue;
											}
											const treeObject = {
												x: newRect.x - defaultIconSize / 2,
												y: newRect.y - defaultIconSize / 2,
												width: defaultIconSize,
												height: defaultIconSize,
												point: mpi,
											};
											const collisionCandidates = pointLocationTree.retrieve(treeObject);
											if(collisionCandidates.length > 0) {
												let hasCollision = false;
												for(let j = 0; j < collisionCandidates.length; j++) {
													const candidate = collisionCandidates[j];
													if((candidate.x > treeObject.x - defaultIconSize
														&& candidate.x < treeObject.x + defaultIconSize)
														&& (candidate.y > treeObject.y - defaultIconSize
															&& candidate.y < treeObject.y + defaultIconSize)
														&& mpi.type !== "configuration") {
														hasCollision = true;
														candidate.point.pointsUnder.push(mpi);
														for(let l = 0; l < areas.length; l++) {
															for(let m = 0; m < areas[l].length; m++) {
																const link = areas[l][m];
																if(link.url === candidate.point.url
																	&& link.title === candidate.point.name) {
																	link.pointsUnder = candidate.point.pointsUnder;
																	break;
																}
															}
														}
														break;
													}
												}
												if(hasCollision) {
													mpi.img.style.display = "none";
													continue;
												}
											}
											pointLocationTree.insert(treeObject);
										}
										const linkLeft = imagingHelper.physicalToLogicalX(
											newRect.x - defaultIconSize / 2
										);
										const linkTop = imagingHelper.physicalToLogicalY(
											newRect.y - defaultIconSize / 2
										);
										const linkRight = imagingHelper.physicalToLogicalX(
											newRect.x + defaultIconSize / 2
										);
										const linkBottom = imagingHelper.physicalToLogicalY(
											newRect.y + defaultIconSize / 2
										);
										const mapPointLink = {
											left: linkLeft,
											top: linkTop,
											right: linkRight,
											bottom: linkBottom,
											url: mpi.url,
											title: mpi.name,
											page: page,
											type: mpi.type,
											pointsUnder: mpi.pointsUnder,
										};
										if(!mpi.highlightPoints) {
											if(!areas[page]) {
												areas[page] = [];
											}
											areas[page].push(mapPointLink);
										}
										if(mpi.highlightPoints && mpi.highlightPoints.length === 2) {
											const pointOne = mpi.highlightPoints[0];
											const pointTwo = mpi.highlightPoints[1];
											const pointOneLeft = parseInt(pointOne.img.style.left, 10);
											const pointOneTop = parseInt(pointOne.img.style.top, 10);
											const pointTwoLeft = parseInt(pointTwo.img.style.left, 10);
											const pointTwoTop = parseInt(pointTwo.img.style.top, 10);
											const upperLeftX = pointOneLeft > pointTwoLeft
												? pointTwoLeft
												: pointOneLeft;
											const upperLeftY = pointOneTop > pointTwoTop
												? pointTwoTop
												: pointOneTop;
											const lowerRightX = pointOneLeft < pointTwoLeft
												? pointTwoLeft
												: pointOneLeft;
											const lowerRightY = pointOneTop < pointTwoTop
												? pointTwoTop
												: pointOneTop;
											const elementWidth = lowerRightX - upperLeftX;
											const elementHeight = lowerRightY - upperLeftY;
											const upperLeftXLogical = imagingHelper.physicalToLogicalX(
												upperLeftX + defaultIconSize / 2
											);
											const upperLeftYLogical = imagingHelper.physicalToLogicalY(
												upperLeftY + defaultIconSize / 2
											);
											const lowerRightXLogical = imagingHelper.physicalToLogicalX(
												lowerRightX + defaultIconSize / 2
											);
											const lowerRightYLogical = imagingHelper.physicalToLogicalY(
												lowerRightY + defaultIconSize / 2
											);
											mpi.img.style.left = upperLeftX + defaultIconSize / 2 + "px";
											// + 1 pixel to fix a small displacement bug
											mpi.img.style.top = upperLeftY + defaultIconSize / 2 + 1 + "px";
											mpi.img.style.width = elementWidth + "px";
											mpi.img.style.height = elementHeight + "px";
											mpi.img.style.display = "inline-block";
											mapPointLink.left = upperLeftXLogical;
											mapPointLink.right = lowerRightXLogical;
											mapPointLink.top = upperLeftYLogical;
											mapPointLink.bottom = lowerRightYLogical;
											mapPointLink.highlightPoints = mpi.highlightPoints;
											if(!areas[page]) {
												areas[page] = [];
											}
											areas[page].push(mapPointLink);
											if(shouldUpdateMetadataAreas) {
												const xMax = vm.coordinateInformation.xMax;
												const yMax = vm.coordinateInformation.yMax;
												mpi.metadataRule.width = Math.round(
													(imagingHelper.physicalToLogicalX(lowerRightX) * xMax)
													- (imagingHelper.physicalToLogicalX(upperLeftX) * xMax)
												);
												mpi.metadataRule.height = Math.round(
													(imagingHelper.physicalToLogicalY(lowerRightY) * yMax)
													- (imagingHelper.physicalToLogicalY(upperLeftY) * yMax)
												);
												switch(mpi.metadataRule.originCorner) {
												case "BottomRight":
													mpi.metadataRule.offsetX = Math.round(
														xMax - lowerRightXLogical * xMax
													);
													mpi.metadataRule.offsetY = Math.round(
														yMax - lowerRightYLogical * yMax
													);
													break;
												case "TopLeft":
													mpi.metadataRule.offsetX = Math.round(
														upperLeftXLogical * xMax
													);
													mpi.metadataRule.offsetY = Math.round(
														upperLeftYLogical * yMax
													);
													break;
												case "TopRight":
													mpi.metadataRule.offsetX = Math.round(
														xMax - lowerRightXLogical * xMax
													);
													mpi.metadataRule.offsetY = Math.round(
														upperLeftYLogical * yMax
													);
													break;
												default:
													mpi.metadataRule.offsetX = Math.round(
														upperLeftXLogical * xMax
													);
													mpi.metadataRule.offsetY = Math.round(
														yMax - lowerRightYLogical * yMax
													);
													break;
												}
											}
										} else {
											mpi.img.style.left = newRect.x - defaultIconSize / 2 + "px";
											mpi.img.style.top = newRect.y - defaultIconSize / 2 + "px";
											mpi.img.style.width = defaultIconSize + "px";
											mpi.img.style.height = defaultIconSize + "px";
											mpi.img.style.display = "inline-block";
										}
										if(mpi.type === "cameraLocation") {
											mpi.img.style.transform = "rotate("
											+ (450 - mpi.rotation)
											+ "deg)";
											mpi.img.style.opacity = mpi.opacity;
										}
									}
									shouldUpdateMetadataAreas = false;
									for(let k = 0; k < mpiLength; k++) {
										const mpi2 = mapPointInformation[k];
										if(mpi2.type === "cameraLocation") {
											continue;
										}
										if(mpi2.pointsUnder.length > 0) {
											mpi2.textNode.nodeValue = mpi2.pointsUnder.length + 1 < 100
												? mpi2.pointsUnder.length + 1
												: "99+";
										} else if(mpi2.pointsUnder.length === 0
											&& mpi2.textNode
											&& mpi2.textNode.nodeValue !== "") {
											mpi2.textNode.nodeValue = "";
										}
									}
									fireOnToggleSpinner(false);
								};

								fireOnToggleSpinner(false);
								vm.repositionElement(true);

								vm.animationStart = function () {
									fireOnToggleSpinner(true);
									$(linkMenuTable).empty();
									linkMenuTable.style.display = "none";
									isLinkMenuOpen = false;
									viewer.setMouseNavEnabled(true);
									visiblePointCounter = 0;
									const mpiLength = mapPointInformation.length;
									for(let i = 0; i < mpiLength; i++) {
										const mpi = mapPointInformation[i];
										if(isPointTypeVisible[mpi.type]) {
											visiblePointCounter++;
										}
									}
									if(visiblePointCounter > maxPointCount) {
										for(let j = 0; j < mpiLength; j++) {
											const mpi2 = mapPointInformation[j];
											if(isPointTypeVisible[mpi2.type]) {
												mpi2.img.style.display = "none";
											}
										}
									}
								};

								vm.animationFinish = function () {
									if(visiblePointCounter > maxPointCount) {
										const mpiLength = mapPointInformation.length;
										for(let i = 0; i < mpiLength; i++) {
											const mpi = mapPointInformation[i];
											if(isPointTypeVisible[mpi.type]) {
												mpi.img.style.display = "inline-block";
											}
										}
										visiblePointCounter = 0;
										vm.repositionElement();
									}
									fireOnToggleSpinner(false);
								};

								vm.changePointLocation = function (pointType, pointName,
									newLocationX, newLocationY) {
									if(newLocationX === null || newLocationX === undefined
										|| newLocationY === null || newLocationY === undefined
										|| isNaN(newLocationX) || isNaN(newLocationY)) {
										return;
									}
									const mpiLength = mapPointInformation.length;
									for(let i = 0; i < mpiLength; i++) {
										const mpi = mapPointInformation[i];
										if(mpi.type === pointType && mpi.name === pointName) {
											const xMax = vm.coordinateInformation.xMax;
											const xMin = vm.coordinateInformation.xMin;
											const yMax = vm.coordinateInformation.yMax;
											const yMin = vm.coordinateInformation.yMin;
											const dividerX = xMax - xMin;
											const dividerY = yMax - yMin;
											const pointMultiplierX = (newLocationX - xMin) / dividerX;
											const pointMultiplierY = 1 - (newLocationY - yMin) / dividerY;
											const rect = new OpenSeadragon.Rect(
												pointMultiplierX * imageSize.x,
												pointMultiplierY * imageSize.y,
												defaultIconSize,
												defaultIconSize
											);
											mpi.rect = rect;
											shouldUpdateMetadataAreas = vm.document.isForDocuments;
											vm.repositionElement();
											return;
										}
									}
								};

								vm.addNewMetadataExtractionArea = function () {
									const newRule = {
										name: "Area" + (++ruleIdCounter),
										pattern: "(?<Area" + ruleIdCounter + ">.*)",
										originCorner: "BottomLeft",
										page: vm.docInfo.currentPageNumber + 1,
										width: Math.round(vm.coordinateInformation.xMax / 3),
										height: Math.round(vm.coordinateInformation.yMax / 3),
										offsetX: Math.round(vm.coordinateInformation.xMax / 3),
										offsetY: Math.round(vm.coordinateInformation.yMax / 3),
										id: ruleIdCounter,
									};
									vm.metadataExtractionRules.push(newRule);

									const xMax = vm.coordinateInformation.xMax;
									const xMin = vm.coordinateInformation.xMin;
									const yMax = vm.coordinateInformation.yMax;
									const yMin = vm.coordinateInformation.yMin;
									//Add the area to the viewer
									const dividerX = xMax - xMin;
									const dividerY = yMax - yMin;
									const multiplierXInner = (newRule.width + newRule.offsetX - xMin)
										/ dividerX;
									const multiplierYInner = (newRule.height + newRule.offsetY - yMin)
										/ dividerY;
									const multiplierXOuter = (newRule.offsetX - xMin)
										/ dividerX;
									const multiplierYOuter = (newRule.offsetY - yMin)
										/ dividerY;
									const rectOne = new OpenSeadragon.Rect(
										multiplierXInner * imageSize.x,
										imageSize.y - multiplierYInner * imageSize.y,
										defaultIconSize,
										defaultIconSize
									);
									const rectTwo = new OpenSeadragon.Rect(
										multiplierXOuter * imageSize.x,
										imageSize.y - multiplierYOuter * imageSize.y,
										defaultIconSize,
										defaultIconSize
									);

									const pointOneDiv = pointDiv.cloneNode(true);
									const pointTwoDiv = pointDiv.cloneNode(true);

									const pointOne = {
										rect: rectOne,
										img: pointOneDiv,
										name: newRule.id + "-1",
										url: "",
										type: "configuration",
										pointsUnder: [],
										page: vm.docInfo.currentPageNumber,
									};
									const pointTwo = {
										rect: rectTwo,
										img: pointTwoDiv,
										name: newRule.id + "-2",
										url: "",
										type: "configuration",
										pointsUnder: [],
										page: vm.docInfo.currentPageNumber,
									};
									mapPointInformation.push(pointOne);
									mapPointInformation.push(pointTwo);

									const configurationDiv =
										document.getElementById("configuration" + vm.document.id);
									configurationDiv.appendChild(pointOneDiv);
									configurationDiv.appendChild(pointTwoDiv);

									const upperLeftX = rectOne.x > rectTwo.x ? rectTwo.x : rectOne.x;
									const upperLeftY = rectOne.y > rectTwo.y ? rectTwo.y : rectOne.y;
									const lowerRightX = rectOne.x < rectTwo.x ? rectTwo.x : rectOne.x;
									const lowerRightY = rectOne.y < rectTwo.y ? rectTwo.y : rectOne.y;
									const width = lowerRightX - upperLeftX;
									const height = lowerRightY - upperLeftY;
									const highlightRect = new OpenSeadragon.Rect(
										upperLeftX,
										upperLeftY,
										width,
										height
									);

									const highlightDiv = document.createElement("div");
									highlightDiv.style.width = width + "px";
									highlightDiv.style.height = height + "px";
									highlightDiv.style.objectFit = "contain";
									highlightDiv.style.position = "absolute";
									highlightDiv.style.backgroundColor = "rgba(0, 0, 255, 0.1)";
									highlightDiv.style.border = "1px solid gray";

									mapPointInformation.push({
										rect: highlightRect,
										img: highlightDiv,
										name: newRule.name,
										url: "",
										type: "configuration",
										pointsUnder: [],
										highlightPoints: [pointOne, pointTwo],
										metadataRule: newRule,
										page: vm.docInfo.currentPageNumber,
									});

									configurationDiv.appendChild(highlightDiv);

									//Update view
									shouldUpdateMetadataAreas = true;
									vm.repositionElement();
								};

								vm.removeMetadataExtractionArea = function (index) {
									const ruleToRemove = vm.metadataExtractionRules[index];
									for(let i = 0; i < mapPointInformation.length; i++) {
										const mpi = mapPointInformation[i];
										let hasSpliced = false;
										if(mpi.highlightPoints && mpi.highlightPoints.length === 2
											&& mpi.highlightPoints[0].name === ruleToRemove.id + "-1"
											&& mpi.highlightPoints[1].name === ruleToRemove.id + "-2") {
											mpi.img.parentNode.removeChild(mpi.img);
											mapPointInformation.splice(i, 1);
											hasSpliced = true;
										} else if(mpi.name === ruleToRemove.id + "-1"
											|| mpi.name === ruleToRemove.id + "-2") {
											mpi.img.parentNode.removeChild(mpi.img);
											mapPointInformation.splice(i, 1);
											hasSpliced = true;
										}
										if(hasSpliced) {
											i--;
										}
									}
									vm.repositionElement();
								};

								vm.updateMetadataFields = function () {
									if(!vm.metadataExtractionRules) {
										return;
									}
									_.forEach(vm.metadataExtractionRules, rule => {
										_.forEach(mapPointInformation, mpi => {
											// Update names if they have changed
											if(mpi.highlightPoints && mpi.highlightPoints.length === 2
												&& mpi.highlightPoints[0].name === rule.id + "-1"
												&& mpi.highlightPoints[1].name === rule.id + "-2") {
												if(mpi.name !== rule.name) {
													mpi.name = rule.name;
												}
												const page = rule.page > 0
													? rule.page - 1
													: rule.page < 0
														? vm.docInfo.numberOfPages + rule.page
														: 0;
												mpi.page = page;
												mpi.highlightPoints[0].page = page;
												mpi.highlightPoints[1].page = page;
											}
										});
									});
									vm.repositionElement();
								};

								// These handle repositioning the element when interacting with the viewer
								viewer.addHandler("animation", vm.repositionElement);
								viewer.addHandler("animation-start", vm.animationStart);
								viewer.addHandler("animation-finish", vm.animationFinish);
							}
						};

						function findArea(event) {
							const canvasOffset = vm.canvas.offset();
							const mouseRelativeX = event.originalEvent.pageX - canvasOffset.left;
							const mouseRelativeY = event.originalEvent.pageY - canvasOffset.top;
							const x = imagingHelper.physicalToLogicalX(mouseRelativeX);
							const y = imagingHelper.physicalToLogicalY(mouseRelativeY);

							const matchingAreas = _.filter(areas[currentPageNumber], area => {
								return area
									&& area.left <= x
									&& x <= area.right
									&& area.top <= y
									&& y <= area.bottom;
							});
							if(matchingAreas.length > 0) {
								let index = 0;
								let firstArea = _.cloneDeep(matchingAreas[index++]);
								if(!firstArea.type && firstArea.links) {
									for(let i = 1; i < matchingAreas.length; i++) {
										const area = matchingAreas[i];
										if(area.links) {
											for(let j = 0; j < area.links.length; j++) {
												firstArea.links.push(_.cloneDeep(area.links[j]));
											}
										}
									}
								}
								while(matchingAreas.length > index && firstArea.highlightPoints) {
									firstArea = _.cloneDeep(matchingAreas[index++]);
								}
								return firstArea;
							}
							return null;
						}

						function cursorPositionInDwgCoordinates(event) {
							const canvasOffset = vm.canvas.offset();
							const mouseRelativeX = event.originalEvent.pageX - canvasOffset.left;
							const mouseRelativeY = event.originalEvent.pageY - canvasOffset.top;
							const x = imagingHelper.physicalToLogicalX(mouseRelativeX);
							const y = imagingHelper.physicalToLogicalY(mouseRelativeY);
							const offsetX = vm.coordinateInformation.xMin;
							const maxX = vm.coordinateInformation.xMax;
							const offsetY = vm.coordinateInformation.yMin;
							const maxY = vm.coordinateInformation.yMax;
							const multiplierX = maxX - offsetX;
							const multiplierY = maxY - offsetY;
							const dwgX = x * multiplierX + offsetX;
							const dwgY = (1 - y) * multiplierY + offsetY;
							return {
								x: Math.round(dwgX),
								y: Math.round(dwgY),
							};
						}
					}, 25); //TODO: Why is this needed?
				}, () => {
					let downloadLinkElement = "";
					// Only add download link for those that can have it
					if(typeof vm.document.isKnownFormat === "boolean") {
						downloadLinkElement =
								"<a ng-href=\"\" class=\"pointer\"ng-click=\"vm.downloadOriginal()\">"
								+ "<i class=\"fa fa-cloud-download\" aria-hidden=\"true\"></i>&nbsp;"
								+ "Click here to download it for viewing in an external viewer."
								+ "</a>";
						vm.document.isKnownFormat = false;
					}
					vm.documentElement.empty();
					vm.documentElement.append($compile(
						"<div id=\"{viewerId}\" style=\"background-color:#CCCCCC;position:relative;"
							+ "left:0;top:0;right:0;bottom:0;width:100%;height:100%;\">"
							+ "<div style=\"bottom: 0; left: 0; overflow: hidden; position: absolute;"
							+ "right: 0; top: 0;\">"
							+ "  <div style=\"height: 100%; white-space: nowrap; width: 100%;\">"
							+ "    <div style=\"display: inline-block; padding-bottom: 10%;"
							+ "    vertical-align: middle; white-space: normal; width: 100%;\">"
							+ "      <div class=\"container\">"
							+ "        <h2 style=\"text-align: center\">Document retrieval failed</h2>"
							+ "        <h3 style=\"text-align: center\">"
							+ "          <div style=\"display: inline-block; margin-top: 5px;"
							+ "          vertical-align: middle;\">"
							+ "            There was a problem retrieving the requested document.<br>"
							+ downloadLinkElement
							+ "          </div>"
							+ "        </h3>"
							+ "      </div>"
							+ "    </div>"
							+ "    <div style=\"display: inline-block; height: 100%;"
							+ "    vertical-align: middle\"></div>"
							+ "  </div>"
							+ "</div>"
							+ "</div>"
					)($scope));
				}
			);
		});
	}

	function checkCursorPosition(event) {
		if(!isLinkMenuOpen) {
			return;
		}
		const co = vm.canvas.offset();
		const mrx = event.pageX - co.left;
		const mry = event.pageY - co.top;
		const pointMenuTableLeft = parseInt(linkMenuTable.style.left, 10);
		const pointMenuTableTop = parseInt(linkMenuTable.style.top, 10);
		if(mrx < pointMenuTableLeft
			|| mrx > pointMenuTableLeft + linkMenuTable.offsetWidth
			|| mry < pointMenuTableTop
			|| mry > pointMenuTableTop + linkMenuTable.offsetHeight) {
			shouldPointMenuBeClosed = true;
		}
	}

	function closeLinkMenuIfNeeded(/*event*/) {
		if(!isLinkMenuOpen) {
			return;
		}
		if(shouldPointMenuBeClosed) {
			const viewer = getViewer();
			if(viewer) {
				closeLinkMenuTable(viewer);
			}
			shouldPointMenuBeClosed = false;
		}
	}

	function destroyViewerAndRemoveHandlers() {
		if(vm.viewerElement) {
			vm.viewerElement.removeEventListener("contextmenu", contextMenuListener, false);
		}
		if(vm && vm.mouseTracker) {
			vm.mouseTracker.destroy();
			vm.mouseTracker = null;
		}
		let viewer = getViewer();
		if(viewer) {
			if(viewer.canvas) {
				viewer.canvas.removeEventListener("mousedown", checkCursorPosition);
				viewer.canvas.removeEventListener("mouseup", closeLinkMenuIfNeeded);
			}
			viewer.removeAllHandlers();
			viewer.destroy();
			viewer = null;
		}
	}

	function getDocumentKey(doc) {
		return doc.dataSourceId + "|" + doc.id;
	}

	function fireOnDocInfoLoaded(documentKey, docInfo) {
		const onDocInfoLoaded = vm.onDocInfoLoaded();
		if(onDocInfoLoaded) {
			onDocInfoLoaded(documentKey, docInfo);
		}
	}

	function fireOnToggleSpinner(isLoading) {
		const onToggleSpinner = vm.onToggleSpinner();
		if(onToggleSpinner) {
			onToggleSpinner(isLoading);
		}
	}

	function fireOnLoad() {
		const onLoad = vm.onLoad();
		if(onLoad) {
			onLoad(vm.document.id);
		}
	}

	function fireOnOpenNewDocument(link) {
		const onOpenNewDocument = vm.onOpenNewDocument();
		if(onOpenNewDocument) {
			onOpenNewDocument(link);
		}
	}

	function fireOnPointLocationChanged(pointName, x, y) {
		const onPointLocationChanged = vm.onPointLocationChanged();
		if(onPointLocationChanged) {
			onPointLocationChanged(pointName, x, y);
		}
	}
}
