angular.module("eShareApp").factory("projectsRepository", [
	"repositoryBase", "notification",
	function (repositoryBase, notification) {

		const baseApi = "/api";
		const projectsApi = baseApi + "/Projects";
		const projectIdApi = projectsApi + "/{projectId}";

		function getAll() {
			return repositoryBase.get(projectsApi, {}).then(data => {
				if(!data || !Utilities.isArray(data)) {
					return [];
				}
				const projects = _.sortBy(data, project => {
					return (project.name || "").toLowerCase();
				});
				return projects;
			}, error => {
				notification.error(error);
			});
		}

		function get(projectId) {
			return repositoryBase.get(projectIdApi, { projectId: projectId });
		}

		function add(project) {
			return repositoryBase.post(projectsApi, {}, projectToDto(project));
		}

		function update(project) {
			return repositoryBase.put(projectIdApi, { projectId: project.id }, projectToDto(project));
		}

		function remove(project) {
			return repositoryBase.remove(projectIdApi, { projectId: project.id });
		}

		function getByName(projectName) {
			return repositoryBase.get(
				"/api/ProjectResolver/ByName/{projectName}",
				{ projectName: projectName }
			);
		}

		function getByNameWithSpecialCharacters(projectName) {
			//This is needed for project names that contain quotation (") characters
			const name = new String(projectName);
			return repositoryBase.post("/api/ProjectResolver/ByName", {}, name);
		}

		function getProjectImage(projectId) {
			return repositoryBase.get(projectIdApi + "/projectImage", { projectId: projectId });
		}

		function removeProjectImage(projectId) {
			return repositoryBase.post(projectIdApi + "/removeProjectImage", { projectId: projectId });
		}

		function getProjectNameById(projectId) {
			return repositoryBase.get(projectIdApi + "/projectName", { projectId: projectId });
		}

		function projectToDto(project) {
			const sourceProjectId = Utilities.isNonEmptyGuid(project.sourceProjectId)
				? project.sourceProjectId
				: null;
			return {
				id: project.id,
				name: project.name,
				description: project.description,
				image: project.image,
				databaseName: project.databaseName,
				sourceProjectId: sourceProjectId,
				is64BitOnly: project.is64BitOnly,
				canBeSourceProject: project.canBeSourceProject,
			};
		}

		function exportAll(projectId) {
			return repositoryBase.post(projectIdApi + "/export", { projectId: projectId }).then(
				data => {
					return JSON.stringify(data, undefined, 3);
				}
			);
		}

		function importAll(projectId, serializedExportedData, shouldImportProjectAdminPermissions,
			shouldImportMarkup) {
			const exportedData = JSON.parse(serializedExportedData);
			if(Utilities.isObject(exportedData) && Utilities.isObject(exportedData.projectUsers)) {
				exportedData.projectUsers.shouldImportProjectAdminPermissions =
					shouldImportProjectAdminPermissions;
			}
			if(Utilities.isObject(exportedData) && Utilities.isObject(exportedData.smartPoints)) {
				exportedData.smartPoints.shouldImportMarkup = shouldImportMarkup;
			}
			return repositoryBase.post(
				projectIdApi + "/import",
				{ projectId: projectId },
				exportedData
			);
		}

		return {
			getAll: getAll,
			get: get,
			add: add,
			update: update,
			remove: remove,
			getByName: getByName,
			getByNameWithSpecialCharacters: getByNameWithSpecialCharacters,
			exportAll: exportAll,
			importAll: importAll,
			getProjectImage: getProjectImage,
			removeProjectImage: removeProjectImage,
			getProjectNameById: getProjectNameById,
		};
	}
]);