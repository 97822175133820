angular.module("eShareApp").controller("groupEditCtrl", [
	"$scope", "$state", "group", "messageBox", "groupsRepository", "users",

	function (
		$scope, $state, group, messageBox, groupsRepository, users
	) {

		const stateName = $state.current.name;

		$scope.isNew = $state.current.data.isNew;
		$scope.isBusy = false;

		if($state.current.data.isNew) {
			$scope.group = {
				id: null,
				name: "",
				description: "",
				memberIds: [],
			};
		} else {
			$scope.group = _.cloneDeep(group);
			if(!$scope.group.memberIds) {
				$scope.group.memberIds = [];
			}
		}

		$scope.allUsers = _.forEach(users, user => {
			user.isMember = $scope.group.memberIds.indexOf(user.id) > -1;
		});

		$scope.isValid = function () {
			return $scope.group.name && $scope.isNameValid();
		};

		$scope.isNameValid = function () {
			return angular.isString($scope.group.name)
				&& Utilities.betweenIncl($scope.group.name.trim().length, 1, 64);
		};

		$scope.editCommit = function () {
			$scope.isBusy = true;
			$scope.group.memberIds = _.map(_.filter($scope.allUsers, user => {
				return user.isMember;
			}), user => {
				return user.id;
			});
			let updateFunction;
			if($state.current.data.isNew) {
				updateFunction = groupsRepository.add;
			} else {
				updateFunction = groupsRepository.update;
			}
			updateFunction($scope.group).then(
				() => {
					goBack();
				},
				message => {
					messageBox.openError(
						"Error creating / modifying group",
						"Error creating / modifying group: " + message
					);
					$scope.isBusy = false;
				}
			);
		};

		function goBack() {
			if($state.current.name !== stateName) {
				return;
			}
			if($state.current.data.isNew) {
				$state.go("admin.groups", {}, { reload: true });
			} else {
				$state.go("^.view", { groupId: $scope.group.id }, { reload: true });
			}
		}
	}
]);
