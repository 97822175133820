angular
	.module("eShareApp")
	.factory("documentListConfigurationRepository", documentListConfigurationRepository);

documentListConfigurationRepository.$inject = [
	"repositoryBase", "$q"
];

function documentListConfigurationRepository(
	repositoryBase, $q
) {
	const columnsApi = "api/project/{projectId}/DocumentTree/Columns/Default/{datasourceId}";

	function get(projectId, dataSourceId) {
		const configs = angular.fromJson(localStorage.getItem("documentListConfiguration")) || {};
		if(configs[projectId]) {
			if(configs[projectId][dataSourceId]) {
				const d = $q.defer();
				d.resolve(configs[projectId][dataSourceId]);
				return d.promise;
			} else {
				return getDefaults(projectId, dataSourceId);
			}
		}
		return getDefaults(projectId, dataSourceId);
	}

	function set(projectId, dataSourceId, config) {
		const configs = angular.fromJson(localStorage.getItem("documentListConfiguration")) || {};
		if(!configs[projectId]) {
			configs[projectId] = {};
		}
		configs[projectId][dataSourceId] = config;
		localStorage.setItem("documentListConfiguration", angular.toJson(configs));
	}

	function getDefaults(projectId, datasourceId) {
		return repositoryBase.get(columnsApi, { projectId: projectId, datasourceId: datasourceId });
	}

	function setDefaults(projectId, datasourceId, config) {
		return repositoryBase.post(
			columnsApi, { projectId: projectId, datasourceId: datasourceId }, config
		);
	}

	return {
		get: get,
		set: set,
		setDefaults: setDefaults,
		getDefaults: getDefaults,
	};
}
