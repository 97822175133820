angular.module("eShareApp").factory("pointCloudsUploadService", pointCloudsUploadService);
pointCloudsUploadService.$inject = ["uploadService"];

function pointCloudsUploadService(uploadService) {

	function forProject(project) {
		const uri = "api/project/{projectId}/PointCloud";
		const service = uploadService.getOrCreate(
			project.id,
			"Point Cloud",
			[".ptx", ".cpd", ".e57", ".cpe", ".cpx"],
			uri,
			uri
		);

		service.uploadFiles = function (files, queryParameters, duplicates, isOverwriting) {
			if(this.isUploadInProgress) {
				throw "Tried to upload with upload still in progress!";
			}
			this.isCanceled = false;
			this.isFailed = false;
			this.isUploadInProgress = true;
			this.uploadStatuses.length = 0;
			if(duplicates != null) {
				if(!isOverwriting) {
					files = skipDuplicates(files, duplicates, this);
				}
			}
			queryParameters.isOverwriting = isOverwriting;

			// these are necessary to call:
			const uploadFunctions = this.prepareUploadFunctions(this, files, queryParameters);
			this.sequentialUpload(this, uploadFunctions);
		};
		return service;
	}

	function skipDuplicates(files, duplicates, service) {
		files = files.filter(file => {
			return _.find(duplicates, dupe => {
				return dupe === file;
			}) == null;
		});
		duplicates.forEach(dupe => {
			const originalIndex = _.findIndex(service.uploadStatuses, item => {
				return item.sourceFileName === dupe.name;
			});
			if(originalIndex !== -1) {
				service.uploadStatuses.splice(originalIndex, 1);
			}
			const uploadObject = {
				sourceFileName: dupe.name,
				status: "skipped",
				statusText: "Skipped",
			};
			service.uploadStatuses.push(uploadObject);
		});
		return files;
	}

	return {
		forProject: forProject,
	};
}