angular.module("eShareApp").directive("accessLevel", [
	"authorization",
	function (authorization) {

		return {
			restrict: "A",
			link: function (scope, element, attrs) {
				const originalDisplayValue = element.css("display");
				let accessLevel;

				scope.currentUser = window.eShare.currentUser;

				scope.$watch("currentUser.role", () => {
					updateCss();
				});

				attrs.$observe("accessLevel", newAccessLevel => {
					accessLevel = newAccessLevel;
					updateCss();
				});

				function updateCss() {
					if(scope.currentUser && scope.currentUser.role && accessLevel) {
						if(authorization.authorize(accessLevel, scope.currentUser.role)) {
							element.css("display", originalDisplayValue);
						} else {
							element.css("display", "none");
						}
					}
				}
			},
		};
	}
]);