angular.module("eShareApp").controller("AdapterTypeSelectCtrl", [
	"$state", "$scope", "project", "messageBox", "adaptersRepository", "adapterTypes",

	function (
		$state, $scope, project, messageBox, adaptersRepository, adapterTypes
	) {

		$scope.adapterTypes = adapterTypes;
		$scope.selectedAdapterType = { typeGuid: "", name: "" };

		$scope.selectAdapterType = function (adapterType) {
			$scope.selectedAdapterType = adapterType;
		};

		$scope.createAdapter = function () {
			$scope.isNewAdapter = true;
			adaptersRepository.create(project, $scope.selectedAdapterType.typeGuid).then(
				adapterId => {
					// success
					adaptersRepository.get(project.id, adapterId).then(
						adapter => {
							$scope.adapter = adapter;
							$state.go("project.admin.adapters.adapter.edit", {
								projectId: project.id,
								adapterId: adapterId,
							});
						},
						message => {
							showError(message);
						}
					);
				},
				message => {
					showError(message);
				}
			);

			function showError(message) {
				const mbox = messageBox.openError(
					"Creating adapter failed!", "Creating adapter failed. Reason: " + message
				);
				mbox.result.then(
					() => {
						$state.go("^");
					}
				);
			}
		};

		function selectFirstRow() {
			if($scope.adapterTypes.length > 0) {
				$scope.selectedAdapterType = $scope.adapterTypes[0];
			}
		}

		selectFirstRow();
	}
]);
