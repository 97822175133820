angular.module("eShareApp").factory("colorsRepository", colorsRepository);

colorsRepository.$inject = ["repositoryBase"];

function colorsRepository(repositoryBase) {
	const baseApi = "api/project/{projectId}/Colors";

	return {
		get: get,
		update: update,
		importAll: importAll,
		exportAll: exportAll,
	};

	function get(projectId) {
		return repositoryBase.get(baseApi, { projectId: projectId });
	}

	function update(projectId, colors) {
		return repositoryBase.put(baseApi, { projectId: projectId }, colors);
	}

	function importAll(projectId, colors) {
		return repositoryBase.put(baseApi + "/Import", { projectId: projectId }, colors);
	}

	function exportAll(projectId) {
		return repositoryBase.get(baseApi + "/Export", { projectId: projectId });
	}
}
