angular.module("eShareApp").directive("cadIndexingOptions", cadIndexingOptions);

cadIndexingOptions.$inject = [];

function cadIndexingOptions() {
	return {
		restrict: "E",
		scope: {},
		bindToController: {
			dsc: "<",
			isEditing: "<",
			beforeCommit: "=",
			isSaveEnabled: "=",
			initialize: "=?"
		},
		controller: CadIndexingOptionsController,
		controllerAs: "vm",
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadIndexingOptions.html'),
		replace: false,
	};
}

CadIndexingOptionsController.$inject = [];

function CadIndexingOptionsController() {
	const vm = this;
	vm.isDataSourceInEditMode = vm.isEditing;
	vm.dataSourceConfig = vm.dsc;

	vm.modeDictionary = {
		"OnSave": "Once",
		"Daily": "Daily",
		"OnGivenDaysOfWeek": "Once per Days of Week",
		"OnGivenTimesOfDaysOfWeek": "Given Times in Days of Week",
		"OnGivenDaysOfMonth": "Days of Month",
		"AllTheTime": "All the Time",
	};

	vm.initialize = function() {
		vm.dataSourceConfig.isIndexing = vm.dataSourceConfig.isIndexing === "true"
			|| vm.dataSourceConfig.isIndexing === true;
		vm.indexingMode = angular.fromJson(vm.dataSourceConfig.indexingMode);
		const duration = moment.duration(vm.indexingMode.targetDuration);
		vm.indexingMode.targetDurationMinutes = duration.asMinutes();
		if(typeof(vm.indexingMode.strategy) === "string") {
			vm.indexingMode.isAsap = vm.indexingMode.strategy.toLowerCase() === "asfastaspossible";
		}
		vm.weekDays = [
			{ name: "Monday", selected: _.includes(vm.indexingMode.daysOfWeek, "Monday") },
			{ name: "Tuesday", selected: _.includes(vm.indexingMode.daysOfWeek, "Tuesday") },
			{ name: "Wednesday", selected: _.includes(vm.indexingMode.daysOfWeek, "Wednesday") },
			{ name: "Thursday", selected: _.includes(vm.indexingMode.daysOfWeek, "Thursday") },
			{ name: "Friday", selected: _.includes(vm.indexingMode.daysOfWeek, "Friday") },
			{ name: "Saturday", selected: _.includes(vm.indexingMode.daysOfWeek, "Saturday") },
			{ name: "Sunday", selected: _.includes(vm.indexingMode.daysOfWeek, "Sunday") }
		];
		vm.hours = [];
		for(let i = 0; i < 24; i++) {
			vm.hours.push({ name: i });
		}
		vm.minutes = [];
		for(let j = 0; j < 60; j++) {
			vm.minutes.push({ name: j });
		}
		vm.days = [];
		for(let k = 1; k < 32; k++) {
			vm.days.push({ name: k });
		}
		vm.indexingTimes = _.map(vm.indexingMode.timesOfDay, time => {
			const duration = moment.duration(time);
			return {
				hours: duration.hours(),
				minutes: duration.minutes(),
			};
		});
		vm.indexingDays = _.orderBy(_.map(vm.indexingMode.daysOfMonth, day => {
			return {
				day: day,
			};
		}), id => id.day);
	}

	vm.isSaveEnabled = function () {
		if(vm.indexingMode.mode === "OnGivenTimesOfDaysOfWeek"
			|| vm.indexingMode.mode === "OnGivenDaysOfWeek") {
			if(!_.some(vm.weekDays, "selected")) {
				return false;
			}
		}
		if(vm.indexingMode.mode === "OnGivenTimesOfDaysOfWeek") {
			if(vm.indexingTimes.length < 1
				|| _.some(vm.indexingTimes, { "hours": null })
				|| _.some(vm.indexingTimes, { "minutes": null })) {
				return false;
			}
		}
		if(vm.indexingMode.mode === "OnGivenDaysOfMonth") {
			if(vm.indexingDays.length < 1
				|| _.some(vm.indexingDays, { "day": null })
				|| vm.indexingDays.length !== _.uniqBy(vm.indexingDays, "day").length) {
				return false;
			}
		}
		const targetDurationMinutes = vm.indexingMode.targetDurationMinutes;
		if(vm.indexingMode !== undefined && !vm.indexingMode.isAsap
			&& (targetDurationMinutes > 1439 || targetDurationMinutes === undefined)) {
			return false;
		}

		return true;
	};

	vm.beforeCommit = function () {
		vm.dataSourceConfig.isIndexing = vm.dataSourceConfig.isIndexing.toString();
		vm.indexingMode.strategy = vm.indexingMode.isAsap
			? "AsFastAsPossible"
			: "SpendAtLeastDuration";
		delete vm.indexingMode.isAsap;

		const duration = moment.duration(vm.indexingMode.targetDurationMinutes, "minutes");
		delete vm.indexingMode.targetDurationMinutes;
		vm.indexingMode.daysOfWeek = _.map(_.filter(vm.weekDays, "selected"), "name");
		vm.indexingMode.targetDuration = "0." + duration.hours() + ":" + duration.minutes() + ":00";
		vm.indexingMode.timesOfDay = _.map(vm.indexingTimes, time => {
			return "0." + time.hours + ":" + time.minutes + ":00";
		});
		vm.indexingMode.daysOfMonth = _.map(vm.indexingDays, day => {
			return day.day;
		});
		vm.dataSourceConfig.indexingMode = angular.toJson(vm.indexingMode);
		vm.dsc.indexingMode = vm.dataSourceConfig.indexingMode;
		vm.dsc.isIndexing = vm.dataSourceConfig.isIndexing;
	};

	vm.addIndexingDay = function () {
		vm.indexingDays.splice(0, 0, { day: 1 });
	};

	vm.removeIndexingDay = function (indexingDay) {
		const index = vm.indexingDays.indexOf(indexingDay);
		vm.indexingDays.splice(index, 1);
	};

	vm.onIndexingDayChanged = function(){
		vm.indexingDays = _.orderBy(vm.indexingDays, id => id.day);
	}

	vm.addIndexingTime = function () {
		vm.indexingTimes.push({ hours: 6, minutes: 0 });
	};

	vm.removeIndexingTime = function (indexingTime) {
		const index = vm.indexingTimes.indexOf(indexingTime);
		vm.indexingTimes.splice(index, 1);
	};

	vm.initialize();
}