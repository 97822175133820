angular.module("eShareApp").factory("repositoryBase", repositoryBase);

repositoryBase.$inject = ["$http", "$q", "notification"];

function repositoryBase($http, $q, notification) {
	return {
		get: get,
		put: put,
		post: post,
		rawPost: rawPost,
		remove: remove,
	};

	function rawAccess(verb, urlTemplate, routeData, postData, skipErrorNotification) {
		let encodedRouteData = null;
		if(routeData) {
			encodedRouteData = {};
			let hasProperties = false;
			for(const propertyName in routeData) {
				if(routeData.hasOwnProperty(propertyName)) {
					const propertyValue = routeData[propertyName];
					if(!Utilities.isNullOrUndefined(propertyValue)) {
						encodedRouteData[propertyName] = encodeURIComponent(String(propertyValue));
						hasProperties = true;
					}
				}
			}
			if(!hasProperties) {
				encodedRouteData = null;
			}
		}
		const url = urlTemplate.supplant(encodedRouteData);
		return $http[verb](url, postData).then(
			undefined,
			reason => {
				const errorMessage = Utilities.getErrorMessage(reason);
				if(!skipErrorNotification) {
					notification.error(errorMessage);
				}
				return $q.reject(errorMessage);
			}
		);
	}

	function access(verb, urlTemplate, routeData, postData, skipErrorNotification) {
		return rawAccess(verb, urlTemplate, routeData, postData, skipErrorNotification).then(
			response => {
				return response.data;
			}
		);
	}

	function get(urlTemplate, routeData, skipErrorNotification) {
		return access("get", urlTemplate, routeData, null, skipErrorNotification);
	}

	function put(urlTemplate, routeData, putData, skipErrorNotification) {
		return access("put", urlTemplate, routeData, putData, skipErrorNotification);
	}

	function post(urlTemplate, routeData, postData, skipErrorNotification) {
		return access("post", urlTemplate, routeData, postData, skipErrorNotification);
	}

	function rawPost(urlTemplate, routeData, postData, skipErrorNotification) {
		return rawAccess("post", urlTemplate, routeData, postData, skipErrorNotification);
	}

	function remove(urlTemplate, routeData, skipErrorNotification) {
		return access("delete", urlTemplate, routeData, skipErrorNotification);
	}
}