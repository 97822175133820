angular.module("eShare.documentViewer")
	.directive("cadDocumentViewerPageNav", cadDocumentViewerPageNav);

cadDocumentViewerPageNav.$inject = [];

function cadDocumentViewerPageNav() {
	return {
		restrict: "E",
		replace: true,
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadDocumentViewerPageNav.directive.html'),
		scope: {},
		bindToController: {
			numberOfPages: "<",
			currentPageNumber: "<",
			onPageNumberRequested: "&",
			control: "=",
		},
		controllerAs: "vm",
		controller: cadDocumentViewerPageNavController,
	};
}

cadDocumentViewerPageNavController.$inject = ["$scope", "$element", "$timeout", "debounce"];

function cadDocumentViewerPageNavController($scope, $element, $timeout, debounce) {
	const vm = this;

	vm.goToFirstPageClicked = goToFirstPageClicked;
	vm.goToPreviousPageClicked = goToPreviousPageClicked;
	vm.goToNextPageClicked = goToNextPageClicked;
	vm.goToLastPageClicked = goToLastPageClicked;
	vm.isEditing = false;
	vm.editedPageNumber = 0;
	vm.textInfoStyle = {
		width: "30px",
		cursor: "pointer",
	};
	vm.startEditing = startEditing;
	vm.pageEditorKeyUp = pageEditorKeyUp;

	vm.requestedPageNumber = vm.currentPageNumber || 0;
	const debouncedRequestPage = debounce(firePageNumberRequested, 300);
	setupControl();
	setupTextInfoStyle();

	$scope.$watch("vm.control", setupControl);

	$scope.$watch("vm.currentPageNumber", () => {
		vm.requestedPageNumber = vm.currentPageNumber;
	});

	$scope.$watch("vm.numberOfPages", setupTextInfoStyle);

	function setupControl() {
		if(vm.control) {
			vm.control.processKey = processKey;
		}
	}

	function setupTextInfoStyle() {
		if(vm.numberOfPages >= 1) {
			vm.textInfoStyle.cursor = vm.numberOfPages > 1 ? "pointer" : undefined;
			vm.textInfoStyle.width = ((vm.numberOfPages.toString().length * 2 + 4) * 8) + "px";
		}
	}

	function processKey(keyCode) {
		switch(keyCode) {
		case 36: // Home
			goToFirstPageClicked();
			return true;
		case 35: // End
			goToLastPageClicked();
			return true;
		case 33: // PgUp
			goToPreviousPageClicked();
			return true;
		case 34: // PgDn
			goToNextPageClicked();
			return true;
		default:
			return false;
		}
	}

	function goToFirstPageClicked() {
		setRequestedPageNumber(0);
	}

	function goToPreviousPageClicked() {
		setRequestedPageNumber(vm.requestedPageNumber - 1);
	}

	function goToNextPageClicked() {
		setRequestedPageNumber(vm.requestedPageNumber + 1);
	}

	function goToLastPageClicked() {
		setRequestedPageNumber(vm.numberOfPages - 1);
	}

	function startEditing() {
		if(vm.numberOfPages > 1) {
			vm.isEditing = true;
			vm.editedPageNumber = vm.currentPageNumber + 1;
			$timeout(() => {
				$element.find("input").focus().select();
			}, 10);
		}
	}

	function pageEditorKeyUp(keyCode) {
		switch(keyCode) {
		case 27: // Esc
		case 0: // blur
			vm.isEditing = false;
			vm.editedPageNumber = 0;
			event.preventDefaultAction = true;
			break;
		case 13: // Enter
			// eslint-disable-next-line no-case-declarations
			const pageNumber = +vm.editedPageNumber;
			if(Utilities.isInteger(pageNumber) && 1 <= pageNumber && pageNumber <= vm.numberOfPages) {
				setRequestedPageNumber(pageNumber - 1);
			}
			vm.isEditing = false;
			vm.editedPageNumber = 0;
			event.preventDefaultAction = true;
			break;
		}
	}

	function getNumberOfPages() {
		return vm.numberOfPages || 0;
	}

	function setRequestedPageNumber(pageNumber) {
		const requestedPageNumber = clampPageNumber(pageNumber);
		if(vm.requestedPageNumber !== requestedPageNumber) {
			vm.requestedPageNumber = requestedPageNumber;
			debouncedRequestPage();
		}
	}

	function clampPageNumber(pageNumber) {
		const numberOfPages = getNumberOfPages();
		if(!numberOfPages) {
			return 0;
		}
		pageNumber = Math.max(0, pageNumber);
		pageNumber = Math.min(pageNumber, numberOfPages - 1);
		return pageNumber;
	}

	function firePageNumberRequested() {
		const onPageNumberRequested = vm.onPageNumberRequested();
		if(onPageNumberRequested) {
			onPageNumberRequested(vm.requestedPageNumber);
		}
	}
}