angular.module("eShareApp").factory("dataSourceRepository", [
	"repositoryBase", "notification",

	function (
		repositoryBase, notification
	) {

		function getAvailableDataSources(projectId) {
			return repositoryBase.get(
				"/api/project/{projectId}/DataSourceInfo", { projectId: projectId }
			);
		}

		function getAvailableDataSourcesForKeyType(projectId, keyType) {
			return repositoryBase.post(
				"/api/project/{projectId}/DataSourceInfoForKeyType", { projectId: projectId }, keyType
			);
		}

		function getAvailableDataSourcesForKeyTypes(projectId, keyTypes) {
			return repositoryBase.post(
				"/api/project/{projectId}/DataSourceInfoForKeyTypes", { projectId: projectId }, keyTypes
			);
		}

		function getCategorizationDataSourceInfo(projectId) {
			return repositoryBase.get(
				"/api/project/{projectId}/CategorizationDataSourceInfo", { projectId: projectId }
			);
		}

		function getAvailableDocumentDataSources(projectId) {
			return repositoryBase.get(
				"/api/project/{projectId}/documentDataSources", { projectId: projectId }
			);
		}

		function getConfigurationView(projectId, dataSourceId) {
			return repositoryBase
				.get(
					"/api/project/{projectId}/ConfigurationEditor/GetDataSourceConfigurationTemplate/"
					+ "{dataSourceId}",
					{ projectId: projectId, dataSourceId: dataSourceId }
				)
				.then(data => {
					return data.template;
				}, error => {
					notification.error(error);
				});
		}

		function getConfigurationController(projectId, dataSourceId) {
			return repositoryBase
				.get(
					"/api/project/{projectId}/ConfigurationEditor/GetDataSourceConfigurationScript/"
					+ "{dataSourceId}",
					{ projectId: projectId, dataSourceId: dataSourceId }
				)
				.then(data => {
					return data.script;
				}, error => {
					notification.error(error);
				});
		}

		function create(project, dataSourceTypeGuid) {
			return repositoryBase.post(
				"/api/project/{projectId}/ConfigurationEditor/CreateDataSource/{dataSourceTypeGuid}",
				{ projectId: project.id, dataSourceTypeGuid: dataSourceTypeGuid }
			);
		}

		return {
			getAvailableDataSources: getAvailableDataSources,
			getAvailableDataSourcesForKeyType: getAvailableDataSourcesForKeyType,
			getAvailableDataSourcesForKeyTypes: getAvailableDataSourcesForKeyTypes,
			create: create,
			getConfigurationView: getConfigurationView,
			getConfigurationController: getConfigurationController,
			getCategorizationDataSourceInfo: getCategorizationDataSourceInfo,
			getAvailableDocumentDataSources: getAvailableDocumentDataSources,
		};
	}
]);
