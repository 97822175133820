angular.module("eShareApp").controller("ProjectExternalDocumentAdminCtrl", [
	"$state", "$scope", "project", "documentConfigurationsRepository", "pdfDocumentConfiguration",
	"messageBox", "notification",

	function (
		$state, $scope, project, documentConfigurationsRepository, pdfDocumentConfiguration,
		messageBox, notification
	) {

		$scope.project = project;
		$scope.pdfConfiguration = pdfDocumentConfiguration;

		if(!$scope.pdfConfiguration.documentTypeConfigurations) {
			$scope.pdfConfiguration.documentTypeConfigurations = [];
		}

		$scope.addDocumentType = function () {
			$state.go(".editDocType", {
				projectId: project.id,
				documentTypeIndex: -1,
			});
		};

		$scope.addDocumentFormat = function () {
			$state.go(".editDocFormat", {
				projectId: project.id,
				documentFormatIndex: -1,
			});
		};

		$scope.removeDocumentType = function (documentType) {
			const mbox = messageBox.openQuestion(
				"Remove selected document type?",
				"Do you really want to remove the " + documentType.name + " document type?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					_.remove($scope.pdfConfiguration.documentTypeConfigurations, documentType);
					documentConfigurationsRepository.savePdfSettings(
						$scope.project.id, $scope.pdfConfiguration
					);
				}
			);
		};

		$scope.removeDocumentConversionFormat = function (documentConversionFormat) {
			const mbox = messageBox.openQuestion(
				"Remove selected document conversion format?",
				"Do you really want to remove the " + documentConversionFormat.name
				+ " document conversion format?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					_.remove(
						$scope.pdfConfiguration.documentConversionFormats, documentConversionFormat
					);
					documentConfigurationsRepository.savePdfSettings(
						$scope.project.id, $scope.pdfConfiguration
					);
				}
			);
		};

		$scope.checkFormatNotInUse = function (documentConversionFormat) {
			for(let i = 0; i < $scope.pdfConfiguration.documentTypeConfigurations.length; i++) {
				if($scope.pdfConfiguration
					.documentTypeConfigurations[i].conversionFormat === documentConversionFormat.name) {
					return false;
				}
			}
			return true;
		};

		$scope.getTargetDataSourceString = function (targetDataSource) {
			if(targetDataSource.indexOf(":::::") === -1) {
				return "";
			}
			return targetDataSource.substring(0, targetDataSource.indexOf(":::::"));
		};

		function saveConfiguration() {
			documentConfigurationsRepository
				.savePdfSettings($scope.project.id, $scope.pdfConfiguration)
				.then(() => {
				}, error => {
					notification.error(error);
				});
		}

		$scope.saveConfiguration = saveConfiguration;
	}
]);
