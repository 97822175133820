angular.module("eShareApp").controller("Model3dCtrl", [
	"$rootScope", "$scope", "$http", "$timeout", "$state", "$stateParams", "notification",
	"model3dState", "eBrowser", "searchService", "project", "attributesService", "integrations",
	"pointOfInterestKindRepository", "statusTrackingKindRepository", "markupsRepository", "$window",
	"attributeDefinitions", "coordinateAttributes", "markupPhotosUploadService",

	function (
		$rootScope, $scope, $http, $timeout, $state, $stateParams, notification,
		model3dState, eBrowser, searchService, project, attributesService, integrations,
		pointOfInterestKindRepository, statusTrackingKindRepository, markupsRepository, $window,
		attributeDefinitions, coordinateAttributes, markupPhotosUploadService
	) {

		if(!project || !project.id) {
			notification.error("Project not found");
			$state.go("selectProject", {}, { location: "replace" });
			return;
		}

		const projectMarkupPhotosService = markupPhotosUploadService.forProject(project);
		$scope.maxMultiselectCount = window.eShare.maxMultiselectCount;

		setupPoiIcons();

		function setupPoiIcons() {
			pointOfInterestKindRepository.getAll($scope.project.id).then(response => {
				if(!response) {
					return;
				}
				$scope.pointOfInterestIcons = {};
				_.forEach(response, poiKind => {
					$scope.pointOfInterestIcons[poiKind.id] = {
						color: poiKind.color,
						url: poiKind.iconUrl + "&iconType=main",
					};
				});
			});
		}

		$scope.isCoordinateAttribute = function isCoordinateAttribute(attribute) {
			const eShareKey = attribute.eShareKey;
			if(!eShareKey || eShareKey.keyType !== "Tag" || !eShareKey.tags) {
				return false;
			}
			const tags = eShareKey.tags;
			if(tags.length !== 1) {
				return false;
			}
			const abbreviation = tags[0].attribute;
			return _.includes(coordinateAttributes, abbreviation);
		};

		eBrowser.hideMessage();

		function getParameter(value) {
			return _.isString(value) ? value.trim() : "";
		}

		let positionId = getParameter($stateParams.positionId);
		let tag = getParameter($stateParams.tag);
		let geometryId = getParameter($stateParams.geometryId);
		let modelTimestamp = getParameter($stateParams.modelTimestamp);
		let examineFlags = getParameter($stateParams.examineFlags);
		if(examineFlags === undefined || examineFlags === "") {
			// Examine flags default to 1, which means orbit is active but other examine bar features
			// are not
			examineFlags = 1;
		}
		let visualStyleName = getParameter($stateParams.visualStyleName);
		if(visualStyleName === undefined || visualStyleName === "") {
			visualStyleName = "";
		}
		let hierarchyName = getParameter($stateParams.hierarchyName);
		if(hierarchyName === undefined || hierarchyName === "") {
			hierarchyName = "";
		}
		eBrowser.changeHierarchy(hierarchyName);

		let hierarchyBranchPath = getParameter($stateParams.examineBranchPath);
		if(hierarchyBranchPath === undefined || hierarchyBranchPath === "") {
			hierarchyBranchPath = "";
		}

		// Defaults to "Current", which means don't change tree visibility. Value "Show" is used to
		// show the tree, "Hide" to hide it.
		let treeVisibility = getParameter($stateParams.treeVisibility);
		if(treeVisibility === undefined || treeVisibility === "") {
			treeVisibility = "Current";
		}
		eBrowser.setModelTreeVisibile(treeVisibility);

		// Defaults to "Current", which means don't change pane visibility. Value "Show" is used to
		// show the tree, "Hide" to hide it.
		let attributePaneVisibility = getParameter($stateParams.attributePaneVisibility);
		if(attributePaneVisibility === undefined || attributePaneVisibility === "") {
			attributePaneVisibility = "Current";
		}

		let externalTag = getParameter($stateParams.externalTag);
		let externalId = getParameter($stateParams.externalId);

		let groupParentTag = getParameter($stateParams.groupParentTag);
		let groupChildTag = getParameter($stateParams.groupChildTag);
		let groupId = getParameter($stateParams.groupId);
		let groupGuidId = getParameter($stateParams.groupGuidId);

		let pointId = getParameter($stateParams.pointId);

		let markupId = getParameter($stateParams.markupId);

		const pointCloud = getParameter($stateParams.pointCloud);

		let multiExamine = getParameter($stateParams.multiExamine);

		let poiKind = getParameter($stateParams.poiKind);
		let pointReference = getParameter($stateParams.pointReference);

		const searchFor = getParameter($stateParams.searchFor);
		const searchScope = getParameter($stateParams.searchScope);
		const createSmartPoint = getParameter($stateParams.createSmartPoint);

		//  if other type of examine was requested also, do not examine by the tree branch
		if(positionId && positionId !== ""
			|| geometryId && geometryId !== ""
			|| groupId && groupId !== ""
			|| pointId && pointId !== ""
			|| markupId && markupId !== ""
			|| pointCloud && pointCloud !== ""
			|| multiExamine && multiExamine !== ""
			|| searchFor && searchFor !== "") {
			hierarchyBranchPath = "";
		}

		if(hierarchyName === ""
			&& Utilities.isString(hierarchyBranchPath)
			&& hierarchyBranchPath !== "") {
			// no hierarchy change requested so we can examine the tree now.
			eBrowser.examineBranch(hierarchyBranchPath, examineFlags);
			hierarchyBranchPath = "";
		}

		//  if   visual style was requested, do it now.
		if(Utilities.isString(visualStyleName) && visualStyleName !== "") {
			eBrowser.changeVisualStyle(visualStyleName);
		}

		const location = {
			x: getParameter($stateParams.x),
			y: getParameter($stateParams.y),
			z: getParameter($stateParams.z),
			rotation: getParameter($stateParams.r),
			slope: getParameter($stateParams.s),
		};

		let geometryIds = [];

		$scope.isEbrowserSupported = function () {
			return eBrowser.isSupported();
		};

		$scope.getCategoryVisibility = function (categoryName, categoryIndex) {
			if($scope.categoryVisibilityDictionary === undefined) {
				if($window.localStorage.getItem("categoryVisibilityDictionary")) {
					const jsonString = $window.localStorage.getItem("categoryVisibilityDictionary");
					try {
						$scope.categoryVisibilityDictionary = JSON.parse(jsonString);
					} catch(e) {
						$scope.categoryVisibilityDictionary = [];
						// Something has gone wrong here, so resetting the local information
						$window.localStorage.removeItem("categoryVisibilityDictionary");
					}
				} else {
					$scope.categoryVisibilityDictionary = [];
				}
			}
			for(let i = 0; i < $scope.categoryVisibilityDictionary.length; i++) {
				const displayCategory = $scope.categoryVisibilityDictionary[i].displayCategory;
				if(!displayCategory) {
					continue;
				}
				if(categoryIndex === 0 && "InternalType:" + categoryName === displayCategory) {
					return $scope.categoryVisibilityDictionary[i].isHidden;
				} else if(categoryIndex !== 0 && categoryName === displayCategory) {
					return $scope.categoryVisibilityDictionary[i].isHidden;
				}
			}
			return false;
		};
		$scope.setCategoryVisibility = function (categoryName, isHidden, categoryIndex) {
			for(let i = 0; i < $scope.categoryVisibilityDictionary.length; i++) {
				const displayCategory = $scope.categoryVisibilityDictionary[i].displayCategory;
				if(!displayCategory) {
					continue;
				}
				if(categoryName === displayCategory
					|| categoryIndex === 0
						&& categoryName === displayCategory.replace("InternalType:", "")) {
					if(isHidden) {
						$scope.categoryVisibilityDictionary[i].isHidden = isHidden;
						$window.localStorage.setItem(
							"categoryVisibilityDictionary",
							JSON.stringify($scope.categoryVisibilityDictionary)
						);
						return;
					} else {
						// Only keep the item in the array if it is set to be hidden
						$scope.categoryVisibilityDictionary.splice(i, 1);
						$window.localStorage.setItem(
							"categoryVisibilityDictionary",
							JSON.stringify($scope.categoryVisibilityDictionary)
						);
						return;
					}
				}
			}
			let displayCategory = categoryName;
			if(categoryIndex === 0) {
				displayCategory = "InternalType:" + categoryName;
			}
			$scope.categoryVisibilityDictionary.push({
				displayCategory: displayCategory,
				isHidden: isHidden,
			});
			$window.localStorage.setItem(
				"categoryVisibilityDictionary",
				JSON.stringify($scope.categoryVisibilityDictionary)
			);
		};

		const searchResult = searchService.result;
		$scope.searchResults = _.cloneDeep(searchResult.objects);
		_.remove($scope.searchResults, object => {
			return object.isReference;
		});
		if(searchResult && $scope.searchResults) {
			$scope.currentSearchIndex = getCurrentSearchIndex();
		}

		function getCurrentSearchIndex() {
			try {
				for(let i = 0; i < $scope.searchResults.length; i++) {
					const sr = $scope.searchResults[i];
					if(sr.groupDefinition && sr.keyAttribute) {
						if(groupId === sr.keyAttribute.value
								&& groupChildTag === sr.groupDefinition.childTag
								&& groupParentTag === sr.groupDefinition.parentTag) {
							return i;
						}
					} else {
						if(geometryId === sr.geometryId) {
							return i;
						}
					}
				}
				return -1;
			} catch(e) {
				return -1;
			}
		}

		$scope.locateSearchObject = function (index) {
			clearParameters();
			const sr = $scope.searchResults[index];
			geometryId = sr.geometryId;
			modelTimestamp = searchResult.modelTimestamp;
			if(sr.keyAttribute) {
				positionId = sr.keyAttribute.value;
				tag = sr.keyAttribute.tag;
			}
			if(sr.groupDefinition) {
				positionId = "";
				tag = "";
				geometryId = "";
				modelTimestamp = "";
				groupChildTag = sr.groupDefinition.childTag;
				groupParentTag = sr.groupDefinition.parentTag;
				groupId = sr.keyAttribute.value;
			}
			if((groupChildTag === "" || groupParentTag === "" || groupId === "")
					&& (geometryId === "" || modelTimestamp === "")) {
				$scope.locateSearchObject(index + 1);
				return;
			}

			locateObject();
		};

		$scope.locateNextSearchObject = function () {
			$scope.locateSearchObject($scope.currentSearchIndex + 1);
		};

		$scope.locatePreviousSearchObject = function () {
			$scope.locateSearchObject($scope.currentSearchIndex - 1);
		};

		$scope.getAttributeDescription = function (attribute) {
			const eShareKey = attribute.eShareKey;
			return eShareKey
				&& eShareKey.keyType === "Tag"
				&& eShareKey.tags
				&& eShareKey.tags.length === 1
				&& eShareKey.tags[0].attribute
				? "Attribute/tag: " + eShareKey.tags[0].attribute
				: null;
		};

		$scope.getCurrentStateParams = function getCurrentStateParams() {
			return {
				positionId: positionId,
				tag: tag,
				geometryId: geometryId,
				modelTimestamp: modelTimestamp,
				externalTag: externalTag,
				externalId: externalId,
				groupParentTag: groupParentTag,
				groupChildTag: groupChildTag,
				groupId: groupId,
				pointId: pointId,
			};
		};
		let animateMovement = false;

		let moveCamera = "-1";

		if(model3dState.isModelOutdated()) {
			clearParameters();
			onModelOutdated();
		}

		// If any of the values are set, update the value of stored pointId. Otherwise the previous
		// one will be preserved (so that the point info is not lost if simply changing between tabs)
		if(positionId !== "" || tag !== "" || (geometryId !== "" && geometryId.indexOf(",") === -1)
			|| externalTag !== "" || externalId !== ""
			|| groupParentTag !== "" || groupChildTag !== "" || groupId !== ""
			|| pointId !== ""
			|| markupId !== ""
			|| pointCloud !== ""
			|| location.x !== ""
			|| pointReference !== ""
			|| searchFor !== "") {
			model3dState.pointId = parseInt(pointId);
			if(isNaN(model3dState.pointId)) {
				model3dState.pointId = "";
			}
			if($state.current.name === "project.model") {
				locateObject();
			}
		}

		if(createSmartPoint) {
			onAddPoint(null, project.id, -1, location.x, location.y, location.z);
			location.x = parseInt(location.x, 10) - 600;
			location.y = parseInt(location.y, 10) - 600;
			location.z = parseInt(location.z, 10) + 600;
			location.rotation = 45;
			location.slope = 35;
			goToLocation();
		}

		$scope.$on("$destroy", () => {
			model3dState.generateRequestId();
		});

		restartAutorefresh();

		function restartAutorefresh() {
			model3dState.isAutorefreshEnabled = true;
			if(model3dState.autorefreshData.length > 0) {
				const requestId = model3dState.generateRequestId();
				for(let i = 0; i < model3dState.autorefreshData.length; ++i) {
					const data = model3dState.autorefreshData[i];
					attributesService.getAttributesFromDataSource(
						requestId,
						project.id,
						data.dataSourceId,
						addAttributes,
						processError,
						data.key,
						1
					);
				}
			}
		}

		function clearParameters() {
			positionId = tag = geometryId = modelTimestamp = "";
			geometryIds = [];
			externalTag = externalId = "";
			groupParentTag = groupChildTag = groupId = groupGuidId = "";
			model3dState.pointId = "";
			pointId = "";
			markupId = "";
			animateMovement = false;
			examineFlags = 1;
			visualStyleName = "";
			hierarchyName = "";
			treeVisibility = "Current";
			attributePaneVisibility = "Current";
			multiExamine = "";
			poiKind = "";
			pointReference = "";
		}

		function locateObject() {
			eBrowser.isMarkupEditorDirty().then(isDirty => {
				if(isDirty) {
					eBrowser.showImportantMessage(
						"Markup Editor",
						"Please finish editing markup first.",
						"<i class='fa fa-check-circle-o fa-fw'></i> OK"
					);
					return undefined;
				}
				if(positionId !== "" || geometryId !== "" || modelTimestamp !== "") {
					if(externalTag !== ""
						|| externalId !== ""
						|| groupParentTag !== ""
						|| groupChildTag !== ""
						|| groupId !== ""
						|| model3dState.pointId !== ""
						|| markupId !== "") {
						return showBadRequestMessage();
					}
					return locateModelObject(true);
				}
				if(externalTag !== "" || externalId !== "") {
					if(groupParentTag !== ""
						|| groupChildTag !== ""
						|| groupId !== ""
						|| model3dState.pointId !== ""
						|| markupId !== "") {
						return showBadRequestMessage();
					}
					return locateExternalObject();
				}
				if(groupParentTag !== "" || groupChildTag !== "" || groupId !== "") {
					if(model3dState.pointId !== "" || markupId !== "") {
						return showBadRequestMessage();
					}
					return locateGroup(true);
				}
				if(model3dState.pointId !== "") {
					if(markupId !== "") {
						return showBadRequestMessage();
					}
					return locatePointById();
				}
				if(markupId !== "") {
					return openMarkup();
				}
				if(pointCloud !== "") {
					return locatePointCloud();
				}
				if(location.x !== "") {
					return goToLocation();
				}
				if(pointReference !== "") {
					return locatePointByKindAndReference();
				}
				if(searchFor !== "") {
					return locateByCustomSearch(true);
				}
				if(visualStyleName !== "") {
					return changeVisualStyle(visualStyleName);
				}
				return showBadRequestMessage();
			});
		}

		function changeVisualStyle(visStyleName) {
			eBrowser.changeVisualStyle(visStyleName);
		}

		function locatePointCloud() {
			eBrowser.locatePointCloud(pointCloud);
		}

		function goToLocation() {
			eBrowser.goToLocation(location);
		}

		function openMarkup() {
			model3dState.clear();
			model3dState.isLocateActive = true;
			model3dState.locatedObjectType = "markup";
			model3dState.onMarkupSavedAction = null;
			eBrowser.exitExamineMode();
			markupsRepository.getForEdit(project.id, markupId)
				.then(response => {
					if(!response) {
						notification.error("Cannot find markup " + markupId);
						return;
					}
					model3dState.isLocateActive = false;
					model3dState.latestMarkup = response;
					if(model3dState.latestMarkup.history) {
						const formattedHistory = [];
						let entryIndex = 1;
						for(let i = 0; i < model3dState.latestMarkup.history.length; i++){
							const entry = model3dState.latestMarkup.history[i];

							const entryTime = entry.entryTime;
							if(entryTime) {
								const date = moment.utc(entryTime);
								entry.entryTime = date.isValid() ? date.local().format("LLL") : entryTime;
							}

							entry.changes =
								entry.changes?.filter(change => change.fromValue !== change.toValue);

							const hasChanges = entry.changes != null && entry.changes.length > 0;
							const hasImage = entry.hasExternalImage;
							const hasComment = entry.comment != null && entry.comment.trim() !== "";
							const isEmptyEntry = !hasChanges
														&& !hasImage
														&& !hasComment;

							if(!isEmptyEntry) {
								entry.index = entryIndex++;
								formattedHistory.push(entry);
							}
						}

						model3dState.latestMarkup.history = formattedHistory;
					}
					model3dState.latestMarkup.isNew = false;
					if(!model3dState.latestMarkup.markupType.isEditable) {
						_.forEach(_.filter(model3dState.latestMarkup.markupType.attributeKinds, {
							"dataType": "Enumeration",
						}), enumAttributeKind => {
							const enumAttribute =
								model3dState.latestMarkup.attributeValues[enumAttributeKind.id];
							enumAttribute.value = _.find(enumAttributeKind.enumValues, {
								id: enumAttribute.enumId,
							}).value;
						});

					}
					model3dState.latestMarkup.originalValues = _.cloneDeep(response.attributeValues);
					if(response.ebxFileContents) {
						eBrowser.openMarkup(response.ebxFileContents, response.isEditAllowed);
					} else {
						notification.error("Cannot edit markup " + markupId);
					}
				}, (/*reason*/) => {
					model3dState.isLocateActive = false;
					notification.error("Cannot edit markup " + markupId);
				});
		}

		function locatePointByKindAndReference() {
			searchService.locate({
				projectId: project.id,
				positionId: "",
				tag: "",
				geometryId: "",
				poiKind: poiKind,
				pointReference: pointReference,
				modelTimestamp: modelTimestamp,
				searchFor: "",
				searchScope: "",
			}).then(
				data => {
					if(!data) {
						return;
					}
					if(data.hasModelExpired) {
						onModelOutdated();
						return;
					}
					if(data.pointId === 0) {
						const searchRequest = _.cloneDeep(data.searchRequest);
						$state.go("project.search", {
							projectId: project.id,
							isAdvanced: searchRequest.isAdvanced,
							text: searchRequest.text,
							scope: searchRequest.scope,
							terms: searchService.serializeTerms(searchRequest.terms),
							searchTarget: searchRequest.searchTarget,
						}, {
							reloadOnSearch: true,
							location: "replace",
						});
						return;
					}
					model3dState.pointId = data.pointId;
					locatePointById();
				},
				reason => {
					switch(reason.status) {
					case 400:
						// Bad request
						notification.error("Bad locate request: " + reason.message);
						showBadRequestMessage();
						break;
					case 404:
						// Not found
						if(pointReference !== "") {
							searchService.clear();
							let poiKindId = "-1";
							if(poiKind) {
								pointOfInterestKindRepository.getAll(project.id).then(allKinds => {
									const kindObjects = _.filter(allKinds, { "name": poiKind });
									if(kindObjects && kindObjects.length > 0) {
										poiKindId = kindObjects[0].id;
									}
									$state.go("project.search", {
										projectId: project.id,
										isAdvanced: true,
										text: "",
										scope: "",
										terms: "((pointKind)):eq:" + poiKindId
											+ "|((externalId)):eq:" + pointReference,
										searchTarget: "Points",
									}, {
										reload: true,
										inherit: false,
									});
								}, () => {
									eBrowser.showImportantMessage(
										"Locate object",
										"The requested smart point not found",
										"<i class='fa fa-check-circle-o fa-fw'></i> OK"
									);
								});
							} else {
								$state.go("project.search", {
									projectId: project.id,
									isAdvanced: true,
									text: "",
									scope: "",
									terms: "((pointKind)):eq:" + poiKindId
										+ "|((externalId)):eq:" + pointReference,
									searchTarget: "Points",
								}, {
									reload: true,
									inherit: false,
								});
							}
						} else {
							eBrowser.showImportantMessage(
								"Locate object",
								"The requested smart point not found",
								"<i class='fa fa-check-circle-o fa-fw'></i> OK"
							);
						}
						break;
					case 0:
					case -1:
						eBrowser.showImportantMessage(
							"Locate object",
							"Server unavailable",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						break;
					default:
						notification.error("Error " + reason.status + " while locating smart point");
						eBrowser.showImportantMessage(
							"Locate object",
							"Error " + reason.status + " while locating the requested smart point",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						break;
					}
				}
			);
		}

		let lastExamineTime = null;

		function locateModelObject(doExamine, pGeometryId, pModelTimestamp, pMoveCamera,
			requestTimeStamp) {
			model3dState.isLocateActive = true;
			searchService.locate({
				projectId: project.id,
				positionId: positionId,
				tag: tag,
				geometryId: pGeometryId || geometryId,
				modelTimestamp: pModelTimestamp || modelTimestamp,
				poiKind: "",
				pointReference: "",
				searchFor: "",
				searchScope: "",
			}).then(
				data => {
					if(!data) {
						return;
					}
					if(data.hasModelExpired && data.geometryId === -1) {
						onModelOutdated();
						return;
					}
					if(lastExamineTime && requestTimeStamp && lastExamineTime > requestTimeStamp) {
						return;
					}
					lastExamineTime = requestTimeStamp;
					positionId = tag = "";
					externalTag = externalId = "";
					groupParentTag = groupChildTag = groupId = "";
					model3dState.pointId = "";
					geometryIds.length = 0;
					geometryId = data.geometryId;
					modelTimestamp = data.modelTimestamp;
					if(pMoveCamera) {
						moveCamera = pMoveCamera;
					}
					model3dState.locatedObjectType = "modelObject";
					if(data.geometryId !== -1) {
						geometryId = data.geometryId;
						modelTimestamp = data.modelTimestamp;
						if(doExamine) {
							eBrowser.locateByGeometryId(
								geometryId, modelTimestamp, moveCamera, examineFlags
							);
							eBrowser.setModelTreeVisibile(treeVisibility);
							setAttributePanelVisibility(attributePaneVisibility);
						}
						getStatusTrackingKinds()
							.then(() => showAttributesByGeometryId(data.geometryId, data.modelTimeStamp));
					} else {
						if(data.isGroup) {
							getStatusTrackingKinds().then(() =>
								showCategory(
									data.parentGroupTag, data.childGroupTag, data.groupId, false, true, 1, ""
								));
						} else {
							const searchRequest = _.cloneDeep(data.searchRequest);
							searchRequest.projectId = project.id;
							$state.go("project.search", searchRequest, {
								reloadOnSearch: true,
								location: "replace",
							});
						}
					}
				},
				reason => {
					switch(reason.status) {
					case 400:
						// Bad request
						notification.error("Bad locate request: " + reason.message);
						showBadRequestMessage();
						break;
					case 404:
						// Not found
						if(positionId !== "" && geometryId === "" && modelTimestamp === "") {
							$state.go("project.search", { text: positionId }, { location: "replace" });
						} else {
							eBrowser.showImportantMessage(
								"Locate object",
								"The requested 3D object not found",
								"<i class='fa fa-check-circle-o fa-fw'></i> OK"
							);
						}
						break;
					case 0:
					case -1:
						eBrowser.showImportantMessage(
							"Locate object",
							"Server unavailable",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						break;
					default:
						notification.error("Error " + reason.status + " while locating 3D object");
						eBrowser.showImportantMessage(
							"Locate object",
							"Error " + reason.status + " while locating the requested 3D object",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						break;
					}
				}
			);
		}

		function locateByCustomSearch(doExamine) {
			model3dState.isLocateActive = true;
			searchService.locate({
				projectId: project.id,
				positionId: "",
				tag: tag || "",
				geometryId: "",
				modelTimestamp: modelTimestamp,
				poiKind: poiKind || "",
				pointReference: "",
				searchFor: searchFor,
				searchScope: searchScope || "",
			}).then(
				data => {
					if(!data) {
						return;
					}
					if(data.searchRequest) {
						const searchRequest = _.cloneDeep(data.searchRequest);
						$state.go("project.search", {
							projectId: project.id,
							isAdvanced: searchRequest.isAdvanced,
							text: searchRequest.text,
							scope: searchRequest.scope,
							terms: searchService.serializeTerms(searchRequest.terms),
							searchTarget: searchRequest.searchTarget,
						}, {
							reloadOnSearch: true,
							location: "replace",
						});
						return;
					}
					if(data.pointId > 0) {
						if(data.hasModelExpired) {
							onModelOutdated();
							return;
						}
						model3dState.pointId = data.pointId;
						locatePointById();
						return;
					}
					if(data.hasModelExpired && data.geometryId === -1) {
						onModelOutdated();
						return;
					}
					model3dState.locatedObjectType = "modelObject";
					if(data.geometryId !== -1) {
						geometryId = data.geometryId;
						modelTimestamp = data.modelTimestamp;
						if(doExamine) {
							eBrowser.locateByGeometryId(
								geometryId, modelTimestamp, moveCamera, examineFlags
							);
							eBrowser.setModelTreeVisibile(treeVisibility);
							setAttributePanelVisibility(attributePaneVisibility);
						}
						getStatusTrackingKinds().then(() => showAttributesByGeometryId());
					} else {
						if(data.isGroup) {
							getStatusTrackingKinds().then(() =>
								showCategory(
									data.parentGroupTag, data.childGroupTag, data.groupId, false, true, 1, ""
								));
						}
					}
				},
				reason => {
					switch(reason.status) {
					case 400:
						// Bad request
						notification.error("Bad locate request: " + reason.message);
						showBadRequestMessage();
						break;
					case 404:
						eBrowser.showImportantMessage(
							"Locate object",
							"The requested 3D object or smart point could not be found",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						break;
					case 0:
					case -1:
						eBrowser.showImportantMessage(
							"Locate object",
							"Server unavailable",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						break;
					default:
						notification.error(
							"Error " + reason.status + " while locating 3D object or smart point"
						);
						eBrowser.showImportantMessage(
							"Locate object",
							"Error " + reason.status
							+ " while locating the requested 3D object or smart point",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						break;
					}
				}
			);
		}

		function locateExternalObject() {
			if(externalTag === "" || externalId === "") {
				showBadRequestMessage();
				return;
			}
			model3dState.isLocateActive = true;
			model3dState.locatedObjectType = "externalObject";
			eBrowser.exitExamineMode();
			eBrowser.showMessage(
				"External reference",
				"There is no 3D object to display for the requested external reference",
				"<i class='fa fa-check-circle-o fa-fw'></i> OK"
			);
			showAttributes("Search", externalTag, externalId, false);
		}

		function locateGroup(doExamine) {
			if(groupParentTag === "" || groupChildTag === "" || groupId === "") {
				showBadRequestMessage();
				return;
			}
			model3dState.isLocateActive = true;
			model3dState.locatedObjectType = "group";

			if(doExamine) {
				eBrowser.locateGroup(
					groupParentTag, groupChildTag, groupId, animateMovement, examineFlags
				);
				eBrowser.setModelTreeVisibile(treeVisibility);
				setAttributePanelVisibility(attributePaneVisibility);
			}
			getStatusTrackingKinds().then(() =>
				showAttributes("Model", groupParentTag, groupId, true, groupGuidId));
		}

		function locatePointById() {
			if(model3dState.pointId === "") {
				showBadRequestMessage();
				return;
			}
			model3dState.isLocateActive = true;
			model3dState.locatedObjectType = "point";
			eBrowser.exitExamineMode();
			eBrowser.locatePoint(model3dState.pointId);
			showPointAttributes();
		}

		function showAttributes(category, requestedTag, requestedValue, isGroup, guidId) {
			model3dState.positionId = "";
			model3dState.attributes.length = 0;
			model3dState.attributesView.length = 0;
			model3dState.autorefreshData.length = 0;
			model3dState.isAutorefreshEnabled = true;
			const requestId = model3dState.generateRequestId();
			const uri = "api/project/{projectId}/ObjectAttributes/ResolveTag".supplant({
				projectId: project.id,
			});
			$http.post(uri, {
				requestId: requestId,
				key: {
					keyType: "Tag",
					tags: [
						{
							attribute: requestedTag,
							value: "",
						}
					],
				},
			}).then(
				response => {
					const data = response.data;
					if(!model3dState.isCurrentRequestId(data.requestId)) {
						return;
					}
					addAttributes({
						requestId: data.requestId,
						attributes: [
							{
								eShareKey: {
									keyType: "Tag",
									tags: [
										{
											attribute: requestedTag,
											value: requestedValue,
										}
									],
								},
								displayCategory: category,
								displayName: data.displayName || requestedTag,
								value: requestedValue,
								isKey: true,
							}
						],
					}, 0);
					attributesService.getAttributes(
						data.requestId,
						project.id,
						addAttributes,
						processError,
						[{
							keyType: "Tag",
							tags: [
								{
									attribute: requestedTag,
									value: requestedValue,
									isGroup: isGroup,
									guidId: guidId === "" ? null : guidId
								}
							],
						}]
					);
					if(isGroup
						&& requestedTag
						&& requestedTag[0] === "["
						&& requestedTag[requestedTag.length - 1] === "]") {
						requestedTag = requestedTag.substring(1, requestedTag.length);
						requestedTag = requestedTag.substring(0, requestedTag.length - 1);
						attributesService.getAttributes(
							data.requestId,
							project.id,
							addAttributes,
							processError,
							[{
								keyType: "Tag",
								tags: [
									{
										attribute: requestedTag,
										value: requestedValue,
										isGroup: true,
										guidId: guidId === "" ? null : guidId
									}
								],
							}]
						);
					}
				},
				processError
			);
		}

		function showPointAttributes() {
			model3dState.positionId = "";
			model3dState.attributes.length = 0;
			model3dState.attributesView.length = 0;
			model3dState.autorefreshData.length = 0;
			model3dState.isAutorefreshEnabled = true;
			const requestId = model3dState.generateRequestId();
			const uri = "/api/project/{projectId}/PointsOfInterest/ResolveById".supplant({
				projectId: project.id,
			});
			$http.post(uri, {
				requestId: requestId,
				pointId: model3dState.pointId,
			}).then(
				response => {
					const data = response.data;
					if(!model3dState.isCurrentRequestId(data.requestId)) {
						return;
					}
					model3dState.isEditable = data.isEditable;
					model3dState.isDeletable = data.isDeletable;
					attributesService.getAttributes(
						data.requestId,
						project.id,
						addAttributes,
						processError,
						[{
							keyType: "PoiId",
							pointId: model3dState.pointId,
						}]
					);
					attributesService.getAttributes(
						data.requestId,
						project.id,
						addAttributes,
						processError,
						[{
							keyType: "PointOfInterest",
							pointOfInterestKind: data.kind,
							externalId: data.externalId,
						}]
					);
				},
				processError
			);
		}

		function getAttributeByCommandTypeAndTag(commandType, attributeTag) {
			switch(commandType) {
			case "byTag":
				return getAttributeByTag(attributeTag);
			case "byId":
				return model3dState.positionId;
			case "showProject":
				return project.name;
			default:
				return null;
			}
		}

		model3dState.getAttributeByCommandTypeAndTag = getAttributeByCommandTypeAndTag;

		function getAttributeByTag(attributeTag) {
			if(attributeTag === null) {
				return null;
			}
			try {
				for(let i = 0; i < model3dState.attributes.length; i++) {
					if(model3dState.attributes[i].eShareKey.tags[0].attribute === attributeTag) {
						return model3dState.attributes[i].value;
					}
				}
			} catch(e) {
				//alert(e);
			}
			return null;
		}

		function getGuidIdByTag(attributeTag) {
			if(attributeTag === null) {
				return null;
			}
			try {
				for(let i = 0; i < model3dState.attributes.length; i++) {
					if(model3dState.attributes[i].eShareKey.tags[0].attribute === attributeTag) {
						return model3dState.attributes[i].eShareKey.tags[0].guidId;
					}
				}
			} catch(e) {
				//alert(e);
			}
			return null;
		}

		model3dState.getAttributeByTag = getAttributeByTag;

		$scope.integrations = integrations;

		$scope.modelState = model3dState;
		$scope.modelState.geometryIds = [];

		$scope.projectId = project.id;

		$scope.attributes = model3dState.attributes;
		$scope.attributesView = model3dState.attributesView;

		$scope.pointOfInterestKinds = [];
		$scope.markupKinds = [];
		$scope.nameAutoCompletionTag = "((assignee))";

		let lastWidth = NaN;
		let currentWidth = lastWidth;
		let ignoreResizes = false;

		$scope.splitterOptions = {
			context: "model3d",
			storageKey: "model3d.splitter",
			orientation: "horizontal",
			afterMax: "50%",
			initialSizeAfter: "30%",
			isAfterMinimized: attributePaneVisibility.toLowerCase() === "hide",
			onResize: onInfoPanelResize,
			onDragStart: eBrowser.onDragStart,
			onDrag: eBrowser.onDrag,
			onDragEnd: eBrowser.onDragEnd,
		};

		function onInfoPanelResize(data) {
			const size = data.sizeAfter;
			$rootScope.$broadcast("CadChart::ResizeWidth", size - 30.0);
			currentWidth = size;
			if(ignoreResizes) {
				return;
			}
			lastWidth = size;
		}

		function setInfoPanelSize(width) {
			if(isNaN(width)) {
				return;
			}
			$scope.splitterOptions.resizeAfter(width);
			$rootScope.$broadcast("CadChart::ResizeWidth", width - 30.0);
			currentWidth = width;
		}

		function setAttributePanelVisibility(visibility) {
			if(visibility.toLowerCase() === "hide") {
				$scope.splitterOptions.minimizeAfter();
			} else if(visibility.toLowerCase() === "show") {
				$scope.splitterOptions.restore();
			}
		}

		function showBadRequestMessage() {
			eBrowser.showImportantMessage(
				"Locate object",
				"Bad locate request parameters",
				"<i class='fa fa-check-circle-o fa-fw'></i> OK"
			);
		}

		$scope.$on("eBrowser::ShowMessage", onShowMessage);
		$scope.$on("eBrowser::ObjectRequested", onExamineObject);
		$scope.$on("eBrowser::MultipleObjectsRequested", onExamineMultipleObjects);
		$scope.$on("eBrowser::GroupRequested", onExamineGroup);
		$scope.$on("eBrowser::ModelOutdated", onModelOutdated);
		$scope.$on("eBrowser::PointRequested", onExaminePoint);
		$scope.$on("eBrowser::AddPoint", onAddPoint);
		$scope.$on("eBrowser::ShowExamineData", onShowExamineData);
		$scope.$on("eBrowser::HierarchyChanged", onHierarchyChanged);
		$scope.$on("eBrowser::MarkupCreationStarted", onMarkupCreationStarted);
		$scope.$on("eBrowser::MarkupEditSave", onMarkupEditSave);

		function onMarkupCreationStarted(event, projectId, referenceObjectId) {
			model3dState.clear();
			model3dState.isLocateActive = false;
			model3dState.locatedObjectType = "markup";
			model3dState.latestMarkup = {
				"point": { "name": "" },
				"markupType": {},
				"attributeValues": {},
				"isNew": true,
				"originalValues": {},
			};
			$scope.markupKinds = [];

			if(!referenceObjectId || referenceObjectId === -1) {
				model3dState.isObjectPointCloud = true;
			} else {
				model3dState.isObjectPointCloud = false;
			}

			pointOfInterestKindRepository.getAllCreatable($scope.projectId).then(result => {
				$scope.markupKinds = _.filter(result, { "isMarkup": true });
				const markupIds = _.map($scope.markupKinds, "id");
				const markupId = Math.min.apply(null, markupIds);
				model3dState.latestMarkup.markupType.id = markupId;
				$scope.updateMarkupKind();

				if(model3dState.isObjectPointCloud) {
					$scope.modelAttributes = [];
					model3dState.latestMarkup.point.referenceObjectAbbreviation = "";
				} else {
					eBrowser.getModelTimestamp().then(modelTimestamp => {
						attributesService.getModelAttributes(projectId, {
							keyType: "GeometryId",
							geometryId: referenceObjectId,
							modelImportDate: modelTimestamp,
						}).then(modelAttributes => {
							model3dState.latestMarkup.point.referenceObjectId = referenceObjectId;
							let keyIndex = -1;
							let groupIndex = -1;
							let displayNameIndex = -1;
							let valueIndex = -1;
							let eShareKeyIndex = -1;
							const columnDefinitionCount =
								modelAttributes.data.attributes.columnDefinitionIds.length;
							for(let i = 0; i < columnDefinitionCount; i++) {
								switch(modelAttributes.data.attributes.columnDefinitionIds[i]) {
								case "isKey":
									keyIndex = i;
									break;
								case "isGroup":
									groupIndex = i;
									break;
								case "displayName":
									displayNameIndex = i;
									break;
								case "value":
									valueIndex = i;
									break;
								case "eShareKey":
									eShareKeyIndex = i;
									break;
								}
							}
							$scope.modelAttributes = [];

							// Selection logic for referenceObjectAbbreviation:
							// 1. If the object to which we are adding a markup has a key,
							//    select the key attribute. Else:
							// 2. If an attribute was selected earlier (when adding a previous point),
							//    and that attribute exists on the new object, select this attribute.
							//    Else:
							// 3. Select the first group attribute.

							// Check for option #2 (whether the previously-selected attribute
							// still exists). Also, create a list of all attributes for the
							// drop-down ($scope.modelAttributes).
							let foundPreviousAbbreviation = false;
							_.forEach(modelAttributes.data.attributes.data, attribute => {
								if(attribute[keyIndex] === "true" || attribute[groupIndex] === "true") {
									const key = angular.fromJson(attribute[eShareKeyIndex]);
									let guidId = null;
									if(key !== null
										&& key !== undefined
										&& key.tags !== undefined
										&& key.tags.length > 0
										&& key.tags[0].guidId !== null
										&& key.tags[0].guidId !== undefined
										&& !Utilities.isEmptyGuid(key.tags[0].guidId)){
											guidId = key.tags[0].guidId;
										}
									$scope.modelAttributes.push({
										displayName: attribute[displayNameIndex] + " (" + attribute[valueIndex] + ")",
										value: attribute[valueIndex],
										guid: guidId,
										abbreviation: key.tags[0].attribute,
										isKey: attribute[keyIndex] === "true",
										isGroup: attribute[groupIndex] === "true",
									});
									const prevAbbreviation =
										model3dState.latestMarkup.point.referenceObjectAbbreviation;
									if(key.attribute === prevAbbreviation) {
										foundPreviousAbbreviation = true;
									}
								}
							});
							if(!foundPreviousAbbreviation) {
								model3dState.latestMarkup.point.referenceObjectAbbreviation = "";
							}

							// Select the correct attribute (see rules above):
							_.forEach(modelAttributes.data.attributes.data, attribute => {
								if(attribute[keyIndex] === "true" || attribute[groupIndex] === "true") {
									const key = angular.fromJson(attribute[eShareKeyIndex]);
									if(attribute[keyIndex] === "true"
										|| model3dState.latestMarkup.point
											.referenceObjectAbbreviation === "") {
										model3dState.latestMarkup.point
											.referenceObjectAbbreviation = key.tags[0].attribute;
									}
								}
							});
						}, error => {
							notification.error(error);
						});
					});
				}
			});
		}

		$scope.cancelMarkup = function () {
			eBrowser.exitMarkupMode(false);
			model3dState.latestMarkup = null;
			model3dState.arePhotosWaiting = false;
		};

		$scope.saveMarkup = function (onSavedAction) {
			if(typeof onSavedAction === "function") {
				model3dState.onMarkupSavedAction = onSavedAction;
			}
			model3dState.arePhotosWaiting = false;
			eBrowser.exitMarkupMode(true);
		};

		function onMarkupEditSave(event, projectId, ebxFileContents, markupGuid) {
			const onSavedAction = model3dState.onMarkupSavedAction;
			model3dState.onMarkupSavedAction = null;
			if(projectId !== project.id) {
				model3dState.latestMarkup = null;
				return;
			}
			if(ebxFileContents) {
				const markupData = model3dState.latestMarkup;
				model3dState.latestMarkup = {};
				markupData.ebxFileResponse = ebxFileContents;
				// NOTE: Save only modified attribute values. Otherwise the history of the markup will
				// be cluttered with non-changing items.
				const changedValues = {};
				_.forEach(Object.getOwnPropertyNames(markupData.attributeValues),
					attributeValueKeyString => {
						const attributeValueKey = parseInt(attributeValueKeyString);
						if(isNaN(attributeValueKey)){
							return;
						}
						const attributeValue = markupData.attributeValues[attributeValueKey];
						const originalValue = markupData.originalValues[attributeValueKey];
						if(!originalValue
							|| originalValue.value !== attributeValue.value
							|| originalValue.enumId !== attributeValue.enumId) {
							changedValues[attributeValueKey] = attributeValue;
							const attributeKind = _.find(markupData.markupType.attributeKinds, {
								"id": attributeValueKey,
							});
							if(attributeKind.dataType === "Enumeration") {
								const enumValue = _.find(attributeKind.enumValues, {
									"id": attributeValue.enumId,
								});
								attributeValue.value = enumValue.value;
							}
						}
					});
				let referenceObjectAbbreviation = "";
				let modelAttribute = {
					value: "",
				};
				if(!markupData.point.id
					&& !model3dState.isObjectPointCloud
					&& markupData.point.referenceObjectAbbreviation) {
					referenceObjectAbbreviation = markupData.point.referenceObjectAbbreviation;
					modelAttribute = _.find($scope.modelAttributes, {
						abbreviation: referenceObjectAbbreviation,
					});
					const guidStringIndex = referenceObjectAbbreviation.indexOf("--||GUID||--");
					if(guidStringIndex > -1){
						referenceObjectAbbreviation =
							referenceObjectAbbreviation.substring(0, guidStringIndex);
					}
					if(modelAttribute.isGroup) {
						referenceObjectAbbreviation = "[" + referenceObjectAbbreviation + "]";
					}
				}
				markupData.point.referenceObjectGuidId = modelAttribute.guid;
				markupData.point.referenceObjectValue = modelAttribute.value;
				markupData.point.referenceObjectAbbreviation = referenceObjectAbbreviation;

				markupData.entries = [];
				const markupEntry = {};
				markupEntry.entryId = markupGuid;
				markupEntry.point = markupData.point;
				markupEntry.attributeValues = changedValues;
				markupData.entries.push(markupEntry);
				markupData.originalValues = null;
				markupData.point = null;
				markupData.attributeValues = null;
				markupData.history = null;
				markupData.ebxFileContents = [];
				model3dState.latestMarkup = null;
				markupsRepository.saveEdited($scope.projectId, markupData)
					.then(markupsImportResult => {
						const isSuccess = eBrowser.updatePoints(
							markupsImportResult.pointsOfInterestWithKinds
						);
						if(isSuccess) {
							notification.success("Markup saved");
							if(typeof onSavedAction === "function") {
								const id = markupsImportResult.pointsOfInterestWithKinds
									&& markupsImportResult.pointsOfInterestWithKinds.points
									&& markupsImportResult.pointsOfInterestWithKinds.points[0]
									&& markupsImportResult.pointsOfInterestWithKinds.points[0].id || null;
								onSavedAction(id);
							}
						} else {
							notification.error("Saving markup failed");
							model3dState.latestMarkup = null;
						}
					});
			}
			model3dState.latestMarkup = null;
		}

		function onShowExamineData(event, projectId, examineData) {
			if(projectId !== project.id) {
				return;
			}
			model3dState.clear();
			if(!examineData) {
				return;
			}
			let isExamine = examineData.startsWith("EXAMINE:");
			const isCollision = examineData.startsWith("COLLISION:");
			if(isExamine) {
				examineData = examineData.substr(8);
			} else if(isCollision) {
				examineData = examineData.substr(10);
			} else {
				isExamine = true;
			}
			const terms = examineData.split("|*|");
			let numberOfTerms = terms.length;
			if((numberOfTerms % 2) !== 0) {
				--numberOfTerms;
			}
			if(numberOfTerms === 0) {
				return;
			}
			for(let i = 0; i < numberOfTerms; i += 2) {
				const term1 = terms[i].replace(/</g, "&lt;").replace(/>/g, "&gt;");
				const term2 = terms[i + 1].replace(/</g, "&lt;").replace(/>/g, "&gt;");
				const attribute = {
					displayCategory: "Model",
					displayName: term1 || "Attribute",
					dataType: "string",
					eShareKey: null,
					value: term2,
					displayValue: term2,
					isKey: false,
					isGroup: false,
					isHidden: false,
					listIndex: i,
				};
				model3dState.attributes.push(attribute);
			}
			if(isCollision) {
				model3dState.positionId = "Collision";
			}
			refreshView();
		}

		function onShowMessage(event, projectId/*, messageType, title, message*/) {
			if(projectId !== project.id) {
				return;
			}
			// Do nothing for now - the message is now shown via the toaster/notification.*
			// Alternatively, show "full screen message box"
		}

		function onExamineObject(
			event, projectId, pGeometryId, pModelTimestamp, pMoveCamera, doExamine
		) {
			if(projectId !== project.id || pGeometryId === -1) {
				return;
			}
			if(pGeometryId === geometryId && pModelTimestamp === modelTimestamp) {
				return;
			}
			const requestTimeStamp = Date.now().valueOf();

			locateModelObject(doExamine, pGeometryId, pModelTimestamp, pMoveCamera, requestTimeStamp);
			hidePointEditor();
			model3dState.locatedObjectType = "object";
		}

		function onExamineMultipleObjects(
			event, projectId, pGeometryIds, pModelTimestamp, moveCamera, doExamine
		) {
			if(projectId !== project.id || pGeometryIds === undefined || pGeometryIds === null) {
				return;
			}
			const intGeometryIds = _.filter(_.map(pGeometryIds.split(","), s => {
				return parseInt(s, 10);
			}), i => {
				return !isNaN(i);
			});
			if(intGeometryIds.length === 0) {
				clearParameters();
				model3dState.clear();
				return;
			}
			if(intGeometryIds.length === 1) {
				onExamineObject(
					event, projectId, intGeometryIds[0], pModelTimestamp, moveCamera, doExamine
				);
				return;
			}
			const flags = examineFlags;
			const visStyle = visualStyleName;
			clearParameters();

			const requestTimeStamp = Date.now().valueOf();

			geometryIds = intGeometryIds;
			modelTimestamp = pModelTimestamp;
			examineFlags = flags;
			visualStyleName = visStyle;
			hidePointEditor();
			model3dState.locatedObjectType = "object";

			showAttributesByGeometryIds(
				doExamine, intGeometryIds, pModelTimestamp, requestTimeStamp
			);
		}

		function onExamineGroup(
			event, projectId, parentTag, childTag, value, isFullGroup, timestamp, doExamine
		) {
			if($scope.modelState.geometryIds) {
				$scope.modelState.geometryIds.length = 0;
			}
			if(projectId !== project.id) {
				return;
			}
			model3dState.locatedObjectType = "group";
			showCategory(parentTag, childTag, value, false, doExamine, 1, "");
		}

		function onExaminePoint(event, projectId, pPointId) {
			if(projectId !== project.id || pPointId === "") {
				return;
			}
			model3dState.clear();
			clearParameters();
			model3dState.pointId = pPointId;
			pointId = pPointId;

			//End Edit or Delete if a new point is selected
			hidePointEditor();

			showPointAttributes();
			model3dState.locatedObjectType = "point";
		}

		function onHierarchyChanged() {
			const examinePath = hierarchyBranchPath;

			hierarchyBranchPath = "";
			eBrowser.examineBranch(examinePath, examineFlags);
		}

		function onModelOutdated() {
			model3dState.clear();
			eBrowser.exitMarkupMode(false);
			eBrowser.showMessage(
				"Model Updated",
				"The model you are viewing is outdated. Please update the model to be able to view"
				+ " object attributes.",
				"<i class='fa fa-refresh fa-fw'></i> Update Model",
				"<i class='fa fa-undo fa-fw'></i> Cancel",
				() => {
					model3dState.isModelOutdated();
					searchService.repeatLastSearch();
					eBrowser.reloadModel();
				}
			);
			return;
		}

		$scope.updateMarkupKind = function () {
			const kindId = model3dState.latestMarkup.markupType.id;
			const kind = _.cloneDeep(_.find($scope.markupKinds, { "id": kindId }));
			model3dState.latestMarkup.markupType = kind;
			model3dState.latestMarkup.markupType.isEditable =
				model3dState.latestMarkup.markupType.isCreatable;
			if(!kind.hasAssignee) {
				model3dState.latestMarkup.point.assigneeName = null;
			}
			getAttributesForMarkupKind(kindId);
		};

		function getAttributesForMarkupKind(kindId) {
			return markupsRepository.getKind($scope.projectId, kindId).then(
				data => {
					//Get the attribute kinds and create empty entries for the attribute values
					model3dState.latestMarkup.markupType.attributeKinds = _.filter(
						data.attributeKinds, attributeKind => {
							return attributeKind.dataType !== "Drawing"
									&& attributeKind.dataType !== "Image";
						}
					);
					_.forEach(data.attributeKinds, kind => {
						if(!$scope.modelState.latestMarkup.attributeValues[kind.id]) {
							$scope.modelState.latestMarkup.attributeValues[kind.id] = {
								attributeKindId: kind.id,
							};
						}
					});
				},
				processError
			);
		}

		$scope.cancelAddPoint = function () {
			model3dState.pointEditor.referenceObjectId = null;
			model3dState.pointEditor.x = null;
			model3dState.pointEditor.y = null;
			model3dState.pointEditor.z = null;
			model3dState.pointEditor.name = "";
			model3dState.pointEditor.externalId = "";
			// Don't clear kindId and referenceObjectAbbreviation in order to keep their existing
			// values pre-selected next time
			hidePointEditor();
		};
		$scope.cancelAddPoint();
		$scope.modelAttributes = [];

		function performEditPoint(pointOfInterestKinds, showMarkup) {
			$scope.pointOfInterestKinds = pointOfInterestKinds;

			let uri = "api/project/{projectId}/PointsOfInterest/GetPoint/".replace(
				"{projectId}", $scope.projectId
			);
			uri = uri.concat(model3dState.pointId);

			$http.get(uri).then(
				response => {
					const point = response.data;
					const pointKindId = point.kindId;
					const pointKind = _.find(pointOfInterestKinds, { "id": pointKindId });
					if(pointKind){
						$scope.pointOfInterestKinds = _.filter(pointOfInterestKinds, {"isReferencingGuid": pointKind.isReferencingGuid });
					}
					if(showMarkup || (pointKind && pointKind.isMarkup)) {
						model3dState.isLocateActive = false;
						model3dState.positionId = "";
						model3dState.attributes.length = 0;
						model3dState.attributesView.length = 0;
						model3dState.autorefreshData.length = 0;
						markupId = point.id;
						openMarkup();
					} else {
						model3dState.pointEditor.id = model3dState.pointId;
						model3dState.pointEditor.referenceObjectId = point.referenceObjectId;
						model3dState.pointEditor.x = point.x;
						model3dState.pointEditor.y = point.y;
						model3dState.pointEditor.z = point.z;
						model3dState.pointEditor.name = point.name;
						model3dState.pointEditor.externalId = point.externalId;
						model3dState.pointEditor.referenceObjectAbbreviation =
							point.referenceObjectAbbreviation;
						model3dState.pointEditor.kindId = point.kindId;
						model3dState.pointEditor.attributeKinds.length = 0;
						model3dState.pointEditor.attributeValues = point.attributeValues;
						//Get the attribute kinds and attribute values
						getAttributesForPointKind(point.kindId)
							.then(() => {
								showPointEditor();
							});
					}
				},
				processError
			);
		}

		$scope.editPoint = function (showMarkup) {
			pointOfInterestKindRepository.getAllEditable(project.id).then(
				pointOfInterestKinds => {
					if(!pointOfInterestKinds || (pointOfInterestKinds.length === 0 && !showMarkup)) {
						eBrowser.showImportantMessage(
							"Edit Smart Point",
							"No smart point types defined",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						return;
					}
					performEditPoint(pointOfInterestKinds, showMarkup);
				},
				error => {
					notification.error(
						"Error retrieving smart point types: " + Utilities.getErrorMessage(error)
					);
				}
			);
		};

		function showPointEditor() {
			$scope.confirmDelete = false;
			$scope.modelState.pointEditor.visible = true;
			updateState();
		}

		function hidePointEditor() {
			$scope.confirmDelete = false;
			$scope.modelState.pointEditor.visible = false;
			updateState();
		}

		function updateState() {
			if($scope.modelState.pointEditor.visible || $scope.confirmDelete) {
				ignoreResizes = true;
				if(currentWidth < 300) {
					setInfoPanelSize(300);
				}
				// Below is a hack to fix a bug occurring on IE11
				if($scope.modelState.pointEditor.visible) {
					$timeout(() => {
						const pointEditorNameElement = document.getElementById("pointEditorName");
						if(pointEditorNameElement) {
							pointEditorNameElement.focus();
							pointEditorNameElement.blur();
						}
					});
				}
			} else {
				ignoreResizes = false;
				setInfoPanelSize(lastWidth);
			}
		}

		$scope.savePoint = function () {
			//If the point Id is not present, a new point is being added
			if(model3dState.pointEditor.id == null) {
				$scope.addPoint();
				return;
			}
			const uri = "api/project/{projectId}/PointsOfInterest/SavePoint".replace(
				"{projectId}", $scope.projectId
			);
			const pointId = model3dState.pointEditor.id;
			$http.put(uri, {
				Id: pointId,
				referenceObjectId: model3dState.pointEditor.referenceObjectId,
				referenceObjectAbbreviation: model3dState.pointEditor.referenceObjectAbbreviation,
				x: model3dState.pointEditor.x,
				y: model3dState.pointEditor.y,
				z: model3dState.pointEditor.z,
				name: model3dState.pointEditor.name,
				kindId: model3dState.pointEditor.kindId,
				externalId: model3dState.pointEditor.externalId,
				attributeValues: model3dState.pointEditor.attributeValues,
			}).then(
				response => {
					const data = response.data;
					const point = data.points[0];
					const kind = data.kinds[0];
					if(point.kindId !== kind.id) {
						return;
					}
					hidePointEditor();
					notification.success("Point modified");
					//Remove the old version of the point and add the updated one
					eBrowser.deletePointFrom3D(point.id);
					eBrowser.addPointTo3D(
						point.id,
						point.name,
						point.externalId,
						point.status,
						kind.name,
						kind.color,
						kind.iconUrl,
						point.x,
						point.y,
						point.z
					);
					model3dState.pointId = point.id;
					$timeout(() => {
						model3dState.locatedObjectType = "point";
						showPointAttributes();
					});
				},
				reason => {
					if(reason.status === 404) {
						const errorMessage = "Could not save the point"
						+ " - it has been deleted by someone else";
						hidePointEditor();
						model3dState.clear();
						notification.error(errorMessage);
						eBrowser.deletePointFrom3D(pointId);
						eBrowser.showMessage(
							"Point Conflict",
							errorMessage,
							"<i class='fa fa-check-circle-o fa-fw'></i> OK",
							""
						);
					} else {
						processError(reason);
					}
				}
			);
		};

		function performAddPoint(event, projectId, referenceObjectId, x, y, z, pointOfInterestKinds) {
			if(referenceObjectId !== -1) {
				eBrowser.exitExamineMode();
			}

			$scope.pointOfInterestKinds = _.filter(pointOfInterestKinds, {
				"isMarkup": false,
				"isCreatable": true,
			});
			if($scope.pointOfInterestKinds.length === 0) {
				notification.error("No smart point types defined for creation in this project.");
				return;
			}

			model3dState.pointId = "";
			model3dState.positionId = "";
			model3dState.attributes.length = 0;
			model3dState.attributesView.length = 0;
			model3dState.autorefreshData.length = 0;
			model3dState.locatedObjectType = null;

			model3dState.pointEditor.id = null;
			model3dState.pointEditor.referenceObjectId = referenceObjectId;
			model3dState.pointEditor.x = x;
			model3dState.pointEditor.y = y;
			model3dState.pointEditor.z = z;
			model3dState.pointEditor.name = "";
			model3dState.pointEditor.externalId = "";
			model3dState.pointEditor.attributeKinds.length = 0;
			model3dState.pointEditor.attributeValues = {};
			model3dState.pointEditor.kindId = $scope.pointOfInterestKinds[0].id;

			model3dState.pointId = "";
			getAttributesForPointKind(model3dState.pointEditor.kindId);

			eBrowser.getModelTimestamp().then(modelTimestamp => {
				attributesService.getModelAttributes(projectId, {
					keyType: "GeometryId",
					geometryId: referenceObjectId,
					modelImportDate: modelTimestamp,
				}).then(modelAttributes => {
					let keyIndex = -1;
					let groupIndex = -1;
					let displayNameIndex = -1;
					let valueIndex = -1;
					let eShareKeyIndex = -1;
					for(let i = 0; i < modelAttributes.data.attributes.columnDefinitionIds.length; i++) {
						switch(modelAttributes.data.attributes.columnDefinitionIds[i]) {
						case "isKey":
							keyIndex = i;
							break;
						case "isGroup":
							groupIndex = i;
							break;
						case "displayName":
							displayNameIndex = i;
							break;
						case "value":
							valueIndex = i;
							break;
						case "eShareKey":
							eShareKeyIndex = i;
							break;
						}
					}
					$scope.modelAttributes = [];

					// Selection logic for referenceObjectAbbreviation:
					// 1. If the object to which we are adding a Smart Point has a key,
					//    select the key attribute. Else:
					// 2. If an attribute was selected earlier (when adding a previous point),
					//    and that attribute exists on the new object, select this attribute.
					//    Else:
					// 3. Select the first group attribute.

					// Check for option #2 (whether the previously-selected attribute still exists).
					// Also, create a list of all attributes for the drop-down ($scope.modelAttributes).
					let foundPreviousAbbreviation = false;
					_.forEach(modelAttributes.data.attributes.data, attribute => {
						if(attribute[keyIndex] === "true" || attribute[groupIndex] === "true") {
							const key = angular.fromJson(attribute[eShareKeyIndex]);
							let guidId = null;
							if(key !== null
								&& key !== undefined
								&& key.tags !== undefined
								&& key.tags.length > 0
								&& key.tags[0].guidId !== null
								&& key.tags[0].guidId !== undefined
								&& !Utilities.isEmptyGuid(key.tags[0].guidId)){
									guidId = key.tags[0].guidId;
								}
							$scope.modelAttributes.push({
								displayName: attribute[displayNameIndex] + " (" + attribute[valueIndex] + ")",
								value: attribute[valueIndex],
								guid: guidId,
								abbreviation: key.tags[0].attribute,
								isKey: attribute[keyIndex] === "true",
								isGroup: attribute[groupIndex] === "true",
							});
							if(key.attribute === model3dState.pointEditor.referenceObjectAbbreviation) {
								foundPreviousAbbreviation = true;
							}
						}
					});
					if(!foundPreviousAbbreviation) {
						model3dState.pointEditor.referenceObjectAbbreviation = "";
					}

					// Select the correct attribute (see rules above):
					_.forEach(modelAttributes.data.attributes.data, attribute => {
						if(attribute[keyIndex] === "true" || attribute[groupIndex] === "true") {
							const key = angular.fromJson(attribute[eShareKeyIndex]);
							if(attribute[keyIndex] === "true"
								|| model3dState.pointEditor.referenceObjectAbbreviation === "") {
								const abbreviation = key.tags[0].attribute;
								model3dState.pointEditor.referenceObjectAbbreviation = abbreviation;
							}
						}
					});

					showPointEditor();
				}, error => {
					notification.error(error);
				});
			});
		}

		function onAddPoint(event, projectId, referenceObjectId, x, y, z) {
			pointOfInterestKindRepository.getAllCreatable(projectId).then(
				pointOfInterestKinds => {
					if(!pointOfInterestKinds || pointOfInterestKinds.length === 0) {
						eBrowser.showImportantMessage(
							"Add Smart Point",
							"No smart point types defined",
							"<i class='fa fa-check-circle-o fa-fw'></i> OK"
						);
						return;
					}
					if(referenceObjectId === -1) {
						model3dState.isObjectPointCloud = true;
					} else {
						model3dState.isObjectPointCloud = false;
					}
					performAddPoint(event, projectId, referenceObjectId, x, y, z, pointOfInterestKinds);
				},
				error => {
					notification.error(
						"Error retrieving smart point types: " + Utilities.getErrorMessage(error)
					);
				}
			);
		}

		$scope.addPoint = function () {
			const uri = "api/project/{projectId}/PointsOfInterest/AddPoint".replace(
				"{projectId}", $scope.projectId
			);
			let referenceObjectAbbreviation = "";
			let modelAttribute = {
				value: "",
			};
			if(model3dState.pointEditor.referenceObjectId !== -1
				&& model3dState.pointEditor.referenceObjectAbbreviation) {
				referenceObjectAbbreviation = model3dState.pointEditor.referenceObjectAbbreviation;
				modelAttribute = _.find($scope.modelAttributes, {
					abbreviation: referenceObjectAbbreviation,
				});
				const guidStringIndex = referenceObjectAbbreviation.indexOf("--||GUID||--");
				if(guidStringIndex > -1){
					referenceObjectAbbreviation =
						referenceObjectAbbreviation.substring(0, guidStringIndex);
				}
				if(modelAttribute.isGroup) {
					referenceObjectAbbreviation = "[" + referenceObjectAbbreviation + "]";
				}
			}
			$http.post(uri, {
				referenceObjectId: model3dState.pointEditor.referenceObjectId,
				referenceObjectGuidId: modelAttribute.guid,
				referenceObjectAbbreviation: referenceObjectAbbreviation,
				referenceObjectValue: modelAttribute.value,
				x: model3dState.pointEditor.x,
				y: model3dState.pointEditor.y,
				z: model3dState.pointEditor.z,
				name: model3dState.pointEditor.name,
				kindId: model3dState.pointEditor.kindId,
				externalId: model3dState.pointEditor.externalId,
				attributeValues: model3dState.pointEditor.attributeValues,
			}).then(response => {
				const data = response.data;
				const point = data.points[0];
				const kind = data.kinds[0];
				if(point.kindId !== kind.id) {
					return;
				}
				hidePointEditor();
				notification.success("Point added");
				eBrowser.addPointTo3D(
					point.id,
					point.name,
					point.externalId,
					point.status,
					kind.name,
					kind.color,
					kind.iconUrl,
					point.x,
					point.y,
					point.z
				);
				model3dState.pointId = point.id;
				$timeout(() => {
					model3dState.locatedObjectType = "point";
					showPointAttributes();
				});
			},
			processError);
		};

		$scope.confirmDelete = false;

		$scope.confirmDeletePoint = function () {
			$scope.confirmDelete = true;
			updateState();
		};

		$scope.cancelDeletePoint = function () {
			$scope.confirmDelete = false;
			updateState();
		};

		$scope.deletePoint = function () {
			model3dState.isAutorefreshEnabled = false;
			model3dState.autorefreshData.length = 0;
			hidePointEditor();
			const uri = "api/project/{projectId}/PointsOfInterest/DeletePoint/{pointId}"
				.replace("{projectId}", $scope.projectId)
				.replace("{pointId}", model3dState.pointId);
			$http.delete(uri).then(() => {
				notification.success("Point deleted");
				eBrowser.deletePointFrom3D(model3dState.pointId);
				model3dState.clear();
			},
			reason => {
				if(reason.status === 404) {
					notification.success("Point deleted");
					eBrowser.deletePointFrom3D(pointId);
					model3dState.clear();
				} else {
					processError(reason);
				}
			});
		};

		$scope.showCategoryByTagKey = function (attribute) {
			const tags = _.filter(attribute.eShareKey.tags, { isGroup: true });
			if(tags.length > 0) {
				const tag = tags[0];
				showCategory(
					"[" + tag.attribute + "]", tag.attribute, attribute.value, true, true, 1, "", tag.guidId
				);
			}
		};

		function showCategory(parentTag, childTag, value, animate, doExamine, flags, visualStyle, guidId) {
			clearParameters();
			groupParentTag = parentTag;
			groupChildTag = childTag;
			groupId = value;
			animateMovement = animate;
			examineFlags = flags;
			visualStyleName = visualStyle;
			groupGuidId = guidId;
			locateGroup(doExamine);
		}

		$scope.showCategory = showCategory;

		function showAttributesByGeometryIds(doExamine, pGeometryIds, pModelTimestamp,
			requestTimeStamp) {
			if(!geometryIds || geometryIds.length === 0 || !modelTimestamp) {
				return;
			}
			if(lastExamineTime && requestTimeStamp && lastExamineTime > requestTimeStamp) {
				return;
			}
			lastExamineTime = requestTimeStamp;
			model3dState.attributes.length = 0;
			model3dState.attributesView.length = 0;
			model3dState.autorefreshData.length = 0;
			model3dState.isAutorefreshEnabled = true;
			model3dState.geometryIds = geometryIds;
			const requestId = model3dState.generateRequestId();
			getStatusTrackingKinds().then(() => {
				//eBrowser.changeHierarchy(hierarchyName);
				if(doExamine) {
					eBrowser.examineSearchResults(pGeometryIds, examineFlags);
					eBrowser.setModelTreeVisibile(treeVisibility);
					setAttributePanelVisibility(attributePaneVisibility);
				}
				attributesService.getAttributes(requestId, project.id, addAttributes, processError, [
					{
						keyType: "MultiGeometryId",
						geometryIds: pGeometryIds || geometryIds,
						modelImportDate: pModelTimestamp || modelTimestamp,
					}
				]);
			});
		}

		function showAttributesByGeometryId(pGeometryId, pModelTimestamp) {
			if(geometryId === null
				|| geometryId === undefined
				|| geometryId === -1
				|| !modelTimestamp) {
				return;
			}
			model3dState.pointId = "";
			model3dState.positionId = "";
			model3dState.attributes.length = 0;
			model3dState.attributesView.length = 0;
			model3dState.autorefreshData.length = 0;
			model3dState.isAutorefreshEnabled = true;
			model3dState.geometryIds = [];
			const requestId = model3dState.generateRequestId();
			attributesService.getAttributes(requestId, project.id, addAttributes, processError, [
				{
					keyType: "GeometryId",
					geometryId: pGeometryId || geometryId,
					modelImportDate: pModelTimestamp || modelTimestamp,
				}
			]);
		}

		function addAttributes(response, requestDepth) {
			model3dState.isLocateActive = false;
			if(!model3dState.isCurrentRequestId(response.requestId)
				|| model3dState.latestMarkup) {
				return false;
			}
			const dataSourceId = response.dataSourceId;
			const key = response.key;
			const isMatchingAttribute = function (attribute) {
				return attribute.dataSourceId === dataSourceId
					&& Utilities.isObject(attribute.key)
					&& attribute.key.keyType === key.keyType
					&& Utilities.areEqual(attribute.key, key);
			};
			// eslint-disable-next-line no-constant-condition
			while(true) {
				const indexToRemove = _.findIndex(model3dState.attributes, isMatchingAttribute);
				if(indexToRemove === -1) {
					break;
				}
				model3dState.attributes.splice(indexToRemove, 1);
			}
			_.forEach(response.attributes, attribute => {
				attribute.dataSourceId = dataSourceId;
				attribute.key = key;
				attribute.displayPriority = typeof attribute.displayPriority === "undefined"
					? 0
					: parseInt(attribute.displayPriority, 10);
				if(attribute.dataType === "statusTracking") {
					// handle duplicate status trackings by combining the template attributes
					const attributeStatusTrackingId = attribute.eShareKey?.statusTrackingKindId;
					const found = attributeStatusTrackingId
						&& _.find(model3dState.attributes, attr => {
							const eShareKey = attr.eShareKey;
							return eShareKey
								&& attr.dataType === "statusTracking"
								&& eShareKey.statusTrackingKindId === attributeStatusTrackingId;
						});
					if(found) {
						if(attribute.eShareKey.templateAttributes) {
							found.eShareKey.templateAttributes = found.eShareKey.templateAttributes || {};
							_.forOwn(attribute.eShareKey.templateAttributes, (value, key) => {
								found.eShareKey.templateAttributes[key] = value;
							});
						}
						return;
					}
					attribute.statusIdentifier = Utilities.generateUuid();
				}
				if(attribute.dataType !== "PoiName") {
					model3dState.attributes.push(attribute);
				}
				if(model3dState.positionId === ""
					&& attribute.isKey
					&& attribute.value
					&& !attribute.isFromGroup) {
					model3dState.positionId = attribute.value;
					if(model3dState.locatedObjectType === "object"
						&& attribute.eShareKey
						&& Utilities.isArray(attribute.eShareKey.tags)) {
						positionId = attribute.value;
						tag = attribute.eShareKey.tags[0].attribute;
					}
				}
			});
			refreshView();
			if(response.attributesRefreshInterval > 0) {
				$timeout(() => {
					if(!model3dState.isAutorefreshEnabled
						|| !model3dState.isCurrentRequestId(response.requestId)) {
						return;
					}
					attributesService.getAttributesFromDataSource(
						response.requestId, project.id, dataSourceId, addAttributes, processError, key, 1
					);
					const autorefreshDataIndex = _.findIndex(model3dState.autorefreshData, d => {
						return d.dataSourceId === dataSourceId
								&& d.key.keyType === key.keyType
								&& Utilities.areEqual(d.key, key);
					});
					if(autorefreshDataIndex === -1) {
						model3dState.autorefreshData.push({
							dataSourceId: dataSourceId,
							key: key,
						});
					}
				}, response.attributesRefreshInterval * 1000);
			}
			return requestDepth === 1;
		}

		function processError(reason) {
			model3dState.isLocateActive = false;
			if(reason.status === 410) {
				onModelOutdated();
			} else {
				notification.error(reason.data);
			}
		}

		//Updates the point attributes to the editor if the point type is changed
		$scope.updatePointKind = function () {
			getAttributesForPointKind($scope.modelState.pointEditor.kindId);
		};

		function getAttributesForPointKind(kindId) {
			return pointOfInterestKindRepository.get($scope.projectId, kindId).then(
				data => {
					//Get the attribute kinds and create empty entries for the attribute values
					$scope.modelState.pointEditor.attributeKinds = data.attributeKinds;
					$scope.modelState.pointEditor.hasExternalId = data.hasExternalId;
					_.forEach(data.attributeKinds, kind => {
						if(!$scope.modelState.pointEditor.attributeValues[kind.id]) {
							$scope.modelState.pointEditor.attributeValues[kind.id] = {
								dataType: kind.dataType,
								pointOfInterestKindId: kind.id,
							};
						}
					});
				},
				processError
			);
		}

		function getStatusTrackingKinds() {
			return eBrowser.getModelTimestamp().then(modelTimeStamp => {
				return statusTrackingKindRepository
					.getKindsForAttributes(project.id, modelTimeStamp)
					.then(data => {
						$scope.modelState.statusKinds = data.kinds;
					},
					processError);
			});
		}

		$scope.updateObjectStatus = function (
			abbreviation, attributeValue, kindName, stateName, stateColor
		) {
			eBrowser.updateObjectStatus(abbreviation, attributeValue, kindName, stateName, stateColor);
		};

		$scope.updateObjectStatusWithIds = function(objectIds, kindName, stateName, stateColor){
			eBrowser.updateObjectStatusWithIds(objectIds, kindName, stateName, stateColor);
		};

		$scope.saveStatus = function (statusChanges) {
			// If no changed values, exit
			if(statusChanges.length < 1) {
				notification.success("Status values already up to date");
				return;
			}

			statusTrackingKindRepository.saveEntries($scope.projectId, statusChanges).then(
				() => {
					notification.success("Status values modified");
					if(geometryId != null && geometryId !== "") {
						getStatusTrackingKinds().then(() => showAttributesByGeometryId());
					} else if(geometryIds != null && geometryIds.length != 0) {
						getStatusTrackingKinds().then(() => showAttributesByGeometryIds(false));
					} else {
						getStatusTrackingKinds().then(() =>
							showAttributes("Model", groupParentTag, groupId, true, groupGuidId));
					}
				}
			);
		};

		$scope.decodeUri = function (uri) {
			return decodeURIComponent(uri);
		};

		$scope.filterVisibility = function (attribute, category) {
			return !attribute.isHidden || category.showHidden;
		};

		$scope.isShowingHidden = function (category) {
			return !category.showHidden;
		};

		$scope.flipHidden = function (category) {
			category.showHidden = !category.showHidden;
		};

		$scope.getAttributeColor = function (attribute) {
			return attribute.isHidden ? "#888888" : "#000000";
		};

		function refreshView() {
			if(searchResult && $scope.searchResults) {
				$scope.currentSearchIndex = getCurrentSearchIndex();
				$scope.attributesView = model3dState.attributesView;
			}
			let attributesView = [];
			const hiddenCategories = [];
			switch($scope.modelState.attributesViewType) {
			case 1:{
				const categoryOrder = function (value) {
					const displayCategory = value.displayCategory.toLowerCase();
					if(value.hasOnlyHidden) {
						return "0101 " + displayCategory;
					}
					switch(displayCategory) {
					case "documents":
						return "1000 " + displayCategory;
					case "model":
					case "search":
					case "point":
						return "0100 " + displayCategory;
					default:
						return "0500 " + displayCategory;
					}
				};
				let i;
				let attribute;
				for(i = 0; i < model3dState.attributes.length; ++i) {
					attribute = model3dState.attributes[i];
					attribute.isConflicting = false;
					attribute.conflictIndex = 0;
				}
				let conflictIndex = 1;
				for(i = 0; i < model3dState.attributes.length; ++i) {
					attribute = model3dState.attributes[i];
					const displayName = (attribute.displayName || "").toLowerCase();
					const value = attribute.value;
					const dataType = attribute.dataType;
					const isFromGroup = !!attribute.isFromGroup;
					if(!displayName || attribute.isConflicting || isFromGroup) {
						continue;
					}
					let isConflicting = false;
					for(let j = i + 1; j < model3dState.attributes.length; ++j) {
						const other = model3dState.attributes[j];
						if((other.displayName || "").toLowerCase() === displayName
							&& other.value !== value
							&& dataType !== "statusTracking"
							&& other.dataType !== "statusTracking"
							&& !other.isFromGroup) {
							isConflicting = true;
							for(let k = 0; k < model3dState.attributes.length; ++k) {
								const a = model3dState.attributes[k];
								if((a.displayName || "").toLowerCase() === displayName) {
									a.isConflicting = true;
									a.conflictIndex = conflictIndex;
								}
							}
						}
					}
					if(isConflicting) {
						++conflictIndex;
					}
				}
				const displayCategories = _.uniq(_.map(model3dState.attributes, "displayCategory"));
				for(i = 0; i < displayCategories.length; ++i) {
					attributesView.push({
						displayCategory: displayCategories[i],
						attributes: _.sortBy(_.filter(model3dState.attributes, {
							"displayCategory": displayCategories[i],
						}), ["displayPriority", "displayName"]),
					});
				}
				let categoryIndex = 0;
				_.forEach(attributesView, category => {
					if(_.every(category.attributes, attribute => {
						return !!attribute.isFromGroup;
					})) {
						hiddenCategories.push({ name: category.displayCategory, index: categoryIndex });
						category.hasOnlyHidden = true;
					}
					categoryIndex++;
				});
				attributesView = _.sortBy(attributesView, categoryOrder);
				_.forEach(attributesView, category => {
					if(category.displayCategory === "Point") {
						const pointTypeNameAttributes = _.filter(category.attributes, {
							"dataType": "PoiType",
						});
						if(pointTypeNameAttributes.length === 1) {
							category.displayCategory = pointTypeNameAttributes[0].value;
							// Hide the type field
							_.remove(category.attributes, pointTypeNameAttributes[0]);
						}
					}
					category.hasHidden = _.some(category.attributes, attribute => {
						return attribute.isHidden;
					});
				});
				break;
			}
			case 2:{
				const attributeToKeyValue = function (a) {
					return { displayName: a.displayName, value: a.value };
				};
				attributesView = _.sortBy(
					_.map(model3dState.attributes, attributeToKeyValue),
					["displayPriority", "displayName"]
				);
				break;
			}
			}
			// Getting category hidden attributes visibility from old attributesView
			_.forEach(model3dState.attributesView, oldCategory => {
				const newCategory = _.find(attributesView, c => {
					return c.displayCategory === oldCategory.displayCategory;
				});
				if(newCategory) {
					newCategory.showHidden = oldCategory.showHidden;
				}
			});
			copyArray(attributesView, model3dState.attributesView);
			for(let l = 0; l < hiddenCategories.length; l++) {
				const hiddenCategory = hiddenCategories[l];
				$scope.getCategoryVisibility(hiddenCategory.name, true, hiddenCategory.index);
				$scope.setCategoryVisibility(hiddenCategory.name, true, hiddenCategory.index);
			}
		}

		function copyArray(from, to) {
			to.length = from.length;
			for(let i = 0; i < from.length; ++i) {
				to[i] = from[i];
			}
		}

		function tagToDisplayName(tag) {
			const attributeDefinition = _.find(attributeDefinitions, { abbreviation: tag });
			return attributeDefinition ? attributeDefinition.displayName : "?";
		}

		function operationToDisplayName(operation) {
			switch(operation) {
			case "eq":
				return "equals";
			case "exists":
				return "exists";
			case "gt":
				return "&gt;";
			case "ge":
				return "&ge;";
			case "lt":
				return "&lt;";
			case "le":
				return "&le;";
			case "contains":
				return "contains";
			case "statusIs":
				return "is";
			case "statusIsSet":
				return "is set";
			case "statusIsNotSet":
				return "is not set";
			case "wasEq":
				return "was equal";
			case "existed":
				return "existed";
			case "contained":
				return "contained";
			case "wasGt":
				return "was &gt;";
			case "wasGe":
				return "was &ge;";
			case "wasLt":
				return "was &lt;";
			case "wasLe":
				return "was &le;";
			case "notEq":
				return "not equal";
			default:
				return "?";
			}
		}

		$scope.examineMultipleLinkedItems = function (eShareKey) {
			const request = {
				isAdvanced: true,
				projectId: project.id,
				scope: "IdOnly",
				searchTarget: "Objects",
				shouldGetGeometryIds: true,
				terms: [
					{
						attributeTag: eShareKey.tags[0].attribute,
						date: null,
						isMultiline: false,
						operation: "eq",
						previousTag: eShareKey.tags[0].attribute,
						text: eShareKey.tags[0].value,
					}
				],
				text: "",
			};
			searchService.getObjectGeometryIds(request, attributeDefinitions).then(data => {
				eBrowser.examineSearchResults(data.objectGeometryIds, examineFlags);
				$scope.modelState.positionId = "Linked Model Items";
				$scope.modelState.isLocateActive = true;
				$scope.modelState.isEditable = false;
				$scope.modelState.isDeletable = false;
				$scope.attributesView = [
					{
						displayCategory: "Linked Model Items",
						attributes: [
							{
								displayName: attributeDefinitions.filter(definition => {
									return definition.abbreviation === eShareKey.tags[0].attribute;
								})[0].displayName,
								value: eShareKey.tags[0].value,
							},
							{
								displayName: "Number of Objects",
								value: data.objectGeometryIds.length,
							}
						],
					}
				];
			});
		};

		function examineMultipleObjects(includeGroups) {
			try {

				if(includeGroups) {
					if(searchResult.allObjectGeometryIds.length > 0) {
						eBrowser.examineSearchResults(
							searchResult.allObjectGeometryIds.toString(), examineFlags
						);
						eBrowser.setModelTreeVisibile(treeVisibility);
						setAttributePanelVisibility(attributePaneVisibility);
					}
				} else {
					if(searchResult.objectGeometryIds.length > 0) {
						eBrowser.examineSearchResults(
							searchResult.objectGeometryIds.toString(), examineFlags
						);
						eBrowser.setModelTreeVisibile(treeVisibility);
						setAttributePanelVisibility(attributePaneVisibility);
					} else {
						notification.info("No objects found in the search result. "
							+ "Try examining all objects and groups instead");
					}
				}
				$scope.modelState.isLocateActive = false;
			} catch(e) {
				if(e.number === -2147024809) {
					notification.error("Invalid argument(s) provided for examining objects");
				} else if(e.number === -2147467259) {
					notification.error("Cannot examine objects: The model has not loaded");
				} else {
					notification.error("Cannot examine objects due to an exception " + e.number);
				}
				searchResult.shouldExamineAllObjects = false;
				return;
			}
			if(searchResult.isDocumentExamine) {
				$scope.modelState.positionId = "Document Examine Results";
				$scope.attributesView = [
					{
						displayCategory: "Information",
						attributes: [
							{
								displayName: "Document Name",
								value: searchResult.text,
							},
							{
								displayName: "Number of Objects",
								value: searchResult.allObjectGeometryIds.length,
							}
						],
					}
				];
			} else {
				$scope.modelState.positionId = "Search Results";
				if(searchResult.request.isAdvanced) {
					$scope.attributesView = [
						{
							displayCategory: "Advanced Search Information",
							attributes: [
								{
									displayName: "Number of Objects",
									value: includeGroups
										? searchResult.allObjectGeometryIds.length
										: searchResult.objectGeometryIds.length,
								}
							],
						}
					];
					const terms = searchResult.request.terms;
					statusTrackingKindRepository.getAll(project.id).then(statusTrackingKinds => {
						for(let i = 0; i < terms.length; i++) {
							if(isStatusTracking(terms[i].attributeTag)) {
								const tag = terms[i].attributeTag;
								const operation = terms[i].operation;
								const text = terms[i].text;
								let st = "";
								let value = "";
								_.forEach(statusTrackingKinds, stk => {
									if(tag.indexOf(stk.id) > -1) {
										st = stk.name;
										if(text
											&& operation !== "statusIsSet"
											&& operation !== "statusIsNotSet") {
											const states = text.split(",");
											_.forEach(states, state => {
												_.forEach(stk.statusValues, statusValue => {
													if(statusValue.id.toLowerCase() === state.toLowerCase()) {
														value += statusValue.name + ", ";
													}
												});
											});
											value = value.substring(0, value.length - 2);
										}
										$scope.attributesView[0].attributes.push({
											displayName: "Search Term " + (i + 1),
											value: st
												+ " "
												+ operationToDisplayName(operation)
												+ " "
												+ value,
										});
										return;
									}
								});
							} else {
								$scope.attributesView[0].attributes.push({
									displayName: "Search Term " + (i + 1),
									value: tagToDisplayName(terms[i].attributeTag)
										+ " "
										+ operationToDisplayName(terms[i].operation)
										+ " "
										+ terms[i].text,
								});
							}
						}
					});
				} else {
					$scope.attributesView = [
						{
							displayCategory: "Simple Search Information",
							attributes: [
								{
									displayName: "Number of Objects",
									value: includeGroups
										? searchResult.allObjectGeometryIds.length
										: searchResult.objectGeometryIds.length,
								}
							],
						}
					];
					if(searchResult.request.text !== "") {
						$scope.attributesView[0].attributes.push({
							displayName: "Search Text",
							value: searchResult.request.text,
						});
					}
				}
			}
			searchResult.shouldExamineAllObjects = false;
		}

		const statusTrackingTagRegex = new RegExp(
			"^\\(\\(ST:" + Utilities.guidRegexStr + "\\)\\)$", "i"
		);

		function isStatusTracking(tag) {
			return Utilities.isString(tag) && statusTrackingTagRegex.test(tag);
		}

		if(searchResult && searchResult.shouldExamineAllObjects) {
			$scope.modelState.isLocateActive = true;
			examineMultipleObjects(searchResult.includeGroups);
		}

		if(geometryId.indexOf(",") > -1) {
			onExamineMultipleObjects(event, project.id, geometryId, modelTimestamp, true, true);
		}

		if(multiExamine !== "") {
			const objectTagValuePairs = multiExamine.split("|");
			model3dState.isLocateActive = true;
			const tagValuePairs = [];
			objectTagValuePairs.forEach(pair => {
				const attributeTag = pair.substring(0, pair.indexOf(":"));
				const attributeValue = pair.substring(pair.indexOf(":") + 1);
				tagValuePairs.push({
					tag: attributeTag,
					value: attributeValue,
				});
			});
			searchService.locateAllGeometryIds(tagValuePairs, project.id).then(result => {
				const uniqueGeometryIds = _.uniq(result.data.geometryIds);
				const geometryIdsString = uniqueGeometryIds.toString();
				onExamineMultipleObjects(
					event, project.id, geometryIdsString, result.data.modelTimeStamp, true, true
				);
			});
		}

		$scope.goToHistory = function (datasourceId, displayName, eShareKey) {
			$state.go("project.historicalData", {
				datasourceId: datasourceId,
				displayName: displayName,
				eShareKey: angular.toJson(eShareKey),
			});
		};

		$scope.markupPhotosSelected = function (files/*, isMarkupInvalid*/) {
			if(!files || files.length === 0) {
				return;
			}
			model3dState.onMarkupSavedAction = function (newId) {
				projectMarkupPhotosService.uploadFiles(files, newId).then(() => {
					model3dState.arePhotosWaiting = false;
				});
			};
			model3dState.arePhotosWaiting = true;
			model3dState.waitingUploadsCount = files.length;
		};

		$scope.isUploadInProgress = function () {
			return projectMarkupPhotosService.uploadStatuses.length > 0;
		};

		$scope.getReadyUploads = function () {
			const queued = projectMarkupPhotosService.uploadStatuses.filter(status => {
				return status.status === "queued";
			});
			const currentItemNumber =
				projectMarkupPhotosService.uploadStatuses.length - queued.length + 1 || 1;
			const lastItemNumber = projectMarkupPhotosService.uploadStatuses.length || 1;
			return currentItemNumber + "/" + lastItemNumber;
		};

		$scope.isValidId = function (id) {
			const isNumber = typeof id === "number";
			const isNotNegative = id >= 0;
			return isNumber && isNotNegative;
		};

		$scope.clearPhotoUploads = function () {
			model3dState.onMarkupSavedAction = null;
			model3dState.arePhotosWaiting = false;
		};

		$scope.getPointsLength = function (attr) {
			return angular.fromJson(attr.value).pointValues.length;
		};
	}
]);
