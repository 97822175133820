angular.module("eShareApp").factory("attributeCategorizationRepository", [
	"repositoryBase",
	function (repositoryBase) {

		const baseApi = "/api/project";
		const attributeCategorizationApi =
			baseApi + "/{projectId}/ModelAttributeCategorizations";
		const attributeCategorizationIdApi =
			baseApi + "/{projectId}/ModelAttributeCategorization/{categorizationId}";
		const attributeValueApi =
			baseApi + "/{projectId}/AttributeValues?abbreviation={abbreviation}";
		const attributeValueApi2 =
			baseApi + "/{projectId}/AttributeValues2/{categorizationId}";
		const attributeDefinitionUsage =
			baseApi + "/{projectId}/ModelAttributeDefinitionsUsage?abbreviation={abbreviation}";

		function get(projectId) {
			return repositoryBase.get(attributeCategorizationApi, { projectId: projectId });
		}

		function getById(projectId, categorizationId) {
			return repositoryBase.get(attributeCategorizationIdApi, {
				projectId: projectId,
				categorizationId: categorizationId,
			});
		}

		function remove(projectId, categorizationId) {
			return repositoryBase.remove(attributeCategorizationIdApi, {
				projectId: projectId,
				categorizationId: categorizationId,
			});
		}

		function save(projectId, attributeCategorization) {
			return repositoryBase.put(attributeCategorizationApi, {
				projectId: projectId,
			}, attributeCategorization, true);
		}

		function exportAll(projectId) {
			return repositoryBase
				.post(attributeCategorizationApi + "/export", { projectId: projectId })
				.then(data => {
					return JSON.stringify(data, undefined, 3);
				});
		}

		function importAll(projectId, serializedExportedData) {
			const exportedData = JSON.parse(serializedExportedData);
			return repositoryBase.post(attributeCategorizationApi + "/import", {
				projectId: projectId,
			}, exportedData);
		}

		function getAttributeValues(projectId, abbreviation) {
			return repositoryBase.get(attributeValueApi, {
				projectId: projectId,
				abbreviation: abbreviation,
			});
		}

		function getAttributeValues2(projectId, categorizationId, categorizationConfiguration) {
			return repositoryBase.post(attributeValueApi2, {
				projectId: projectId,
				categorizationId: categorizationId,
			}, categorizationConfiguration);
		}

		function getAttributeDefinitionUsage(projectId, abbreviation) {
			return repositoryBase.get(attributeDefinitionUsage, {
				projectId: projectId,
				abbreviation: abbreviation,
			});
		}

		return {
			get: get,
			getById: getById,
			save: save,
			remove: remove,
			exportAll: exportAll,
			importAll: importAll,
			getAttributeValues: getAttributeValues,
			getAttributeValues2: getAttributeValues2,
			getAttributeDefinitionUsage: getAttributeDefinitionUsage,
		};
	}
]);
