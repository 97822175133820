angular.module("eShare.ui").directive("cadHttpSrc", cadHttpSrcDirective);

cadHttpSrcDirective.$inject = ["$http"];

function cadHttpSrcDirective($http) {
	return {
		scope: {},
		link: link,
	};

	function link($scope, elem, attrs) {
		function revokeObjectUrl() {
			if($scope.objectUrl) {
				URL.revokeObjectURL($scope.objectUrl);
			}
		}

		function processImage(response) {
			const blob = new Blob(
				[response.data],
				{ type: response.headers("Content-Type") }
			);
			$scope.objectUrl = URL.createObjectURL(blob);
		}

		$scope.$watch("objectUrl", objectUrl => {
			elem.attr("src", objectUrl);
		});

		$scope.$on("$destroy", () => {
			revokeObjectUrl();
		});

		attrs.$observe("cadHttpSrc", url => {
			revokeObjectUrl();
			if(!url) {
				return;
			}
			if(url.indexOf("data:") === 0) {
				$scope.objectUrl = url;
			} else {
				const config = {
					responseType: "arraybuffer",
					headers: {
						"accept": "image/*,*/*;q=0.8",
					},
				};
				$http.get(url, config).then(processImage);
			}
		});
	}
}
