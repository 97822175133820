angular.module("eShareApp").controller("UserListCtrl", [
	"$scope", "messageBox", "users", "usersRepository", "notification",

	function (
		$scope, messageBox, users, usersRepository, notification
	) {

		$scope.selectedUser = null;
		$scope.users = users;
		$scope.activeUserCount = 0;
		$scope.onlineUserCount = 0;
		$scope.isBusy = false;
		localizeTimes();
		countActiveUsers();
		getLoggedInUsers();

		usersRepository.getAllowUserNameChange().then(isAllowed => {
			$scope.allowUserNameChange = isAllowed;
		});

		$scope.allowUserNameChangeSettingChanged = function () {
			if($scope.isBusy === true) {
				return;
			}
			$scope.isBusy = true;
			$scope.allowUserNameChange = !$scope.allowUserNameChange;
			usersRepository.setAllowUserNameChange($scope.allowUserNameChange).then(() => {
				$scope.isBusy = false;
			});
		};

		$scope.removeUser = function (user) {
			const mbox = messageBox.openQuestion(
				"Remove selected user?",
				"Do you really want to remove user " + user.name + "?",
				"Remove", "Cancel"
			);
			mbox.result.then(() => {
				usersRepository.remove(user).then(updateUserList, onError);
			});
		};

		$scope.activateUser = function (user) {
			user.isDeactivated = false;
			usersRepository.update(user)
				.then(updateUserList, onError);
		};

		$scope.selectUser = function (user) {
			$scope.selectedUser = user;
		};

		function onError(error) {
			notification.error(error);
		}

		function updateUserList() {
			usersRepository.getAll()
				.then(allUsers => {
					$scope.users = allUsers;
					countActiveUsers();
					localizeTimes();
					getLoggedInUsers();
				}, onError);
		}

		function countActiveUsers() {
			$scope.activeUserCount = _.filter($scope.users, { "isDeactivated": false }).length;
		}

		function getLoggedInUsers() {
			usersRepository.getLoggedInUsers()
				.then(users => {
					$scope.onlineUserCount = 0;
					const now = new Date();
					const msMinute = 60 * 1000;
					for(let usersIndex = 0; usersIndex < $scope.users.length; usersIndex++) {
						$scope.users[usersIndex].loggedIn = false;
						let onlineUsersIndex = 0;
						for(onlineUsersIndex; onlineUsersIndex < users.length; onlineUsersIndex++) {
							if($scope.users[usersIndex].login === users[onlineUsersIndex].login) {
								if(Math.floor(
									(now - Date.parse(users[onlineUsersIndex].lastSeenAtUtcTime)) / msMinute
								) <= 2) {
									$scope.users[usersIndex].loggedIn = true;
									$scope.onlineUserCount++;
								}
							}
						}
					}
				}, onError);
		}

		function localizeTimes() {
			for(let i = 0; i < $scope.users.length; i++) {
				const user = $scope.users[i];
				if(user.deactivatedAt) {
					user.deactivatedAt = moment.utc(user.deactivatedAt).local();
				}
			}
		}
	}
]);
