angular.module("eShareApp").factory("projectActivityRepository", projectActivityRepository);

projectActivityRepository.$inject = ["repositoryBase"];

function projectActivityRepository(repositoryBase) {

	const setApi = "api/project/{projectId}/projectActivity";
	const setShortApi = "api/project/{projectId}/projectActivityShort";
	const getApi = "api/misc/getProjectActivity";

	return {
		setProjectActivity: setProjectActivity,
		setProjectActivityShort: setProjectActivityShort,
		getProjectActivity: getProjectActivity,
	};

	function setProjectActivity(projectId, data) {
		return repositoryBase.post(setApi, { projectId: projectId }, data);
	}

	function setProjectActivityShort(projectId, data) {
		return repositoryBase.post(setShortApi, { projectId: projectId }, data);
	}

	function getProjectActivity() {
		return repositoryBase.get(getApi);
	}
}