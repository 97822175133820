angular.module("eShareApp").directive("cadCategorizationEditor", cadCategorizationEditorDirective);

cadCategorizationEditorDirective.$inject = [];

function cadCategorizationEditorDirective() {
	return {
		restrict: "E",
		controller: cadCategorizationEditorController,
		controllerAs: "vm",
		bindToController: {
			categorization: "=",
			isEditing: "=",
			isUsingDynamicCategories: "=",
			isCaseSensitive: "=",
		},
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadCategorizationEditor.directive.html'),
		replace: true,
	};
}

cadCategorizationEditorController.$inject = ["messageBox", "colorService", "$state"];

function cadCategorizationEditorController(messageBox, colorService, $state) {
	const vm = this;
	colorService.getProjectColors($state.params.projectId).then(colors => {
		vm.colors = colors;
	});
	vm.deleteCategories = function (event, categories, index) {
		if(categories.length >= 2) {
			event.stopPropagation();
			const mbox = messageBox.openQuestion(
				"Remove these " + categories.length + " categories?",
				"Do you really want to remove " + categories.length + " selected categories?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					vm.categorization.deleteCategories(categories);
					vm.selectedCategories = [];
				}
			);
		} else {
			vm.categorization.deleteCategory(index);
		}
	};

	vm.getColorName = function (color) {
		const obj = _.find(vm.colors, { "color": color.trim() });
		if(obj == null || !obj.name) {
			return color;
		} else {
			return obj.name;
		}
	};
}