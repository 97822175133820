angular.module("eShareApp").factory("iconsRepository", [
	"repositoryBase",

	function (repositoryBase) {

		const iconsApi = "/api/icons";

		function getIcons() {
			return repositoryBase.get(iconsApi, {});
		}

		return {
			getIcons: getIcons,
		};
	}
]);
