angular.module("eShareApp").factory("markupPhotosUploadService", markupPhotosUploadService);
markupPhotosUploadService.$inject = [
	"notification", "Upload", "$http", "onBeforeUnloadService", "$timeout"
];

//TODO: merge with point cloud upload service somehow?
const services = {};

function markupPhotosUploadService(
	notification, upload, $http, onBeforeUnloadService, $timeout
) {
	const extensions = [".bmp", ".gif", ".exif", ".jpg", ".jpeg", ".png", ".tiff"];

	onBeforeUnloadService.addUnloadListener(event => {
		let downloads = 0;
		_.forOwn(services, value => {
			downloads += value.uploadStatuses.length;
		});
		if(downloads > 0) {
			const toReturn = downloads + " markup photo upload(s) in progress!";
			event.returnValue = toReturn;
			return toReturn;
		}
		return "";
	});

	function forProject(project) {
		if(services[project.id]) {
			return services[project.id];
		}
		const uploadStatuses = [];
		let isCanceled = false;
		let isUploadInProgress = false;

		function uploadFiles(files, markupId) {
			if(isUploadInProgress) {
				throw "Tried to upload with upload still in progress!";
			}
			isCanceled = false;
			isUploadInProgress = true;
			uploadStatuses.length = 0;
			if(files.length > 0) {
				const uploadsBox = document.getElementById("uploads");
				if(uploadsBox) {
					uploadsBox.scrollIntoView();
				}
			}
			const uploadsToPerform = files
				.map(file => {
					if(!file) {
						return null;
					}
					let fileType = "";
					const extension = (file.name || "").toLowerCase();
					const dot = extension.lastIndexOf(".");
					const endsWithExtension = _.some(extensions, ext => {
						return extension.endsWith(ext);
					});
					if(endsWithExtension) {
						fileType = extension.substring(dot);
					}
					if(fileType === "") {
						let types = extensions.join(", ");
						const lastComma = types.lastIndexOf(",");
						if(lastComma !== -1) {
							types = types.substring(0, lastComma)
								+ " or "
								+ extensions[extensions.length] + ".";
						}
						notification.error("File " + file.name + ": The file type must be "
							+ ".bmp, .gif, .exif, .jpg, .jpeg, .png or .tiff");
						return null;
					}

					const originalIndex = _.findIndex(uploadStatuses, item => {
						return item.sourceFileName === file.name;
					});
					if(originalIndex !== -1) {
						uploadStatuses.splice(originalIndex, 1);
					}
					const uploadObject = {
						sourceFileName: file.name,
						status: "queued",
						statusText: "Upload in queue",
					};
					uploadStatuses.push(uploadObject);

					if(!window.eShare.integratedSecurity) {
						return function (index, total) {
							return $http.post("api/OneTimeToken/Create")
								.then(tokenData => {
									return uploadPhoto(
										file,
										uploadObject,
										markupId,
										index,
										total,
										tokenData.data.token
									);
								},
								() => {
									notification.error("Error getting one-time authorization token");
									isUploadInProgress = false;
								});
						};
					} else {
						return function (index, total) {
							return uploadPhoto(file, uploadObject, markupId, index, total);
						};
					}
				})
				.filter(item => {
					return item != null;
				});
			return sequentialUpload(uploadsToPerform);
		}

		function sequentialUpload(uploads, index) {
			index = typeof index === "number" ? index : 0;
			if(index < uploads.length && !isCanceled) {
				return uploads[index](index, uploads.length).then(() => {
					return sequentialUpload(uploads, index + 1);
				}, () => {
					return sequentialUpload(uploads, index + 1);
				});
			} else {
				uploadStatuses.length = 0;
				isUploadInProgress = false;
				return $timeout(() => {
				});
			}
		}

		function uploadPhoto(file, uploadObject, markupId, index, total, oneTimeToken) {
			let url = oneTimeToken
				? "api/project/{projectId}/Markups/{markupId}/UploadPhoto?token={oneTimeToken}"
				: "api/project/{projectId}/Markups/{markupId}/UploadPhoto";
			url = url.supplant({
				projectId: project.id,
				oneTimeToken: oneTimeToken,
				markupId: markupId,
			});
			uploadObject.isActive = true;
			uploadObject.progress = "0%";
			const uploadSession = upload.upload({
				url: url,
				data: {
					file: file,
				},
			});
			let lastUpdated = Date.now();
			return uploadSession.then(onSuccess, onError, onProgress);

			function onSuccess() {
				uploadObject.isActive = false;
				uploadObject.progress = "100%";
				uploadObject.status = "success";
				uploadObject.statusText = "Upload successful, awaiting processing";
				notification.success("Photo "
					+ uploadObject.sourceFileName
					+ " uploaded (" + (index + 1) + "/" + total + ")");
			}

			function onError(reason) {
				uploadObject.status = "fail";
				uploadObject.isActive = false;
				uploadObject.progress = "100%";
				uploadObject.statusText = "Upload failed: " + Utilities.getErrorMessage(reason);
				notification.error("Photo upload failed: " + Utilities.getErrorMessage(reason));
			}

			function onProgress(event) {
				if(isCanceled) {
					uploadSession.abort();
				}
				let progress = event.total ? event.loaded / event.total : 0;
				progress = Math.floor(progress * 100);
				// progressbar looks better in IE if you throttle it a bit
				if(Date.now() > lastUpdated + 100 || progress === 100) {
					uploadObject.progress = progress + "%";
					lastUpdated = Date.now();
				}
			}
		}

		function cancel() {
			isCanceled = true;
		}

		function getIsUploadInProgress() {
			return isUploadInProgress;
		}

		const toReturn = {
			uploadFiles: uploadFiles,
			uploadStatuses: uploadStatuses,
			getIsUploadInProgress: getIsUploadInProgress,
			cancel: cancel,
		};
		services[project.id] = toReturn;
		return toReturn;
	}

	return {
		forProject: forProject,
	};
}