angular.module("eShareApp").factory("authorization", [
	"$http", "repositoryBase",

	function (
		$http, repositoryBase
	) {

		const possibleRoles = [
			"anonymous",
			"user",
			"anyprojectadmin",
			"sysadmin"
		];

		const possibleAccessLevels = {
			"public": "*",
			"anonymous": ["anonymous"],
			"user": ["user", "anyprojectadmin", "sysadmin"],
			"anyprojectadmin": ["anyprojectadmin", "sysadmin"],
			"sysadmin": ["sysadmin"],
		};

		const roles = buildRoles();
		const accessLevels = buildAccessLevels();

		function isValidRole(roleName) {
			return angular.isString(roleName) && roleName !== "" && roleName in roles;
		}

		function isValidAccessLevel(accessLevelName) {
			return angular.isString(accessLevelName)
				&& accessLevelName.trim() !== ""
				&& accessLevelName.trim() in accessLevels;
		}

		function authorize(accessLevelName, roleName) {
			if(!isValidAccessLevel(accessLevelName)) {
				throw new EShareException("Invalid accessLevelName: " + accessLevelName);
			}
			if(!isValidRole(roleName)) {
				throw new EShareException("Invalid roleName: " + roleName);
			}
			const accessLevel = accessLevels[accessLevelName.trim()];
			const role = roles[roleName];
			// eslint-disable-next-line no-bitwise
			return (accessLevel.bitMask & role.bitMask) !== 0;
		}

		function buildRoles() {
			const result = {};
			let bitMask = 1;
			for(const role in possibleRoles) {
				const roleName = possibleRoles[role];
				result[roleName] = {
					bitMask: bitMask,
					name: roleName,
				};
				// eslint-disable-next-line no-bitwise
				bitMask <<= 1;
			}
			return result;
		}

		function buildAccessLevels() {
			const result = {};
			let bitMask;
			let role;
			for(const level in possibleAccessLevels) {
				if(typeof possibleAccessLevels[level] === "string") {
					if(possibleAccessLevels[level] === "*") {
						bitMask = 0;
						for(role in roles) {
							// eslint-disable-next-line no-bitwise
							bitMask |= roles[role].bitMask;
						}
						result[level] = {
							bitMask: bitMask,
							name: level,
						};
					} else {
						console.log(
							"Access Control Error: Could not parse \""
							+ possibleAccessLevels[level] + "\" as access definition for level \""
							+ level + "\""
						);
					}
				} else {
					bitMask = 0;
					for(role in possibleAccessLevels[level]) {
						if(roles.hasOwnProperty(possibleAccessLevels[level][role])) {
							// eslint-disable-next-line no-bitwise
							bitMask |= roles[possibleAccessLevels[level][role]].bitMask;
						} else {
							console.log(
								"Access Control Error: Could not find role \""
								+ possibleAccessLevels[level][role]
								+ "\" in registered roles while building access for \"" + level + "\""
							);
						}
					}
					result[level] = {
						bitMask: bitMask,
						name: level,
					};
				}
			}
			return result;
		}

		function getUserPermissions(projectId) {
			return $http.get("/api/UserPermissions/" + projectId);
		}

		function createOneTimeToken() {
			return repositoryBase.post("api/OneTimeToken/Create", {});
		}

		return {
			isValidAccessLevel: isValidAccessLevel,
			isValidRole: isValidRole,
			authorize: authorize,
			roles: roles,
			accessLevels: accessLevels,
			getUserPermissions: getUserPermissions,
			createOneTimeToken: createOneTimeToken,
		};
	}
]);
