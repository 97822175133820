angular.module("eShareApp").factory("subModelDivisionRepository", subModelDivisionRepository);

subModelDivisionRepository.$inject = ["repositoryBase"];

function subModelDivisionRepository(repositoryBase) {
	const baseApi = "/api/project";
	const divisionRulesApi = baseApi + "/{projectId}/SubModelDivisionRules";
	const exportApi = baseApi + "/{projectId}/SubModelDivisionRules/export";
	const importApi = baseApi + "/{projectId}/SubModelDivisionRules/import";
	const modelDivisionInformationApi = baseApi + "/{projectId}/ModelDivisionInformation";
	const getSubModelGeometryIdsApi = baseApi + "/{projectId}/ProjectSubModel/{subModelId}/internal";

	return {
		setModelDivisionRules: setModelDivisionRules,
		getModelDivisionRules: getModelDivisionRules,
		exportAll: exportAll,
		importAll: importAll,
		getModelDivisionInformation: getModelDivisionInformation,
		getSubModelGeometryIds: getSubModelGeometryIds,
	};

	function setModelDivisionRules(projectId, divisionRules) {
		return repositoryBase.post(divisionRulesApi, { projectId: projectId }, divisionRules);
	}

	function getModelDivisionRules(projectId) {
		return repositoryBase.get(divisionRulesApi, { projectId: projectId });
	}

	function exportAll(projectId) {
		return repositoryBase.post(exportApi, { projectId: projectId }).then(
			data => {
				return JSON.stringify(data, undefined, 3);
			}
		);
	}

	function importAll(projectId, serializedExportedData) {
		const exportedData = JSON.parse(serializedExportedData);
		return repositoryBase.post(importApi, { projectId: projectId }, exportedData);
	}

	function getModelDivisionInformation(projectId) {
		return repositoryBase.post(modelDivisionInformationApi, { projectId: projectId });
	}

	function getSubModelGeometryIds(projectId, subModelId) {
		return repositoryBase.get(
			getSubModelGeometryIdsApi,
			{ projectId: projectId, subModelId: subModelId }
		);
	}
}