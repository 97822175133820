angular.module("eShareApp").factory("attributeDefinitionRepository", attributeDefinitionRepository);

attributeDefinitionRepository.$inject = ["repositoryBase"];

function attributeDefinitionRepository(repositoryBase) {
	const baseApi = "/api/project/{projectId}";
	const hideEmptyAttributesApi = baseApi + "/HideEmptyAttributes";
	const modelAttributeDefinitionsApi = baseApi + "/ModelAttributeDefinitions/{modelId}";
	const databaseAttributeDefinitionsApi = modelAttributeDefinitionsApi + "/DB";
	const databaseAttributeForObjectsDefinitionsApi = modelAttributeDefinitionsApi + "/DB/Objects";
	const definitionsApi = "/api/project/{projectId}/ModelAttributeDefinitions";
	const definitionsUsedByObjectsApi =
		"api/project/{projectId}/ModelAttributeDefinitions/UsedByObjects";

	return {
		getDefinitions: getDefinitions,
		getDefinitionsUsedByObjects: getDefinitionsUsedByObjects,
		getSearchableDefinitions: getSearchableDefinitions,
		getIdAndGroupDefinitions: getIdAndGroupDefinitions,
		getCoordinateAttributes: getCoordinateAttributes,
		get: get,
		getDatabaseAttributes: getDatabaseAttributes,
		getDatabaseAttributesForObjects: getDatabaseAttributesForObjects,
		save: save,
		exportAttributeVisibilities: exportAttributeVisibilities,
		importAttributeVisibilities: importAttributeVisibilities,
		getHideEmptyAttributes: getHideEmptyAttributes,
		saveHideEmptyAttributes: saveHideEmptyAttributes,
	};

	function save(projectId, definitions) {
		return repositoryBase.post(definitionsApi, { projectId: projectId }, definitions);
	}

	function getDefinitions(projectId) {
		return getSortedDefinitions(definitionsApi, projectId);
	}

	function getDefinitionsUsedByObjects(projectId) {
		return getSortedDefinitions(definitionsUsedByObjectsApi, projectId);
	}

	function getSearchableDefinitions(projectId) {
		return getSortedDefinitions(
			"/api/project/{projectId}/SearchableModelAttributeDefinitions", projectId
		);
	}

	function getIdAndGroupDefinitions(projectId) {
		return getSortedDefinitions(
			"/api/project/{projectId}/IdModelAttributeDefinitions", projectId
		);
	}

	function getSortedDefinitions(uri, projectId) {
		return repositoryBase
			.get(uri, { projectId: projectId })
			.then(data => {
				let attributeDefinitions = data;
				attributeDefinitions = _.sortBy(attributeDefinitions, attributeDefinition => {
					return attributeDefinition.displayName.toLowerCase();
				});
				return attributeDefinitions;
			});
	}

	function get(projectId, modelId) {
		return repositoryBase.get(
			modelAttributeDefinitionsApi, { projectId: projectId, modelId: modelId }
		);
	}

	function getDatabaseAttributes(projectId, modelId, ignoreErrors) {
		// Note: modelId is used to identify the model through the model revision; the particular
		// revision isn't used
		return repositoryBase
			.get(
				databaseAttributeDefinitionsApi,
				{ projectId: projectId, modelId: modelId },
				ignoreErrors
			)
			.then(null, errorMessage => {
				if(ignoreErrors) {
					return [];
				} else {
					throw errorMessage;
				}
			});
	}

	function getDatabaseAttributesForObjects(projectId, modelId, ignoreErrors) {
		// Note: modelId is used to identify the model through the model revision; the particular
		// revision isn't used
		return repositoryBase
			.get(
				databaseAttributeForObjectsDefinitionsApi,
				{ projectId: projectId, modelId: modelId },
				ignoreErrors
			)
			.then(null, errorMessage => {
				if(ignoreErrors) {
					return [];
				} else {
					throw errorMessage;
				}
			});
	}

	function getHideEmptyAttributes(projectId) {
		return repositoryBase.get(hideEmptyAttributesApi, { projectId: projectId });
	}

	function saveHideEmptyAttributes(projectId, bool) {
		return repositoryBase.post(hideEmptyAttributesApi, { projectId: projectId }, bool);
	}

	function importAttributeVisibilities(projectId, serializedExportedData) {
		const exportedData = JSON.parse(serializedExportedData);
		return repositoryBase.post(
			Utilities.combineUri(definitionsApi, "importVisibilities"),
			{ projectId: projectId },
			exportedData
		);
	}

	function exportAttributeVisibilities(projectId) {
		return repositoryBase
			.post(Utilities.combineUri(definitionsApi, "exportVisibilities"), { projectId: projectId })
			.then(exportedData => JSON.stringify(exportedData, undefined, 3));
	}

	function getCoordinateAttributes(projectId) {
		return repositoryBase.get(
			"api/project/{projectId}/ModelAttributeDefinitions/coordinateAttributes",
			{ projectId: projectId }
		);
	}
}
