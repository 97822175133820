angular.module("eShareApp").factory("pointOfInterestKindRepository", [
	"repositoryBase", "notification",
	function (repositoryBase, notification) {

		const baseApi = "/api/project/{projectId}";
		const kindApi = baseApi + "/PointOfInterestKind/{kindId}";
		const kindsApi = baseApi + "/PointOfInterestKinds";
		const editableApi = baseApi + "/PointOfInterestKinds/GetAllEditable";
		const creatableApi = baseApi + "/PointOfInterestKinds/GetAllCreatable";
		const locatePointApi = "/api/project/{projectId}/PointsOfInterest/GetCoordinates/{pointId}";
		const exportBaseApi = "api/project/{projectId}/SmartPoints";

		function locatePointById(projectId, pointId) {
			return repositoryBase.get(locatePointApi, { projectId: projectId, pointId: pointId });
		}

		function getAll(projectId) {
			return repositoryBase.get(kindsApi, { projectId: projectId }).then(data => {
				let kinds = Utilities.isArray(data) ? data : [];
				kinds = _.sortBy(kinds, kind => {
					return kind.name.toLowerCase();
				});
				return kinds;
			}, error => {
				notification.error(error);
			});
		}

		function getAllCreatable(projectId) {
			return repositoryBase.get(creatableApi, { projectId: projectId }).then(data => {
				let kinds = Utilities.isArray(data) ? data : [];
				kinds = _.sortBy(kinds, kind => {
					return kind.name.toLowerCase();
				});
				return kinds;
			}, error => {
				notification.error(error);
			});
		}

		function getAllEditable(projectId) {
			return repositoryBase.get(editableApi, { projectId: projectId }).then(data => {
				let kinds = Utilities.isArray(data) ? data : [];
				kinds = _.sortBy(kinds, kind => {
					return kind.name.toLowerCase();
				});
				return kinds;
			}, error => {
				notification.error(error);
			});
		}

		function get(projectId, kindId) {
			return repositoryBase.get(kindApi, { projectId: projectId, kindId: kindId }).then(data => {
				return data;
			}, error => {
				notification.error(error);
			});
		}

		function add(projectId, kind) {
			return repositoryBase.post(kindsApi, { projectId: projectId }, kind);
		}

		function update(projectId, kind) {
			return repositoryBase.put(kindsApi, { projectId: projectId }, kind);
		}

		function remove(projectId, kindId) {
			return repositoryBase.remove(kindApi, { projectId: projectId, kindId: kindId });
		}

		function exportAll(projectId) {
			return repositoryBase.post(
				"/api/project/{projectId}/SmartPoints/Export",
				{ projectId: projectId }
			).then(data => {
				return JSON.stringify(data, undefined, 3);
			});
		}

		function importAll(projectId, serializedExportedData, importMarkup) {
			const exportedData = JSON.parse(serializedExportedData);
			if(Utilities.isObject(exportedData)) {
				exportedData.shouldImportMarkup = importMarkup;
			}
			return repositoryBase.post(
				"/api/project/{projectId}/SmartPoints/Import",
				{ projectId: projectId },
				exportedData
			);
		}

		function previewExcelData(projectId, excelConfig) {
			return repositoryBase.post(
				exportBaseApi + "/Excel/RowsPreview",
				{ projectId: projectId },
				excelConfig
			);
		}

		function previewExcelSmartPoints(projectId, excelConfig) {
			return repositoryBase.post(
				exportBaseApi + "/Excel/SmartPointsPreview",
				{ projectId: projectId },
				excelConfig
			);
		}

		function importExcelData(projectId, excelConfig) {
			return repositoryBase.post(
				exportBaseApi + "/Excel/Import",
				{ projectId: projectId },
				excelConfig
			);
		}

		return {
			getAll: getAll,
			getAllEditable: getAllEditable,
			getAllCreatable: getAllCreatable,
			get: get,
			add: add,
			update: update,
			remove: remove,
			locatePointById: locatePointById,
			exportAll: exportAll,
			importAll: importAll,
			previewExcelData: previewExcelData,
			importExcelData: importExcelData,
			previewExcelSmartPoints: previewExcelSmartPoints,
		};
	}
]);