angular.module("eShareApp").factory("messageBox", [
	"$uibModal",
	function ($uibModal) {

		function openOkCancel(title, message, okButtonText, cancelButtonText, ok2ButtonText) {
			if(Utilities.isNullOrUndefined(title)) {
				throw new EShareException("MessageBox title must be defined");
			}
			if(Utilities.isNullOrUndefined(message)) {
				throw new EShareException("MessageBox message must be defined");
			}
			if(Utilities.isNullOrUndefined(okButtonText)) {
				throw new EShareException("MessageBox okButtonText must be defined");
			}
			const modalOptions = angular.extend(
				{}, {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\dialogs\\MessageBox.html'),
					controller: "MessageBoxCtrl",
					backdrop: true,
				}, {
					resolve: {
						options: function () {
							return {
								title: title,
								message: message,
								okButtonText: okButtonText,
								cancelButtonText: cancelButtonText,
								ok2ButtonText: ok2ButtonText,
							};
						},
					},
				}
			);
			return $uibModal.open(modalOptions);
		}

		function open(title, message, okButtonText, cancelButtonText) {
			return openOkCancel(title, message, okButtonText, cancelButtonText);
		}

		function openQuestion(title, message, okButtonText, cancelButtonText) {
			return openOkCancel(title, message, okButtonText, cancelButtonText);
		}

		function openTripleQuestion(title, message, okButtonText, ok2ButtonText, cancelButtonText) {
			return openOkCancel(title, message, okButtonText, cancelButtonText, ok2ButtonText);
		}

		function openInfo(title, message) {
			return openOkCancel(title, message, "OK", "");
		}

		function openError(title, message) {
			return openOkCancel(title, message, "OK", "");
		}

		return {
			open: open,
			openQuestion: openQuestion,
			openTripleQuestion: openTripleQuestion,
			openInfo: openInfo,
			openError: openError,
		};
	}
]);