angular.module("eShareApp").controller("DocumentListConfigurationDialogCtrl", [
	"$scope", "$uibModalInstance", "metadataFields", "dataSourceId", "projectId",
	"documentListConfigurationRepository", "authorization",

	function (
		$scope, $uibModalInstance, metadataFields, dataSourceId, projectId,
		documentListConfigurationRepository, authorization
	) {

		$scope.metadataFields = metadataFields;
		$scope.displayedColumns = [];
		$scope.unusedFields = [];

		authorization.getUserPermissions(projectId).then(permission => {
			$scope.isAdmin = permission.data.isProjectAdmin || permission.data.isSystemAdmin;
		});

		documentListConfigurationRepository.get(projectId, dataSourceId).then(config => {
			$scope.displayedColumns = config;
			if($scope.displayedColumns.length === 0 && $scope.metadataFields.length > 0) {
				// By default, only the "Revision" column is displayed:
				$scope.displayedColumns = [];
				if(_.some($scope.metadataFields, field => {
					return field === "Revision";
				})) {
					$scope.displayedColumns.push({
						displayName: "Revision",
					});
				}
			}
			populateUnusedFields();
		});

		$scope.sortableOptions = {
			containment: "#displayedColumnsList",
			containerPositioning: "relative",
		};

		$scope.moveUp = function (column) {
			const i = _.indexOf($scope.displayedColumns, column);
			if(i > 0) {
				const previousColumn = $scope.displayedColumns[i - 1];
				$scope.displayedColumns[i - 1] = column;
				$scope.displayedColumns[i] = previousColumn;
			}
		};

		$scope.moveDown = function (column) {
			const i = _.indexOf($scope.displayedColumns, column);
			if(i >= 0 && i < $scope.displayedColumns.length - 1) {
				const nextColumn = $scope.displayedColumns[i + 1];
				$scope.displayedColumns[i + 1] = column;
				$scope.displayedColumns[i] = nextColumn;
			}
		};

		$scope.removeColumn = function (column) {
			_.remove($scope.displayedColumns, column);
			$scope.unusedFields.push(column.displayName);
		};

		$scope.addColumn = function (field) {
			_.remove($scope.unusedFields, value => {
				return value === field;
			});
			$scope.displayedColumns.push({
				displayName: field,
			});
		};

		$scope.ok = function () {
			documentListConfigurationRepository.set(projectId, dataSourceId, $scope.displayedColumns);
			$uibModalInstance.close($scope.displayedColumns);
		};

		$scope.saveAsDefault = function () {
			documentListConfigurationRepository
				.setDefaults(projectId, dataSourceId, $scope.displayedColumns);
		};

		$scope.resetToDefault = function () {
			documentListConfigurationRepository
				.getDefaults(projectId, dataSourceId, $scope.displayedColumns).then(data => {
					$scope.unusedFields.length = 0;
					$scope.displayedColumns = data;
					populateUnusedFields();
				});
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss("cancel");
		};

		function populateUnusedFields() {
			_.forEach($scope.metadataFields, field => {
				if(!_.some($scope.displayedColumns, { displayName: field })) {
					$scope.unusedFields.push(field);
				}
			});
		}
	}
]);
