angular.module("eShareApp").controller("StatusTrackingEditCtrl", StatusTrackingEditCtrl);

StatusTrackingEditCtrl.$inject = [
	"$scope", "$state", "$stateParams", "notification", "statusTracking", "messageBox",
	"statusTrackingKindRepository", "dirtyFormTrackingService", "possibleTargetAttributes",
	"colorService", "groups", "attributeDefinitions"
];

function StatusTrackingEditCtrl(
	$scope, $state, $stateParams, notification, statusTracking, messageBox,
	statusTrackingKindRepository, dirtyFormTrackingService, possibleTargetAttributes,
	colorService, groups, attributeDefinitions
) {

	$scope.isNew = statusTracking === null;
	$scope.originalName = "";
	//$scope.possibleTargetAttributes = _.map(possibleTargetAttributes, 'DisplayName');
	$scope.possibleTargetAttributes = possibleTargetAttributes;
	$scope.isTransitionChecked = isTransitionChecked;
	$scope.toggleTransitionChecked = toggleTransitionChecked;
	$scope.clearAllTransitions = clearAllTransitions;
	$scope.setAllTransitions = setAllTransitions;
	$scope.groups = groups;
	$scope.attributeDefinitions = attributeDefinitions;
	$scope.isUsedForGroupingToggle = false;
	$scope.isInUse = false;

	initialize();

	function initialize() {
		if($scope.isNew) {
			$scope.statusTracking = {
				name: "",
				statusValues: [],
				pointCount: 0,
				defaultCategory: "Undefined",
				defaultColor: "#222222",
				isAddingEnumsAllowed: false,
				isUsingTemplate: false,
				isUsedForGrouping: false,
				IsReferencingGuid: false,
				template: [],
				displayCategory: "",
			};
			_.forEach($scope.groups, group => {
				group.allowedTargetStates = [];
			});
		} else {
			$scope.statusTracking = _.cloneDeep(statusTracking);
			$scope.isInUse = _.some($scope.statusTracking.statusValues, sv => {return sv.isUsed;});
			$scope.originalName = $scope.statusTracking.name;
			$scope.statusTracking.targetAttribute = _.find($scope.possibleTargetAttributes,
				{ id: $scope.statusTracking.attributeDefinitionId });
			_.forEach($scope.groups, group => {
				group.hasPermissionCreate = $scope.statusTracking.hasPermissionCreateGroupIds
					&& $scope.statusTracking.hasPermissionCreateGroupIds.indexOf(group.id) > -1;
				group.hasPermissionSee = $scope.statusTracking.hasPermissionSeeGroupIds
					&& $scope.statusTracking.hasPermissionSeeGroupIds.indexOf(group.id) > -1;
				group.hasPermissionChange = $scope.statusTracking.hasPermissionChangeGroupIds
					&& $scope.statusTracking.hasPermissionChangeGroupIds.indexOf(group.id) > -1;
				group.allowedTargetStates = _.map(_.filter($scope.statusTracking.statusValues, sv => {
					return sv.groupsAllowedToTarget.indexOf(group.id) > -1;
				}), "id");
			});
			if($scope.statusTracking.template == null) {
				$scope.statusTracking.isUsingTemplate = false;
				$scope.statusTracking.template = [];
			} else {
				$scope.statusTracking.isUsingTemplate = true;
			}
			$scope.isUsedForGroupingToggle = $scope.statusTracking.isUsedForGrouping;
		}
		if($scope.statusTracking.isUsedForGrouping){
			clearAllTransitions();
		} else {
			cleanupTransitions();
			if(Utilities.isNullOrUndefined($scope.statusTracking.transitions)) {
				setAllTransitions();
			}
		}
	}

	function clearAllTransitions() {
		$scope.statusTracking.transitions = null;
	}

	function setAllTransitions() {
		clearAllTransitions();
		const validStateIds = getValidStateIds();
		_.forEach(validStateIds, stateId => {
			toggleTransitionChecked(null, stateId);
			_.forEach(validStateIds, toStateId => {
				if(stateId !== toStateId) {
					toggleTransitionChecked(stateId, toStateId);
				}
			});
		});
	}

	function cleanupTransitions() {
		const transitions = $scope.statusTracking.transitions;
		if(Utilities.isNullOrUndefined(transitions)) {
			return;
		}
		const validStateIds = getValidStateIds();
		_.remove(transitions, transition => {
			return (
				transition.fromStateId !== Utilities.emptyGuid()
				&& !_.includes(validStateIds, toLower(transition.fromStateId))
			) || !Utilities.isArray(transition.toStateIds);
		});
		_.forEach(transitions, transition => {
			_.remove(transition.toStateIds, stateId => {
				return !_.includes(validStateIds, toLower(stateId));
			});
		});
		_.remove(transitions, transition => {
			return !transition.toStateIds || transition.toStateIds.length === 0;
		});
		if(transitions.length === 0) {
			$scope.statusTracking.transitions = null;
		}
	}

	function validateTransitions() {
		function getTransitionByStateId(fromStateId) {
			return _.find($scope.statusTracking.transitions, transition => {
				return areGuidsEqual(transition.fromStateId, fromStateId);
			});
		}

		function traverseTransitions(fromStateId, visitedStateIds) {
			fromStateId = toLower(fromStateId);
			if(_.includes(visitedStateIds, fromStateId)) {
				return;
			}
			visitedStateIds.push(fromStateId);
			const transition = getTransitionByStateId(fromStateId);
			if(Utilities.isNullOrUndefined(transition)) {
				return;
			}
			_.forEach(transition.toStateIds, stateId => {
				traverseTransitions(stateId, visitedStateIds);
			});
		}

		if(Utilities.isNullOrUndefined($scope.statusTracking.transitions)) {
			return true;
		}

		const visitedStateIds = [];
		traverseTransitions(Utilities.emptyGuid(), visitedStateIds);
		const validStateIds = getValidStateIds();
		let message = "";
		let hasErrors = false;
		_.forEach(validStateIds, stateId => {
			if(!_.includes(visitedStateIds, toLower(stateId))) {
				const state = _.find($scope.statusTracking.statusValues, statusValue => {
					return areGuidsEqual(statusValue.id, stateId);
				});
				message = message + "The state <i>\"" + state.name
				+ "\"</i> has not been added to the workflow. Redefine the status workflow <br>";
				hasErrors = true;
			}
		});
		if(hasErrors) {
			messageBox.openError("Invalid Transitions", message);
		}
		return !hasErrors;
	}

	function getValidStateIds() {
		const validStateIds = $scope.statusTracking.statusValues.filter(sv => {
			return Utilities.isGuid(sv.id);
		})
			.map(sv => {
				return toLower(sv.id);
			});
		return validStateIds;
	}

	function isTransitionChecked(fromStatusValueId, toStatusValueId) {
		if(!fromStatusValueId) {
			fromStatusValueId = Utilities.emptyGuid();
		}
		if(!Utilities.isGuid(fromStatusValueId) || !Utilities.isGuid(toStatusValueId)) {
			return false;
		}
		const transitions = $scope.statusTracking.transitions;
		if(Utilities.isNullOrUndefined(transitions)) {
			return false;
		}
		const fromStateId = toLower(fromStatusValueId);
		const transitionsForState = _.find(transitions, transition => {
			return areGuidsEqual(transition.fromStateId, fromStateId);
		});
		if(!transitionsForState) {
			return false;
		}
		const toStateId = toLower(toStatusValueId);
		return _.some(transitionsForState.toStateIds, stateId => {
			return areGuidsEqual(stateId, toStateId);
		});
	}

	function toggleTransitionChecked(fromStatusValueId, toStatusValueId) {
		if(!fromStatusValueId) {
			fromStatusValueId = Utilities.emptyGuid();
		}
		if(!Utilities.isGuid(fromStatusValueId) || !Utilities.isGuid(toStatusValueId)) {
			return;
		}
		const fromStateId = toLower(fromStatusValueId);
		const toStateId = toLower(toStatusValueId);
		let transitions = $scope.statusTracking.transitions;
		if(!transitions) {
			transitions = [];
			$scope.statusTracking.transitions = transitions;
		}
		let transitionsForState = _.find(transitions, transition => {
			return areGuidsEqual(transition.fromStateId, fromStateId);
		});
		if(!transitionsForState) {
			transitionsForState = {
				fromStateId: fromStateId,
				toStateIds: [],
			};
			transitions.push(transitionsForState);
		}
		const isPossible = _.some(transitionsForState.toStateIds, stateId => {
			return areGuidsEqual(stateId, toStateId);
		});
		if(isPossible) {
			_.remove(transitionsForState.toStateIds, stateId => {
				return areGuidsEqual(stateId, toStateId);
			});
		} else {
			transitionsForState.toStateIds.push(toStateId);
		}
	}

	$scope.isNameChanged = function () {
		return !$scope.isNew && $scope.originalName !== $scope.statusTracking.name;
	};

	// Change tracking (warn before navigating to a different page
	// if the user has changed something on the page):
	$scope.setFormScope = function (formScope) {
		$scope.formScope = formScope;
		dirtyFormTrackingService.trackForm($scope.formScope, "form");
	};

	$scope.editCommit = function () {
		if(!$scope.statusTracking.isUsedForGrouping && $scope.statusTracking.isAddingEnumsAllowed) {
			setAllTransitions();
		}
		
		if($scope.statusTracking.isUsedForGrouping){
			clearAllTransitions();
		} else {
			cleanupTransitions();
			const areTransitionsValid = validateTransitions();
			if(!areTransitionsValid) {
				return;
			}
			if(!$scope.statusTracking.transitions && !$scope.statusTracking.isAddingEnumsAllowed) {
				notification.warning(
					"No state transitions were specified - all transitions will be enabled"
				);
			}
		}
		const targetAttribute = $scope.statusTracking.targetAttribute;
		$scope.statusTracking.attributeDefinitionId = targetAttribute.id;
		$scope.statusTracking.attributeDefinitionAbbreviation = targetAttribute.abbreviation;
		for(let i = 0; i < $scope.statusTracking.statusValues.length; i++) {
			const statusValue = $scope.statusTracking.statusValues[i];
			statusValue.color = statusValue.color.trim();
			statusValue.groupsAllowedToTarget = [];
			for(let j = 0; j < $scope.groups.length; j++) {
				const group = $scope.groups[j];
				if(group.allowedTargetStates.indexOf(statusValue.id) > -1) {
					statusValue.groupsAllowedToTarget.push(group.id);
					continue;
				}
			}
		}
		$scope.statusTracking.hasPermissionCreateGroupIds = getGroupIdsForPermission(group => {
			return group.hasPermissionCreate;
		});
		$scope.statusTracking.hasPermissionSeeGroupIds = getGroupIdsForPermission(group => {
			return group.hasPermissionSee;
		});
		$scope.statusTracking.hasPermissionChangeGroupIds = getGroupIdsForPermission(group => {
			return group.hasPermissionChange;
		});
		if(!$scope.statusTracking.isAddingEnumsAllowed || !$scope.statusTracking.isUsingTemplate) {
			$scope.statusTracking.template = null;
		}

		let updateFunction;
		if($scope.isNew) {
			updateFunction = statusTrackingKindRepository.save;
		} else {
			updateFunction = statusTrackingKindRepository.update;
		}
		const promise = updateFunction($stateParams.projectId, $scope.statusTracking);
		promise.then(
			() => {
				notification.success("Status tracking kind successfully saved");
				$scope.formScope.form && $scope.formScope.form.$setPristine();
				goBack();
			},
			message => {
				messageBox.openError(
					"Error creating / modifying status tracking",
					"Error creating / modifying status tracking: " + message
				);
			}
		);
	};
	$scope.hasAllowedGroups = function () {
		return _.some($scope.groups, group => {
			return group.hasPermissionCreate || group.hasPermissionSee || group.hasPermissionChange;
		});
	};

	$scope.isValid = function () {
		return $scope.statusTracking
				&& $scope.statusTracking.name
				&& $scope.statusTracking.targetAttribute
				&& _.every($scope.statusTracking.statusValues,
					sv => {
						return sv.name;
					})
				&& _.every($scope.statusTracking.statusValues,
					sv1 => {
						return _.every($scope.statusTracking.statusValues,
							sv2 => {
								return sv1 === sv2 || sv1.name.toUpperCase() !== sv2.name.toUpperCase();
							});
					});
	};

	$scope.addStatusValue = function () {
		const usedColors = _.map($scope.statusTracking.statusValues, "color");
		usedColors.push($scope.statusTracking.defaultColor);
		const newValue = {
			id: Utilities.getFakeGuid(),
			name: "",
			color: colorService.getNextColor(usedColors),
			statusValueCount: 0,
		};
		$scope.statusTracking.statusValues.push(newValue);
		$scope.formScope.form.$setDirty();
	};

	$scope.isNotFirst = function (statusValue) {
		return statusValue !== $scope.statusTracking.statusValues[0];
	};

	$scope.isNotLast = function (statusValue) {
		const lastIndex = $scope.statusTracking.statusValues.length - 1;
		return statusValue !== $scope.statusTracking.statusValues[lastIndex];
	};

	$scope.removeStatusValue = function (statusValue) {
		const index = $scope.statusTracking.statusValues.indexOf(statusValue);
		$scope.statusTracking.statusValues.splice(index, 1);
		$scope.formScope.form.$setDirty();
	};

	$scope.isStatusPermissionChecked = function (groupId, statusValueId) {
		const group = _.find($scope.groups, { "id": groupId });
		if(!group) {
			return false;
		}
		return group.allowedTargetStates.indexOf(statusValueId) > -1;
	};

	$scope.toggleStatusPermissionChecked = function (groupId, statusValueId) {
		const group = _.find($scope.groups, { "id": groupId });
		if(!group) {
			return;
		}
		const statusLocation = group.allowedTargetStates.indexOf(statusValueId);
		if(statusLocation < 0) {
			group.allowedTargetStates.push(statusValueId);
		} else {
			group.allowedTargetStates.splice(statusLocation, 1);
		}
	};

	$scope.isUsedForGroupingChanged = function(){
		$scope.statusTracking.isUsedForGrouping = !$scope.statusTracking.isUsedForGrouping;
		$scope.isUsedForGroupingToggle = $scope.statusTracking.isUsedForGrouping;
		if($scope.statusTracking.isUsedForGrouping){
			clearAllTransitions();
		} else {
			setAllTransitions();
		}
	}

	function goBack() {
		$state.go("^", {}, { reload: true });
	}

	function toLower(value) {
		return (value || "").toLowerCase();
	}

	function areGuidsEqual(a, b) {
		return toLower(a) === toLower(b);
	}

	function getGroupIdsForPermission(permissionSelector) {
		return _.map(_.filter($scope.groups, group => {
			return permissionSelector(group);
		}), group => {
			return group.id;
		});
	}
}
