angular.module("eShareApp").controller("DocumentFormatEditCtrl", [
	"$state", "$scope", "$filter", "$stateParams", "$timeout", "documentConfigurationsRepository",
	"pdfDocumentConfiguration", "notification", "dirtyFormTrackingService",

	function (
		$state, $scope, $filter, $stateParams, $timeout, documentConfigurationsRepository,
		pdfDocumentConfiguration, notification, dirtyFormTrackingService
	) {

		$scope.pdfConfiguration = pdfDocumentConfiguration || {};
		$scope.pdfConfiguration.documentConversionFormats =
			$scope.pdfConfiguration.documentConversionFormats || [];
		$scope.duplicateNameWarning = false;

		let isNew;
		if($stateParams.documentFormatIndex == -1
			|| $scope.pdfConfiguration.documentConversionFormats.length
				<= $stateParams.documentFormatIndex) {
			$scope.dsc = {
				name: "",
				paperSize: 3,
				portraitOrientation: false,
				convertAllPages: false,
				colorScheme: "Black & White",
			};
			isNew = true;
		} else {
			$scope.dsc = _.cloneDeep(
				$scope.pdfConfiguration.documentConversionFormats[$stateParams.documentFormatIndex]
			);
			isNew = false;
		}

		$scope.displayPaperSize = function (size) {
			switch(size) {
			case 0:
				return "A0";
			case 1:
				return "A1";
			case 2:
				return "A2";
			case 3:
				return "A3";
			case 4:
				return "A4";
			case 5:
				return "2A0";
			case 6:
				return "4A0";
			case 7:
				return "8A0";
			}
		};

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		$scope.save = function () {
			const cfg = _.cloneDeep($scope.dsc);
			if(isNew) {
				$scope.pdfConfiguration.documentConversionFormats.push(cfg);
			} else {
				const formatIndex = $stateParams.documentFormatIndex;
				$scope.pdfConfiguration.documentConversionFormats[formatIndex] = cfg;
			}
			documentConfigurationsRepository
				.savePdfSettings($stateParams.projectId, $scope.pdfConfiguration)
				.then(() => {
					$scope.formScope.form && $scope.formScope.form.$setPristine();
					$state.go("^", {}, { reload: true });
				}, error => {
					notification.error(error);
				});
		};

		$scope.checkForDuplicateNames = function () {
			$scope.duplicateNameWarning = false;
			for(let i = 0; i < $scope.pdfConfiguration.documentConversionFormats.length; i++) {
				if($scope.dsc.name === $scope.pdfConfiguration.documentConversionFormats[i].name
					|| $scope.dsc.name === "Default Format") {
					$scope.duplicateNameWarning = true;
				}
			}
			$scope.someFormatInvalid = $scope.duplicateNameWarning;
		};
	}
]);
