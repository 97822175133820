angular.module("eShareApp").factory("colorService", colorService);

colorService.$inject = ["$timeout", "colorsRepository"];
const projectColors = {};

function colorService($timeout, colorsRepository) {
	function getProjectColors(id) {
		if(typeof id !== "string" || id.trim().length === 0) {
			throw new Error("please give a valid project id");
		}
		if(projectColors[id] == null) {
			return colorsRepository.get(id).then(colors => {
				projectColors[id] = colors;
				return colors;
			});
		} else {
			return $timeout(() => {
				return projectColors[id];
			});
		}
	}

	function resetProjectColors(id) {
		if(typeof id !== "string" || id.trim().length === 0) {
			throw new Error("please give a valid project id");
		}
		delete projectColors[id];
	}

	const rgbPaletteSmall = [
		"#e6194b", "#3cb44b", "#ffe119", "#4363d8", "#f58231", "#911eb4", "#46f0f0", "#f032e6",
		"#bcf60c", "#fabebe", "#008080", "#e6beff", "#9a6324", "#fffac8", "#800000", "#aaffc3",
		"#808000", "#ffd8b1", "#000075", "#808080", "#ffffff", "#000000"
	];
	const rgbPalette = [];
	const labPalette = [];
	let index = 0;

	createPalette();

	return {
		getNextColor: getNextColor,
		getFastColor: getFastColor,
		getProjectColors: getProjectColors,
		resetProjectColors: resetProjectColors,
	};

	function createPalette() {
		const c = new Array("00", "CC", "33", "66", "99", "FF");
		for(let i = 0; i < 6; i++) {
			for(let j = 0; j < 6; j++) {
				for(let k = 0; k < 6; k++) {
					const rgbColor = "#" + c[i] + c[j] + c[k];
					rgbPalette.push(rgbColor);
					labPalette.push(xyzToLab(rgbToXyz(rgbColor)));
				}
			}
		}
	}

	// http://en.wikipedia.org/wiki/Color_difference --> CIE76
	function calculateLabDistance(lab1, lab2) {
		const l1 = lab1[0];
		const l2 = lab2[0];
		const a1 = lab1[1];
		const a2 = lab2[1];
		const b1 = lab1[2];
		const b2 = lab2[2];
		return Math.sqrt((l2 - l1) * (l2 - l1) + (a2 - a1) * (a2 - a1) + (b2 - b1) * (b2 - b1));
	}

	function rgbToXyz(rgb) {
		const r = parseInt(rgb.slice(1, 3), 16);
		const g = parseInt(rgb.slice(3, 5), 16);
		const b = parseInt(rgb.slice(5), 16);

		let varR = (r / 255); // R from 0 to 255
		let varG = (g / 255); // G from 0 to 255
		let varB = (b / 255); // B from 0 to 255

		if(varR > 0.04045) {
			varR = Math.pow(((varR + 0.055) / 1.055), 2.4);
		} else {
			varR /= 12.92;
		}
		if(varG > 0.04045) {
			varG = Math.pow(((varG + 0.055) / 1.055), 2.4);
		} else {
			varG /= 12.92;
		}
		if(varB > 0.04045) {
			varB = Math.pow(((varB + 0.055) / 1.055), 2.4);
		} else {
			varB /= 12.92;
		}

		varR *= 100;
		varG *= 100;
		varB *= 100;

		// Observer = 2°, Illuminant = D65
		const x = varR * 0.4124 + varG * 0.3576 + varB * 0.1805;
		const y = varR * 0.2126 + varG * 0.7152 + varB * 0.0722;
		const z = varR * 0.0193 + varG * 0.1192 + varB * 0.9505;
		const result = [];
		result.push(x);
		result.push(y);
		result.push(z);
		return result;
	}

	function xyzToLab(xyz) {
		// Observer = 2°, Illuminant = D65
		const refX = 95.047;
		const refY = 100.000;
		const refZ = 108.883;

		let varX = xyz[0] / refX;
		let varY = xyz[1] / refY;
		let varZ = xyz[2] / refZ;

		if(varX > 0.008856) {
			varX = Math.pow(varX, (1 / 3));
		} else {
			varX = (7.787 * varX) + (16 / 116);
		}
		if(varY > 0.008856) {
			varY = Math.pow(varY, (1 / 3));
		} else {
			varY = (7.787 * varY) + (16 / 116);
		}
		if(varZ > 0.008856) {
			varZ = Math.pow(varZ, (1 / 3));
		} else {
			varZ = (7.787 * varZ) + (16 / 116);
		}

		const l = (116 * varY) - 16;
		const a = 500 * (varX - varY);
		const b = 200 * (varY - varZ);
		const result = [];
		result.push(l);
		result.push(a);
		result.push(b);
		return result;
	}

	function getNextColor(usedColors) {
		const labColors = [];
		const rgbColors = [];
		for(let i = 0; i < usedColors.length; ++i) {
			rgbColors.push(usedColors[i]);
			labColors.push(xyzToLab(rgbToXyz(usedColors[i])));
		}
		let mostDistantColorIndex;
		let maxDistance = 0;
		for(let l = 0; l < labPalette.length; ++l) {
			let minLocalDistance = undefined;
			for(let j = 0; j < labColors.length; ++j) {
				const distance = calculateLabDistance(labPalette[l], labColors[j]);
				if(typeof(minLocalDistance) === "undefined" || distance < minLocalDistance) {
					minLocalDistance = distance;
				}
			}
			if(typeof(minLocalDistance) !== "undefined" && minLocalDistance > maxDistance) {
				mostDistantColorIndex = l;
				maxDistance = minLocalDistance;
			}
		}
		if(typeof(mostDistantColorIndex) !== "undefined") {
			return rgbPalette[mostDistantColorIndex];
		}
		return "#FF0000";
	}

	//This function is used when colors need to be generated quickly
	function getFastColor() {
		if(rgbPaletteSmall.length <= index) {
			index = 0;
		}
		return rgbPaletteSmall[index++];
	}
}
