angular.module("eShareApp").factory("pipingStandardRepository", pipingStandardRepository);

pipingStandardRepository.$inject = ["repositoryBase", "$http"];

function pipingStandardRepository(repositoryBase, $http) {
	const pipingStandardApi = "/api/project/{projectId}/PipingStandard";
	const pipingSchedulesApi = "/Content/PipingStandards/{scheduleTable}.json";

	return {
		getPipingStandard: getPipingStandard,
	};

	function getPipingStandard(projectId) {
		return repositoryBase.get(pipingStandardApi.replace("{projectId}", projectId))
			.then(
				standard => {
					switch(standard.type) {
					case "Scheduled":
						return $http.get(
							pipingSchedulesApi.replace("{scheduleTable}",
								standard.standardDefinition.scheduleTable)
						).then(response => {
							standard.standardDefinition.scheduleTable = response.data;
							return standard;
						});
					case "UseAttributes":
						/* falls through */
					case "Undefined":
						/* falls through */
					default:
						return standard;
					}
				}
			);
	}
}