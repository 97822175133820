angular.module("eShareApp").controller("eGoSynchronizedDevicesCtrl", [
	"$scope", "projectActivityRepository", "eGoSettingsRepository", "$http", "notification",
	"Upload", "$timeout",

	function (
		$scope, projectActivityRepository, eGoSettingsRepository, $http, notification,
		upload, $timeout
	) {

		//Init
		const validLicenseRegex = /(?:[a-fA-F0-9]{1,8}-){10}[a-fA-F0-9]{1,8}/;

		$scope.allDevices = [];
		$scope.filteredDevices = [];
		$scope.newLicense = {
			text: "",
		};

		$scope.filters = {
			nameFilterText: "",
			hostIdFilterText: "",
			clientVersionFilterText: "",
			projectFilterText: "",
			userFilterText: "",
			showOnlyMismatchingVersions: false,
			showExpiredLicensesFirst: true,
		};

		$scope.productVersion = window.eShare.version;

		initialize();

		// Functions

		function initialize() {
			eGoSettingsRepository.getDeviceInformation().then(data => {
				$scope.allDevices = data;
				$scope.filter();
				$timeout(() => {
					$(".dropdown").on("hide.bs.dropdown", () => {
						$scope.newLicense.text = "";
					});
				});
			});

			projectActivityRepository.getProjectActivity().then(data => {
				$scope.projectDisabled = data;
			});
		}

		$scope.refresh = function () {
			initialize();
		};

		$scope.exportDevices = function () {

			// Cannot get the communication to work with using repositoryBase, so doing this here
			// by hand... Similar solution in searchService.js
			// TODO: Should make a service for requesting files from the server and initiating
			// their download
			const uri = "api/eGoDeviceInformation/export";
			return $http.post(uri, {}, { responseType: "blob" }).then(response => {
				const blob = response.data;
				const fileName = getFileNameFromResponseHeader(response) || "Exported Devices.xlsx";
				window.saveAs(blob, fileName);
			}, reason => {
				notification.error(
					"Exporting devices to excel failed: " + Utilities.getErrorMessage(reason)
				);
			});
		};

		$scope.localizeDate = function localizeDate(utcDateTimeString) {
			const date = moment.utc(utcDateTimeString);
			return date.isValid() ? date.local().format("LLL") : utcDateTimeString;
		};

		$scope.doesVersionMatch = function (version) {
			return $scope.productVersion === version;
		};

		$scope.filter = function () {
			const result = [];
			$scope.allDevices.forEach(device => {
				if($scope.filters.showOnlyMismatchingVersions === true
					&& $scope.doesVersionMatch(device.clientVersion)) {
					return;
				}
				if($scope.filters.nameFilterText !== ""
					&& device.deviceName.toLowerCase().indexOf(
						$scope.filters.nameFilterText.toLowerCase()
					) === -1) {
					return;
				}
				if($scope.filters.hostIdFilterText !== ""
					&& device.hostId.toLowerCase().indexOf(
						$scope.filters.hostIdFilterText.toLowerCase()
					) === -1) {
					return;
				}
				if($scope.filters.clientVersionFilterText !== ""
					&& device.clientVersion.toLowerCase().indexOf(
						$scope.filters.clientVersionFilterText.toLowerCase()
					) === -1) {
					return;
				}
				if($scope.filters.projectFilterText !== "") {
					const found = device.deviceProjectUserInformation.some(information => {
						return information.projectName.toLowerCase().indexOf(
							$scope.filters.projectFilterText.toLowerCase()
						) !== -1;
					});
					if(!found) {
						return;
					}
				}
				if($scope.filters.userFilterText !== "") {
					const found = device.deviceProjectUserInformation.some(information => {
						return information.userName.toLowerCase().indexOf(
							$scope.filters.userFilterText.toLowerCase()
						) !== -1;
					});
					if(!found) {
						return;
					}
				}
				result.push(device);
			});
			$scope.filteredDevices = sortDevices(result);
		};

		$scope.changeOrder = function () {
			$scope.filters.showExpiredLicensesFirst = !$scope.filters.showExpiredLicensesFirst;
			$scope.filteredDevices = sortDevices($scope.filteredDevices);
		};

		$scope.hasLicenseExpired = function (licenseDate) {
			return moment().isAfter(moment(licenseDate));
		};

		$scope.isValidLicense = function () {
			const isMatch = validLicenseRegex.exec($scope.newLicense.text);
			return isMatch != null && isMatch[0] === isMatch.input;
		};

		$scope.saveNewLicense = function (targetDevice) {
			if(!$scope.newLicense.text || $scope.newLicense.text === "") {
				return;
			}
			const targetDeviceClone = _.cloneDeep(targetDevice);
			targetDeviceClone.license = $scope.newLicense.text;
			eGoSettingsRepository.saveLicense(targetDeviceClone).then(() => {
				$scope.newLicense.text = "";
				initialize();
			}, errorMessage => {
				notification.error(errorMessage);
			});
		};

		$scope.excelFileSelected = function (files/*, event*/) {
			if(!files || !files[0]) {
				return;
			}
			const file = files[0];
			if(!file.name.endsWith(".xlsx")) {
				notification.error("The file type must be xlsx format");
				return;
			}
			if(!window.eShare.integratedSecurity) {
				$http.post("api/OneTimeToken/Create")
					.then(
						tokenData => {
							uploadExcel(file, tokenData.data.token);
						},
						() => {
							notification.error("Error getting one-time authorization token");
						}
					);
			} else {
				uploadExcel(file);
			}
		};

		function sortDevices(devicesToSort) {
			const result = _.sortBy(devicesToSort, d => {
				let value = moment(d.licenseExpirationTime).valueOf();
				if(!d.licenseExpirationTime) {
					value = d.license ? moment("2200-01-01").valueOf() : moment("1900-01-01").valueOf();
				}
				return $scope.filters.showExpiredLicensesFirst ? value : value * -1;
			});
			return result;
		}

		function uploadExcel(file, oneTimeToken) {
			$scope.isUploadInProgress = true;
			let url = oneTimeToken
				? "api/eGoDeviceInformation/import?token={oneTimeToken}"
				: "api/eGoDeviceInformation/import";
			url = url.supplant({
				oneTimeToken: oneTimeToken,
			});
			const uploadSession = upload.upload({
				url: url,
				data: {
					file: file,
				},
			});
			uploadSession.then(onSuccess, onError, onProgress);

			function onSuccess(response) {
				if(response && response.data) {
					notification.success("Excel configuration imported");
					if(response.data.warningMessages && response.data.warningMessages.length > 0) {
						_.forEach(response.data.warningMessages, message => {
							notification.warning(message);
						});
					}
					$scope.allDevices = response.data.eGoFleetDeviceInformation || [];
					$scope.filter();
				}
			}

			function onError(reason) {
				notification.error(
					"Excel configuration import failed: " + Utilities.getErrorMessage(reason)
				);
			}

			function onProgress(/*event*/) {
				//Do nothing
			}
		}

		function getFileNameFromResponseHeader(response) {
			const contentDispositionParts = (response.headers("Content-Disposition") || "").split(";");
			for(let i = 0; i < contentDispositionParts.length; ++i) {
				const contentDispositionPart = contentDispositionParts[i].trim();
				if(contentDispositionPart.toLowerCase().startsWith("filename=")) {
					let filename = contentDispositionPart.substr(9).trim();
					if(filename.length >= 2 && filename[0] === "\"") {
						filename = filename.substr(1, filename.length - 2);
					}
					return filename;
				}
			}
			return null;
		}
	}
]);
