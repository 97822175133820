angular.module("eShareApp").component("cadListOfMarkups", {
	template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadListOfMarkups.html'),
	controller: CadListOfMarkupsController,
	bindings: {
		markups: "<",
		onEdit: "&",
		onDelete: "&",
	},
});

CadListOfMarkupsController.$inject = ["eBrowser", "notification"];

function CadListOfMarkupsController(eBrowser, notification) {
	const ctrl = this;
	ctrl.$onInit = $onInit;
	ctrl.edit = edit;
	ctrl.remove = remove;

	function $onInit() {
	}

	function edit(markup) {
		{
			if(!eBrowser.isModelLoaded()) {
				notification.warning("Please wait until 3D model has been loaded.");
			} else {
				const onEdit = ctrl.onEdit();
				if(onEdit) {
					onEdit(markup);
				}
			}
		}
	}

	function remove(markup) {
		const onDelete = ctrl.onDelete();
		if(onDelete) {
			onDelete(markup);
		}
	}
}