angular.module("eShareApp").controller("ColorsEditCtrl", ColorsEditCtrl);

ColorsEditCtrl.$inject = [
	"$state", "$scope", "project", "colors", "colorService", "dirtyFormTrackingService",
	"$timeout", "colorsRepository", "messageBox"
];

function ColorsEditCtrl(
	$state, $scope, project, colors, colorService, dirtyFormTrackingService,
	$timeout, colorsRepository, messageBox
) {
	const vm = {};

	function Color() {
		this.name = "";
		this.color = "#ffffff";
	}

	$scope.vm = vm;
	vm.colors = colors;
	vm.colors.forEach(color => {
		color.name = color.name || "";
	});

	vm.addColor = function () {
		const colorObj = new Color();
		const usedColors = _.map(vm.colors, "color");
		const colorValue = colorService.getNextColor(usedColors);
		colorObj.color = colorValue;
		vm.colors.push(colorObj);
	};

	vm.removeColor = function (obj) {
		const index = vm.colors.indexOf(obj);
		vm.colors.splice(index, 1);
	};

	vm.validateName = function (color, id) {
		color.isNameInvalid = false;
		color.isColorInvalid = false;
		const isNameEmpty = color.name == null || color.name.trim() === "";
		const hasNameDuplicates = _.filter(vm.colors, { "name": color.name }).length !== 1;
		$scope.form["name-" + id].$setValidity("illegal", !isNameEmpty && !hasNameDuplicates);
	};
	vm.validateColor = function (color, id) {
		if(isValidColorNumber(color.color)) {
			color.color = "#" + color.color;
		}
		const colorStartsWithWrongSymbol = !color.color.startsWith("#");
		const isColorInvalid = !isValidColorNumber(color.color.substring(1));
		const hasColorDuplicates = _.filter(vm.colors, { "color": color.color }).length !== 1;
		$scope.form["color-" + id].$setValidity(
			"illegal",
			!colorStartsWithWrongSymbol && !isColorInvalid && !hasColorDuplicates
		);
	};

	function isValidColorNumber(str) {
		return str != null
			&& str.length === 6
			&& (0x1000000 + parseInt(str, 16)).toString(16).toUpperCase() === "1" + str.toUpperCase();
	}

	$scope.setFormScope = function (formScope) {
		$scope.formScope = formScope;
		dirtyFormTrackingService.trackForm($scope.formScope, "form");
	};

	vm.validateAll = function () {
		return $scope.form.$valid;
	};

	let isCommitting = false;
	vm.editCommit = function () {
		if(isCommitting) {
			return;
		}
		isCommitting = true;
		try {
			colorsRepository.update($scope.project.id, vm.colors)
				.then(
					() => {
						$scope.formScope.form && $scope.formScope.form.$setPristine();
						colorService.resetProjectColors($scope.project.id);
						$state.go("^", {}, { reload: true }).then(() => {
							isCommitting = false;
						});
					},
					message => {
						isCommitting = false;
						messageBox.openError(
							"Error",
							"Error saving configuration: " + message
						);
					}
				);
		} catch(e) {
			isCommitting = false;
		}
	};
}
