angular.module("eShareApp").factory("documentViewerRepository", documentViewerRepository);

documentViewerRepository.$inject = ["repositoryBase"];

function documentViewerRepository(repositoryBase) {
	return {
		prepareDziDocument: prepareDziDocument,
	};

	function prepareDziDocument(
		projectId, dataSourceId, documentId, colorConfigurationId, isForComparison, isForConfiguration
	) {
		const requestObject = {
			projectId: projectId,
			dataSourceId: dataSourceId,
			documentId: encodeURIComponent(documentId),
			isForMap:
				dataSourceId.toLowerCase() === "864AC5A3-A3C6-4F12-82A4-5B710DBEEBF7".toLowerCase(),
			linkColoringConfigurationId: colorConfigurationId,
			isForComparison: !!isForComparison,
			isForConfiguration: !!isForConfiguration,
		};
		return repositoryBase.post("/api/dzi/prepare/", {}, requestObject);
	}
}
