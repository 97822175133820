angular.module("eShareApp").factory("model3dState", [
	"$rootScope",
	function ($rootScope) {

		$rootScope.$on("eBrowser::ModelOutdated", onModelOutdated);

		let currentRequestId = 0;
		let currentProjectId = Utilities.emptyGuid();
		let shouldReloadModel = false;

		const revealingModule = {
			setProject: setProject,
			clear: clear,
			objectId: "",
			attributes: [],
			attributesViewType: 1,
			attributesView: [],
			isInfoPaneCollapsed: true,
			infoPaneWidth: "20%",
			generateRequestId: generateRequestId,
			isCurrentRequestId: isCurrentRequestId,
			isModelOutdated: isModelOutdated,
			positionId: null,
			pointId: "",
			locatedObjectType: null,
			autorefreshData: [],
			isLocateActive: false,
			isAutorefreshEnabled: true,
			isEditable: false,
			isDeletable: false,
			pointEditor: {
				id: null,
				referenceObjectId: null,
				x: null,
				y: null,
				z: null,
				name: "",
				kindId: null,
				externalId: "",
				referenceObjectAbbreviation: "",
				attributeKinds: [],
				attributeValues: {},
				hasExternalId: true,
				visible: false,
			},
			statusTrackingEditor: {
				statusKinds: [],
				statusValues: {},
				transitions: [],
			},
			geometryIds: [],
		};

		function setProject(projectId) {
			if(currentProjectId !== projectId) {
				clear();
				currentProjectId = projectId;
				shouldReloadModel = false;
			}
		}

		function clear() {
			generateRequestId();
			revealingModule.objectId = "";
			revealingModule.attributes.length = 0;
			revealingModule.attributesView.length = 0;
			revealingModule.isInfoPaneCollapsed = true;
			revealingModule.positionId = null;
			revealingModule.pointId = "";
			revealingModule.locatedObjectType = null;
			revealingModule.autorefreshData.length = 0;
			revealingModule.isLocateActive = false;
			revealingModule.isAutorefreshEnabled = true;
			revealingModule.isEditable = false;
			revealingModule.isDeletable = false;
			revealingModule.pointEditor = {
				id: null,
				referenceObjectId: null,
				x: null,
				y: null,
				z: null,
				name: "",
				kindId: null,
				externalId: "",
				referenceObjectAbbreviation: "",
				attributeKinds: [],
				attributeValues: {},
				hasExternalId: true,
				visible: false,
			};
			revealingModule.statusTrackingEditor = {
				statusKinds: [],
				statusValues: {},
				visible: false,
				transitions: [],
			};
			revealingModule.latestMarkup = null;
			revealingModule.geometryIds.length = 0;
		}

		function generateRequestId() {
			return ++currentRequestId;
		}

		function isCurrentRequestId(requestId) {
			return requestId === currentRequestId;
		}

		function isModelOutdated() {
			if(shouldReloadModel) {
				shouldReloadModel = false;
				return true;
			} else {
				return false;
			}
		}

		function onModelOutdated() {
			shouldReloadModel = true;
		}

		return revealingModule;
	}
]);