angular.module("eShareApp").controller("HomeCtrl", [
	"$scope", "$state", "lastUsedProjectId", "projectActivityRepository",

	function (
		$scope, $state, lastUsedProjectId, projectActivityRepository
	) {

		$scope.state = $state;

		projectActivityRepository.getProjectActivity().then(disabledProjectsDictionary => {
			if(lastUsedProjectId
				&& !Utilities.isEmptyGuid(lastUsedProjectId)
				&& disabledProjectsDictionary
				&& !disabledProjectsDictionary[lastUsedProjectId]) {
				$state.go("project", { projectId: lastUsedProjectId }, { location: "replace" });
			} else {
				$state.go("selectProject", {}, { location: "replace" });
			}
		});
	}
]);
