angular.module("eShareApp").controller("ProjectByNameCtrl", ProjectByNameCtrl);

ProjectByNameCtrl.$inject = ["$state", "project"];

function ProjectByNameCtrl($state, project) {
	if(!project || !project.projectId) {
		$state.go("error");
		return;
	}
	if($state.current.name === "projectByName") {
		$state.go("project", { projectId: project.projectId });
	}
}
