angular.module("eShareApp").controller("DataSourceViewCtrl", DataSourceViewCtrl);

DataSourceViewCtrl.$inject = [
	"$state", "$scope", "$timeout", "$stateParams", "$compile", "project", "adapter", "$rootScope",
	"$injector", "adaptersRepository", "adapterConfigurationView", "adapterConfigurationScript",
	"dataSourceConfigurationView", "dataSourceConfigurationScript", "categorizationService",
	"groups", "permissions"
];

function DataSourceViewCtrl(
	$state, $scope, $timeout, $stateParams, $compile, project, adapter, $rootScope,
	$injector, adaptersRepository, adapterConfigurationView, adapterConfigurationScript,
	dataSourceConfigurationView, dataSourceConfigurationScript, categorizationService,
	groups, permissions
) {

	$scope.project = project;
	$scope.adapter = adapter;
	$scope.adapterConfig = $scope.adapter.configurationParameters;
	const ds = _.filter($scope.adapter.dataSourceConfigurations, { id: $stateParams.dataSourceId });
	if(ds.length !== 1) {
		throw new EShareException("Data Source " + $stateParams.dataSourceId + " not found");
	}
	$scope.dataSource = ds[0];
	$scope.dataSourceConfig = $scope.dataSource.configurationParameters;
	$scope.isAdapterInEditMode = false;
	$scope.isDataSourceInEditMode = false;
	$scope.performAdapterAction = performAdapterAction;
	$scope.performDataSourceAction = performDataSourceAction;
	$scope.enableCategorization = enableCategorization;
	$scope.categorization = null;
	$scope.dataSource.groups = _.filter(groups, group => {
		return _.some(permissions, permission => {
			return permission.userGroupId === group.id;
		});
	});

	$rootScope.$broadcast("DataSourceInfoExtracted", $scope.dataSource);

	$timeout(() => {
		$scope.dataSourceScript = eval(dataSourceConfigurationScript)($scope, $injector);
		const dataSourceConfigurationArea = $("#dataSourceConfigurationArea");
		dataSourceConfigurationArea.html(dataSourceConfigurationView);
		$compile(dataSourceConfigurationArea.contents())($scope);
	}, 0);

	function enableCategorization() {
		categorizationService.setup($scope.dataSourceConfig.categories, onChanged, refreshCategories);
		$scope.categorization = categorizationService;

		function onChanged() {
			// do nothing
		}

		function refreshCategories() {
			// do nothing
		}
	}

	function performAdapterAction(action) {
		return adaptersRepository.performAdapterAction($scope.project.id, $scope.adapter, action);
	}

	function performDataSourceAction(action) {
		return adaptersRepository.performDataSourceAction(
			$scope.project.id,
			$scope.adapter,
			$scope.dataSource,
			action
		);
	}
}
