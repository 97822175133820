angular.module("eShareApp").factory("modelsRepository", [
	"repositoryBase", function (repositoryBase) {

		const baseApi = "/api";
		const projectsApi = baseApi + "/project";
		const projectIdApi = projectsApi + "/{projectId}";
		const modelMetaDataApi = projectIdApi + "/ModelInfo/GetModelMetaData";
		const modelRevisionMetaDataApi = projectIdApi
			+ "/ModelInfo/GetModelMetaData/{modelRevisionId}";
		const publishApi = baseApi + "/Models/{projectId}/Publish/{modelId}";
		const republishApi = baseApi + "/project/{projectId}/Models/{modelId}/Republish";
		const multipublishApi = baseApi + "/project/{projectId}/Models/Multipublish";
		const cancelPublishApi = "/api/project/{projectId}/Models/{modelId}";
		const canDownloadApi = "api/project/{projectId}/WhoCanDownloadModel";

		function getModelInfo(projectId) { // Gets information for all models in a project
			return repositoryBase.get(modelMetaDataApi, { projectId: projectId });
		}

		function getModelRevisionInfo(projectId, modelRevisionId) {
			return repositoryBase.get(
				modelRevisionMetaDataApi,
				{ projectId: projectId, modelRevisionId: modelRevisionId }
			);
		}

		function publish(projectId, modelId) {
			return repositoryBase.post(publishApi, { projectId: projectId, modelId: modelId });
		}

		function republish(projectId, modelId) {
			return repositoryBase.post(republishApi, { projectId: projectId, modelId: modelId });
		}

		function multipublish(projectId) {
			return repositoryBase.post(multipublishApi, { projectId: projectId });
		}

		function cancelPublish(projectId, modelId) {
			return repositoryBase.remove(cancelPublishApi, { projectId: projectId, modelId: modelId });
		}

		function whoIsAllowedToDownload(projectId) {
			return repositoryBase.get(canDownloadApi, { projectId: projectId });
		}

		return {
			getModelInfo: getModelInfo,
			getModelRevisionInfo: getModelRevisionInfo,
			publish: publish,
			republish: republish,
			cancelPublish: cancelPublish,
			multipublish: multipublish,
			whoIsAllowedToDownload: whoIsAllowedToDownload,
		};
	}
]);