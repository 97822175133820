angular.module("eShare.core").config(config);

config.$inject = ["$httpProvider", "minicolorsProvider"];

function config($httpProvider, minicolorsProvider) {
	$httpProvider.interceptors.push("httpRequestInterceptor");
	angular.extend(minicolorsProvider.defaults, {
		control: "hue",
		format: "hex",
		letterCase: "uppercase",
		opacity: false,
		position: "bottom left",
	});
}
