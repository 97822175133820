angular.module("eShareApp").factory("statusTrackingKindRepository", statusTrackingKindRepository);

statusTrackingKindRepository.$inject = ["repositoryBase"];

function statusTrackingKindRepository(repositoryBase) {
	const baseApi = "/api/project/{projectId}";
	const trackingKindsApi = baseApi + "/statusTrackingKinds";
	const trackingKindApi = baseApi + "/statusTrackingKinds/{kindId}";
	const trackingGeometryApi = baseApi + "/statusTrackings/GeometryId/{geometryId}";
	const trackingKindsForAttributesApi = baseApi + "/statusTrackingKindsForAttributes";
	const trackingHistoryApi = baseApi + "/statusTrackings/History/{statusTrackingId}";
	const trackingByValueApi = baseApi + "/statusTrackings/ByAttributeValue";
	const trackingsApi = baseApi + "/StatusTrackings";

	return {
		getAll: getAll,
		get: get,
		getByGeometryId: getByGeometryId,
		getKindsForAttributes: getKindsForAttributes,
		getHistory: getHistory,
		getByAttributeValue: getByAttributeValue,
		saveEntries: saveEntries,
		update: update,
		save: save,
		remove: remove,
		exportAll: exportAll,
		importAll: importAll,
	};

	function getAll(projectId) {
		return repositoryBase.get(trackingKindsApi, { projectId: projectId }).then(
			statusTrackingKinds => {
				statusTrackingKinds = _.sortBy(statusTrackingKinds, s => {
					return (s.name || "").toLowerCase();
				});
				return statusTrackingKinds;
			}
		);
	}

	function get(projectId, kindId) {
		return repositoryBase.get(trackingKindApi, { projectId: projectId, kindId: kindId });
	}

	function getByGeometryId(projectId, geometryId) {
		return repositoryBase.get(
			trackingGeometryApi,
			{ projectId: projectId, geometryId: geometryId }
		);
	}

	function getKindsForAttributes(projectId, modelTimeStamp) {
		return repositoryBase.post(
			trackingKindsForAttributesApi,
			{ projectId: projectId },
			{ modelTimeStamp: modelTimeStamp }
		);
	}

	function getHistory(projectId, statusTrackingId) {
		return repositoryBase.get(
			trackingHistoryApi,
			{ projectId: projectId, statusTrackingId: statusTrackingId }
		);
	}

	function getByAttributeValue(projectId, abbreviation, value) {
		return repositoryBase.post(
			trackingByValueApi,
			{ projectId: projectId },
			{ abbreviation: abbreviation, value: value }
		);
	}

	function update(projectId, tracking) {
		return repositoryBase.put(trackingKindsApi, { projectId: projectId }, tracking);
	}

	function save(projectId, tracking) {
		return repositoryBase.post(trackingKindsApi, { projectId: projectId }, tracking);
	}

	function saveEntries(projectId, statusTrackingEntries) {
		return repositoryBase.post(trackingsApi, { projectId: projectId }, statusTrackingEntries);
	}

	function remove(projectId, kindId) {
		return repositoryBase.remove(trackingKindApi, { projectId: projectId, kindId: kindId });
	}

	function exportAll(projectId) {
		const url = Utilities.combineUri(trackingKindsApi, "Export");
		return repositoryBase.post(url, { projectId: projectId }).then(
			data => {
				return JSON.stringify(data, undefined, 3);
			}
		);
	}

	function importAll(projectId, serializedExportedData) {
		const url = Utilities.combineUri(trackingKindsApi, "Import");
		const exportedData = JSON.parse(serializedExportedData);
		return repositoryBase.post(url, { projectId: projectId }, exportedData);
	}
}