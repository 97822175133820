angular.module("eShareApp").controller("AttributeTransformationsCtrl", [
	"$scope", "$state", "$stateParams", "$timeout", "project", "attributeDefinitions",
	"attributeTransformations", "notification", "attributeTransformationsRepository", "modelInfo",
	"attributeDefinitionsInDatabase", "groupDefiningAbbreviations", "dirtyFormTrackingService",
	"keyDefiningAbbreviations", "isSysAdmin", "excludeFromPublishAbbreviations",

	function (
		$scope, $state, $stateParams, $timeout, project, attributeDefinitions,
		attributeTransformations, notification, attributeTransformationsRepository, modelInfo,
		attributeDefinitionsInDatabase, groupDefiningAbbreviations, dirtyFormTrackingService,
		keyDefiningAbbreviations, isSysAdmin, excludeFromPublishAbbreviations
	) {
		$scope.state = $state;
		$scope.project = project;
		$scope.modelId = $stateParams.modelId;
		$scope.attributeDefinitions = attributeDefinitions;
		$scope.attributeTransformations = _.filter(attributeTransformations, at => {
			return at.type !== "script";
		});
		$scope.showModelAttributes = true;
		$scope.showSplit = true;
		$scope.showReplace = true;
		$scope.showCombined = true;
		$scope.showExistence = true;
		$scope.currentModelType = modelInfo.active ? "Published" : "Pending";
		$scope.pendingModelExists = !modelInfo.active;
		$scope.scriptTransformation = null;
		$scope.scriptCompilationErrors = [];
		$scope.scriptCompiledSuccesfully = false;
		$scope.isScriptCompiled = false;

		$scope.isSysAdmin = isSysAdmin;

		function getAttributeType(isKey, isGroup, isTemporary, shouldExclude) {
			if(isKey) {
				return "key";
			}
			if(isGroup) {
				return "group";
			}
			if(isTemporary) {
				return "temporary";
			}
			if(shouldExclude) {
				return "exclude";
			}
			return "default";
		}

		// Introduction to the variables passed in to this controller through app.js:
		//
		// attributeDefinitions is the list of attributes in the current model
		// (the pending one, if one exists, otherwise the latest published one).
		//
		// attributeDefinitionsInDatabase is the list of attributes in the database,
		// i.e. after any possible name conflicts have been resolved.
		//
		// attributeTransformations is the list of transformations to be applied to
		// the model. It contains several different types, as determined using the
		// "type" field (see the AttributeTransformationDto class):
		//  - "attributeConfig": simple renaming of model attributes; these don't
		//    necessarily directly match the attributes in attributeDefinitions,
		//    as an attributeTransformation is only created when necessary. In the
		//    other direction, it is possible an attribute has been removed from
		//    the model, but a transformation still exists for it.
		//  - "regex": extraction of some components of an attribute. Regex
		//    transformations can define any number of new attributes, so
		//    these are defined in attributeTransformations[N].attributes
		//  - "replace": search & replace of attribute values; the new attribute
		//    is defined in attributeTransformations[N].attributes[0]
		//  - "combine": combine multiple attributes into one; the new attribute
		//    is defined in the transformation record itself.
		//  - "existence": for each attribute in a user defined list check for the
		//    existence of that attribute and if it exists assign its value to the new attribute,
		//    if it doesn't exist then check for the existence of the next attribute in the list etc.
		//  - "script": using C# to define transformation of all the attributes
		//    of an object. The new attributes are stored to $scope.scriptTransformation.attributes
		//
		// groupDefiningAbbreviations is a simple array of attribute abbreviations
		// which define groups. (It should really be refactored inside the other
		// variables.) Search and populate this array when some attribute's
		// attributeType is "group".

		//////////////////////
		// Model Attributes
		//////////////////////

		// Store the original display name of each attribute (in the current model) in
		// .modelDisplayName, and add an empty .note property to each attribute definition:
		_.forEach($scope.attributeDefinitions, currentModelAttribute => {
			currentModelAttribute.modelDisplayName = currentModelAttribute.displayName;
			currentModelAttribute.note = "";
			if(!currentModelAttribute.dataType) {
				currentModelAttribute.dataType = "string";
			}
			currentModelAttribute.group = _.includes(
				groupDefiningAbbreviations,
				currentModelAttribute.abbreviation
			);
			currentModelAttribute.key = _.includes(
				keyDefiningAbbreviations,
				currentModelAttribute.abbreviation
			);
			currentModelAttribute.shouldExclude = _.includes(
				excludeFromPublishAbbreviations,
				currentModelAttribute.abbreviation
			);
			currentModelAttribute.attributeType = getAttributeType(
				currentModelAttribute.key, currentModelAttribute.group,
				false, currentModelAttribute.shouldExclude
			);
		});

		// Next, scan the attribute definitions in the database, adding notes where the display
		// name has been changed.
		// In this case, also set the default display name to that in the database.
		_.forEach(attributeDefinitionsInDatabase, dbAttribute => {
			const correspondingAttribute = _.find($scope.attributeDefinitions, {
				abbreviation: dbAttribute.abbreviation,
			});
			if(correspondingAttribute
				&& correspondingAttribute.displayName !== dbAttribute.displayName) {
				correspondingAttribute.note = correspondingAttribute.note
					+ "Display Name is currently \"" + dbAttribute.displayName + "\". ";
				correspondingAttribute.displayName = dbAttribute.displayName;
			}
		});

		// Finally, walk through the 'attributeConfig' type transformation rules,
		// and set names for the attributes as per the rules
		_.forEach(_.filter($scope.attributeTransformations, { type: "attributeConfig" }),
			attributeTransformation => {
				let correspondingAttribute = _.find($scope.attributeDefinitions, {
					abbreviation: attributeTransformation.abbreviation,
				});
				if(correspondingAttribute) {
					if(attributeTransformation.displayName === correspondingAttribute.displayName) {
						correspondingAttribute.note = "";
					}
					if(attributeTransformation.displayName != "") {
						correspondingAttribute.displayName = attributeTransformation.displayName;
					}
					correspondingAttribute.doRename = attributeTransformation.displayName != "";
					if(attributeTransformation.dataType) {
						correspondingAttribute.dataType = attributeTransformation.dataType;
					}
				} else {
				// If a rule is found for an attribute that cannot be found in the model,
				// add a new corresponding attribute to the table:
					correspondingAttribute = {
						abbreviation: attributeTransformation.abbreviation,
						displayName: attributeTransformation.displayName,
						modelDisplayName: "",
						note: "Matching attribute not found in model",
						doRename: attributeTransformation.displayName != "",
						attributeType: getAttributeType(false, false, false, false),
						dataType: attributeTransformation.dataType,
					};
					$scope.attributeDefinitions.push(correspondingAttribute);
				}
			});

		$scope.toggleModelAttributes = function () {
			$scope.showModelAttributes = !$scope.showModelAttributes;
		};

		$scope.renameToggle = function (attribute) {
			attribute.displayName = attribute.modelDisplayName;
			updateAttributeLists();
		};

		$scope.dataTypeChanged = function (attribute) {
			const transformation = _.find($scope.attributeTransformations, {
				type: "attributeConfig", abbreviation: attribute.abbreviation,
			});
			if(transformation != undefined) {
				transformation.dataType = attribute.dataType;
			} else {
				createAttributeTransformation(attribute);
			}
			updateAttributeLists();
		};

		///////////////////////////////
		// Splitting Transformations
		///////////////////////////////

		$scope.toggleSplit = function () {
			$scope.showSplit = !$scope.showSplit;
		};

		$scope.$on("attributeTransformation:delete", (event, transformation) => {
			_.remove($scope.attributeTransformations, transformation);
			$scope.formScope.form.$setDirty();
		});

		$scope.addRegexDefinition = function () {
			$scope.attributeTransformations.push({
				type: "regex",
				attributes: [],
			});
			$scope.formScope.form.$setDirty();
		};

		_.forEach(_.filter($scope.attributeTransformations, { type: "regex" }),
			attributeTransformation => {
				_.forEach(attributeTransformation.attributes, attribute => {
					let isGroup = false;
					if(_.includes(groupDefiningAbbreviations, attribute.abbreviation)) {
						isGroup = true;
					}
					attribute.attributeType = getAttributeType(
						attribute.isKey, isGroup, attribute.isTemporary, false
					);
				});
			});

		//////////////////////////////////////
		// Search & Replace Transformations
		//////////////////////////////////////

		$scope.toggleReplace = function () {
			$scope.showReplace = !$scope.showReplace;
		};

		$scope.addReplacementRule = function () {
			$scope.attributeTransformations.push({
				type: "replace",
				sourceAttribute: $scope.attributeDefinitions[0],
				abbreviation: $scope.attributeDefinitions[0].abbreviation,
				displayName: $scope.attributeDefinitions[0].displayName,
				attributes: [
					{
						abbreviation: "",
						displayName: "",
						attributeType: getAttributeType(false, false, false, false),
					}
				],
				replacementRules: [],
			});
			$scope.formScope.form.$setDirty();
		};

		_.forEach(_.filter($scope.attributeTransformations, { type: "replace" }),
			attributeTransformation => {
				let isGroup = false;
				if(_.includes(
					groupDefiningAbbreviations, attributeTransformation.attributes[0].abbreviation
				)) {
					isGroup = true;
				}
				attributeTransformation.attributes[0].attributeType =
				getAttributeType(
					attributeTransformation.attributes[0].isKey,
					isGroup,
					attributeTransformation.attributes[0].isTemporary,
					false
				);
			});

		///////////////////////////////
		// Combining Transformations
		///////////////////////////////

		_.forEach(_.filter($scope.attributeTransformations, { type: "combine" }),
			attributeTransformation => {
				_.forEach(_.filter(attributeTransformation.format, { type: "attribute" }),
					attribute => {
						const correspondingAttribute = _.find($scope.attributeDefinitions, {
							abbreviation: attribute.abbreviation,
						});
						if(correspondingAttribute) {
							attribute.displayName = correspondingAttribute.displayName;
						}
					});
				if(_.includes(groupDefiningAbbreviations, attributeTransformation.abbreviation)) {
					attributeTransformation.group = true;
				}
				attributeTransformation.attributeType = getAttributeType(
					attributeTransformation.isKey,
					attributeTransformation.group,
					attributeTransformation.isTemporary,
					false
				);
			});

		$scope.allAttributes = [];
		let allAbbreviations = {};
		$scope.sourceAttributes = [];
		$scope.sourceDefinitions = [];

		function addToAllAttributes(attribute) {
			attribute.abbreviationDuplicated = false;
			if(allAbbreviations.hasOwnProperty(attribute.abbreviation)) {
				attribute.abbreviationDuplicated = true;
				_.forEach(_.filter($scope.allAttributes, { abbreviation: attribute.abbreviation }),
					attr => {
						attr.abbreviationDuplicated = true;
					});
			} else {
				allAbbreviations[attribute.abbreviation] = true;
			}
			$scope.allAttributes.push(attribute);
		}

		function updateAttributeLists() {
			// When attribute transformation rules change, update the list of all attributes (from the
			// model and from rules)
			$scope.allAttributes.length = 0;
			allAbbreviations = {};
			_.forEach($scope.attributeDefinitions, attribute => { // Attributes from the model
				addToAllAttributes(attribute);
			});
			_.forEach($scope.attributeTransformations,
				attributeTransformation => { // Transformation rules
					switch(attributeTransformation.type) {
					case "attributeConfig": {
						const correspondingAttribute = _.find($scope.attributeDefinitions, {
							abbreviation: attributeTransformation.abbreviation,
						});
						if(!correspondingAttribute) {
							addToAllAttributes(attributeTransformation);
						}
						break;
					}
					case "regex":
						_.forEach(attributeTransformation.attributes, regexAttribute => {
							addToAllAttributes(regexAttribute);
						});

						break;
					case "replace":
						addToAllAttributes(attributeTransformation.attributes[0]);
						break;
					case "combine":
						addToAllAttributes(attributeTransformation);
						// Update the display names used in 'combine' rules when they are generated
						// from 'regex' rules:
						_.forEach(_.filter(attributeTransformation.format, { type: "attribute" }),
							format => {
								_.forEach(_.filter($scope.attributeTransformations, { type: "regex" }),
									regexTransform => {
										const regexAttribute = _.find(regexTransform.attributes, {
											abbreviation: format.abbreviation,
										});
										if(regexAttribute) {
											format.displayName = regexAttribute.displayName;
										}
									});
							});
						break;
					case "existence":
						addToAllAttributes(attributeTransformation);
						break;
					}
				});
			$scope.sourceAttributes.length = 0;

			_.forEach($scope.allAttributes, attribute => {
				if(!(_.some($scope.attributeTransformations, {

					type: "attributeConfig",
					abbreviation: attribute.abbreviation,
					dataType: "unixDate",
				}) || _.some($scope.attributeTransformations, {
					type: "attributeConfig",
					abbreviation: attribute.abbreviation,
					dataType: "numeric",
				}))) {
					$scope.sourceAttributes.push(attribute);
				}
			});
			$scope.sourceDefinitions.length = 0;
			_.forEach($scope.attributeDefinitions, definition => {
				if(!(_.some($scope.attributeTransformations, {
					type: "attributeConfig",
					abbreviation: definition.abbreviation,
					dataType: "unixDate",
				}) || _.some($scope.attributeTransformations, {
					type: "attributeConfig",
					abbreviation: definition.abbreviation,
					dataType: "numeric",
				}))) {
					$scope.sourceDefinitions.push(definition);
				}
			});
		}

		$scope.$watch("attributeTransformations", updateAttributeLists, true);

		$scope.$watch("attributeDefinitions", newAttributeDefinitions => {
			// When attribute definitions change (e.g. when they are renamed in the UI), update the
			// corresponding 'combine' rules'
			// display names in their .format entries.
			_.forEach(newAttributeDefinitions, attribute => {
				_.forEach(_.filter($scope.attributeTransformations, { type: "combine" }),
					transformation => {
						_.forEach(_.filter(transformation.format, { type: "attribute" }), format => {
							if(format.abbreviation === attribute.abbreviation) {
								format.displayName = attribute.displayName;
							}
						});
					});
			});
			updateAttributeLists();
		}, true);

		_.forEach(_.filter($scope.attributeTransformations, { type: "replace" }),
			attributeTransformation => {
				attributeTransformation.sourceAttribute = _.head(_.filter($scope.allAttributes, {
					abbreviation: attributeTransformation.abbreviation,
				}));
			});

		$scope.toggleCombined = function () {
			$scope.showCombined = !$scope.showCombined;
		};

		$scope.addCombiningDefinition = function () {
			$scope.attributeTransformations.push({
				type: "combine",
				abbreviation: "",
				displayName: "",
				format: [],
				group: false,
				attributeType: getAttributeType(false, false, false, false),
			});
			$scope.formScope.form.$setDirty();
		};

		function createAttributeTransformation(attribute) {
			$scope.attributeTransformations.push({
				type: "attributeConfig",
				abbreviation: attribute.abbreviation,
				displayName: attribute.doRename ? attribute.displayName : "",
				dataType: attribute.dataType,
				attributeType: getAttributeType(false, false, false, false),
			});
		}

		// Change tracking (warn before navigating to a different page if the user has
		// changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		//////////////////////////////////////
		// Existence Transformations
		//////////////////////////////////////

		_.forEach(_.filter($scope.attributeTransformations, { type: "existence" }),
			attributeTransformation => {
				const isGroup = _.includes(
					groupDefiningAbbreviations, attributeTransformation.abbreviation
				);
				attributeTransformation.attributeType = getAttributeType(
					attributeTransformation.isKey, isGroup, attributeTransformation.isTemporary, false
				);
			});

		$scope.toggleExistence = function () {
			$scope.showExistence = !$scope.showExistence;
		};

		$scope.addExistenceDefinition = function () {
			$scope.attributeTransformations.push({
				type: "existence",
				abbreviation: "",
				displayName: "",
				attributeType: getAttributeType(false, false, false, false),
				attributes: [{}],
			});
			$scope.formScope.form.$setDirty();
		};

		//////////////////////////////////////
		// Script Transformation
		//////////////////////////////////////

		const script = _.find(attributeTransformations, { type: "script" });
		if(script) {
			$scope.scriptTransformation = script;
			_.forEach($scope.scriptTransformation.attributes, attribute => {
				let isGroup = false;
				if(_.includes(groupDefiningAbbreviations, attribute.abbreviation)) {
					isGroup = true;
				}
				attribute.attributeType = getAttributeType(
					attribute.isKey, isGroup, attribute.isTemporary, false
				);
			});

		}

		$scope.toggleScript = function () {
			$scope.showScript = !$scope.showScript;
		};

		$scope.addScript = function () {
			$scope.scriptTransformation = {
				type: "script",
				attributes: [],
				pattern: "",
			};
			$scope.formScope.form.$setDirty();
		};

		$scope.deleteScriptTransformation = function () {
			$scope.scriptTransformation = null;
			$scope.formScope.form.$setDirty();
		};

		$scope.tryScriptTransformation = function () {
			$scope.scriptTransformation = null;
			$scope.formScope.form.$setDirty();
		};

		$scope.addScriptAttributeDefinition = function () {
			const definition = {
				abbreviation: "",
				displayName: "",
				attributeType: getAttributeType(false, false, false, false),
			};
			$scope.scriptTransformation.attributes.push(definition);
			$scope.allAttributes.push(definition);
		};

		$scope.deleteScriptAttributeDefinition = function (attribute) {
			const index = $scope.scriptTransformation.attributes.indexOf(attribute);
			if(index > -1) {
				$scope.scriptTransformation.attributes.splice(index, 1);
			}
		};

		function sortScriptTransformationTestData() {
			showScriptTransformationCompilationData($scope.scriptTestResults);
			if(!$scope.scriptCompiledSuccesfully) {
				return;
			}
			$scope.scriptCompilationErrors = $scope.scriptTestResults.errors;
			$scope.scriptCompiledSuccesfully = true;
			const displayNames = {};
			for(let i = 0; i < $scope.attributeDefinitions.length; i++) {
				const def = $scope.attributeDefinitions[i];
				displayNames[def.abbreviation] = def.displayName;
			}
			const keys = $scope.scriptTestResults.sourceDefinitionList.keys;
			const sourceDefinitionList = $scope.scriptTestResults.sourceDefinitionList;
			for(let i = 0; i < keys.length; i++) {
				displayNames[sourceDefinitionList.keys[i]] = sourceDefinitionList.values[i];
			}
			let isFirst = true;
			for(let i = 0; i < $scope.scriptTestResults.matchingResults.length; i++) {
				const match = $scope.scriptTestResults.matchingResults[i];
				match.isOpen = isFirst;
				isFirst = false;
				const inputKeys = _.map(match.inputValues, "abbreviation");
				const outputKeys = _.map(match.outputValues, "abbreviation");
				const abbreviations = _.uniq(_.union(inputKeys, outputKeys));
				match.results = [];
				for(let j = 0; j < abbreviations.length; j++) {
					const tag = abbreviations[j];
					let inputValue = undefined;
					const input = _.find(match.inputValues, { "abbreviation": tag });
					if(input) {
						inputValue = input.value;
					}
					let outputValue = undefined;
					const output = _.find(match.outputValues, { "abbreviation": tag });
					if(output) {
						outputValue = output.value;
					}
					const displayName = displayNames[tag];
					match.results.push({
						abbreviation: tag,
						displayName: displayName,
						inputValue: inputValue,
						outputValue: outputValue,
						isInputKey: match.inputKey === tag,
						isOutputKey: match.outputKey === tag,
						isConflicting: inputValue && outputValue && inputValue !== outputValue,
					});
				}
				const inputResults = _.orderBy(
					_.filter(match.results, { "outputValue": undefined }),
					["displayName"]
				);
				const outputResults = _.orderBy(
					_.filter(match.results, { "inputValue": undefined }),
					["displayName"]
				);
				const mixedResults = _.orderBy(
					_.difference(_.difference(match.results, inputResults), outputResults),
					["displayName"]
				);
				match.results = inputResults.concat(mixedResults).concat(outputResults);
			}

			for(let i = 0; i < $scope.scriptTestResults.nonMatchingResults.length; i++) {
				const match = $scope.scriptTestResults.nonMatchingResults[i];
				match.results = [];
				for(let j = 0; j < match.inputValues.length; j++) {
					const tag = match.inputValues[j].abbreviation;
					let inputValue = undefined;
					const input = _.find(match.inputValues, { "abbreviation": tag });
					if(input) {
						inputValue = input.value;
					}
					const displayName = displayNames[tag];
					match.results.push({
						abbreviation: tag,
						displayName: displayName,
						inputValue: inputValue,
						isInputKey: match.inputKey === tag,
					});
				}
				match.results = _.orderBy(match.results, ["displayName"]);
			}
		}

		$scope.tryScriptTransformation = function () {
			$scope.scriptTestButtonDisabled = true;
			attributeTransformationsRepository
				.test(
					$scope.project.id,
					$scope.modelId,
					$scope.attributeTransformations,
					0,
					$scope.scriptTransformation
				)
				.then(data => {
					$scope.scriptCompilationErrors = [];
					$scope.scriptTestResults = data;
					$scope.scriptTestButtonDisabled = false;
					sortScriptTransformationTestData(data);
					if($scope.scriptCompiledSuccesfully) {
						$scope.scriptTestResultsExpanded = true;
					}
				}, error => {
					notification.error("Error testing script: " + error);
					$scope.scriptTestButtonDisabled = false;
				});
		};

		function showScriptTransformationCompilationData(data) {
			$scope.isScriptCompiled = true;
			$scope.scriptCompiledSuccesfully = data.isCompiled;
			$scope.scriptCompilationErrors = data.errors;
			$scope.scriptTestResultsExpanded = false;
			$scope.scriptTestButtonDisabled = false;
		}

		$scope.compileScriptTransformation = function () {
			$scope.isScriptCompiled = false;
			$scope.scriptTestButtonDisabled = true;
			attributeTransformationsRepository
				.compile($scope.project.id, $scope.modelId, $scope.scriptTransformation)
				.then(data => {
					showScriptTransformationCompilationData(data);
				}, error => {
					showScriptTransformationCompilationData({
						errors: ["Error accessing server: " + Utilities.getErrorMessage(error)],
						isCompiled: false,
					});
				});
		};

		$scope.toggleScriptTestResult = function () {
			$scope.scriptTestResultsExpanded = false;
		};

		$scope.keyPressedScriptEdit = function (evt) {
			if(evt.keyCode === 9) { // tab
				evt.preventDefault();
				// TODO: Add a tab white space character to the location of the cursor
			}
		};

		// Save button:
		$scope.saveTransformations = function () {
			const groupDefiningAbbreviations = [];
			const keyDefiningAbbreviations = [];
			const excludeFromPublishAbbreviations = [];
			_.forEach($scope.attributeDefinitions, attribute => {
				const correspondingTransformation = _.find($scope.attributeTransformations, {
					type: "attributeConfig",
					abbreviation: attribute.abbreviation,
				});
				if(correspondingTransformation) {
					correspondingTransformation.displayName = "";
					let remove = true;
					if(attribute.doRename) {
						correspondingTransformation.displayName = attribute.displayName;
						remove = false;
					}
					if(attribute.dataType && attribute.dataType !== "string") {
						correspondingTransformation.dataType = attribute.dataType;
						remove = false;
					}
					if(remove) {
						_.remove($scope.attributeTransformations, correspondingTransformation);
					}
				} else if(attribute.doRename
						|| (attribute.dataType && attribute.dataType != "string")) {
					createAttributeTransformation(attribute);
				}
				if(attribute.attributeType === "group") {
					groupDefiningAbbreviations.push(attribute.abbreviation);
				}
				if(attribute.attributeType === "key") {
					keyDefiningAbbreviations.push(attribute.abbreviation);
				}
				if(attribute.attributeType === "exclude") {
					excludeFromPublishAbbreviations.push(attribute.abbreviation);
				}
			});
			const transformations = _.cloneDeep($scope.attributeTransformations);
			if($scope.scriptTransformation) {
				transformations.push(_.cloneDeep($scope.scriptTransformation));
			}
			_.forEach(transformations, transformation => {
				switch(transformation.type) {
				case "regex":
					_.forEach(transformation.attributes, attribute => {
						if(attribute.attributeType === "group") {
							groupDefiningAbbreviations.push(attribute.abbreviation);
						}
					});
					break;
				case "script":
					_.forEach(transformation.attributes, attribute => {
						if(attribute.attributeType === "group") {
							groupDefiningAbbreviations.push(attribute.abbreviation);
						}
					});
					break;
				case "replace":
					_.forEach(transformation.attributes, attribute => {
						if(attribute.attributeType === "group") {
							groupDefiningAbbreviations.push(attribute.abbreviation);
						}
					});
					break;
				case "combine":
					if(transformation.attributeType === "group") {
						groupDefiningAbbreviations.push(transformation.abbreviation);
					}
					break;
				case "existence":
					if(transformation.attributeType === "group") {
						groupDefiningAbbreviations.push(transformation.abbreviation);
					}
					break;
				}
				transformation.isKey = false;
				transformation.isTemporary = false;
				switch(transformation.attributeType) {
				case "key":
					transformation.isKey = true;
					break;
				case "temporary":
					transformation.isTemporary = true;
					break;
				}
				delete transformation.attributeType;
				_.forEach(transformation.attributes, attribute => {
					attribute.isKey = false;
					attribute.isTemporary = false;
					switch(attribute.attributeType) {
					case "key":
						attribute.isKey = true;
						break;
					case "temporary":
						attribute.isTemporary = true;
						break;
					}
					delete attribute.attributeType;
				});
			});
			attributeTransformationsRepository
				.save(
					project.id,
					$scope.modelId,
					transformations,
					groupDefiningAbbreviations,
					keyDefiningAbbreviations,
					excludeFromPublishAbbreviations
				)
				.then(() => {
					notification.success("Transformations saved successfully");
					$timeout(() => {
						$scope.formScope.form && $scope.formScope.form.$setPristine();
						$state.go("^", {}, { reload: true });
					}, 0);
				}, errorMessage => {
					notification.error("Error saving attribute transformations: " + errorMessage);
				});
		};

		$scope.aceLoaded = function (editor) {
			//Make editor disabled
			editor.setReadOnly(true);
			editor.container.style.opacity = 0.5;

			$scope.isFocusDropped = true;
			editor.setShowPrintMargin(false);
			editor.setOptions({
				autoScrollEditorIntoView: true,
				minLines: 4,
				maxLines: 30,
			});
			setPlaceholderCode(editor);
			editor.on("focus", () => {
				$scope.isFocusDropped = false;
			});
			editor.renderer.setScrollMargin(4, 4);
			//Enable editor if the user is system admin
			if($scope.isSysAdmin) {
				editor.setReadOnly(false);
				editor.container.style.opacity = 1;
			}
		};

		$scope.aceChanged = function (e) {
			const editor = e[1];
			setPlaceholderCode(editor);
		};

		$scope.aceBlurred = function () {
			$scope.isFocusDropped = true;
		};

		function setPlaceholderCode(editor) {
			const shouldShow = !editor.session.getValue().length;
			let node = editor.renderer.emptyMessageNode;
			if(!shouldShow && node) {
				editor.renderer.scroller.removeChild(editor.renderer.emptyMessageNode);
				editor.renderer.emptyMessageNode = null;
			} else if(shouldShow && !node) {
				node = editor.renderer.emptyMessageNode = document.createElement("div");
				node.style.cssText = "padding: 4px 4px;  white-space: pre;";
				node.textContent = "Example: \n"
				+ "if(attributes[\"sys\"] != null && attributes[\"sys\"] == \"Equipment\") { \n"
				+ "    attributes[\"type\"] = \"Equipment\"; \n}";
				node.className = "ace_invisible ace_emptyMessage";
				editor.renderer.scroller.appendChild(node);
			}
		}
	}
]);
