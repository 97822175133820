angular.module("eShareApp").directive("cadSqlQueryBuilder", cadSqlQueryBuilder);

cadSqlQueryBuilder.$inject = [];

function cadSqlQueryBuilder() {
	return {
		restrict: "E",
		controller: cadSqlQueryBuilderController,
		controllerAs: "vm",
		bindToController: {
			queryBuilder: "=",
			sqlQueryString: "=",
			parameters: "=",
			providedType: "<",
			modelAttributes: "<",
			connectionString: "<",
			onAttributesChanged: "=",
			getDatabasesFunction: "=",
			getDatabaseTablesFunction: "=",
			getDatabaseTableFieldsFunction: "=",
		},
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadSqlQueryBuilder.directive.html'),
		replace: true,
	};
}

cadSqlQueryBuilderController.$inject = ["$scope", "notification", "$q"];

function cadSqlQueryBuilderController($scope, notification, $q) {
	const vm = this;

	vm.comparisonOperators = [
		{
			symbol: "=",
			displayName: "equals",
		},
		{
			symbol: "<>",
			displayName: "not equal",
		},
		{
			symbol: ">",
			displayName: "greater than",
		},
		{
			symbol: "<",
			displayName: "less than",
		},
		{
			symbol: ">=",
			displayName: "greater than or equal",
		},
		{
			symbol: "<=",
			displayName: "less than or equal",
		},
		{
			symbol: "LIKE",
			displayName: "like",
		}
	];
	vm.joinTypes = [
		{
			name: "JOIN",
			displayName: "Inner Join",
			helpText: "Returns rows that have matching values in both tables",
		},
		{
			name: "LEFT JOIN",
			displayName: "Left Join",
			helpText: "Returns all rows from the left table, "
				+ "and only matched rows from the right table",
		},
		{
			name: "RIGHT JOIN",
			displayName: "Right Join",
			helpText: "Returns all rows from the right table, "
			+ "and only matched rows from the left table",
		},
		{
			name: "FULL JOIN",
			displayName: "Full Join",
			helpText: "Returns all rows when there is a match in either table",
		}
	];

	vm.whereClauseDefinitions = [];
	vm.builderOptions = null;

	$scope.$watch("vm.builderOptions", updateSqlQueryText, true);
	$scope.$watch("[vm.providedType, vm.parameters]", updateWhereClauseDefinitions, true);

	function QueryBuilderOptions(isEnabled = true){
		this.enabled = isEnabled;
		this.databases = [];
		this.selectedDatabase = new SqlDatabase();
		this.tables = [];
		this.selectedTable = new SqlTable();
		this.fields = [];
		this.selectedFields = [];
		this.numberOfRows = null;
		this.orderByField = new SqlField();
		this.descending = false;
		this.shouldJoin = false;
		this.joins = [];
		this.filterGroup = new SqlFilterGroup();
	}

	function SqlJoin(){
		this.joinType = vm.joinTypes[0];
		this.joinCondition = new SqlJoinCondition();
		this.joinTable = new SqlTable();
		this.joinTableFields = [];
	}

	function SqlFilterGroup(){
		this.operator = "AND";
		this.conditions = [new SqlWhereCondition()];
		this.subGroups = [];
	}

	function SqlJoinCondition(first, second){
		this.first = first || new SqlField();
		this.second = second || new SqlField();
		this.operator = "=";
	}

	function SqlWhereCondition(first){
		this.first = first || new SqlField();
		this.operator = "=";
		this.format = [];
	}

	function SqlDatabase(name){
		this.name = name || "";
	}

	function SqlTable(name, schema){
		this.name = name || "";
		this.schema = schema || "";
	}

	function SqlField(name, table, schema){
		this.name = name || "";
		this.table = table || "";
		this.schema = schema || "";
	}

	initialize();
	async function initialize() {
		vm.builderOptions = _.defaultsDeep(
			vm.queryBuilder.options, new QueryBuilderOptions(!vm.sqlQueryString)
		);
		const initialDbName = vm.builderOptions.selectedDatabase.name
			|| getInitialCatalog(vm.connectionString);
		const initialTableName = vm.builderOptions.selectedTable.name;
		const initialTableSchema = vm.builderOptions.selectedTable.schema;
		const initialFieldNames = vm.builderOptions.selectedFields.map(field => field.name);

		// eslint-disable-next-line prefer-const
		let [databases, tables, fields] = await Promise.all([
			getDatabases(),
			getDatabaseTables(initialDbName),
			getDatabaseTableFields(initialDbName, initialTableName, initialTableSchema)
		]);

		// Joins
		for(let i = 0; i < vm.builderOptions.joins.length; i++){
			const join = vm.builderOptions.joins[i];
			join.joinTable = _.find(tables, { name: join.joinTable.name });
			join.joinTableFields = await getDatabaseTableFields(
				initialDbName, join.joinTable.name, join.joinTable.schema
			);
			fields = [...fields, ...join.joinTableFields];
			const firstCondition = join.joinCondition.first;
			const secondCondition = join.joinCondition.second;
			join.joinCondition = new SqlJoinCondition(
				_.find(join.joinTableFields, {
					name: firstCondition.name,
					table: firstCondition.table,
					schema: firstCondition.schema,
				}),
				_.find(fields, {
					name: secondCondition.name,
					table: secondCondition.table,
					schema: secondCondition.schema,
				})
			);
		}

		// Where conditions
		for(let i = 0; i < vm.builderOptions.filterGroup.conditions.length; i++){
			const condition = vm.builderOptions.filterGroup.conditions[i];
			condition.first = _.find(fields, {
				name: condition.first.name,
				table: condition.first.table,
				schema: condition.first.schema,
			});
		}
		for(let i = 0; i < vm.builderOptions.filterGroup.subGroups.length; i++){
			const subGroup = vm.builderOptions.filterGroup.subGroups[i];
			for(let j = 0; j < subGroup.conditions.length; j++){
				const subCondition = subGroup.conditions[j];
				subCondition.first = _.find(fields, {
					name: subCondition.first.name,
					table: subCondition.first.table,
					schema: subCondition.first.schema,
				});
			}
		}

		vm.builderOptions.databases = databases;
		vm.builderOptions.tables = tables;
		vm.builderOptions.fields = fields;
		vm.builderOptions.selectedDatabase =
			_.find(databases, { name: initialDbName }) || new SqlDatabase();
		vm.builderOptions.selectedTable =
			_.find(tables, { name: initialTableName }) || new SqlTable();
		vm.builderOptions.selectedFields =
			fields.filter(field => initialFieldNames.indexOf(field.name) > -1);
		vm.builderOptions.orderByField = _.find(fields, {
			name: vm.builderOptions.orderByField.name,
			table: vm.builderOptions.orderByField.table,
			schema: vm.builderOptions.orderByField.schema,
		});
		updateWhereClauseDefinitions();
	}

	vm.trimTablePrefixFromField = trimTablePrefixFromField;
	vm.updateWhereClauseDefinitions = updateWhereClauseDefinitions;
	vm.hasMultipleSchemas = hasMultipleSchemas;
	vm.getAllFields = getAllFields;
	vm.formatSqlDatabaseForDisplay = formatSqlDatabaseForDisplay;
	vm.formatSqlTableForDisplay = formatSqlTableForDisplay;
	vm.formatSqlFieldForDisplay = formatSqlFieldForDisplay;

	vm.except = function (fields) {
		return function (item) {
			return fields.indexOf(item) < 0;
		};
	};

	vm.operatorToDisplayName = function (operator) {
		const comparisonOperator = _.find(vm.comparisonOperators, { symbol: operator });
		return comparisonOperator?.symbol
			? comparisonOperator.displayName
			: comparisonOperator
				? comparisonOperator.displayName
				: "";
	};

	vm.addWhereCondition = function (group) {
		group.conditions.push(new SqlWhereCondition());
	};

	vm.removeCondition = function (group, condition) {
		_.remove(group.conditions, condition);
	};

	vm.addGroup = function (group) {
		group.subGroups.push(new SqlFilterGroup());
	};

	vm.removeGroup = function (group, subGroup) {
		_.remove(group.subGroups, subGroup);
	};

	vm.addJoin = function () {
		vm.builderOptions.shouldJoin = true;
		vm.builderOptions.joins.push(new SqlJoin());
		updateWhereClauseDefinitions();
	};

	vm.removeJoin = function (join) {
		vm.builderOptions.selectedFields = vm.builderOptions.selectedFields.filter(field =>{
			return !_.some(join.joinTableFields, {
				name: field.name,
				table: field.table,
				schema: field.schema,
			});
		});

		vm.builderOptions.fields = vm.builderOptions.fields.filter(field =>{
			return !_.some(join.joinTableFields, {
				name: field.name,
				table: field.table,
				schema: field.schema,
			});
		});

		_.remove(vm.builderOptions.joins, join);
		vm.builderOptions.shouldJoin = vm.builderOptions.joins.length > 0;
		updateWhereClauseDefinitions();
	};

	vm.onDatabaseChange = async function () {
		vm.builderOptions.tables = await getDatabaseTables(vm.builderOptions.selectedDatabase.name);
	};

	vm.onSelectedTableChange = async function () {
		vm.builderOptions.fields = await getDatabaseTableFields(
			vm.builderOptions.selectedDatabase.name,
			vm.builderOptions.selectedTable.name,
			vm.builderOptions.selectedTable.schema
		);
		updateWhereClauseDefinitions();
	};

	vm.onJoinTableChange = async function (join) {
		const newJoinFields = await getDatabaseTableFields(
			vm.builderOptions.selectedDatabase.name,
			join.joinTable.name,
			join.joinTable.schema
		);
		const oldJoinFields = join.joinTableFields;

		vm.builderOptions.fields = vm.builderOptions.fields.filter(field => {
			return !_.some(oldJoinFields, {
				name: field.name,
				table: field.table,
				schema: field.schema,
			});
		});

		join.joinTableFields = newJoinFields;
		vm.builderOptions.fields = vm.builderOptions.fields.concat(newJoinFields);
		updateWhereClauseDefinitions();
	};

	vm.onWhereConditionFormatChange = function (format) {
		const nonKeyAbbreviations = _.filter(format, attribute => {
			if(attribute.type !== "attribute") {
				return false;
			}
			const match = _.find(
				_.filter(vm.whereClauseDefinitions, { isModelAttribute: true, isKey: false }),
				{ abbreviation: attribute.abbreviation }
			);
			return !!match;
		}).map(attribute => {
			return attribute.abbreviation;
		});
		_.forEach(nonKeyAbbreviations, abbreviation => {
			const parameter = vm.parameters[0];
			parameter.attributes.push({ abbreviation: abbreviation });
			vm.onAttributesChanged(parameter);
		});
	};

	function formatSqlDatabaseForDisplay(database){
		const parts = [database?.name];
		return formatForDisplay(parts);
	}

	function formatSqlDatabaseForQuery(database){
		const parts = [database?.name];
		return formatForQuery(parts);
	}

	function formatSqlTableForDisplay(table){
		const parts = [
			hasMultipleSchemas(vm.builderOptions.tables) ? table?.schema : "",
			table?.name
		];
		return formatForDisplay(parts);
	}

	function formatSqlTableForQuery(table){
		const parts = [table?.schema, table?.name];
		return formatForQuery(parts);
	}

	function formatSqlFieldForDisplay(field){
		const parts = [
			hasMultipleSchemas(vm.builderOptions.tables) ? field?.schema : "",
			field?.table,
			field?.name
		];
		return formatForDisplay(parts);
	}

	function formatSqlFieldForQuery(field){
		const parts = [field?.schema, field?.table, field?.name];
		return formatForQuery(parts);
	}

	function formatForDisplay(parts){
		return parts.filter(str => !!str).join(".");
	}

	function formatForQuery(parts){
		return parts.filter(str => !!str).map(wrapWithBrackets).join(".");
	}

	function updateSqlQueryText() {
		if(!vm.builderOptions || !vm.builderOptions.enabled) {
			return;
		}
		vm.sqlQueryString = "";
		const builderOptions = vm.builderOptions;
		let sqlQueryLines = [];
		const initialCatalog = getInitialCatalog(vm.connectionString);
		const useValue =
			initialCatalog.toLowerCase() === builderOptions.selectedDatabase.name.toLowerCase()
				? ""
				: formatSqlDatabaseForQuery(builderOptions.selectedDatabase);
		const selectValues = getSelectValues(builderOptions.selectedFields);
		const fromValue = formatSqlTableForQuery(builderOptions.selectedTable);
		const joinValues = builderOptions.shouldJoin
			? builderOptions.joins.map(join => sqlJoinToString(join))
			: [];
		const whereValue = sqlFilterGroupToString(builderOptions.filterGroup);

		let orderByValue = "";
		if(builderOptions.orderByField){
			orderByValue = `${formatSqlFieldForQuery(builderOptions.orderByField)} `
				+ `${builderOptions.descending ? "DESC" : "ASC"}`;
		}

		if(useValue) {
			sqlQueryLines.push(`USE ${useValue}`);
		}
		const numOfRowsString = builderOptions.numberOfRows
			? `TOP (${builderOptions.numberOfRows}) `
			: "";
		if(selectValues.length > 0) {
			sqlQueryLines.push(`SELECT ${numOfRowsString}${selectValues.join(", ")}`);
		} else {
			sqlQueryLines.push(`SELECT ${numOfRowsString}*`);
		}
		if(fromValue) {
			sqlQueryLines.push(`FROM ${fromValue}`);
		}
		if(joinValues.length > 0) {
			sqlQueryLines = sqlQueryLines.concat(joinValues);
		}
		if(whereValue) {
			sqlQueryLines.push(`WHERE ${whereValue}`);
		}
		if(orderByValue) {
			sqlQueryLines.push(`ORDER BY ${orderByValue}`);
		}
		vm.sqlQueryString = sqlQueryLines.join("\n");
	}

	function updateWhereClauseDefinitions() {
		const numberOfAttributes = _.max(_.map(vm.parameters, parameter => {
			return parameter.attributes.length;
		}));

		const keys = {};
		_.forEach(vm.parameters, parameter => {
			const attributes = parameter.attributes;
			for(let i = 0; i < attributes.length; i++) {
				if(!keys["key" + (i + 1)]) {
					keys["key" + (i + 1)] = [];
				}
				keys["key" + (i + 1)].push({
					abbreviation: attributes[i].abbreviation,
					displayName: attributes[i].displayName,
				});
			}
		});

		const modelAttributeDefinitions = vm.providedType === "Smart Points"
			? []
			: vm.modelAttributes.map(attribute => {
				const abbreviation = attribute.abbreviation;
				let displayName = attribute.displayName;
				let isKey = false;
				for(let i = 0; i < numberOfAttributes; i++) {
					const keyArray = keys["key" + (i + 1)];
					if(keyArray) {
						const match = _.find(keyArray, { abbreviation: abbreviation });
						if(match) {
							displayName = match.displayName;
							isKey = true;
							break;
						}
					}
				}
				return { abbreviation, displayName, isKey, isModelAttribute: true };
			});
		const externalIdDefinitions = vm.providedType === "Smart Points"
			? [
				{
					abbreviation: "((poi))",
					displayName: "External Id (@Key1)",
					isKey: true,
					isModelAttribute: false,
				}
			]
			: [];
		const allFields = getAllFields(vm.builderOptions);
		const fieldDefinitions = allFields.map(field => {
			const fieldString = formatSqlFieldForDisplay(field);
			return {
				abbreviation: fieldString,
				displayName: fieldString,
				isKey: false,
				isModelAttribute: false,
			};
		});
		let definitions = modelAttributeDefinitions
			.concat(externalIdDefinitions)
			.concat(fieldDefinitions);
		if(definitions.length === 0) {
			definitions = _.map(Object.keys(keys), key => {
				return {
					abbreviation: key,
					displayName: "@" + key,
					isKey: true,
				};
			});
		}
		vm.whereClauseDefinitions = definitions;
	}

	function getAllFields(builderOptions){
		if(!builderOptions){
			return [];
		}
		const mainFields = builderOptions.selectedFields;
		const joinFields = builderOptions.shouldJoin
			? builderOptions.joins.map(join => join.joinTableFields)
			: [];
		return mainFields.concat(joinFields);
	}

	function wrapWithBrackets(str) {
		return "[" + str + "]";
	}

	function trimTablePrefixFromField(field) {
		return field.substring(field.indexOf(".") + 1);
	}

	function sqlFilterGroupToString(filterGroup){
		const conditionStrings = filterGroup.conditions
			.filter(isValidSqlCondition)
			.map(sqlWhereConditionToString);

		//TODO: make condition groups work again
		/*const groupConditionStrings = filterGroup.subGroups
			.map(subGroup => {
				return subGroup.conditions
					.filter(isValidSqlCondition)
					.map(condition => sqlWhereConditionToString(condition))
					.join(" " + subGroup.operator + " ");
			})
			.map(conditionString => "(" + conditionString + ")");*/

		/*return conditionStrings
			.concat(groupConditionStrings)
			.join(" " + filterGroup.operator + " ");*/
		return conditionStrings.join(" " + filterGroup.operator + " ");
	}

	function sqlJoinToString(join){
		return `${join.joinType.name} ${formatSqlTableForQuery(join.joinTable)} ON `
					+ `${sqlJoinConditionToString(join.joinCondition)}`;
	}

	function sqlJoinConditionToString(condition){
		return `${formatSqlFieldForQuery(condition.first)} ${condition.operator} `
			+ `${formatSqlFieldForQuery(condition.second)}`;
	}

	function sqlWhereConditionToString(condition){
		return `${formatSqlFieldForQuery(condition.first)} ${condition.operator} `
			+ `${attributeCombinationFormatToString(condition.format)}`;
	}

	function hasMultipleSchemas(tables){
		const schemas = tables.reduce((acc, currentTable) => {
			if(acc.indexOf(currentTable.schema) < 0){
				acc = [...acc, currentTable.schema];
			}
			return acc;
		}, []);
		return schemas.length > 1;
	}

	function isValidSqlCondition(condition){
		return condition.first && condition.operator && condition.format.length > 0;
	}

	function attributeCombinationFormatToString(format) {
		const result = [];
		for(let i = 0; i < format.length; i++){
			const component = format[i];
			if(component.type !== "attribute") {
				// Is free text
				result.push("'" + component.displayName + "'");
				continue;
			}
			let found = false;
			for(let j = 0; j < vm.parameters.length; j++) {
				const items = vm.parameters[j].attributes;
				for(let k = 0; k < items.length; k++) {
					const item = items[k];
					if(item.abbreviation === component.abbreviation
							|| component.abbreviation === "((poi))") {
						// Is model attribute/poi external id (key)
						result.push(`@Key${k + 1}`);
						found = true;
					}
				}
			}
			if(found){
				continue;
			}else{
				// Is another field in table
				result.push(component.displayName.split(".").map(wrapWithBrackets).join("."));
			}
		}
		return result.join(" + ");
	}

	function getSelectValues(fields) {
		return _.reduce(fields, (result, field) => {
			let newFieldName = field.name;
			let duplicateIndex = 1;
			while(result.fieldNames.indexOf(newFieldName) >= 0) {
				newFieldName = field.name + ++duplicateIndex;
			}
			let displayName = formatSqlFieldForQuery(field);
			if(duplicateIndex > 1) {
				displayName += " as " + newFieldName;
			}
			result.fieldNames.push(newFieldName);
			result.displayNames.push(displayName);
			return result;
		}, { fieldNames: [], displayNames: [] }).displayNames;
	}

	function getInitialCatalog(connectionString) {
		const initialCatalogKeys = ["database", "initial catalog"];
		const parts = connectionString.split(";");
		for(let i = 0; i < parts.length; i++) {
			const part = parts[i].trim();
			const [key, value] = part.split("=");
			if(!key || !value) {
				continue;
			}
			const found = _.some(initialCatalogKeys, initialCatalogKey => {
				return initialCatalogKey === key.toLowerCase().trim();
			});
			if(found) {
				return value.trim();
			}
		}
		return "";
	}

	function getDatabases() {
		return vm.getDatabasesFunction().then(
			databases => {
				const dbNames = angular.fromJson(databases);
				return dbNames.map(name => new SqlDatabase(name));
			},
			reason => {
				notification.error(`Could not fetch list of databases: ${reason}`);
				return [];
			}
		);
	}

	function getDatabaseTables(databaseName) {
		if(!databaseName) {
			return $q.resolve([]);
		}
		return vm.getDatabaseTablesFunction(databaseName).then(
			tables => {
				const tableObjects = angular.fromJson(tables);
				return tableObjects.map(table => new SqlTable(table.name, table.schema));
			},
			reason => {
				notification.error(`Could not fetch list of database tables: ${reason}`);
				return [];
			}
		);
	}

	function getDatabaseTableFields(databaseName, tableName, tableSchema) {
		if(!databaseName || !tableName || !tableSchema) {
			return $q.resolve([]);
		}
		return vm.getDatabaseTableFieldsFunction(databaseName, tableName, tableSchema).then(
			fields => {
				const fieldNames = angular.fromJson(fields);
				return fieldNames.map(name => new SqlField(name, tableName, tableSchema));
			},
			reason => {
				notification.error(`Could not fetch list of database table fields: ${reason}`);
				return [];
			}
		);
	}
}
