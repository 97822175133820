angular.module("eShareApp").controller("DataSourceEditCtrl", DataSourceEditCtrl);

DataSourceEditCtrl.$inject = [
	"$scope", "$state", "$compile", "$timeout", "$stateParams", "$rootScope", "$injector",
	"messageBox", "adapter", "project", "adaptersRepository", "adapterConfigurationView",
	"adapterConfigurationScript", "dataSourceConfigurationView", "dataSourceConfigurationScript",
	"dirtyFormTrackingService", "categorizationService", "groups", "permissions"
];

function DataSourceEditCtrl(
	$scope, $state, $compile, $timeout, $stateParams, $rootScope, $injector,
	messageBox, adapter, project, adaptersRepository, adapterConfigurationView,
	adapterConfigurationScript, dataSourceConfigurationView, dataSourceConfigurationScript,
	dirtyFormTrackingService, categorizationService, groups, permissions
) {

	$scope.$on("$viewContentLoaded", () => {
		$timeout(() => {
			$scope.formScope = $scope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		}, 10);
	});

	let isCommitting = false;

	$scope.messageBox = messageBox;

	$scope.project = project;
	$scope.adapter = adapter;
	$scope.adapterConfig = $scope.adapter.configurationParameters;
	const ds = _.filter($scope.adapter.dataSourceConfigurations, { id: $stateParams.dataSourceId });
	if(ds.length !== 1) {
		throw new EShareException("Data Source " + $stateParams.dataSourceId + " not found");
	}
	$scope.dataSource = ds[0];
	$scope.dataSourceConfig = $scope.dataSource.configurationParameters;

	$scope.isAdapterInEditMode = false;
	$scope.isDataSourceInEditMode = true;
	$scope.isNew = $state.current.data.isNew;
	$scope.isSaveEnabled = isSaveEnabled;
	$scope.performAdapterAction = performAdapterAction;
	$scope.performDataSourceAction = performDataSourceAction;
	$scope.editCommit = editCommit;
	$scope.editRollback = editRollback;
	$scope.enableCategorization = enableCategorization;
	$scope.categorization = null;
	$scope.dataSource.groups = groups;
	_.forEach($scope.dataSource.groups, group => {
		group.hasPermission = _.some(permissions, permission => {
			return permission.userGroupId === group.id;
		});
	});

	$scope.hasAllowedGroups = function () {
		return _.some($scope.dataSource.groups, group => {
			return group.hasPermission;
		});
	};

	$rootScope.$broadcast("DataSourceInfoExtracted", $scope.dataSource);

	$timeout(() => {
		$scope.dataSourceScript = eval(dataSourceConfigurationScript)($scope, $injector);
		const dataSourceConfigurationArea = $("#dataSourceConfigurationArea");
		dataSourceConfigurationArea.html(dataSourceConfigurationView);
		$compile(dataSourceConfigurationArea.contents())($scope);
	}, 0);

	function enableCategorization() {
		$scope.categorization = categorizationService;
		categorizationService.setup($scope.dataSourceConfig.categories, onChanged, refreshCategories);

		function onChanged() {
			$scope.formScope.form.$setDirty();
		}

		function refreshCategories() {
			beforeCommit();
			return adaptersRepository.refreshCategories(
				$scope.project.id,
				$scope.adapter,
				$scope.dataSource
			);
		}
	}

	function performAdapterAction(action) {
		return adaptersRepository.performAdapterAction($scope.project.id, $scope.adapter, action);
	}

	function performDataSourceAction(action) {
		return adaptersRepository.performDataSourceAction(
			$scope.project.id,
			$scope.adapter,
			$scope.dataSource,
			action
		);
	}

	function isSaveEnabled() {
		try {
			const isEnabled = Utilities.invokeCallback($scope.dataSourceScript.isSaveEnabled);
			return typeof isEnabled === "undefined" || Utilities.toBool(isEnabled);
		} catch(e) {
			return true;
		}
	}

	function beforeCommit() {
		Utilities.invokeCallback($scope.dataSourceScript.beforeCommit);
		if($scope.categorization) {
			$scope.dataSourceConfig.categories = $scope.categorization.serialize();
		}
		$scope.dataSource.allowedGroups = _.map(_.filter($scope.dataSource.groups, group => {
			return group.hasPermission;
		}), group => {
			return group.id;
		});
	}

	function editCommit() {
		if(isCommitting) {
			return;
		}
		isCommitting = true;
		try {
			beforeCommit();
			for(const i in $scope.adapter.dataSourceConfigurations) {
				if($scope.adapter.dataSourceConfigurations[i].id === $scope.dataSource.id) {
					$scope.adapter.dataSourceConfigurations[i] = _.cloneDeep($scope.dataSource);
					break;
				}
			}
			$scope.adapter.dataSourceConfigurations = _.sortBy(
				$scope.adapter.dataSourceConfigurations, d => {
					return d.name.toLowerCase();
				}
			);
			adaptersRepository.update($scope.project.id, $scope.adapter)
				.then(
					() => {
						$scope.formScope.form && $scope.formScope.form.$setPristine();
						$state.go("^.view", {}, { reload: true }).then(() => {
							isCommitting = false;
						});
					},
					message => {
						isCommitting = false;
						if(message.indexOf(
							"Violation of UNIQUE KEY constraint 'UC_DataSourceConfiguration_Name'"
						) > -1) {
							messageBox.openError(
								"Error",
								"Data sources must have unique names."
							);
						} else {
							messageBox.openError(
								"Error",
								"Error saving data source configuration: " + message
							);
						}
					}
				);
		} catch(e) {
			isCommitting = false;
		}
	}

	function editRollback() {
		if($state.current.data.isNew) {
			$state.go("project.admin.adapters.adapter.view", {}, { reload: true });
		} else {
			$state.go("^.view", {}, { reload: true });
		}
	}
}
