angular.module("eShareApp").controller("groupListCtrl", [
	"$scope", "messageBox", "groups", "groupsRepository", "notification",

	function (
		$scope, messageBox, groups, groupsRepository, notification
	) {

		$scope.selectedGroup = null;
		$scope.groups = groups;

		$scope.removeGroup = function (group) {
			const mbox = messageBox.openQuestion(
				"Remove selected group?",
				"Do you really want to remove group " + group.name + "?",
				"Remove", "Cancel"
			);
			mbox.result.then(() => {
				groupsRepository.remove(group).then(updateGroupList, onError);
			});
		};

		$scope.selectGroup = function (group) {
			$scope.selectedGroup = group;
		};

		$scope.descriptionForDisplay = function (description) {
			description = description.replace(/\n/g, "<BR>");
			return description;
		};

		function onError(error) {
			notification.error(error);
		}

		function updateGroupList() {
			groupsRepository.getAll()
				.then(allGroups => {
					$scope.groups = allGroups;
				}, onError);
		}
	}
]);
