angular
	.module("eShareApp")
	.controller("variablesEditCtrl", variablesEditCtrl);

variablesEditCtrl.$inject = [
	"$state", "$scope", "$stateParams", "projectVariablesRepository", "notification"
];

function variablesEditCtrl(
	$state, $scope, $stateParams, projectVariablesRepository, notification
) {

	projectVariablesRepository.getProjectVariables($stateParams.projectId).then(
		result => {
			if(result && result.variables) {
				$scope.variableSettings = result;
			} else {
				$scope.variableSettings = {
					variables: [],
				};
			}
		}
	);

	$scope.removeVariable = function (index) {
		$scope.variableSettings.variables.splice(index, 1);
	};

	$scope.save = function () {
		const clone = _.cloneDeep($scope.variableSettings);
		projectVariablesRepository.setProjectVariables($stateParams.projectId, clone).then(
			() => {
				notification.success("Project variables successfully saved");
				$state.go("^", {}, { reload: true });
			}, error => {
				notification.error(error);
			}
		);
	};

	$scope.areVariablesValid = function () {
		if(!$scope.variableSettings) {
			return false;
		}
		const nameArray = [];
		for(let i = 0; i < $scope.variableSettings.variables.length; i++) {
			if($scope.variableSettings.variables[i].name === ""
					|| $scope.variableSettings.variables[i].value === ""
					// Blacklisted words
					|| $scope.variableSettings.variables[i].name === "projectName"
					|| $scope.variableSettings.variables[i].name === "projectDbName"
					|| $scope.variableSettings.variables[i].name === "projectId") {
				return false;
			}
			nameArray.push($scope.variableSettings.variables[i].name);
		}
		return !checkForDuplicateNames(nameArray);
	};

	function checkForDuplicateNames(a) {
		const counts = [];
		for(let i = 0; i <= a.length; i++) {
			if(!counts[a[i]]) {
				counts[a[i]] = 1;
			} else {
				return true;
			}
		}
		return false;
	}
}
