angular.module("eShareApp").controller("AdaptersExportImportCtrl", AdaptersExportImportCtrl);

AdaptersExportImportCtrl.$inject = [
	"$state", "notification", "isExport", "isImport", "project", "adaptersRepository"
];

function AdaptersExportImportCtrl(
	$state, notification, isExport, isImport, project, adaptersRepository
) {
	const vm = this;
	vm.isExport = isExport;
	vm.isImport = isImport;
	vm.serializedExportedData = "";
	vm.importResult = null;
	vm.importAll = importAll;
	vm.isInputValid = isInputValid;
	vm.isBusy = false;

	const stateName = $state.current.name;

	initialize();

	function initialize() {
		if(isExport) {
			vm.serializedExportedData = "Please wait...";
			adaptersRepository.exportAll(project.id).then(
				serializedExportedData => {
					vm.serializedExportedData = serializedExportedData;
				}
			);
		}
	}

	function importAll() {
		vm.importResult = null;
		let isSingleAdapter = true;
		let exportedData = null;
		try {
			exportedData = JSON.parse(vm.serializedExportedData);
			isSingleAdapter = !Utilities.isArray(exportedData.adapterConfigurations);
		} catch(e) {
			// do nothing
		}
		vm.isBusy = true;
		if(isSingleAdapter) {
			adaptersRepository.importConfiguration(project, exportedData).then(
				upgradedConfiguration => {
					vm.importResult = {
						hasSucceeded: true,
						isValid: true,
						errors: [],
						warnings: [],
					};
					notification.success("Imported adapter");
					if($state.current.name === stateName) {
						$state.go("project.admin.adapters.adapter.edit", {
							projectId: project.id,
							adapterId: upgradedConfiguration.id,
						}, {
							reload: true,
						});
					}
				},
				errorMessage => {
					vm.isBusy = false;
					vm.importResult = {
						hasSucceeded: false,
						isValid: true,
						errors: ["Adapter import failed. Reason: " + errorMessage],
						warnings: [],
					};
				}
			);
		} else {
			adaptersRepository.importAll(project.id, vm.serializedExportedData).then(
				importResult => {
					if(importResult.hasSucceeded
						&& (!importResult.warnings || importResult.warnings.length === 0)) {
						notification.success("Imported adapters");
						if($state.current.name === stateName) {
							$state.go("^", {}, { reload: true });
						}
					} else {
						vm.isBusy = false;
						if(importResult.hasSucceeded) {
							notification.success("Imported adapters, with warnings");
						}
						vm.importResult = importResult;
					}
				}
			);
		}
	}

	function isInputValid() {
		return Utilities.isJsonObject(vm.serializedExportedData);
	}
}
