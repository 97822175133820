angular.module("eShareApp").controller("ProjectManagedDocumentAdminCtrl", [
	"$state", "$scope", "$rootScope", "project", "documentConfigurationsRepository", "hierarchies",
	"highlightMode", "messageBox", "groups", "permissions", "savingSettings", "indexingSettings",
	"$timeout",

	function (
		$state, $scope, $rootScope, project, documentConfigurationsRepository, hierarchies,
		highlightMode, messageBox, groups, permissions, savingSettings, indexingSettings,
		$timeout
	) {

		$scope.project = project;
		$scope.hierarchies = hierarchies;
		$scope.indexingSettings = indexingSettings;
		$scope.isInEditMode = true;

		setHighlightMode(highlightMode);
		$scope.groups = groups;
		$scope.originalGroups = [];
		setPermissions(permissions);
		$scope.savingSettings = {
			keepOldRevisions: !!savingSettings.keepOldRevisions,
		};

		$scope.addDocumentHierarchy = function () {
			$state.go(".editHierarchy", {
				projectId: project.id,
				hierarchyIndex: -1,
			});
		};

		$scope.removeDocumentHierarchy = function (hierarchy) {
			const mbox = messageBox.openQuestion(
				"Remove selected document hierarchy?",
				"Do you really want to remove the " + hierarchy.name + " document hierarchy?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					_.remove($scope.hierarchies, hierarchy);
					documentConfigurationsRepository.saveHierarchySettings(
						$scope.project.id, $scope.hierarchies
					);
				}
			);
		};

		$scope.saveHighlightMode = function () {
			documentConfigurationsRepository
				.saveManagedDocumentHighlightMode($scope.project.id, $scope.highlightMode)
				.then(data => {
					setHighlightMode(data);
				});
		};

		$scope.isHighlightModeDirty = function () {
			const originalHighlightMode = $scope.originalHighlightMode;
			const highlightMode = $scope.highlightMode;
			return (originalHighlightMode.name !== highlightMode.name
				|| originalHighlightMode.shouldColorLinks !== highlightMode.shouldColorLinks
				|| areLinkColoringConfigurationsDirty(
					originalHighlightMode.linkColoringConfigurations,
					highlightMode.linkColoringConfigurations
				)
			);
		};

		$scope.isConfigurationInvalid = function () {
			for(let i = 0; i < $scope.highlightMode.linkColoringConfigurations.length; i++) {
				const configuration = $scope.highlightMode.linkColoringConfigurations[i];
				if(!configuration.displayName) {
					return true;
				}
			}
			return false;
		};

		$scope.isIndexingSaveEnabled = function () {
			if(typeof $scope.indexingSaveEnabled === "function") {
				return $scope.indexingSaveEnabled();
			}
			return false;
		};

		function setHighlightMode(mode) {
			$scope.highlightMode = mode;
			if(!$scope.highlightMode) {
				$scope.highlightMode = {};
				$scope.highlightMode.shouldColorLinks = false;
				$scope.highlightMode.linkColoringConfigurations = [];
				const colorConfiguration = {
					id: Utilities.getFakeGuid(),
					displayName: "Default",
					showLegend: false,
					colorLinkMode: 1,
					linkTargetIds: [],
					colorLinkDefaultColors: ["#00FF00", "#FF0000", "#FFFF00"],

				};
				$scope.highlightMode.linkColoringConfigurations.push(colorConfiguration);
			}
			if(!$scope.highlightMode.name) {
				$scope.highlightMode.name = "Overlay";
			}
			_.forEach($scope.highlightMode.linkColoringConfigurations, configuration => {
				if(!configuration.colorLinkDefaultColors) {
					configuration.colorLinkDefaultColors = ["#00FF00", "#FF0000", "#FFFF00"];
				}
				if(!configuration.linkTargetIds) {
					configuration.linkTargetIds = [];
				}
			});
			$scope.originalHighlightMode = _.cloneDeep($scope.highlightMode);
		}

		function compareArrays(a, b) {
			if(a.length !== b.length) {
				return true;
			}
			for(let i = 0; i < a.length; i++) {
				if(a[i] !== b[i]) {
					return true;
				}
			}
			return false;
		}

		function areLinkColoringConfigurationsDirty(a, b) {
			if(a.length !== b.length) {
				return true;
			}
			for(let i = 0; i < a.length; i++) {
				if(a[i].displayName !== b[i].displayName
					|| a[i].id !== b[i].id
					|| a[i].showLegend !== b[i].showLegend
					|| a[i].colorLinkMode !== b[i].colorLinkMode
					|| compareArrays(a[i].linkTargetIds, b[i].linkTargetIds)
					|| compareArrays(a[i].colorLinkDefaultColors, b[i].colorLinkDefaultColors)) {
					return true;
				}
			}
			return false;
		}

		$scope.arePermissionsDirty = function () {
			if($scope.originalGroups.length !== $scope.groups.length) {
				return true;
			}
			for(let i = 0; i < $scope.groups.length; i++) {
				const original = $scope.originalGroups[i];
				const current = $scope.groups[i];
				if(current.hasPermissionRead !== original.hasPermissionRead) {
					return true;
				}
			}
			return false;
		};

		$scope.savePermissions = function () {
			const updatedPermissions = {};
			updatedPermissions.permissionToSeeGroupIds = _.map(_.filter($scope.groups, {
				"hasPermissionRead": true,
			}), "id");
			documentConfigurationsRepository
				.saveManagedDocumentPermissions($scope.project.id, updatedPermissions)
				.then(data => {
					setPermissions(data);
				});
		};

		$scope.saveIndexingSettings = function () {
			if(typeof $scope.indexingCommit === "function") {
				$scope.indexingCommit();
			}
			documentConfigurationsRepository
				.saveManagedDocumentIndexingSettings($scope.project.id, $scope.indexingSettings)
				.then(data => {
					$scope.indexingSettings = data;
					if(typeof $scope.initializeIndexing === "function") {
						$scope.initializeIndexing();
					}
				});
		};

		const removeListener = $rootScope.$on("$stateChangeStart", onStateChangeStart);
		$scope.$on("$destroy", removeListener);

		function onStateChangeStart(event, toState, toParams) {
			if(!$scope.isHighlightModeDirty()) {
				return;
			}
			event.preventDefault();
			messageBox.openQuestion(
				"Unsaved Changes",
				"You have unsaved changes on this page.<br><br>"
				+ "Do you really want to continue without saving these changes?",
				"<i class='fa fa-check fa-fw'></i> Yes", "<i class='fa fa-times fa-fw'></i> No"
			).result.then(
				() => {
					removeListener();
					$state.go(toState, toParams);
				}, () => {
					// do nothing on cancel
				}
			);
		}

		function setPermissions(permissions) {
			_.forEach($scope.groups, group => {
				group.hasPermissionRead = permissions.permissionToSeeGroupIds
					&& permissions.permissionToSeeGroupIds.indexOf(group.id) > -1;
			});
			$scope.originalGroups = _.cloneDeep($scope.groups);
		}

		$scope.saveSavingSettings = function () {
			const dtoToReturn = {
				keepOldRevisions: $scope.savingSettings.keepOldRevisions,
			};
			let mbox;
			if(!dtoToReturn.keepOldRevisions) {
				mbox = messageBox.openQuestion(
					"Stop keeping old document revisions?",
					"Do you really want to stop keeping old document revisions?<br>"
					+ "<span class=\"emphasized\">This will permanently delete all old revisions of"
					+ " your documents from eShare.</span>",
					"Accept", "Cancel"
				);
			} else {
				mbox = {
					result: $timeout(),
				};
			}
			mbox.result.then(
				() => {
					documentConfigurationsRepository
						.updateManagedDocumentSavingSettings(project.id, dtoToReturn)
						.then(settings => {
							savingSettings = settings;
						});
				}
			);
		};

		$scope.areSavingSettingsDirty = function () {
			return !!savingSettings.keepOldRevisions !== $scope.savingSettings.keepOldRevisions;
		};
	}
]);
