angular.module("eShare.core").factory("serverMonitor", serverMonitor);

serverMonitor.$inject = ["$rootScope", "$interval", "usersRepository", "notification"];

function serverMonitor($rootScope, $interval, usersRepository, notification) {

	let numberOfHtmlWhoAmIs = 0;

	const service = {
		initialize: initialize,
		isAlive: true,
		connectionLost: connectionLost,
		tryReconnect: tryReconnect,
	};

	return service;

	function initialize() {
		$interval(pingWhoAmI, 60 * 1000);
	}

	function tryReconnect() {
		pingWhoAmI();
	}

	function pingWhoAmI() {
		usersRepository.whoAmI()
			.then(
				response => {
					try {
						if(response.status !== 200) {
							throw new Error();
						}
						const headers = response.headers();
						const contentType = ((headers && headers["content-type"]) || "")
							.trim().toLowerCase();
						if(contentType.startsWith("text/html")) {
							// the server is probably recycling / warming up
							++numberOfHtmlWhoAmIs;
							if(numberOfHtmlWhoAmIs >= 3) {
								throw new Error();
							}
							return;
						}
						numberOfHtmlWhoAmIs = 0;
						if(!contentType.startsWith("application/json")) {
							throw new Error();
						}
						const data = response.data;
						if(!data) {
							throw new Error();
						}
						if((data.userId || window.eShare.currentUser.id)
							&& data.userId !== window.eShare.currentUser.id) {
							throw new Error();
						}
						connectionRestored();
					} catch(e) {
						notification.error("User identification failed");
						window.Authenticator.setCadAuthorization(null);
						$rootScope.$broadcast("event:sessionTerminated");
						connectionLost();
					}
				}, () => {
					connectionLost();
				}
			);
	}

	function setIsAlive(value) {
		if(service.isAlive === value) {
			return;
		}
		service.isAlive = value;
		$rootScope.$broadcast("event:serverIsAliveChanged", value);
	}

	function connectionLost() {
		setIsAlive(false);
	}

	function connectionRestored() {
		setIsAlive(true);
	}
}
