angular.module("eShareApp")
	.controller("groupViewCtrl", [
		"$scope", "group", "users",
		function (
			$scope, group, users
		) {

			$scope.group = group;
			$scope.group.users = _.sortBy(_.filter(_.map(group.memberIds, memberId => {
				return _.find(users, user => {
					return user.id === memberId;
				});
			}), member => {
				return member;
			}), user => {
				return (user.name || "").toLowerCase();
			});

			$scope.descriptionForDisplay = function (description) {
				description = description.replace(/\n/g, "<BR>");
				return description;
			};
		}
	]);
