angular.module("eShareApp").controller("eBrowserCtrl", [
	"$scope", "$rootScope", "$element", "$interval", "$timeout", "eBrowser", "$state",
	"authorization", "pointOfInterestKindRepository", "pipingStandardRepository", "notification",
	"messageBox", "serverMonitor", "$q",

	function (
		$scope, $rootScope, $element, $interval, $timeout, eBrowser, $state,
		authorization, pointOfInterestKindRepository, pipingStandardRepository, notification,
		messageBox, serverMonitor, $q
	) {

		let externalComponent = window.external && window.external.isEBrowserControl
			? window.external
			: undefined;
		if(window.chrome
			&& window.chrome.webview
			&& window.chrome.webview.hostObjects
			&& window.chrome.webview.hostObjects.eBrowserControl) {
			externalComponent = window.chrome.webview.hostObjects.eBrowserControl;
		}
		$scope.hasExternalControl = Utilities.toBool(externalComponent);

		console.log("eBrowserCtrl starting: " + $scope.hasExternalControl);

		let isInitialized = false;

		$rootScope.$on("event:cadAuthorizationChanged", () => {
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl) {
					eBrowserControl.ptyCadAuthorization = window.Authenticator.getCadAuthorization();
				}
			});
		});

		let objectElementHtml = "";
		let scriptElementHtml = "";
		let eBrowserWrapperElement = null;
		const E_INVALIDARG = -2147024809; // = 0xFFFFFFFF80070057

		let projectId = Utilities.emptyGuid();
		let sequentialNumber = 0;
		let x = 0;
		let y = 0;
		let w = 0;
		let h = 0;
		let visibility = "";

		let locateRequest = null;
		let lastLocateRequest = null;
		let lastLocateRequestExecutedAt = null;

		const emptyMessage = {
			isVisible: false,
			title: "",
			text: "",
			button1Text: "",
			button2Text: "",
			button1Clicked: null,
			button2Clicked: null,
		};
		$scope.message = _.clone(emptyMessage);

		$scope.model = {
			isLoaded: false,
			isVisible: true,
			shouldDisplay: function () {
				return this.isLoaded && this.isVisible;
			},
		};

		let environment;
		initialize();

		function ensureIsInitialized() {
			if(!isInitialized) {
				throw new EShareException("eBrowser is not initialized");
			}
		}

		function initialize() {
			if(isInitialized) {
				throw new EShareException("eBrowser is already initialized");
			}
			console.log("eBrowserCtrl::initialize");
			initializeScript();
			initializeObjectAsync().then(version => {
				const installedVersion = version || "";
				const isInstalled = installedVersion !== "";
				let isSupportedVersion = installedVersion === window.eShare.version;
				if(!isSupportedVersion && isInstalled) {
					isSupportedVersion = installedVersion === "0.0.0.0"
						|| window.eShare.version === "1.0.0.0";
					if(!isSupportedVersion) {
						if(window.eShare.automatedClientUpdates) {
							const versionParts = installedVersion.split(".");
							const majorVersion = +versionParts[0];
							const minorVersion = +versionParts[1];
							if(majorVersion > 20 || (majorVersion === 20 && minorVersion >= 3)) {
								window.postMessage({
									type: "Post::eBrowser::VersionUpdateCommenced",
								}, "*");

							}
						} else {
							const objectElement = getObjectElement();
							if(objectElement) {
								objectElement.remove();
							}
						}
					}
				}
				$interval(trackContainer, 100);
				isInitialized = true;
				const newEnvironment = {
					isActiveXSupported: isActiveXSupported() || $scope.hasExternalControl,
					isInstalled: isInstalled,
					isSupported: isSupportedVersion,
					installedVersion: installedVersion,
					neededVersion: window.eShare.version,
					isDesktopAppRequired: false,
					isDesktopApp: $scope.hasExternalControl,
				};
				console.log("Environment: " + JSON.stringify(newEnvironment));
				environment = newEnvironment;
				$scope.environment = newEnvironment;
			});

			function initializeScript() {
				if($scope.hasExternalControl) {
					// nothing to be done
				} else {
					const scriptElement = getScriptElement();
					if(scriptElement.length !== 1) {
						throw new EShareException(
							"Invalid eBrowser HTML container: <script> element missing"
						);
					}
					scriptElementHtml = scriptElement[0].outerHTML.replace(
						/__eBrowserControl_/g, "__eBrowserControl::"
					);
					scriptElement.remove();
				}
			}

			function initializeObjectAsync() {
				if($scope.hasExternalControl) {
					return $q.resolve(externalComponent.ptyVersionString);
				} else {
					const objectElement = getObjectElement();
					if(objectElement.length !== 1) {
						throw new EShareException(
							"Invalid eBrowser HTML container: <object> element missing"
						);
					}
					eBrowserWrapperElement = objectElement.parent();
					objectElementHtml = objectElement[0].outerHTML;
					return $q.resolve(objectElement[0].ptyVersionString);
				}
			}

			function isActiveXSupported() {
				// This check for ActiveX support below is "convoluted" for a reason:
				// ActiveXObject is hidden from DOM starting in IE11, so this is the way to test for it
				if("ActiveXObject" in window
						|| (Object.getOwnPropertyDescriptor
								&& Object.getOwnPropertyDescriptor(window, "ActiveXObject"))) {
					return true;
				}
				return false;
			}
		}

		function isSupported() {
			return environment?.isSupported;
		}

		function isModelLoaded() {
			return $scope.model.isLoaded;
		}

		function isModelVisible() {
			return visibility === "visible";
		}

		async function loadModel(project) {
			ensureIsInitialized();
			if(!environment) {
				await $timeout(await loadModel(project), 100);
				return;
			}
			if(!Utilities.isObject(project)) {
				throw new EShareException("Project must be specified for model loading");
			}
			let projectIdToLoad = project.id;
			projectIdToLoad = projectIdToLoad || Utilities.emptyGuid();
			if(!Utilities.isGuid(projectIdToLoad)) {
				throw new EShareException("Invalid project ID to load model for");
			}
			if(!environment.isSupported) {
				return;
			}
			if(projectId === projectIdToLoad) {
				return;
			}
			$scope.model.isLoaded = false;
			destroy();
			if(Utilities.isEmptyGuid(projectIdToLoad)) {
				projectId = projectIdToLoad;
				return;
			}
			environment.isDesktopAppRequired = Utilities.toBool(
				project.is64BitOnly && !$scope.hasExternalControl
			);
			if(environment.isDesktopAppRequired) {
				projectId = projectIdToLoad;
				return;
			}
			window.eShare.eBrowserControl_projectId = projectIdToLoad;
			const modelUrl = "{rootUrl}api/project/{projectId}/Models2/{projectId}".supplant({
				rootUrl: window.eShare.rootUrl,
				projectId: projectIdToLoad,
			});
			if($scope.hasExternalControl) {
				await getEBrowserControlAsync().then(async eBrowserControl => {
					if(eBrowserControl) {
						try {
							await eBrowserControl.reset();
						} catch (e) {
							// ignored
						}
						await finishLoadModel(eBrowserControl);
					}
				});
			} else {
				const eBrowserControlId = "__eBrowserControl_" + (++sequentialNumber);
				const objectHtml = objectElementHtml.replace(
					"__eBrowserControl", eBrowserControlId
				);
				const scriptHtml = scriptElementHtml.replace(
					/__eBrowserControl::/g, eBrowserControlId + "::"
				);
				eBrowserWrapperElement.append(objectHtml);
				$element.append(scriptHtml);
				await getEBrowserControlAsync().then(async eBrowserControl => {
					if(eBrowserControl) {
						await finishLoadModel(eBrowserControl);
					}
				});
			}

			async function finishLoadModel(eBrowserControl) {
				window.debugOut("Loading model: " + modelUrl);
				if($scope.hasExternalControl) {
					if(document.cookie.length > 0) {
						await eBrowserControl.mthAddCustomHttpHeader("Cookie", document.cookie);
					} else {
						console.log("No cookies found");
					}
					await eBrowserControl.setPtyExternalDataServer(window.eShare.rootUrl);
					await eBrowserControl.setPtyAuthenticationToken(
						window.eShare.integratedSecurity
							? ""
							: window.eShare.currentUser.token
					);
					await eBrowserControl.setPtyCadAuthorization(
						window.Authenticator.getCadAuthorization()
					);
					await eBrowserControl.setPtyModelFileName(modelUrl);
					await eBrowserControl.setPtyIsAdmin(false);
					await eBrowserControl.setPtyEnableRendering(false);
				} else {
					eBrowserControl.ptyExternalDataServer = window.eShare.rootUrl;
					eBrowserControl.ptyAuthenticationToken = window.eShare.integratedSecurity
						? ""
						: window.eShare.currentUser.token;
					eBrowserControl.ptyCadAuthorization = window.Authenticator.getCadAuthorization();
					eBrowserControl.ptyModelFileName = modelUrl;
					eBrowserControl.ptyIsAdmin = false;
					eBrowserControl.ptyEnableRendering = false;
				}

				await $timeout(async () => {
					await wrapInAsync(eBrowserControl.mthInitializeAndDraw()).then(() => {
						window.debugOut("Model loaded");

						projectId = projectIdToLoad;
						authorization.getUserPermissions(projectId).then(
							response => {
								eBrowserControl.ptyIsAdmin = response.data.isProjectAdmin || false;
							}, error => {
								notification.error(error);
							}
						);
						pipingStandardRepository.getPipingStandard(projectId).then(standard => {
							const def = standard.standardDefinition;
							switch (standard.type) {
							case "Scheduled":
								eBrowserControl.mthEnableScheduledPipelineSpecification(
									def.pipelineTag,
									def.npdTag,
									def.scheduleTag,
									JSON.stringify(def.scheduleTable)
								);
								break;
							case "UseAttributes":
								eBrowserControl.mthEnableAttributePipelineSpecification(
									def.pipelineTag,
									def.outerDiameterTag,
									def.wallThicknessTag
								);
								break;
							}
						}, error => {
							notification.error(error);
						});
					});
				}, 0);
			}
		}

		function reloadModel() {
			ensureIsInitialized();
			$scope.model.isLoaded = false;
			adjustEnableRendering();
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl) {
					eBrowserControl.mthReloadModel();
				}
			});
		}

		function examineBranch(examinePath, examineFlags) {
			ensureIsInitialized();
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl && Utilities.isString(examinePath) && examinePath !== "") {
					if(isNaN(examineFlags)) {
						examineFlags = 1;
					}
					if(isModelLoaded()) {
						eBrowserControl.mthExamineHierarchyBranch(examinePath, examineFlags);
					} else {
						const interval = $interval(() => {
							if(isModelLoaded()) {
								eBrowserControl.mthExamineHierarchyBranch(examinePath, examineFlags);
								$interval.cancel(interval);
							}
						}, 1000);

					}
				}
			});
		}

		function showMessage(title, text, button1Text, button2Text, button1Click, button2Click) {
			showImportantMessage(title, text, button1Text, button2Text, button1Click, button2Click);
		}

		function showImportantMessage(
			title, text, button1Text, button2Text, button1Click, button2Click
		) {
			$scope.message.title = title || "TITLE";
			$scope.message.text = text || "Text";
			$scope.message.button1Text = button1Text || "";
			$scope.message.button2Text = button2Text || "";
			$scope.message.button1Click = function () {
				Utilities.invokeCallback(button1Click, 1);
				$scope.message.isVisible = false;
				$scope.model.isVisible = true;
			};
			$scope.message.button2Click = function () {
				Utilities.invokeCallback(button2Click, 2);
				$scope.message.isVisible = false;
				$scope.model.isVisible = true;
			};
			$scope.message.isVisible = true;
			$scope.model.isVisible = false;
		}

		function hideMessage() {
			_.assign($scope.message, emptyMessage);
			$scope.model.isVisible = true;
		}

		function destroy() {
			if($scope.hasExternalControl) {
				// do nothing
			} else {
				const scriptElement = getScriptElement();
				scriptElement.remove();
				const objectElement = getObjectElement();
				objectElement.remove();
			}
		}

		function adjustEnableRendering() {
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl) {
					eBrowserControl.ptyEnableRendering = isModelLoaded() && isModelVisible();
				}
			});
		}

		function getObjectElement() {
			return getChildElement("object");
		}

		function getScriptElement() {
			return getChildElement("script");
		}

		function getChildElement(elementType) {
			const element = $element.find(elementType);
			if(element.length > 1) {
				throw new EShareException(
					"Cannot get eBrowser <" + elementType
					+ "> element, because there are " + element.length + " of them"
				);
			}
			return element;
		}

		function getEBrowserControlAsync(dontCheckVersion) {
			const control = getEBrowserControl();
			if(control == null) {
				return $q.resolve(null);
			}
			if(dontCheckVersion) {
				return wrapInAsync(control);
			}
			return wrapInAsync(control.ptyVersionString).then(result => {
				if(!result || !environment || result !== environment.installedVersion) {
					return null; // "Invalid eBrowser <object> element version"
				}
				return control;
			});
			function getEBrowserControl() {
				if(!isInitialized) {
					return null;
				}
				let control;
				if(externalComponent) {
					control = externalComponent;
				} else {
					const objectElement = getObjectElement();
					if(objectElement.length !== 1) {
						// "Invalid eBrowser <object> element"
						return null;
					}
					control = objectElement[0];
				}
				if(!control) {
					return null;
				}
				return control;
			}
		}

		function askToUpdate() {
			setTimeout(() => {
				getEBrowserControlAsync(true).then(eBrowserControl => {
					if(!eBrowserControl) {
						alert("Failed to get eShare3D control component");
						return;
					}
					const updateTarget = $scope.hasExternalControl
						? "eShare App"
						: "eShare 3D-component";
					messageBox.openQuestion(
						updateTarget + " outdated",
						"The eShare server has been updated.<br><br>"
						+ "Do you want to update " + updateTarget + " now?",
						"<i class='fa fa-check fa-fw'></i> Yes", "<i class='fa fa-times fa-fw'></i> No"
					).result.then(() => {
						try {
							wrapInAsync(
								eBrowserControl.mthLaunchVersionUpdate(
									window.eShare.rootUrl,
									window.eShare.integratedSecurity
										? ""
										: window.eShare.currentUser.token,
									window.Authenticator.getCadAuthorization()
								)
							).then(isSuccess => {
								if(!isSuccess) {
									notification.error(
										"The automated " + updateTarget + " update failed."
										+ " Try to run the program with admin privileges,"
										+ " perform the update manually"
										+ " or ask IT department to perform the update."
									);
								} else {
									const objectElement = getObjectElement();
									if(objectElement) {
										objectElement.remove();
									}
									window.close();
								}
							});
						} catch {
							const file = $scope.hasExternalControl
								? "/files/eShareApp.msi"
								: "/files/eShare3D.msi";
							messageBox.openError(
								updateTarget + " automatic update failed",
								"Something went wrong when automatically updating " + updateTarget + "."
								+ "<br><br>"
								+ "Download " + updateTarget + " to update manually:<br>"
								+ "<a href=\"" + file + "\"><i class=\"fa fa-download\">"
								+ "</i> Download</a>"
							);
						}
					}, () => {
						// do nothing on no
					});
				});
			}, 5000);
		}

		async function windowEventListener(event) {
			if(!Utilities.isObject(event)) {
				return;
			}
			const data = event.data || {};
			const eBrowserControlProjectId = window.eShare.eBrowserControl_projectId;
			switch(data.type) {
			case "Post::eBrowser::ShowMessage":
				if(projectId == eBrowserControlProjectId) {
					const title = data.title;
					const displayMessage = (
						Utilities.isString(title) ? title + ": " : title
					) + data.message;
					switch(data.messageType) {
					case "INFO":
						notification.info(displayMessage);
						break;
					case "WARN":
						notification.warning(displayMessage);
						break;
					default: // "ERR"
						notification.error(displayMessage);
						break;
					}
				}
				$rootScope.$broadcast(
					"eBrowser::ShowMessage",
					eBrowserControlProjectId,
					data.messageType,
					data.title,
					data.message
				);
				break;
			case "Post::eBrowser::ModelLoaded":
				$scope.model.isLoaded = true;
				adjustEnableRendering();
				executeLocateRequest();
				break;
			case "Post::eBrowser::ModelRefreshed":
				$scope.model.isLoaded = true;
				adjustEnableRendering();
				executeLocateRequest();
				break;
			case "Post::eBrowser::Resized":
				if(locateRequest === null
					&& lastLocateRequest !== null
					&& lastLocateRequestExecutedAt !== null) {
					const now = new Date();
					const timeSinceLastLocate = now.getTime() - lastLocateRequestExecutedAt.getTime();
					if(timeSinceLastLocate < 200) {
						locateRequest = _.clone(lastLocateRequest);
					}
					executeLocateRequest();
				}
				break;
			case "Post::eBrowser::ObjectRequested":
				$rootScope.$broadcast(
					"eBrowser::ObjectRequested",
					eBrowserControlProjectId,
					data.geometryId,
					data.timestamp,
					data.moveCamera
				);
				break;
			case "Post::eBrowser::MultipleObjectsRequested":
				$rootScope.$broadcast(
					"eBrowser::MultipleObjectsRequested",
					eBrowserControlProjectId,
					data.geometryIds,
					data.timestamp,
					data.moveCamera,
					data.doExamine
				);
				break;
			case "Post::eBrowser::PointRequested":
				$rootScope.$broadcast(
					"eBrowser::PointRequested",
					eBrowserControlProjectId,

					data.pointId,
					data.pointType
				);
				break;
			case "Post::eBrowser::AddPoint":
				$rootScope.$broadcast(
					"eBrowser::AddPoint",
					eBrowserControlProjectId,
					data.relatedObjectId,
					data.x,
					data.y,
					data.z
				);
				break;
			case "Post::eBrowser::GroupRequested":
				$rootScope.$broadcast(
					"eBrowser::GroupRequested",
					eBrowserControlProjectId,
					data.parentTag,
					data.childTag,
					data.value,
					data.isFullGroup,
					data.timestamp,
					data.doExamine
				);
				break;
			case "link::position":
				$state.go("project.model", {
					projectId: eBrowserControlProjectId,
					positionId: data.positionId,
					tag: data.tag,
				});
				break;
			case "Post::eBrowser::ModelOutdated":
				$rootScope.$broadcast("eBrowser::ModelOutdated");
				break;
			case "Post::eBrowser::HierarchyChanged":
				$scope.model.hierarchyBeingChanged = false;
				$rootScope.$broadcast("eBrowser::HierarchyChanged");
				break;
			case "Post::eBrowser::MarkupEditSave":
				$rootScope.$broadcast(
					"eBrowser::MarkupEditSave",
					eBrowserControlProjectId,
					data.ebxFileContents,
					data.markupGuid
				);
				break;
			case "Post::eBrowser::ShowExamineData":
				$rootScope.$broadcast(
					"eBrowser::ShowExamineData",
					eBrowserControlProjectId,
					data.examineData
				);
				break;
			case "Post::eBrowser::MarkupCreationStarted":
				$rootScope.$broadcast(
					"eBrowser::MarkupCreationStarted",
					eBrowserControlProjectId,
					data.relatedObjectId
				);
				break;
			case "Post::eBrowser::VersionUpdateCommenced":
				askToUpdate();
				break;
			case "Post::eBrowser::ModelLoadingInterrupted":
				showMessage(
					"Model Loading Interrupted",
					`Model loading was interrupted due to an unknown error. Make sure that this domain 
					is considered trusted or local in your internet options. Also, if you logged on to 
					this domain with credentials, make sure that those credentials are added to 
					windows credentials manager.`
				);
				break;
			case "Post::eBrowser::OneTimeTokenRequested": {
				if($scope.hasExternalControl) {
					const oneTimeToken = await authorization.createOneTimeToken();
					const control = await getEBrowserControlAsync();
					await control.setPtyOneTimeAuthenticationToken(oneTimeToken.token);
				}
				break;
			}
			case "Post::eBrowser::MouseDown": {
				const element = checkUIElementsUnderCoordinates(data.x, data.y + 51);
				if(element) {
					simulate(element, "click", { pointerX: data.x, pointerY: data.y + 51 });
					removeHighlightFromDropdownElements();
					onMouseMove(data.x, data.y + 51);
				} else {
					closeDropdownMenus();
					removeHighlightFromDropdownElements();
				}
				break;
			}
			case "Post::eBrowser::MouseMove": {
				// Note: Mouse move simulation does not seem to work, so styles related to
				// mouse movement need to be set manually.
				onMouseMove(data.x, data.y + 51);
				break;
			}
			default:
				// ignore
				break;
			}
		}

		// Gets the lowest-level element under the coordinates
		function checkUIElementsUnderCoordinates(x, y) {
			let element = null;
			const uiContainers = document.querySelectorAll("[cad-3d-ui-element]");
			for(let i = 0; i < uiContainers.length; i++) {
				const uiContainer = uiContainers[i];
				element = getUIElementOn3D(uiContainer, x, y);
				if(element) {
					break;
				}
			}
			return element;

			function getUIElementOn3D(parentElement, x, y) {
				if(!parentElement) {
					return null;
				}
				for(const key in parentElement.children) {
					if(Object.hasOwnProperty.call(parentElement.children, key)) {
						const childElement = parentElement.children[key];
						if(childElement.getAttribute("ignore") !== null) {
							continue;
						}
						const absolutePosition = childElement.getBoundingClientRect();
						if(x > absolutePosition.left && x < absolutePosition.right
						&& y > absolutePosition.top && y < absolutePosition.bottom) {
							return getUIElementOn3D(childElement, x, y) || childElement;
						}
						if(childElement.children.length > 0) {
							const element = getUIElementOn3D(childElement, x, y);
							if(element) {
								return element;
							}
						}
					}
				}
				const parentRect = parentElement.getBoundingClientRect();
				if(x > parentRect.left && x < parentRect.right
					&& y > parentRect.top && y < parentRect.bottom) {
					return parentElement;
				}
				return null;
			}
		}

		async function onMouseMove(x, y) {
			const element = checkUIElementsUnderCoordinates(x, y);
			const eBrowserCtrl = await getEBrowserControlAsync();
			if(element) {
				eBrowserCtrl.ptyDisable3DMouseHandling = true;
				closeDropdownSubmenusUnderElement(element, x, y);
				removeHighlightFromDropdownElements();
				highlightAndInteractWithDropdownMenu(element);
			} else {
				eBrowserCtrl.ptyDisable3DMouseHandling = false;
				closeDropdownSubmenus();
				removeHighlightFromDropdownElements();
			}
		}

		const highlightedElements = [];

		function highlightAndInteractWithDropdownMenu(element) {
			if(element.getAttribute("cad-3d-ui-element") !== null) {
				return;
			}
			if(element.parentElement.getAttribute("highlight-children") !== null) {
				element.classList.add("active"); // Bootstrap
				highlightedElements.push(element);
			}
			if(element.classList.contains("dropdown-submenu")) { // Bootstrap
				element.classList.add("open"); // Bootstrap
			}
			highlightAndInteractWithDropdownMenu(element.parentElement);
		}

		function closeDropdownMenus() {
			const openDropdownMenus = document.querySelectorAll(".dropdown.open"); // Bootstrap
			for(let i = 0; i < openDropdownMenus.length; i++) {
				const dropdownMenu = openDropdownMenus[i];
				dropdownMenu.classList.remove("open"); // Bootstrap
			}
		}

		function closeDropdownSubmenus() {
			// Bootstrap
			const openDropdownSubmenus = document.querySelectorAll(".dropdown-submenu.open");
			for(let i = 0; i < openDropdownSubmenus.length; i++) {
				const dropdownMenu = openDropdownSubmenus[i]; // Bootstrap
				dropdownMenu.classList.remove("open");
			}
		}

		function closeDropdownSubmenusUnderElement(element, x, y) {
			// Bootstrap
			const openSubmenuElements = document.querySelectorAll(".dropdown-submenu.open");
			for(let i = 0; i < openSubmenuElements.length; i++) {
				const dropdownMenu = openSubmenuElements[i];
				const absolutePosition = dropdownMenu.getBoundingClientRect();
				if(x > absolutePosition.left && x < absolutePosition.right
					&& y > absolutePosition.top && y < absolutePosition.bottom) {
					continue;
				}
				dropdownMenu.classList.remove("open"); // Bootstrap
			}
		}

		function removeHighlightFromDropdownElements() {
			for(let i = 0; i < highlightedElements.length; i++) {
				const element = highlightedElements[i];
				if(element.classList.contains("open")) { // Bootstrap
					continue;
				}
				element.classList.remove("active"); // Bootstrap
				highlightedElements.splice(i, 1);
				i--;
			}
		}

		const eventMatchers = {
			"HTMLEvents":
				/^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
			"MouseEvents":
				/^(?:click|dblclick|mouse(?:down|up|enter|over|move|out))$/,
		};
		const defaultOptions = {
			pointerX: 0,
			pointerY: 0,
			button: 0,
			ctrlKey: false,
			altKey: false,
			shiftKey: false,
			metaKey: false,
			bubbles: true,
			cancelable: true,
		};

		function simulate(element, eventName, args) {
			const options = extend(defaultOptions, args || {});
			let oEvent, eventType = null;
			eventType = eventName;
			for(const name in eventMatchers) {
				if(eventMatchers[name].test(eventName)) {
					eventType = name;
					break;
				}
			}
			if(!eventType) {
				throw new SyntaxError("Only HTMLEvents and MouseEvents interfaces are supported");
			}
			if(document.createEvent) {
				oEvent = document.createEvent(eventType);
				if(eventType == "HTMLEvents") {
					oEvent.initEvent(eventName, options.bubbles, options.cancelable);
				} else {
					oEvent.initMouseEvent(eventName, options.bubbles, options.cancelable,
						document.defaultView, options.button, options.pointerX, options.pointerY,
						options.pointerX, options.pointerY, options.ctrlKey, options.altKey,
						options.shiftKey, options.metaKey, options.button, element);
				}
				element.dispatchEvent(oEvent);
			} else {
				options.clientX = options.pointerX;
				options.clientY = options.pointerY;
				const evt = document.createEventObject();
				oEvent = extend(evt, options);
				element.fireEvent("on" + eventName, oEvent);
			}
			return element;

			function extend(destination, source) {
				for(const property in source) {
					destination[property] = source[property];
				}
				return destination;
			}
		}

		let isHidden = false;
		let isEBrowserWrapperElementHidden = false;
		let lastLocationUpdateTime = new Date(0);

		function hide() {
			setLocation(-20000, 0);
			x = y = w = h = -1;
			visibility = "hidden";
			isHidden = true;
		}

		function setLocation(left, top, width, height) {
			if(isHidden) {
				if(left === -20000 && top === 0) {
					return;
				}
				isHidden = false;
			}
			window.debugOut("setLocation <= (" + left + " , " + top + ") " + width + " x " + height);
			$element.css({ left: left + "px", top: top + "px" });
			if(!Utilities.isNullOrUndefined(width) && !Utilities.isNullOrUndefined(height)) {
				$element.css({ width: width + "px", height: height + "px" });
			}
			if($scope.hasExternalControl) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(isEBrowserWrapperElementHidden) {
						left = -20000;
						top = 0;
						width = undefined;
						height = undefined;
					}
					const zoom = window.devicePixelRatio || 1;
					window.debugOut(
						"setLocation z=" + zoom
						+ " l=" + left
						+ " t=" + top
						+ " w=" + width
						+ " h=" + height
					);
					const xScale = left < 0 ? left : ((left || 0) / window.innerWidth);
					const yScale = (top || 0) / window.innerHeight / zoom;
					const widthScale = (width || 0) / window.innerWidth;
					const heightScale = (height || 0) / window.innerHeight;
					window.debugOut(
						"setLocation => x=" + xScale
						+ " y=" + yScale
						+ " w=" + widthScale
						+ " h=" + heightScale
					);
					eBrowserControl.setLocation(xScale, yScale, widthScale, heightScale);
				});
			}
		}

		function trackContainer() {
			const container = $("#eBrowserView");
			if(Utilities.isNullOrUndefined(container) || container.length === 0) {
				hide();
				return;
			}
			const containerOffset = container.offset();
			if(Utilities.isNullOrUndefined(containerOffset)) {
				console.log("trackContainer: #eBrowserView doesn't have offset");
				hide();
				return;
			}
			const newX = containerOffset.left;
			const newY = containerOffset.top;
			const newW = container.width();
			const newH = container.height();
			let newVisibility = container.css("visibility");
			let newIsEBrowserWrapperElementHidden = false;
			if($scope.hasExternalControl) {
				const divElement = $("#__eBrowserContainer");
				if(divElement.length === 1) {
					eBrowserWrapperElement = divElement.parent();
					newIsEBrowserWrapperElementHidden = eBrowserWrapperElement.hasClass(
						"eBrowserHidden"
					);
				}
			}
			if(!serverMonitor.isAlive && newVisibility === "visible") {
				newVisibility = "hidden";
			}
			const timeNow = new Date();
			if(x !== newX || y !== newY || w !== newW || h !== newH
				|| visibility !== newVisibility
				|| isEBrowserWrapperElementHidden !== newIsEBrowserWrapperElementHidden
				|| timeNow - lastLocationUpdateTime > 500) {
				lastLocationUpdateTime = timeNow;
				x = newX;
				y = newY;
				w = newW;
				h = newH;
				visibility = newVisibility;
				isEBrowserWrapperElementHidden = newIsEBrowserWrapperElementHidden;
				if(visibility === "visible") {
					setLocation(x, y, w, h);
				} else {
					hide();
				}
				adjustEnableRendering();
			}
		}

		function locateByGeometryId(geometryId, modelTimestamp, moveCamera, examineFlags) {
			locateRequest = {
				type: "geometry",
				geometryId: geometryId,
				modelTimestamp: modelTimestamp,
				moveCamera: moveCamera,
				examineFlags: examineFlags,
			};
			executeLocateRequest();
		}

		function locateGroup(parentTag, childTag, id, animate, examineFlags) {
			locateRequest = {
				type: "group",
				parentTag: parentTag,
				childTag: childTag,
				id: id,
				animate: animate,
				examineFlags: examineFlags,
			};
			executeLocateRequest();
		}

		function locatePoint(pointId) {
			locateRequest = {
				type: "point",
				pointId: pointId,
			};
			executeLocateRequest();
		}

		function locatePointCloud(fileName) {
			locateRequest = {
				type: "pointCloud",
				fileName: fileName,
			};
			executeLocateRequest();
		}

		function goToLocation(location) {
			if(!environment) {
				$timeout(goToLocation(location), 500);
				return;
			}
			if(environment.isSupported) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						if(isModelLoaded()) {
							doSetLocation(eBrowserControl, location);
						} else {
							const interval = $interval(() => {
								if(isModelLoaded()) {
									doSetLocation(eBrowserControl, location);
									$interval.cancel(interval);
								}
							}, 1001);
						}
					}
				});
			}

			function doSetLocation(eBrowserControl, location) {
				if(location.rotation === "" || location.slope === "") {
					//TODO: Might want to get this information in other than string format.
					// This might be difficult, though.
					getCurrentLocationUrl().then(currentLocationUrl => {
						if(!currentLocationUrl) {
							return;
						}
						const currentRotation = parseInt(
							currentLocationUrl.substring(
								currentLocationUrl.indexOf("&r=") + 3,
								currentLocationUrl.indexOf("&s=")
							),
							0
						);
						const currentSlope = parseInt(
							currentLocationUrl.substring(currentLocationUrl.indexOf("&s=") + 3), 0
						);
						location.rotation = location.rotation || currentRotation;
						location.slope = location.slope || currentSlope;

						eBrowserControl.mthSetCameraLocation(
							location.x, location.y, location.z, location.rotation, location.slope, true
						);
					});
				} else {
					eBrowserControl.mthSetCameraLocation(
						location.x, location.y, location.z, location.rotation, location.slope, true
					);
				}
			}
		}

		function exitMarkupMode(save) {
			if(!environment) {
				$timeout(exitMarkupMode(save), 500);
				return;
			}
			if(environment.isSupported && _.isBoolean(save)) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						if(isModelLoaded()) {
							eBrowserControl.mthExitMarkupMode(save);
						} else {
							const interval = $interval(() => {
								if(isModelLoaded()) {
									eBrowserControl.mthExitMarkupMode(save);
									$interval.cancel(interval);
								}
							}, 1000);
						}
					}
				});
			}
		}

		function examineSearchResults(geometryIds, examineFlags) {
			if(!environment) {
				$timeout(examineSearchResults(geometryIds, examineFlags), 500);
				return;
			}
			if(environment.isSupported) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						geometryIds = geometryIds.toString();
						if(isModelLoaded()) {
							eBrowserControl.mthExamineSearchResults(geometryIds, examineFlags);
						} else {
							const interval = $interval(() => {
								if(isModelLoaded()) {
									eBrowserControl.mthExamineSearchResults(geometryIds, examineFlags);
									$interval.cancel(interval);
								}
							}, 1000);
						}
					}
				});
			}
		}

		function changeVisualStyle(visualStyleName) {
			if(!environment) {
				$timeout(changeVisualStyle(visualStyleName), 500);
				return;
			}
			if(environment.isSupported) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl
						&& Utilities.isString(visualStyleName)
						&& visualStyleName !== "") {
						if(isModelLoaded()) {
							eBrowserControl.mthSetVisualStyle(visualStyleName);
						} else {
							const interval = $interval(() => {
								if(isModelLoaded()) {
									eBrowserControl.mthSetVisualStyle(visualStyleName);
									$interval.cancel(interval);
								}
							}, 1000);
						}
					}
				});
			}
		}

		function changeHierarchy(hierarchyName) {
			if(!environment) {
				return;
			}
			if(environment.isSupported) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl && Utilities.isString(hierarchyName) && hierarchyName !== "") {
						$scope.model.hierarchyBeingChanged = true;
						if(isModelLoaded()) {
							eBrowserControl.ptyCurrentHierarchy = hierarchyName;
						} else {
							const interval = $interval(() => {
								if(isModelLoaded()) {
									eBrowserControl.ptyCurrentHierarchy = hierarchyName;
									$interval.cancel(interval);
								}
							}, 1000);
						}
					}
				});
			}
		}

		function setModelTreeVisibile(visible) {
			if(!environment) {
				return;
			}
			if(environment.isSupported) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						if(visible.toLowerCase() === "show") {
							eBrowserControl.mthSetModelTreeVisibile(true);
						} else if(visible.toLowerCase() === "hide") {
							eBrowserControl.mthSetModelTreeVisibile(false);
						}
					}
				});
			}
		}

		function executeLocateRequest() {
			if(!environment) {
				$timeout(executeLocateRequest(), 500);
				return;
			}
			lastLocateRequest = null;
			lastLocateRequestExecutedAt = null;
			if(locateRequest === null) {
				return;
			}
			if(!environment.isSupported) {
				locateRequest = null;
				return;
			}
			if(!$scope.model.isLoaded) {
				return;
			}
			if($scope.model.hierarchyBeingChanged) {
				const interval = $interval(() => {
					executeLocateRequest();
					$interval.cancel(interval);
				}, 1000);
			}

			$timeout(() => {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(Utilities.isObject(locateRequest)) {
						if(!eBrowserControl) {
							return;
						}
						switch (locateRequest.type) {
						case "geometry":
							if(isModelTimestampMatching(locateRequest.modelTimestamp)) {
								const hexGeometryId = locateRequest.geometryId.toString(16);
								eBrowserControl.mthLocateById(
									hexGeometryId, locateRequest.moveCamera, locateRequest.examineFlags
								);
							} else {
								locateRequest = null;
								$rootScope.$broadcast("eBrowser::ModelOutdated");
							}
							break;
						case "group":
							try {
								if(locateRequest.animate) {
									eBrowserControl.mthLocateGroupWithAnimation(
										locateRequest.parentTag,
										locateRequest.childTag,
										locateRequest.id,
										locateRequest.examineFlags
									);
								} else {
									eBrowserControl.mthLocateGroup(
										locateRequest.parentTag,
										locateRequest.childTag,
										locateRequest.id,
										locateRequest.examineFlags
									);
								}

							} catch (e) {
								// this is "bit of a" hack, doing checks like this here and also showing
								// UI here... but the whole mechanism should be changed to make some sense
								// out of it; locating groups should basically work exactly the same way
								// locating objects: first we should ask from server, then we should either
								// use the key we got to find the item in 3d, or display error message...
								// in current situation we don't do the server roundtrip at all, thus we
								// cannot show details for groups either... still waiting for the actual
								// group implementation MIY 2015-07-07
								if(e.number === E_INVALIDARG) { // group not found
									exitExamineMode();
									showMessage(
										"Group not found in 3D model",
										"There are no 3D objects to display for the requested group",
										"<i class='fa fa-check-circle-o fa-fw'></i> OK"
									);
								} else {
									throw e;
								}
							}
							break;
						case "point":
							locatePointById(locateRequest.pointId);
							break;
						case "pointCloud":
							eBrowserControl.mthOpenPanoramaPointCloud(locateRequest.fileName);
							break;
						default:
							break;
						}
					}
					if(locateRequest !== null) {
						lastLocateRequest = _.clone(locateRequest);
						lastLocateRequestExecutedAt = new Date();
						locateRequest = null;
					}
				});
			}, 0);
		}

		function locatePointById(pointId) {
			if(!Utilities.isNonEmptyGuid(projectId)) {
				return;
			}
			pointOfInterestKindRepository.locatePointById(projectId, pointId).then(
				response => {
					if(response.pointId !== pointId) {
						return;
					}
					const camX = response.x - 600;
					const camY = response.y - 600;
					const camZ = response.z + 600;
					const rotationAngle = 45;
					const slopeAngle = 35;
					const refresh = 1;
					getEBrowserControlAsync().then(eBrowserControl => {
						if(eBrowserControl) {
							eBrowserControl.mthSetCameraLocation(
								camX, camY, camZ, rotationAngle, slopeAngle, refresh
							);
						}
					});
				}
			);
		}

		function exitExamineMode() {
			if(!environment) {
				$timeout(exitExamineMode(), 500);
				return;
			}
			if(environment.isSupported) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						eBrowserControl.mthExitExamineMode();
					}
				});
			}
		}

		function addPointTo3D(
			id, name, externalId, status, kindName, color, iconUrl, xCoord, yCoord, zCoord
		) {
			if(!environment) {
				return $timeout(
					addPointTo3D(
						id, name, externalId, status, kindName, color, iconUrl, xCoord, yCoord, zCoord
					), 500
				);
			}
			if(environment.isSupported) {
				return getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						if(status === null) {
							status = "";
						}
						return eBrowserControl.mthAddPointTo3D(
							id, name, externalId, status, kindName, color, iconUrl, xCoord, yCoord, zCoord
						);
					}
					return null;
				});
			}
			return $q.resolve(null);
		}

		function deletePointFrom3D(id) {
			if(!environment) {
				return $timeout(deletePointFrom3D(id), 500);
			}
			if(environment.isSupported) {
				return getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						return eBrowserControl.mthDeletePointFrom3D(id);
					}
					return null;
				});
			}
			return $q.resolve(null);
		}

		function updateObjectStatus(
			attributeAbbreviation, attributeValue, trackingName, state, color
		) {
			if(!environment) {
				$timeout(updateObjectStatus(
					attributeAbbreviation, attributeValue, trackingName, state, color
				), 500);
				return;
			}
			if(environment.isSupported) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						eBrowserControl.mthUpdateObjectStatus(
							attributeAbbreviation, attributeValue, trackingName, state, color
						);
					}
				});
			}
		}

		function updateObjectStatusWithIds(objectIds, kindName, stateName, stateColor){
			if(!environment) {
				$timeout(updateObjectStatusWithIds(
					objectIds, kindName, stateName, stateColor
				), 500);
				return;
			}
			if(environment.isSupported) {
				getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						objectIds = objectIds.toString();
						eBrowserControl.mthUpdateObjectStatusWithIds(
							objectIds, kindName, stateName, stateColor
						);
					}
				});
			}
		}

		function getModelTimestamp() {
			if(!environment) {
				return $timeout(() => {
					return getModelTimestamp();
				}, 1001);
			}
			if((environment.isSupported && !isModelLoaded())) {
				return $q.resolve("");
			}
			return getEBrowserControlAsync().then(eBrowserControl => {
				if(environment.isSupported && eBrowserControl) {
					return eBrowserControl.ptyModelTimeStamp;
				} else {
					return $q.resolve("");
				}
			});
		}

		function isModelTimestampMatching(timestamp) {
			return getModelTimestamp().then(loadedModelTimestamp => {
				if(loadedModelTimestamp === "") {
					return true;
				}
				const timestampDiff = Math.abs(
					(new Date(loadedModelTimestamp)).getTime() - (new Date(timestamp)).getTime()
				);
				return timestampDiff < 3000;
			});
		}

		function print3D() {
			if(!environment) {
				return $timeout(print3D(), 500);
			}
			if(environment.isSupported) {
				return getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						const encodedImage = eBrowserControl.mthGetBase64EncodedPngImageFor3D(1024, 768);
						return encodedImage;
					}
					return null;
				});
			}
			return $q.resolve(null);
		}

		function getCurrentLocationUrl() {
			if(!environment) {
				return $timeout(getCurrentLocationUrl(), 500);
			}
			if(environment.isSupported) {
				return getEBrowserControlAsync().then(eBrowserControl => {
					if(eBrowserControl) {
						return eBrowserControl.mthGetCameraLocationUrl();
					}
					return null;
				});
			}
			return $q.resolve(null);
		}

		function openMarkup(ebxFileContents, allowEditing) {
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl) {
					if(allowEditing) {
						eBrowserControl.mthMarkupEdit(ebxFileContents);
					} else {
						eBrowserControl.mthMarkupView(ebxFileContents);
					}
				}
			});
		}

		function isMarkupEditorDirty() {
			return getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl) {
					return wrapInAsync(eBrowserControl.mthIsMarkupEditorDirty()).then(result => {
						return result;
					});
				}
				return false;
			});

		}

		function cancelEditMarkup() { // Not used?
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl) {
					console.log("EBROWSER NEEDS TO CANCEL MARKUP EDITOR");
					// eBrowserControl.mthMarkupEditCancel();
				}
			});
		}

		function onDragStart(/*data*/) {
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl && $scope.hasExternalControl) {
					eBrowserControl.onDragStart();
				}
			});
		}

		function onDrag(/*data*/) {
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl && $scope.hasExternalControl) {
					eBrowserControl.onDrag();
				}
			});
		}

		function onDragEnd(/*data*/) {
			getEBrowserControlAsync().then(eBrowserControl => {
				if(eBrowserControl && $scope.hasExternalControl) {
					eBrowserControl.onDragEnd();
				}
			});
		}

		function wrapInAsync(obj) {
			return $q.resolve(obj);
		}

		if(window.chrome && window.chrome.webview && window.chrome.webview.addEventListener) {
			window.chrome.webview.addEventListener("message", windowEventListener, false);
		} else {
			Utilities.safelyAddEventListener("message", "onmessage", windowEventListener);
		}

		$scope.$on("eShare::projectChanged", async (event, projectInfo) => {
			locateRequest = null;
			await loadModel(projectInfo.project);
		});

		eBrowser.registerController({
			isSupported: isSupported,
			isModelLoaded: isModelLoaded,
			reloadModel: reloadModel,
			showMessage: showMessage,
			showImportantMessage: showImportantMessage,
			hideMessage: hideMessage,
			locateByGeometryId: locateByGeometryId,
			locateGroup: locateGroup,
			locatePoint: locatePoint,
			locatePointCloud: locatePointCloud,
			goToLocation: goToLocation,
			examineSearchResults: examineSearchResults,
			exitExamineMode: exitExamineMode,
			addPointTo3D: addPointTo3D,
			deletePointFrom3D: deletePointFrom3D,
			getModelTimestamp: getModelTimestamp,
			isModelTimestampMatching: isModelTimestampMatching,
			print3D: print3D,
			getCurrentLocationUrl: getCurrentLocationUrl,
			openMarkup: openMarkup,
			isMarkupEditorDirty: isMarkupEditorDirty,
			cancelEditMarkup: cancelEditMarkup,
			updateObjectStatus: updateObjectStatus,
			updateObjectStatusWithIds: updateObjectStatusWithIds,
			onDragStart: onDragStart,
			onDrag: onDrag,
			onDragEnd: onDragEnd,
			setModelTreeVisibile: setModelTreeVisibile,
			changeHierarchy: changeHierarchy,
			examineBranch: examineBranch,
			exitMarkupMode: exitMarkupMode,
			changeVisualStyle: changeVisualStyle,
		});
	}
]);
