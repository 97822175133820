angular.module("eShareApp").directive("cadCombiningAttributeTransformation", [
	"notification", "attributeTransformationsRepository",
	function (notification, attributeTransformationsRepository) {
		return {
			restrict: "A",
			replace: true,
			scope:
			{
				allAttributes: "=",
				transformation: "=",
				projectId: "=",
				modelId: "=",
				allTransformations: "=",
			},
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadCombiningAttributeTransformation.html'),
			controller: [
				"$scope", "$rootScope",
				function ($scope, $rootScope) {
					$scope.testResults = {
						sourceDefinitions: [],
						targetDefinitions: [],
						matchingResults: [],
						nonMatching: [],
					};
					$scope.expanded = false;
					$scope.testButtonDisabled = false;
					$scope.isValid = function () {
						if(!$scope.transformation
							|| !$scope.transformation.abbreviation
							|| $scope.transformation.abbreviation.length === 0) {
							return false;
						}
						if(!$scope.transformation.format
							|| $scope.transformation.format.length === 0) {
							return false;
						}
						if(!$scope.transformation.displayName
							|| $scope.transformation.displayName.length === 0) {
							return false;
						}
						return true;
					};

					function validate() {
						if(!$scope.transformation
							|| !$scope.transformation.abbreviation
							|| $scope.transformation.abbreviation.length === 0) {
							notification.error("An abbreviation for the new attribute must be entered");
							return false;
						}
						if(!$scope.transformation.displayName
							|| $scope.transformation.displayName.length === 0) {
							notification.error("A display name for the new attribute must be entered");
							return false;
						}
						if(!$scope.transformation.format
							|| $scope.transformation.format.length === 0) {
							notification.error("A pattern to generate the new attribute must be entered");
							return false;
						}
						return true;
					}

					$scope.getTagClass = function (tag) {
						if(_.some($scope.allAttributes, { displayName: tag })) {
							return "badge badge-info";
						}
						return "badge badge-warning";
					};

					$scope.toggleExpand = function () {
						$scope.expanded = !$scope.expanded;
					};

					function formatTestResults() {
						for(let j = 0; j < $scope.testResults.matchingResults.length; j++) {
							const match = $scope.testResults.matchingResults[j];
							match.inputMap = {};
							match.outputMap = {};
							for(let i = 0; i < match.inputValues.length; i++) {
								match.inputMap[match.inputValues[i].abbreviation] =
									match.inputValues[i].value;
							}
							for(let i = 0; i < match.outputValues.length; i++) {
								match.outputMap[match.outputValues[i].abbreviation] =
									match.outputValues[i].value;
							}
						}
					}

					$scope.tryPattern = function () {
						if(!validate()) {
							return;
						}
						$scope.testButtonDisabled = true;
						const index = _.indexOf($scope.allTransformations, $scope.transformation);
						attributeTransformationsRepository.test(
							$scope.projectId,
							$scope.modelId,
							$scope.allTransformations,
							index
						).then(data => {
							$scope.testResults = data;
							formatTestResults();
							$scope.expanded = true;
							$scope.testButtonDisabled = false;
						}, error => {
							notification.error("Error testing pattern: " + error);
							$scope.testButtonDisabled = false;
						});
					};

					$scope.deleteTransformation = function () {
						$rootScope.$broadcast("attributeTransformation:delete", $scope.transformation);
					};
				}
			],
		};
	}
]);