angular.module("eShareApp").service("l10n", [L10N]);

function L10N() {
	this.formats = {};
	this.formats.datepickerDate = "yyyy-mm-dd";
	this.formats.momentDate = "YYYY-MM-DD";
	this.formats.momentTimestamp = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]";

	this.regexp = {};
	this.regexp.date = new RegExp("^" + this.formats.momentDate.replace(/[^-]/g, "\\d") + "$");

	this.isValidDateString = isValidDateString;
	this.parseDate = parseDate;
	this.dateToTimestamp = dateToTimestamp;
	this.formatDate = formatDate;
	this.dateToText = dateToText;
	this.textToDate = textToDate;
	this.dateToLocalText = dateToLocalText;
	this.textToLocalDate = textToLocalDate;

	const self = this;

	function isValidDateString(dateString) {
		return angular.isString(dateString)
			&& self.regexp.date.test(dateString)
			&& moment(dateString, self.formats.momentDate).isValid();
	}

	function parseDate(dateString) {
		return isValidDateString(dateString)
			? moment(dateString, self.formats.momentDate).toDate()
			: undefined;
	}

	function dateToTimestamp(date) {
		if(angular.isString(date)) {
			date = parseDate(date);
		}
		if(!angular.isDate(date)) {
			return undefined;
		}
		const momentDate = moment(date);
		if(!momentDate.isValid()) {
			return undefined;
		}
		return momentDate.utc().format(self.formats.momentTimestamp);
	}

	function formatDate(date) {
		if(!angular.isDefined(date) || !angular.isDate(date)) {
			date = new Date();
		}
		let momentDate = moment(date);
		if(!momentDate.isValid()) {
			momentDate = moment();
		}
		return momentDate.format(self.formats.momentDate);
	}

	function dateToText(date) {
		return (date || new Date()).toISOString().substring(0, 10);
	}

	function dateToLocalText(date) {
		date = (date || new Date());
		return moment(date).format().substring(0, 10);
	}

	function textToDate(text) {
		if(!text) {
			return new Date();
		}
		const date = new Date(text);
		return isNaN(date.getTime()) ? new Date() : date;
	}

	function textToLocalDate(text) {
		if(!text) {
			return new Date();
		}
		const date = moment(text).toDate();
		return isNaN(date.getTime()) ? new Date() : date;
	}
}
