angular.module("eShareApp").controller("HistoricalDataCtrl", [
	"$rootScope", "$state", "$scope", "historicalDataRepository", "l10n", "notification",

	function (
		$rootScope, $state, $scope, historicalDataRepository, l10n, notification
	) {

		$scope.timeSeries = [];
		$scope.projectId = $state.params.projectId;
		$scope.datasourceId = $state.params.datasourceId;
		$scope.eShareKey = angular.fromJson($state.params.eShareKey);
		$scope.displayName = $scope.eShareKey.keyType === "PointOfInterest"
			? $scope.eShareKey.externalId
			: $state.params.displayName;
		$scope.startDate = getToday();
		$scope.endDate = getToday();
		$scope.showChart = true;
		$scope.showCustom = false;
		$scope.datepickerFormat = "yyyy-MM-dd";
		$scope.datepickerOptions = {
			startingDay: 1,
		};
		if(!$scope.eShareKey) {
			throw "Invalid eShareKey parameter";
		}

		$scope.predefinedTimes = [
			{ label: "1 hour", value: "1hr", id: "chartPeriod1h" },
			{ label: "8 hours", value: "8hr", id: "chartPeriod8h" },
			{ label: "1 day", value: "1day", id: "chartPeriod1d" },
			{ label: "1 week", value: "1week", id: "chartPeriod1w" },
			{ label: "1 month", value: "1month", id: "chartPeriod1M" },
			{ label: "Custom", value: "custom", id: "chartPeriodCustom" }
		];

		$scope.refreshData = function (duration) {
			if(duration === "custom") {
				$scope.showCustom = true;
				return;
			}
			$scope.showCustom = false;
			$scope.showChart = false;
			const historyIdentifier = {
				eShareKey: $scope.eShareKey,
				historyDepth: duration,
				pointCount: $("#line").width() / 2,
			};
			historicalDataRepository
				.getHistory($scope.projectId, $scope.datasourceId, historyIdentifier)
				.then(historicalData => {
					$scope.timeSeries = historicalData;
					$scope.showChart = true;
				}, error => {
					$scope.showChart = true;
					notification.error(error);
				});
		};

		$scope.selectedTime = "8hr";
		$scope.refreshData($scope.selectedTime);

		$scope.refreshDataWithTimespan = function () {
			const historyIdentifier = {
				eShareKey: $scope.eShareKey,
				historyDepth: "custom",
				startTime: l10n.dateToText($scope.startDate) + "T00:00:00.000",
				endTime: l10n.dateToText($scope.endDate) + "T23:59:59.999",
				pointCount: $("#line").width() / 2,
			};
			$scope.showChart = false;
			historicalDataRepository
				.getHistory($scope.projectId, $scope.datasourceId, historyIdentifier)
				.then(historicalData => {
					$scope.timeSeries = historicalData;
					$scope.showChart = true;
				}, error => {
					$scope.showChart = true;
					notification.error(error);
				});
		};

		$scope.loadDetailData = function (startTime, endTime, pointCount) {
			const historyIdentifier = {
				eShareKey: $scope.eShareKey,
				historyDepth: "custom",
				startTime: startTime,
				endTime: endTime,
				pointCount: pointCount,

			};
			return historicalDataRepository.getHistory(
				$scope.projectId, $scope.datasourceId, historyIdentifier
			);
		};

		$scope.customDatesAreValid = function () {
			return $scope.startDate && $scope.endDate && $scope.startDate <= $scope.endDate;
		};

		function getToday() {
			return l10n.textToDate(moment().format("YYYY-MM-DD"));
		}
	}
]);
