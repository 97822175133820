angular
	.module("eShareApp")
	.controller("PointOfInterestKindExportImportCtrl", PointOfInterestKindExportImportCtrl);

PointOfInterestKindExportImportCtrl.$inject = [
	"$state", "notification", "isExport", "isImport", "project", "pointOfInterestKindRepository"
];

function PointOfInterestKindExportImportCtrl(
	$state, notification, isExport, isImport, project, pointOfInterestKindRepository
) {
	const vm = this;
	vm.isExport = isExport;
	vm.isImport = isImport;
	vm.serializedExportedData = "";
	vm.importResult = null;
	vm.importAll = importAll;
	vm.isInputValid = isInputValid;
	vm.importMarkup = false;

	initialize();

	function initialize() {
		if(isExport) {
			vm.serializedExportedData = "Please wait...";
			pointOfInterestKindRepository.exportAll(project.id).then(
				serializedExportedData => {
					vm.serializedExportedData = serializedExportedData;
				}
			);
		}
	}

	function importAll() {
		vm.importResult = null;
		pointOfInterestKindRepository
			.importAll(project.id, vm.serializedExportedData, vm.importMarkup)
			.then(importResult => {
				if(importResult.hasSucceeded
						&& (!importResult.warnings || importResult.warnings.length === 0)) {
					notification.success("Imported smart point types");
					$state.go("^", {}, { reload: true });
				} else {
					if(importResult.hasSucceeded) {
						notification.success("Imported smart point types, with warnings");
					}
					vm.importResult = importResult;
				}
			});
	}

	function isInputValid() {
		return Utilities.isJsonObject(vm.serializedExportedData);
	}
}
