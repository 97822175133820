angular.module("eShareApp").factory("adapterOAuthService", adapterOAuthService);
adapterOAuthService.$inject = ["repositoryBase"];

function adapterOAuthService(repositoryBase) {
	const baseApi = "/api/project/{projectId}/adapterOAuth/";
	const registerApi = baseApi + "register";

	async function registerNewStateCode(projectId){
		const result = await repositoryBase.post(
			registerApi,
			{ projectId: projectId }
		);
		return result;
	}
	return {
		registerNewStateCode: registerNewStateCode,
	};
}
