angular.module("eShareApp").controller("ProjectListCtrl", [
	"$scope", "$state", "messageBox", "projects", "projectsRepository", "permissionsRepository",
	"notification", "projectActivityRepository",

	function (
		$scope, $state, messageBox, projects, projectsRepository, permissionsRepository,
		notification, projectActivityRepository
	) {

		$scope.projects = projects;
		$scope.isSysAdmin = window.eShare.currentUser.role === "sysadmin";
		$scope.getProjectNameById = getProjectNameById;
		$scope.removeProject = removeProject;
		$scope.isServerBusy = false;

		projectActivityRepository.getProjectActivity().then(data => {
			$scope.projectDisabled = data;
		});

		$scope.projectsContainImages = _.some(projects, project => {
			return project.containsImage;
		});
		_.forEach(projects, project => {
			if(project.containsImage) {
				projectsRepository.getProjectImage(project.id).then(response => {
					project.imageBase64 = response;
				});
			}
		});

		$scope.toggleProjectActivity = function (project, value) {
			const stateToBe = value ? "Disabled" : "Enabled";
			const mbox = messageBox.openQuestion(
				"Toggle project activity",
				"Do you really want to set the project \"" + project.name + "\" as " + stateToBe + "?",
				"Confirm", "Cancel"
			);
			mbox.result.then(
				() => {
					$scope.isServerBusy = true;
					projectActivityRepository.setProjectActivity(project.id, value).then(data => {
						$scope.projectDisabled = data;
						$scope.isServerBusy = false;
						refreshProjects();
					}, error => {
						notification.error(error);
						$scope.isServerBusy = false;
						refreshProjects();
					});
				}
			);
		};

		function updateProject(project) {
			$scope.isServerBusy = true;
			projectsRepository.update(project).then(() => {
				$scope.isServerBusy = false;
				refreshProjects();
			});
		}

		$scope.toggleProject64BitOnly = function (project) {
			const stateToBe = project.is64BitOnly ? "32-bit" : "64-bit";
			const mbox = messageBox.openQuestion(
				"Toggle project properties",
				"Do you really want to change the project " + project.name + " to " + stateToBe + "?",
				"Confirm", "Cancel"
			);
			mbox.result.then(() => {
				project.is64BitOnly = !project.is64BitOnly;
				updateProject(project);
			});
		};

		$scope.toggleCanBeSourceProject = function (project) {
			const stateToBe = project.canBeSourceProject ? "prohibit" : "allow";
			const mbox = messageBox.openQuestion(
				"Toggle project properties",
				"Do you really want to " + stateToBe + " the project " + project.name
				+ " to be a template for other projects?",
				"Confirm", "Cancel"
			);
			mbox.result.then(() => {
				project.canBeSourceProject = !project.canBeSourceProject;
				updateProject(project);
			});
		};

		function removeProject(project) {
			if(!project) {
				return;
			}
			const mbox = messageBox.openQuestion(
				"Remove selected project?",
				"Do you really want to remove project " + project.name + "?",
				"Remove", "Cancel"
			);
			mbox.result.then(() => {
				$scope.project = null;
				if($scope.projectDisabled[project.id]) {
					projectActivityRepository.setProjectActivityShort(project.id, false).then(() => {
						projectsRepository.remove(project)
							.catch(error => {
								$scope.project = project;
								notification.error(error);
							})
							.finally(refreshProjects);
					}, error => {
						notification.error(error);
						refreshProjects();
					});
				} else {
					projectsRepository.remove(project)
						.catch(error => {
							$scope.project = project;
							notification.error(error);
						})
						.finally(refreshProjects);
				}
			});
		}

		function filterProjects() {
			if($scope.isSysAdmin) {
				return;
			}
			permissionsRepository.getForUser().then(userPermissions => {
				$scope.projects = _.filter($scope.projects, project => {
					return _.some(userPermissions, permission => {
						return permission.projectId === project.id && permission.isAdmin;
					});
				});
			}, error => {
				notification.error(error);
			});
		}

		function refreshProjects() {
			projectsRepository.getAll()
				.then(allProjects => {
					if($scope.projectsContainImages) {
						_.forEach(allProjects, project => {
							const oldProject = _.find($scope.projects, p => {
								return p.id === project.id;
							});
							if(oldProject) {
								project.imageBase64 = oldProject.imageBase64;
							}
						});
					}
					$scope.projects = allProjects;
					filterProjects();
				}, error => {
					notification.error(error);
				});
			projectActivityRepository.getProjectActivity().then(data => {
				$scope.projectDisabled = data;
			});
		}

		function getProjectNameById(projectId) {
			const project = Utilities.isNonEmptyGuid(projectId)
				? _.find($scope.projects, { id: projectId })
				: null;
			return project ? (project.name || "") : "";
		}

		$scope.hoverIn = function (project) {
			project.isHovered = true;
		};

		$scope.hoverOut = function (project) {
			project.isHovered = false;
		};

		$scope.togglePopup = function (projectId) {
			const popup = document.getElementById("projectImagePopup" + projectId);
			popup.classList.toggle("show");
		};

		filterProjects();

	}
]);
