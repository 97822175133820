angular.module("eShareApp").factory("permissionsRepository", permissionsRepository);

permissionsRepository.$inject = ["repositoryBase"];

function permissionsRepository(repositoryBase) {
	const baseApi = "/api";
	const permissionsApi = baseApi + "/permissions";
	const groupPermissionsApi = baseApi + "/groupPermissions";
	const permissionIdApi = permissionsApi + "/{permissionId}";
	const groupPermissionIdApi = groupPermissionsApi + "/{permissionId}";
	const projectPermissionsApi = permissionsApi + "/project/{projectId}";
	const canUserAdministerProjectApi = projectPermissionsApi + "/canUserAdminister";
	const canUserHandleMarkupsApi = projectPermissionsApi + "/canUserHandleMarkups";
	const projectGroupPermissionsApi = groupPermissionsApi + "/projectGroups/{projectId}";
	const userPermissionsApi = baseApi + "/userPermissions";
	const forDataSourceApi = baseApi
		+ "/project/{projectId}/permissions/forDataSource/{dataSourceId}";
	const forAttributeCategorizationApi = baseApi
		+ "/project/{projectId}/permissions/forAttributeCategorization/{categorizationId}";
	const forPointOfInterestKindApi = baseApi
		+ "/project/{projectId}/permissions/forPointOfInterestKind/{pointOfInterestKindsId}";

	return {
		getByProject: getByProject,
		add: add,
		addGroup: addGroup,
		update: update,
		updateForGroup: updateForGroup,
		remove: remove,
		removeForGroup: removeForGroup,
		getForUser: getForUser,
		canUserAdministerProject: canUserAdministerProject,
		canUserHandleMarkups: canUserHandleMarkups,
		getForDataSource: getForDataSource,
		getByProjectForGroups: getByProjectForGroups,
		getForAttributeCategorization: getForAttributeCategorization,
		getForPointOfInterestKind: getForPointOfInterestKind,
	};

	function getByProjectForGroups(projectId) {
		return repositoryBase.get(projectGroupPermissionsApi, { projectId: projectId });
	}

	function getByProject(projectId) {
		return repositoryBase.get(projectPermissionsApi, { projectId: projectId });
	}

	function getForUser() {
		return repositoryBase.get(userPermissionsApi, {});
	}

	function getForDataSource(projectId, dataSourceId) {
		return repositoryBase.get(
			forDataSourceApi,
			{ projectId: projectId, dataSourceId: dataSourceId }
		);
	}

	function getForAttributeCategorization(projectId, categorizationId) {
		return repositoryBase.get(
			forAttributeCategorizationApi,
			{ projectId: projectId, categorizationId: categorizationId }
		);
	}

	function getForPointOfInterestKind(projectId, pointOfInterestKindId) {
		return repositoryBase.get(
			forPointOfInterestKindApi,
			{ projectId: projectId, pointOfInterestKindId: pointOfInterestKindId }
		);
	}

	function canUserAdministerProject(projectId) {
		return repositoryBase.get(canUserAdministerProjectApi, { projectId: projectId })
			.then(data => {
				if(data.isProjectUser === true) {
					return data.isProjectAdmin === true;
				}
				throw "Access to project denied";
			});
	}

	function canUserHandleMarkups(projectId) {
		return repositoryBase.get(canUserHandleMarkupsApi, { projectId: projectId });
	}

	function add(permission) {
		return repositoryBase.post(permissionsApi, {}, permission);
	}

	function addGroup(permission) {
		return repositoryBase.post(groupPermissionsApi, {}, permission);
	}

	function update(permission) {
		return repositoryBase.put(permissionIdApi, { permissionId: permission.id }, permission);
	}

	function updateForGroup(permission) {
		return repositoryBase.put(groupPermissionIdApi, { permissionId: permission.id }, permission);
	}

	function remove(permission) {
		return repositoryBase.remove(permissionIdApi, { permissionId: permission.id });
	}

	function removeForGroup(permission) {
		return repositoryBase.remove(groupPermissionIdApi, { permissionId: permission.id });
	}
}
