angular.module("eShareApp").controller("PoiKindAdminCtrl", PoiKindAdminCtrl);

PoiKindAdminCtrl.$inject = [
	"$state", "$scope", "messageBox", "project", "notification", "pointOfInterestKindRepository",
	"colorService"
];

function PoiKindAdminCtrl(
	$state, $scope, messageBox, project, notification, pointOfInterestKindRepository,
	colorService
) {
	const vm = $scope;
	vm.project = project;
	vm.pointOfInterestKinds = [];
	vm.markupKinds = [];
	vm.addPointOfInterestKind = addPointOfInterestKind;
	vm.removePointOfInterestKind = removePointOfInterestKind;
	vm.addMarkupKind = addMarkupKind;
	vm.getColorName = getColorName;

	updatePointOfInterestKinds();

	colorService.getProjectColors(vm.project.id).then(colors => {
		vm.colors = colors;
	});

	function addPointOfInterestKind() {
		$state.go(".editPointOfInterestKind", {
			isMarkup: false,
			pointOfInterestKindId: -1,
			projectId: project.id,
		});
	}

	function addMarkupKind() {
		$state.go(".editPointOfInterestKind", {
			isMarkup: true,
			pointOfInterestKindId: -1,
			projectId: project.id,
		});
	}

	function removePointOfInterestKind(kind) {
		if(!kind.pointCount || kind.pointCount === 0) {
			const mbox = messageBox.openQuestion(
				"Remove selected smart point type?",
				"Do you really want to remove the smart point type <span class='emphasized'>"
				+ kind.name + "</span>?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					pointOfInterestKindRepository.remove(project.id, kind.id)
						.then(
							updatePointOfInterestKinds,
							error => {
								notification.error(error);
								updatePointOfInterestKinds();
							}
						);
				}
			);
		} else {
			const mbox2 = messageBox.openQuestion(
				"Remove selected smart point type?",
				"<p>Do you really want to remove the smart point type "
				+ "<span class='emphasized'>" + kind.name + "</span>?</p>"
				+ "<p class='text-danger'><span class='emphasized'>WARNING: There are "
				+ kind.pointCount + " Smart Point(s) of this type in use!</span>"
				+ " They will be deleted, and this cannot be undone.</p>",
				"Remove", "Cancel"
			);
			mbox2.result.then(
				() => {
					pointOfInterestKindRepository.remove(project.id, kind.id)
						.then(
							updatePointOfInterestKinds,
							error => {
								notification.error(error);
								updatePointOfInterestKinds();
							}
						);
				}
			);
		}
	}

	function updatePointOfInterestKinds() {
		pointOfInterestKindRepository.getAll(vm.project.id).then(
			data => {
				vm.pointOfInterestKinds = _.filter(data, { "isMarkup": false });
				vm.markupKinds = _.filter(data, { "isMarkup": true });
			},
			notification.error
		);
	}

	function getColorName(color) {
		const obj = _.find(vm.colors, { "color": color.trim() });
		if(obj == null || !obj.name) {
			return color;
		} else {
			return obj.name;
		}
	}
}
