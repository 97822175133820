angular.module("eShareApp").factory("attributesService", [
	"$http", "$q", "dataSourceRepository", "notification",

	function (
		$http, $q, dataSourceRepository, notification
	) {

		const baseApi = "/api/project/{projectId}";
		const attributesApi = baseApi + "/ObjectAttributes/GetAttributes/{dataSourceId}";

		function getAttributes(requestId, projectId, attributesSink, errorSink, keys, requestDepth) {
			if(Utilities.isNullOrUndefined(requestDepth)) {
				requestDepth = 1;
			}
			dataSourceRepository.getAvailableDataSourcesForKeyTypes(projectId, keys)
				.then(dataSources => {
					dataSources = _.filter(dataSources, { category: "AI" });
					_.forEach(dataSources, dataSource => {
						dataSource.keys = _.map(dataSource.keys, key => {
							return JSON.parse(key);
						});
						const dataSourceKeys = getKeysForDataSource(keys, dataSource);
						_.forEach(dataSourceKeys, key => {
							getAttributesFromDataSource(
								requestId,
								projectId,
								dataSource.id,
								attributesSink,
								errorSink,
								key,
								requestDepth
							);
						});
					});
				}, error => {
					notification.error(error);
				});
		}

		function getKeysForDataSource(keys, dataSource) {
			const result = [];

			_.forEach(dataSource.keys, dsKey => {
				if(dsKey.keyType === "Tag") {
					_.forEach(dsKey.tags, dsTag => {
						const match = _.find(keys, key => {
							return dsKey.keyType === key.keyType
									&& _.some(key.tags, tag => {
										return tag.attribute === dsTag.attribute;
									});
						});
						const matchingTag = (match === undefined || match === null)
							? undefined
							: _.find(match.tags, tag => {
								return tag.attribute === dsTag.attribute;
							});
						dsTag.value = !matchingTag ? undefined : matchingTag.value;
						dsTag.isKey = !matchingTag ? undefined : matchingTag.isKey;
						dsTag.isGroup = !matchingTag ? undefined : matchingTag.isGroup;
						dsTag.guidId = !matchingTag ? undefined : matchingTag.guidId;
					});
					if(_.every(dsKey.tags, dsTag => {
						return dsTag.value !== undefined;
					})) {
						result.push(dsKey);
					}
				}
			});

			_.forEach(_.filter(keys, key => {
				return _.some(dataSource.keys, dsKey => {
					if(dsKey.keyType === "Tag") {
						return false;
					}
					return dsKey.keyType === key.keyType
							&& _.every(dsKey.tags, dsTag => {
								return _.some(key.tags, tag => {
									return tag.attribute === dsTag.attribute;
								});
							});
				});
			}), key => {
				result.push(key);
			});
			return result;
		}

		function getModelAttributes(projectId, key) {
			const uri = attributesApi
				.replace("{projectId}", projectId)
				.replace("{dataSourceId}", projectId);
			return $http.post(uri, { requestId: 0, key: key });
		}

		function getAttributesFromDataSource(
			requestId, projectId, dataSourceId, attributesSink, errorSink, key, requestDepth
		) {
			const uri = attributesApi
				.replace("{projectId}", projectId)
				.replace("{dataSourceId}", dataSourceId);
			$http
				.post(uri, {
					requestId: requestId,
					key: key,
				})
				.then(data => {
					const response = data.data;
					response.key = _.cloneDeep(key);
					processAttributes(
						requestId, projectId, response, requestDepth, attributesSink, errorSink
					);
				},
				errorSink);
		}

		function processAttributes(
			requestId, projectId, response, requestDepth, attributesSink, errorSink
		) {
			normalizeAttributes(response);
			const fetchMoreAttributes = attributesSink(response, requestDepth);
			const keys = [];
			if(fetchMoreAttributes) {
				_.forEach(response.attributes, attribute => {
					if(attribute.eShareKey !== null
							&& attribute.eShareKey !== ""
							&& !attribute.isFromGroup) {
						if(attribute.eShareKey.keyType !== response.key.keyType
								|| !Utilities.areEqual(attribute.eShareKey, response.key)) {
							keys.push(attribute.eShareKey);
						}
					}
				});
				getAttributes(requestId, projectId, attributesSink, errorSink, keys, requestDepth + 1);
			}
		}

		function normalizeAttributes(response) {
			if(!Utilities.isObject(response.attributes)) {
				response.attributes = {
					name: "",
					rows: [],
				};
			} else {
				response.attributes = Utilities.inflateDataTable(response.attributes);
			}
			const attributes = response.attributes.rows;
			_.forEach(attributes, attribute => {
				attribute.isKey = Utilities.toBool(attribute.isKey);
				attribute.isGroup = Utilities.toBool(attribute.isGroup);
				attribute.isHidden = Utilities.toBool(attribute.isHidden);
				if(attribute.eShareKey !== null && attribute.eShareKey !== "") {
					attribute.eShareKey = angular.fromJson(attribute.eShareKey);
				}
				attribute.displayPriority = typeof attribute.displayPriority === "undefined"
					? 0
					: parseInt(attribute.displayPriority, 10);

				if(!attribute.index) {
					if(!attribute.displayName) {
						attribute.listIndex = "C";
					} else {
						// Create sortable string from display priority integer
						let priorityNumber;
						let prefix;
						if(attribute.displayPriority < 0) {
							// Negative integers start with 0
							prefix = "0";
							// Negative numbers must be made positive for sorting to work
							priorityNumber = 2147483647 + attribute.displayPriority;
						} else {
							// Positive integers start with 1
							prefix = "1";
							priorityNumber = attribute.displayPriority;
						}
						let priorityString = priorityNumber.toString();
						// String should be fixed length 10 so that "11" < "2" situations don't apply
						while(priorityString.length < 10) {
							priorityString = "0" + priorityString;
						}
						attribute.listIndex = "B" + prefix + priorityString + attribute.displayName;
					}
				} else {
					attribute.listIndex = "A" + attribute.index;
				}
			});
			response.attributes = attributes;
		}

		return {
			getAttributes: getAttributes,
			getAttributesFromDataSource: getAttributesFromDataSource,
			getModelAttributes: getModelAttributes,
		};
	}
]);
