angular.module("eShareApp").controller("PointCloudsAdminCtrl", PointCloudsAdminCtrl);

PointCloudsAdminCtrl.$inject = [
	"$state", "$scope", "project", "pointCloudsRepository", "backgroundProgressesRepository",
	"pointClouds", "$interval", "pointCloudMessages", "Upload", "notification", "$http",
	"messageBox", "$timeout", "pointCloudFolder", "pointCloudSettings", "pointCloudsUploadService",
	"groups"
];

function PointCloudsAdminCtrl(
	$state, $scope, project, pointCloudsRepository, backgroundProgressesRepository,
	pointClouds, $interval, pointCloudMessages, upload, notification, $http,
	messageBox, $timeout, pointCloudFolder, pointCloudSettings, pointCloudsUploadService,
	groups
) {
	const vm = $scope;
	vm.pointCloudFolder = pointCloudFolder.isLocalConnection ? pointCloudFolder.folder : "";
	vm.shouldShowCleanupPane = pointCloudSettings.isManualMode;
	vm.folder = {
		totalSize: pointCloudFolder.totalSize,
		sourceFilesSize: pointCloudFolder.sourceFilesSize,
	};
	vm.deletingDictionary = {};
	vm.project = project;
	vm.clouds = pointClouds.panoramicPointClouds;
	vm.isEshareApp = !!(window.chrome
		&& window.chrome.webview
		&& window.chrome.webview.hostObjects
		&& window.chrome.webview.hostObjects.eBrowserControl);
	const uploadService = pointCloudsUploadService.forProject(project);
	vm.uploadService = uploadService;
	processMessages(pointCloudMessages, vm.clouds);
	updatePointClouds(vm.clouds);
	vm.settings = angular.copy(pointCloudSettings);
	vm.groups = groups;

	const refreshInterval = $interval(() => {
		if($state.current.name === "project.admin.pointClouds") {
			pointCloudsRepository.getPointCloudFolder(project.id).then(folder => {
				vm.folder.totalSize = folder.totalSize;
				vm.folder.sourceFilesSize = folder.sourceFilesSize;
			});
			pointCloudsRepository.getPointClouds(project.id).then(result => {
				//processMessages(vm.cloudMessages);
				updatePointClouds(result.panoramicPointClouds);
			});
		} else {
			$interval.cancel(refreshInterval);
		}
	}, 5000);
	$scope.$on("$destroy", () => {
		$interval.cancel(refreshInterval);
		vm.cancelUpload = true;
	});

	function updatePointClouds(clouds) {
		return backgroundProgressesRepository
			.getTargetBackgroundMessages(project.id, "PointCloud")
			.then(messages => {
				return processMessages(messages, clouds);
			});
	}

	$scope.localizeDate = function (utcDateTimeString) {
		const date = moment.utc(utcDateTimeString);
		return date.isValid() ? date.local().format("LLL") : utcDateTimeString;
	};

	vm.openFolder = function () {
		try {
			window.open(
				"file:///C:/ProgramData/Cadmatic/eShare/pointCloud/{projectId}"
					.supplant({ projectId: project.id })
			);
		} catch(e) {
			// do nothing;
		}
	};

	$scope.initResize = function () {
		$timeout(() => {
			$(".table-resizer").bind("mousedown", function () {
				const parent = $(this).parent();
				$("body").bind("mousemove", event => {
					let width = event.pageX - parent.offset().left + 10;
					width = Math.max(width, 120);
					width = Math.min(width, parent.parent().width() / 3);
					parent.css("width", width);
				});
				$("body").bind("mouseup", function (/*event*/) {
					$(this).unbind("mousemove mouseup");
				});
				return false;
			});
		});
	};

	vm.formatFileSize = function (size) {
		if(typeof size === "string") {
			size = parseInt(size);
		}
		if(typeof size !== "number") {
			return "Unknown";
		}
		let unit;
		if(size < 1048576) {
			unit = "KB";
			size /= 1024;
		} else if(size < 1073741824) {
			unit = "MB";
			size /= 1048576;
		} else {
			unit = "GB";
			size /= 1073741824;
		}
		return size.toPrecision(3) + " " + unit;
	};
	vm.removeAllPointClouds = function () {
		const toDelete = [];
		vm.clouds.forEach(cloud => {
			const sourceFileName = cloud.sourceFileName;
			if(!vm.deletingDictionary.hasOwnProperty(sourceFileName)) {
				vm.deletingDictionary[sourceFileName] = true;
				toDelete.push(sourceFileName);
			}
		});
		vm.failedClouds.forEach(cloud => {
			const sourceFileName = cloud.targetId;
			if(!vm.deletingDictionary.hasOwnProperty(sourceFileName)) {
				vm.deletingDictionary[sourceFileName] = true;
				toDelete.push(sourceFileName);
			}
		});
		const mBox = messageBox.openQuestion("Remove All Point Cloud Files?",
			"Are you sure you want to remove <span class=\"emphasized\">all point clouds</span>"
			+ " and their files?<br><span class=\"emphasized\">This cannot be undone.</span>",
			"Remove All",
			"Cancel");
		mBox.result.then(() => {
			toDelete.forEach(sourceFileName => {
				pointCloudsRepository.removePointCloud(project.id, sourceFileName).then(() => {
					// deleting...
				}, e => {
					notification.warning("Removing " + sourceFileName + "returned an error: " + e);
				});
			});
		}, () => {
			toDelete.forEach(sourceFileName => {
				delete vm.deletingDictionary[sourceFileName];
			});
		});

	};
	vm.removePointCloud = function (sourceFileName) {
		if(!vm.deletingDictionary.hasOwnProperty(sourceFileName)) {
			vm.deletingDictionary[sourceFileName] = true;
			const mBox = messageBox.openQuestion("Remove Point Cloud File?",
				"Are you sure you want to remove <span class=\"emphasized\">" + sourceFileName
				+ "</span> and all its point clouds and files?<br>This cannot be undone.",
				"Remove",
				"Cancel");
			mBox.result.then(() => {
				pointCloudsRepository.removePointCloud(project.id, sourceFileName).then(() => {
					// deleting...
				}, e => {
					notification.warning("Removing " + sourceFileName + "returned an error: " + e);
				});
			}, () => {
				delete vm.deletingDictionary[sourceFileName];
			});
		}
	};
	vm.getNumberOfFiles = function () {
		return _.uniqBy(vm.clouds, "sourceFileName").length;
	};
	vm.cleanupFolder = function () {
		const mbox = messageBox.openQuestion("Delete Original Point Cloud Files?",
			"Are you sure you want to permanently delete all original point cloud files?"
			+ " This will free up " + vm.formatFileSize(vm.folder.sourceFilesSize)
					+ " of space on the server, but cannot be undone.",
			"Yes", "Cancel");
		mbox.result.then(() => {
			pointCloudsRepository.cleanPointCloudFolder(project.id);
			vm.folder.isCleaning = true;
		});
	};

	function processMessages(cloudMessages, updatedClouds) {
		updatedClouds = _.sortBy(updatedClouds,
			[
				function (cloud) {
					return cloud.sourceFileName.toUpperCase();
				},
				function (cloud) {
					return cloud.displayName.toUpperCase();
				}
			]);
		updatedClouds = updatedClouds.map((item, index) => {
			item = angular.copy(item);
			item.x = typeof item.x === "number" ? Math.round(item.x) : "";
			item.y = typeof item.y === "number" ? Math.round(item.y) : "";
			item.z = typeof item.z === "number" ? Math.round(item.z) : "";
			if(typeof item.isMainItem === "boolean") {
				return item;
			}
			if(index === 0 || item.sourceFileName !== updatedClouds[index - 1].sourceFileName) {
				item.isMainItem = true;
			} else {
				item.isMainItem = false;
			}
			return item;
		});
		vm.clouds.length = 0;
		for(let i = 0; i < updatedClouds.length; ++i) {
			vm.clouds.push(updatedClouds[i]);
		}

		vm.cleanupMessage = null;
		cloudMessages = (cloudMessages || []).filter(item => {
			vm.cleanupMessage = item.targetId === "/cleanup" ? item : vm.cleanupMessage;
			return item.targetId !== "/cleanup";
		});

		if(cloudMessages) {
			vm.cloudStatus = {};
			vm.cloudMessages = cloudMessages;
			_.forEach(cloudMessages, msg => {
				if(msg.targetId) {
					vm.cloudStatus[msg.targetId.toLowerCase()] = msg;
				}
			});
		}
		const indicesToRemove = [];
		uploadService.uploadStatuses.forEach((uploadObj, index) => {
			const sourceFilename = uploadObj.sourceFileName.toLowerCase();
			if(vm.cloudStatus[sourceFilename]
				&& (uploadObj.status === "success"
						|| vm.cloudStatus[sourceFilename].otherAttributes
						&& vm.cloudStatus[sourceFilename].otherAttributes.isNewFile)) {
				indicesToRemove.push(index);
			}
		});
		indicesToRemove.forEach((index, indexIndex) => {
			uploadService.uploadStatuses.splice(index - indexIndex, 1);
		});

		// Classify messages without clouds

		// messages that are ok and new, but have no cloud (and shouldn't have one)
		vm.newClouds = [];
		// messages that failed and have no cloud (and shouldn't have ones)
		vm.failedClouds = [];
		// messages that are ok but have no corresponding cloud (but should have)
		const okMissingMessages = [];

		vm.cloudMessages.forEach(message => {
			const cloudForMessage = _.find(vm.clouds, { sourceFileName: message.targetId }) != null;
			if(!message.targetId || cloudForMessage || !message.otherAttributes) {
				return;
			}
			if(message.otherAttributes.isFailed) {
				vm.failedClouds.push(message);
			} else if(message.otherAttributes.isNewFile) {
				vm.newClouds.push(message);
			} else if(!message.otherAttributes.isNewFile) {
				vm.newClouds.push(message);
				okMissingMessages.push(message);
			}

			if(message.message && _.includes(message.message, "removal")) {
				vm.deletingDictionary[message.targetId] = true;
			} else {
				delete vm.deletingDictionary[message.targetId];
			}
		});

		_.forOwn(vm.deletingDictionary, (value, key) => {
			if(_.find(vm.clouds, { sourceFileName: key }) == null
			&& _.find(vm.failedClouds, { targetId: key }) == null) {
				delete vm.deletingDictionary[key];
			}
		});

		if(!vm.cleanupMessage) {
			vm.folder.isCleaning = false;
			delete vm.cleanupMessage;
		} else {
			vm.folder.isCleaning = true;
		}
	}

	vm.downloadCloud = function (cloud) {
		let cpx = cloud.fileName;
		cpx = cpx.replace("].{%d}.cpx", "].{0}.cpx");
		pointCloudsRepository.downloadPointCloud(project.id, cpx).then(response => {
			const blob = response.data;
			const filename = cloud.displayName ? cloud.displayName + ".cpx" : "cloud.cpx";
			window.saveAs(blob, filename);
		});
	};

	let isSelectingFile = false;

	vm.cloudFileSelected = function (files) {
		if(!files || files.length === 0) {
			return;
		}
		if(isSelectingFile) {
			return;
		}
		const duplicates = files.filter(file => {
			return !!_.find(vm.cloudMessages, msg => {
				return msg.targetId
					&& file.name
					&& (msg.targetId.toLowerCase() === file.name.toLowerCase());
			}) || !!_.find(vm.clouds, cloud => {
				return cloud.sourceFileName
					&& file.name
					&& (cloud.sourceFileName.toLowerCase() === file.name.toLowerCase());
			});
		});

		if(duplicates.length > 0) {
			const duplicateNames = "<br><br>&nbsp;&nbsp;&bull; " + duplicates.map(dupe => {
				return dupe.name;
			}).join("<br>&nbsp;&nbsp;&bull; ") + "<br><br>";

			const mbox = messageBox.openTripleQuestion(
				"Replace duplicates?",
				"This point cloud upload contains <span class=\"emphasized\">" + duplicates.length
				+ " file(s)</span> that already exist on the server:" + duplicateNames
				+ "Do you wish to overwrite or skip the file(s)?",
				"Overwrite", "Skip", "Cancel"
			);
			mbox.result.then(multiUploader, () => {
				isSelectingFile = false;
			});
		} else {
			multiUploader("ok");
		}

		function multiUploader(dialogResult) {
			isSelectingFile = false;
			const isOverwriting = dialogResult === "ok";
			uploadService.uploadFiles(files, {}, duplicates, isOverwriting);
		}
	};

	vm.getStatusForCloud = function (cloud) {
		const msg1 = (
			vm.cloudStatus[cloud.sourceFileName.toLowerCase()]
			&& vm.cloudStatus[cloud.sourceFileName.toLowerCase()].errorMessage
				? vm.cloudStatus[cloud.sourceFileName.toLowerCase()].errorMessage
				: vm.cloudStatus[cloud.sourceFileName.toLowerCase()]
					&& vm.cloudStatus[cloud.sourceFileName.toLowerCase()].message
		) || "";
		const msg2 = vm.cloudStatus[cloud.sourceFileName.toLowerCase()]
			&& vm.cloudStatus[cloud.sourceFileName.toLowerCase()].currentStep > 0
			? " (step " + vm.cloudStatus[cloud.sourceFileName.toLowerCase()].currentStep + "/"
			+ vm.cloudStatus[cloud.sourceFileName.toLowerCase()].totalSteps + ")"
			: "";
		return msg1 + msg2;
	};

	vm.cancelUpload = function () {
		const uploadCount = uploadService.uploadStatuses.filter(upload => {
			return upload.status === "queued" || upload.isActive;
		}).length;
		if(uploadCount > 0) {
			messageBox.openQuestion(
				"Uploads in progress",
				uploadCount + " upload(s) are still in progress."
				+ " Are you sure you wish to cancel these upload(s)?",
				"<i class='fa fa-check fa-fw'></i> Yes", "<i class='fa fa-times fa-fw'></i> Cancel"
			).result.then(
				() => {
					notification.warning("Upload(s) canceled");
					uploadService.cancel();
				}, () => {
					// do nothing on cancel
				}
			);
		}
	};

	vm.savePointCloudSettings = function () {
		if(vm.isSavingSettings) {
			vm.saveQueued = true;
			return;
		}
		vm.isSavingSettings = true;
		pointCloudsRepository.savePointCloudSettings(project.id, vm.settings).then(() => {
			vm.isSavingSettings = false;
			if(vm.saveQueued) {
				vm.saveQueued = false;
				vm.savePointCloudSettings();
			}
		});
	};

	vm.saveColorFactor = function (factor) {
		if(factor == null || factor === "" || isNaN(factor) || typeof factor !== "number") {
			factor = 1;
		}
		factor = Math.max(0.001, factor);
		factor = Math.min(10, factor);
		vm.settings.colorFactor = factor;
		vm.savePointCloudSettings();
	};
}
