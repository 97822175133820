angular.module("eShareApp").controller("ProjectStatusViewCtrl", ProjectStatusViewCtrl);

ProjectStatusViewCtrl.$inject = [
	"$state", "$scope", "projects", "projectsRepository", "backgroundProgressesRepository",
	"projectActivityRepository"
];

function ProjectStatusViewCtrl(
	$state, $scope, projects, projectsRepository, backgroundProgressesRepository,
	projectActivityRepository
) {

	// Initialization

	const syncObjects = {};
	$scope.projects = projects;

	for(let i = 0; i < $scope.projects.length; i++) {
		syncObjects[$scope.projects[i].id] = {};
	}

	$scope.dataSources = [];
	refreshProjectProgress();

	// Methods

	$scope.getSynchronizationObject = function (index) {
		const syncObject = syncObjects[$scope.projects[index].id];
		return syncObject;
	};

	function initialize() {
		backgroundProgressesRepository.getDataSources().then(dataSources => {
			for(let i = 0; i < $scope.projects.length; i++) {
				$scope.dataSources[i] = [];
				for(let a = 0; a < dataSources.length; a++) {
					if($scope.projects[i].id === dataSources[a].projectId) {
						$scope.dataSources[i].push({
							source: dataSources[a],
							percentage: "",
							message: "Loading indexing information",
						});
					}
				}
				$scope.dataSources[i] = _.sortBy($scope.dataSources[i], ds => {
					return ds.source.name.toLowerCase();
				});
			}
		});
	}

	function getInformation() {
		projectsRepository.getAll().then(projects => {
			$scope.projects = projects;
		});
		projectActivityRepository.getProjectActivity().then(data => {
			$scope.projectDisabled = data;
			backgroundProgressesRepository.getAll().then(
				content => {
					for(let projectIndex = 0; projectIndex < $scope.projects.length; projectIndex++) {
						if($scope.projectDisabled[$scope.projects[projectIndex].id]) {
							continue;
						}
						const projectId = $scope.projects[projectIndex].id;
						const syncObject = syncObjects[projectId];
						if(syncObject && syncObject.onUpdate) {
							const publishingContent = _.find(content.publishing, c => {
								return c[0] === projectId;
							});
							const pointCloudContent = _.find(content.pointClouds, c => {
								return c[0] === projectId;
							});
							syncObject.onUpdate(publishingContent, pointCloudContent, content.indexing);
						}
					}
				}
			);
		});
	}

	function refreshProjectProgress() {
		// Initial
		initialize();
		getInformation();
		// Refresh cycle
		$scope.getInformationInterval = setInterval(() => {
			if($state.current.name === "admin.dashboard.backgroundTasks") {
				getInformation();
			}
		}, 5000);
	}

	// Ends the refresh cycle if the user goes elsewhere
	$scope.$on("$destroy", () => {
		if($scope.getInformationInterval) {
			clearInterval($scope.getInformationInterval);
		}
	});
}
