import "./vendors";

import "./core";
import "./documentViewer";
import "./eShare.module";
import "./modelImporters";
import "./scripts";
import "./styles/styles.less";
import "./ui";

polyfillConsole();

window.debugOut = debugOut;
window.onerror = unhandledErrorHandler;

window.eShare.is64BitWindows = getIs64BitWindows();

window.eShare.eBrowserControl_projectId = "";

function reqListener() {
	const responseText = this.responseText;
	const ViewBag = JSON.parse(responseText);

	const minimumIeVersion = 11;
	const ieVersion = getInternetExplorerVersion();
	if (ieVersion !== -1 && ieVersion < minimumIeVersion) {
		window.location.href = "OldIeVersion";
	}
	const userAgent = navigator.userAgent.toLowerCase();

	const loginUrl =
		ViewBag.loginUrl + "&redirect_to=" + encodeURIComponent(location.href);

	window.eShare = window.eShare || {};
	window.eShare.isIE = ieVersion >= minimumIeVersion;
	window.eShare.isFirefox = userAgent.indexOf("firefox") > -1;
	window.eShare.isChrome =
		userAgent.indexOf("chrome") > -1 || userAgent.indexOf("safari") > -1;
	window.eShare.version = ViewBag.serverVersion;
	window.eShare.productVersion = ViewBag.serverProductVersion;
	window.eShare.rootUrl = ViewBag.rootUrl;
	window.eShare.databaseSchemaErrorUrl = ViewBag.databaseSchemaErrorUrl;
	window.eShare.loginUrl = loginUrl;
	window.eShare.sessionTerminatedUrl = ViewBag.sessionTerminatedUrl;
	window.eShare.noLicenseUrl = ViewBag.noLicenseUrl;
	window.eShare.noLicenseMessage = "No license";
	window.eShare.connectionLostMessage = "eShare connection lost";
	window.eShare.integratedSecurity = ViewBag.integratedSecurity;
	window.eShare.protocolWhitelist = ViewBag.protocolWhitelist;
	window.eShare.is64BitWindows = getIs64BitWindows();
	window.eShare.has3ddExport = ViewBag.has3ddExport;
	window.eShare.maxMultiselectCount = ViewBag.maxMultiselectCount;
	window.eShare.automatedClientUpdates = ViewBag.automatedClientUpdates;
	window.eShare.databaseEngineInfo = {
		isExpress: ViewBag.databaseEngineInfoIsExpress,
		isWrongBitness: ViewBag.databaseEngineInfoIsWrongBitness,
	};

	const accessToken = getAccessToken();
	window.eShare.accessToken = accessToken;
	try {
		let lastRequestedUrl;
		let serializedLastRequestedUrl;
		const timestampNow = Date.now().valueOf();
		if (accessToken) {
			serializedLastRequestedUrl = localStorage.getItem("lastRequestedUrl");
			if (typeof serializedLastRequestedUrl === "string") {
				localStorage.removeItem("lastRequestedUrl");
				lastRequestedUrl = JSON.parse(serializedLastRequestedUrl);
				if (
					lastRequestedUrl
					&& typeof lastRequestedUrl.timestamp === "number"
					&& typeof lastRequestedUrl.href === "string"
				) {
					const timestamp = lastRequestedUrl.timestamp;
					if (timestampNow - timestamp < 10000) {
						location.href = lastRequestedUrl.href;
					}
				}
			}
		} else {
			const href = location.href;
			if (href.indexOf("#") > 0) {
				lastRequestedUrl = {
					href: href,
					timestamp: timestampNow,
				};
				serializedLastRequestedUrl = JSON.stringify(lastRequestedUrl);
				localStorage.setItem(
					"lastRequestedUrl",
					serializedLastRequestedUrl
				);
			}
		}
	} catch (e) {
		// do nothing
	}

	verifyEShareAppVersion();

	$(() => {
		Authenticator.initialize(() => {
			angular.bootstrap($("#eShareApp"), ["eShare"]);
		});
	});

	function verifyEShareAppVersion() {
		const externalComponent = window.external?.isEBrowserControl
			|| window.chrome?.webview?.hostObjects?.eBrowserControl;
		if (!externalComponent) {
			return;
		}
		Promise.resolve(externalComponent.ptyVersionString).then(eShareAppVersion => {
			if(!eShareAppVersion) {
				eShareAppVersion = "0.0.0.0";
			}
			const serverVersion = ViewBag.serverVersion;
			if(eShareAppVersion === serverVersion) {
				return;
			}
			if(eShareAppVersion === "0.0.0.0" || serverVersion === "1.0.0.0") {
				return;
			}
			if(checkForAutomatedClientUpdates(eShareAppVersion)) {
				window.location.href = "UpdatingEShareApp";
			} else {
				window.location.href =
				"IncompatibleEShareAppVersion?installedVersion=" + eShareAppVersion;
			}
		});

		function checkForAutomatedClientUpdates(eShareAppVersion) {
			if(ViewBag.automatedClientUpdates) {
				const versionParts = eShareAppVersion.split(".");
				const majorVersion = +versionParts[0];
				const minorVersion = +versionParts[1];
				if(majorVersion > 20 || (majorVersion === 20 && minorVersion >= 3)) {
					if(confirm("eShare Server has been updated. Starting eShareApp update...")) {
						Promise.resolve(
							externalComponent.mthLaunchVersionUpdate(
								ViewBag.rootUrl,
								getAccessToken(),
								""
							)
						).then(isSuccess => {
							if(!isSuccess) {
								alert("Starting eShareApp update failed");
							}
						});
						return true;
					}
					return false;
				}
				return false;
			}
			return false;
		}
	}
}

function transferFailed() {
	console.error("/api/ServerInfo failed");
}

function transferCanceled() {
	console.error("/api/ServerInfo cancelled");
}

const xhr = new XMLHttpRequest();
xhr.addEventListener("load", reqListener);
xhr.addEventListener("error", transferFailed);
xhr.addEventListener("abort", transferCanceled);
xhr.open("GET", "/api/ServerInfo");
xhr.send();

function polyfillConsole() {
	function noop() {}

	if (!window.console) {
		window.console = {};
	}
	if (!window.console.hasOwnProperty) {
		window.console.hasOwnProperty = function (name) {
			return name in window.console;
		};
	}

	const propertyNames = "memory".split(",");
	let propertyName;
	while ((propertyName = propertyNames.pop())) {
		if (!window.console.hasOwnProperty(propertyName)) {
			window.console[propertyName] = {};
		}
	}
	const methodNames =
		("assert,clear,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,"
		+ "markTimeline,profile,profiles,profileEnd,show,table,time,timeEnd,timeline,timelineEnd,"
		+ "timeStamp,trace,warn").split(
			","
		);
	let methodName;
	while ((methodName = methodNames.pop())) {
		if (!window.console.hasOwnProperty(methodName)) {
			window.console[methodName] = noop;
		}
	}
}

function unhandledErrorHandler(message, source, lineno, colno, error) {
	function normalizeText(text) {
		text = typeof text === "string" ? text.trim() : "";
		return text || "<None>";
	}

	function normalizeNumber(number) {
		const isNumber =
			typeof number === "number" && !isNaN(number) && number !== 0;
		return isNumber ? number : "?";
	}

	message = normalizeText(message);
	source = normalizeText(source);
	lineno = normalizeNumber(lineno);
	colno = normalizeNumber(colno);
	let stack;
	if (error && typeof error === "object") {
		stack = error.stack;
		error.stack = undefined;
	}
	error = error ? JSON.stringify(error, null, "   ") : "<None>";
	let alertErrorMessage;
	let consoleErrorMessage;
	if (message.toLowerCase().indexOf("script error") >= 0) {
		alertErrorMessage =
			"Unhandled script error: see browser console for detail";
		consoleErrorMessage = alertErrorMessage;
	} else {
		const errorMessageLines = [
			"Unhandled error: " + message,
			"Source: " + source + " (line " + lineno + ", column " + colno + ")",
			"Details: " + error
		];
		alertErrorMessage = errorMessageLines.join("\n");
		if (stack) {
			errorMessageLines.push("Stack: " + stack);
		}
		consoleErrorMessage = errorMessageLines.join("\n");
	}
	console.error(consoleErrorMessage);
	debugOut(consoleErrorMessage);
	window.alert(alertErrorMessage);
	return true;
}

function debugOut(message) {
	if (typeof message !== "string") {
		message = "" + message;
	}
	if (window.external && window.external.isEBrowserControl) {
		window.external.debugOut(message);
	}
	console.log("debugOut: " + message);
}

function getAccessToken() {
	const hash = window.location.hash.substring(1);
	const token = {};
	const hashParts = hash.split("&");
	for (let i = 0; i < hashParts.length; ++i) {
		const hashPart = hashParts[i].split("=");
		if (
			hashPart.length !== 2
			|| hashPart[0].length === 0
			|| hashPart[1].length === 0
		) {
			return "";
		}
		token[hashPart[0]] = hashPart[1];
	}
	if (
		token.token_type !== "bearer"
		|| typeof token.access_token !== "string"
		|| token.access_token.length < 32
	) {
		return "";
	}
	return token.access_token;
}

function getInternetExplorerVersion() {
	let version = -1;
	let matches;
	if (navigator.appName === "Netscape") {
		matches = /Trident\/.+rv:([0-9]{1,}[.0-9]{0,})/.exec(
			navigator.userAgent
		);
		if (matches !== null) {
			version = normalizeVersion(matches[1]);
		}
	}
	if (version === -1) {
		matches = /MSIE ([0-9]{1,}[.0-9]{0,})/.exec(navigator.userAgent);
		if (matches !== null) {
			version = normalizeVersion(matches[1]);
		}
	}
	return version;

	function normalizeVersion(v) {
		v = +v;
		if (!isFinite(v)) {
			return -1;
		}
		v = Math.floor(v);
		return v === 0 ? -1 : v;
	}
}

function getIs64BitWindows() {
	const os = window.platform && window.platform.os;
	if (!os) {
		return false;
	}
	const family = os.family;
	let is64BitWindows =
		family
		&& family.startsWith("Windows")
		&& family !== "Windows Phone"
		&& os.architecture === 64;
	is64BitWindows = Utilities.toBool(is64BitWindows);
	return is64BitWindows;
}
