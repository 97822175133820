angular
	.module("eShare.core")
	.factory("httpActivityNotificationChannel", httpActivityNotificationChannel);

httpActivityNotificationChannel.$inject = ["$rootScope"];

function httpActivityNotificationChannel($rootScope) {
	const httpActivityStartedMessage = "event:httpActivityStarted";
	const httpActivityEndedMessage = "event:httpActivityEnded";

	return {
		activityStarted: activityStarted,
		activityEnded: activityEnded,
		onActivityStarted: onActivityStarted,
		onActivityEnded: onActivityEnded,
	};

	function activityStarted() {
		$rootScope.$broadcast(httpActivityStartedMessage);
	}

	function activityEnded() {
		$rootScope.$broadcast(httpActivityEndedMessage);
	}

	function onActivityStarted(scope, handler) {
		scope.$on(httpActivityStartedMessage, handler);
	}

	function onActivityEnded(scope, handler) {
		scope.$on(httpActivityEndedMessage, handler);
	}
}
