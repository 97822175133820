angular.module("eShareApp").controller("variablesViewCtrl", variablesViewCtrl);

variablesViewCtrl.$inject = [
	"$scope", "$stateParams", "projectVariablesRepository"
];

function variablesViewCtrl(
	$scope, $stateParams, projectVariablesRepository
) {
	projectVariablesRepository.getProjectVariables($stateParams.projectId).then(
		result => {
			if(result && result.variables) {
				$scope.variableSettings = result;
			} else {
				$scope.variableSettings = {
					variables: [],
				};
			}
		}
	);
}
