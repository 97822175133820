angular.module("eShareApp").controller("SysAdminDashboardCtrl", SysAdminDashboardCtrl);

SysAdminDashboardCtrl.$inject = [
	"$state", "$scope", "logRepository", "projects", "backgroundProgressesRepository",
	"applicationPoolRepository"
];

function SysAdminDashboardCtrl(
	$state, $scope, logRepository, projects, backgroundProgressesRepository,
	applicationPoolRepository
) {
	// Initialization
	let today = new Date();
	let dd = today.getDate();
	let mm = today.getMonth() + 1; //January is 0!
	const yyyy = today.getFullYear();
	if(dd < 10) {
		dd = "0" + dd;
	}
	if(mm < 10) {
		mm = "0" + mm;
	}
	today = yyyy + "-" + mm + "-" + dd;

	$scope.projects = projects;
	$scope.numberofDataSources = "-";
	$scope.numberOfPublishingProcesses = "-";
	$scope.numberOfIndexingProcesses = "-";

	$scope.log = {
		today: today,
		selectedLog: {
			messageTimestamp: [],
			messageType: [],
			message: [],
		},
	};
	let numberOfLogMessagesShown = 10;

	countDataSources();
	refreshProjectProgress();
	getLog(today);
	getAppPoolInformation();

	// Methods

	function getAppPoolInformation() {
		applicationPoolRepository.getInfo().then(content => {
			$scope.applicationPool = {
				name: content.name,
				startedAt: content.startedAt
					.substring(0, content.startedAt.length - 10)
					.replace("T", " "),
				startedAtDate: content.startedAt,
			};
		});
	}

	function countDataSources() {
		backgroundProgressesRepository.getDataSources().then(dataSources => {
			$scope.numberofDataSources = dataSources.length;
		});
	}

	$scope.numberOfRows = function (num) {
		return new Array(num);
	};

	function refreshProjectProgress() {
		//Initial
		count();
		$scope.countInterval = setInterval(() => {
			//Refresh cycle
			if($state.current.name === "admin.dashboard") {
				count();
			}
		}, 5000);
	}

	$scope.$on("$destroy", () => {
		if($scope.countInterval) {
			clearInterval($scope.countInterval);
		}
	});

	function count() {
		backgroundProgressesRepository.getAll().then(content => {
			let numberOfPublishingProcesses = 0;
			let numberOfIndexingProcesses = 0;
			for(let i = 0; i < content.publishing.length; i++) {
				if(content.publishing[i][1].length !== 0
						&& content.publishing[i][2] !== "Publishing failed") {
					numberOfPublishingProcesses++;
				}
			}
			for(let i = 0; i < content.indexing.length; i++) {
				if(content.indexing[i][2].length !== 0) {
					numberOfIndexingProcesses++;
				}
			}
			let isPointCloudSynchronizationActiveNew = false;
			for(let i = 0; i < content.pointClouds.length; i++) {
				if(content.pointClouds[i][2].length !== 0) {
					isPointCloudSynchronizationActiveNew = true;
					break;
				}
			}
			$scope.isPointCloudSynchronizationActive = isPointCloudSynchronizationActiveNew;
			$scope.numberOfPublishingProcesses = numberOfPublishingProcesses;
			$scope.numberOfIndexingProcesses = numberOfIndexingProcesses;
		});
	}

	$scope.getLog = function (date) {
		if(date === "") {
			date = today;
		}
		getLog(date);
	};

	function getLog(date) {
		logRepository.getLogText(date).then(
			logData => {
				previewFormat(logData);
			}
		);
	}

	function previewFormat(logText) {
		$scope.log.selectedLog.messageTimestamp = [];
		$scope.log.selectedLog.messageType = [];
		$scope.log.selectedLog.message = [];
		const lines = logText.split("\n").reverse();
		if(numberOfLogMessagesShown > lines.length) {
			numberOfLogMessagesShown = lines.length;
		}
		let logIndex = 0;
		let stringCumulator = "";
		for(let i = 0; i < lines.length; i++) {
			if(lines[i].length > 30) {
				if(lines[i].substring(0, 10) !== $scope.log.today) {
					stringCumulator = "\n" + lines[i] + stringCumulator;
				} else {
					$scope.log.selectedLog.messageTimestamp[logIndex] = lines[i].substring(11, 11 + 13);
					$scope.log.selectedLog.messageType[logIndex] = lines[i].substring(25, 25 + 5);
					if(lines[i].charAt(lines[i].indexOf("]") + 2) === "[") {
						const messageSubstring = lines[i].substring(lines[i].indexOf("]") + 2);
						$scope.log.selectedLog.message[logIndex] = messageSubstring.substring(
							messageSubstring.indexOf("]") + 2
						) + stringCumulator;
					} else {
						$scope.log.selectedLog.message[logIndex] = lines[i].substring(
							lines[i].indexOf("]") + 2
						) + stringCumulator;
					}
					logIndex++;
					stringCumulator = "";
					if(logIndex === numberOfLogMessagesShown) {
						break;
					}
				}
			}
		}
	}

	$scope.messageTypeStyle = function (type) {
		switch(type.trim()) {
		case "WARN":
			return "lightgoldenrodyellow";
		case "ERROR":
			return "lightpink";
		case "FATAL":
			return "lightcoral";
		}
		return "";
	};
}
