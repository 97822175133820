angular.module("eShareApp").directive("cadSelectAttribute", cadSelectAttribute);

cadSelectAttribute.$inject = [];

function cadSelectAttribute() {
	return {
		restrict: "E",
		scope: {},
		bindToController: {
			attributeDefinitions: "=",
			hasSpecialAttributes: "=",
			hasPointAttributes: "=",
			isPointType: "=",
			attribute: "=",
			selectorWidth: "=",
			selectionChanged: "&",
		},
		controller: CadSelectAttributeController,
		controllerAs: "vm",
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadSelectAttribute.html'),
		replace: false,
	};
}

CadSelectAttributeController.$inject = ["$scope"];

function CadSelectAttributeController($scope) {
	const vm = this;

	vm.s = function () {
		//debugger;
		//vm.selectionChanged();
	};

	vm.attributes = [];

	$scope.$watch(() => {
		return vm.attributeDefinitions;
	}, inputsChanged);

	$scope.$watch(() => {
		return vm.hasSpecialAttributes;
	}, inputsChanged);

	$scope.$watch(() => {
		return vm.hasPointAttributes;
	}, inputsChanged);

	function inputsChanged() {
		vm.attributes = getAttributeDefinitions();
	}

	function getAttributeDefinitions() {
		let attributeDefinitions = [];
		if(vm.hasSpecialAttributes) {
			attributeDefinitions.push(
				{ abbreviation: "((id))", displayName: "ID Attribute", isSpecial: true }
			);
			attributeDefinitions.push(
				{ abbreviation: "((any))", displayName: "Any Attribute", isSpecial: true }
			);
		}
		if(vm.hasPointAttributes) {
			if(vm.isPointType) {
				attributeDefinitions.push(
					{ abbreviation: "((pointKind))", displayName: "Point Type" }
				);
				return attributeDefinitions;
			}
			attributeDefinitions.push(
				{ abbreviation: "((name))", displayName: "Name" }
			);
			attributeDefinitions.push(
				{ abbreviation: "((createdBy))", displayName: "Created By" }
			);
			attributeDefinitions.push(
				{ abbreviation: "((creationDate))", displayName: "Creation Date" }
			);
			attributeDefinitions.push(
				{ abbreviation: "((modifiedBy))", displayName: "Modified By" }
			);
			attributeDefinitions.push(
				{ abbreviation: "((modificationDate))", displayName: "Modification Date" }
			);
			attributeDefinitions.push(
				{ abbreviation: "((x))", displayName: "X" }
			);
			attributeDefinitions.push(
				{ abbreviation: "((y))", displayName: "Y" }
			);
			attributeDefinitions.push(
				{ abbreviation: "((z))", displayName: "Z" }
			);
		}
		attributeDefinitions = _.concat(_.map(vm.attributeDefinitions, a => {
			a.abbreviation = a.abbreviation.toString();
			return a;
		}), attributeDefinitions);
		return attributeDefinitions;
	}
}