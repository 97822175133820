angular.module("eShareApp").directive("cadDatePicker", cadDatePicker);

cadDatePicker.$inject = [];

function cadDatePicker() {
	return {
		restrict: "E",
		controller: cadDatePickerController,
		controllerAs: "vm",
		bindToController: {
			ngChange: "&?",
			ngModel: "=",
		},
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadDatePicker.html'),
		replace: true,
	};
}

cadDatePickerController.$inject = ["l10n", "$timeout"];

function cadDatePickerController(l10n, $timeout) {
	const vm = this;
	if(vm.ngModel == null || typeof vm.ngModel !== "object") {
		throw "cadDatePicker: ngModel not set to a valid object";
	}

	vm.datepickerFormat = "yyyy-MM-dd";
	vm.datepickerOptions = {
		startingDay: 1,
		timezone: new Date().getTimezoneOffset() / 60,
	};

	vm.applyDate = function (term, id) {
		focusId(id);
		term.text = l10n.dateToLocalText(term.date);
		if(vm.ngChange != null && typeof vm.ngChange === "function") {
			vm.ngChange();
		}
	};

	vm.openDate = function (term, id) {
		term.isDateOpen = true;
		focusId(id);
	};

	function focusId(id) {
		const input = document.getElementById(id);
		if(input) {
			$timeout(() => {
				input.focus();
			}, 10);
		}
	}
}