angular.module("eShareApp").controller("ModelImporterTypeSelectCtrl", [
	"$state", "$scope", "$stateParams", "messageBox", "project", "modelImportersRepository",
	"modelImporterTypes",

	function (
		$state, $scope, $stateParams, messageBox, project, modelImportersRepository,
		modelImporterTypes
	) {

		$scope.project = project;
		$scope.importerTypes = modelImporterTypes;
		$scope.selectedImporterType = { typeGuid: "", name: "" };

		$scope.selectImporterType = function (importerType) {
			$scope.selectedImporterType = importerType;
		};

		$scope.createImporter = function () {
			$scope.isNewImporter = true;
			modelImportersRepository
				.create(project.id, $scope.selectedImporterType.typeGuid)
				.then(importer => {
					$state.go("project.admin.model.importer.edit", {
						projectId: project.id,
						importerId: importer.id,
						isNewImporter: true,
					});
				});
		};

		function selectFirstRow() {
			if($scope.importerTypes.length > 0) {
				$scope.selectedImporterType = $scope.importerTypes[0];
			}
		}

		selectFirstRow();
	}
]);
