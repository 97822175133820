angular.module("eShareApp").controller("ProjectByNameModelCtrl", ProjectByNameModelCtrl);

ProjectByNameModelCtrl.$inject = ["$state", "$stateParams", "project"];

function ProjectByNameModelCtrl($state, $stateParams, project) {
	if(!project || !project.projectId) {
		$state.go("error");
		return;
	}
	const stateParams = _.cloneDeep($stateParams);
	stateParams.projectId = project.projectId;
	$state.go("project.model", stateParams);
}
