angular.module("eShare.documentViewer")
	.directive("cadDocumentViewerContextMenu", cadDocumentViewerContextMenu);

cadDocumentViewerContextMenu.$inject = [];

function cadDocumentViewerContextMenu() {
	return {
		restrict: "E",
		replace: true,
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadDocumentViewerContextMenu.directive.html'),
		scope: {},
		bindToController: {
			modelX: "@",
			modelY: "@",
			modelZ: "@",
		},
		controllerAs: "vm",
		controller: cadDocumentSearchController,
	};
}

cadDocumentSearchController.$inject = ["$state"];

function cadDocumentSearchController($state) {
	const vm = this;

	vm.navigateToModelView = function () {
		$state.go(
			"project.model",
			{
				x: vm.modelX,
				y: vm.modelY,
				z: (parseInt(vm.modelZ, 10) + 1650),
			}
		);
	};

	vm.createSmartPointInModelView = function () {
		$state.go(
			"project.model",
			{
				x: vm.modelX,
				y: vm.modelY,
				z: vm.modelZ,
				createSmartPoint: true,
			},
			{ reload: true }
		);
	};
}
