angular.module("eShareApp").controller("AdapterEditCtrl", AdapterEditCtrl);

AdapterEditCtrl.$inject = [
	"$state", "$scope", "$timeout", "adapter", "project", "messageBox", "$compile",
	"adaptersRepository", "adapterConfigurationView", "adapterConfigurationScript",
	"dirtyFormTrackingService", "$injector"
];

function AdapterEditCtrl(
	$state, $scope, $timeout, adapter, project, messageBox, $compile,
	adaptersRepository, adapterConfigurationView, adapterConfigurationScript,
	dirtyFormTrackingService, $injector
) {
	let isCommitting = false;
	$scope.messageBox = messageBox;

	$scope.adapter = _.cloneDeep(adapter);
	$scope.project = _.cloneDeep(project);
	$scope.adapterConfig = $scope.adapter.configurationParameters;

	$scope.isAdapterInEditMode = true;

	$scope.performAdapterAction = performAdapterAction;
	$scope.setFormScope = setFormScope;
	$scope.isSaveEnabled = isSaveEnabled;
	$scope.editCommit = editCommit;
	$scope.editRollback = editRollback;

	$timeout(() => {
		$scope.adapterScript = eval(adapterConfigurationScript)($scope, $injector);
		const adapterConfigurationArea = $("#adapterConfigurationArea");
		adapterConfigurationArea.html(adapterConfigurationView);
		$compile(adapterConfigurationArea.contents())($scope);
	}, 0);

	function performAdapterAction(action) {
		return adaptersRepository.performAdapterAction($scope.project.id, $scope.adapter, action);
	}

	// Change tracking (warn before navigating to a different page
	// if the user has changed something on the page):
	function setFormScope(formScope) {
		$scope.formScope = formScope;
		dirtyFormTrackingService.trackForm($scope.formScope, "form");
	}

	function isSaveEnabled() {
		try {
			const callback = $scope.adapterScript.isSaveEnabled;
			const result = Utilities.invokeCallbackInContext(this, callback);
			return typeof result === "undefined" || Utilities.toBool(result);
		} catch(e) {
			return true;
		}
	}

	function editCommit() {
		if(isCommitting) {
			return;
		}
		isCommitting = true;
		try {
			try {
				Utilities.invokeCallbackInContext(this, $scope.adapterScript.beforeCommit);
			} catch(e) {
				// do nothing
			}
			return adaptersRepository.update($scope.project.id, $scope.adapter)
				.then(
					() => {
						$scope.formScope.form && $scope.formScope.form.$setPristine();
						$state.go("^.view", {
							adapterId: adapter.id,
							projectId: project.id,
						}, {
							reload: true,
						}).then(() => {
							isCommitting = false;
						});
					},
					message => {
						isCommitting = false;
						if(message.indexOf("Name must be unique") > -1) {
							messageBox.openError(
								"Error",
								"Adapters must have unique names."
							);
						} else {
							messageBox.openError(
								"Error",
								"Error saving adapter configuration: " + message
							);
						}
					}
				);
		} catch(e) {
			isCommitting = false;
		}
	}

	function editRollback() {
		if($state.current.data.isNew) {
			$state.go("project.admin.adapters", { projectId: project.id }, { reload: true });
		} else {
			$state.go("^.view", { adapterId: adapter.id, projectId: project.id }, { reload: true });
		}
	}
}
