angular.module("eShareApp").factory("applicationPoolRepository", applicationPoolRepository);

applicationPoolRepository.$inject = ["repositoryBase"];

function applicationPoolRepository(repositoryBase) {
	const api = "api/misc/GetApplicationPoolInformation";

	return {
		getInfo: getInfo,
	};

	function getInfo() {
		return repositoryBase.get(api);
	}
}
