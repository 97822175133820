angular.module("eShareApp").factory("modelHierarchiesRepository", [
	"repositoryBase",
	function (repositoryBase) {

		const baseApi = "/api/project";
		const modelHierarchiesApi = baseApi + "/{projectId}/ModelHierarchies";
		const originalHierarchiesApi = baseApi + "/{projectId}/ModelHierarchies/Original";

		function getOriginal(projectId) {
			return repositoryBase.get(originalHierarchiesApi, { projectId: projectId });
		}

		function get(projectId) {
			return repositoryBase.get(modelHierarchiesApi, { projectId: projectId });
		}

		function save(projectId, modelHierarchies) {
			return repositoryBase.post(
				modelHierarchiesApi,
				{ projectId: projectId },
				{ hierarchyDefinitions: modelHierarchies }
			);
		}

		function exportHierarchies(projectId) {
			const uri = Utilities.combineUri(modelHierarchiesApi, "export");
			return repositoryBase.post(uri, { projectId: projectId }).then(
				exportedData => {
					return JSON.stringify(exportedData, undefined, 3);
				}
			);
		}

		function importHierarchies(projectId, serializedExportedData) {
			const exportedData = JSON.parse(serializedExportedData);
			const uri = Utilities.combineUri(modelHierarchiesApi, "import");
			return repositoryBase.post(uri, { projectId: projectId }, exportedData);
		}

		return {
			getOriginal: getOriginal,
			get: get,
			save: save,
			importHierarchies: importHierarchies,
			exportHierarchies: exportHierarchies,
		};
	}
]);