angular.module("eShareApp").controller("ProjectModelAdminCtrl", ProjectModelAdminCtrl);

ProjectModelAdminCtrl.$inject = [
	"$rootScope", "$state", "$scope", "$timeout", "Upload", "project", "messageBox", "$interval",
	"modelsRepository", "notification", "backgroundProgressesRepository", "modelImportersRepository",
	"modelImporterTypes", "$http", "modelStatus", "modelsInfo", "modelsUploadService"
];

function ProjectModelAdminCtrl(
	$rootScope, $state, $scope, $timeout, upload, project, messageBox, $interval,
	modelsRepository, notification, backgroundProgressesRepository, modelImportersRepository,
	modelImporterTypes, $http, modelStatus, modelsInfo, modelsUploadService
) {

	$scope.model = modelsInfo[0];
	$scope.project = project;
	$scope.isProjectBusy = false;
	$scope.modelUploads = {};
	$scope.modelStatus = modelStatus;
	$scope.importerStatuses = {};
	$scope.importerInfos = {};
	$scope.settings = {
		autopublish: false,
		keepModelsSeparate: true,
		addBoundingBoxCenterCoordinates: modelStatus.addBoundingBoxCenterCoordinates,
		addBoundingBoxMinCoordinates: modelStatus.addBoundingBoxMinCoordinates,
		addBoundingBoxMaxCoordinates: modelStatus.addBoundingBoxMaxCoordinates,
	};
	$scope.onKeepModelsSeparateChanged = onKeepModelsSeparateChanged;
	$scope.onAddBoundingBoxCenterCoordinatesChanged = onAddBoundingBoxCenterCoordinatesChanged;
	$scope.onAddBoundingBoxMinCoordinatesChanged = onAddBoundingBoxMinCoordinatesChanged;
	$scope.onAddBoundingBoxMaxCoordinatesChanged = onAddBoundingBoxMaxCoordinatesChanged;
	$scope.onAutopublishChanged = onAutopublishChanged;
	$scope.messageTypeToDisplayName = messageTypeToDisplayName;
	$scope.messageTypeToCssTextClass = messageTypeToCssTextClass;

	let importerInterval = null;
	let publishingInterval = null;
	let overtimeInterval = null;
	let isPublishingTimeSet = false;
	let isUpdatingKeepModelsSeparate = false;
	let isUpdatingAutopublish = false;
	const uploadServices = {};

	$scope.modelFileSelected = modelFileSelected;

	backgroundProgressesRepository.getTotalPublishingTime(project.id).then(totalTime => {
		$scope.totalPublishingTime = totalTime;
	});

	function onKeepModelsSeparateChanged() {
		isUpdatingKeepModelsSeparate = true;
		modelImportersRepository
			.setKeepModelsSeparate(project.id, $scope.settings.keepModelsSeparate)
			.then(updateCompleted, updateCompleted);

		function updateCompleted() {
			isUpdatingKeepModelsSeparate = false;
		}
	}

	function onAddBoundingBoxCenterCoordinatesChanged() {
		modelImportersRepository
			.setAddBoundingBoxCenterCoordinates(
				project.id, $scope.settings.addBoundingBoxCenterCoordinates
			)
			.then(updateCompleted, updateCompleted);

		function updateCompleted() {
		}
	}

	function onAddBoundingBoxMinCoordinatesChanged() {
		modelImportersRepository
			.setAddBoundingBoxMinCoordinates(
				project.id, $scope.settings.addBoundingBoxMinCoordinates
			)
			.then(updateCompleted, updateCompleted);

		function updateCompleted() {
		}
	}

	function onAddBoundingBoxMaxCoordinatesChanged() {
		modelImportersRepository
			.setAddBoundingBoxMaxCoordinates(
				project.id, $scope.settings.addBoundingBoxMaxCoordinates
			)
			.then(updateCompleted, updateCompleted);

		function updateCompleted() {
		}
	}

	function onAutopublishChanged() {
		isUpdatingAutopublish = true;
		modelImportersRepository
			.setAutopublish(project.id, $scope.settings.autopublish)
			.then(updateCompleted, updateCompleted);

		function updateCompleted() {
			isUpdatingAutopublish = false;
		}
	}

	const uploadInterval = $interval(() => {
		if($state.current.name !== "project.admin.model") {
			$interval.cancel(uploadInterval);
		}
		if(uploadServices == null) {
			return;
		}
		_.forOwn(uploadServices, (service, key) => {
			if(service.uploadStatuses.length > 0) {
				$scope.modelUploads[key] = $scope.modelUploads[key] || {};
				$scope.modelUploads[key].isActive = service.uploadStatuses[0].isActive;
				$scope.modelUploads[key].progress = service.uploadStatuses[0].progress;
			}
		});
	}, 100);

	function modelFileSelected(files, event, importer, shouldPublish) {
		const file = files ? files[0] : null;
		if(!file) {
			return;
		}
		const fileName = (file.name || "").toLowerCase();
		const dotIndex = fileName.lastIndexOf(".");
		const fileType = dotIndex !== -1 ? fileName.substring(dotIndex) : "";
		if(fileType === "") {
			notification.error("The file must be of a valid type");
			return;
		}
		const action = shouldPublish ? "UploadModelAndPublish" : "UploadModel";
		const importerService = modelsUploadService.forImporter(
			project, importer, getImporterType(importer.typeGuid)
		);
		uploadServices[importer.id] = importerService;
		files.length = 1;
		importerService.uploadFiles(files, action).then(service => {
			const status = service.uploadStatuses[0];
			const isSuccessful = status && status.status === "success";
			if(isSuccessful) {
				$timeout(() => {
					updateModelStatus(true);
				}, 100);
				const impStatus = $scope.importerStatuses[importer.id];
				if(impStatus) {
					impStatus.isImporting = true;
				} else {
					$scope.importerStatuses[importer.id] = {
						hasSucceeded: false,
						isImporting: true,
					};
				}
				updateModelInformation();
			}
		});
	}

	// Kludge to find "some" suitable model (revision) id to link to in the
	// Attribute Transformations button
	function updateModelInformation() {
		$scope.isProjectBusy = false;
		$scope.aPublishedModelExists = false;
		$scope.isActiveRevisionFake = false;
		let model = $scope.model;
		if(!model) {
			model = {};
			$scope.model = {};
		}
		const firstInactiveRevision = _.find(model.revisions, { active: false });

		if(firstInactiveRevision) {
			$scope.modelRevisionId = firstInactiveRevision.modelRevisionId;
			$scope.isActiveRevisionFake = firstInactiveRevision.isFake;
		} else {
			const lastModelRevision = _.last(model.revisions);
			if(lastModelRevision) {
				$scope.modelRevisionId = lastModelRevision.modelRevisionId;
				$scope.isActiveRevisionFake = lastModelRevision.isFake;
			}
		}
		if(_.some(model.revisions, { active: true })) {
			$scope.aPublishedModelExists = true;
		}
		if(model.isModelBusy) {
			$scope.isProjectBusy = true;
			if(!isPublishingTimeSet) {
				isPublishingTimeSet = true;
				startPublishingCountDown();
			}
			const activeRevision = _.find(model.revisions, { active: true });
			if(activeRevision) {
				model.creationDate = activeRevision.creationDate;
				model.uploader = activeRevision.uploader;
				model.uploadDate = activeRevision.uploadDate;
				model.publishedModelExists = true;
			} else {
				model.creationDate = "";
				model.uploader = "";
				model.uploadDate = "";
				model.publishedModelExists = false;
			}
		}
	}

	updateModelInformation();

	$scope.republish = function (model) {
		doModelPublish(model, modelsRepository.multipublish);
	};

	function doModelPublish(model, action) {
		model.isModelBusy = true;
		updateModelInformation();
		action(project.id)
			.then(() => {
				notification.success("Model published successfully");
				$rootScope.$broadcast("eBrowser::ModelOutdated");
				model.isModelBusy = false;
				modelsRepository.getModelInfo(project.id).then(info => {
					$scope.model = info[0];
					updateModelInformation();
					$timeout(() => {
						$state.go(".", { modelId: $scope.modelRevisionId }, { reload: true });
					}, 0);
				}, () => {
					model.isModelBusy = true;
					updateModelInformation();
				});
			}, (/*errorMessage*/) => {
				model.isModelBusy = true;
				updateModelInformation();
			});
	}

	if($scope.model.isModelBusy) {
		isPublishingTimeSet = true;
		startPublishingCountDown();
	}

	function startPublishingCountDown() {
		backgroundProgressesRepository.getRemainingPublishingTime(project.id).then(publishingTime => {
			if(publishingTime) {
				$interval.cancel(publishingInterval);
				$interval.cancel(overtimeInterval);
				$scope.publishingTime = publishingTime;
				$scope.barColor = "#007bff";
				publishingInterval = $interval(() => {
					if($state.current.name === "project.admin.model") {
						if($scope.publishingTime > 0) {
							$scope.publishingTime--;
							stringifyPublishingTime(false);
							let publishingProgress = (
								($scope.totalPublishingTime - $scope.publishingTime)
								/ $scope.totalPublishingTime
							);
							publishingProgress = Math.floor(publishingProgress * 100);
							$scope.barLength = publishingProgress + "%";
							$scope.barTextColor = publishingProgress > 50
								? "white"
								: $scope.barColor === "#dc3545" ? "white" : "black";
						} else {
							startOverTimeCounter();
							$interval.cancel(publishingInterval);
						}
						if($scope.publishingPercentage === "Publishing failed") {
							$scope.barColor = "#dc3545";
							$scope.barTextColor = "white";
							$scope.barLength = "100%";
							$scope.publishingTimeString = "ERROR";
							$scope.isPublishingBarInErrorState = true;
							setTimeout($interval.cancel(publishingInterval), 1000 * 10);
						}
					} else {
						$interval.cancel(publishingInterval);
					}
				}, 1000);
			} else {
				setTimeout(startPublishingCountDown, 1000);
			}
		});
	}

	function startOverTimeCounter() {
		$scope.barColor = "#ffc107";
		$scope.barLength = "100%";
		$scope.barTextColor = "black";
		$scope.publishingTime *= -1;
		overtimeInterval = $interval(() => {
			if($state.current.name === "project.admin.model") {
				if($scope.isProjectBusy || !$scope.aPublishedModelExists) {
					$scope.publishingTime++;
					stringifyPublishingTime(true);
					if($scope.publishingPercentage === "Publishing failed") {
						$scope.barColor = "#dc3545";
						$scope.barTextColor = "white";
						$scope.barLength = "100%";
						$scope.publishingTimeString = "ERROR";
						$scope.isPublishingBarInErrorState = true;
						setTimeout($interval.cancel(overtimeInterval), 1000 * 10);
					}
				} else {
					$interval.cancel(overtimeInterval);
				}
			} else {
				$interval.cancel(overtimeInterval);
			}
		}, 1000);
	}

	function stringifyPublishingTime(isOnOvertime) {
		let seconds;
		let minutes = "00";
		let hours = "00";
		if($scope.publishingTime > 60) {
			if(($scope.publishingTime / 60) > 60) {
				hours = Math.floor($scope.publishingTime / 60 / 60);
				hours = hours < 10 ? "0" + hours : hours;
				minutes = Math.floor($scope.publishingTime / 60) % 60;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = $scope.publishingTime % 60;
				seconds = seconds < 10 ? "0" + seconds : seconds;
			} else {
				minutes = Math.floor($scope.publishingTime / 60);
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = $scope.publishingTime % 60;
				seconds = seconds < 10 ? "0" + seconds : seconds;
			}
		} else {
			seconds = $scope.publishingTime < 10 ? "0" + $scope.publishingTime : $scope.publishingTime;
		}
		let timeString = hours + ":" + minutes + ":" + seconds;
		timeString = isOnOvertime ? "On overtime: " + timeString : "About " + timeString + " left";
		$scope.publishingTimeString = timeString;
	}

	$scope.removeImporter = function (importer) {
		const mbox = messageBox.openQuestion(
			"Remove this model?",
			"Are you sure you want to remove importer " + importer.name + "? This cannot be undone.",
			"Remove", "Cancel"
		);
		mbox.result.then(() => {
			modelImportersRepository.remove($scope.project.id, importer.id).then(
				() => {
					updateModelStatus();
				}, error => {
					notification.error(error);
				}
			);
		});
	};

	$scope.toggleImporterIsActive = function (importer) {
		$scope.isSavingImporter = true;
		importer.isActive = !importer.isActive;
		modelImportersRepository.get($scope.project.id, importer.id).then(result => {
			result.isActive = importer.isActive;
			modelImportersRepository.update($scope.project.id, result)
				.then(
					() => {
						$scope.isSavingImporter = false;
						//updateImporters();
					},
					message => {
						messageBox.openError(
							"Error",
							"Error saving importer configuration: " + message
						);
						$scope.isSavingImporter = false;
					}
				);
		},
		message => {
			messageBox.openError(
				"Error",
				"Error loading importer configuration: " + message
			);
			$scope.isSavingImporter = false;
		});
	};

	$scope.isImporterTypeKnown = function (importerTypeGuid) {
		const importerType = getImporterType(importerTypeGuid);
		return !Utilities.isNullOrUndefined(importerType);
	};

	$scope.getImporterNeedsOwnLicense = function (importerTypeGuid) {
		const importerType = getImporterType(importerTypeGuid);
		return Utilities.isNullOrUndefined(importerType) ? false : importerType.needsOwnLicense;
	};

	$scope.getImporterTypeName = function (importerTypeGuid) {
		const importerType = getImporterType(importerTypeGuid);
		return Utilities.isNullOrUndefined(importerType)
			? "Unknown type (GUID: " + importerTypeGuid + ")"
			: importerType.name;
	};

	$scope.getImporterTypeFormats = function (importerTypeGuid) {
		const importerType = getImporterType(importerTypeGuid);
		return (importerType && importerType.supportedFileExtensions) || "";
	};

	$scope.localizeDate = function localizeDate(utcDateTimeString) {
		const date = moment.utc(utcDateTimeString);
		return date.isValid() ? date.local().format("LLL") : utcDateTimeString;
	};

	function getImporterType(importerTypeGuid) {
		return _.find(modelImporterTypes, at => {
			return at.typeGuid === importerTypeGuid;
		});
	}

	let intervalDelay = 10 * 1000;

	updateModelStatus();
	const intervalFunction = function () {
		if($state.current.name === "project.admin.model") {
			updateModelStatus();
		} else {
			$interval.cancel(importerInterval);
		}
	};
	importerInterval = $interval(intervalFunction, intervalDelay);

	function setShortInterval() {
		if(intervalDelay !== 3000) {
			intervalDelay = 3000;
			$interval.cancel(importerInterval);
			importerInterval = $interval(intervalFunction, intervalDelay);
		}
	}

	function setLongInterval() {
		if(intervalDelay !== 10 * 1000) {
			intervalDelay = 10 * 1000;
			$interval.cancel(importerInterval);
			importerInterval = $interval(intervalFunction, intervalDelay);
		}
	}

	$scope.isUploading = function () {
		let isUploading = false;
		_.forOwn($scope.modelUploads, uploader => {
			isUploading = isUploading || uploader.isActive;
		});
		return isUploading;
	};

	$scope.isImporting = function () {
		let isImporting = false;
		_.forOwn($scope.importerStatuses, importer => {
			isImporting = isImporting || importer.isImporting;
		});
		return isImporting;
	};

	$scope.$watch("isUploading() || isImporting() || model.isModelBusy || isProjectBusy", result => {
		if(result) {
			setShortInterval();
		} else {
			setLongInterval();
		}
	});

	$scope.$on("$destroy", () => {
		$interval.cancel(importerInterval);
		$interval.cancel(publishingInterval);
		$interval.cancel(overtimeInterval);
	});

	function updateModelStatus() {
		modelImportersRepository.getStatus(project.id).then(status => {
			let isUpdated = false;
			if(!isUpdatingKeepModelsSeparate) {
				$scope.settings.keepModelsSeparate = status.keepModelsSeparate;
			}
			if(!isUpdatingAutopublish) {
				$scope.settings.autopublish = status.autopublish;
			}
			_.forEach(status.modelImporterStatuses, impStatus => {
				const importerStatus = $scope.importerStatuses[impStatus.modelImporterConfigurationId];
				isUpdated = isUpdated
					|| !importerStatus
					|| impStatus.timestamp !== importerStatus.timestamp;

				const importer = _.find($scope.modelImporters, {
					id: impStatus.modelImporterConfigurationId,
				});
				if(importerStatus
						&& importerStatus.isImporting
						&& impStatus.hasSucceeded
						&& !impStatus.isImporting
				) {
					notification.success(importer.name + ": model import succeeded");
				} else if(
					$scope.importerStatuses[impStatus.modelImporterConfigurationId]
							&& $scope.importerStatuses[impStatus.modelImporterConfigurationId].isImporting
							&& !impStatus.hasSucceeded
							&& !impStatus.isImporting
				) {
					notification.error(importer.name + ": model import failed");
				}
				$scope.importerStatuses[impStatus.modelImporterConfigurationId] = impStatus;
			});
			$scope.modelStatus = status;
			$scope.modelImporters = _.sortBy(status.modelImporterConfigurations, mi => {
				return (mi.id === project.id ? "0" : "1") + (mi.name || "").toLowerCase();
			});
			$scope.modelImporters.forEach(importer => {
				if(uploadServices[importer.id] != null) {
					return;
				}
				const importerType = getImporterType(importer.typeGuid);
				if(importerType == null){
					return;
				}
				uploadServices[importer.id] = modelsUploadService.forImporter(
					project, importer, importerType
				);
			});
			$scope.importerInfos = {};
			_.forEach(status.modelImporterInfos, info => {
				$scope.importerInfos[info.modelImporterConfigurationId] = info;
			});
			if(status.modelMetaData
				&& status.modelMetaData[0]
				&& status.modelMetaData[0].revisions
				&& status.modelMetaData[0].revisions[0]) {
				$scope.model = status.modelMetaData[0];
				$scope.isProjectBusy = $scope.model.isModelBusy;
				if(_.some($scope.model.revisions, { active: true })) {
					$scope.aPublishedModelExists = true;
				}
				if($scope.model.isModelBusy && !isPublishingTimeSet) {
					isPublishingTimeSet = true;
					startPublishingCountDown();
				}
				//$scope.currentModelRevision = status.modelMetaData[0].revisions[0];
			}
			$scope.publishingPercentage = status.publishingPercentage;
			$scope.publishingMessage = status.publishingMessage;
			if($scope.isPublishingBarInErrorState) {
				if($scope.publishingPercentage !== "Publishing failed") {
					startPublishingCountDown();
					$scope.isPublishingBarInErrorState = false;
				}
			}
		});
	}

	function messageTypeToDisplayName(messageType) {
		switch(messageType) {
		case "I":
			return "INFO";
		case "W":
			return "WARNING";
		case "E":
			return "ERROR";
		default:
			return "";
		}
	}

	function messageTypeToCssTextClass(messageType) {
		switch(messageType) {
		case "W":
			return "text-warning";
		case "E":
			return "text-danger";
		default:
			return "text-dim";
		}
	}
}
