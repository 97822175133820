angular.module("eShareApp").controller("LogViewCtrl", LogViewCtrl);

LogViewCtrl.$inject = [
	"$scope", "logRepository", "$timeout", "$element"
];

function LogViewCtrl(
	$scope, logRepository, $timeout, $element
) {

	// Initialization

	$scope.isEditing = false;

	logRepository.getLoggingLevel().then(logLevel => {
		$scope.currentLoggingLevel = logLevel;
	});

	const numberRegex = /^\d+$/;

	$scope.today = new Date();
	let dd = $scope.today.getDate();
	let mm = $scope.today.getMonth() + 1; //January is 0!
	const yyyy = $scope.today.getFullYear();
	if(dd < 10) {
		dd = "0" + dd;
	}
	if(mm < 10) {
		mm = "0" + mm;
	}
	$scope.today = yyyy + "-" + mm + "-" + dd; // The default date

	$scope.log = {
		allLogDates: [],
		selectedDate: $scope.today,
		fullLog: "",
		filterTypes: {
			includeTrace: true,
			includeDebug: true,
			includeInfo: true,
			includeWarn: true,
			includeError: true,
			includeFatal: true,
		},
		numberOfTraceMessages: 0,
		numberOfDebugMessages: 0,
		numberOfInfoMessages: 0,
		numberOfWarnMessages: 0,
		numberOfErrorMessages: 0,
		numberOfFatalMessages: 0,
		showMessageSourceColumn: true,
		includeMessageSources: true,
		showThreadNumbers: true,
		timeReversed: true,
		messageFilterText: "",
		sourceFilterText: "",
		threadFilterText: "",
	};
	$scope.logPages = [];
	$scope.currentPage = 0;
	$scope.pageSize = 200;
	$scope.numberOfPages = function () {
		return $scope.logPages.length;
	};
	getDates();
	getLog($scope.today);

	// Functions

	$scope.resetSettings = function () {
		$scope.log.filterTypes = {
			includeTrace: true,
			includeDebug: true,
			includeInfo: true,
			includeWarn: true,
			includeError: true,
			includeFatal: true,
		};
		$scope.log.showMessageSourceColumn = true;
		$scope.log.includeMessageSources = true;
		$scope.log.showThreadNumbers = true;
		$scope.log.timeReversed = true;
		$scope.log.messageFilterText = "";
		$scope.log.sourceFilterText = "";
		$scope.log.threadFilterText = "";
		$scope.filter();
	};

	function getDates() {
		logRepository.getLogFileDates().then(
			pathList => {
				const dates = [];
				for(let i = 0; i < pathList.length; i++) {
					let fileName = pathList[i];
					for(let j = 0; j < pathList[i].length; j++) {
						if(pathList[i][j] === "\\") {
							fileName = pathList[i].substring(j, pathList[i].length);
						}
					}
					dates[i] = fileName.substring(1, fileName.length - 4);
				}
				$scope.log.allLogDates = dates;
			}
		);
	}

	$scope.getLog = function (date) {
		if(date === "") {
			// For the cases when the user has not selected a date,
			// the system uses the default date "today".
			date = $scope.today;
		}
		getLog(date);
	};

	function getLog(date) {
		logRepository.getLogText(date).then(
			logData => {
				$scope.log.fullLog = logData;
				processNewLog();
			}
		);
	}

	$scope.download = function () {
		logRepository.getServerMachineName().then(
			machineName => {
				const fileName = $scope.log.selectedDate + " eShare Server " + machineName + ".log";
				const blob = new Blob([$scope.log.fullLog], { type: "text/csv" });
				if(window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveBlob(blob, fileName);
				} else {
					const elem = window.document.createElement("a");
					elem.href = window.URL.createObjectURL(blob);
					elem.download = fileName;
					document.body.appendChild(elem);
					elem.click();
					document.body.removeChild(elem);
				}
			}
		);
	};

	let textRowsWithColumns = null;

	$scope.filter = function () {
		if(!textRowsWithColumns) {
			return;
		}
		const filteredTextRowsWithColumns = filterText(textRowsWithColumns);
		pagify(filteredTextRowsWithColumns);
	};

	function processNewLog() {
		getLogDate($scope.log.fullLog);
		const textRows = textToRows($scope.log.fullLog);
		textRowsWithColumns = getColumnInformation(textRows);
		const filteredTextRowsWithColumns = filterText(textRowsWithColumns);
		pagify(filteredTextRowsWithColumns);
	}

	function getLogDate(rawLogFile) {
		$scope.log.selectedDate = rawLogFile.substring(0, rawLogFile.indexOf("\n"));
		$scope.log.fullLog = rawLogFile.substring(rawLogFile.indexOf("\n") + 1);
	}

	function textToRows(logText) {
		const textRows = [];
		const lines = logText.split("\n");
		let logIndex = 0;
		const logDate = lines[0].substring(0, 10);

		for(let i = 0; i < lines.length; i++) {
			if(lines[i].length > 0) {
				if(lines[i].substring(0, 10) !== logDate) {
					textRows[logIndex - 1] += "\n" + lines[i];
				} else {
					textRows[logIndex] = lines[i];
					logIndex++;
				}
			}
		}
		if($scope.log.timeReversed) {
			textRows.reverse();
		}

		return textRows;
	}

	function getColumnInformation(textRows) {
		const result = [];
		_.forEach(textRows, row => {
			const rowInformation = {
				time: "",
				type: "",
				source: "",
				sourceColumn: "",
				message: "",
				thread: "",
			};
			if(row.length > 30) {
				if($scope.log.selectedDate !== row.substring(0, 10)) {
					rowInformation.time = row.substring(0, 11 + 13);
				} else {
					rowInformation.time = row.substring(11, 11 + 13);
				}
				rowInformation.type = row.substring(25, 25 + 5);
				if(row.indexOf("[Cadmatic.EShare.") >= 0) {
					rowInformation.source = row.substring(
						row.indexOf("[Cadmatic.EShare.") + 17, row.indexOf("]")
					);
				} else {
					rowInformation.source = row.substring(row.indexOf("[") + 1, row.indexOf("]"));
				}
				rowInformation.sourceColumn = "<span class='avoidwrap'>"
					+ rowInformation.source.replace(
						/\./g, ".</span><span class='avoidwrap'>"
					) + "</span>";
				if(row.charAt(row.indexOf("]") + 2) === "[") {
					const messageSubstring = row.substring(row.indexOf("]") + 2);
					rowInformation.thread = messageSubstring.substring(
						1, messageSubstring.indexOf("]")
					);
					rowInformation.message = messageSubstring.substring(
						messageSubstring.indexOf("]") + 2
					);
				} else {
					rowInformation.message = row.substring(row.indexOf("]") + 2);
				}
				//TODO: Turn the spaces at the beginning of message into tabs
				result.push(rowInformation);
			}
		});
		return result;
	}

	function filterText(categorizedTextArray) {
		$scope.log.numberOfTraceMessages = 0;
		$scope.log.numberOfDebugMessages = 0;
		$scope.log.numberOfInfoMessages = 0;
		$scope.log.numberOfWarnMessages = 0;
		$scope.log.numberOfErrorMessages = 0;
		$scope.log.numberOfFatalMessages = 0;
		const categorizedTextArrayClone = _.cloneDeep(categorizedTextArray);
		for(let i = 0; i < categorizedTextArrayClone.length;) {
			if(!$scope.log.filterTypes.includeTrace) {
				if(categorizedTextArrayClone[i].type.indexOf("TRACE") !== -1) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			if(!$scope.log.filterTypes.includeDebug) {
				if(categorizedTextArrayClone[i].type.indexOf("DEBUG") !== -1) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			if(!$scope.log.filterTypes.includeInfo) {
				if(categorizedTextArrayClone[i].type.indexOf("INFO") !== -1) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			if(!$scope.log.filterTypes.includeWarn) {
				if(categorizedTextArrayClone[i].type.indexOf("WARN") !== -1) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			if(!$scope.log.filterTypes.includeError) {
				if(categorizedTextArrayClone[i].type.indexOf("ERROR") !== -1) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			if(!$scope.log.filterTypes.includeFatal) {
				if(categorizedTextArrayClone[i].type.indexOf("FATAL") !== -1) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			if($scope.log.messageFilterText !== "") {
				const message = categorizedTextArrayClone[i].message.toLowerCase();
				const filterMessage = $scope.log.messageFilterText.toLowerCase();
				if(message.indexOf(filterMessage) === -1) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			if($scope.log.sourceFilterText !== "" && $scope.log.includeMessageSources) {
				const source = categorizedTextArrayClone[i].source.toLowerCase();
				const filterSource = $scope.log.sourceFilterText.toLowerCase();
				if(source.indexOf(filterSource) === -1) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			if($scope.log.threadFilterText !== "" && $scope.log.showThreadNumbers) {
				const filterValue = $scope.log.threadFilterText.toLowerCase();
				const threadValue = categorizedTextArrayClone[i].thread.toLowerCase();
				let keepRow = true;
				const isNumberFilter = numberRegex.test(filterValue);
				if(isNumberFilter) {
					const isNumberValue = numberRegex.test(threadValue);
					const filterNumber = isNumberValue ? parseInt(filterValue, 10) : NaN;
					const threadNumber = isNumberValue ? parseInt(threadValue, 10) : NaN;
					keepRow = isNumberValue
						? threadNumber === filterNumber
						: threadValue.contains(filterValue);
				} else {
					keepRow = threadValue.contains(filterValue);
				}
				if(!keepRow) {
					categorizedTextArrayClone.splice(i, 1);
					continue;
				}
			}
			switch(categorizedTextArrayClone[i].type.trim()) {
			case "TRACE":
				$scope.log.numberOfTraceMessages++;
				break;
			case "DEBUG":
				$scope.log.numberOfDebugMessages++;
				break;
			case "INFO":
				$scope.log.numberOfInfoMessages++;
				break;
			case "WARN":
				$scope.log.numberOfWarnMessages++;
				break;
			case "ERROR":
				$scope.log.numberOfErrorMessages++;
				break;
			case "FATAL":
				$scope.log.numberOfFatalMessages++;
				break;
			}
			i++;
		}
		return categorizedTextArrayClone;
	}

	function pagify(textRows) {
		$scope.logPages = [];
		$scope.currentPage = 0;
		$scope.logPages = _.chunk(textRows, $scope.pageSize);
	}

	$scope.reverseTime = function () {
		$scope.log.timeReversed = !$scope.log.timeReversed;

		_.forEach($scope.logPages, page => {
			page.reverse();
		});
		$scope.logPages.reverse();
		$scope.currentPage = 0;
	};

	$scope.messageTypeStyle = function (type) {
		switch(type.trim()) {
		case "WARN":
			return "lightgoldenrodyellow";
		case "ERROR":
			return "lightpink";
		case "FATAL":
			return "lightcoral";
		}
		return "";
	};

	$scope.scrollUp = function () {
		window.scrollTo(0, 0);
	};

	$scope.setLoggingLevel = function (logLevel) {
		logRepository.setLoggingLevel(logLevel).then(() => {
			$scope.currentLoggingLevel = logLevel;
		});
	};

	$scope.startEditing = function (targetId) {
		if($scope.numberOfPages() > 1) {
			$scope.isEditing = true;
			$scope.log.editedPageNumber = $scope.currentPage + 1;
			$timeout(() => {
				$element.find("#" + targetId).focus().select();
			}, 10);
		}
	};

	$scope.pageEditorKeyUp = function (keyCode, isLowerPageEditor) {
		switch(keyCode) {
		case 27: // Esc
		case 0: // blur
			$scope.isEditing = false;
			$scope.log.editedPageNumber = 0;
			event.preventDefaultAction = true;
			break;
		case 13:{ // Enter
			const pageNumber = +$scope.log.editedPageNumber;
			if(Utilities.isInteger(pageNumber)
				&& 1 <= pageNumber
				&& pageNumber <= $scope.numberOfPages()) {
				$scope.currentPage = pageNumber - 1;
			}
			$scope.isEditing = false;
			$scope.log.editedPageNumber = 0;
			event.preventDefaultAction = true;
			if(isLowerPageEditor) {
				$scope.scrollUp();
			}
			break;
		}
		}
	};

	$scope.isLevelAboveCurrentLogLevel = function (level) {
		switch(level) {
		case "Debug":
			return $scope.currentLoggingLevel === "Trace";
		case "Info":
			return $scope.currentLoggingLevel === "Trace"
				|| $scope.currentLoggingLevel === "Debug";
		case "Warn":
			return $scope.currentLoggingLevel === "Trace"
				|| $scope.currentLoggingLevel === "Debug"
				|| $scope.currentLoggingLevel === "Info";
		case "Error":
			return $scope.currentLoggingLevel === "Trace"
				|| $scope.currentLoggingLevel === "Debug"
				|| $scope.currentLoggingLevel === "Info"
				|| $scope.currentLoggingLevel === "Warn";
		case "Fatal":
			return $scope.currentLoggingLevel === "Trace"
			|| $scope.currentLoggingLevel === "Debug"
			|| $scope.currentLoggingLevel === "Info"
			|| $scope.currentLoggingLevel === "Warn"
			|| $scope.currentLoggingLevel === "Error";
		default:
			return false;
		}
	};
}
