angular.module("eShareApp").controller("DocumentHierarchyEditCtrl", [
	"$state", "$scope", "$stateParams", "project", "documentConfigurationsRepository",
	"dirtyFormTrackingService", "documentAttributes", "hierarchies", "notification",

	function (
		$state, $scope, $stateParams, project, documentConfigurationsRepository,
		dirtyFormTrackingService, documentAttributes, hierarchies, notification
	) {

		$scope.project = project;
		$scope.documentAttributes = documentAttributes;
		$scope.hierarchies = angular.copy(hierarchies);
		if($stateParams.hierarchyIndex >= 0
			&& $stateParams.hierarchyIndex < $scope.hierarchies.length) {
			$scope.hierarchy = $scope.hierarchies[$stateParams.hierarchyIndex];
			for(let i = 0; i < $scope.hierarchy.hierarchy.length; i++) {
				for(let j = 0; j < $scope.hierarchy.hierarchy[i].length; j++) {
					if($scope.documentAttributes.indexOf($scope.hierarchy.hierarchy[i][j]) < 0) {
						$scope.documentAttributes.push($scope.hierarchy.hierarchy[i][j]);
					}
				}
			}
		} else {
			$scope.hierarchy = {
				name: "",
				showDocumentInSubfolders: false,
				hierarchy: [[]],
			};
			$scope.hierarchies.push($scope.hierarchy);
		}

		$scope.selectOptions = {
			filter: "contains",
			placeholder: "Select one or more attributes",
		};

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		$scope.addLevel = function () {
			$scope.hierarchy.hierarchy.push([]);
			$scope.formScope.form.$setDirty();
		};

		$scope.removeLevel = function (index) {
			$scope.hierarchy.hierarchy.splice(index, 1);
			$scope.formScope.form.$setDirty();
		};

		$scope.isValid = function () {
			return $scope.hierarchy.name && !_.some($scope.hierarchies, h1 => {
				return _.some($scope.hierarchies, h2 => {
					return h1 !== h2
						&& h1.name
						&& h2.name
						&& h1.name.toLowerCase() === h2.name.toLowerCase();
				});
			});
		};

		$scope.saveChanges = function () {
			documentConfigurationsRepository
				.saveHierarchySettings($stateParams.projectId, $scope.hierarchies)
				.then(() => {
					$scope.formScope.form && $scope.formScope.form.$setPristine();
					$state.go("^", {}, { reload: true });
				}, error => {
					notification.error(error);
				});
		};

	}
]);
