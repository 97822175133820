angular.module("eShareApp").controller("UserEditCtrl", [
	"$scope", "$state", "user", "messageBox", "usersRepository",

	function (
		$scope, $state, user, messageBox, usersRepository
	) {

		const stateName = $state.current.name;

		$scope.isNew = $state.current.data.isNew;
		$scope.isBusy = false;

		if($state.current.data.isNew) {
			$scope.user = {
				name: "",
				login: "",
				isAdmin: false,
				isDeactivated: false,
				deactivationReason: null,
			};
		} else {
			$scope.user = _.cloneDeep(user);
		}

		$scope.isModifyingSelf = $scope.user.id === window.eShare.currentUser.id;

		$scope.isValid = function () {
			return $scope.user.name
				&& $scope.user.login
				&& $scope.isUserNameValid()
				&& $scope.isLoginValid();
		};

		$scope.isUserNameValid = function () {
			return angular.isString($scope.user.name)
				&& Utilities.betweenIncl($scope.user.name.trim().length, 1, 640);
		};

		$scope.isLoginValid = function () {
			return angular.isString($scope.user.login)
				&& Utilities.betweenIncl($scope.user.login.trim().length, 1, 640);
		};

		$scope.editCommit = function () {
			$scope.isBusy = true;
			let updateFunction;
			if($state.current.data.isNew) {
				updateFunction = usersRepository.add;
			} else {
				updateFunction = usersRepository.update;
			}
			updateFunction($scope.user).then(
				() => {
					goBack();
				},
				message => {
					messageBox.openError(
						"Error creating / modifying user",
						"Error creating / modifying user: " + message
					);
					$scope.isBusy = false;
				}
			);
		};

		function goBack() {
			if($state.current.name !== stateName) {
				return;
			}
			if($state.current.data.isNew) {
				$state.go("admin.users", {}, { reload: true });
			} else {
				$state.go("^.view", { userId: $scope.user.id }, { reload: true });
			}
		}
	}
]);
