angular.module("eShareApp").factory("printedDocumentInformation", printedDocumentInformation);

printedDocumentInformation.$inject = [];

function printedDocumentInformation() {

	let currentProjectId = Utilities.emptyGuid();

	const revealingModule = {
		setProject: setProject,
		clear: clear,
		document: null,
		image: null,
		currentPage: 0,
		bounds: null,
	};

	function setProject(projectId) {
		if(currentProjectId !== projectId) {
			clear();
			currentProjectId = projectId;
		}
	}

	function clear() {
		revealingModule.document = null;
		revealingModule.image = null;
		revealingModule.currentPage = 0;
		revealingModule.bounds = null;
	}

	return revealingModule;
}
