angular.module("eShareApp", ["eShare.documentViewer", "eShare.core", "eShare.ui"]);

angular.module("eShareApp").config([
	"$compileProvider", "$stateProvider", "$urlRouterProvider", "$locationProvider",
	"uiSelectConfig",
	function ($compileProvider, $stateProvider, $urlRouterProvider, $locationProvider,
		uiSelectConfig) {

		$compileProvider.debugInfoEnabled(false);

		uiSelectConfig.theme = "bootstrap";

		$urlRouterProvider.otherwise(($injector, $location) => {
			const url = $location.url() || "";
			if(url === "" || url.substring(0, 13) === "/access_token") {
				return "/";
			}
			return "/error";
		});

		const projectResolver = [
			"projectsRepository", "$stateParams", function (projectsRepository, $stateParams) {
				if($stateParams.projectId) {
					return projectsRepository.get($stateParams.projectId)
						.then(project => {
							return project;
						}, (/*error*/) => {
							return null;
						});
				}
				return null;
			}
		];

		function subMenuItemTemplate(text) {
			return "<a class='list-group-item list-group-item-indented' "
			+ "data-ui-sref-active-eq='active' data-ui-sref='.'>"
			+ text + "</a>";
		}

		$stateProvider.state("home", {
			url: "/",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\Home.html'),
			controller: "HomeCtrl",
			resolve: {
				lastUsedProjectId: [
					"applicationState", function (applicationState) {
						return applicationState.getLastUsedProjectId();
					}
				],
			},
			data: {
				accessLevel: "public",
				title: "",
			},
		});
		$stateProvider.state("selectProject", {
			url: "/selectProject?projectId&filter",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\SelectProject.html'),
			controller: "SelectProjectCtrl",
			resolve: {
				project: projectResolver,
				projects: [
					"projectsRepository", function (projectsRepository) {
						return projectsRepository.getAll();
					}
				],
				projectActivity: [
					"projectActivityRepository", function (projectActivityRepository) {
						return projectActivityRepository.getProjectActivity();
					}
				],
			},
			data: {
				accessLevel: "public",
				title: "Select Project",
			},
		});
		$stateProvider.state("projectByName", {
			url: "/p/name/{projectName}",
			template: "<div ui-view=''></div>",
			controller: "ProjectByNameCtrl",
			resolve: {
				project: [
					"projectsRepository", "$stateParams", function (projectsRepository, $stateParams) {
						if($stateParams.projectName) {
							return projectsRepository.getByName($stateParams.projectName)
								.then(project => {
									return project;
								}, (/*error*/) => {
									return null;
								});
						}
						return null;
					}
				],
			},
			data: {
				accessLevel: "user",
				title: "",
			},
		});
		$stateProvider.state("projectByName.model", {
			url:
				"/model"
				+ "?positionId"
				+ "&tag"
				+ "&geometryId"
				+ "&modelTimestamp"
				+ "&externalTag"
				+ "&externalId"
				+ "&groupParentTag"
				+ "&groupChildTag"
				+ "&groupId"
				+ "&pointId"
				+ "&markupId"
				+ "&pointCloud"
				+ "&multiExamine"
				+ "&x&y&z&r&s"
				+ "&examineFlags"
				+ "&visualStyleName"
				+ "&treeVisibility"
				+ "&attributePaneVisibility"
				+ "&hierarchyName"
				+ "&examineBranchPath"
				+ "&poiKind"
				+ "&pointReference",
			template: "<div ui-view=''></div>",
			controller: "ProjectByNameModelCtrl",
			data: {
				accessLevel: "user",
				title: "",
			},
		});
		$stateProvider.state("project", {
			url: "/p/{projectId}",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectHome.html'),
			controller: "ProjectHomeCtrl",
			resolve: {
				project: projectResolver,
				canAdministerProject: [
					"permissionsRepository", "$stateParams",
					function (permissionsRepository, $stateParams) {
						return permissionsRepository.canUserAdministerProject($stateParams.projectId);
					}
				],
				documentDataSourcesExist: [
					"documentTreeRepository", "$stateParams",
					function (documentTreeRepository, $stateParams) {
						return documentTreeRepository.documentDataSourcesExist($stateParams.projectId);
					}
				],
				maps: [
					"$stateParams", "mapsRepository",
					function ($stateParams, mapsRepository) {
						return mapsRepository.getAll($stateParams.projectId);
					}
				],
				canHandleMarkups: [
					"permissionsRepository", "$stateParams",
					function (permissionsRepository, $stateParams) {
						return permissionsRepository.canUserHandleMarkups($stateParams.projectId);
					}
				],
				whoCanDownload: [
					"modelsRepository", "$stateParams",
					function (modelsRepository, $stateParams) {
						return modelsRepository.whoIsAllowedToDownload($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "user",
				title: "",
			},
		});
		$stateProvider.state("project.licenses", {
			url: "/licenses",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\License.html'),
					controller: "LicenseCtrl",
				},
			},
			resolve: {
				project: projectResolver,
			},
			data: {
				accessLevel: "public",
				title: "eShare Licenses",
			},
		});
		$stateProvider.state("project.model", {
			url:
				"/model"
				+ "?positionId"
				+ "&tag"
				+ "&geometryId"
				+ "&modelTimestamp"
				+ "&externalTag"
				+ "&externalId"
				+ "&groupParentTag"
				+ "&groupChildTag"
				+ "&groupId"
				+ "&pointId"
				+ "&markupId"
				+ "&pointCloud"
				+ "&multiExamine"
				+ "&x&y&z&r&s"
				+ "&examineFlags"
				+ "&visualStyleName"
				+ "&treeVisibility"
				+ "&attributePaneVisibility"
				+ "&hierarchyName"
				+ "&examineBranchPath"
				+ "&poiKind"
				+ "&pointReference"
				+ "&searchFor"
				+ "&searchScope"
				+ "&createSmartPoint",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\Model3d.html'),
			controller: "Model3dCtrl",
			resolve: {
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getSearchableDefinitions($stateParams.projectId);
					}
				],
				coordinateAttributes: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getCoordinateAttributes($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "user",
				title: "Model",
			},
		});
		$stateProvider.state("project.model.print", {
			url: "/print",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\Model3dPrint.html'),
			controller: "Model3dPrintCtrl",
			controllerAs: "vm",
			resolve: {
				coordinateAttributes: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getCoordinateAttributes($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "user",
				title: "Model Print",
			},
		});
		$stateProvider.state("project.document", {
			url: "/document?dataSourceId&documentId&page&tag&val&x&y&width&height&areaId",
			template: "<div data-ui-view=''></div>",
			data: {
				accessLevel: "user",
				title: "Documents",
			},
		});
		$stateProvider.state("project.document.print", {
			url: "/print",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\documentViewer\\cadDocumentTreeViewPrint.html'),
					controller: "cadDocumentTreeViewPrintCtrl",
					controllerAs: "vm",
				},
			},
			data: {
				accessLevel: "user",
				title: "Documents print",
			},
		});
		$stateProvider.state("project.search", {
			url: "/search?isAdvanced&text&scope&terms&searchTarget&label",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\Search.html'),
			controller: "SearchCtrl",
			controllerAs: "vm",
			resolve: {
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getSearchableDefinitions($stateParams.projectId);
					}
				],
				poiAttributeKinds: [
					"$stateParams", "poiAttributeKindRepository",
					function ($stateParams, poiAttributeKindRepository) {
						return poiAttributeKindRepository
							.getForPoiKinds($stateParams.projectId).then(data => {
								_.forEach(data, types => {
									types.pointOfInterestKindId = types.pointOfInterestKindId.toString();
									types.attributeKinds = _.map(types.attributeKinds,
										kind => {
											// eslint-disable-next-line no-self-assign
											kind.abbreviation = kind.abbreviation;
											kind.displayName = kind.name;
											kind.hasHistory = types.hasHistory;
											return kind;
										});
								});
								return data;
							}, (/*error*/) => {
							//notification.error(error);
							});
					}
				],
				statusTrackingDefinitions: [
					"$stateParams", "statusTrackingKindRepository",
					function ($stateParams, statusTrackingKindRepository) {
						return statusTrackingKindRepository.getAll($stateParams.projectId);
					}
				],
				poiKinds: [
					"$stateParams", "pointOfInterestKindRepository",
					function ($stateParams, pointOfInterestKindRepository) {
						return pointOfInterestKindRepository.getAll($stateParams.projectId);
					}
				],
				savedSearches: [
					"savedSearchRepository", "project",
					function (savedSearchRepository, project) {
						return savedSearchRepository.getAll(project.id);
					}
				],
				wordExportTemplates: [
					"searchService", "project",
					function (searchService, project) {
						return searchService.getWordExportTemplates(project.id);
					}
				],
				excelExportDataSources: [
					"searchService", "project",
					function (searchService, project) {
						return searchService.getExcelExportDataSourceInfos(project.id);
					}
				],
				attributeDefinitionsUsedByObjects: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getDefinitionsUsedByObjects($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "user",
				title: "Search",
			},
		});
		$stateProvider.state("project.historicalData", {
			url: "/historicalData?datasourceId&kind&displayName&eShareKey",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\HistoricalData.html'),
			controller: "HistoricalDataCtrl",
			data: {
				accessLevel: "user",
				title: "Historical Data",
			},
		});
		$stateProvider.state("project.admin", {
			url: "/admin",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectAdmin.html'),
					controller: "ProjectAdminCtrl",
				},
			},
			resolve: {
				projects: [
					"projectsRepository", function (projectsRepository) {
						return projectsRepository.getAll();
					}
				],
				adapters: [
					"$stateParams", "adaptersRepository", function ($stateParams, adaptersRepository) {
						return adaptersRepository.getAll($stateParams.projectId);
					}
				],
				availableAdapterTypes: [
					"adaptersRepository", function (adaptersRepository) {
						return adaptersRepository.getAvailableAdapters();
					}
				],
				users: [
					"usersRepository", function (usersRepository) {
						return usersRepository.getAll();
					}
				],
				permissions: [
					"$stateParams", "permissionsRepository",
					function ($stateParams, permissionsRepository) {
						return permissionsRepository.getByProject($stateParams.projectId);
					}
				],
				isSysAdmin: [
					function () {
						return window.eShare.currentUser.role === "sysadmin";
					}
				],
				modelsInfo: [
					"$stateParams", "modelsRepository",
					function ($stateParams, modelsRepository) {
						return modelsRepository.getModelInfo($stateParams.projectId);
					}
				],
				initializedDatasources: [
					"$stateParams", "dataSourceRepository",
					function ($stateParams, dataSourceRepository) {
						return dataSourceRepository.getAvailableDataSources($stateParams.projectId);
					}
				],
				hasManagedDocuments: [
					"$stateParams", "documentConfigurationsRepository",
					function ($stateParams, documentConfigurationsRepository) {
						return documentConfigurationsRepository
							.hasAnyManagedDocuments($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Administration",
			},
		});
		$stateProvider.state("project.admin.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectExportImport.html'),
					controller: "ProjectExportImportCtrl",
					controllerAs: "vm",
				},
				"projectAdminMenuView@": {
					template: subMenuItemTemplate("Export Project"),
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Project",
			},
		});
		$stateProvider.state("project.admin.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectExportImport.html'),
					controller: "ProjectExportImportCtrl",
					controllerAs: "vm",
				},
				"projectAdminMenuView@": {
					template: subMenuItemTemplate("Import Project"),
				},
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Project",
			},
		});
		$stateProvider.state("project.admin.edit", {
			url: "/edit",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectEdit.html'),
					controller: "ProjectEditCtrl",
				},
				"projectAdminMenuView@": {
					template: subMenuItemTemplate("Edit"),
				},
			},
			resolve: {
				projects: [
					"projectsRepository", function (projectsRepository) {
						return projectsRepository.getAll();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				isNew: false,
				title: "Edit",
			},
		});
		$stateProvider.state("project.admin.model", {
			url: "/model",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectModelAdmin.html'),
					controller: "ProjectModelAdminCtrl",
				},
			},
			resolve: {
				modelImporterTypes: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository.getAllTypes($stateParams.projectId);
					}
				],
				modelStatus: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository.getStatus($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Model Configuration",
			},
		});
		$stateProvider.state("project.admin.model.create", {
			url: "/create",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelImporterTypeSelectCtrl.html'),
					controller: "ModelImporterTypeSelectCtrl",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("New Importer"),
				},
			},
			resolve: {
				modelImporterTypes: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository.getAllTypes($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "New Importer",
				isNew: true,
			},
		});
		$stateProvider.state("project.admin.model.importer", {
			url: "/{importerId:" + Utilities.guidRegexStr + "}",
			views: {
				"": {
					template: "<div data-ui-view=''></div>",
				},
			},
			"abstract": true,
			data: {
				accessLevel: "anyprojectadmin",
				title: "",
			},
		});
		$stateProvider.state("project.admin.model.importer.view", {
			url: "/view",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelImporterEdit.html'),
					controller: "ModelImporterEditCtrl",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("{{importer.name}}"),
					controller: [
						"$scope", "importer", function ($scope, importer) {
							$scope.importer = importer;
						}
					],
				},
			},
			resolve: {
				modelImporterTypes: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository.getAllTypes($stateParams.projectId);
					}
				],
				importer: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository
							.get($stateParams.projectId, $stateParams.importerId);
					}
				],
				importerConfigurationScript: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository
							.getConfigurationScript($stateParams.projectId, $stateParams.importerId);
					}
				],
				importerConfigurationTemplate: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository
							.getConfigurationTemplate($stateParams.projectId, $stateParams.importerId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Importer {{importer.name}}",
			},
		});
		$stateProvider.state("project.admin.model.importer.edit", {
			url: "/edit",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelImporterEdit.html'),
					controller: "ModelImporterEditCtrl",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("{{importer.name}}"),
					controller: [
						"$scope", "importer", function ($scope, importer) {
							$scope.importer = importer;
						}
					],
				},
			},
			resolve: {
				importer: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository
							.get($stateParams.projectId, $stateParams.importerId);
					}
				],
				importerConfigurationScript: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository
							.getConfigurationScript($stateParams.projectId, $stateParams.importerId);
					}
				],
				importerConfigurationTemplate: [
					"$stateParams", "modelImportersRepository",
					function ($stateParams, modelImportersRepository) {
						return modelImportersRepository
							.getConfigurationTemplate($stateParams.projectId, $stateParams.importerId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				isNew: false,
				title: "Edit Importer {{importer.name}}",
			},
			params: {
				isNewImporter: false,
			},
		});
		$stateProvider.state("project.admin.model.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelImporterExportImport.html'),
					controller: "ModelImporterExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Import Model Importer(s)"),
				},
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
				isSingle: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Model Importer(s)",
			},
		});
		$stateProvider.state("project.admin.model.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelImporterExportImport.html'),
					controller: "ModelImporterExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Export Model Importers"),
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
				isSingle: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Model Importers",
			},
		});
		$stateProvider.state("project.admin.model.importer.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelImporterExportImport.html'),
					controller: "ModelImporterExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Export Model Importer"),
				},
				data: {
					accessLevel: "anyprojectadmin",
					title: "Export Model Importer {{importer.name}}",
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
				isSingle: function () {
					return true;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Model Importer {{importer.name}}",
			},
		});
		$stateProvider.state("project.admin.maps", {
			url: "/maps",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\MapsConfig.html'),
					controller: "MapsConfigCtrl",
				},
			},
			resolve: {
				maps: [
					"$stateParams", "mapsRepository", function ($stateParams, mapsRepository) {
						return mapsRepository.getAll($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Map Configuration",
			},
		});
		$stateProvider.state("project.admin.maps.map", {
			url: "/{mapId:" + Utilities.guidRegexStr + "}",
			views: {
				"": {
					template: "<div data-ui-view=''></div>",
				},
			},
			"abstract": true,
			data: {
				accessLevel: "anyprojectadmin",
				title: "",
			},
		});
		$stateProvider.state("project.admin.maps.map.edit", {
			url: "/edit",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\MapsEdit.html'),
					controller: "MapsEditCtrl",
				},
				"mapsConfigView@": {
					template: subMenuItemTemplate("Map Edit"),
					controller: [
						"$scope", "map", function ($scope, map) {
							$scope.map = map;
						}
					],
				},
			},
			resolve: {
				map: [
					"$stateParams", "mapsRepository", function ($stateParams, mapsRepository) {
						return mapsRepository.getMap($stateParams.projectId, $stateParams.mapId);
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAllForProject();
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Map Edit",
			},
		});
		$stateProvider.state("project.maps", {
			url: "/maps",
			template: "<div data-ui-view=''></div>",
			data: {
				accessLevel: "user",
				title: "Map View",
			},
		});
		$stateProvider.state("project.admin.poiKind", {
			url: "/poiKind",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PoiKindAdmin.html'),
					controller: "PoiKindAdminCtrl",
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Smart Point and Markup Types",
			},
		});
		$stateProvider.state("project.admin.poiKind.poiFileImport", {
			url: "/poiFileImport",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PoiFileImport.html'),
					controller: "PoiFileImportCtrl",
				},
				"poiMenuView@": {
					template: subMenuItemTemplate("Import Smart Points from Excel"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Smart Point File Import",
			},
			params: {
				smartPointTypeId: "-1",
			},
			resolve: {
				smartPointTypes: [
					"$stateParams", "pointOfInterestKindRepository",
					function ($stateParams, pointOfInterestKindRepository) {
						return pointOfInterestKindRepository.getAll($stateParams.projectId);
					}
				],
			},
		});
		$stateProvider.state("project.admin.pointClouds", {
			url: "/pointClouds",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PointCloudsAdmin.html'),
					controller: "PointCloudsAdminCtrl",
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Point Clouds",
			},
			resolve: {
				pointClouds: [
					"$stateParams", "pointCloudsRepository",
					function ($stateParams, pointCloudsRepository) {
						return pointCloudsRepository.getPointClouds($stateParams.projectId);
					}
				],
				pointCloudMessages: [
					"$stateParams", "backgroundProgressesRepository",
					function ($stateParams, backgroundProgressesRepository) {
						return backgroundProgressesRepository
							.getTargetBackgroundMessages($stateParams.projectId, "PointCloud");
					}
				],
				pointCloudFolder: [
					"$stateParams", "pointCloudsRepository",
					function ($stateParams, pointCloudsRepository) {
						return pointCloudsRepository.getPointCloudFolder($stateParams.projectId);
					}
				],
				pointCloudSettings: [
					"$stateParams", "pointCloudsRepository",
					function ($stateParams, pointCloudsRepository) {
						return pointCloudsRepository.getPointCloudSettings($stateParams.projectId);
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAllForProject();
					}
				],
			},
		});
		$stateProvider.state("project.admin.pointClouds.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PointCloudSettingsExportImport.html'),
					controller: "PointCloudSettingsExportImportCtrl",
					controllerAs: "vm",
				},
				"pointCloudView@": {
					template: subMenuItemTemplate("Import Point Cloud Options"),
				},
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Point Cloud Options",
			},
		});
		$stateProvider.state("project.admin.pointClouds.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PointCloudSettingsExportImport.html'),
					controller: "PointCloudSettingsExportImportCtrl",
					controllerAs: "vm",
				},
				"pointCloudView@": {
					template: subMenuItemTemplate("Export Point Cloud Options"),
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Point Cloud Options",
			},
		});
		$stateProvider.state("project.admin.colors", {
			url: "/colors",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\colorsView.html'),
					controller: "ColorsViewCtrl",
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Project Colors",
			},
			resolve: {
				colors: [
					"$stateParams", "colorsRepository", function ($stateParams, colorsRepository) {
						return colorsRepository.get($stateParams.projectId);
					}
				],
			},
		});
		$stateProvider.state("project.admin.colors.edit", {
			url: "/edit",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\colorsEdit.html'),
					controller: "ColorsEditCtrl",
				},
				"colorsView@": {
					template: subMenuItemTemplate("Project Colors Edit"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Edit",
			},
		});
		$stateProvider.state("project.admin.colors.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectColorsExportImport.html'),
					controller: "ProjectColorsExportImportCtrl",
					controllerAs: "vm",
				},
				"colorsView@": {
					template: subMenuItemTemplate("Export Project Colors"),
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Project Colors",
			},
		});
		$stateProvider.state("project.admin.colors.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectColorsExportImport.html'),
					controller: "ProjectColorsExportImportCtrl",
					controllerAs: "vm",
				},
				"colorsView@": {
					template: subMenuItemTemplate("Import Project Colors"),
				},
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Project Colors",
			},
		});
		$stateProvider.state("project.admin.statusTracking", {
			url: "/statusTracking",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\StatusTrackingAdmin.html'),
					controller: "StatusTrackingAdminCtrl",
				},
			},
			resolve: {
				statusTrackingKinds: [
					"$stateParams", "statusTrackingKindRepository",
					function ($stateParams, statusTrackingKindRepository) {
						return statusTrackingKindRepository.getAll($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Status Tracking",
			},
		});
		$stateProvider.state("project.admin.statusTracking.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\StatusTrackingExportImport.html'),
					controller: "StatusTrackingExportImportCtrl",
					controllerAs: "vm",
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Status Tracking",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.statusTracking.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\StatusTrackingExportImport.html'),
					controller: "StatusTrackingExportImportCtrl",
					controllerAs: "vm",
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Status Tracking",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.adapters", {
			url: "/adapters",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\Adapters.html'),
					controller: "AdaptersCtrl",
				},
			},
			resolve: {
				adapters: [
					"$stateParams", "adaptersRepository", function ($stateParams, adaptersRepository) {
						return adaptersRepository.getAll($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Adapters and Data Sources",
			},
		});
		$stateProvider.state("project.admin.externalDocuments", {
			url: "/externalDocuments",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectExternalDocumentAdmin.html'),
					controller: "ProjectExternalDocumentAdminCtrl",
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Document Handling",
			},
			resolve: {
				pdfDocumentConfiguration: [
					"documentConfigurationsRepository", "project",
					function (documentConfigurationsRepository, project) {
						return documentConfigurationsRepository.getPdfSettings(project.id);
					}
				],
			},
		});
		$stateProvider.state("project.admin.managedDocuments", {
			url: "/managedDocuments",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectManagedDocumentAdmin.html'),
					controller: "ProjectManagedDocumentAdminCtrl",
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Managed Documents Configuration",
			},
			resolve: {
				hierarchies: [
					"documentConfigurationsRepository", "project",
					function (documentConfigurationsRepository, project) {
						return documentConfigurationsRepository.getHierarchySettings(project.id);
					}
				],
				highlightMode: [
					"documentConfigurationsRepository", "project",
					function (documentConfigurationsRepository, project) {
						return documentConfigurationsRepository
							.getManagedDocumentHighlightMode(project.id);
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAllForProject();
					}
				],
				permissions: [
					"documentConfigurationsRepository", "project",
					function (documentConfigurationsRepository, project) {
						return documentConfigurationsRepository.getManagedDocumentPermissions(project.id);
					}
				],
				savingSettings: [
					"documentConfigurationsRepository", "project",
					function (documentConfigurationsRepository, project) {
						return documentConfigurationsRepository
							.getManagedDocumentSavingSettings(project.id);
					}
				],
				indexingSettings: [
					"documentConfigurationsRepository", "project",
					function (documentConfigurationsRepository, project) {
						return documentConfigurationsRepository
							.getManagedDocumentIndexingSettings(project.id);
					}
				],
			},
		});
		$stateProvider.state("project.admin.managedDocuments.editHierarchy", {
			url: "/editHierarchy?hierarchyIndex",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentHierarchyEdit.html'),
					controller: "DocumentHierarchyEditCtrl",
				},
				"docHierarchyMenuView@": {
					template: subMenuItemTemplate("Document Hierarchy Settings"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Document Hierarchy Settings",
			},
			resolve: {
				documentAttributes: [
					"documentConfigurationsRepository", "$stateParams",
					function (documentConfigurationsRepository, $stateParams) {
						return documentConfigurationsRepository
							.getDocumentAttributes($stateParams.projectId).then(data => {
								return data;
							}, (/*error*/) => {
							//notification.error(error);
							});
					}
				],
			},
		});
		$stateProvider.state("project.admin.managedDocuments.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentHierarchiesExportImport.html'),
					controller: "DocumentHierarchiesExportImportCtrl",
					controllerAs: "vm",
				},
				"docHierarchyMenuView@": {
					template: subMenuItemTemplate("Export Document Hierarchies"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Document Hierarchies",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.managedDocuments.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentHierarchiesExportImport.html'),
					controller: "DocumentHierarchiesExportImportCtrl",
					controllerAs: "vm",
				},
				"docHierarchyMenuView@": {
					template: subMenuItemTemplate("Import Document Hierarchies"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Document Hierarchies",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.managedDocuments.exportIndexing", {
			url: "/exportIndexing",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentIndexingExportImport.html'),
					controller: "DocumentIndexingExportImportCtrl",
					controllerAs: "vm",
				},
				"docHierarchyMenuView@": {
					template: subMenuItemTemplate("Export Document Indexing Settings"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Document Indexing Settings",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.managedDocuments.importIndexing", {
			url: "/importIndexing",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentIndexingExportImport.html'),
					controller: "DocumentIndexingExportImportCtrl",
					controllerAs: "vm",
				},
				"docHierarchyMenuView@": {
					template: subMenuItemTemplate("Import Document Indexing Settings"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Document Indexing Settings",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.users", {
			url: "/users",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectUsers.html'),
					controller: "ProjectUsersCtrl",
				},
			},
			resolve: {
				users: [
					"usersRepository", function (usersRepository) {
						return usersRepository.getAll();
					}
				],
				permissions: [
					"$stateParams", "permissionsRepository",
					function ($stateParams, permissionsRepository) {
						return permissionsRepository.getByProject($stateParams.projectId);
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAll();
					}
				],
				groupPermissions: [
					"$stateParams", "permissionsRepository",
					function ($stateParams, permissionsRepository) {
						return permissionsRepository.getByProjectForGroups($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Project Users and Groups",
			},
		});
		$stateProvider.state("project.admin.users.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectUsersExportImport.html'),
					controller: "ProjectUsersExportImportCtrl",
					controllerAs: "vm",
				},
				"usersMenuView@": {
					template: subMenuItemTemplate("Export Project Users"),
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Project Users and Groups",
			},
		});
		$stateProvider.state("project.admin.users.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectUsersExportImport.html'),
					controller: "ProjectUsersExportImportCtrl",
					controllerAs: "vm",
				},
				"usersMenuView@": {
					template: subMenuItemTemplate("Import Project Users"),
				},
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Project Users and Groups",
			},
		});
		$stateProvider.state("project.admin.adapters.adapter", {
			url: "/{adapterId:" + Utilities.guidRegexStr + "}",
			views: {
				"": {
					template: "<div data-ui-view=''></div>",
				},
			},
			"abstract": true,
			data: {
				accessLevel: "anyprojectadmin",
				title: "",
			},
		});
		$stateProvider.state("project.admin.adapters.adapter.view", {
			url: "/view",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AdapterView.html'),
					controller: "AdapterViewCtrl",
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("{{adapter.name}}"),
					controller: [
						"$scope", "adapter", function ($scope, adapter) {
							$scope.adapter = adapter;
						}
					],
				},
			},
			resolve: {
				adapter: [
					"$stateParams", "adaptersRepository", function ($stateParams, adaptersRepository) {
						return adaptersRepository.get($stateParams.projectId, $stateParams.adapterId);
					}
				],
				adapterConfigurationView: [
					"$stateParams", "adaptersRepository",
					function ($stateParams, adaptersRepository) {
						return adaptersRepository
							.getConfigurationView($stateParams.projectId, $stateParams.adapterId);
					}
				],
				adapterConfigurationScript: [
					"$stateParams", "adaptersRepository",
					function ($stateParams, adaptersRepository) {
						return adaptersRepository
							.getConfigurationController($stateParams.projectId, $stateParams.adapterId);
					}
				],
				availableDataSourceTypes: [
					"$stateParams", "adapter", "adaptersRepository",
					function ($stateParams, adapter, adaptersRepository) {
						const adapterTypeGuid = adapter.typeGuid;
						return adaptersRepository.getAvailableAdapters().then(
							availableAdapterTypes => {
								const adapterType = _.find(availableAdapterTypes, at => {
									return at.typeGuid === adapterTypeGuid;
								});
								return adapterType.dataSourceTypeInfoDtos;
							},
							(/*error*/) => {
								//notification.error(error);
							}
						);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Adapter {{adapter.name}}",
			},
		});
		$stateProvider.state("project.admin.adapters.adapter.edit", {
			url: "/edit",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AdapterEdit.html'),
					controller: "AdapterEditCtrl",
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("{{adapter.name}}"),
					controller: [
						"$scope", "adapter", function ($scope, adapter) {
							$scope.adapter = adapter;
						}
					],
				},
			},
			resolve: {
				adapter: [
					"$stateParams", "adaptersRepository",
					function ($stateParams, adaptersRepository) {
						return adaptersRepository.get($stateParams.projectId, $stateParams.adapterId);
					}
				],
				adapterConfigurationView: [
					"adapter", "project", "adaptersRepository",
					function (adapter, project, adaptersRepository) {
						return adaptersRepository.getConfigurationView(project.id, adapter.id);
					}
				],
				adapterConfigurationScript: [
					"adapter", "project", "adaptersRepository",
					function (adapter, project, adaptersRepository) {
						return adaptersRepository.getConfigurationController(project.id, adapter.id);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				isNew: false,
				title: "Edit Adapter {{adapter.name}}",
			},
		});
		$stateProvider.state("project.admin.adapters.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AdaptersExportImport.html'),
					controller: "AdaptersExportImportCtrl",
					controllerAs: "vm",
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("Import Adapter(s)"),
				},
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Adapter(s)",
			},
		});
		$stateProvider.state("project.admin.adapters.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AdaptersExportImport.html'),
					controller: "AdaptersExportImportCtrl",
					controllerAs: "vm",
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("Export Adapters"),
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Adapters",
			},
		});
		$stateProvider.state("project.admin.adapters.adapter.view.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AdapterImportExport.html'),
					controller: "AdapterExportCtrl",
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("Export Adapter"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Adapter {{adapter.name}}",
			},
		});
		$stateProvider.state("project.admin.adapters.create", {
			url: "/create",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AdapterTypeSelect.html'),
					controller: "AdapterTypeSelectCtrl",
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("New Adapter"),
				},
			},
			resolve: {
				adapterTypes: [
					"adaptersRepository", function (adaptersRepository) {
						return adaptersRepository.getAvailableAdapters();
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "New Adapter",
			},
		});
		$stateProvider.state("project.admin.adapters.adapter.view.dataSource", {
			url: "/{dataSourceId:" + Utilities.guidRegexStr + "}",
			views: {
				"": {
					template: "<div data-ui-view=''></div>",
				},
			},
			"abstract": true,
			data: {
				accessLevel: "anyprojectadmin",
				title: "",
			},
		});
		$stateProvider.state("project.admin.adapters.adapter.view.dataSource.view", {
			url: "/view",
			views: {
				"": {
					controller: "DataSourceViewCtrl",
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DataSourceView.html'),
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("Data Source"),
				},
			},
			resolve: {
				dataSourceConfigurationView: [
					"$stateParams", "dataSourceRepository",
					function ($stateParams, dataSourceRepository) {
						return dataSourceRepository
							.getConfigurationView($stateParams.projectId, $stateParams.dataSourceId);
					}
				],
				dataSourceConfigurationScript: [
					"$stateParams", "dataSourceRepository",
					function ($stateParams, dataSourceRepository) {
						return dataSourceRepository
							.getConfigurationController($stateParams.projectId, $stateParams.dataSourceId);
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAllForProject();
					}
				],
				permissions: [
					"$stateParams", "permissionsRepository",
					function ($stateParams, permissionsRepository) {
						return permissionsRepository
							.getForDataSource($stateParams.projectId, $stateParams.dataSourceId);
					}
				],
				adapter: [
					"$stateParams", "adaptersRepository", function ($stateParams, adaptersRepository) {
						return adaptersRepository.get($stateParams.projectId, $stateParams.adapterId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				// FIXME: Should be {{dataSource.name}} but that isn't defined here
				title: "Data Source",
			},
		});
		$stateProvider.state("project.admin.adapters.adapter.view.dataSource.edit", {
			url: "/edit",
			views: {
				"": {
					controller: "DataSourceEditCtrl",
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DataSourceEdit.html'),
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("Data Source"),
				},
			},
			resolve: {
				dataSourceConfigurationView: [
					"$stateParams", "dataSourceRepository",
					function ($stateParams, dataSourceRepository) {
						return dataSourceRepository
							.getConfigurationView($stateParams.projectId, $stateParams.dataSourceId);
					}
				],
				dataSourceConfigurationScript: [
					"$stateParams", "dataSourceRepository",
					function ($stateParams, dataSourceRepository) {
						return dataSourceRepository
							.getConfigurationController($stateParams.projectId, $stateParams.dataSourceId);
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAllForProject();
					}
				],
				permissions: [
					"$stateParams", "permissionsRepository",
					function ($stateParams, permissionsRepository) {
						return permissionsRepository
							.getForDataSource($stateParams.projectId, $stateParams.dataSourceId);
					}
				],
				adapter: [
					"$stateParams", "adaptersRepository", function ($stateParams, adaptersRepository) {
						return adaptersRepository.get($stateParams.projectId, $stateParams.adapterId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				isNew: false,
				// FIXME: Should be {{dataSource.name}} but that isn't defined here
				title: "Data Source",
			},
		});
		$stateProvider.state("project.admin.adapters.adapter.view.create", {
			url: "/create",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DataSourceTypeSelect.html'),
					controller: "DataSourceTypeSelectCtrl",
				},
				"adapterMenuView@": {
					template: subMenuItemTemplate("New Data Source"),
				},
			},
			resolve: {
				adapterTypes: [
					"adaptersRepository", function (adaptersRepository) {
						return adaptersRepository.getAvailableAdapters();
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				isNew: true,
				title: "New Data Source",
			},
		});
		$stateProvider.state("project.admin.statusTracking.editStatusTracking", {
			url: "/edit?statusTrackingId",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\StatusTrackingEdit.html'),
					controller: "StatusTrackingEditCtrl",
				},
				"statusTrackingMenuView@": {
					template: subMenuItemTemplate("Edit"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Edit Status Tracking",
			},
			resolve: {
				statusTracking: [
					"$stateParams", "statusTrackingKindRepository",
					function ($stateParams, statusTrackingKindRepository) {
						if($stateParams.statusTrackingId < 0) {
							return null;
						}
						return statusTrackingKindRepository
							.get($stateParams.projectId, $stateParams.statusTrackingId).then(data => {
								return data;
							}, (/*error*/) => {
							//notification.error(error);
							});
					}
				],
				possibleTargetAttributes: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getIdAndGroupDefinitions($stateParams.projectId).then(data => {
								return data;
							}, (/*error*/) => {
							//notification.error(error);
							});
					}
				],
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getDefinitions($stateParams.projectId).then(data => {
								return data;
							}, (/*error*/) => {
							//notification.error(error);
							});
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAllForProject();
					}
				],

			},
		});
		$stateProvider.state("project.admin.poiKind.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PointOfInterestKindExportImport.html'),
					controller: "PointOfInterestKindExportImportCtrl",
					controllerAs: "vm",
				},
				"poiMenuView@": {
					template: subMenuItemTemplate("Smart Point and Markup Types Export"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.poiKind.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PointOfInterestKindExportImport.html'),
					controller: "PointOfInterestKindExportImportCtrl",
					controllerAs: "vm",
				},
				"poiMenuView@": {
					template: subMenuItemTemplate("Smart Point and Markup Types Import"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.poiKind.editPointOfInterestKind", {
			url: "/edit?pointOfInterestKindId?isMarkup",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PointOfInterestKindEdit.html'),
					controller: "PointOfInterestKindEditCtrl",
				},
				"poiMenuView@": {
					template: subMenuItemTemplate("Smart Point Type"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Edit",
			},
			resolve: {
				pointKindInfo: [
					"$stateParams", function ($stateParams) {
						const kindInfo = {};
						kindInfo.isMarkup = $stateParams.isMarkup && $stateParams.isMarkup === "true";
						return kindInfo;
					}
				],
				pointOfInterestKind: [
					"$stateParams", "pointOfInterestKindRepository",
					function ($stateParams, poikRepository) {
						if($stateParams.pointOfInterestKindId < 0) {
							return null;
						}
						return poikRepository
							.get($stateParams.projectId, $stateParams.pointOfInterestKindId)
							.then(data => {
								return data;
							});
					}
				],
				pointOfInterestKinds: [
					"$stateParams", "pointOfInterestKindRepository",
					function ($stateParams, poikRepository) {
						return poikRepository.getAll($stateParams.projectId)
							.then(data => {
								return data;
							});
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAllForProject();
					}
				],
				icons: [
					"iconsRepository", function (iconsRepository) {
						return iconsRepository.getIcons();
					}
				],
				attributeKinds: [
					"$stateParams", "poiAttributeKindRepository",
					function ($stateParams, poiAttributeKindRepository) {
						return poiAttributeKindRepository.getAll($stateParams.projectId).then(data => {
							return data;
						}, (/*error*/) => {
							//notification.error(error);
						});
					}
				],
				pointOfInterestColors: [
					"$stateParams", "pointOfInterestKindRepository",
					function ($stateParams, poikRepository) {
						if($stateParams.pointOfInterestKindId >= 0) {
							return [];
						}
						return poikRepository.getAll($stateParams.projectId).then(data => {
							return _.map(data, kind => {
								return kind.color;
							});
						}, (/*error*/) => {
							//notification.error(error);
						});
					}
				],
			},
		});
		$stateProvider.state("project.admin.poiKind.editPoiAttributeKinds", {
			url: "/editAttributes",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\PoiAttributeKindsEdit.html'),
					controller: "PoiAttributeKindsEditCtrl",
				},
				"poiMenuView@": {
					template: subMenuItemTemplate("Smart Point and Markup Attribute Types"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Smart Point and Markup Attribute Types",
			},
			resolve: {
				poiAttributeKinds: [
					"$stateParams", "poiAttributeKindRepository",
					function ($stateParams, poiAttributeKindRepository) {
						return poiAttributeKindRepository.getAll($stateParams.projectId).then(data => {
							return data;
						}, (/*error*/) => {
							//notification.error(error);
						});
					}
				],
			},
		});
		$stateProvider.state("project.admin.externalDocuments.editDocType", {
			url: "/editDocType?documentTypeIndex",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentTypeEdit.html'),
					controller: "DocumentTypeEditCtrl",
				},
				"docMenuView@": {
					template: subMenuItemTemplate("Document Type Settings"),
				},
			},
			resolve: {
				documentDataSources: [
					"dataSourceRepository", "$stateParams",
					function (dataSourceRepository, $stateParams) {
						return dataSourceRepository
							.getAvailableDocumentDataSources($stateParams.projectId).then(data => {
								return data;
							}, (/*error*/) => {
							//notification.error(error);
							});
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Document Type Settings",
			},
		});
		$stateProvider.state("project.admin.externalDocuments.editDocFormat", {
			url: "/editDocFormat?documentFormatIndex",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentFormatEdit.html'),
					controller: "DocumentFormatEditCtrl",
				},
				"docMenuView@": {
					template: subMenuItemTemplate("Document Conversion Format Settings"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Document Conversion Format Settings",
			},
		});
		$stateProvider.state("project.admin.externalDocuments.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentTypesExportImport.html'),
					controller: "DocumentTypesExportImportCtrl",
					controllerAs: "vm",
				},
				"docMenuView@": {
					template: subMenuItemTemplate("Document Types Import"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Document Types Import",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.externalDocuments.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\DocumentTypesExportImport.html'),
					controller: "DocumentTypesExportImportCtrl",
					controllerAs: "vm",
				},
				"docMenuView@": {
					template: subMenuItemTemplate("Document Types Export"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Document Types Export",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.variables", {
			url: "/variables",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\variablesView.html'),
					controller: "variablesViewCtrl",
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Project Variables",
			},
		});
		$stateProvider.state("project.admin.variables.edit", {
			url: "/edit",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\variablesEdit.html'),
					controller: "variablesEditCtrl",
				},
				"variablesView@": {
					template: subMenuItemTemplate("Project Variables"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Project Variables",
			},
		});
		$stateProvider.state("project.admin.variables.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\projectVariablesExportImport.html'),
					controller: "projectVariablesExportImportCtrl",
					controllerAs: "vm",
				},
				"variablesView@": {
					template: subMenuItemTemplate("Export Project Variables"),
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Project Variables",
			},
		});
		$stateProvider.state("project.admin.variables.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\projectVariablesExportImport.html'),
					controller: "projectVariablesExportImportCtrl",
					controllerAs: "vm",
				},
				"variablesView@": {
					template: subMenuItemTemplate("Import Project Variables"),
				},
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Project Variables",
			},
		});
		$stateProvider.state("project.admin.model.transformations", {
			url: "/transformations/{modelId}",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeTransformations.html'),
					controller: "AttributeTransformationsCtrl",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Attribute Settings"),
				},
			},
			resolve: {
				modelInfo: [
					"$stateParams", "modelsRepository",
					function ($stateParams, modelsRepository) {
						return modelsRepository
							.getModelRevisionInfo($stateParams.projectId, $stateParams.modelId);
					}
				],
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.get($stateParams.projectId, $stateParams.modelId);
					}
				],
				attributeDefinitionsInDatabase: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getDatabaseAttributes($stateParams.projectId, $stateParams.modelId, true);
					}
				],
				attributeTransformations: [
					"$stateParams", "attributeTransformationsRepository",
					function ($stateParams, attributeTransformationsRepository) {
						return attributeTransformationsRepository
							.get($stateParams.projectId, $stateParams.modelId);
					}
				],
				groupDefiningAbbreviations: [
					"$stateParams", "attributeTransformationsRepository",
					function ($stateParams, attributeTransformationsRepository) {
						return attributeTransformationsRepository
							.getGroupDefiningAbbreviations($stateParams.projectId);
					}
				],
				keyDefiningAbbreviations: [
					"$stateParams", "attributeTransformationsRepository",
					function ($stateParams, attributeTransformationsRepository) {
						return attributeTransformationsRepository
							.getKeyDefiningAbbreviations($stateParams.projectId);
					}
				],
				excludeFromPublishAbbreviations: [
					"$stateParams", "attributeTransformationsRepository",
					function ($stateParams, attributeTransformationsRepository) {
						return attributeTransformationsRepository
							.getExcludeFromPublishAbbreviations($stateParams.projectId);
					}
				],
				isSysAdmin: [
					function () {
						return window.eShare.currentUser.role === "sysadmin";
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Attribute Settings",
			},
		});
		$stateProvider.state("project.admin.model.transformations.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeTransformationsExportImport.html'),
					controller: "AttributeTransformationsExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Import Attribute Settings"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.model.transformations.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeTransformationsExportImport.html'),
					controller: "AttributeTransformationsExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Export Attribute Settings"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.model.attributeCategorization", {
			url: "/attributeCategorization/{modelId}",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeCategorization.html'),
					controller: "AttributeCategorizationCtrl",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Attribute Categorization"),
				},
			},
			resolve: {
				attributeCategorizations: [
					"$stateParams", "attributeCategorizationRepository",
					function ($stateParams, attributeCategorizationRepository) {
						return attributeCategorizationRepository.get($stateParams.projectId);
					}
				],
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository.getDatabaseAttributesForObjects(
							$stateParams.projectId,
							$stateParams.modelId,
							true
						);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Attribute Categorization",
			},
		});
		$stateProvider.state("project.admin.model.attributeCategorization.edit", {
			url: "/edit/{categorizationId}",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeCategorizationEdit.html'),
					controller: "AttributeCategorizationEditCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Attribute Categorization Edit"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Edit Attribute Categorization",
			},
			resolve: {
				attributeCategorization: [
					"$stateParams", "attributeCategorizationRepository",
					function ($stateParams, attributeCategorizationRepository) {
						return attributeCategorizationRepository
							.getById($stateParams.projectId, $stateParams.categorizationId);
					}
				],
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAllForProject();
					}
				],
				permissions: [
					"$stateParams", "permissionsRepository",
					function ($stateParams, permissionsRepository) {
						return permissionsRepository.getForAttributeCategorization(
							$stateParams.projectId,
							$stateParams.categorizationId
						);
					}
				],
			},
		});
		$stateProvider.state("project.admin.model.attributeCategorization.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeCategorizationExportImport.html'),
					controller: "AttributeCategorizationExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Attribute Categorizations Export"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Attribute Categorizations",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.model.attributeCategorization.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeCategorizationExportImport.html'),
					controller: "AttributeCategorizationExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Attribute Categorizations Import"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Attribute Categorizations",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.model.hierarchies", {
			url: "/hierarchies",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelHierarchies.html'),
					controller: "ModelHierarchiesCtrl",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Model Hierarchies"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Model Hierarchies",
			},
			resolve: {
				modelHierarchies: [
					"$stateParams", "modelHierarchiesRepository",
					function ($stateParams, modelHierarchiesRepository) {
						return modelHierarchiesRepository.get($stateParams.projectId);
					}
				],
				originalHierarchies: [
					"$stateParams", "modelHierarchiesRepository",
					function ($stateParams, modelHierarchiesRepository) {
						return modelHierarchiesRepository.getOriginal($stateParams.projectId);
					}
				],
			},
		});
		$stateProvider.state("project.admin.model.hierarchies.editHierarchy", {
			url: "/editHierarchy?hierarchyIndex",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelHierarchyEdit.html'),
					controller: "ModelHierarchyEditCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Model Hierarchies"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Model Hierarchy",
			},
			resolve: {
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getDefinitionsUsedByObjects($stateParams.projectId);
					}
				],
			},
		});
		$stateProvider.state("project.admin.model.hierarchies.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelHierarchiesExportImport.html'),
					controller: "ModelHierarchiesExportImportCtrl",
					controllerAs: "vm",
				},
				"docHierarchyMenuView@": {
					template: subMenuItemTemplate("Export Model Hierarchies"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Document Hierarchies",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.model.hierarchies.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ModelHierarchiesExportImport.html'),
					controller: "ModelHierarchiesExportImportCtrl",
					controllerAs: "vm",
				},
				"docHierarchyMenuView@": {
					template: subMenuItemTemplate("Import Model Hierarchies"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Document Hierarchies",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.model.attributeVisibility", {
			url: "/attributeVisibility",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeVisibility.html'),
					controller: "AttributeVisibilityCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Attribute Visibility and Order"),
				},
			},
			resolve: {
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository.getDefinitions($stateParams.projectId);
					}
				],
				hideEmptyAttributes: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getHideEmptyAttributes($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Attribute Visibility and Order",
			},
		});
		$stateProvider.state("project.admin.model.attributeVisibility.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeVisibilityExportImport.html'),
					controller: "AttributeVisibilityExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Attribute Visibilities and Order Export"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Attribute Visibilities and Order",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.model.attributeVisibility.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\AttributeVisibilityExportImport.html'),
					controller: "AttributeVisibilityExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("Attribute Visibilities and Order Import"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Attribute Visibilities and Order",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.subModel", {
			url: "/subModel",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\SubModelView.html'),
					controller: "SubModelViewCtrl",
					controllerAs: "vm",
				},
			},
			resolve: {
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getSearchableDefinitions($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Sub-Models",
			},
		});
		$stateProvider.state("project.admin.subModel.rules", {
			url: "/rules?ruleId",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\SubModelDivisionRulesConfiguration.html'),
					controller: "SubModelDivisionRulesConfigurationCtrl",
					controllerAs: "vm",
				},
				"subModelMenuView@": {
					template: subMenuItemTemplate("Manage Rules"),
				},
			},
			resolve: {
				attributeDefinitions: [
					"$stateParams", "attributeDefinitionRepository",
					function ($stateParams, attributeDefinitionRepository) {
						return attributeDefinitionRepository
							.getSearchableDefinitions($stateParams.projectId);
					}
				],
				poiAttributeKinds: [
					"$stateParams", "poiAttributeKindRepository",
					function ($stateParams, poiAttributeKindRepository) {
						return poiAttributeKindRepository
							.getForPoiKinds($stateParams.projectId).then(data => {
								_.forEach(data, types => {
									types.pointOfInterestKindId = types.pointOfInterestKindId.toString();
									types.attributeKinds = _.map(types.attributeKinds,
										kind => {
											// eslint-disable-next-line no-self-assign
											kind.abbreviation = kind.abbreviation;
											kind.displayName = kind.name;
											kind.hasHistory = types.hasHistory;
											return kind;
										});
								});
								return data;
							}, (/*error*/) => {
							//notification.error(error);
							});
					}
				],
				statusTrackingDefinitions: [
					"$stateParams", "statusTrackingKindRepository",
					function ($stateParams, statusTrackingKindRepository) {
						return statusTrackingKindRepository.getAll($stateParams.projectId);
					}
				],
				poiKinds: [
					"$stateParams", "pointOfInterestKindRepository",
					function ($stateParams, pointOfInterestKindRepository) {
						return pointOfInterestKindRepository.getAll($stateParams.projectId);
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Sub-Model Division Rules",
			},
		});
		$stateProvider.state("project.admin.subModel.rules.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\SubModelDivisionRulesExportImport.html'),
					controller: "subModelDivisionRulesExportImportCtrl",
					controllerAs: "vm",
				},
				"subModelMenuView@": {
					template: subMenuItemTemplate("Sub-Model Division Rules Export"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export Sub-Model Division Rules",
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
		});
		$stateProvider.state("project.admin.subModel.rules.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\SubModelDivisionRulesExportImport.html'),
					controller: "subModelDivisionRulesExportImportCtrl",
					controllerAs: "vm",
				},
				"subModelMenuView@": {
					template: subMenuItemTemplate("Sub-Model Division Rules Import"),
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import Sub-Model Division Rules",
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
		});
		$stateProvider.state("project.admin.model.eGoSettings", {
			url: "/eGoSettings",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\eGoSettings.html'),
					controller: "eGoSettingsCtrl",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("eGo QR Configuration"),
				},
			},
			resolve: {
				isSysAdmin: [
					function () {
						return window.eShare.currentUser.role === "sysadmin";
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "eGo QR Configuration",
			},
		});
		$stateProvider.state("project.admin.model.eGoSettings.export", {
			url: "/export",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\eGoSettingsExportImport.html'),
					controller: "eGoSettingsExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("eGo settings Export"),
				},
			},
			resolve: {
				isExport: function () {
					return true;
				},
				isImport: function () {
					return false;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Export",
			},
		});
		$stateProvider.state("project.admin.model.eGoSettings.import", {
			url: "/import",
			views: {
				"": {
					template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\eGoSettingsExportImport.html'),
					controller: "eGoSettingsExportImportCtrl",
					controllerAs: "vm",
				},
				"modelMenuView@": {
					template: subMenuItemTemplate("eGo settings Import"),
				},
			},
			resolve: {
				isExport: function () {
					return false;
				},
				isImport: function () {
					return true;
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Import",
			},
		});
		$stateProvider.state("admin", {
			url: "/admin?projectId",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\Administration.html'),
			controller: "AdministrationCtrl",
			resolve: {
				project: projectResolver,
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Administration",
			},
		});
		$stateProvider.state("admin.users", {
			url: "/user",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\UserList.html'),
			controller: "UserListCtrl",
			resolve: {
				users: [
					"usersRepository", function (usersRepository) {
						return usersRepository.getAll();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				title: "Users",
			},
		});
		$stateProvider.state("admin.users.user", {
			url: "/{userId:" + Utilities.guidRegexStr + "}",
			template: "<div data-ui-view=''></div>",
			"abstract": true,
			data: {
				accessLevel: "sysadmin",
				title: "",
			},
		});
		$stateProvider.state("admin.users.user.view", {
			url: "/view",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\UserView.html'),
			controller: "UserViewCtrl",
			resolve: {
				user: [
					"$stateParams", "usersRepository", function ($stateParams, usersRepository) {
						return usersRepository.get($stateParams.userId);
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				title: "{{user.name}}",
			},
		});
		$stateProvider.state("admin.users.user.edit", {
			url: "/edit",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\UserEdit.html'),
			controller: "UserEditCtrl",
			resolve: {
				user: [
					"$stateParams", "usersRepository", function ($stateParams, usersRepository) {
						return usersRepository.get($stateParams.userId);
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				isNew: false,
				title: "Edit {{user.name}}",
			},
		});
		$stateProvider.state("admin.users.create", {
			url: "/create",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\UserEdit.html'),
			controller: "UserEditCtrl",
			resolve: {
				user: function () {
					return {};
				},
			},
			data: {
				accessLevel: "sysadmin",
				isNew: true,
				title: "New User",
			},
		});

		$stateProvider.state("admin.groups", {
			url: "/group",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\GroupList.html'),
			controller: "groupListCtrl",
			resolve: {
				groups: [
					"groupsRepository", function (groupsRepository) {
						return groupsRepository.getAll();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				title: "groups",
			},
		});
		$stateProvider.state("admin.groups.group", {
			url: "/{groupId:" + Utilities.guidRegexStr + "}",
			template: "<div data-ui-view=''></div>",
			"abstract": true,
			data: {
				accessLevel: "sysadmin",
				title: "",
			},
		});
		$stateProvider.state("admin.groups.group.view", {
			url: "/view",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\GroupView.html'),
			controller: "groupViewCtrl",
			resolve: {
				group: [
					"$stateParams", "groupsRepository", function ($stateParams, groupsRepository) {
						return groupsRepository.get($stateParams.groupId);
					}
				],
				users: [
					"usersRepository", function (usersRepository) {
						return usersRepository.getAll();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				title: "{{group.name}}",
			},
		});
		$stateProvider.state("admin.groups.group.edit", {
			url: "/edit",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\GroupEdit.html'),
			controller: "groupEditCtrl",
			resolve: {
				group: [
					"$stateParams", "groupsRepository", function ($stateParams, groupsRepository) {
						return groupsRepository.get($stateParams.groupId);
					}
				],
				users: [
					"usersRepository", function (usersRepository) {
						return usersRepository.getAll();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				isNew: false,
				title: "Edit {{group.name}}",
			},
		});
		$stateProvider.state("admin.groups.create", {
			url: "/create",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\GroupEdit.html'),
			controller: "groupEditCtrl",
			resolve: {
				group: function () {
					return {};
				},
				users: [
					"usersRepository", function (usersRepository) {
						return usersRepository.getAll();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				isNew: true,
				title: "New group",
			},
		});
		$stateProvider.state("admin.dashboard", {
			url: "/dashboard",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\SysAdminDashboard.html'),
			controller: "SysAdminDashboardCtrl",
			resolve: {
				project: projectResolver,
				projects: [
					"projectsRepository", function (projectsRepository) {
						return projectsRepository.getAll();
					}
				],
				availableAdapterTypes: [
					"adaptersRepository", function (adaptersRepository) {
						return adaptersRepository.getAvailableAdapters();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				title: "Dashboard",
			},
		});
		$stateProvider.state("admin.dashboard.log", {
			url: "/log",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\LogView.html'),
			controller: "LogViewCtrl",
			resolve: {
			},
			data: {
				accessLevel: "sysadmin",
				title: "eShare Log View",
			},
		});
		$stateProvider.state("admin.dashboard.backgroundTasks", {
			url: "/backgroundTasks",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectStatusView.html'),
			controller: "ProjectStatusViewCtrl",
			resolve: {
				project: projectResolver,
				projects: [
					"projectsRepository", function (projectsRepository) {
						return projectsRepository.getAll();
					}
				],
				availableAdapterTypes: [
					"adaptersRepository", function (adaptersRepository) {
						return adaptersRepository.getAvailableAdapters();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				title: "Dashboard",
			},
		});

		$stateProvider.state("admin.projects", {
			url: "/project",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectList.html'),
			controller: "ProjectListCtrl",
			resolve: {
				projects: [
					"projectsRepository", function (projectsRepository) {
						return projectsRepository.getAll();
					}
				],
			},
			data: {
				accessLevel: "anyprojectadmin",
				title: "Projects",
			},
		});
		$stateProvider.state("admin.projects.create", {
			url: "/create",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\ProjectEdit.html'),
			controller: "ProjectEditCtrl",
			resolve: {
				project: function () {
					return {};
				},
			},
			data: {
				accessLevel: "anyprojectadmin",
				isNew: true,
				title: "New Project",
			},
		});
		$stateProvider.state("admin.eGoDevices", {
			url: "/eGoDevices",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\eGoSynchronizedDevices.html'),
			controller: "eGoSynchronizedDevicesCtrl",
			resolve: {
				projects: [
					"projectsRepository", function (projectsRepository) {
						return projectsRepository.getAll();
					}
				],
			},
			data: {
				accessLevel: "sysadmin",
				title: "Devices",
			},
		});
		$stateProvider.state("userProfile", {
			url: "/userProfile/{userId:" + Utilities.guidRegexStr + "}?projectId",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\UserProfile.html'),
			controller: "UserProfileCtrl",
			resolve: {
				user: [
					"$stateParams", "usersRepository", function ($stateParams, usersRepository) {
						if($stateParams.projectId) {
							return usersRepository
								.getWithProjectId($stateParams.projectId, $stateParams.userId);
						} else {
							return usersRepository.get($stateParams.userId);
						}
					}
				],
				project: projectResolver,
				canHandleMarkups: [
					"permissionsRepository", "$stateParams",
					function (permissionsRepository, $stateParams) {
						if($stateParams.projectId) {
							return permissionsRepository.canUserHandleMarkups($stateParams.projectId);
						}
						return false;
					}
				],
				canAdministerProject: [
					"permissionsRepository", "$stateParams",
					function (permissionsRepository, $stateParams) {
						if($stateParams.projectId) {
							return permissionsRepository.canUserAdministerProject($stateParams.projectId);
						}
						return false;
					}
				],
				documentDataSourcesExist: [
					"documentTreeRepository", "$stateParams",
					function (documentTreeRepository, $stateParams) {
						if($stateParams.projectId) {
							return documentTreeRepository.documentDataSourcesExist($stateParams.projectId);
						}
						return false;
					}
				],
				maps: [
					"$stateParams", "mapsRepository", function ($stateParams, mapsRepository) {
						if($stateParams.projectId) {
							return mapsRepository.getAll($stateParams.projectId);
						}
						return [];
					}
				],
			},
			data: {
				accessLevel: "user",
				isNew: false,
				title: "User Profile {{user.name}}",
			},
		});
		$stateProvider.state("notifications", {
			url: "/notifications?projectId",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\Notifications.html'),
			controller: "NotificationsCtrl",
			resolve: {
				project: projectResolver,
			},
			data: {
				accessLevel: "user",
				title: "Notifications",
			},
		});
		$stateProvider.state("error", {
			url: "/error?projectId",
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\Error.html'),
			controller: "ErrorCtrl",
			resolve: {
				project: projectResolver,
			},
			data: {
				accessLevel: "public",
				title: "Error",
			},
		});

		$locationProvider.html5Mode(false);
	}
]);

angular.module("eShareApp").config([
	"$compileProvider", function ($compileProvider) {
		if($compileProvider.aHrefSanitizationWhitelist()
			.source === "^\\s*(https?|ftp|mailto|tel|webcal|local|file|data|blob):") {
			if(window.eShare.protocolWhitelist) {
				const whitelist = "^\\s*(https?|ftp|mailto|tel|webcal|local|file|data|blob|"
					+ window.eShare.protocolWhitelist + "):";
				$compileProvider.aHrefSanitizationWhitelist(new RegExp(whitelist));
			}
		} else {
			console.log(
				"Angular sanitize is updated. Fix how the setting from AppSettings.Config is handled."
			);
		}
	}
]);
