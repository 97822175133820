angular.module("eShareApp").controller("ProjectExportImportCtrl", ProjectExportImportCtrl);

ProjectExportImportCtrl.$inject = [
	"$state", "notification", "projectsRepository", "project", "isExport", "isImport"
];

function ProjectExportImportCtrl(
	$state, notification, projectsRepository, project, isExport, isImport
) {
	const vm = this;
	vm.isExport = isExport;
	vm.isImport = isImport;
	vm.serializedExportedData = "";
	vm.shouldImportProjectAdminPermissions = false;
	vm.shouldImportMarkup = false;
	vm.importResult = null;
	vm.importAll = importAll;
	vm.isInputValid = isInputValid;

	initialize();

	function initialize() {
		if(isExport) {
			vm.serializedExportedData = "Please wait...";
			projectsRepository.exportAll(project.id).then(
				serializedExportedData => {
					vm.serializedExportedData = serializedExportedData;
				}
			);
		}
	}

	function importAll() {
		vm.importResult = null;
		projectsRepository
			.importAll(
				project.id,
				vm.serializedExportedData,
				vm.shouldImportProjectAdminPermissions,
				vm.shouldImportMarkup
			)
			.then(
				importResult => {
					if(importResult.hasSucceeded && !importResult.hasWarnings) {
						notification.success("Imported project");
						$state.go("^", {}, { reload: true });
					} else {
						if(importResult.hasSucceeded) {
							notification.success("Imported project, with warnings");
						}
						vm.importResult = importResult;
					}
				}
			);
	}

	function isInputValid() {
		return Utilities.isJsonObject(vm.serializedExportedData);
	}
}
