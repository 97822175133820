angular.module("eShareApp").factory("projectVariablesRepository", projectVariablesRepository);

projectVariablesRepository.$inject = ["repositoryBase"];

function projectVariablesRepository(repositoryBase) {
	const baseApi = "/api/project";
	const variablesApi = baseApi + "/{projectId}/Variables";

	return {
		setProjectVariables: setProjectVariables,
		getProjectVariables: getProjectVariables,
		exportAll: exportAll,
		importAll: importAll,
	};

	function setProjectVariables(projectId, variables) {
		return repositoryBase.post(variablesApi, { projectId: projectId }, variables);
	}

	function getProjectVariables(projectId) {
		return repositoryBase.get(variablesApi, { projectId: projectId });
	}

	function exportAll(projectId) {
		return repositoryBase.post(variablesApi + "/export", { projectId: projectId }).then(
			data => {
				return JSON.stringify(data, undefined, 3);
			}
		);
	}

	function importAll(projectId, serializedExportedData) {
		const exportedData = JSON.parse(serializedExportedData);
		return repositoryBase.post(variablesApi + "/import", { projectId: projectId }, exportedData);
	}
}