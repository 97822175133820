angular.module("eShareApp").controller("ProjectAdminCtrl", [
	"$rootScope", "$state", "$scope", "$filter", "project", "projects", "adapters",
	"availableAdapterTypes", "users", "permissions", "isSysAdmin", "adaptersRepository",
	"permissionsRepository", "messageBox", "modelsInfo", "$interval", "modelsRepository",
	"notification", "$timeout", "documentConfigurationsRepository", "pointOfInterestKindRepository",
	"initializedDatasources", "hasManagedDocuments", "projectsRepository",
	"backgroundProgressesRepository",
	function (
		$rootScope, $state, $scope, $filter, project, projects, adapters,
		availableAdapterTypes, users, permissions, isSysAdmin, adaptersRepository,
		permissionsRepository, messageBox, modelsInfo, $interval, modelsRepository,
		notification, $timeout, documentConfigurationRepository, pointOfInterestKindRepository,
		initializedDatasources, hasManagedDocuments, projectsRepository,
		backgroundProgressesRepository
	) {

		$scope.project = project;
		$scope.adapters = adapters;
		$scope.selectedAdapter = {};
		$scope.users = users;
		$scope.selectedProjectUser = {};
		$scope.selectedAllUser = {};
		$scope.permissions = permissions;
		$scope.projectUsers = [];
		$scope.allUsers = [];
		$scope.isSysAdmin = isSysAdmin;
		$scope.availableUsersOpen = false;
		$scope.allModels = modelsInfo;
		$scope.isProjectBusy = false;
		$scope.modelRevisionId = null;
		$scope.modelRevisionCount = 0;
		$scope.aPublishedModelExists = false;
		$scope.sourceProjectName = getSourceProjectName();
		const authorization = {
			oneTimeToken: "",
		};
		$scope.authorization = authorization;
		$scope.pointOfInterestKinds = [];
		$scope.documentDataSources = [];
		$scope.statusSynchronizationObject = {};

		refreshProjectProgress();

		modelsRepository.getModelInfo($scope.project.id).then(newModelInfo => {
			$scope.isProjectBusy = _.some(newModelInfo, { isModelBusy: true });
		}, error => {
			notification.error(error);
		});

		projectsRepository.getProjectImage($scope.project.id).then(response => {
			$scope.projectImageSource = response;
		});

		//Check if we have datasources providing external documents (the GUID below is the GUID for
		// Managed document data source, which does not count)
		$scope.hasExternalDocuments = _.some(initializedDatasources, source => {
			return source.category === "D"
				&& source.id.toLowerCase() !== "7c800013-66bb-40ac-979e-e131f0f1667b";
		});
		$scope.hasManagedDocuments = hasManagedDocuments;

		$scope.removeAdapter = function (adapter) {
			const mbox = messageBox.openQuestion(
				"Remove selected adapter?",
				"Do you really want to remove adapter " + adapter.name + "?",
				"Remove", "Cancel"
			);
			mbox.result.then(
				() => {
					adaptersRepository.remove($scope.project, adapter).then(
						() => {
							adaptersRepository.getAll($scope.project.id)
								.then(allAdapters => {
									$scope.adapters = allAdapters;
								}, error => {
									notification.error(error);
								});
						}, error => {
							notification.error(error);
						}
					);
				}
			);
		};

		$scope.isKnownAdapterType = function (adapterTypeGuid) {
			return !Utilities.isNullOrUndefined(getAdapterType(adapterTypeGuid));
		};

		$scope.getAdapterTypeName = function (adapterTypeGuid) {
			const adapterType = getAdapterType(adapterTypeGuid);
			return Utilities.isNullOrUndefined(adapterType)
				? "Unknown (GUID: " + adapterTypeGuid + ")"
				: adapterType.name;
		};

		function getAdapterType(adapterTypeGuid) {
			return _.find(availableAdapterTypes, at => {
				return at.typeGuid === adapterTypeGuid;
			});
		}

		$scope.addDocumentType = function () {
			$state.go(".editDocumentType", {
				projectId: project.id,
				documentTypeIndex: -1,
			});
		};

		$scope.removeDocumentType = function (documentType) {
			_.remove($scope.pdfConfiguration.documentTypeConfigurations, documentType);
			documentConfigurationRepository.savePdfSettings(
				$scope.project.id, $scope.pdfConfiguration
			);
		};

		$scope.addUserToProject = function (user) {
			const permission = {
				userAccountId: user.id,
				projectId: $scope.project.id,
				isAdmin: false,
			};
			permissionsRepository.add(permission)
				.then(() => {
					$scope.permissions = permissionsRepository.getByProject($scope.project.id)
						.then(newPermissions => {
							$scope.permissions = newPermissions;
							addPermissions(newPermissions);
						}, error => {
							notification.error(error);
						});
				}, error => {
					notification.error(error);
				});
		};

		$scope.removeUserFromProject = function (user) {
			permissionsRepository.remove(user.permission)
				.then(() => {
					$scope.permissions = permissionsRepository.getByProject($scope.project.id)
						.then(newPermissions => {
							$scope.permissions = newPermissions;
							addPermissions(newPermissions);
						}, error => {
							notification.error(error);
						});
				}, error => {
					notification.error(error);
				});
		};

		$scope.adminChanged = function (user) {
			permissionsRepository.update(user.permission)
				.then(() => {
					$scope.permissions = permissionsRepository.getByProject($scope.project.id)
						.then(newPermissions => {
							$scope.permissions = newPermissions;
							addPermissions(newPermissions);
						}, error => {
							notification.error(error);
						});
				}, error => {
					notification.error(error);
				});
		};

		function addPermissions(newPermissions) {
			const permissionDictionary = {};
			for(const p in newPermissions) {
				if(newPermissions.hasOwnProperty(p)) {
					const permission = newPermissions[p];
					permissionDictionary[permission.userAccountId] = permission;
				}
			}
			$scope.projectUsers = [];
			$scope.allUsers = [];
			if($scope.users) {
				for(const u in $scope.users) {
					if($scope.users.hasOwnProperty(u)) {
						const user = $scope.users[u];
						if(permissionDictionary[user.id] !== undefined) {
							user.permission = permissionDictionary[user.id];
							$scope.projectUsers.push(user);
						} else {
							user.permission = {};
							$scope.allUsers.push(user);
						}
					}
				}
			}
		}

		function refreshProjectProgress() {
			// Initial
			initialize();
			getInformation();
			// Refresh cycle
			$scope.getInformationInterval = setInterval(() => {
				if($state.current.name === "project.admin") {
					getInformation();
				}
			}, 5000);
		}

		// Ends the refresh cycle if the user goes elsewhere
		$scope.$on("$destroy", () => {
			if($scope.getInformationInterval) {
				clearInterval($scope.getInformationInterval);
			}
		});

		function getInformation() {
			backgroundProgressesRepository.getForProject($scope.project.id).then(
				content => {
					if($scope.statusSynchronizationObject.onUpdate) {
						$scope.statusSynchronizationObject.onUpdate(
							content.publishing[0], content.pointClouds[0], content.indexing
						);
					}
				}
			);
		}

		function initialize() {
			backgroundProgressesRepository
				.getDataSourcesForProject($scope.project.id)
				.then(dataSources => {
					for(let a = 0; a < dataSources.length; a++) {
						$scope.documentDataSources.push({
							source: dataSources[a],
							percentage: "",
							message: "Loading indexing information",
						});
					}
					$scope.documentDataSources = _.sortBy($scope.documentDataSources, ds => {
						return ds.source.name.toLowerCase();
					});
				});
		}

		function getSourceProjectName() {
			const sourceProjectId = project.sourceProjectId;
			const sourceProject = Utilities.isNonEmptyGuid(sourceProjectId)
				? _.find(projects, { id: sourceProjectId })
				: null;
			return sourceProject ? (sourceProject.name || "") : "";
		}

		addPermissions($scope.permissions);
	}
]);
