angular.module("eShareApp").controller("ExportExcelModalCtrl", [
	"$scope", "$uibModalInstance", "$timeout", "projectId", "notification", "attributesForExport",
	"activeSearchTab", "savedSearchExportConfigurationsRepository", "getExportConfiguration",
	"setExportConfiguration",

	function (
		$scope, $uibModalInstance, $timeout, projectId, notification, attributesForExport,
		activeSearchTab, savedSearchExportConfigurationsRepository, getExportConfiguration,
		setExportConfiguration
	) {

		const vm = this;
		const types = ["markups", "points", "objects", "dataSources"];
		const initialConfiguration = getExportConfiguration();

		const emptyExportOptions = {
			mode: "ALL",
			shouldCompareEmptyValues: false,
			attributesToCompare: [
				{
					first: {
						id: null,
					},
					second: {
						id: null,
					},
				}
			],
			searchTarget: activeSearchTab,
		};

		vm.excelExportAttributes = attributesForExport;
		vm.activeSearchTab = activeSearchTab;
		vm.savedExportConfigurations = [];
		vm.activeSavedConfiguration = null;
		vm.exportOptions = {};
		vm.comparisonItems = [];

		// 0 = simple search, 1 = object search, 2 = point search
		vm.selectedAll = {
			markups: vm.activeSearchTab === 0 || vm.activeSearchTab === 2,
			points: vm.activeSearchTab === 0 || vm.activeSearchTab === 2,
			objects: vm.activeSearchTab === 0 || vm.activeSearchTab === 1,
			dataSources: false,
		};
		vm.enabled = {
			markups: vm.activeSearchTab === 0 || vm.activeSearchTab === 2,
			points: vm.activeSearchTab === 0 || vm.activeSearchTab === 2,
			objects: vm.activeSearchTab === 0 || vm.activeSearchTab === 1,
			dataSources: false,
		};

		vm.onToggleTopLevel = onToggleTopLevel;
		vm.onToggleSelectAll = onToggleSelectAll;
		vm.onToggleAttribute = onToggleAttribute;
		vm.getNumberOfSelected = getNumberOfSelected;
		vm.isExpanded = isExpanded;
		vm.applyConfiguration = applyConfiguration;
		vm.deleteSavedConfiguration = deleteSavedConfiguration;
		vm.saveCurrentConfiguration = saveCurrentConfiguration;
		vm.exportCurrentSelections = exportCurrentSelections;
		vm.canExport = canExport;
		vm.addAttributeComparison = addAttributeComparison;
		vm.removeAttributeComparison = removeAttributeComparison;

		init();

		function init() {
			if(initialConfiguration.options.searchTarget === vm.activeSearchTab) {
				applyConfiguration({ selections: initialConfiguration });
			} else {
				vm.exportOptions = _.cloneDeep(emptyExportOptions);
			}

			savedSearchExportConfigurationsRepository.getAll(projectId).then(savedExportConfigs => {
				_.forEach(savedExportConfigs, config => {
					vm.savedExportConfigurations.push({
						id: config.id,
						name: config.name,
						userId: config.userId,
						selections: angular.fromJson(config.selections),
					});
				});
			}, error => {
				notification("Error fetching saved exports:" + error);
			});
		}

		$scope.$watch("[vm.excelExportAttributes, vm.exportOptions, vm.enabled]", () => {
			const config = getCurrentConfiguration();
			setExportConfiguration(config);
			updateComparisonItems();
			if($scope.exportExcelModal.$dirty){
				vm.activeSavedConfiguration = null;
			}
		}, true);

		function isExpanded(divId) {
			return $("#" + divId).attr("aria-expanded") === "true";
		}

		function onToggleTopLevel(type) {
			// If 0 attributes selected then select all
			if(vm.enabled[type] && getNumberOfSelected(type) === 0) {
				vm.selectedAll[type] = true;
				onToggleSelectAll(type);
				checkIfAllSelected(type);
			}
		}

		function onToggleSelectAll(type) {
			_.forEach(vm.excelExportAttributes[type], item => {
				item.selected = vm.selectedAll[type];
			});
		}

		function onToggleAttribute(type) {
			checkIfAllSelected(type);
		}

		function updateComparisonItems() {
			vm.comparisonItems = [];
			if(vm.enabled.objects) {
				_.forEach(_.filter(vm.excelExportAttributes.objects, { selected: true }), attribute => {
					vm.comparisonItems.push({
						id: attribute.name,
						displayName: attribute.displayName,
						group: "Object Attributes",
						shouldCompareAllFields: null,
					});
				});
			}
			if(vm.enabled.dataSources) {
				_.forEach(_.filter(vm.excelExportAttributes.dataSources, { selected: true }),
					dataSource => {
						vm.comparisonItems.push({
							id: dataSource.id,
							displayName: dataSource.name + " · " + dataSource.adapterName,
							adapterName: dataSource.adapterName,
							group: "External Data Source Attributes (Fields with same name)",
							shouldCompareAllFields: false,
						});
						vm.comparisonItems.push({
							id: dataSource.id,
							displayName: dataSource.name + " · " + dataSource.adapterName,
							adapterName: dataSource.adapterName,
							group: "External Data Source Attributes (All fields)",
							shouldCompareAllFields: true,
						});
					});
			}
		}

		function getCurrentConfiguration() {
			const currentConfig = {};

			// Selections
			_.forEach(types, type => {
				const selectedAttributes = _.filter(vm.excelExportAttributes[type], attribute => {
					return attribute.selected;
				});
				const selectedAndFormattedAttributes = _.map(selectedAttributes, attribute => {
					return {
						id: attribute.id,
						name: attribute.name,
					};
				});
				currentConfig[type] = {
					enabled: vm.enabled[type],
					selectedAttributes: selectedAndFormattedAttributes,
				};
			});

			// Options
			currentConfig.options = _.cloneDeep(vm.exportOptions);

			return currentConfig;
		}

		function deselectAllTypes() {
			_.forEach(types, type => {
				vm.selectedAll[type] = false;
				_.forEach(vm.excelExportAttributes[type], item => {
					item.selected = false;
				});
			});
		}

		function checkIfAllSelected(type) {
			vm.selectedAll[type] = _.every(vm.excelExportAttributes[type], item => {
				return item.selected;
			});
		}

		function getNumberOfSelected(type) {
			if(typeof vm.excelExportAttributes[type] === "undefined") {
				return 0;
			}
			return _.filter(vm.excelExportAttributes[type], item => {
				return item.selected;
			}).length;
		}

		function canExport() {
			const enabledTypes = _.filter(types, type => {
				return vm.enabled[type];
			});

			const someAttributeSelected = _.some(enabledTypes, type => {
				return _.some(vm.excelExportAttributes[type], attribute => {
					return attribute.selected;
				});
			});

			const comparisonsAreValid = vm.exportOptions.mode !== "CONFLICTS_CUSTOM"
				|| (vm.exportOptions.attributesToCompare.length > 0
					&& _.every(vm.exportOptions.attributesToCompare, attributePair => {
						return (attributePair.first?.id && attributePair.second?.id)
							&& (attributePair.first.id !== attributePair.second.id)
							&& (_.find(vm.comparisonItems, { id: attributePair.first.id }))
							&& (_.find(vm.comparisonItems, { id: attributePair.second.id }));
					}));
			return someAttributeSelected && comparisonsAreValid;
		}

		function applyConfiguration(config){
			applySelections(config.selections);
			vm.exportOptions = _.cloneDeep(config.selections.options || emptyExportOptions);
			$scope.exportExcelModal?.$setPristine();
		}

		function applySelections(selections) {
			deselectAllTypes();
			_.forEach(types, type => {
				_.forEach(vm.excelExportAttributes[type], attribute => {
					attribute.selected = _.some(selections[type].selectedAttributes,
						selectedAttribute => {
							if(type === "objects" || selectedAttribute.id === -1) {
							// objects and hard coded attributes (id = -1) use name as identifier
							// for objects name is the attribute's abbreviation
							// and for status tracking definitions name is the GUID
								return selectedAttribute.name === attribute.name;
							}
							return selectedAttribute.id === attribute.id;
						});
				});
				checkIfAllSelected(type);
				vm.enabled[type] = selections[type].enabled;
			});
		}

		function deleteSavedConfiguration(config) {
			$uibModalInstance.close({
				action: "delete",
				configurationToDelete: config,
			});
		}

		function saveCurrentConfiguration() {
			const currentConfig = getCurrentConfiguration();
			$uibModalInstance.close({
				action: "save",
				currentConfiguration: currentConfig,
			});
		}

		function exportCurrentSelections() {
			const attributesToExport = {};

			// Trim disabled attributes
			_.forEach(types, type => {
				if(vm.enabled[type]) {
					attributesToExport[type] = _.filter(vm.excelExportAttributes[type], attribute => {
						return attribute.selected;
					});
				}
			});

			// Assign comparison indexes
			let comparisonIndex = 0;
			_.forEach(vm.exportOptions.attributesToCompare, attributePair => {
				let match1 = findMatchingObjectAttribute(
					attributesToExport.objects,
					attributePair.first.id
				);
				match1 = match1 || findMatchingDataSource(
					attributesToExport.dataSources,
					attributePair.first.id
				);
				let match2 = findMatchingObjectAttribute(
					attributesToExport.objects,
					attributePair.second.id
				);
				match2 = match2 || findMatchingDataSource(
					attributesToExport.dataSources,
					attributePair.second.id
				);
				if(match1 && match2) {
					match1.comparisonIndexes.push(comparisonIndex);
					match1.shouldCompareAllFields = attributePair.first.shouldCompareAllFields;
					match2.comparisonIndexes.push(comparisonIndex);
					match2.shouldCompareAllFields = attributePair.second.shouldCompareAllFields;
				}
				comparisonIndex++;
			});

			$uibModalInstance.close({
				action: "export",
				exportConfiguration: {
					attributesToExport: attributesToExport,
					exportOptions: vm.exportOptions,
				},
			});
		}

		function findMatchingObjectAttribute(objectAttributes, abbreviation) {
			return _.find(objectAttributes, attribute => {
				return attribute.name === abbreviation;
			});
		}

		function findMatchingDataSource(dataSources, id) {
			return _.find(dataSources, dataSource => {
				return dataSource.id === id;
			});
		}

		function addAttributeComparison() {
			vm.exportOptions.attributesToCompare.push({
				first: {
					id: null,
				},
				second: {
					id: null,
				},
			});

			// Scroll to bottom
			$timeout(() => {
				const modalBody = document.getElementById("modalBody");
				modalBody.scrollTop = modalBody.scrollHeight;
			}, 0, false);

			$scope.exportExcelModal.$setDirty();
		}

		function removeAttributeComparison(attributeComparison) {
			_.remove(vm.exportOptions.attributesToCompare, attributeComparison);
			if(vm.exportOptions.attributesToCompare.length < 1) {
				addAttributeComparison();
			}
			$scope.exportExcelModal.$setDirty();
		}
	}
]);
