angular.module("eShareApp").factory("modelsUploadService", modelsUploadService);
modelsUploadService.$inject = ["uploadService"];

function modelsUploadService(uploadService) {

	function forImporter(project, importer, importerType) {
		const name = importer.id;
		const extensions = importerType.supportedFileExtensions
			.split(",")
			.map(ext => {
				return ext.trim();
			});
		const service = uploadService.getOrCreate(
			project.id,
			name + " model",
			extensions,
			"api/project/{projectId}/ModelImporters/"
		);

		service.uploadFiles = function (files, action) {
			if(this.isUploadInProgress) {
				throw "Tried to upload with another upload still in progress!";
			}
			this.isCanceled = false;
			this.isFailed = false;
			this.isUploadInProgress = true;
			this.uploadStatuses.length = 0;

			this.uploadUrl = "api/project/{projectId}/ModelImporters/" + importer.id + "/" + action;
			this.multiUploadUrl = this.uploadUrl;

			// these are necessary to call:
			const uploadFunctions = this.prepareUploadFunctions(this, files, {});
			return this.sequentialUpload(this, uploadFunctions);
		};
		return service;
	}

	return {
		forImporter: forImporter,
	};
}