angular.module("eShareApp").factory("searchService", searchService);

searchService.$inject = ["$http", "$q", "l10n", "notification"];

function searchService($http, $q, l10n, notification) {
	const maxPointsOfInterest = 200;
	const markupChunkSize = 100;

	const emptySearchRequest = {
		projectId: Utilities.emptyGuid(),
		isAdvanced: false,
		text: "",
		scope: "IdOnly",
		terms: [],
		searchTarget: "Objects",
		shouldGetGeometryIds: false,
	};

	const emptyLocateRequest = {
		projectId: Utilities.emptyGuid(),
		positionId: "",
		tag: "",
		geometryId: "",
		modelTimestamp: "",
		poiKind: "",
		pointReference: "",
		searchFor: "",
		searchScope: "",
	};

	const emptyCompletionRequest = {
		projectId: Utilities.emptyGuid(),
		isAdvanced: false,
		attributeTag: "",
		text: "",
		searchTarget: "Objects",
		kindId: 0,
	};

	const emptySearchResult = {
		isBusy: false,
		request: _.cloneDeep(emptySearchRequest),
		modelTimestamp: null,
		numberOfObjectsFound: 0,
		areObjectsTruncated: false,
		objects: [],
		objectGeometryIds: [],
		allObjectGeometryIds: [],
		shouldExamineAllObjects: false,
		includeGroups: false,
		externalReferences: [],
		arePointsOfInterestTruncated: false,
		allPointsOfInterest: [],
		pointsOfInterest: [],
		documents: [],
		markups: [],
		numberOfMarkupsFound: 0,
		areMarkupsTruncated: false,
	};

	let searchRequestId = 0;
	let locateRequestId = 0;
	let completionRequestId = 0;

	const result = _.cloneDeep(emptySearchResult);
	result.numberOfResults = function () {
		return result.numberOfObjectsFound
			+ result.externalReferences.length
			+ result.pointsOfInterest.length
			+ result.documents.length
			+ result.numberOfMarkupsFound;
	};
	result.hasResults = function () {
		return result.numberOfResults() > 0;
	};

	const emptyExcelExportConfiguration = {
		markups: {
			enabled: true,
			selectedAttributes: null,
		},
		points: {
			enabled: true,
			selectedAttributes: null,
		},
		objects: {
			enabled: true,
			selectedAttributes: null,
		},
		dataSources: {
			enabled: false,
			selectedAttributes: null,
		},
		options: {
			mode: "ALL",
			shouldCompareEmptyValues: false,
			attributesToCompare: [
				{
					first: {
						id: null,
					},
					second: {
						id: null,
					},
				}
			],
			searchTarget: -1,
		},
	};

	let excelExportConfiguration = _.cloneDeep(emptyExcelExportConfiguration);

	return {
		clear: clear,
		clearIfProjectChanged: clearIfProjectChanged,
		search: search,
		exportBcf: exportBcf,
		exportExcel: exportExcel,
		exportWord: exportWord,
		export3dd: export3dd,
		exportMarkup: exportMarkup,
		repeatLastSearch: repeatLastSearch,
		locate: locate,
		getCompletion: getCompletion,
		serializeTerms: serializeTerms,
		deserializeTerms: deserializeTerms,
		result: result,
		getObjectGeometryIds: getObjectGeometryIds,
		locateAllGeometryIds: locateAllGeometryIds,
		getWordExportTemplates: getWordExportTemplates,
		getExcelExportDataSourceInfos: getExcelExportDataSourceInfos,
		getExcelExportConfiguration: getExcelExportConfiguration,
		setExcelExportConfiguration: setExcelExportConfiguration,
	};

	function clear() {
		_.assign(result, emptySearchResult);
		++searchRequestId;
		++locateRequestId;
		++completionRequestId;
		_.assign(excelExportConfiguration, emptyExcelExportConfiguration);
	}

	function clearIfProjectChanged(projectId) {
		if (result.request.projectId !== projectId) {
			clear();
		}
	}

	function areSearchRequestsEqual(request1, request2) {
		if (request1.isAdvanced !== request2.isAdvanced
			|| request1.projectId !== request2.projectId) {
			return false;
		}
		if (!request1.isAdvanced) {
			return request1.text === request2.text && request1.scope === request2.scope;
		}
		if (request1.searchTarget !== request2.searchTarget
			|| request1.terms.length !== request2.terms.length) {
			return false;
		}
		for (let i = 0; i < request1.terms.length; ++i) {
			if (request1.terms[i].attributeTag !== request2.terms[i].attributeTag
				|| request1.terms[i].operation !== request2.terms[i].operation
				|| request1.terms[i].text !== request2.terms[i].text) {
				return false;
			}
		}
		return true;
	}

	function search(searchRequest, attributeDefinitions, forceSearch) {
		const request = _.cloneDeep(searchRequest);
		if (!isValidSearchRequest(request)) {
			const error = new Error("Invalid search request");
			error.status = 400;
			return $q.reject(error);
		} else {
			if (areSearchRequestsEqual(result.request, request) && !forceSearch) {
				return $q.resolve(result);
			} else {
				_.assign(result, emptySearchResult);
				result.request = _.cloneDeep(request);
				result.isBusy = true;
				if (isEmptySearchRequest(request)) {
					result.isBusy = false;
					return $q.resolve(result);
				} else {
					const requestId = ++searchRequestId;
					const uri = "/api/project/{projectId}/Search"
						.replace("{projectId}", request.projectId);
					const terms = prepareTerms(request.terms, attributeDefinitions);
					return $http.post(uri, {
						requestId: requestId,
						isAdvanced: request.isAdvanced,
						text: request.text,
						scope: request.scope,
						searchTarget: request.searchTarget,
						terms: terms,
						shouldGetGeometryIds: false,
					}).then(
						response => {
							const data = response.data;
							if (isValidSearchResponse(data)) {
								if (requestId !== searchRequestId || data.requestId !== requestId) {
									result.isBusy = false;
									return result;
								}
								const objects = processObjects(data.objects);
								const externalReferences =
									processExternalReferences(data.externalReferences);
								const allPointsOfInterest = data.pointsOfInterest;
								const pointsOfInterest = processPointsOfInterest(allPointsOfInterest);
								_.assign(result, {
									isBusy: false,
									modelTimestamp: data.modelTimestamp,
									numberOfObjectsFound: data.numberOfObjectsFound,
									areObjectsTruncated: data.areObjectsTruncated,
									objects: objects,
									objectGeometryIds: cleanArray(data.objectGeometryIds),
									allObjectGeometryIds: cleanArray(data.allObjectGeometryIds),
									externalReferences: externalReferences,
									arePointsOfInterestTruncated:
										pointsOfInterest.length !== allPointsOfInterest.length,
									allPointsOfInterest: allPointsOfInterest,
									pointsOfInterest: pointsOfInterest,
									documents: data.documents,
									numberOfDocumentsFound: data.numberOfDocumentsFound,
									areDocumentsTruncated: data.areDocumentsTruncated,
									markups: data.markups,
									markupChunks: _.chunk(data.markups, 100),
									currentMarkupChunk: 0,
									numberOfMarkupsFound: data.numberOfMarkupsFound
								});
								return result;
							} else {
								// Should never happen!
								result.isBusy = false;
								return $q.reject(new Error("Invalid search response"));
							}
						},
						reason => {
							result.isBusy = false;
							return $q.reject(reason);
						}
					);
				}
			}
		}
	}

	function getObjectGeometryIds(searchRequest, attributeDefinitions) {
		const request = _.cloneDeep(searchRequest);
		if (!isValidSearchRequest(request)) {
			const error = new Error("Invalid search request");
			error.status = 400;
			return $q.reject(error);
		} else {
			result.isFetchingGeometry = true;
			const requestId = ++searchRequestId;
			const uri = "/api/project/{projectId}/Search".replace("{projectId}", request.projectId);
			const terms = prepareTerms(request.terms, attributeDefinitions);
			return $http.post(uri, {
				requestId: requestId,
				isAdvanced: request.isAdvanced,
				text: request.text,
				scope: request.scope,
				searchTarget: request.searchTarget,
				terms: terms,
				shouldGetGeometryIds: true,
			}).then(
				response => {
					const data = response.data;
					if (requestId !== searchRequestId || data.requestId !== requestId) {
						result.isFetchingGeometry = false;
						return null;
					}
					const geometryIdResult = {
						objectGeometryIds: cleanArray(data.objectGeometryIds),
						allObjectGeometryIds: cleanArray(data.allObjectGeometryIds),
					};
					result.isFetchingGeometry = false;
					return geometryIdResult;
				},
				reason => {
					result.isFetchingGeometry = false;
					notification.error(
						"Getting object geometry failed: " + Utilities.getErrorMessage(reason)
					);
					return $q.reject(reason);
				}
			);
		}
	}

	function getWordExportTemplates(projectId) {
		const uri = "/api/project/{projectId}/Search/GetWordExportTemplates"
			.replace("{projectId}", projectId);
		return $http.get(uri, {}).then(
			response => {
				return response.data;
			},
			reason => {
				return $q.reject(reason);
			}
		);
	}

	function getExcelExportDataSourceInfos(projectId) {
		const uri = "/api/project/{projectId}/Search/GetExcelExportDataSourceInfos"
			.replace("{projectId}", projectId);
		return $http.post(uri, emptySearchRequest).then(
			response => {
				return response.data;
			},
			reason => {
				return $q.reject(reason);
			}
		);
	}

	function cleanArray(array) {
		let cleanOne = _.map(array, id => {
			if (id !== -1) {
				return id;
			}
		});
		cleanOne = _.without(cleanOne, undefined);
		array = _.uniq(cleanOne);
		return array;
	}

	function exportMarkup(searchRequest, attributeDefinitions) {
		exportToFile(
			searchRequest,
			attributeDefinitions,
			"/api/project/{projectId}/Search/ExportMarkup",
			"SearchResultMarkup.ebx",
			"EBX"
		);
	}

	function exportBcf(searchRequest, attributeDefinitions) {
		exportToFile(
			searchRequest,
			attributeDefinitions,
			"/api/project/{projectId}/Search/ExportBcf",
			"SearchResult.bcfzip",
			"BCF"
		);
	}

	function exportExcel(searchRequest, attributeDefinitions) {
		exportToFile(
			searchRequest,
			attributeDefinitions,
			"/api/project/{projectId}/Search/ExportExcel",
			"SearchResult.xlsx",
			"Excel"
		);
	}

	function exportWord(searchRequest, attributeDefinitions) {
		exportToFile(
			searchRequest,
			attributeDefinitions,
			"/api/project/{projectId}/Search/ExportWord",
			"SearchResult.docx",
			"Word"
		);
	}

	function export3dd(searchRequest, attributeDefinitions) {
		exportToFile(
			searchRequest,
			attributeDefinitions,
			"/api/project/{projectId}/Search/Export3dd",
			"model.3dd",
			".3dd"
		);
	}

	function exportToFile(searchRequest, attributeDefinitions, path, defaultFileName,
		targetName) {
		const wordExportTemplatePath = searchRequest.wordExportTemplatePath;
		const excelExportConfiguration = searchRequest.excelExportConfiguration;
		delete searchRequest.wordExportTemplatePath;
		delete searchRequest.excelExportConfiguration;
		const request = _.cloneDeep(searchRequest);
		request.shouldGetGeometryIds = false;
		if (!isValidSearchRequest(request)) {
			const error = new Error("Invalid search request");
			error.status = 400;
			return $q.reject(error);
		} else {
			const uri = path.replace("{projectId}", request.projectId);
			const terms = prepareTerms(request.terms, attributeDefinitions);
			return $http
				.post(uri, {
					isAdvanced: request.isAdvanced,
					text: request.text,
					scope: request.scope,
					searchTarget: request.searchTarget,
					terms: terms,
					wordExportTemplatePath: wordExportTemplatePath,
					excelExportConfiguration: excelExportConfiguration,
				}, {
					responseType: "blob",
				})
				.then(processGenerateReportResponse, processErrorResponse);
		}

		function processGenerateReportResponse(response) {
			const blob = response.data;
			const filename = decodeURIComponent(getFileNameFromResponseHeader(response))
				|| defaultFileName;
			if (response.headers("X-CAD-NoMarkups") === "True") {
				notification.error(
					`Exporting search results to ${targetName} failed: \
					Search result did not contain any markups.`
				);
				return;
			}
			const numOfSkippedSmartPoints = response.headers("X-CAD-SkippedSmartPointsCount");
			if (numOfSkippedSmartPoints && parseInt(numOfSkippedSmartPoints, 10) > 0) {
				notification.warning(
					`Skipped ${numOfSkippedSmartPoints} Smart Point(s). \
					Exporting Smart Points to ${targetName} is not currently supported.`
				);
			}
			if (response.headers("X-CAD-ErrorInTemplate") === "True") {
				notification.error(
					"Error(s) in template. See output document for more information."
				);
			}
			if (response.headers("X-CAD-ExportExcelError")) {
				notification.error(
					`Exporting to Excel failed: ${response.headers("X-CAD-ExportExcelError")}`
				);
				return;
			}
			window.saveAs(blob, filename);
		}

		function processErrorResponse(reason) {
			notification.error(
				`Exporting search results to ${targetName} failed: ${Utilities.getErrorMessage(reason)}`
			);
			return $q.reject(reason);
		}
	}

	function getFileNameFromResponseHeader(response) {
		const contentDispositionParts = (response.headers("Content-Disposition") || "").split(";");
		for (let i = 0; i < contentDispositionParts.length; ++i) {
			const contentDispositionPart = contentDispositionParts[i].trim();
			if (contentDispositionPart.toLowerCase().startsWith("filename=")) {
				let filename = contentDispositionPart.substr(9).trim();
				if (filename.length >= 2 && filename[0] === "\"") {
					filename = filename.substr(1, filename.length - 2);
				}
				return filename;
			}
		}
		return null;
	}

	function prepareTerms(requestTerms, attributeDefinitions) {
		if (!requestTerms || requestTerms.length === 0) {
			return undefined;
		}
		const terms = _.cloneDeep(requestTerms);
		for (let i = 0; i < terms.length; ++i) {
			const term = terms[i];
			const attributeTag = term.attributeTag;
			if (attributeTag !== "((id))" && attributeTag !== "((any))") {
				const attributeDefinition = _.find(
					attributeDefinitions,
					{ abbreviation: attributeTag }
				);
				const dataType = attributeDefinition ? attributeDefinition.dataType : "string";
				if (dataType === "unixDate") {
					term.text = l10n.dateToTimestamp(term.text);
				}
			}
		}
		return terms;
	}

	function repeatLastSearch() {
		const searchRequest = _.cloneDeep(result.request);
		if (isValidSearchRequest(searchRequest)) {
			clear();
			search(searchRequest);
		}
	}

	function locate(request, ignoreRequestIdMismatch) {
		if (!isValidLocateRequest(request)) {
			const error = new Error("Invalid locate request");
			error.status = 400;
			return $q.reject(error);
		} else {
			const requestId = ++locateRequestId;
			const uri = "/api/project/{projectId}/Search/Locate"
				.replace("{projectId}", request.projectId);
			return $http.post(uri, {
				requestId: requestId,
				positionId: request.positionId,
				tag: request.tag,
				geometryId: request.geometryId,
				modelTimestamp: request.modelTimestamp,
				poiKind: request.poiKind,
				pointReference: request.pointReference,
				searchFor: request.searchFor,
				searchScope: request.searchScope,
			}).then(
				response => {
					const data = response.data;
					if (isValidLocateResponse(data)) {
						if (!ignoreRequestIdMismatch
							&& (requestId !== locateRequestId || data.requestId !== requestId)) {
							return null;
						}
						data.hasModelExpired = Utilities.toBool(data.hasModelExpired);
						return data;
					} else {
						// Should never happen!
						return $q.reject(new Error("Invalid locate response"));
					}
				}
			);
		}
	}

	function locateAllGeometryIds(tagValuePairs, projectId) {
		const uri = "/api/project/{projectId}/Search/LocateAllGeometryIds"
			.replace("{projectId}", projectId);
		return $http.post(uri, {
			pairs: tagValuePairs,
		}).then(response => {
			return response;
		});
	}

	function getCompletion(request) {
		if (!isValidCompletionRequest(request)) {
			const error = new Error("Invalid completion request");
			error.status = 400;
			return $q.reject(error);
		} else {
			const requestId = ++completionRequestId;
			return $http.post("/api/project/{projectId}/Search/Completion"
				.replace("{projectId}", request.projectId), {
				requestId: requestId,
				isAdvanced: request.isAdvanced,
				attributeTag: request.attributeTag,
				text: request.text,
				searchTarget: request.searchTarget,
				kindId: request.kindId,
			}).then(
				response => {
					const data = response.data;
					if (isValidCompletionResponse(data)) {
						if (requestId !== completionRequestId || data.requestId !== requestId) {
							return null;
						} else {
							let values = data.values || [];
							values = _.sortBy(values, value => {
								return (value || "").toLowerCase();
							});
							return {
								isTruncated: Utilities.toBool(data.isTruncated),
								values: values,
							};
						}
					} else {
						// Should never happen!
						return $q.reject(new Error("Invalid completion response"));
					}
				}
			);
		}
	}

	function serializeTerms(terms) {
		if (Utilities.isNullOrUndefined(terms)) {
			return undefined;
		}
		const serializedTerms = [];
		for (let i = 0; i < terms.length; ++i) {
			const term = terms[i];
			const serializedTerm = encodeURIComponent(term.attributeTag) + ":"
				+ encodeURIComponent(term.operation) + ":"
				+ encodeURIComponent(term.text);
			serializedTerms.push(serializedTerm);
		}
		return serializedTerms.join("|");
	}

	function deserializeTerms(serializedTerms) {
		const terms = [];
		if (Utilities.isString(serializedTerms)) {
			serializedTerms = serializedTerms.trim();
			serializedTerms = serializedTerms.split("|");
			for (let i = 0; i < serializedTerms.length; ++i) {
				const serializedTerm = serializedTerms[i];
				const parts = serializedTerm.split(":");
				if (parts.length < 3) {
					return [];
				}
				const attributeTag = decodeURIComponent(parts[0].trim());
				const operation = decodeURIComponent(parts[1].trim());
				const text = decodeURIComponent(
					(parts.length === 3 ? parts[2] : parts.slice(2).join(":")).trim()
				);
				terms.push({
					attributeTag: attributeTag,
					operation: operation,
					text: text,
					previousTag: attributeTag,
				});
			}
		}
		return terms;
	}

	function isValidSearchRequest(request) {
		return Utilities.isAssignableTo(emptySearchRequest, request)
			&& Utilities.isNonEmptyGuid(request.projectId);
	}

	function isValidSearchResponse(data) {
		return Utilities.hasOwnProperties(
			data,
			[
				"requestId",
				"modelTimestamp",
				"numberOfObjectsFound",
				"areObjectsTruncated",
				"objects",
				"externalReferences",
				"pointsOfInterest"
			]
		)
			&& angular.isArray(data.objects)
			&& angular.isArray(data.externalReferences)
			&& angular.isArray(data.pointsOfInterest);
	}

	function isValidLocateRequest(request) {
		return Utilities.isAssignableTo(emptyLocateRequest, request)
			&& Utilities.isNonEmptyGuid(request.projectId);
	}

	function isValidCompletionRequest(request) {
		return Utilities.isAssignableTo(emptyCompletionRequest, request)
			&& Utilities.isNonEmptyGuid(request.projectId)
			&& Utilities.isString(request.attributeTag) && Utilities.isString(request.text)
			&& request.attributeTag !== "";
	}

	function isValidLocateResponse(data) {
		return Utilities.hasOwnProperties(
			data,
			[
				"requestId",
				"geometryId",
				"hasModelExpired",
				"modelTimestamp",
				"searchRequest"
			]
		);
	}

	function isValidCompletionResponse(data) {
		return Utilities.hasOwnProperties(data, ["requestId", "isTruncated", "values"]);
	}

	function isEmptySearchRequest(request) {
		return request.isAdvanced ? (request.terms.length === 0) : (request.text === "");
	}

	function processObjects(objects) {
		_.forEach(objects, object => {
			object.attributes = _.sortBy(object.attributes, attribute => {
				return attribute.displayName.toLowerCase();
			});
		});
		objects = _.sortBy(objects, object => {
			return object.keyAttribute
				? "0" + object.keyAttribute.value.toString().toLowerCase()
				: "1" + object.id.toString();
		});
		return objects;
	}

	function processExternalReferences(externalReferences) {
		return _.sortBy(externalReferences, externalReference => {
			return (
				externalReference.displayName
				|| (externalReference.key.keyType === "Tag" && externalReference.key.attribute)
			).toLowerCase();
		});
	}

	function processPointsOfInterest(allPointsOfInterest) {
		allPointsOfInterest = _.filter(allPointsOfInterest, poi => {
			return poi.name || poi.externalId;
		});
		let pointsOfInterest = _.sortBy(allPointsOfInterest, poi => {
			return (poi.name || poi.externalId || "").toLowerCase();
		});
		if (pointsOfInterest.length > maxPointsOfInterest) {
			pointsOfInterest = pointsOfInterest.slice(0, maxPointsOfInterest);
		}
		return pointsOfInterest;
	}

	function getExcelExportConfiguration() {
		return excelExportConfiguration;
	}

	function setExcelExportConfiguration(exportOptions) {
		excelExportConfiguration = exportOptions;
	}
}
