angular.module("eShareApp").filter("newlineToBr", [
	"$sanitize", function ($sanitize) {

		return function (value) {
			if(value != null) {
				return $sanitize(value.replace(/(\r\n|\n|\r|&#10;|&#13;|&#13;&#10;)/gm, "<br />"));
			}
			return "";
		};
	}
]);
