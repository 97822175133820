angular.module("eShareApp").controller("AdapterExportCtrl", [
	"$state", "$scope", "adapter",

	function (
		$state, $scope, adapter
	) {

		$scope.importExportTitle = "Export";
		$scope.isImport = false;

		$scope.cfg = { json: angular.toJson(adapter, true) };
	}
]);
