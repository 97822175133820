angular.module("eShareApp").controller("SelectProjectCtrl", [
	"$scope", "$state", "$location", "$timeout", "project", "projects", "projectActivity",
	"projectsRepository",

	function (
		$scope, $state, $location, $timeout, project, projects, projectActivity,
		projectsRepository
	) {

		$scope.state = $state;
		$scope.projects = [];
		$scope.hasProjects = Utilities.isArray(projects) && projects.length > 0;
		$scope.project = project;
		$scope.filter = {
			text: $state.params.filter,
			changed: updateProjects,
		};
		$scope.projectsContainImages = _.some(projects, project => {
			return project.containsImage;
		});
		_.forEach(projects, project => {
			if(project.containsImage) {
				projectsRepository.getProjectImage(project.id).then(response => {
					project.imageBase64 = response;
				});
			}
		});

		$scope.hoverIn = function (project) {
			project.isHovered = true;
		};

		$scope.hoverOut = function (project) {
			project.isHovered = false;
		};

		$scope.onInit = function onInit() {
			$timeout(() => {
				$("#filterText").focus();
			});
		};

		$scope.goToProject = function (projectId) {
			$state.go("project", { projectId: projectId });
		};

		const activeProjects = _.filter(projects, project => {
			return !projectActivity[project.id];
		});

		updateProjects();

		function updateProjects() {
			const filterText = ($scope.filter.text || "").toLowerCase();
			if(filterText === "") {
				$scope.projects = activeProjects;
			} else {
				const filteredProjects = _.filter(activeProjects, project => {
					return Utilities.isString(project.name)
						&& project.name.toLowerCase().contains(filterText)
							|| Utilities.isString(project.description)
								&& project.description.toLowerCase().contains(filterText);
				});
				$scope.projects = filteredProjects;
			}
			const newStateParams = {
				projectId: project && project.id ? project.id : undefined,
				filter: filterText ? filterText : undefined,
			};
			let newUrl = $state.href(".", newStateParams);
			newUrl = newUrl.substr(1);
			const currentUrl = $location.url();
			if(currentUrl !== newUrl) {
				$state.go(".", newStateParams, { notify: false });
			}
		}
	}
]);
