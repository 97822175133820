angular.module("eShareApp").factory("categorizationService", categorizationService);

categorizationService.$inject = ["colorService", "messageBox"];

function categorizationService(colorService, messageBox) {
	let onChanged = null;
	let refreshCategoriesFromServer = null;
	let isInitialized = false;
	const svc = {
		setup: setup,
		serialize: serialize,
		addCategory: addCategory,
		deleteCategory: deleteCategory,
		deleteCategories: deleteCategories,
		moveCategoryUp: moveCategoryUp,
		moveCategoryDown: moveCategoryDown,
		sortValuesAz: sortValuesAz,
		sortValuesZa: sortValuesZa,
		sortDisplayNamesAz: sortDisplayNamesAz,
		sortDisplayNamesZa: sortDisplayNamesZa,
		removeUnusedCategories: removeUnusedCategories,
		refreshCategories: refreshCategories,
		categories: null,
		multipleCategories: null,
		uncategorized: null,
		hasCounts: false,
		onChange: function () {
			if(typeof onChanged === "function") {
				onChanged();
			}
		},
	};
	return svc;

	function ensureIsInitialized() {
		if(!isInitialized) {
			throw new EShareException("categorizationService must be initialized before use");
		}
	}

	function setup(serializedCategories, onChangedCallback, refreshCategoriesFromServerCallback) {
		if(typeof onChangedCallback !== "function") {
			throw new EShareException("onChanged callback must be provided");
		}
		if(typeof refreshCategoriesFromServerCallback !== "function") {
			throw new EShareException("refreshCategoriesFromServer callback must be provided");
		}
		onChanged = onChangedCallback;
		refreshCategoriesFromServer = refreshCategoriesFromServerCallback;
		const categories = Utilities.fromJson(serializedCategories, []);
		initializeCategories(categories, null);
		isInitialized = true;
	}

	function isColor(color) {
		return Utilities.isString(color) && color.length === 7 && color.startsWith("#");
	}

	function initializeCategories(categories/*, memberCounts*/) {
		if(!Utilities.isArray(categories)) {
			categories = [];
		}
		const usedColors = [];
		const categoryIndexesWithoutColor = [];
		let hasCounts = false;
		const length = categories.length; //Caching the length to optimize the array iteration
		for(let i = 0; i < length; i++) {
			const c = categories[i];
			if(!isColor(c.color)) {
				categoryIndexesWithoutColor.push(i);
			} else {
				usedColors.push(c.color);
			}
			if(c.count) {
				hasCounts = true;
			}
		}
		const shouldUseFastColoring = categoryIndexesWithoutColor.length > 100;
		for(let j = 0; j < categoryIndexesWithoutColor.length; j++) {
			const category = categories[categoryIndexesWithoutColor[j]];
			const color = shouldUseFastColoring
				? colorService.getFastColor()
				: colorService.getNextColor(usedColors);
			category.color = color;
			usedColors.push(category.color);
		}
		const multipleCategories = Utilities.findAndRemoveItem(
			categories, { type: "MultipleCategories" }
		) || {
			name: "",
			displayName: "Multiple Categories",
			type: "MultipleCategories",
			color: "#CC0000",
		};
		const uncategorized = Utilities.findAndRemoveItem(
			categories, { type: "Uncategorized" }
		) || {
			name: "",
			displayName: "Uncategorized",
			type: "Uncategorized",
			color: "#555555",
		};
		svc.categories = categories;
		svc.multipleCategories = multipleCategories;
		svc.uncategorized = uncategorized;
		svc.hasCounts = hasCounts;
	}

	function serialize() {
		ensureIsInitialized();
		const categories = _.cloneDeep(svc.categories);
		categories.push(_.cloneDeep(svc.multipleCategories));
		categories.push(_.cloneDeep(svc.uncategorized));
		const serializedCategories = Utilities.toJson(categories);
		return serializedCategories;
	}

	function addCategory() {
		ensureIsInitialized();
		const color = colorService.getNextColor(_.map(svc.categories, "color"));
		const category = {
			name: "",
			displayName: "",
			count: 0,
			color: color,
			type: "Normal",
		};
		svc.categories.unshift(category);
		onChanged();
	}

	function deleteCategory(index) {
		ensureIsInitialized();
		svc.categories.splice(index, 1);
		onChanged();
	}

	function deleteCategories(list) {
		ensureIsInitialized();
		list.forEach(item => {
			const index = svc.categories.indexOf(item);
			svc.categories.splice(index, 1);
		});
	}

	function moveCategoryUp(index) {
		ensureIsInitialized();
		if(index < 1) {
			return;
		}
		const temp = svc.categories[index - 1];
		svc.categories[index - 1] = svc.categories[index];
		svc.categories[index] = temp;
		onChanged();
	}

	function moveCategoryDown(index) {
		ensureIsInitialized();
		if(index > svc.categories.length - 2) {
			return;
		}
		const temp = svc.categories[index + 1];
		svc.categories[index + 1] = svc.categories[index];
		svc.categories[index] = temp;
		onChanged();
	}

	function sortValuesAz() {
		sortCategories("Sort by Value Ascending?", c => {
			return c.name.toLowerCase();
		}, true);
	}

	function sortValuesZa() {
		sortCategories("Sort by Value Descending?", c => {
			return c.name.toLowerCase();
		}, false);
	}

	function sortDisplayNamesAz() {
		sortCategories("Sort by Display Values Ascending?", c => {
			return (c.displayName || c.name).toLowerCase();
		}, true);
	}

	function sortDisplayNamesZa() {
		sortCategories("Sort by Display Values Descending?", c => {
			return (c.displayName || c.name).toLowerCase();
		}, false);
	}

	function removeUnusedCategories() {
		ensureIsInitialized();
		const oldLength = svc.categories.length;
		_.remove(svc.categories, category => {
			return !category.count;
		});
		const newLength = svc.categories.length;
		if(newLength !== oldLength) {
			onChanged();
		}
	}

	function refreshCategories() {
		ensureIsInitialized();
		refreshCategoriesFromServer().then(response => {
			initializeCategories(response.categoryDefinitions, response.memberCounts);
		});
	}

	function sortCategories(questionTitle, criteria, isAscending) {
		ensureIsInitialized();
		const mbox = messageBox.openQuestion(
			questionTitle,
			"This operation cannot be undone.",
			"Sort", "Cancel"
		);
		mbox.result.then(() => {
			svc.categories = _.sortBy(svc.categories, criteria);
			if(!isAscending) {
				svc.categories.reverse();
			}
			onChanged();
		});
	}
}
