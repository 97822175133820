angular.module("eShareApp").controller("MessageBoxCtrl", [
	"$scope", "$uibModalInstance", "options",

	function (
		$scope, $uibModalInstance, options
	) {

		if(!options.title || !options.message
			|| (!options.okButtonText && !options.cancelButtonText)
			|| (!options.okButtonText && options.cancelButtonText)) {
			throw new EShareException("Invalid dialog definition");
		}

		$scope.title = options.title;
		$scope.message = options.message;
		$scope.okButtonText = options.okButtonText || "";
		$scope.cancelButtonText = options.cancelButtonText || "";
		$scope.ok2ButtonText = options.ok2ButtonText || "";

		$scope.ok = function () {
			$uibModalInstance.close("ok");
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss("cancel");
		};

		$scope.ok2 = function () {
			$uibModalInstance.close("ok2");
		};
	}
]);
