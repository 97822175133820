angular.module("eShare.core").factory("httpRequestInterceptor", httpRequestInterceptor);

httpRequestInterceptor.$inject = [
	"$q", "$rootScope", "$injector", "httpActivityNotificationChannel"
];

function httpRequestInterceptor($q, $rootScope, $injector, httpActivityNotificationChannel) {
	const authorizationHeaderKey = "Authorization";
	const cadAuthorizationHeaderKey = "X-CAD-Authorization";
	const cadAuthorizationChangedHeaderKey = "X-CAD-Authorization-Changed";
	let numberOfActiveRequests = 0;

	return {
		request: request,
		requestError: requestError,
		response: response,
		responseError: responseError,
	};

	function request(config) {
		httpActivityStarted();
		if(config && config.headers) {
			const token = window.eShare.currentUser.token;
			if(Utilities.isString(token) && token !== "" && !window.eShare.integratedSecurity) {
				config.headers[authorizationHeaderKey] = "Bearer " + token;
			}
			const cadAuthorization = window.Authenticator.getCadAuthorization();
			if(cadAuthorization) {
				config.headers[cadAuthorizationHeaderKey] = cadAuthorization;
			}
		}
		return config;
	}

	function requestError(rejection) {
		httpActivityStarted();
		return $q.reject(rejection);
	}

	function response(actualResponse) {
		httpActivityEnded();
		if(actualResponse && actualResponse.headers) {
			try {
				const cadAuthorizationChanged = actualResponse.headers(
					cadAuthorizationChangedHeaderKey
				);
				if(cadAuthorizationChanged) {
					const cadAuthorization = actualResponse.headers(cadAuthorizationHeaderKey);
					if(cadAuthorization) {
						setCadAuthorization(cadAuthorization);
					}
				}
			} catch(e) {
				// ignore
			}
		}
		return actualResponse;
	}

	function responseError(rejection) {
		httpActivityEnded();

		if(rejection.config && rejection.config.data && rejection.config.data.file) {
			// This was a file upload. Do nothing.
		} else if(rejection.status === 401
			|| (rejection.status === 400 && rejection.data === "Unrecognized access token")) {
			setCadAuthorization(null);
			$rootScope.$broadcast("event:loginRequired");
		} else if(rejection.status === 403) {
			$rootScope.$broadcast("event:permissionDenied");
		} else if(rejection.status === 498) {
			setCadAuthorization(null);
			$rootScope.$broadcast("event:sessionTerminated");
		} else if(rejection.status === 499) {
			setCadAuthorization(null);
			$rootScope.$broadcast("event:noLicense");
		} else if(rejection.status === 598) {
			$rootScope.$broadcast("event:databaseSchemaError", {
				type: "project",
				message: rejection.data.message,
			});
		} else if(rejection.status === 599) {
			$rootScope.$broadcast("event:databaseSchemaError", {
				type: "main",
				message: rejection.data.message,
			});
		} else if(rejection.status <= 0) {
			const serverMonitor = $injector.get("serverMonitor");
			serverMonitor.connectionLost();
		}
		return $q.reject(rejection);
	}

	function httpActivityStarted() {
		if(numberOfActiveRequests === 0) {
			httpActivityNotificationChannel.activityStarted();
		}
		++numberOfActiveRequests;
	}

	function httpActivityEnded() {
		--numberOfActiveRequests;
		if(numberOfActiveRequests === 0) {
			httpActivityNotificationChannel.activityEnded();
		}
	}

	function setCadAuthorization(cadAuthorization) {
		window.Authenticator.setCadAuthorization(cadAuthorization);
	}
}
