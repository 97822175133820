angular.module("eShareApp").controller("LicenseCtrl", LicenseCtrl);

LicenseCtrl.$inject = [
	"$state", "$scope", "project", "licenseRepository", "notification"
];

function LicenseCtrl(
	$state, $scope, project, licenseRepository, notification
) {

	$scope.state = $state;
	$scope.project = project;

	$scope.licenses = [];
	const divider = " - ";

	$scope.cadLicense = getCadLicense();

	licenseRepository.getThirdPartyLicenseList().then(
		text => {
			const lines = text.split("\n");
			for(let line = 0; line < lines.length; line++) {
				const row = lines[line];
				if(row.indexOf(divider) !== -1) {
					const licenseName = row.substring(0, row.indexOf(divider));
					const licensePath = row.substring(row.indexOf(divider) + 2);
					const license = [licenseName, licensePath];
					$scope.licenses.push(license);
				}
			}
		}, error => {
			notification.error(error);
		}
	);

	function getCadLicense() {
		return licenseRepository.getCadmaticLicense();
	}

	$scope.viewCadmaticLicense = function () {
		const win = window.open("", "_blank");
		const html = $scope.cadLicense.$$state.value.toString();
		win.document.title = "CADMATIC eShare License";
		win.document.body.innerHTML = html;
	};
}
