angular.module("eShare.documentViewer")
	.controller("cadDocumentTreeViewPrintCtrl", cadDocumentTreeViewPrintController);

cadDocumentTreeViewPrintController.$inject = [
	"$timeout", "$state", "$stateParams", "project", "notification", "printedDocumentInformation"
];

// ReSharper disable InconsistentNaming
function cadDocumentTreeViewPrintController(
	$timeout, $state, $stateParams, project, notification, printedDocumentInformation
) {
	// ReSharper enable InconsistentNaming

	const vm = this;
	vm.document = printedDocumentInformation.document;
	vm.projectName = project.name;
	vm.projectId = project.id;
	vm.imageDataUrl = printedDocumentInformation.image;
	vm.page = printedDocumentInformation.currentPageNumber;
	vm.bounds = printedDocumentInformation.bounds;
	vm.rows = [];
	vm.close = close;

	initialize();

	function initialize() {
		if(!project || !project.id) {
			notification.error("Project not found");
			$state.go("selectProject", {}, { location: "replace" });
			return;
		}
		vm.rows = vm.document ? vm.document.metadata : [];
		makeQrCode();
		$timeout(() => {
			/*
			Resize the table cell to match the size of the picture
			This clips the "extra space" away (either from width or height)
			after resizing the picture to 1024x768 limits.
			*/
			const imageTableElement = document.getElementById("imageTable");
			const imageTableRow = imageTableElement.rows[1];
			if(imageTableRow) {
				const printImgTdElement = imageTableRow.cells[0];
				const printImgElement = printImgTdElement.children[0];
				printImgTdElement.style.height = printImgElement.offsetHeight + "px";
				printImgTdElement.style.width = printImgElement.offsetWidth + "px";
			}
			window.print();
		}, 500);
	}

	function close() {
		$state.go("project.document", $stateParams, { location: "replace" });
	}

	function makeQrCode() {
		const qrCodeElement = angular.element(document.getElementById("qrcode"));
		if(vm.document) {
			const href = $state.href("project.document", {
				projectId: vm.projectId,
				dataSourceId: vm.document.dataSourceId,
				documentId: vm.document.id,
				page: vm.page,
				x: vm.bounds.x,
				y: vm.bounds.y,
				width: vm.bounds.width,
				height: vm.bounds.height,
			}, {
				absolute: true,
				reload: true,
				inherit: false,
			});
			const qrCode = new QRCode(
				"qrcode",
				{
					width: 512,
					height: 512,
					correctLevel: QRCode.CorrectLevel.L,
				}
			);
			qrCode.makeCode(href);
			const imageElement = qrCodeElement.find("img")[0];
			imageElement.style.width = "100%";
			imageElement.style.height = "100%";
		} else {
			qrCodeElement.innerHTML = "";
		}
	}
}
