angular.module("eShare.ui").directive("cadHelp", cadHelpDirective);

cadHelpDirective.$inject = [];

function cadHelpDirective() {
	return {
		restrict: "E",
		controller: CadHelpController,
		controllerAs: "vm",
		bindToController: {
			level: "=",
		},
		scope: {},
		replace: true,
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\ui\\cadHelp.directive.html'),
		transclude: true,
	};
}

CadHelpController.$inject = ["helpService"];

function CadHelpController(helpService) {
	const vm = this;
	vm.isVisible = isVisible;

	function isVisible() {
		return helpService.isVisible(vm.level);
	}
}
