angular.module("eShareApp").factory("pointCloudsRepository", pointCloudsRepository);
pointCloudsRepository.$inject = ["repositoryBase", "$http"];

function pointCloudsRepository(repositoryBase, $http) {
	const baseApi = "api/project/{projectId}";
	const pointCloudApi = baseApi + "/PointCloud";
	const downloadApi = baseApi + "/PointCloud/{cpxFileName}/";
	const settingsApi = baseApi + "/PointCloudSettings";

	function getPointClouds(projectId) {
		return repositoryBase.get(baseApi + "/PointClouds", { projectId: projectId });
	}

	function removePointCloud(projectId, fileName) {
		return repositoryBase.remove(
			pointCloudApi + "/Remove/{fileName}/",
			{ projectId: projectId, fileName: fileName }
		);
	}

	function getPointCloudFolder(projectId) {
		return repositoryBase.get(baseApi + "/PointCloudFolder", { projectId: projectId });
	}

	function getPointCloudSettings(projectId) {
		return repositoryBase.get(settingsApi, { projectId: projectId });
	}

	function savePointCloudSettings(projectId, settings) {
		return repositoryBase.post(settingsApi, { projectId: projectId }, settings);
	}

	function cleanPointCloudFolder(projectId) {
		return repositoryBase.post(baseApi + "/PointCloudFolder/Clean", { projectId: projectId });
	}

	function downloadPointCloud(projectId, cpxFileName) {
		return $http.get(downloadApi.supplant({ projectId: projectId, cpxFileName: cpxFileName }), {
			responseType: "blob",
		});
	}

	function importSettings(projectId, settings) {
		const exportedData = JSON.parse(settings);
		return repositoryBase.post(settingsApi + "/import", { projectId: projectId }, exportedData);
	}

	function exportSettings(projectId) {
		return repositoryBase.get(settingsApi + "/export", { projectId: projectId });
	}

	return {
		getPointClouds: getPointClouds,
		getPointCloudFolder: getPointCloudFolder,
		removePointCloud: removePointCloud,
		getPointCloudSettings: getPointCloudSettings,
		cleanPointCloudFolder: cleanPointCloudFolder,
		downloadPointCloud: downloadPointCloud,
		savePointCloudSettings: savePointCloudSettings,
		importSettings: importSettings,
		exportSettings: exportSettings,
	};
}