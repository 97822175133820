angular.module("eShareApp").controller("NotificationsCtrl", [
	"$scope", "$state", "notification", "project",

	function (
		$scope, $state, notification, project
	) {

		$scope.notifications = notification.messages;
		$scope.project = project;

		$scope.toggleDetails = function (message) {
			if(message.showDetails) {
				message.showDetails = false;
			} else {
				message.showDetails = true;
			}
		};

		$scope.clearAll = function () {
			notification.clearAll();
		};
	}
]);
