angular.module("eShareApp").factory("savedSearchExportConfigurationsRepository", [
	"repositoryBase", "notification",
	function (repositoryBase, notification) {

		const baseApi = "/api/project/{projectId}";
		const savedSelectionsApi = baseApi + "/SavedSearchExportConfigurations";
		const savedSelectionsIdApi = baseApi + "/SavedSearchExportConfigurations/{selectionsId}";

		function getAll(projectId) {
			return repositoryBase.get(savedSelectionsApi, { projectId: projectId }).then(data => {
				return data;
			}, error => {
				notification.error(error);
			});
		}

		function add(projectId, selections) {
			return repositoryBase.post(savedSelectionsApi, { projectId: projectId }, selections);
		}

		function remove(projectId, selectionsId) {
			return repositoryBase.remove(
				savedSelectionsIdApi,
				{ projectId: projectId, selectionsId: selectionsId }
			);
		}

		return {
			getAll: getAll,
			add: add,
			remove: remove,
		};
	}
]);