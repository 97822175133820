angular.module("eShareApp")
	.directive("cadAttributeCombinationInput", cadAttributeCombinationInputDirective);

function cadAttributeCombinationInputDirective() {
	return {
		restrict: "E",
		controller: cadAttributeCombinationInputController,
		controllerAs: "vm",
		bindToController: {
			definitions: "=",
			format: "=",
			ownAttributeName: "=",
			onChange: "&?",
		},
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadAttributeCombinationInput.html'),
		replace: true,
	};
}

cadAttributeCombinationInputController.$inject = ["$filter", "$scope", "$timeout"];

function cadAttributeCombinationInputController($filter, $scope, $timeout) {
	const vm = this;
	vm.isInserting = false;
	vm.current = -1;
	vm.listHidden = true; // hides the list initially
	vm.newComponent = "";
	vm.hasInputBeenSelected = false;
	vm.isAppendLoaded = true;
	vm.cancelFocus = true;
	vm.format.forEach(item => {
		if(item != null) {
			item.isEditing = false;
		}
	});

	vm.setScope = function (id) {
		vm.scopeId = id;
		vm.sortableOptions = {
			accept: function (sourceItemHandleScope, destSortableScope) {
				return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
			},
			containment: "#displayedColumnsList" + id,
			containerPositioning: "relative",
		};
	};

	vm.addComponent = function (component, componentType) {
		vm.insertComponent(component, componentType);
	};

	let debounce = false;
	vm.insertComponent = function (component, componentType, index) {
		vm.format.forEach(item => {
			if(item != null) {
				item.isEditing = false;
			}
		});
		vm.newComponent = "";
		vm.current = -1;
		vm.listHidden = true;
		vm.isInserting = false;
		if(debounce) {
			return;
		} else {
			debounce = true;
			$timeout(() => {
				debounce = false;
			}, 100);
		}
		if(index != null) {
			vm.removeComponent(vm.format[index]);
		}
		index = index == null ? vm.format.length : index;
		if(componentType === "string" && component != null && component !== "") {
			vm.format.splice(index, 0, {
				displayName: component,
				type: componentType,
			});
		} else if(componentType === "attribute" && component != null && component !== "") {
			vm.format.splice(index, 0, {
				abbreviation: component.abbreviation,
				displayName: component.displayName,
				type: componentType,
			});
		}
		if(typeof vm.onChange === "function") {
			vm.onChange();
		}
		return;
	};
	vm.removeComponent = function (component) {
		_.remove(vm.format, component);
		if(typeof vm.onChange === "function") {
			vm.onChange();
		}
	};
	vm.onKeyDown = function (evt, index) {
		let filteredDefinitions;
		switch(evt.keyCode) {
		case 38: // Up arrow:
			if(vm.current >= 0) {
				vm.current--;
				evt.preventDefault();
			}
			break;
		case 40: // Down arrow:
			filteredDefinitions = $filter("filter")(
				vm.definitions,
				{ displayName: vm.newComponent },
				vm.filterAttributes
			);
			if(vm.current < filteredDefinitions.length - 1) {
				vm.current++;
				evt.preventDefault();
			}
			break;
		case 13: // Enter key:
			if(vm.current >= 0) {
				if(!vm.listHidden) {
					filteredDefinitions = $filter("filter")(
						vm.definitions,
						{ displayName: vm.newComponent },
						vm.filterAttributes
					);
					vm.insertComponent(filteredDefinitions[vm.current], "attribute", index);
				}
			} else {
				vm.insertComponent(vm.newComponent, "string", index);
			}
			vm.newComponentWidth = "32px";
			evt.preventDefault();
			document.body.focus();
			vm.hasEnterBeenPressed = true;
			vm.reloadAppend();
			break;
		default:
			vm.current = -1;
			break;
		}
		vm.listHidden = false;
	};
	vm.newComponentWidth = "32px";
	vm.newComponentChanged = function () {
		const filteredTokens = $filter("filter")(
			vm.definitions,
			{ displayName: vm.newComponent },
			vm.filterAttributes
		);
		vm.listHidden = (filteredTokens.length === 0);
		const tempSpan = document.createElement("span");
		tempSpan.className = "cad-attribute-textbox temp-element";
		tempSpan.innerHTML = (vm.newComponent + "WW")
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt");
		document.body.appendChild(tempSpan);
		vm.newComponentWidth = tempSpan.offsetWidth;
		document.body.removeChild(tempSpan);
	};
	vm.isCurrent = function (index) {
		return vm.current === index;
	};
	vm.setCurrent = function (index) {
		vm.current = index;
	};
	vm.componentClass = function (component) {
		let style = "label";
		if(component.isHovered) {
			style += " cad-attribute-expand";
		}
		switch(component.type) {
		case "string":
			return style + " label-default";
		case "attribute":
			return style + " label-primary";
		}
		return style + " label-warning";
	};
	vm.filterAttributes = function (actual, expected) {
		if(typeof actual !== "string") {
			return false;
		}
		if(expected.length === 0 || actual.length === 0) {
			return false;
		}
		if(actual === vm.ownAttributeName) {
			return false;
		}
		return actual.toLowerCase().indexOf(expected.toLowerCase()) > -1;
	};
	vm.editComponent = function (component) {
		const fakeKey = {
			keyCode: 13,
			preventDefault: function () {
			},
		};
		vm.onKeyDown(fakeKey);
		component.isEditing = true;
		vm.isInserting = true;
		vm.newComponent = component.displayName;
		vm.newComponentChanged();
	};
	vm.stopPropagation = function (event) {
		event.stopPropagation();
	};
	$scope.$watch("vm.definitions", () => {
		const abbreviationDefinitions = vm.definitions.map(item => {
			return item.abbreviation;
		});
		const displayNameDefinitions = vm.definitions.map(item => {
			return item.displayName;
		});
		_.filter(vm.format, item => {
			return item.type == null || item.type === "attribute";
		}).forEach(item => {
			const abbreviationLocation = abbreviationDefinitions.indexOf(item.abbreviation);
			if(abbreviationLocation !== -1) {
				item.abbreviation = abbreviationDefinitions[abbreviationLocation];
				item.displayName = displayNameDefinitions[abbreviationLocation];
				item.type = "attribute";
			} else {
				delete (item.type);
			}
		});
	}, true);
	vm.focusInput = function (id, index) {
		$timeout(() => {
			const inputField = document.getElementById(id + "-" + index);

			if(vm.cancelFocus) {
				vm.cancelFocus = false;
			} else {
				if(!inputField) {
					return;
				}
				inputField.focus();
				inputField.selectionStart = inputField.selectionEnd = 10000;
			}
			if(!inputField) {
				return;
			}
			inputField.onblur = function () {
				index = typeof index === "number" ? index : undefined;
				vm.onKeyDown({
					keyCode: 13,
					preventDefault: function () {
					},
				}, index);
				vm.cancelFocus = true;
			};
		});
	};
	vm.focusAppend = function (id) {
		vm.focusInput(id, "append");
	};
	vm.showOnEnter = function (component) {
		component.isHovered = true;
		component.isRemoving = false;
	};

	const interval = 2000;
	vm.hideOnExit = function (component) {
		component.lastExit = Date.now();
		component.isRemoving = true;
		$timeout(() => {
			const timeSinceLastExit = Date.now() - component.lastExit;
			if(component.isRemoving && timeSinceLastExit + 100 > interval) {
				component.isHovered = false;
			}
		}, interval);
	};

	vm.reloadAppend = function () {
		vm.isAppendLoaded = false;
		$timeout(() => {
			vm.isAppendLoaded = true;
		});
	};
}