angular.module("eShareApp").component("cadProjectStatusView", {
	template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadProjectStatusView.html'),
	controller: CadProjectStatusView,
	bindings: {
		project: "<",
		isProjectDisabled: "<",
		dataSources: "<",
		synchronizationObject: "<",
		creationState: "<",
	},
});

CadProjectStatusView.$inject = [
	"$state", "$interval", "modelsRepository", "backgroundProgressesRepository", "messageBox"
];

function CadProjectStatusView(
	$state, $interval, modelsRepository, backgroundProgressesRepository, messageBox
) {
	const ctrl = this;
	ctrl.publishingProgress = {
		percentage: "Not publishing",
		message: "",
	};
	ctrl.pointCloudProgress = {
		action: "Not active",
		step: "",
	};

	const dataSourcesFound = ctrl.dataSources && ctrl.dataSources.length > 0;
	let indexingMessagesFound = false;

	ctrl.onUpdate = function (publishingData, pointCloudData, indexingData) {
		getUploadDate(ctrl.project.id).then(setProjectPublishingDate());
		if(publishingData) {
			ctrl.publishingProgress.message = publishingData[1];
			ctrl.publishingProgress.percentage = publishingData[2];
			ctrl.publishingProgress.totalPublishingTime = parseInt(publishingData[3]);
			if(ctrl.publishingProgress.message !== "" && !ctrl.publishingProgress.countDownStarted) {
				if(ctrl.publishingProgress.isPublishingBarInErrorState) {
					if(ctrl.publishingProgress.percentage !== "Publishing failed") {
						startPublishingCountDown(ctrl.project.id);
						ctrl.publishingProgress.isPublishingBarInErrorState = false;
					}
				} else {
					startPublishingCountDown(ctrl.project.id);
				}
			}
		}
		if(pointCloudData) {
			ctrl.pointCloudProgress.action = pointCloudData[1];
			ctrl.pointCloudProgress.step = pointCloudData[2];
		}
		if(indexingData && ctrl.dataSources) {
			for(let dsIndex = 0; dsIndex < ctrl.dataSources.length; dsIndex++) {
				for(let i = 0; i < indexingData.length; i++) {
					if(ctrl.project.id === indexingData[i][0] && ctrl.dataSources[dsIndex].source.id === indexingData[i][1]) {
						ctrl.dataSources[dsIndex].message = indexingData[i][2];
						ctrl.dataSources[dsIndex].percentage = indexingData[i][3];
					}
				}
			}
		}
		if(!indexingMessagesFound && dataSourcesFound) {
			for(let i = 0; i < ctrl.dataSources.length; i++) {
				if(ctrl.dataSources[i].percentage.length === 0) {
					ctrl.dataSources[i].message = "Not indexing";
				}
			}
			indexingMessagesFound = true;
		}
	};

	if(ctrl.synchronizationObject !== undefined && ctrl.synchronizationObject !== null) {
		ctrl.synchronizationObject.onUpdate = ctrl.onUpdate;
	}

	ctrl.clearIndexing = function (projectId, datasource) {
		const mbox = messageBox.openQuestion(
			"Clear the indexing of data source?",
			"Do you really want to clear the indexing of data source " + datasource.name + "?",
			"Clear", "Cancel"
		);
		mbox.result.then(() => {
			backgroundProgressesRepository.clearIndexing(projectId, datasource.id);
		});
	};

	ctrl.restartIndexing = function (projectId, datasource) {
		backgroundProgressesRepository.restartIndexing(projectId, datasource.id);
	};

	ctrl.restartAsapIndexing = function (projectId, datasource) {
		backgroundProgressesRepository.restartAsapIndexing(projectId, datasource.id);
	};

	function getUploadDate(projectId) {
		try {
			const result = modelsRepository.getModelInfo(projectId).then(
				projectModels => {
					try {
						const uploadDate = new Date(projectModels[0].revisions[0].uploadDate);
						const uploadDateString = uploadDate.getDate()
								+ "."
								+ (uploadDate.getMonth() + 1)
								+ "."
								+ uploadDate.getFullYear()
								+ " "
								+ doubleDigitForm(uploadDate.getHours())
								+ ":"
								+ doubleDigitForm(uploadDate.getMinutes())
								+ ":"
								+ doubleDigitForm(uploadDate.getSeconds());
						return uploadDateString;
					} catch(e) {
						return "";
					}
				},
				(/*error*/) => {
					return "";
				}
			);
			return result;
		} catch(e) {
			return "";
		}
	}

	function setProjectPublishingDate() {
		return function (dateTime) {
			if(dateTime && dateTime.length > 0) {
				ctrl.publishingProgress.lastPublishedDate = "Last published at " + dateTime;
			} else {
				ctrl.publishingProgress.lastPublishedDate = "No published model";
			}
		};
	}

	function doubleDigitForm(value) {
		if(value < 10) {
			return "0" + value.toString();
		} else {
			return value.toString();
		}
	}

	function startPublishingCountDown(projectId) {
		if(!ctrl.publishingProgress) {
			return;
		}
		ctrl.publishingProgress.countDownStarted = true;
		backgroundProgressesRepository.getRemainingPublishingTime(projectId).then(publishingTime => {
			if(publishingTime) {
				ctrl.publishingProgress.publishingTime = publishingTime;
				ctrl.publishingProgress.barColor = "#007bff";
				const counter = $interval(() => {
					if($state.current.name === ctrl.creationState) {
						if(ctrl.publishingProgress.publishingTime > 0) {
							ctrl.publishingProgress.publishingTime--;
							stringifyPublishingTime(false);
							const totalPublishingTime = ctrl.publishingProgress.totalPublishingTime;
							const publishingTime = ctrl.publishingProgress.publishingTime;
							let publishingProgress =
								((totalPublishingTime - publishingTime) / totalPublishingTime);
							publishingProgress = Math.floor(publishingProgress * 100);
							ctrl.publishingProgress.barLength = publishingProgress + "%";
							ctrl.publishingProgress.barTextColor = publishingProgress > 50
								? "white"
								: ctrl.publishingProgress.barColor === "#dc3545"
									? "white"
									: "black";
						} else {
							startOverTimeCounter();
							$interval.cancel(counter);
						}
						if(ctrl.publishingProgress.percentage === "Publishing failed") {
							ctrl.publishingProgress.barColor = "#dc3545";
							ctrl.publishingProgress.barTextColor = "white";
							ctrl.publishingProgress.barLength = "100%";
							ctrl.publishingProgress.publishingTimeString = "ERROR";
							setTimeout($interval.cancel(counter), 1000 * 10);
							ctrl.publishingProgress.countDownStarted = false;
							ctrl.publishingProgress.isPublishingBarInErrorState = true;
						}
					}
				}, 1000);
			} else {
				setTimeout(startPublishingCountDown(), 1000);
			}
		});
	}

	function startOverTimeCounter() {
		ctrl.publishingProgress.barColor = "#ffc107";
		ctrl.publishingProgress.barLength = "100%";
		ctrl.publishingProgress.barTextColor = "black";
		ctrl.publishingProgress.publishingTime *= -1;
		const counter = $interval(() => {
			if($state.current.name === ctrl.creationState) {
				if(ctrl.publishingProgress.message !== "") {
					ctrl.publishingProgress.publishingTime++;
					stringifyPublishingTime(true);
					if(ctrl.publishingProgress.percentage === "Publishing failed") {
						ctrl.publishingProgress.barColor = "#dc3545";
						ctrl.publishingProgress.barTextColor = "white";
						ctrl.publishingProgress.barLength = "100%";
						ctrl.publishingProgress.publishingTimeString = "ERROR";
						setTimeout($interval.cancel(counter), 1000 * 10);
						ctrl.publishingProgress.countDownStarted = false;
						ctrl.publishingProgress.isPublishingBarInErrorState = true;
					}
				} else {
					$interval.cancel(counter);
					ctrl.publishingProgress.countDownStarted = false;
				}
			}
		}, 1000);
	}

	function stringifyPublishingTime(isOnOvertime) {
		let seconds;
		let minutes = "00";
		let hours = "00";
		if(ctrl.publishingProgress.publishingTime > 60) {
			if((ctrl.publishingProgress.publishingTime / 60) > 60) {
				hours = Math.floor(ctrl.publishingProgress.publishingTime / 60 / 60);
				hours = hours < 10 ? "0" + hours : hours;
				minutes = Math.floor(ctrl.publishingProgress.publishingTime / 60) % 60;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = ctrl.publishingProgress.publishingTime % 60;
				seconds = seconds < 10 ? "0" + seconds : seconds;
			} else {
				minutes = Math.floor(ctrl.publishingProgress.publishingTime / 60);
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = ctrl.publishingProgress.publishingTime % 60;
				seconds = seconds < 10 ? "0" + seconds : seconds;
			}
		} else {
			seconds = ctrl.publishingProgress.publishingTime < 10
				? "0" + ctrl.publishingProgress.publishingTime
				: ctrl.publishingProgress.publishingTime;
		}
		let timeString = hours + ":" + minutes + ":" + seconds;
		timeString = isOnOvertime ? "On overtime: " + timeString : "About " + timeString + " left";
		ctrl.publishingProgress.publishingTimeString = timeString;
	}
}
