angular
	.module("eShareApp")
	.factory("attributeTransformationsRepository", attributeTransformationsRepository);

attributeTransformationsRepository.$inject = ["repositoryBase"];

function attributeTransformationsRepository(repositoryBase) {
	const baseApi = "/api/project";
	const attributeTransformationApi = baseApi + "/{projectId}/AttributeTransformations/{modelId}";
	const attributeTransformationTestApi = attributeTransformationApi + "/Test";
	const attributeTransformationCompilationApi = attributeTransformationApi + "/Compile";
	const groupDefiningAbbreviationsApi = baseApi + "/{projectId}/GroupDefiningAbbreviations";
	const keyDefiningAbbreviationsApi = baseApi + "/{projectId}/KeyDefiningAbbreviations";
	const excludeFromPublishAbbreviationsApi =
		baseApi + "/{projectId}/ExcludeFromPublishAbbreviations";

	return {
		get: get,
		getGroupDefiningAbbreviations: getGroupDefiningAbbreviations,
		getKeyDefiningAbbreviations: getKeyDefiningAbbreviations,
		getExcludeFromPublishAbbreviations: getExcludeFromPublishAbbreviations,
		test: test,
		compile: compile,
		save: save,
		exportAll: exportAll,
		importAll: importAll,
	};

	function get(projectId, modelId) {
		return repositoryBase.get(
			attributeTransformationApi, { projectId: projectId, modelId: modelId }
		);
	}

	function getGroupDefiningAbbreviations(projectId) {
		return repositoryBase.get(groupDefiningAbbreviationsApi, { projectId: projectId });
	}

	function getKeyDefiningAbbreviations(projectId) {
		return repositoryBase.get(keyDefiningAbbreviationsApi, { projectId: projectId });
	}

	function getExcludeFromPublishAbbreviations(projectId) {
		return repositoryBase.get(excludeFromPublishAbbreviationsApi, { projectId: projectId });
	}

	function test(projectId, modelId, transformations, index, scriptTransformation) {
		const postData = {
			transformationDtos: transformations,
			testedIndex: index,
			scriptTransformationDto: scriptTransformation,
		};
		return repositoryBase.post(
			attributeTransformationTestApi, { projectId: projectId, modelId: modelId }, postData
		);
	}

	function compile(projectId, modelId, scriptTransformation) {
		const postData = {
			scriptTransformationDto: scriptTransformation,
		};
		return repositoryBase.post(
			attributeTransformationCompilationApi, { projectId: projectId, modelId: modelId }, postData
		);
	}

	function save(
		projectId,
		modelId,
		attributeTransformations,
		groupDefiningAbbreviations,
		keyDefiningAbbreviations,
		excludeFromPublishAbbreviations
	) {
		const postData = {
			attributeTransformations: attributeTransformations,
			groupDefiningAbbreviations: groupDefiningAbbreviations,
			keyDefiningAbbreviations: keyDefiningAbbreviations,
			excludeFromPublishAbbreviations: excludeFromPublishAbbreviations,
		};
		return repositoryBase.post(
			attributeTransformationApi, { projectId: projectId, modelId: modelId }, postData
		);
	}

	function exportAll(projectId, modelId) {
		return repositoryBase
			.post(
				Utilities.combineUri(attributeTransformationApi, "export"),
				{ projectId: projectId, modelId: modelId }
			)
			.then(exportedData => JSON.stringify(exportedData, undefined, 3));
	}

	function importAll(projectId, modelId, serializedExportedData) {
		const exportedData = JSON.parse(serializedExportedData);
		return repositoryBase.post(
			Utilities.combineUri(attributeTransformationApi, "import"),
			{ projectId: projectId, modelId: modelId },
			exportedData
		);
	}
}
