angular.module("eShareApp").directive("cadStatusHistory", cadStatusHistory);

function cadStatusHistory() {
	return {
		restrict: "E",
		controller: CadStatusHistoryController,
		controllerAs: "vm",
		scope: {},
		bindToController: {
			projectId: "@",
			value: "@",
			name: "@",
			saveStatus: "<",
			kinds: "=",
			key: "=",
			update3d: "=",
			update3dWithIds: "=",
			statusIdentifier: "@",
			getAttributeByTag: "<",
			getGuidIdByTag: "<",
		},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadStatusHistory.html'),
		replace: true,
	};
}

CadStatusHistoryController.$inject = [
	"$scope", "$filter", "$timeout", "statusTrackingKindRepository"
];

function CadStatusHistoryController(
	$scope, $filter, $timeout, statusTrackingKindRepository
) {
	const vm = this;
	vm.isVisible = false;
	vm.toggleStatusHistory = toggleStatusHistory;
	const emptyGuid = Utilities.emptyGuid();
	const formatAttributeValueFilter = $filter("formatAttributeValue");

	$scope.$watch("vm.statusIdentifier", initialize);

	function initialize() {
		$timeout(() => {
			addStatusTrackingToState();
			populateTransitions();
		}, 1);
	}

	function addStatusTrackingToState() {
		if(!vm.key || !vm.kinds) {
			return;
		}
		vm.trackingIds = vm.key.statusTrackingIds;
		if(vm.key.enumIds.length > 1) {
			vm.enumId = "multivalueid";
		} else {
			vm.enumId = vm.key.enumIds[0];
		}
		vm.originalValues = vm.key.enumIds;
		vm.kindId = vm.key.statusTrackingKindId;
		const kind = _.find(vm.kinds, { "id": vm.kindId });
		if(!kind) {
			console.error("No status tracking kind found with the given key.", vm.kinds, vm.key);
		}
		vm.kind = kind;
		let abbreviation = kind.attributeDefinitionAbbreviation;
		if(abbreviation
			&& abbreviation.charAt(0) === "["
			&& abbreviation.charAt(abbreviation.length - 1) === "]") {
			abbreviation = abbreviation.substring(1, abbreviation.length - 1);
		}
		vm.isAddingAllowed = kind.isAddingAllowedForUser;
		vm.isChangingAllowed = kind.isChangingAllowed;
		if(kind.isReferencingGuid === true){
			vm.guidIds = vm.key.guidIdValues;
		} else {
			vm.attributeValues = vm.key.keyAttributeValues;
		}
		for(let i = 0; i < vm.key.enumIds.length; i++) {
			const enumId = vm.key.enumIds[i];
			const state = _.find(kind.statusValues, { "id": enumId });
			let stateName = kind.defaultCategory;
			let stateColor = kind.defaultColor;
			if(state && enumId !== emptyGuid) {
				stateName = state.name;
				stateColor = state.color;
			}
			if(kind.isReferencingGuid === true){
				if(vm.key.geometryIds && vm.key.geometryIds.length > 0){
					vm.update3dWithIds(vm.key.geometryIds, kind.name, stateName, stateColor)
				}
			} else {
				_.forEach(vm.attributeValues[i], attributeValue => {
					vm.update3d(abbreviation, attributeValue, kind.name, stateName, stateColor);
				});
			}
		}
	}

	function populateTransitions() {
		function getAllTransitions(currentStatusId, statusValues) {
			const transitions = [];
			_.forEach(statusValues, statusValue => {
				if(statusValue.id !== currentStatusId) {
					const state = {
						id: statusValue.id,
						name: statusValue.name,
					};
					transitions.push(state);
				}
			});
			return transitions;
		}

		vm.transitions = [];
		let commonTransitions = null;
		for(let i = 0; i < vm.key.enumIds.length; i++) {
			const currentStateId = vm.key.enumIds[i];
			const values = vm.kind.statusValues;
			let normalizedTransitions = [];
			if(vm.kind.transitionsForUser) {
				const transition = _.find(
					vm.kind.transitionsForUser,
					{ "fromStateId": currentStateId }
				);
				if(transition && Utilities.isArray(transition.toStateIds)) {
					_.forEach(transition.toStateIds, stateId => {
						const name = _.find(values, { "id": stateId }).name;
						const state = {
							id: stateId,
							name: name,
						};
						normalizedTransitions.push(state);
					});
				}
			} else {
				normalizedTransitions = getAllTransitions(currentStateId, values);
			}
			if(!_.includes(normalizedTransitions, { "id": currentStateId })) {
				let name;
				if(Utilities.isEmptyGuid(currentStateId)) {
					name = vm.kind.defaultCategory;
				} else {
					name = _.find(values, { "id": currentStateId }).name;
				}
				normalizedTransitions.unshift({
					id: currentStateId,
					name: name,
				});
			}
			if(commonTransitions === null) {
				commonTransitions = normalizedTransitions;
			} else {
				commonTransitions = _.intersectionWith(
					commonTransitions,
					normalizedTransitions,
					(a, b) => {
						return a.id == b.id;
					}
				);
			}
		}
		vm.transitions = commonTransitions;
		if(vm.enumId === "multivalueid") {
			vm.transitions.push({
				id: "multivalueid",
				name: "Multiple Values",
			});
		}
	}

	function toggleStatusHistory() {
		if(vm.isVisible) {
			vm.statusHistory = undefined;
			vm.isVisible = false;
		} else {
			statusTrackingKindRepository.getHistory(vm.projectId, vm.trackingIds[0][0]).then(data => {
				const sortedData = _.sortBy(data, datum => {
					return -(datum.time || 0);
				});
				if(sortedData.length > 0) {
					vm.value = sortedData[0].value;
				}
				const formattedData = _.map(sortedData, datum => {
					datum.time = formatAttributeValueFilter({ value: datum.time, dataType: "unixDate" });
					return datum;
				});
				vm.statusHistory = formattedData;
				vm.isVisible = true;
			});
		}
	}

	vm.isHistoryToggleVisible = function () {
		return vm.trackingIds
			&& vm.trackingIds.length === 1
			&& vm.trackingIds[0].length === 1
			&& vm.originalValues
			&& vm.originalValues.length === 1;
	};

	vm.addEnum = function () {
		delete (vm.enumId);
		vm.changeStatus();
	};

	vm.changeStatus = function () {
		if(vm.enumId === "multivalueid"){
			return;
		}
		const abbreviation = vm.kind.attributeDefinitionAbbreviation;

		const currentTime = moment().utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
		const statusChanges = [];

		let attrValue = "";
		let guidId = null;

		if(vm.kind.isReferencingGuid){
			if((!vm.guidIdValues || vm.guidIdValues.length === 0)
				&& vm.trackingIds
				&& vm.trackingIds === 1) {
						guidId = vm.getGuidIdByTag(abbreviation);
			}
		} else {
			if((!vm.attributeValues || vm.attributeValues.length === 0)
				&& vm.trackingIds
				&& vm.trackingIds === 1) {
						attrValue = vm.getAttributeByTag(abbreviation);
			}
		}
		for(let i = 0; i < vm.originalValues.length; i++) {
			if(vm.originalValues[i] === vm.enumId) {
				continue;
			}
			const trackingSet = vm.trackingIds[i];
			_.forEach(trackingSet, id => {
				statusChanges.push({
					AttributeAbbreviation: abbreviation,
					KindId: vm.kindId,
					EnumId: vm.enumId,
					EnumName: vm.newEnumName,
					StatusTrackingId: id,
					AttributeValue: vm.kind.isReferencingGuid ? "" : attrValue,
					GuidId: vm.kind.isReferencingGuid ? guidId : "",
					EntryTime: currentTime,
					IsNewEnum: vm.isAddingEnum,
				});
			});
			if(!trackingSet || (trackingSet && trackingSet.length === 0)) {
				if(vm.kind.isReferencingGuid === true){
					const guidSet = vm.guidIds[i];
					_.forEach(guidSet, guid => {
						statusChanges.push({
							AttributeAbbreviation: abbreviation,
							KindId: vm.kindId,
							EnumId: vm.enumId,
							EnumName: vm.newEnumName,
							AttributeValue: "",
							GuidId: guid,
							EntryTime: currentTime,
							IsNewEnum: vm.isAddingEnum,
						});
					});
				} else {
					const valueSet = vm.attributeValues[i];
					_.forEach(valueSet, value => {
						statusChanges.push({
							AttributeAbbreviation: abbreviation,
							KindId: vm.kindId,
							EnumId: vm.enumId,
							EnumName: vm.newEnumName,
							AttributeValue: value,
							GuidId: null,
							EntryTime: currentTime,
							IsNewEnum: vm.isAddingEnum,
						});
					});
				}
			}
		}
		$timeout(() => {
			vm.saveStatus(statusChanges);
			vm.isAddingEnum = false;
		}, 1);
	};

	vm.startAddingEnum = function (id) {
		vm.isAddingEnum = true;
		vm.newEnumName = makeTemplateString();
		$timeout(() => {
			const inputBox = document.getElementById(id);
			inputBox.focus();
			$timeout(() => {
				inputBox.selectionStart = inputBox.selectionEnd = 10000;
			}, 0);
		}, 0);
	};

	vm.handleKeyDown = function (event) {
		switch(event.keyCode) {
		case 13: // Enter
			$timeout(() => {
				if(vm.validateNewEnum()) {
					vm.addEnum();
				}
			});
			break;
		case 27: // esc
			vm.isAddingEnum = false;
			event.preventDefault();
			break;
		}
	};

	vm.validateNewEnum = function () {
		const lowerCaseEnum = vm.newEnumName.toLowerCase();
		const isAlreadyExisting = _.find(vm.transitions, transition => {
			return transition.name.toLowerCase() === lowerCaseEnum;
		}) != null;
		const isEmpty = lowerCaseEnum.trim() === "";
		return !isAlreadyExisting && !isEmpty;
	};

	function makeTemplateString() {
		const template = vm.kind.template;
		if(vm.kind.template == null) {
			return "";
		}
		let templateString = "";
		let isValid = true;
		template.forEach(item => {
			let appendValue = null;
			switch(item.type) {
			case "attribute":
				appendValue = vm.getAttributeByTag(item.abbreviation);
				appendValue = appendValue
					|| (vm.key.templateAttributes && vm.key.templateAttributes[item.abbreviation]);
				break;
			case "string":
				appendValue = item.displayName;
				break;
			}
			isValid = isValid && appendValue != null;
			if(appendValue != null) {
				templateString += appendValue;
			}
		});
		if(isValid) {
			return templateString;
		} else {
			return "";
		}
	}
}