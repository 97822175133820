angular.module("eShareApp").controller("AllAdaptersExportCtrl", [
	"$state", "$scope", "adapters",

	function (
		$state, $scope, adapters
	) {

		$scope.importExportTitle = "Export";
		$scope.isImport = false;

		$scope.cfg = { json: angular.toJson(adapters, true) };
	}
]);
