angular.module("eShareApp").directive("cadNavBarLink", cadNavBarLink);

function cadNavBarLink() {
	return {
		restrict: "E",
		scope: {
			accessLevel: "@",
			state: "@",
		},
		replace: true,
		transclude: true,
		template: "<li ui-sref-active=\"active\" access-level=\"{{accessLevel}}\">"
				+ "<a ui-sref=\"{{state}}\" ng-transclude></a></li>",
	};
}