angular.module("eShareApp").factory("poiAttributeKindRepository", [
	"repositoryBase", "notification",
	function (repositoryBase, notification) {

		const baseApi = "/api/project/{projectId}";
		const kindApi = baseApi + "/PointOfInterestAttributeKind/{kindId}";
		const kindsApi = baseApi + "/PointOfInterestAttributeKinds";
		const kindsApiForPoiKind = baseApi + "/PointOfInterestAttributeKindsPerType/";

		function getAll(projectId) {
			return repositoryBase.get(kindsApi, { projectId: projectId }).then(data => {
				let kinds = Utilities.isArray(data) ? data : [];
				kinds = _.sortBy(kinds, kind => {
					return kind.name.toLowerCase();
				});
				return kinds;
			}, error => {
				notification.error(error);
			});
		}

		function getForPoiKinds(projectId) {
			return repositoryBase.get(kindsApiForPoiKind, { projectId: projectId });
		}

		function get(projectId, kindId) {
			return repositoryBase.get(kindApi, { projectId: projectId, kindId: kindId });
		}

		function update(projectId, kind) {
			return repositoryBase.put(kindsApi, { projectId: projectId }, kind);
		}

		function save(projectId, kind) {
			return repositoryBase.post(kindsApi, { projectId: projectId }, kind);
		}

		function remove(projectId, kindId) {
			return repositoryBase.remove(kindApi, { projectId: projectId, kindId: kindId });
		}

		return {
			getAll: getAll,
			get: get,
			update: update,
			save: save,
			remove: remove,
			getForPoiKinds: getForPoiKinds,
		};
	}
]);