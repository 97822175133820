angular
	.module("eShareApp")
	.controller("ModelHierarchiesExportImportCtrl", ModelHierarchiesExportImportCtrl);

ModelHierarchiesExportImportCtrl.$inject = [
	"$state", "project", "notification", "modelHierarchiesRepository", "isExport", "isImport"
];

function ModelHierarchiesExportImportCtrl(
	$state, project, notification, modelHierarchiesRepository, isExport, isImport
) {
	const vm = this;
	vm.isExport = isExport;
	vm.isImport = isImport;
	vm.serializedExportedData = "";
	vm.importResult = null;
	vm.importAll = importAll;
	vm.isInputValid = isInputValid;

	initialize();

	function initialize() {
		if(isExport) {
			vm.serializedExportedData = "Please wait...";
			modelHierarchiesRepository.exportHierarchies(project.id).then(
				serializedExportedData => {
					vm.serializedExportedData = serializedExportedData;
				}
			);
		}
	}

	function importAll() {
		vm.importResult = null;
		modelHierarchiesRepository.importHierarchies(project.id, vm.serializedExportedData).then(
			importResult => {
				if(importResult.hasSucceeded
						&& (!importResult.warnings || importResult.warnings.length === 0)) {
					notification.success("Imported model hierarchies");
					$state.go("^", {}, { reload: true });
				} else {
					if(importResult.hasSucceeded) {
						notification.success("Imported model hierarchies, with warnings");
					}
					vm.importResult = importResult;
				}
			}
		);
	}

	function isInputValid() {
		return Utilities.isJsonObject(vm.serializedExportedData);
	}
}
