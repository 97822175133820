angular.module("eShareApp")
	.directive("documentLinkColorConfiguration", documentLinkColorConfigurationDirective);

documentLinkColorConfigurationDirective.$inject = [];

function documentLinkColorConfigurationDirective() {
	return {
		restrict: "E",
		controller: documentLinkColorConfigurationController,
		controllerAs: "vm",
		bindToController: {
			colorOptions: "=",
		},
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\documentLinkColorConfiguration.html'),
		replace: true,
	};
}

documentLinkColorConfigurationController.$inject = [
	"$stateParams", "statusTrackingKindRepository", "attributeCategorizationRepository",
	"dataSourceRepository", "pointOfInterestKindRepository"
];

function documentLinkColorConfigurationController(
	$stateParams, statusTrackingKindRepository, attributeCategorizationRepository,
	dataSourceRepository, pointOfInterestKindRepository
) {
	const vm = this;

	vm.unUsedStatusTrackers = [];
	vm.usedStatusTrackers = [];
	vm.unUsedCategorizations = [];
	vm.usedCategorizations = [];
	vm.unUsedDataSourceCategorizations = [];
	vm.usedDataSourceCategorizations = [];
	vm.unUsedPointOfInterestKinds = [];
	vm.usedPointOfInterestKinds = [];

	statusTrackingKindRepository.getAll($stateParams.projectId).then(data => {
		vm.statusTrackingKinds = data || [];
		vm.unUsedStatusTrackers = _.cloneDeep(vm.statusTrackingKinds);
		vm.usedStatusTrackers = [];

		attributeCategorizationRepository.get($stateParams.projectId).then(data => {
			vm.categorizations = data;
			vm.unUsedCategorizations = _.cloneDeep(vm.categorizations);
			vm.usedCategorizations = [];

			dataSourceRepository.getCategorizationDataSourceInfo($stateParams.projectId).then(data => {
				vm.dataSourceCategorizations = data;
				vm.unUsedDataSourceCategorizations = _.cloneDeep(vm.dataSourceCategorizations);
				vm.usedDataSourceCategorizations = [];

				pointOfInterestKindRepository.getAll($stateParams.projectId).then(data => {
					vm.pointOfInterestKinds = _.filter(data, poi => {
						return !poi.isMarkup;
					});
					vm.unUsedPointOfInterestKinds = _.cloneDeep(vm.pointOfInterestKinds);
					vm.usedPointOfInterestKinds = [];

					initialize();
				});
			});
		});
	});

	function initialize() {
		_.forEach(vm.colorOptions.linkColoringConfigurations, configuration => {
			vm.unUsedStatusTrackers[configuration.id] = _.cloneDeep(vm.statusTrackingKinds);
			vm.usedStatusTrackers[configuration.id] = [];

			vm.unUsedCategorizations[configuration.id] = _.cloneDeep(vm.categorizations);
			vm.usedCategorizations[configuration.id] = [];

			vm.unUsedDataSourceCategorizations[configuration.id] =
				_.cloneDeep(vm.dataSourceCategorizations);
			vm.usedDataSourceCategorizations[configuration.id] = [];

			vm.unUsedPointOfInterestKinds[configuration.id] = _.cloneDeep(vm.pointOfInterestKinds);
			vm.usedPointOfInterestKinds[configuration.id] = [];

			switch(configuration.colorLinkMode) {
			case 2:
				for(let i = 0; i < configuration.linkTargetIds.length; i++) {
					for(let j = 0; j < vm.unUsedStatusTrackers[configuration.id].length; j++) {
						const st = vm.unUsedStatusTrackers[configuration.id][j];
						if(st.name === configuration.linkTargetIds[i]) {
							vm.usedStatusTrackers[configuration.id].push(st);
							vm.unUsedStatusTrackers[configuration.id].splice(j, 1);
							break;
						}
					}
				}
				break;
			case 3:
				for(let i = 0; i < configuration.linkTargetIds.length; i++) {
					for(let j = 0; j < vm.unUsedCategorizations[configuration.id].length; j++) {
						const categorization = vm.unUsedCategorizations[configuration.id][j];
						if(categorization.name === configuration.linkTargetIds[i]) {
							vm.usedCategorizations[configuration.id].push(categorization);
							vm.unUsedCategorizations[configuration.id].splice(j, 1);
							break;
						}
					}
				}
				break;
			case 4:
				for(let i = 0; i < configuration.linkTargetIds.length; i++) {
					const unUsedDSCategorizations = vm.unUsedDataSourceCategorizations[configuration.id];
					for(let j = 0; j < unUsedDSCategorizations.length; j++) {
						const categorization = unUsedDSCategorizations[j];
						const categorizationIdentifier = categorization.adapterName + ":::::"
							+ categorization.dataSourceName + ":::::"
							+ categorization.typeGuid;
						if(categorizationIdentifier === configuration.linkTargetIds[i]) {
							vm.usedDataSourceCategorizations[configuration.id]
								.push(unUsedDSCategorizations[j]);
							vm.unUsedDataSourceCategorizations[configuration.id]
								.splice(j, 1);
							break;
						}
					}
				}
				break;
			case 5:
				for(let i = 0; i < configuration.linkTargetIds.length; i++) {
					for(let j = 0; j < vm.unUsedPointOfInterestKinds[configuration.id].length; j++) {
						const poik = vm.unUsedPointOfInterestKinds[configuration.id][j];
						if(poik.name === configuration.linkTargetIds[i]) {
							vm.usedPointOfInterestKinds[configuration.id].push(poik);
							vm.unUsedPointOfInterestKinds[configuration.id].splice(j, 1);
							break;
						}
					}
				}
				break;
			}

		});

	}

	vm.colorLinkModeToString = function (index) {
		switch(index) {
		case 1:
			return "Model";
		case 2:
			return "Status Tracking";
		case 3:
			return "Model Attribute Categorization";
		case 4:
			return "Data Source Categorization";
		case 5:
			return "Smart Point";
		default:
			return "None";
		}
	};

	vm.changeColorLinkMode = function (configuration, modeNumber) {
		configuration.colorLinkMode = modeNumber;
		configuration.linkTargetIds = [];
		switch(modeNumber) {
		case 2:
			vm.usedStatusTrackers[configuration.id].forEach(statusTracker => {
				configuration.linkTargetIds.push(statusTracker.name);
			});
			break;
		case 3:
			vm.usedCategorizations[configuration.id].forEach(categorization => {
				configuration.linkTargetIds.push(categorization.name);
			});
			break;
		case 4:
			vm.usedDataSourceCategorizations[configuration.id].forEach(categorization => {
				const categorizationIdentifier = categorization.getAdapterTypeName + ":::::"
					+ categorization.dataSourceName + ":::::"
					+ categorization.typeGuid;
				configuration.linkTargetIds.push(categorizationIdentifier);
			});
			break;
		case 5:
			vm.usedPointOfInterestKinds[configuration.id].forEach(pointOfInterestKind => {
				configuration.linkTargetIds.push(pointOfInterestKind.name);
			});
			break;
		}
	};

	vm.addStatusTracker = function (configuration, statusTracker) {
		configuration.linkTargetIds.push(statusTracker.name);
		vm.usedStatusTrackers[configuration.id].push(statusTracker);
		for(let i = 0; i < vm.unUsedStatusTrackers[configuration.id].length; i++) {
			if(vm.unUsedStatusTrackers[configuration.id][i] === statusTracker) {
				vm.unUsedStatusTrackers[configuration.id].splice(i, 1);
			}
		}
	};

	vm.removeStatusTracker = function (configuration, statusTracker, index) {
		configuration.linkTargetIds.splice(index, 1);
		vm.usedStatusTrackers[configuration.id].splice(index, 1);
		vm.unUsedStatusTrackers[configuration.id].push(statusTracker);
		vm.unUsedStatusTrackers[configuration.id].sort((a, b) => {
			if(a.name < b.name) {
				return -1;
			}
			if(a.name > b.name) {
				return 1;
			}
			return 0;
		});
	};

	vm.addCategorization = function (configuration, categorization) {
		configuration.linkTargetIds.push(categorization.name);
		vm.usedCategorizations[configuration.id].push(categorization);
		for(let i = 0; i < vm.unUsedCategorizations[configuration.id].length; i++) {
			if(vm.unUsedCategorizations[configuration.id][i] === categorization) {
				vm.unUsedCategorizations[configuration.id].splice(i, 1);
			}
		}
	};

	vm.removeCategorization = function (configuration, categorization, index) {
		configuration.linkTargetIds.splice(index, 1);
		vm.usedCategorizations[configuration.id].splice(index, 1);
		vm.unUsedCategorizations[configuration.id].push(categorization);
		vm.unUsedCategorizations[configuration.id].sort((a, b) => {
			if(a.name < b.name) {
				return -1;
			}
			if(a.name > b.name) {
				return 1;
			}
			return 0;
		});
	};

	vm.addDataSourceCategorization = function (configuration, categorization) {
		const categorizationIdentifier = categorization.adapterName + ":::::"
			+ categorization.dataSourceName + ":::::"
			+ categorization.typeGuid;
		configuration.linkTargetIds.push(categorizationIdentifier);
		vm.usedDataSourceCategorizations[configuration.id].push(categorization);
		for(let i = 0; i < vm.unUsedDataSourceCategorizations[configuration.id].length; i++) {
			if(vm.unUsedDataSourceCategorizations[configuration.id][i] === categorization) {
				vm.unUsedDataSourceCategorizations[configuration.id].splice(i, 1);
			}
		}
	};

	vm.removeDataSourceCategorization = function (configuration, categorization, index) {
		configuration.linkTargetIds.splice(index, 1);
		vm.usedDataSourceCategorizations[configuration.id].splice(index, 1);
		vm.unUsedDataSourceCategorizations[configuration.id].push(categorization);
		vm.unUsedDataSourceCategorizations[configuration.id].sort((a, b) => {
			if(a.name < b.name) {
				return -1;
			}
			if(a.name > b.name) {
				return 1;
			}
			return 0;
		});
	};

	vm.addPoiKind = function (configuration, poi) {
		configuration.linkTargetIds.push(poi.name);
		vm.usedPointOfInterestKinds[configuration.id].push(poi);
		for(let i = 0; i < vm.unUsedPointOfInterestKinds[configuration.id].length; i++) {
			if(vm.unUsedPointOfInterestKinds[configuration.id][i] === poi) {
				vm.unUsedPointOfInterestKinds[configuration.id].splice(i, 1);
			}
		}
	};

	vm.removePoiKind = function (configuration, poi, index) {
		configuration.linkTargetIds.splice(index, 1);
		vm.usedPointOfInterestKinds[configuration.id].splice(index, 1);
		vm.unUsedPointOfInterestKinds[configuration.id].push(poi);
		vm.unUsedPointOfInterestKinds[configuration.id].sort((a, b) => {
			if(a.name < b.name) {
				return -1;
			}
			if(a.name > b.name) {
				return 1;
			}
			return 0;
		});
	};

	vm.addNewColorConfiguration = function () {
		const newConfig = {
			id: Utilities.getFakeGuid(),
			displayName: "New Link Coloring Configuration",
			showLegend: false,
			colorLinkMode: 1,
			linkTargetIds: [],
			colorLinkDefaultColors: ["#00FF00", "#FF0000", "#FFFF00"],
		};

		vm.unUsedStatusTrackers[newConfig.id] = _.cloneDeep(vm.statusTrackingKinds);
		vm.usedStatusTrackers[newConfig.id] = [];

		vm.unUsedCategorizations[newConfig.id] = _.cloneDeep(vm.categorizations);
		vm.usedCategorizations[newConfig.id] = [];

		vm.unUsedDataSourceCategorizations[newConfig.id] = _.cloneDeep(vm.dataSourceCategorizations);
		vm.usedDataSourceCategorizations[newConfig.id] = [];

		vm.unUsedPointOfInterestKinds[newConfig.id] = _.cloneDeep(vm.pointOfInterestKinds);
		vm.usedPointOfInterestKinds[newConfig.id] = [];

		vm.colorOptions.linkColoringConfigurations.push(newConfig);
	};

	vm.removeColorConfiguration = function (index) {
		vm.colorOptions.linkColoringConfigurations.splice(index, 1);
	};
}