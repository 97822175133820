angular.module("eShareApp").directive("cadTypeahead", [
	"searchService", "$timeout",
	function (searchService, $timeout) {

		function link(scope, element /*, attrs, controller*/) {
			const inputBox = element[0].children[0];
			const dropdown = element[0].children[1].children[0];

			let isFocused = false;
			let isMouseInList = false;
			let timeoutRequest = null;

			reset();

			function reset() {
				scope.values = [];
				scope.isTruncated = false;
				scope.hasError = false;
				scope.hasNoMatches = false;
				scope.currentIndex = -1;
				scope.requestPending = false;
			}

			function retrieveValues() {
				reset();
				if(scope.skipTypeahead || !isFocused) {
					return;
				}
				if(timeoutRequest) {
					$timeout.cancel(timeoutRequest);
				}
				timeoutRequest = $timeout(() => {
					timeoutRequest = null;
					if(!isFocused) {
						reset();
						return;
					}
					let text = scope.ngModel;
					if(isAttributeCoordinate(scope.attributeTag) && scope.targetCoordinateId) {
						text += ":" + scope.targetCoordinateId;
					}
					scope.requestPending = true;
					const kindId = scope.kindId ? parseInt(scope.kindId, 10) : 0;
					searchService.getCompletion({
						projectId: scope.projectId,
						isAdvanced: scope.isAdvanced,
						attributeTag: scope.attributeTag,
						text: text,
						searchTarget: scope.searchTarget,
						kindId: kindId,
					}).then(
						data => {
							if(!data) {
								return;
							}
							if(!scope.requestPending || !isFocused) {
								reset();
								return;
							}
							scope.hasError = false;
							scope.requestPending = false;
							if(Utilities.isNullOrUndefined(data)) {
								scope.values = [];
							} else {
								scope.values = data.values || [];
								scope.isTruncated = data.isTruncated;
							}
							scope.hasNoMatches = scope.values.length === 0;
						},
						(/*error*/) => {
							reset();
							scope.hasError = scope.requestPending && isFocused;
						}
					);
				}, 500);
			}

			function ensureCurrentItemIsInView(alignWithTop) {
				const currentItem = dropdown.children[scope.currentIndex];
				const itemTopPosition = currentItem.offsetTop + currentItem.clientHeight;
				const dropdownTopPosition = dropdown.scrollTop + dropdown.clientHeight;
				const isInView = currentItem.offsetTop >= dropdown.scrollTop
					&& itemTopPosition <= dropdownTopPosition;
				if(!isInView) {
					dropdown.scrollTop =
						alignWithTop
							? currentItem.offsetTop
							: currentItem.offsetTop - (dropdown.clientHeight - currentItem.clientHeight);
				}
			}

			scope.onChange = function () {
				isFocused = true;
				retrieveValues();
			};

			scope.valueClicked = function (value) {
				scope.ngModel = value;
				reset();
			};

			scope.onBlur = function () {
				if(isMouseInList) {
					$timeout(() => {
						inputBox.focus();
						isMouseInList = false;
					}, 50);
				} else {
					isFocused = false;
					reset();
				}
			};

			scope.onMouseEnterList = function () {
				isMouseInList = true;
			};

			scope.onMouseLeaveList = function () {
				isMouseInList = false;
			};

			scope.onKeyDown = function (event) {
				switch(event.keyCode) {
				case 27: // ESC
					if(scope.values && scope.values.length > 0) {
						reset();
						event.preventDefault();
					}
					break;
				case 33: // PgUp
					if(scope.values && scope.values.length > 0 && scope.currentIndex > 0) {
						scope.currentIndex = Math.max(scope.currentIndex - 10, 0);
						ensureCurrentItemIsInView(true);
					} else {
						scope.currentIndex = -1;
					}
					event.preventDefault();
					break;
				case 34: // PgDn
					if(scope.values
						&& scope.values.length > 0
						&& scope.currentIndex < scope.values.length - 1) {
						if(scope.currentIndex === -1) {
							scope.currentIndex = 0;
						} else {
							scope.currentIndex = Math.min(
								scope.currentIndex + 10,
								scope.values.length - 1
							);
						}
						ensureCurrentItemIsInView(false);
					}
					event.preventDefault();
					break;
				case 38: // Up
					if(scope.values && scope.values.length > 0 && scope.currentIndex > 0) {
						--scope.currentIndex;
						ensureCurrentItemIsInView(true);
					} else {
						scope.currentIndex = -1;
					}
					event.preventDefault();
					break;
				case 40: // Down
					if(!scope.values || scope.values.length === 0) {
						isFocused = true;
						retrieveValues();
					} else if(scope.values
						&& scope.values.length > 0
						&& scope.currentIndex < scope.values.length - 1) {
						++scope.currentIndex;
						ensureCurrentItemIsInView(false);
					}
					event.preventDefault();
					break;
				case 13: // Enter
					if(scope.values && scope.values.length > 0) {
						if(scope.currentIndex >= 0 && scope.currentIndex < scope.values.length) {
							scope.ngModel = scope.values[scope.currentIndex];
						}
						reset();
						event.preventDefault();
					}
					break;
				case 9: // Tab
					reset();
					break;
				}
			};
		}

		function isAttributeCoordinate(tag) {
			return tag === "((x))"
				|| tag === "((y))"
				|| tag === "((z))"
				|| tag === "((zMap))"
				|| tag === "((cameraX))"
				|| tag === "((cameraY))"
				|| tag === "((cameraZ))";
		}

		return {
			restrict: "A",
			scope: {
				projectId: "=",
				isAdvanced: "=",
				attributeTag: "=",
				ngModel: "=",
				searchTarget: "=",
				skipTypeahead: "=",
				targetCoordinateId: "=",
				kindId: "=",
			},
			replace: true,
			template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadTypeahead.html'),
			link: link,
		};
	}
]);