angular.module("eShareApp").controller("PointOfInterestKindEditCtrl", [
	"$scope", "$state", "$stateParams", "pointOfInterestKind", "pointOfInterestKinds", "messageBox",
	"pointOfInterestKindRepository", "groups", "icons", "attributeKinds", "pointOfInterestColors",
	"colorService", "dirtyFormTrackingService", "pointKindInfo",

	function (
		$scope, $state, $stateParams, pointOfInterestKind, pointOfInterestKinds, messageBox,
		pointOfInterestKindRepository, groups, icons, attributeKinds, pointOfInterestColors,
		colorService, dirtyFormTrackingService, pointKindInfo
	) {

		$scope.isNew = pointOfInterestKind === null;
		$scope.originalName = "";
		$scope.icons = icons;
		$scope.attributeKinds = _.cloneDeep(attributeKinds);
		$scope.markupAttrs = [];
		$scope.poiAttrs = [];
		$scope.groups = groups;

		if($scope.isNew) {
			$scope.pointOfInterestKind = {
				id: null,
				name: "",
				color: colorService.getNextColor(pointOfInterestColors),
				icon: icons[0].name,
				hasExternalId: false,
				attributeKinds: [],
				pointCount: 0,
				isModificationHistoryDisplayed: !pointKindInfo.isMarkup,
				isMarkup: pointKindInfo.isMarkup,
				isPersonal: false,
				IsReferencingGuid: false,
			};
			if($scope.pointOfInterestKind.isMarkup) {
				$scope.markupAttrs = _.filter($scope.attributeKinds, { "isForMarkup": true });
			}
		} else {
			$scope.pointOfInterestKind = _.cloneDeep(pointOfInterestKind);
			$scope.markupAttrs = _.filter($scope.pointOfInterestKind.attributeKinds, {
				"isForMarkup": true,
			});
			$scope.poiAttrs = _.filter($scope.pointOfInterestKind.attributeKinds, {
				"isForMarkup": false,
			});
			$scope.pointOfInterestKind.color = $scope.pointOfInterestKind.color.trim();
			$scope.originalName = $scope.pointOfInterestKind.name;
			_.forEach($scope.groups, group => {
				group.hasPermissionRead = $scope.pointOfInterestKind.hasPermissionReadGroupIds
					&& $scope.pointOfInterestKind.hasPermissionReadGroupIds.indexOf(group.id) > -1;
				group.hasPermissionCreate = $scope.pointOfInterestKind.hasPermissionCreateGroupIds
					&& $scope.pointOfInterestKind.hasPermissionCreateGroupIds.indexOf(group.id) > -1;
				group.hasPermissionUpdate = $scope.pointOfInterestKind.hasPermissionUpdateGroupIds
					&& $scope.pointOfInterestKind.hasPermissionUpdateGroupIds.indexOf(group.id) > -1;
				group.hasPermissionDelete = $scope.pointOfInterestKind.hasPermissionDeleteGroupIds
					&& $scope.pointOfInterestKind.hasPermissionDeleteGroupIds.indexOf(group.id) > -1;
			});
		}
		$scope.usedNames = _.filter(_.map(pointOfInterestKinds, p => {
			return p.name;
		}), n => {
			return n !== $scope.originalName;
		});

		filterAttributeKinds();

		$scope.hasAllowedGroups = function () {
			return _.some($scope.groups, group => {
				return group.hasPermissionRead
					|| group.hasPermissionCreate
					|| group.hasPermissionUpdate
					|| group.hasPermissionDelete;
			});
		};

		$scope.isNameChanged = function () {
			return !$scope.isNew && $scope.originalName !== $scope.pointOfInterestKind.name;
		};

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		$scope.editCommit = function () {
			$scope.pointOfInterestKind.attributeKinds = $scope.markupAttrs.concat($scope.poiAttrs);
			$scope.pointOfInterestKind.color = $scope.pointOfInterestKind.color.trim();
			let updateFunction;
			if($scope.isNew) {
				updateFunction = pointOfInterestKindRepository.add;
			} else {
				updateFunction = pointOfInterestKindRepository.update;
			}

			$scope.pointOfInterestKind.hasPermissionReadGroupIds =
				getGroupIdsForPermission(group =>group.hasPermissionRead);
			$scope.pointOfInterestKind.hasPermissionCreateGroupIds =
				getGroupIdsForPermission(group => group.hasPermissionCreate);
			$scope.pointOfInterestKind.hasPermissionUpdateGroupIds =
				getGroupIdsForPermission(group => group.hasPermissionUpdate);
			$scope.pointOfInterestKind.hasPermissionDeleteGroupIds =
				getGroupIdsForPermission(group => group.hasPermissionDelete);

			const promise = updateFunction($stateParams.projectId, $scope.pointOfInterestKind);
			promise.then(
				() => {
					$scope.formScope.form && $scope.formScope.form.$setPristine();
					goBack();
				},
				message => {
					messageBox.openError(
						"Error creating / modifying smart point kind",
						"Error creating / modifying smart point kind: " + message
					);
				}
			);
		};

		$scope.isValid = function () {
			return $scope.pointOfInterestKind
				&& $scope.pointOfInterestKind.name
				&& !_.some($scope.usedNames, n => {
					return n.toLowerCase() === $scope.pointOfInterestKind.name.toLowerCase();
				});
		};

		$scope.addAttributeKind = function (kind) {
			if(!kind) {
				return;
			}
			const newKind = _.cloneDeep(kind);
			newKind.added = true;
			$scope.poiAttrs.push(newKind);
			$scope.formScope.form.$setDirty();
			filterAttributeKinds();
		};

		$scope.isNotFirst = function (attributeKind) {
			let firstAttributeKindNotForMarkup = null;
			for(let i = 0; i < $scope.pointOfInterestKind.attributeKinds.length; i++) {
				if(!$scope.pointOfInterestKind.attributeKinds[i].isForMarkup) {
					firstAttributeKindNotForMarkup = $scope.pointOfInterestKind.attributeKinds[i];
					break;
				}
			}
			return (firstAttributeKindNotForMarkup !== null
				&& attributeKind !== firstAttributeKindNotForMarkup);
		};

		$scope.isNotLast = function (attributeKind) {
			return attributeKind !== $scope.pointOfInterestKind.attributeKinds[
				$scope.pointOfInterestKind.attributeKinds.length - 1
			];
		};

		$scope.attributeKindUp = function (attributeKind) {
			const index = $scope.pointOfInterestKind.attributeKinds.indexOf(attributeKind);
			$scope.pointOfInterestKind.attributeKinds[index] =
				$scope.pointOfInterestKind.attributeKinds[index - 1];
			$scope.pointOfInterestKind.attributeKinds[index - 1] = attributeKind;
			$scope.formScope.form.$setDirty();
		};

		$scope.attributeKindDown = function (attributeKind) {
			const index = $scope.pointOfInterestKind.attributeKinds.indexOf(attributeKind);
			$scope.pointOfInterestKind.attributeKinds[index] =
				$scope.pointOfInterestKind.attributeKinds[index + 1];
			$scope.pointOfInterestKind.attributeKinds[index + 1] = attributeKind;
			$scope.formScope.form.$setDirty();
		};

		$scope.removeAttributeKind = function (attributeKind) {
			const index = $scope.poiAttrs.indexOf(attributeKind);
			$scope.poiAttrs.splice(index, 1);
			$scope.attributeKinds.length = 0;
			for(let i = 0; i < attributeKinds.length; i++) {
				$scope.attributeKinds.push(_.cloneDeep(attributeKinds[i]));
			}
			$scope.formScope.form.$setDirty();
			filterAttributeKinds();
		};

		function getGroupIdsForPermission(permissionSelector) {
			return _.map(_.filter($scope.groups, group => {
				return permissionSelector(group);
			}), group => {
				return group.id;
			});
		}

		function filterAttributeKinds() {
			const attributes = $scope.poiAttrs.concat($scope.markupAttrs);
			_.forEach(attributes, kind => {
				let index = -1;
				for(let i = 0; i < $scope.attributeKinds.length; i++) {
					if($scope.attributeKinds[i].id == kind.id) {
						index = i;
						break;
					}
				}
				if(index > -1) {
					$scope.attributeKinds.splice(index, 1);
				}
			});
			if(!$scope.pointOfInterestKind.isMarkup) {
				$scope.attributeKinds = _.filter($scope.attributeKinds, { "isForMarkup": false });
			}
		}

		function goBack() {
			$state.go("^", {}, { reload: true });
		}

	}
]);
