angular
	.module("eShareApp")
	.controller("StatusTrackingExportImportCtrl", StatusTrackingExportImportCtrl);

StatusTrackingExportImportCtrl.$inject = [
	"$state", "notification", "isExport", "isImport", "project", "statusTrackingKindRepository"
];

function StatusTrackingExportImportCtrl(
	$state, notification, isExport, isImport, project, statusTrackingKindRepository
) {
	const vm = this;
	vm.isExport = isExport;
	vm.isImport = isImport;
	vm.serializedExportedData = "";
	vm.importResult = null;
	vm.importAll = importAll;
	vm.isInputValid = isInputValid;

	initialize();

	function initialize() {
		if(isExport) {
			vm.serializedExportedData = "Please wait...";
			statusTrackingKindRepository.exportAll(project.id).then(
				serializedExportedData => {
					vm.serializedExportedData = serializedExportedData;
				}
			);
		}
	}

	function importAll() {
		vm.importResult = null;
		statusTrackingKindRepository.importAll(project.id, vm.serializedExportedData).then(
			importResult => {
				if(importResult.hasSucceeded
						&& (!importResult.warnings || importResult.warnings.length === 0)) {
					notification.success("Imported status tracking");
					$state.go("^", {}, { reload: true });
				} else {
					if(importResult.hasSucceeded) {
						notification.success("Imported status tracking, with warnings");
					}
					vm.importResult = importResult;
				}
			}
		);
	}

	function isInputValid() {
		return Utilities.isJsonObject(vm.serializedExportedData);
	}
}
