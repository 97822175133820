angular.module("eShareApp").directive("cadImportResult", cadImportResult);

cadImportResult.$inject = [];

function cadImportResult() {
	return {
		restrict: "E",
		controller: CadImportResultController,
		controllerAs: "vm",
		bindToController: {
			importResult: "=",
			validationErrorsMessage: "@",
			errorsMessage: "@",
			warningsMessage: "@",
			successMessage: "@",
		},
		scope: {},
		template: require('C:\\Cadmatic\\W1\\e23b380dbb3074c0\\EShare\\WebSite\\ClientApp\\app\\templates\\cadImportResult.html'),
		replace: true,
	};
}

CadImportResultController.$inject = [];

function CadImportResultController() {
	// eslint-disable-next-line no-unused-vars
	const vm = this;
}