angular.module("eShareApp").controller("ModelImporterEditCtrl", ModelImporterEditCtrl);

ModelImporterEditCtrl.$inject = [
	"$state", "$scope", "$timeout", "importer", "project", "messageBox", "$compile",
	"modelImportersRepository", "importerConfigurationScript", "importerConfigurationTemplate",
	"modelImporterTypes", "dirtyFormTrackingService"
];

function ModelImporterEditCtrl(
	$state, $scope, $timeout, importer, project, messageBox, $compile,
	modelImportersRepository, importerConfigurationScript, importerConfigurationTemplate,
	modelImporterTypes, dirtyFormTrackingService
) {

	let isCommitting = false;
	$scope.messageBox = messageBox;

	$scope.importer = _.cloneDeep(importer);
	$scope.project = _.cloneDeep(project);
	$scope.importerConfig = $scope.importer.configurationParameters;

	$scope.modelImporterType = getModelImporterType(importer.typeGuid);

	$scope.setFormScope = setFormScope;
	$scope.isSaveEnabled = isSaveEnabled;
	$scope.editCommit = editCommit;
	$scope.editRollback = editRollback;

	$scope.isEbmImporter =
		importer.typeGuid.toLowerCase() === "fbbac88c-a9bf-4540-a5e4-d74d0b89cc9a";

	$scope.rotationAxes = [
		{
			displayName: "+X",
			value: "+X",
		}, {
			displayName: "-X",
			value: "-X",
		}, {
			displayName: "+Y",
			value: "+Y",
		}, {
			displayName: "-Y",
			value: "-Y",
		}, {
			displayName: "+Z",
			value: "+Z",
		}, {
			displayName: "-Z",
			value: "-Z",
		}, {
			displayName: "Custom",
			value: "CUSTOM",
		}
	];
	const illegalNames = [
		"CON", "PRN", "AUX", "NUL", "COM1", "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8",
		"COM9", "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", "LPT9"
	];
	const legalCharacterRegex = /^[a-zA-Z0-9 _()-]+$/;

	function getModelImporterType(importerTypeGuid) {
		return _.find(modelImporterTypes, at => {
			return at.typeGuid === importerTypeGuid;
		});
	}

	function Mapping() {
		this.fromAttribute = "";
		this.option = $scope.renameOptions[0];
		this.toAttribute = "";
	}

	$scope.renameOptions = ["Rename", "Delete"];

	$scope.settings = {
		suffix: "",
		renameAll: false,
		attributeMappings: [],
	};

	$scope.hasCustomConfiguration = false;

	function initialize() {
		const imp = $scope.importer;
		const defaultAttributeMappings = [];
		let attributeMappings;
		try {
			attributeMappings = JSON.parse(imp.tagMappings);
			if(!Utilities.isArray(attributeMappings) || attributeMappings.length === 0) {
				attributeMappings = defaultAttributeMappings;
			}
		} catch(e) {
			attributeMappings = defaultAttributeMappings;
		}
		const defaultTransform = {
			rotationAngle: 0.0,
			rotationAxis: [0.0, 0.0, 1.0],
			rotationOrigin: [0.0, 0.0, 0.0],
			scale: 1.0,
			scaleOrigin: [0.0, 0.0, 0.0],
			translate: [0.0, 0.0, 0.0],
		};
		let transform;
		try {
			transform = JSON.parse(imp.transform);
			if(!Utilities.isObject(transform)) {
				transform = defaultTransform;
			}
		} catch(e) {
			transform = defaultTransform;
		}
		$scope.settings.transform = transform;
		$scope.settings.rotationAxis = coordinatesToRotationAxis(
			transform.rotationAxis[0], transform.rotationAxis[1], transform.rotationAxis[2]
		);
		const renameAll = attributeMappings.length === 1
			&& attributeMappings[0].fromAttribute === "*";
		$scope.settings.renameAll = renameAll;
		if(renameAll) {
			$scope.settings.suffix = attributeMappings[0].toAttribute;
		} else {
			$scope.settings.attributeMappings = attributeMappings;
			_.forEach($scope.settings.attributeMappings, mapping => {
				if(mapping.toAttribute === "") {
					mapping.option = $scope.renameOptions[1];
				} else {
					mapping.option = $scope.renameOptions[0];
				}
			});
		}
		$scope.isImporterInEditMode = $state.current.name === "project.admin.model.importer.edit";
		$timeout(() => {
			$scope.hasCustomConfiguration = !!(
				importerConfigurationScript || importerConfigurationTemplate
			);
			$scope.importerScript = importerConfigurationScript
				? eval(importerConfigurationScript)($scope)
				: {};
			const importerConfigurationArea = $("#importerConfigurationArea");
			importerConfigurationArea.html(
				importerConfigurationTemplate
					|| "<i>There are no other configuration options</i>"
			);
			$compile(importerConfigurationArea.contents())($scope);
		}, 0);
		if($state.params.isNewImporter) {
			$scope.importer.name = "";
			$timeout(() => {
				$scope.validateImporterName();
				document.getElementById("name").focus();
			});
		}
	}

	function coordinatesToRotationAxis(x, y, z) {
		if(x === 0) {
			if(y === 0) {
				if(z === 0) {
					return "CUSTOM";
				}
				return z > 0 ? "+Z" : "-Z";
			} else {
				if(z === 0) {
					return y > 0 ? "+Y" : "-Y";
				}
			}
		} else {
			if(y === 0 && z === 0) {
				return x > 0 ? "+X" : "-X";
			}
		}
		return "CUSTOM";
	}

	function rotationAxisToCoordinates(rotationAxis, coordinates) {
		if(rotationAxis === "CUSTOM") {
			return;
		}
		let x = 0;
		let y = 0;
		let z = 0;
		switch(rotationAxis) {
		case "+X":
			x = 1;
			break;
		case "-X":
			x = -1;
			break;
		case "+Y":
			y = 1;
			break;
		case "-Y":
			y = -1;
			break;
		case "+Z":
			z = 1;
			break;
		case "-Z":
			z = -1;
			break;
		default:
			return;
		}
		coordinates[0] = x;
		coordinates[1] = y;
		coordinates[2] = z;
	}

	$scope.onRotationAxisChanged = function onRotationAxisChanged() {
		const rotationAxis = $scope.settings.rotationAxis;
		rotationAxisToCoordinates(rotationAxis, $scope.settings.transform.rotationAxis);
	};

	$scope.rotationAxisToDisplayName = function rotationAxisToDisplayName(value) {
		const rotationAxis = value || $scope.settings.rotationAxis;
		const ra = _.find($scope.rotationAxes, { value: rotationAxis });
		return ra ? ra.displayName : "Custom";
	};

	$scope.addAttributeMapping = function () {
		$scope.settings.attributeMappings.push(new Mapping());
		const element = document.getElementById("fromInput");
		if(element) {
			element.focus();
		}
	};

	$scope.removeAttributeMapping = function (index) {
		$scope.settings.attributeMappings.splice(index, 1);
	};

	$scope.isNewMappingValid = function () {
		const fromAttribute = $scope.newMapping.fromAttribute;
		const isFromAttributeEmpty = fromAttribute.trim() === "";
		const isWildcard = fromAttribute.trim() === "*";
		const isUsed = _.find($scope.importer.attributeMappings, attr => {
			return attr.fromAttribute === fromAttribute;
		}) != null;
		$scope.form.fromInput.$setValidity("unique", !isUsed && !isWildcard);
		return !isFromAttributeEmpty && !isUsed && !isWildcard;
	};

	$scope.$watchCollection("attributeMappings", () => {
		$scope.importer.attributeMappings = _.sortBy($scope.attributeMappings, ["fromAttribute"]);
	});

	$scope.verifyForm = function () {
		return $scope.verifyMappings && !$scope.form.$invalid;
	};

	$scope.verifyMappings = function () {
		let isValid = true;
		_.forEach($scope.settings.attributeMappings, mapping => {
			if($scope.settings.renameAll) {
				return;
			}
			isValid = isValid
				&& typeof mapping.fromAttribute === "string"
				&& mapping.fromAttribute.length > 0;
			if(mapping.option === $scope.renameOptions[0]) {
				isValid = isValid
					&& typeof mapping.toAttribute === "string"
					&& mapping.toAttribute.length > 0;
			}
		});
		return isValid;
	};

	$scope.validateImporterName = function () {
		const name = ($scope.importer.name && $scope.importer.name.trim().toUpperCase()) || "";
		const hasOnlyLegalCharacters = !!name.match(legalCharacterRegex);
		const isIllegalName = illegalNames.indexOf(name) !== -1;
		$scope.form.name.$setValidity("illegal", hasOnlyLegalCharacters && !isIllegalName);
	};

	$scope.validateScale = function () {
		const scale = $scope.settings.transform.scale;
		const isNumber = typeof scale === "number";
		const isZeroOrLess = scale <= 0;
		$scope.form.scale.$setValidity("illegal", isNumber && !isZeroOrLess);
	};

	$scope.validateRotation = function () {
		const hasNonZeroComponent = !!(
			$scope.settings.transform.rotationAxis[0]
			|| $scope.settings.transform.rotationAxis[1]
			|| $scope.settings.transform.rotationAxis[2]
		);
		$scope.form.rotationX.$setValidity("illegal", hasNonZeroComponent);
		$scope.form.rotationY.$setValidity("illegal", hasNonZeroComponent);
		$scope.form.rotationZ.$setValidity("illegal", hasNonZeroComponent);
	};

	// Change tracking (warn before navigating to a different page
	// if the user has changed something on the page):
	function setFormScope(formScope) {
		$scope.formScope = formScope;
		dirtyFormTrackingService.trackForm($scope.formScope, "form");
	}

	function isSaveEnabled() {
		try {
			const callback = $scope.importerScript.isSaveEnabled;
			const result = Utilities.invokeCallbackInContext(this, callback);
			return typeof result === "undefined" || Utilities.toBool(result);
		} catch(e) {
			return true;
		}
	}

	function editCommit() {
		if(isCommitting) {
			return;
		}
		isCommitting = true;
		try {
			try {
				Utilities.invokeCallbackInContext(this, $scope.importerScript.beforeCommit);
			} catch(e) {
				// do nothing
			}
			if($scope.settings.renameAll) {
				$scope.settings.attributeMappings = [
					{
						fromAttribute: "*",
						toAttribute: $scope.settings.suffix || "",
					}
				];
			} else {
				_.forEach($scope.settings.attributeMappings, mapping => {
					if(mapping.option === $scope.renameOptions[1]) {
						mapping.toAttribute = "";
					}
					delete(mapping.option);
				});
			}
			$scope.importer.tagMappings = JSON.stringify($scope.settings.attributeMappings);
			$scope.importer.transform = JSON.stringify($scope.settings.transform);
			modelImportersRepository.update($scope.project.id, $scope.importer)
				.then(
					() => {
						$scope.formScope.form && $scope.formScope.form.$setPristine();
						$state
							.go("project.admin.model", { projectId: project.id }, { reload: true })
							.then(() => {
								isCommitting = false;
							});
					},
					message => {
						isCommitting = false;
						if(message.indexOf("Name must be unique") > -1) {
							messageBox.openError(
								"Error",
								"Importers must have unique names."
							);
						} else {
							messageBox.openError(
								"Error",
								"Error saving importer configuration: " + message
							);
						}
					}
				);
		} catch(e) {
			isCommitting = false;
		}
	}

	function editRollback() {
		$state.go("project.admin.model", { projectId: project.id }, { reload: true });
	}

	initialize();
}
