angular.module("eShareApp").controller("DocumentTypeEditCtrl", [
	"$state", "$scope", "$stateParams", "$interval", "documentConfigurationsRepository",
	"pdfDocumentConfiguration", "notification", "dirtyFormTrackingService",
	"pointOfInterestKindRepository", "documentDataSources", "$timeout",
	"attributeDefinitionRepository",

	function (
		$state, $scope, $stateParams, $interval, documentConfigurationsRepository,
		pdfDocumentConfiguration, notification, dirtyFormTrackingService,
		pointOfInterestKindRepository, documentDataSources, $timeout,
		attributeDefinitionRepository
	) {

		$scope.regexType = "Regex";
		$scope.modelType = "ModelObjects";
		$scope.poiType = "PointOfInterests";

		$scope.pdfConfiguration = pdfDocumentConfiguration || {};
		$scope.pdfConfiguration.documentTypeConfigurations =
			$scope.pdfConfiguration.documentTypeConfigurations || [];
		$scope.showAdvancedOptions = false;
		$scope.documentDataSources = documentDataSources || [];
		$scope.unUsedDocumentDataSources = _.cloneDeep($scope.documentDataSources);
		$scope.metadataOptions = [];
		$scope.projectId = $stateParams.projectId;

		$scope.exampleDocument = null;
		$scope.exampleDocumentViewerId = "DocConfigurationV";
		$scope.exampleDocumentDivId = "DocConfigurationD";
		$scope.exampleDocumentLoadControl = {};
		$scope.exampleDocumentViewerControl = {};
		$scope.docInfo = {};

		$scope.$watch("dsc.shouldExtractMetadata", toggleDocumentViewer);
		$scope.$watch("dsc.targetManagedDocuments", toggleDataSourceTargets);

		// Glossary:
		// - idRule = rule to match text in the document and make it into a link
		// - metadataRule = rule to process document metadata (from the Document Data Source)
		//   to extract "tokens" that can be used in the idRules
		// - token = text extracted from metadata _or_ document text using
		//   Named Capture Groups in a regex
		// - fragment = component of the object ID in an idRule; consists of either
		//   static text (type=string) or a token (type=token)

		// Extract the appropriate document type configuration or create a new one:
		let isNew;
		pointOfInterestKindRepository.getAll($stateParams.projectId).then(data => {
			$scope.pointOfInterestKinds = _.filter(data, poi => {
				return !poi.isMarkup && poi.hasExternalId;
			});

			const documentTypeIndex = $stateParams.documentTypeIndex;
			if(documentTypeIndex === "-1"
				|| $scope.pdfConfiguration.documentTypeConfigurations.length <= documentTypeIndex) {
				$scope.dsc = {
					idRules: [
						{
							// When a new document type is created, it should have automatic detection of
							// model objects as default
							ruleType: $scope.modelType,
							isActive: true, // New rule is enabled by default,
							name: "Automatic detection of model objects",
						},
						{
							// When a new document type is created, it should have automatic detection of
							// points of interest as default
							ruleType: $scope.poiType,
							isActive: true, // New rule is enabled by default
							name: "Automatic detection of smart points",
						}
					],
					metadataRules: [],
					metadataTokens: [],
					name: "",
					highlightLinks: true,
					typeKey: "",
					typeValue: "",
					conversionFormat: "Default Format",
					forcePaperSize: false,
					linkColoringConfigurations: [],
					maximumCombinedLineLength: null,
					lineItemSearchRange: null,
					shouldTargetDataSources: false,
					shouldTargetMetadata: true,
					documentMetadataExtractionRules: [],
				};
				const colorConfiguration = {
					id: Utilities.getFakeGuid(),
					displayName: "Default",
					colorLinkMode: 1,
					colorLinkDefaultColors: ["#00FF00", "#FF0000", "#FFFF00"],
					showLegend: false,
					linkTargetIds: [],
				};
				$scope.dsc.linkColoringConfigurations.push(colorConfiguration);
				isNew = true;
			} else {
				$scope.dsc = _.cloneDeep(
					$scope.pdfConfiguration.documentTypeConfigurations[$stateParams.documentTypeIndex]
				);
				if($scope.dsc.findFromModel !== undefined) { // Upgrade configuration if necessary
					delete $scope.dsc.findFromModel;
				}
				if($scope.dsc.forcePaperSize === undefined) { // Upgrade configuration if necessary
					$scope.dsc.forcePaperSize = false;
				}
				if($scope.dsc.conversionFormat === undefined) { // Upgrade configuration if necessary
					$scope.dsc.conversionFormat = "Default Format";
				}
				if($scope.dsc.lineItemSearchRange === undefined) {
					$scope.dsc.lineItemSearchRange = null;
				}
				if($scope.dsc.maximumCombinedLineLength === undefined) {
					$scope.dsc.maximumCombinedLineLength = null;
				}
				if($scope.dsc.documentMetadataExtractionRules === undefined
					|| $scope.dsc.documentMetadataExtractionRules === null) {
					$scope.dsc.documentMetadataExtractionRules = [];
				}

				if($scope.dsc.targetDataSources) {
					for(let i = 0; i < $scope.dsc.targetDataSources.length; i++) {
						const target = $scope.dsc.targetDataSources[i];
						const targetArray = _.split(target, ":::::");
						for(let j = 0; j < $scope.unUsedDocumentDataSources.length; j++) {
							const ds = $scope.unUsedDocumentDataSources[j];
							if(ds.name === targetArray[0]
								&& ds.adapter.name === targetArray[1]
								&& ds.typeGuid.toLowerCase() === targetArray[2].toLowerCase()) {
								$scope.unUsedDocumentDataSources.splice(j, 1);
								break;
							}
						}
					}
				}

				_.forEach($scope.dsc.metadataRules, metadataRule => {
					metadataRule.usePattern =
						metadataRule.pattern !== "(?<" + metadataRule.source + ">.*)";
				});
				_.forEach($scope.dsc.idRules, idRule => {
					if(idRule.ruleType === $scope.regexType) {
						if(idRule.abbreviation) {
							if(_.some($scope.pointOfInterestKinds, kind => {
								return idRule.abbreviation.indexOf(":::smartPointAbbreviation:::") > - 1
									&& kind.name === idRule.abbreviation.substring(28);
							})) {
								idRule.abbreviation = idRule.abbreviation.substring(28);
								idRule.targetType = "pointOfInterest";
							} else {
								idRule.targetType = "objectAttribute";
							}
						} else {
							idRule.targetType = "any";
						}
						// "upgrade step"
						idRule.format.forEach(part => {
							if(part.type === "token") {
								part.type = "attribute";
							}
							if(part.label != null) {
								part.displayName = part.label;
								part.abbreviation = part.label;
								delete (part.label);
							}
						});
					}
				});
				isNew = false;
			}
			// Initialization:
			$scope.updateAllMetadataTokens();
			documentConfigurationsRepository
				.getDocumentAttributesWithValues(
					$stateParams.projectId, "7C800013-66BB-40AC-979E-E131F0F1667B"
				)
				.then(result => {
					$scope.metadataDictionary = result;
					_.forOwn(result, (value, key) => {
						$scope.metadataOptions.push(key);
					});
				});
		});

		attributeDefinitionRepository.getDefinitions($stateParams.projectId)
			.then(data => {
				$scope.modelAttributes = data;
			});

		$scope.addTargetDataSource = function (dataSource) {
			const dataSourceName = dataSource.name;
			const adapterName = dataSource.adapter.name;
			const typeGuid = dataSource.typeGuid;
			const target = `${dataSourceName}:::::${adapterName}:::::${typeGuid}`;
			if(!$scope.dsc.targetDataSources) {
				$scope.dsc.targetDataSources = [];
			}
			$scope.dsc.targetDataSources.push(target);
			for(let j = 0; j < $scope.unUsedDocumentDataSources.length; j++) {
				const ds = $scope.unUsedDocumentDataSources[j];
				if(ds.name === dataSource.name
					&& ds.adapter.name === dataSource.adapter.name
					&& ds.typeGuid.toLowerCase() === dataSource.typeGuid.toLowerCase()) {
					$scope.unUsedDocumentDataSources.splice(j, 1);
					break;
				}
			}
		};

		$scope.removeTargetDataSource = function (index) {
			const targetArray = _.split($scope.dsc.targetDataSources[index], ":::::");
			for(let j = 0; j < $scope.documentDataSources.length; j++) {
				const ds = $scope.documentDataSources[j];
				if(ds.name === targetArray[0]
					&& ds.adapter.name === targetArray[1]
					&& ds.typeGuid.toLowerCase() === targetArray[2].toLowerCase()) {
					$scope.unUsedDocumentDataSources.push(ds);
					break;
				}
			}
			$scope.dsc.targetDataSources.splice(index, 1);
		};

		$scope.getTargetDataSourceString = function (targetDataSource) {
			if(targetDataSource.indexOf(":::::") === -1) {
				return null;
			}
			return targetDataSource.substring(0, targetDataSource.indexOf(":::::"));
		};

		$scope.getMetadataSuggestions = function (text) {
			return $timeout(() => {
				return { values: getSuggestions(text, $scope.metadataOptions) };
			});
		};

		function getSuggestions(text, list) {
			const inText = text.trim();
			let suggestions;
			if(text.length > 0) {
				suggestions = list.filter(item => {
					return item.toLowerCase().startsWith(inText.toLowerCase());
				});
				const additionalSuggestions = list.filter(item => {
					return item.toLowerCase().indexOf(" " + text.toLowerCase()) !== -1;
				});
				Array.prototype.push.apply(suggestions, additionalSuggestions);
			} else {
				suggestions = list;
			}
			suggestions = _.uniq(suggestions);
			return suggestions;
		}

		$scope.getValueSuggestions = function (text, typeKey) {
			let resultList = [];
			if($scope.metadataDictionary[typeKey] != null) {
				resultList = getSuggestions(text, $scope.metadataDictionary[typeKey]);
				resultList = _.map(resultList, str => {
					return str.replace(/[-|\\{}()[\]^$+*?.]/g, "\\$&"); // escape regex characters
				});
			}
			return $timeout(() => {
				return { values: resultList };
			});
		};

		// Function to dig out Named Capture Groups from a regex (the Search Pattern in ID rules
		// or the Pattern in metadata rules)
		function extractTokensFromRegex(regexStr) {
			const re = /\(\?<([^>]*)>/g;
			let match;
			const tokens = [];
			while((match = re.exec(regexStr))) {
				tokens.push(match[1]);
			}
			return tokens;
		}

		// Metadata Rule handling:

		function updateMetadataTokens(metadataRule) {
			if(metadataRule.usePattern) {
				return extractTokensFromRegex(metadataRule.pattern);
			}
			return [metadataRule.source];
		}

		$scope.updateAllMetadataTokens = function () {
			let metadataTokens = [];
			_.forEach($scope.dsc.metadataRules, metadataRule => {
				const ruleTokens = updateMetadataTokens(metadataRule);
				metadataTokens = metadataTokens.concat(ruleTokens);
			});
			if($scope.dsc.shouldExtractMetadata) {
				for(let i = 0; i < $scope.dsc.documentMetadataExtractionRules.length; i++) {
					const metadataExtractionRule = $scope.dsc.documentMetadataExtractionRules[i];
					metadataExtractionRule.usePattern = true;
					const tokens = updateMetadataTokens(metadataExtractionRule);
					metadataTokens = metadataTokens.concat(tokens);
					if(tokens.length > 0) {
						metadataExtractionRule.name = tokens.join(", ");
					} else {
						metadataExtractionRule.name = "Undefined Area " + (i + 1);
					}
				}
				$scope.updateDocumentMetadataFieldsInViewer();
			}
			$scope.dsc.metadataTokens = metadataTokens;
			_.forEach($scope.dsc.idRules, $scope.updateIdTokens);
			checkForInvalidFormats(true);
		};

		$scope.updateDocumentMetadataFieldsInViewer = function () {
			const updateMetadataFields = $scope.exampleDocumentViewerControl.updateMetadataFields;
			if(updateMetadataFields) {
				updateMetadataFields();
			}
		};

		$scope.addMetadataRule = function () {
			const metadataRule = {
				source: "", // Source metadata field
				pattern: "", // Regex with Named Capture Groups to extract a part of the metadata field
				usePattern: false, // Is the regex used (true) or is the entire field used (false)?
			};
			$scope.dsc.metadataRules.push(metadataRule);
			$scope.formScope.form.$setDirty();
			// No need to call updateAllMetadataTokens as this rule doesn't define any tokens yet.
		};

		$scope.removeMetadataRule = function (metadataRule) {
			_.remove($scope.dsc.metadataRules, metadataRule);
			$scope.updateAllMetadataTokens();
			checkForInvalidFormats(true);
			$scope.formScope.form.$setDirty();
		};

		// Matching Rule (Id Rule) handling:

		$scope.updateIdTokens = function (idRule) {
			if(idRule.ruleType !== $scope.regexType) {
				return;
			}
			// Tokens available in each idRule consist of the tokens defined in its pattern and
			// all tokens from metadata.
			// Possible future extension is to have all idRules' tokens available in all idRules.
			let idTokens = extractTokensFromRegex(idRule.pattern);
			idTokens = idTokens.concat($scope.dsc.metadataTokens);
			idRule.tokens = idTokens.map(token => {
				return {
					displayName: token,
					abbreviation: token,
					type: "attribute",
				};
			});
		};

		$scope.addIdRule = function () {
			const idRule = {
				// Regex with Named Capture Groups to match and extract text in the document
				pattern: "",
				// Set of fragments that make up the object ID to link to
				format: [],
				// Tokens available to be used (populated by $scope.updateIdTokens)
				tokens: [],
				// If useAbbreviation is true, this is the attribute abbreviation that is required
				// for the link to be generated
				abbreviation: "",
				// If useAbbreviation is "any", any text that matches the pattern will be used to
				// generate a link
				targetType: "any",
				// Validation: The format must only contain valid fragments (text and tokens that
				// actually exist)
				formatInvalid: true,
				// Index of the currently selected token in the filtered token list
				current: -1,
				// To separate from automatic detection of model objects or smart points
				ruleType: $scope.regexType,
				// New rule is enabled by default
				isActive: true,
				// a generic name for new entries
				name: getRuleName(),
			};
			$scope.dsc.idRules.push(idRule);
			checkForInvalidFormats(false);
			$scope.formScope.form.$setDirty();
		};

		function getRuleName() {
			const index = $scope.dsc.idRules.length - 1;
			let i = 0;
			// eslint-disable-next-line no-constant-condition
			while(true) {
				const name = "Pattern rule " + (index + i);
				if(!_.some($scope.dsc.idRules, { "name": name })) {
					return name;
				}
				i++;
			}
		}

		$scope.removeIdRule = function (idRule) {
			if(idRule.ruleType !== $scope.regexType) {
				return;
			}
			_.remove($scope.dsc.idRules, idRule);
			checkForInvalidFormats(true);
			$scope.formScope.form.$setDirty();
		};

		$scope.toggleRuleIsActive = function (idRule) {
			idRule.isActive = !idRule.isActive;
			checkForInvalidFormats(true);
			$scope.formScope.form.$setDirty();
		};

		$scope.toggleAdvancedOptions = function () {
			$scope.showAdvancedOptions = !$scope.showAdvancedOptions;
		};

		$scope.toggleRuleAdvancedOptions = function (rule) {
			rule.showAdvancedOptions = !rule.showAdvancedOptions;
		};
		// Matching Rule fragment handling:

		function checkIdRule(idRule) {
			idRule.formatInvalid = idRule.format.length === 0;
			if(!idRule.formatInvalid) {
				_.forEach(_.filter(idRule.format, { type: "token" }), fragment => {
					if(idRule.tokens.indexOf(fragment.label) < 0) {
						idRule.formatInvalid = true;
					}
				});
			}
			return idRule.formatInvalid;
		}

		function checkForInvalidFormats(thorough) {
			let invalid = false;
			_.forEach($scope.dsc.idRules, idRule => {
				if(idRule.ruleType === $scope.regexType) {
					let idRuleInvalid = idRule.formatInvalid;
					if(thorough) {
						idRuleInvalid = checkIdRule(idRule);
					}
					if(idRuleInvalid) {
						invalid = true;
					}
				}
			});
			$scope.someFormatInvalid = invalid;
		}

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		function toggleDocumentViewer() {
			if($scope.dsc && $scope.dsc.shouldExtractMetadata) {
				const cfg = _.cloneDeep($scope.dsc);
				documentConfigurationsRepository
					.getDocumentForConfiguration($scope.projectId, cfg)
					.then(response => {
						documentConfigurationsRepository
							.getDocumentSize($scope.projectId, response.dataSourceId, response.id)
							.then(size => {
								$scope.exampleDocument = response;
								$scope.exampleDocument.mapCoordinateAndPointInformation = {
									coordinateInformation: size,
								};
								$scope.exampleDocument.configurationMode = true;
								$scope.exampleDocument.isForDocuments = true;
								$scope.exampleDocument.isVisible = true;
								$scope.exampleDocument.showPoints = true;
								const interval = $interval(() => {
									const loadDocument = $scope.exampleDocumentLoadControl.loadDocument;
									if(loadDocument && $scope.projectId) {
										loadDocument(
											null, null, null, null, null, null, null, null, false, true
										);
										$interval.cancel(interval);
									}
								}, 100);
							});
					});
			} else {
				$scope.exampleDocumentLoadControl = {};
				$scope.exampleDocumentViewerControl = {};
				$scope.docInfo = {};
				$scope.exampleDocument = null;
				$("#" + $scope.exampleDocumentDivId).empty();
			}
		}

		// Save and Cancel actions:

		$scope.save = function () {
			const cfg = _.cloneDeep($scope.dsc);
			cfg.linkTargetIds = _.uniq(cfg.linkTargetIds);
			_.forEach(cfg.metadataRules, metadataRule => {
				if(!metadataRule.usePattern) {
					metadataRule.pattern = "(?<" + metadataRule.source + ">.*)";
				}
				delete metadataRule.usePattern;
			});
			if(cfg.shouldExtractMetadata) {
				//cfg.metadataFieldWidth = $scope.exampleDocument.metadataFieldWidth;
				//cfg.metadataFieldHeight = $scope.exampleDocument.metadataFieldHeight;
			}
			_.forEach(cfg.idRules, idRule => {
				if(idRule.ruleType === $scope.regexType) {
					if(idRule.targetType === "any") {
						idRule.abbreviation = null;
					}
					if(idRule.targetType === "pointOfInterest") {
						idRule.abbreviation = ":::smartPointAbbreviation:::" + idRule.abbreviation;
					}
					idRule.format.forEach(part => {
						if(part.type === "attribute") {
							part.type = "token";
						}
						part.label = part.displayName;
						delete (part.abbreviation);
						delete (part.displayName);
					});
				}
				delete idRule.targetType;
				delete idRule.newFragment;
				delete idRule.listHidden;
				delete idRule.current;
				delete idRule.newFragmentWidth;
				delete idRule.showAdvancedOptions;
			});
			if(isNew) {
				$scope.pdfConfiguration.documentTypeConfigurations.push(cfg);
			} else {
				const typeIndex = $stateParams.documentTypeIndex;
				$scope.pdfConfiguration.documentTypeConfigurations[typeIndex] = cfg;
			}
			documentConfigurationsRepository
				.savePdfSettings($stateParams.projectId, $scope.pdfConfiguration)
				.then(() => {
					$scope.formScope.form && $scope.formScope.form.$setPristine();
					$state.go("^", {}, { reload: true });
				}, error => {
					notification.error(error);
				});
		};

		$scope.setParent = function (scope) {
			scope.dragAndDrop = scope.$parent;
		};

		$scope.onTargetIdChanged = function () {
			checkForInvalidFormats(true);
			$scope.formScope.form.$setDirty();
		};

		function toggleDataSourceTargets() {
			if(!$scope.dsc) {
				return;
			}
			const managedDocumentDataSource = "Managed Document Data Source:::::"
				+ "Managed Document Data Source Adapter:::::"
				+ "bca34edb-85ff-41d3-9902-68f09eb0e2b5";
			if($scope.dsc.targetManagedDocuments) {
				$scope.dsc.targetDataSources = [];
				$scope.unUsedDocumentDataSources = _.cloneDeep($scope.documentDataSources);
				$scope.dsc.targetDataSources.push(managedDocumentDataSource);
			} else {
				if($scope.dsc.targetDataSources
					&& $scope.dsc.targetDataSources[0]
					&& $scope.dsc.targetDataSources[0] === managedDocumentDataSource) {
					$scope.dsc.targetDataSources = [];
					$scope.unUsedDocumentDataSources = _.cloneDeep($scope.documentDataSources);
				}
			}
		}

		$scope.addDocumentMetadataExtractionRule = function () {
			const addNewMetadataExtractionArea =
				$scope.exampleDocumentViewerControl.addNewMetadataExtractionArea;
			if(addNewMetadataExtractionArea) {
				addNewMetadataExtractionArea();
				$scope.updateAllMetadataTokens();
				$scope.formScope.form.$setDirty();
			}
		};

		$scope.removeDocumentMetadataExtractionRule = function (index) {
			const removeMetadataExtractionArea =
				$scope.exampleDocumentViewerControl.removeMetadataExtractionArea;
			if(removeMetadataExtractionArea) {
				removeMetadataExtractionArea(index);
			}
			$scope.dsc.documentMetadataExtractionRules.splice(index, 1);
			$scope.updateAllMetadataTokens();
			$scope.formScope.form.$setDirty();
		};

		$scope.originCornerEnumToString = function (enumName) {
			switch(enumName) {
			case "BottomLeft":
				return "Bottom Left";
			case "BottomRight":
				return "Bottom Right";
			case "TopLeft":
				return "Top Left";
			case "TopRight":
				return "Top Right";
			default:
				return "undefined";
			}
		};

		$scope.changeOriginCorner = function (rule, newCorner) {
			rule.originCorner = newCorner;
			const repositionElement = $scope.exampleDocumentViewerControl.repositionElement;
			if(repositionElement) {
				repositionElement(true);
			}
		};

		$scope.onDocInfoLoaded = function (documentKey, docInfo) {
			$scope.docInfo = docInfo;
		};

		$scope.onPageNumberRequested = function onPageNumberRequested(pageNumber) {
			if($scope.docInfo.currentPageNumber === pageNumber) {
				return;
			}
			$scope.exampleDocumentViewerControl.changePage(pageNumber);
		};

		$scope.testDocumentMetadataExtraction = function () {
			const configuration = _.cloneDeep($scope.dsc);
			if(!$scope.exampleDocument) {
				return;
			}
			documentConfigurationsRepository
				.getDocumentExtractedMetadata(
					$scope.projectId,
					$scope.exampleDocument.dataSourceId,
					$scope.exampleDocument.id,
					configuration
				)
				.then(response => {
					$scope.extractedMetadata = response;
					$scope.resultContainsMetadata = !!(Object.keys($scope.extractedMetadata).length);
					$scope.metadataExtractionResultsExpanded = true;
				});
		};

		$scope.closeMetadataExtractionResults = function () {
			$scope.metadataExtractionResultsExpanded = false;
		};

		$scope.isDocumentTypeDetectionInvalid = function () {
			if(!$scope.dsc.shouldTargetMetadata && !$scope.dsc.shouldTargetDataSources) {
				return true;
			}
			if($scope.dsc.shouldTargetMetadata
				&& (!$scope.dsc.typeKey || $scope.dsc.typeKey.length === 0
					|| !$scope.dsc.typeValue || $scope.dsc.typeValue.length === 0)) {
				return true;
			}
			if($scope.dsc.shouldTargetDataSources
					&& (!$scope.dsc.targetDataSources || $scope.dsc.targetDataSources.length === 0)) {
				return true;
			}
			return false;
		};
	}
]);
