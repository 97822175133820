angular.module("eShareApp").factory("onBeforeUnloadService", onBeforeUnloadService);
onBeforeUnloadService.$inject = ["$window"];

function onBeforeUnloadService($window) {
	const unloadListeners = [];

	$window.onbeforeunload = function (event) {
		let returnString = "";
		unloadListeners.forEach(listener => {
			const returned = listener(event);
			if(typeof returned === "string") {
				if(returnString.length > 0) {
					returnString += "\n";
				}
				returnString += returned;
			}
		});
		if(returnString.length > 0) {
			return returnString;
		}
		return undefined;
	};

	function addUnloadListener(listener) {
		if(typeof listener !== "function") {
			throw "OnBeforeUnloadService: listener is not a function";
		}
		unloadListeners.push(listener);
	}

	return {
		addUnloadListener: addUnloadListener,
	};
}