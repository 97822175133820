angular.module("eShareApp").controller("ModelHierarchyEditCtrl", [
	"$state", "$scope", "$stateParams", "notification", "dirtyFormTrackingService",
	"attributeDefinitions", "modelHierarchies", "modelHierarchiesRepository",

	function (
		$state, $scope, $stateParams, notification, dirtyFormTrackingService,
		attributeDefinitions, modelHierarchies, modelHierarchiesRepository
	) {

		$scope.attributes = attributeDefinitions;
		$scope.hierarchies = modelHierarchies.hierarchyDefinitions;
		if($stateParams.hierarchyIndex >= 0
				&& $stateParams.hierarchyIndex < $scope.hierarchies.length) {
			$scope.hierarchy = modelHierarchies.hierarchyDefinitions[$stateParams.hierarchyIndex];
			$scope.hierarchy.isEditable = true;
			for(let i = 0; i < $scope.hierarchy.levels.length; i++) {
				const level = $scope.hierarchy.levels[i];
				if(level.groupName && level.groupName.length > 0) {
					$scope.hierarchy.isEditable = false;
				}
				level.attributes = _.map(level.queryAttributes, abbreviation => {
					const definition = _.find(attributeDefinitions, { "abbreviation": abbreviation });
					return {
						abbreviation: abbreviation,
						displayName: definition ? definition.displayName : abbreviation,
					};
				});
				level.attributeString = "";
				for(let k = 0; k < level.attributes.length; k++) {
					level.attributeString += " " + level.attributes[k] + ",";
				}
				const lastCommaIndex = level.attributeString.lastIndexOf(",");
				level.attributeString = level.attributeString.substring(0, lastCommaIndex);
			}
		} else {
			$scope.hierarchy = {
				name: "",
				originalName: "",
				levels: [],
				isActive: true,
				isEditable: true,
				isNew: true,
			};
			$scope.hierarchies.push($scope.hierarchy);
		}

		$scope.selectOptions = {
			filter: "contains",
			placeholder: "Select one or more attributes",
		};

		// Change tracking (warn before navigating to a different page
		// if the user has changed something on the page):
		$scope.setFormScope = function (formScope) {
			$scope.formScope = formScope;
			dirtyFormTrackingService.trackForm($scope.formScope, "form");
		};

		$scope.addLevel = function () {
			$scope.hierarchy.levels.push({
				name: "",
				nonMatchingCategory: "",
				groupName: "",
				attributes: [],
			});
			$scope.formScope.form.$setDirty();
		};

		$scope.removeLevel = function (index) {
			$scope.hierarchy.levels.splice(index, 1);
			$scope.formScope.form.$setDirty();
		};

		$scope.isValid = function () {
			return $scope.hierarchy.name
				&& !_.some($scope.hierarchy.levels, l1 => {
					return !l1.name || l1.attributes.length === 0;
				})
				&& !_.some($scope.hierarchies, h1 => {
					return $scope.hierarchy !== h1
						&& $scope.hierarchy.name
						&& h1.name
						&& $scope.hierarchy.name.toLowerCase() === h1.name.toLowerCase();
				});
		};

		$scope.saveChanges = function () {
			for(let j = 0; j < $scope.hierarchy.levels.length; j++) {
				const level = $scope.hierarchy.levels[j];
				level.queryAttributes = _.map(level.attributes, "abbreviation");
			}
			$scope.hierarchy.isModified = true;
			modelHierarchiesRepository.save($stateParams.projectId, $scope.hierarchies).then(() => {
				$scope.formScope.form && $scope.formScope.form.$setPristine();
				$state.go("^", {}, { reload: true });
			}, error => {
				notification.error(error);
			});
		};
	}
]);
